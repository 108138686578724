import React from "react";

const BidContentPricingItems = (props) => {
    return (
        <tr key={"pricing_item_" + props.index}>
            <td>{parseInt(props.index) + 1}</td>
            <td>
                <textarea
                    type="text"
                    className="form-control form-control-lg p-item-width"
                    value={props.pricing_detail && props.pricing_detail.pricing_item ? props.pricing_detail.pricing_item : ""}
                    onChange={(event) => props.inputChangeHandler(event, "pricing_item")}
                />
            </td>
            <td>
                <input
                    type="text"
                    pattern="[0-9]+"
                    className="form-control form-control-lg price-value-width"
                    value={props.pricing_detail && props.pricing_detail.pricing_unit ? props.pricing_detail.pricing_unit : ""}
                    onChange={(event) => {
                        if (isNaN(Number(event.target.value)) === false) {
                            props.inputChangeHandler(event, "pricing_unit");
                        }
                    }}
                />
            </td>
            <td>
                <input
                    type="text"
                    className="form-control form-control-lg quqntity-width"
                    value={props.pricing_detail && props.pricing_detail.pricing_qty ? props.pricing_detail.pricing_qty : ""}
                    onChange={(event) => {
                        if (isNaN(Number(event.target.value)) === false) {
                            props.inputChangeHandler(event, "pricing_qty");
                        }
                    }}
                />
            </td>
            <td>{props.pricing_detail && props.pricing_detail.pricing_amount ? "$" + props.pricing_detail.pricing_amount : ""}</td>
            <td className="cross-btn1">
                <span className="curser_pointer" onClick={(event) => props.propsRemoveBidContentPricing(event)}>
                    <i className="icon-cross-2"></i>
                </span>
            </td>
        </tr>
    );
};
export default BidContentPricingItems;