import React, { useState, useEffect, useCallback } from "react";

import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";

import MyTasksItems from "./MyTasksItems";

import * as userActions from "../../actions/index";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

const MyTasksList = (props) => {
    const dispatch = useDispatch();

    // const [loader, setloader] = useState(false);

    const [bidtags, setbidtags] = useState([]);
    const [mytasks, setmytasks] = useState([]);
    const [compay_users, setcompay_users] = useState([]);
    const [mytasks_count, setmytasks_count] = useState(0);

    const [completedtasks, setcompletedtasks] = useState(0);
    const [incompletetasks, setincompletetasks] = useState(0);
    const [overduetasks, setoverduetasks] = useState(0);
    const [totaltasks, settotaltasks] = useState(0);

    const [filterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filtersort, setfiltersort] = useState("bid_title");
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersearch, setfiltersearch] = useState(null);
    const [filtersection_status, setfiltersection_status] = useState("all");

    useEffect(() => {
        const GetTags = () => {
            dispatch(userActions.GetTags()).then((response) => {
                if (response.success === true) {
                    setbidtags(userActions.key_to_array_multidimensional(response.data, "bidtag_id"));
                } else {
                    notify.show(response.message, "error");
                }
            });
        };
        GetTags();
    }, [dispatch]);

    useEffect(() => {
        const GetCompanyUsers = () => {
            dispatch(userActions.GetCompanyUsers()).then((response) => {
                if (response.success === true) {
                    setcompay_users(userActions.key_to_array_multidimensional(response.data, "user_id"));
                } else {
                    notify.show(response.message, "error");
                }
            });
        };
        GetCompanyUsers();
    }, [dispatch]);

    useEffect(() => {
        const GetMyTaskStatus = () => {
            dispatch(userActions.GetMyTaskStatus()).then((response) => {
                if (response.success === true) {
                    setcompletedtasks(response.data.section_status && response.data.section_status.Completed ? response.data.section_status.Completed : 0);
                    setincompletetasks(response.data.incompleteTasks ? response.data.incompleteTasks : 0);
                    setoverduetasks(response.data.overdueTasks ? response.data.overdueTasks : 0);
                    settotaltasks(response.data.totalTask ? response.data.totalTask : 0);
                } else {
                    notify.show(response.message, "error");
                }
            });
        };
        GetMyTaskStatus();
    }, [dispatch]);

    const GetMyTasks = useCallback(() => {
        dispatch(userActions.GetMyTasks(filtersearch, filterpage, filterlimit, filtersort, filterorder, filtersection_status)).then((response) => {
            if (response.success === true) {
                setmytasks(response.data);
                setmytasks_count(response.count);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, filtersearch, filterpage, filterlimit, filtersort, filterorder, filtersection_status]);

    useEffect(() => {
        GetMyTasks();
    }, [GetMyTasks, filtersearch, filterpage, filterlimit, filtersort, filterorder, filtersection_status]);

    return (
        <React.Fragment>
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <LoginHeader page_title="My tasks" />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card mb-4 bg-transparent">
                                                <div className="card-body p-0">
                                                    <div className="row mb-9">
                                                        <div className="col-sm-3">
                                                            <div
                                                                className={"client-main cursor-pointer" + (filtersection_status === "complete_task" ? " filtersection_status_active" : "")}
                                                                onClick={(event) => setfiltersection_status("complete_task")}
                                                            >
                                                                <span className="text-dark txt-top">Completed tasks</span> <span className="text-dark fw-bolder txt-bottom">{completedtasks}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div
                                                                className={"client-main cursor-pointer" + (filtersection_status === "incomplete_task" ? " filtersection_status_active" : "")}
                                                                onClick={(event) => setfiltersection_status("incomplete_task")}
                                                            >
                                                                <span className="text-dark txt-top">Incomplete tasks</span> <span className="text-dark fw-bolder txt-bottom">{incompletetasks}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div
                                                                className={"client-main cursor-pointer" + (filtersection_status === "overdue_task" ? " filtersection_status_active" : "")}
                                                                onClick={(event) => setfiltersection_status("overdue_task")}
                                                            >
                                                                <span className="text-dark txt-top">Overdue tasks</span> <span className="text-dark fw-bolder txt-bottom">{overduetasks}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className={"client-main cursor-pointer" + (filtersection_status === "all" ? " filtersection_status_active" : "")} onClick={(event) => setfiltersection_status("all")}>
                                                                <span className="text-dark txt-top">Total tasks</span> <span className="text-dark fw-bolder txt-bottom">{totaltasks}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {bidtags.length !== 0 && compay_users.length !== 0 && (
                                        <MyTasksItems
                                            bidtags={bidtags}
                                            mytasks={mytasks}
                                            filtersort={filtersort}
                                            filterorder={filterorder}
                                            filterlimit={filterlimit}
                                            filterpage={filterpage}
                                            compay_users={compay_users}
                                            mytasks_count={mytasks_count}
                                            propsfiltersort={(event) => setfiltersort(event)}
                                            propsfilterorder={(event) => setfilterorder(event)}
                                            propsfilterpage={(event) => setfilterpage(event)}
                                            propsfiltersearch={(event) => setfiltersearch(event)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default MyTasksList;