import React from "react";
class HeaderNotificationsSidebarItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            desktop_notifications: [],
        };
    }

    async componentDidMount() {
        this.setState({
            desktop_notifications: this.props?.desktop_notifications,
        });
    }

    HeaderNotificationsSidebarReload() {
        this.setState({
            desktop_notifications: this.props?.desktop_notifications,
        });
    }

    render() {
        const { desktop_notifications } = this.state;
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        return (
            <React.Fragment>
                <div className="desktop_notifications">
                    {desktop_notifications.length > 0 ? (
                        <div className="desktop_notifications">
                            {desktop_notifications.map((desktop_notification, desktop_notification_key) => (
                                <div
                                    className="d-flex mb-4 pb-4 border-bottom border-bottom-2 curser_pointer notify-outer"
                                    key={`desktop_notification_${desktop_notification_key}`}
                                    onClick={(event) => this.props.propsNotificationMarkRead(event, desktop_notification)}
                                >
                                    <div className="symbol-group symbol-hover">
                                        <div className="symbol symbol-25px symbol-circle me-3">
                                            {desktop_notification?.action_user && desktop_notification?.action_user.avatar ? (
                                                <span className="symbol_img symbol-labely">
                                                    <img
                                                        src={desktop_notification?.action_user.avatar}
                                                        alt={desktop_notification?.action_user.first_name + " " + desktop_notification?.action_user.last_name}
                                                        title={desktop_notification?.action_user.first_name + " " + desktop_notification?.action_user.last_name}
                                                    />
                                                </span>
                                            ) : (
                                                <span className="symbol-label bg-primary text-inverse-primary">{desktop_notification?.action_user.first_name?.slice(0, 1) + desktop_notification?.action_user.last_name?.slice(0, 1)}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="justify-content-between">
                                        <span className="color1 fs-14 notify-txt me-1">{desktop_notification?.action_user.first_name + " " + desktop_notification?.action_user.last_name}</span>
                                        <span className="color1 fs-14 notify-txt notify-txt-2">{renderHTML(desktop_notification?.desktopnotification_html)}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-center font-weight-bold">No Record Found</div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}
export default HeaderNotificationsSidebarItem;