import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import Validations from "../../components/Utility/Validations";

class Invitation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invitation_form: {
                invite_id: {
                    label: "Invite Id",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                first_name: {
                    type: "text",
                    label: "First Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                last_name: {
                    type: "text",
                    label: "Last  Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                email: {
                    label: "Email Address",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },
                phone: {
                    type: "phone",
                    label: "Phone number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                password: {
                    label: "Password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, password_validation: true },
                },
                confirm_password: {
                    label: "Confirm Password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, password_validation: true },
                },
            },

            invite_detail: [],

            is_form_valid: false,

            passwordshow: false,
            confirm_passwordshow: false,

            redirect_url: "",
            isAuthenticated: false,
            invitation_valid: true,
        };
    }

    componentDidMount() {
        userActions.GetInvitationById(this.props.match.params.id).then((response) => {
            if (response.success === true) {
                let invite_detail = response.data;
                if (invite_detail) {
                    const update_invitation_form = { ...this.state.invitation_form };

                    update_invitation_form.invite_id.value = invite_detail.invite_id;
                    update_invitation_form.email.value = invite_detail.invite_email;

                    this.setState({
                        invite_detail: invite_detail,
                        invitation_form: update_invitation_form,
                        invitation_valid: invite_detail?.user_id ? false : true,
                    });
                } else {
                    this.setState({ invitation_valid: false });
                }
            } else {
                this.setState({ invitation_valid: false });

                setTimeout(() => {
                    this.setState({
                        isAuthenticated: true,
                        redirect_url: "/signin",
                    });
                }, 1000);

                notify.show(response.message, "error");
            }
        });
    }

    inputChangeHandler(event, identifier) {
        const update_invitation_form = { ...this.state.invitation_form };
        const form_element = { ...update_invitation_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_invitation_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_invitation_form) {
            is_form_valid = update_invitation_form[identifier].valid && is_form_valid;
        }

        if (update_invitation_form.password.value && update_invitation_form.confirm_password.value) {
            if (update_invitation_form.password.value !== update_invitation_form.confirm_password.value) {
                update_invitation_form.confirm_password.valid = false;
                update_invitation_form.confirm_password.error_msg = "Passwords do not match";
            }
        }

        this.setState({
            invitation_form: update_invitation_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_invitation_form = this.state.invitation_form;
        for (let key in update_invitation_form) {
            let form_element = update_invitation_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_invitation_form[key] = form_element;
        }

        if (update_invitation_form.password.value && update_invitation_form.confirm_password.value) {
            if (update_invitation_form.password.value !== update_invitation_form.confirm_password.value) {
                update_invitation_form.confirm_password.valid = false;
                update_invitation_form.confirm_password.error_msg = "confirm password not match";
            }
        }

        this.setState({ invitation_form: update_invitation_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.invitation_form) {
                if (key === "remember") {
                    if (this.state.invitation_form[key].isChecked === false) {
                        break;
                    }
                }
                form_data[key] = this.state.invitation_form[key].value;
            }
            this.setState({ loader: true });
            userActions.SubmitInvitation(form_data).then((response) => {
                this.setState({ loader: false });

                if (response.success === true) {
                    notify.show(response.message, "success");

                    this.props.onGetAuthUser().then((response) => {
                        if (response.success === true) {
                            setTimeout(() => {
                                this.setState({
                                    isAuthenticated: true,
                                    redirect_url: "/dashboard",
                                });
                            }, 500);
                        } else {
                            notify.show(response.message, "error");
                        }
                    });
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    render() {
        const { invitation_form, invite_detail } = this.state;
        return (
            <React.Fragment>
                <Notifications />
                {this.state.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <div
                            id="kt_aside"
                            className="aside aside-dark aside-hoverable w-600px "
                            data-kt-drawer="true"
                            data-kt-drawer-name="aside"
                            data-kt-drawer-activate="{default: true, lg: false}"
                            data-kt-drawer-overlay="true"
                            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                            data-kt-drawer-direction="start"
                            data-kt-drawer-toggle="#kt_aside_mobile_toggle"
                        >
                            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 text-center-md w-xl-600px scroll-y">
                                <div className="d-flex flex-row-fluid flex-column pt-lg-10 ps-lg-19">
                                    <Link to="/" className="mb-15 ps-md-10">
                                        <img src="assets/images/logo.svg" title="logo" alt="logo" className="h-60px" />
                                    </Link>
                                    <h1 className="fw-bold-600 fs-3hx pb-md-5 ps-md-10 mb-0 text-white">
                                        Join your team <br />
                                        on BidHQ to <br />
                                        create better <br /> bids.
                                    </h1>
                                </div>
                                <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-400px invite-in-banner"></div>
                            </div>
                        </div>
                        <div className="wrapper d-flex flex-column flex-row-fluid bg-white pt-0 invite-padding" id="kt_wrapper">
                            <div className="mobile-logo-block flex-center d-flex bg-dark-color p-10 pt-15 pb-15 w-100 mb-5">
                                <Link to="/">
                                    <img src="assets/images/logo.svg" alt="Logo" className="logo" />
                                </Link>
                                <img src="assets/images/thankpage-img/Emblem.png" alt="#" className="nav-img-wrap" />
                                <img src="assets/images/thankpage-img/Emblem-one.png" alt="#" className="nav-img-wrap-two" />
                            </div>
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="d-flex flex-column flex-lg-row-fluid">
                                    <div className="d-flex flex-center flex-column flex-column-fluid">
                                        <div className="w-lg-600px p-10 pt-0 pb-0 mx-auto">
                                            {this.state.invitation_valid ? (
                                                <form className="form w-100" autoComplete="off" onSubmit={this.SubmitHandler}>
                                                    <div className="mb-10">
                                                        <h1 className="text-dark mb-3 fs-2tx">Welcome!</h1>
                                                        <div className="text-dark">
                                                            <p>
                                                                <strong>
                                                                    {invite_detail?.created_user?.first_name} {invite_detail?.created_user?.last_name}
                                                                </strong>{" "}
                                                                has invited you to use BidHQ with them, in a workspace called <strong>{invite_detail?.company?.company_name}.</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="row fv-row">
                                                        <div className={"col-xl-6 mb-5" + (!invitation_form.first_name.valid && invitation_form.first_name.onBlur_out ? " error-field" : "")}>
                                                            <label className="form-label">{invitation_form.first_name.label}*</label>
                                                            <input
                                                                autoComplete="new_first_name"
                                                                type={invitation_form.first_name.type}
                                                                className="form-control form-control-lg form-control-solid"
                                                                placeholder={invitation_form.first_name.placeholder}
                                                                value={invitation_form.first_name.value ? invitation_form.first_name.value : ""}
                                                                onChange={(event) => this.inputChangeHandler(event, "first_name")}
                                                                onBlur={(event) => {
                                                                    invitation_form.first_name.onBlur_out = true;
                                                                    this.setState({
                                                                        invitation_form: invitation_form,
                                                                    });
                                                                }}
                                                            />
                                                            {!invitation_form.first_name.valid && invitation_form.first_name.onBlur_out ? <div className="error field-error">{invitation_form.first_name.error_msg}</div> : ""}
                                                        </div>
                                                        <div className={"col-xl-6 mb-5" + (!invitation_form.last_name.valid && invitation_form.last_name.onBlur_out ? " error-field" : "")}>
                                                            <label className="form-label fs-6">{invitation_form.last_name.label}*</label>
                                                            <input
                                                                autoComplete="new_last_name"
                                                                type={invitation_form.last_name.type}
                                                                className="form-control form-control-lg form-control-solid"
                                                                placeholder={invitation_form.last_name.placeholder}
                                                                value={invitation_form.last_name.value ? invitation_form.last_name.value : ""}
                                                                onChange={(event) => this.inputChangeHandler(event, "last_name")}
                                                                onBlur={(event) => {
                                                                    invitation_form.last_name.onBlur_out = true;
                                                                    this.setState({
                                                                        invitation_form: invitation_form,
                                                                    });
                                                                }}
                                                            />
                                                            {!invitation_form.last_name.valid && invitation_form.last_name.onBlur_out ? <div className="error field-error">{invitation_form.last_name.error_msg}</div> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="row fv-row">
                                                        <div className={"col-xl-6 mb-5" + (!invitation_form.email.valid && invitation_form.email.onBlur_out ? " error-field" : "")}>
                                                            <label className="form-label fs-6">{invitation_form.email.label}*</label>
                                                            <input
                                                                readOnly
                                                                autoComplete="new_email"
                                                                type={invitation_form.email.type}
                                                                className="form-control form-control-lg form-control-solid"
                                                                placeholder={invitation_form.email.placeholder}
                                                                value={invitation_form.email.value ? invitation_form.email.value : ""}
                                                                onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                onBlur={(event) => {
                                                                    invitation_form.email.onBlur_out = true;
                                                                    this.setState({
                                                                        invitation_form: invitation_form,
                                                                    });
                                                                }}
                                                            />
                                                            {!invitation_form.email.valid && invitation_form.email.onBlur_out ? <div className="error field-error">{invitation_form.email.error_msg}</div> : ""}
                                                        </div>
                                                        <div className={"col-xl-6 mb-5" + (!invitation_form.phone.valid && invitation_form.phone.onBlur_out ? " error-field" : "")}>
                                                            <label className="form-label fs-6">{invitation_form.phone.label}</label>
                                                            <input
                                                                autoComplete="new_phone"
                                                                type={invitation_form.phone.type}
                                                                className="form-control form-control-lg form-control-solid"
                                                                placeholder={invitation_form.phone.placeholder}
                                                                value={invitation_form.phone.value ? invitation_form.phone.value : ""}
                                                                onChange={(event) => this.inputChangeHandler(event, "phone")}
                                                                onBlur={(event) => {
                                                                    invitation_form.phone.onBlur_out = true;
                                                                    this.setState({
                                                                        invitation_form: invitation_form,
                                                                    });
                                                                }}
                                                            />
                                                            {!invitation_form.phone.valid && invitation_form.phone.onBlur_out ? <div className="error field-error">{invitation_form.phone.error_msg}</div> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="row fv-row">
                                                        <div className={"col-xl-6 mb-5" + (!invitation_form.password.valid && invitation_form.password.onBlur_out ? " error-field" : "")}>
                                                            <label className="form-label fs-6">{invitation_form.password.label}*</label>
                                                            <div className="position-relative mb-3">
                                                                <input
                                                                    autoComplete="new_password"
                                                                    type={this.state.passwordshow ? "text" : "password"}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={invitation_form.password.placeholder}
                                                                    value={invitation_form.password.value ? invitation_form.password.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "password")}
                                                                    onBlur={(event) => {
                                                                        invitation_form.password.onBlur_out = true;
                                                                        this.setState({
                                                                            invitation_form: invitation_form,
                                                                        });
                                                                    }}
                                                                />
                                                                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                                    <i
                                                                        className={this.state.passwordshow ? "bi bi-eye fs-2" : "bi bi-eye-slash fs-2"}
                                                                        onClick={(event) => {
                                                                            this.setState({ passwordshow: this.state.passwordshow ? false : true });
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                            {!invitation_form.password.valid && invitation_form.password.onBlur_out ? <div className="error field-error">{invitation_form.password.error_msg}</div> : ""}
                                                        </div>
                                                        <div className={"col-xl-6 mb-5" + (!invitation_form.confirm_password.valid && invitation_form.confirm_password.onBlur_out ? " error-field" : "")}>
                                                            <label className="form-label fs-6">{invitation_form.confirm_password.label}</label>
                                                            <div className="position-relative mb-3">
                                                                <input
                                                                    autoComplete="new_confirm_password"
                                                                    type={this.state.confirm_passwordshow ? "text" : "password"}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={invitation_form.confirm_password.placeholder}
                                                                    value={invitation_form.confirm_password.value ? invitation_form.confirm_password.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "confirm_password")}
                                                                    onBlur={(event) => {
                                                                        invitation_form.confirm_password.onBlur_out = true;
                                                                        this.setState({
                                                                            invitation_form: invitation_form,
                                                                        });
                                                                    }}
                                                                />
                                                                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                                    <i
                                                                        className={this.state.confirm_passwordshow ? "bi bi-eye fs-2" : "bi bi-eye-slash fs-2"}
                                                                        onClick={(event) => {
                                                                            this.setState({ confirm_passwordshow: this.state.confirm_passwordshow ? false : true });
                                                                        }}
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                            {!invitation_form.confirm_password.valid && invitation_form.confirm_password.onBlur_out ? (
                                                                <div className="error field-error">{invitation_form.confirm_password.error_msg}</div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="mb-4 fs-8">
                                                        <p>
                                                            By clicking sign up, you are creating an email account, and you agree to BidHQ’s
                                                            <Link to={{ pathname: "https://bidhq.com.au/end-user-agreement" }} target="_blank" className="ms-1 link-primary">
                                                                terms of service
                                                            </Link>{" "}
                                                            and
                                                            <Link to={{ pathname: "https://bidhq.com.au/privacy" }} target="_blank" className="ms-1 link-primary">
                                                                privacy policy
                                                            </Link>
                                                            .
                                                        </p>
                                                    </div>

                                                    <div className="text-center mb-4">
                                                        <button type="submit" className="btn rounded-50 btn-lg btn-primary w-100 mb-8">
                                                            <span className="indicator-label">Sign Up !</span>
                                                        </button>
                                                    </div>
                                                </form>
                                            ) : (
                                                <h1 className="text-dark mb-3 fs-2tx">Invitation has been expired</h1>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onGetAuthUser: userActions.GetAuthUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(Invitation);