import React from "react";

class BidReviewAndLockTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bid_detail: [],

            response_pdf: "",
            response_pdf_thumbnail: "",
            response_media: [],
            response_content: [],
            response_media_count: 0,
        };
    }

    componentDidMount() {
        let response_media = []
        if (this.props.response_media && this.props.response_media.length)
            for (const item of this.props.response_media) {
                if (!item.is_tender) {
                    response_media.push(item)
                }
            }
        this.setState({
            bid_detail: this.props.bid_detail,
            response_media: response_media,
            response_pdf: this.props.response_pdf,
            response_pdf_thumbnail: this.props.response_pdf_thumbnail,
            response_content: this.props.bid_response_form.response_content,
        });
    }

    BidReviewAndLockReload() {
        let response_media = []
        if (this.props.response_media && this.props.response_media.length)
            for (const item of this.props.response_media) {
                if (!item.is_tender) {
                    response_media.push(item)
                }
            }
        this.setState({
            bid_detail: this.props.bid_detail,
            response_media: response_media,
            response_pdf: this.props.response_pdf,
            response_pdf_thumbnail: this.props.response_pdf_thumbnail,
            response_content: this.props.bid_response_form.response_content,
        });
    }

    check_bid_lock = () => {
        let section_class = "";
        let bid_detail = this.state.bid_detail;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));

        if (bid_detail.bid_lock_by && bid_detail.bid_lock_by !== user_info?.user?.user_id) {
            section_class = bid_detail?.bid_lock ? " bid_locked_overlay" : "";
        }

        return section_class;
    };

    render() {
        const { bid_detail, response_pdf_thumbnail, response_media } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={"card mb-6" + (bid_detail?.bid_lock ? " bid_locked_overlay" : "") + (bid_detail?.bid_status && bid_detail?.bid_status === "Won" ? " bid_state_won_overlay" : "")}>
                            <div className="p-7">
                                <h2 className="card-title text-dark fw-bold-700 fs-2x">Review</h2>
                                <p className="m-0">There are {response_media && response_media.length !== 0 ? parseInt(response_media.length) + 1 : 1} files.</p>
                            </div>
                            {bid_detail && (
                                <div className="p-7 pt-0 pb-15 rewiew-new">
                                    <div className="row">
                                        <div className="col-md-3" key={"reviewlocktabdocument_key_" + bid_detail.bid_id}>
                                            <div className="review1">
                                                <div className="review-top pt-3 thumbnail_view">
                                                    <div className="align_font mb-1 thumbnail_view_item">
                                                        {response_pdf_thumbnail ? <img className="img-fluid" src={response_pdf_thumbnail} alt="" /> : <img className="img-fluid" src="assets/images/pdf-thumb.png" alt="" />}
                                                    </div>
                                                </div>
                                                <div className="review-bottom py-4 px-3">
                                                    <span className="text-dark fw-bold-700">{bid_detail.bid_title}</span>
                                                    <p className="text-gray-400 m-0">Bid response</p>
                                                </div>
                                            </div>
                                        </div>

                                        {response_media &&
                                            response_media.length !== 0 &&
                                            response_media.map((media_detail, media_key) => (
                                                <div className="col-md-3" key={"reviewlocktabdocument_key_" + media_detail.response_media_id}>
                                                    <div className="review1">
                                                        <div
                                                            className="review-top pt-3 thumbnail_view"
                                                            onClick={(event) => {
                                                                if (media_detail.media_type.indexOf("csv") === -1) {
                                                                    this.props.ViewResponseMedia(media_detail);
                                                                }
                                                            }}
                                                        >
                                                            {(media_detail.media_type.indexOf("jpeg") !== -1 || media_detail.media_type.indexOf("png") !== -1 || media_detail.media_type.indexOf("gif") !== -1) && (
                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                    <img width="100%" height="150px" src={media_detail?.avatar} alt={media_detail?.avatar_title} title={media_detail?.avatar_title} />
                                                                </div>
                                                            )}

                                                            {media_detail.media_type.indexOf("pdf") !== -1 && (
                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                    <i className="text-grey-dark icon-PDF fs-5x" aria-hidden="true"></i>
                                                                </div>
                                                            )}
                                                            {media_detail.media_type.indexOf("msword") !== -1 && (
                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                    <i className="text-grey-dark icon-DOC fs-5x" aria-hidden="true"></i>
                                                                </div>
                                                            )}
                                                            {media_detail.media_type.indexOf("spreadsheetml") !== -1 && (
                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                    <i className="text-grey-dark icon-XLS fs-5x" aria-hidden="true"></i>
                                                                </div>
                                                            )}
                                                            {media_detail.media_type.indexOf("officedocument") !== -1 && media_detail.media_type.indexOf("spreadsheetml") === -1 && (
                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                    <i className="text-grey-dark icon-DOC fs-5x" aria-hidden="true"></i>
                                                                </div>
                                                            )}
                                                            {media_detail.media_type.indexOf("excel") !== -1 && (
                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                    <i className="text-grey-dark icon-xls-new fs-5x" aria-hidden="true"></i>
                                                                </div>
                                                            )}
                                                            {media_detail.media_type.indexOf("csv") !== -1 && (
                                                                <div className="align_font mb-1 thumbnail_view_item">
                                                                    <i className="text-grey-dark icon-csv-new fs-5x" aria-hidden="true"></i>
                                                                </div>
                                                            )}
                                                            {media_detail.media_type.indexOf("html") !== -1 && (
                                                                <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                    <i className="text-grey-dark icon-HTML fs-5x" aria-hidden="true"></i>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="review-bottom py-4 px-3">
                                                            <span className="text-dark fw-bold-700">{media_detail?.avatar_title}</span>
                                                            <p className="text-gray-400 m-0">Bid response</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={"card mb-6" + this.check_bid_lock()}>
                            {bid_detail.bid_lock === true ? (
                                <div className="p-7">
                                    <h2 className="card-title text-dark fw-bold-700 fs-2x">Locked</h2>
                                    <p className="m-0">Bid is locked and no longer editable until it is unlocked again. Only admins and managers can unlock. </p>
                                    <button
                                        type="button"
                                        className="btn btn-md rounded-50 mt-4 unlock-btn hvr-grow"
                                        onClick={(event) => {
                                            this.props.BidUnlockConfirm(event);
                                        }}
                                    >
                                        <i className="icon-lock"></i> Unlock the bid
                                    </button>
                                </div>
                            ) : (
                                <div className="p-7">
                                    <h2 className="card-title text-dark fw-bold-700">Lock</h2>
                                    <p className="m-0">Locking your bid means it will no longer be editable until it is unlocked again. Only admins and managers can unlock. This is to ensure that the export reflects the latest approval.</p>
                                    <button
                                        type="button"
                                        className="btn btn-lg btn-danger rounded-50 mt-4 hvr-grow"
                                        onClick={(event) => {
                                            this.props.BidLockConfirm(true);
                                        }}
                                    >
                                        <i className="icon-lock"></i> Yes, I’m ready to lock
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default BidReviewAndLockTab;