import React from "react";
import { Modal } from "react-bootstrap";
import { Dropdown, DropdownButton } from "react-bootstrap";

import Validations from "../../../../components/Utility/Validations";
class SavetoLibraryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            savetolibrary_form: {
                library_id: {
                    type: "select",
                    label: "Folder this section will be saved under",
                    value: "",
                    value_title: "",
                    valid: true,
                    error_msg: "",
                    options: [],
                    validations: { required: true },
                },
                content_name: {
                    label: "Give this section a unique name so your team can easily identify search",
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: true },
                },
                description: {
                    label: "Content description",
                    type: "WysiwygEditor",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
            },
        };
    }

    componentDidMount() {
        let library_lists = this.props.library_lists;
        let library_bid_content = this.props.library_bid_content;
        const update_savetolibrary_form = { ...this.state.savetolibrary_form };

        let library_list_options = [];
        if (library_lists.length > 0) {
            for (let library_key in library_lists) {
                let library_list = library_lists[library_key];
                library_list_options.push({
                    opk: library_list.library_id,
                    name: library_list.name,
                    value: library_list.library_id,
                });
            }
        }
        update_savetolibrary_form.library_id.options = library_list_options;
        update_savetolibrary_form.description.value = library_bid_content.description;
        this.setState({ savetolibrary_form: update_savetolibrary_form });
    }

    AddSavetoLibraryModalTabReload() {
        let library_lists = this.props.library_lists;
        let library_bid_content = this.props.library_bid_content;
        const update_savetolibrary_form = { ...this.state.savetolibrary_form };

        let library_list_options = [];
        if (library_lists.length > 0) {
            for (let library_key in library_lists) {
                let library_list = library_lists[library_key];
                library_list_options.push({
                    opk: library_list.library_id,
                    name: library_list.name,
                    value: library_list.library_id,
                });
            }
        }
        update_savetolibrary_form.library_id.options = library_list_options;

        update_savetolibrary_form.library_id.value = this.props.library_bid_content_library_id;
        update_savetolibrary_form.library_id.value_title = this.props.library_bid_content_name;

        update_savetolibrary_form.description.value = library_bid_content.description;
        this.setState({ savetolibrary_form: update_savetolibrary_form });
    }

    inputChangeHandlerDropdown(event, option, identifier) {
        const update_savetolibrary_form = { ...this.state.savetolibrary_form };
        const form_element = { ...update_savetolibrary_form[identifier] };

        form_element.value = option.value;
        form_element.value_title = option.name;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_savetolibrary_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_savetolibrary_form) {
            is_form_valid = update_savetolibrary_form[identifier].valid && is_form_valid;
        }

        this.setState({ savetolibrary_form: update_savetolibrary_form });
    }

    inputChangeHandler(event, identifier) {
        const update_savetolibrary_form = { ...this.state.savetolibrary_form };
        const form_element = { ...update_savetolibrary_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_savetolibrary_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_savetolibrary_form) {
            is_form_valid = update_savetolibrary_form[identifier].valid && is_form_valid;
        }

        this.setState({ savetolibrary_form: update_savetolibrary_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_savetolibrary_form = this.state.savetolibrary_form;
        for (let key in update_savetolibrary_form) {
            let form_element = update_savetolibrary_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_savetolibrary_form[key] = form_element;
        }
        this.setState({ savetolibrary_form: update_savetolibrary_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.savetolibrary_form) {
                form_data[key] = this.state.savetolibrary_form[key].value;
            }
            this.props.SavetoLibrary(form_data);
        }
    };

    render() {
        const { savetolibrary_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    className="savetolibrary_modal"
                    show={this.props.savetolibrary_modal}
                    onHide={() => {
                        this.props.savetolibrary_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Save to Library</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100 assign-team-search" onSubmit={this.SubmitHandler}>
                            <div className="row mb-6">
                                <div className={"col-lg-12 fv-row mb-6" + (!savetolibrary_form.library_id.valid && savetolibrary_form.library_id.onBlur_out ? " error-field" : "")}>
                                    <label className="form-label fs-6 color1">{savetolibrary_form.library_id.label}</label>
                                    <DropdownButton className="clientdorpdown " title={savetolibrary_form.library_id.value_title}>
                                        {savetolibrary_form.library_id.options.map((option) => (
                                            <Dropdown.Item key={option.opk} eventKey={option.value} onClick={(event) => this.inputChangeHandlerDropdown(event, option, "library_id")}>
                                                {option.name}
                                            </Dropdown.Item>
                                        ))}
                                        <Dropdown.Item eventKey="0" className="add-client-btn text-gray-new" onClick={(event) => this.props.BidAddNewLibrary(event)}>
                                            <i className="fas fa-plus"></i> Add new section
                                        </Dropdown.Item>
                                    </DropdownButton>
                                    {!savetolibrary_form.library_id.valid && savetolibrary_form.library_id.onBlur_out ? <div className="error field-error">{savetolibrary_form.library_id.error_msg}</div> : ""}
                                </div>
                                <div className={"col-lg-12 fv-row mb-4" + (!savetolibrary_form.content_name.valid && savetolibrary_form.content_name.onBlur_out ? " error-field" : "")}>
                                    <label className="form-label fs-6">{savetolibrary_form.content_name.label}</label>
                                    <input
                                        type={savetolibrary_form.content_name.type}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={savetolibrary_form.content_name.placeholder}
                                        value={savetolibrary_form.content_name.value ? savetolibrary_form.content_name.value : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "content_name")}
                                        onBlur={(event) => {
                                            savetolibrary_form.content_name.onBlur_out = true;
                                            this.setState({
                                                savetolibrary_form: savetolibrary_form,
                                            });
                                        }}
                                    />
                                    {!savetolibrary_form.content_name.valid && savetolibrary_form.content_name.onBlur_out ? <div className="error field-error">{savetolibrary_form.content_name.error_msg}</div> : ""}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn cancel-butn rounded-50 w-150"
                                    onClick={() => {
                                        this.props.savetolibrary_modal_action(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary remove-user rounded-50 w-150 hvr-grow">
                                    Add
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default SavetoLibraryModal;