import * as Master_actionType from "../constants/MasteractionTypes";

const initial_state = {
    error: null,
    token: null,
    loader: false,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case Master_actionType.GetDashboardStart_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case Master_actionType.GetDashboardStart_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case Master_actionType.GetDashboardStart_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case Master_actionType.DashboardGreatJobs_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case Master_actionType.DashboardGreatJobs_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case Master_actionType.DashboardGreatJobs_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case Master_actionType.DashboardTasks_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case Master_actionType.DashboardTasks_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case Master_actionType.DashboardTasks_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case Master_actionType.DashboardRecentActivity_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case Master_actionType.DashboardRecentActivity_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case Master_actionType.DashboardRecentActivity_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case Master_actionType.GetBidActivity_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case Master_actionType.GetBidActivity_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case Master_actionType.GetBidActivity_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case Master_actionType.GetBidValues_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case Master_actionType.GetBidValues_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case Master_actionType.GetBidValues_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case Master_actionType.UpcomingBids_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case Master_actionType.UpcomingBids_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case Master_actionType.UpcomingBids_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case Master_actionType.GetBidRate_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case Master_actionType.GetBidRate_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case Master_actionType.GetBidRate_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case Master_actionType.GetLatestBidHQ_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case Master_actionType.GetLatestBidHQ_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case Master_actionType.GetLatestBidHQ_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        default:
            return state;
    }
};

export default reducer;