import React from "react";
import { Modal } from "react-bootstrap";
class DiscountCodesModal extends React.Component {
    SubmitHandler = (event) => {
        event.preventDefault();
        console.log("SubmitHandler---------");
    };
    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="discountcodes_modal bid-custom-modal "
                    show={this.props.discountcodes_modal}
                    onHide={() => {
                        this.props.discountcodes_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Discount code</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="d-flex flex-column">
                                <div className="row divide-colum">
                                    <div className="col-lg-12 right-colum pt-5">
                                        <div className="d-flex flex-column fv-row">
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-12 mb-4 fv-row">
                                                    <label className="form-label fs-6">Enter your code</label>
                                                    <input type="text" className="form-control form-control-lg form-control-solid" placeholder="Enter your code" value="NEWBIDDER10" />
                                                </div>
                                                <div className="col-sm-12 col-lg-12 mb-4 fv-row">
                                                    <label className="form-label fs-6">Discount (%)</label>
                                                    <input type="text" className="form-control form-control-lg form-control-solid" placeholder="Enter value" value="20%" />
                                                </div>
                                                <div className="col-lg-12 fv-row mg-bottom">
                                                    <label className="form-label">Status</label>
                                                    <select className="form-select form-select-solid">
                                                        <option value="">Select status</option>
                                                        <option value="1">Active</option>
                                                        <option value="2">Inactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn cancel-butn rounded-50 w-150"
                                                onClick={() => {
                                                    this.props.discountcodes_modal_action(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn light-btn-info btn-info rounded-50 px-10 hvr-grow">
                                                Add Discount Code
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default DiscountCodesModal;