import React from "react";
import { Modal } from "react-bootstrap";

import OtpInput from "../../../components/Utility/Otpinput";
import Validations from "../../../components/Utility/Validations";
class EnableTwoFactorOTPVerifyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            otpverify_form: {
                otp_token: {
                    label: "",
                    type: "otp",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
            },
        };
    }

    EnableTwoFactorOTPVerifyModalReload() {
        const update_otpverify_form = { ...this.state.otpverify_form };

        if (this.props.otp_token_validations === false) {
            update_otpverify_form.otp_token.onBlur_out = true;
            update_otpverify_form.otp_token.valid = false;
            update_otpverify_form.otp_token.error_msg = "Incorrect verification code.";
        } else {
            update_otpverify_form.otp_token.value = "";
            update_otpverify_form.otp_token.onBlur_out = true;
            update_otpverify_form.otp_token.valid = true;
            update_otpverify_form.otp_token.error_msg = "";
        }

        this.setState({
            otpverify_form: update_otpverify_form,
        });
    }

    inputChangeHandler(event, identifier) {
        const update_otpverify_form = { ...this.state.otpverify_form };
        const form_element = { ...update_otpverify_form[identifier] };

        if (form_element.type === "otp") {
            form_element.value = event;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_otpverify_form[identifier] = form_element;
        this.setState({ otpverify_form: update_otpverify_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_otpverify_form = this.state.otpverify_form;
        for (let key in update_otpverify_form) {
            let form_element = update_otpverify_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_otpverify_form[key] = form_element;
        }
        this.setState({ otpverify_form: update_otpverify_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.otpverify_form) {
                form_data[key] = this.state.otpverify_form[key].value;
            }
            this.props.EnableTwoFactorOTPVerify(form_data);
        }
    };

    render() {
        const { otpverify_form } = this.state;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                <Modal
                    className="enabletwofactorOTPverify_modal bid-custom-modal modal-error"
                    show={this.props.enabletwofactorOTPverify_modal}
                    onHide={() => {
                        this.props.enabletwofactoraccountverify_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Two-factor authentication</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
					 <p className="mb-7 text-center">Enter the authentification code sent to your email 
					 <span className="d-block">{user_info.user ? user_info.user.email : "-"}.</span></p>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="row mb-3">
                                <div className={"col-lg-12 fv-row" + (!otpverify_form.otp_token.valid && otpverify_form.otp_token.onBlur_out ? " error-field" : "")}>
                                    <label className="form-label fs-6">{otpverify_form.otp_token.label}</label>
                                    <div className="position-relative mb-2 justify-content-center otp-outer">
                                        <OtpInput
											numInputs={6}
											inputStyle="inputStyle custom_otpinput"
											value={otpverify_form.otp_token.value ? otpverify_form.otp_token.value : ""}
											onChange={(event) => this.inputChangeHandler(event, "otp_token")}
										/>
                                    </div>
                                    {!otpverify_form.otp_token.valid && otpverify_form.otp_token.onBlur_out ? <div className="error field-error">{otpverify_form.otp_token.error_msg}</div> : ""}
                                </div>
                            </div>
                            <div className="d-flex mb-5 justify-content-center">
                                <span
                                    className="resend-code cursor-pointer"
                                    onClick={(event) => {
                                        this.props.ResendTwoFactorOTPVerify(event);
                                    }}
                                >
                                    Resend code
                                </span>
                            </div>
                            <div className="modal-footer twofactor-footer">
                                <button
                                    type="button"
                                    className="btn cancel-butn rounded-50 w-150"
                                    onClick={() => {
                                        this.props.enabletwofactoraccountverify_modal_action(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary remove-user rounded-50 w-140px hvr-grow">
                                    Verify
                                </button>
                            </div>
                        </form>
						
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default EnableTwoFactorOTPVerifyModal;