import React from "react";
import { Modal } from "react-bootstrap";

class CompleteBidOnTheWayModal extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    className="completebid_ontheway_modal bid-custom-modal  "
                    show={this.props.completebid_ontheway_modal}
                    onHide={() => {
                        this.props.completebid_ontheway_modal_action(false);
                    }}
                >
                    <Modal.Body>
                        <div className="text-center m-0 py-4">
                            <img className="my-7" src="assets/images/icons/on-the-way.svg" alt="on-the-way" />
                            <h2 className="text-dark fw-bolder fs-22px">It’s on it’s way!</h2>
                            <p className="mb-5 fs-6">Your bid has been sent!</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default CompleteBidOnTheWayModal;