import React from "react";
import { Modal } from "react-bootstrap";

import Validations from "../../../components/Utility/Validations";
class DisableTwoFactorAccountVerifyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabletwofactor_form: {
                email: {
                    label: "Email",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "e.g example@mail.com",
                    validations: { required: true, email: true },
                },
                password: {
                    label: "Password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8 },
                },
            },
        };
    }

    DisableTwoFactorAccountVerifyModalReload() {
        const update_disabletwofactor_form = { ...this.state.disabletwofactor_form };
        console.log("update_disabletwofactor_form----------", update_disabletwofactor_form);
    }

    inputChangeHandler(event, identifier) {
        const update_disabletwofactor_form = { ...this.state.disabletwofactor_form };
        const form_element = { ...update_disabletwofactor_form[identifier] };

        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_disabletwofactor_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_disabletwofactor_form) {
            is_form_valid = update_disabletwofactor_form[identifier].valid && is_form_valid;
        }

        this.setState({
            disabletwofactor_form: update_disabletwofactor_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_disabletwofactor_form = this.state.disabletwofactor_form;
        for (let key in update_disabletwofactor_form) {
            let form_element = update_disabletwofactor_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_disabletwofactor_form[key] = form_element;
        }
        this.setState({ disabletwofactor_form: update_disabletwofactor_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.disabletwofactor_form) {
                form_data[key] = this.state.disabletwofactor_form[key].value;
            }
            this.props.DisableTwoFactorAccountVerify(form_data);
        }
    };

    render() {
        const { disabletwofactor_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    className="disabletwofactoraccountverify_modal bid-custom-modal"
                    show={this.props.disabletwofactoraccountverify_modal}
                    onHide={() => {
                        this.props.disabletwofactoraccountverify_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Disable two-factor authentication</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mb-5">To continue, please enter your password. This will disable two-factor authentification entirely.</p>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="row mb-4">
                                <div className={"col-lg-12 fv-row" + (!disabletwofactor_form.email.valid && disabletwofactor_form.email.onBlur_out ? " error-field" : "")}>
                                    <label className="form-label fs-6">{disabletwofactor_form.email.label}</label>
                                    <input
                                        type={disabletwofactor_form.email.type}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={disabletwofactor_form.email.placeholder}
                                        value={disabletwofactor_form.email.value ? disabletwofactor_form.email.value : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "email")}
                                        onBlur={(event) => {
                                            disabletwofactor_form.email.onBlur_out = true;
                                            this.setState({
                                                disabletwofactor_form: disabletwofactor_form,
                                            });
                                        }}
                                    />
                                    {!disabletwofactor_form.email.valid && disabletwofactor_form.email.onBlur_out ? <div className="error field-error">{disabletwofactor_form.email.error_msg}</div> : ""}
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className={"col-lg-12 fv-row" + (!disabletwofactor_form.password.valid && disabletwofactor_form.password.onBlur_out ? " error-field" : "")}>
                                    <label className="form-label fs-6">{disabletwofactor_form.password.label}</label>
                                    <div className="position-relative mb-9">
                                        <input
                                            type={this.state.password_show ? "text" : "password"}
                                            className="form-control form-control-lg form-control-solid"
                                            placeholder={disabletwofactor_form.password.placeholder}
                                            value={disabletwofactor_form.password.value ? disabletwofactor_form.password.value : ""}
                                            onChange={(event) => this.inputChangeHandler(event, "password")}
                                            onBlur={(event) => {
                                                disabletwofactor_form.password.onBlur_out = true;
                                                this.setState({
                                                    disabletwofactor_form: disabletwofactor_form,
                                                });
                                            }}
                                        />
										{!disabletwofactor_form.password.valid && disabletwofactor_form.password.onBlur_out ? <div className="error field-error">{disabletwofactor_form.password.error_msg}</div> : ""}
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="modal-footer twofactor-footer">
                                <button
                                    type="button"
                                    className="btn cancel-butn rounded-50 w-150"
                                    onClick={() => {
                                        this.props.disabletwofactoraccountverify_modal_action(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary remove-user rounded-50 w-140px hvr-grow">
                                    Disable
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default DisableTwoFactorAccountVerifyModal;