import React, { useState, useEffect, useCallback, useRef } from "react";

import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { notify } from "react-notify-toast";
import { render } from "@testing-library/react";
import { Link, useHistory } from "react-router-dom";

import ConfirmDialog from "../../components/Utility/ConfirmDialog";

import LibraryCaseStudyGridView from "./LibraryCaseStudyGridView";
import LibraryCaseStudyListView from "./LibraryCaseStudyListView";

import AddLibraryContentModal from "./modal/AddLibraryContentModal";
import LibraryContentRenameModal from "./modal/LibraryContentRenameModal";
import LibraryRightSideFilePreview from "./RightSideBar/LibraryRightSideFilePreview";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

const LibraryCaseStudy = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const ConfirmDialogEvent = useRef(null);

    const [library_detail, setlibrary_detail] = useState([]);
    const [library_contents, setlibrary_contents] = useState([]);
    const [librarycontent_search, setlibrarycontent_search] = useState(null);

    const [casestudey_preview, setcasestudey_preview] = useState("grid");
    const [library_content, setSelctedlibrarycontents] = useState("");
    const [addlibrarycontent_modal, setaddlibrarycontent_modal] = useState(false);
    const [librarycontentrename_modal, setlibrarycontentrename_modal] = useState(false);
    const [libraryrightsidebar_filepreview, setlibraryrightsidebar_filepreview] = useState(false);

    useEffect(() => {
        const onGetLibraryByID = () => {
            dispatch(userActions.GetLibraryByID(props.library_id)).then((response) => {
                setlibrary_detail(response.data);
            });
        };
        onGetLibraryByID();
    }, [dispatch, props.library_id]);

    const GetContentsByLibraryID = useCallback(() => {
        if (library_detail.library_id) {
            dispatch(userActions.GetContentsByLibraryID(library_detail.library_id, librarycontent_search)).then((response) => {
                if (response.success === true) {
                    setlibrary_contents(response.data);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    }, [dispatch, library_detail, librarycontent_search]);

    useEffect(() => {
        GetContentsByLibraryID();
    }, [GetContentsByLibraryID, library_detail, librarycontent_search]);

    const LibraryContentRename = (library_content) => {
        setSelctedlibrarycontents(library_content);
        setlibrarycontentrename_modal(true);
    };

    const UpdateLibraryContent = (form_data) => {
        props.onUpdateLibraryContent(form_data).then((response) => {
            if (response.success === true) {
                GetContentsByLibraryID();
                setlibrarycontentrename_modal(false);
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const LibraryContentDelete = (library_content) => {
        render(
            <ConfirmDialog
                title="Delete content?"
                body="Are you sure you want to delete this document? It’ll be gone permanently."
                confirm_modal={true}
                ref={ConfirmDialogEvent}
                confirmButtonText="Delete"
                onConfirm={(event) => ConfirmLibraryContentDelete(library_content)}
            />
        );
    };

    const ConfirmLibraryContentDelete = (library_content) => {
        props.onDeleteLibraryContent(library_content.content_id).then((response) => {
            if (response.success === true) {
                GetContentsByLibraryID();
                ConfirmDialogEvent.current.ModalActionHide();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const CreateLibraryContent = (form_data) => {
        props.onCreateLibraryContent(form_data).then((response) => {
            if (response.success === true) {
                setaddlibrarycontent_modal(false);
                GetContentsByLibraryID();
                notify.show("Content added successfully.", "success");
                setTimeout(function () {
                    history.push("/librarycontent-update/" + response.data.content_uuid);
                }, 1000);
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const LibraryContentMakeAsCopy = (library_content) => {
        const form_data = {};
        form_data["library_id"] = library_content.library_id;
        form_data["content_name"] = library_content.content_name + "-copy";
        form_data["description"] = library_content.description;
        props.onCreateLibraryContent(form_data).then((response) => {
            if (response.success === true) {
                notify.show("Content duplicated successfully.", "success");
                setTimeout(function () {
                    history.push("/librarycontent-update/" + response.data.content_uuid);
                }, 1000);
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    return (
        <React.Fragment>
            {props.loader ? <Loader /> : null}

            {addlibrarycontent_modal && (
                <AddLibraryContentModal
                    library_detail={library_detail}
                    addlibrarycontent_modal={addlibrarycontent_modal}
                    CreateLibraryContent={(form_data) => CreateLibraryContent(form_data)}
                    addlibrarycontent_modal_action={(modal_action) => {
                        setaddlibrarycontent_modal(modal_action);
                    }}
                />
            )}

            {librarycontentrename_modal && (
                <LibraryContentRenameModal
                    library_content={library_content}
                    librarycontentrename_modal={librarycontentrename_modal}
                    UpdateLibraryContent={(form_data) => UpdateLibraryContent(form_data)}
                    librarycontentrename_modal_action={(modal_action) => {
                        setlibrarycontentrename_modal(modal_action);
                    }}
                />
            )}

            <div className={"d-flex flex-column flex-root" + (libraryrightsidebar_filepreview ? " bid_rightsidebar_comment" : "")}>
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                        <LoginHeader page_title="Library" />

                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="l-content">
                                        <div className="container-xxl">
                                            <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap library-menu">
                                                    <li className="nav-item">
                                                        <Link to="/library" className="nav-link text-active-primary me-6">
                                                            Content
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <span className="nav-link text-active-primary me-6 active">{library_detail ? library_detail.name : ""}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="row">
                                                <div className="card mb-5 mb-xl-8">
                                                    <div className="card-header border-0 pt-5 mb-2 d-block">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                    <h3 className="card-title align-items-start flex-column">
                                                                        <span className="card-label fw-bolder fs-2x mb-1">{library_detail ? library_detail.name : ""}</span>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="d-flex align-items-center my-2 justify-content-end search-out">
                                                                    <div className="d-flex align-items-center position-relative me-4 library-search">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Search for content..."
                                                                            className="form-control form-control-sm ps-7 search-expand"
                                                                            onChange={(event) => {
                                                                                setlibrarycontent_search(event.target.value);
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <span
                                                                        className="btn-icon btn-listing me-4"
                                                                        onClick={() => {
                                                                            setcasestudey_preview(casestudey_preview === "grid" ? "list" : "grid");
                                                                        }}
                                                                    >
                                                                        {casestudey_preview === "grid" ? <i className="icon-list text-gray-new"></i> : <i className="icon-grid text-gray-new"></i>}
                                                                    </span>

                                                                    <button
                                                                        type="button"
                                                                        className="rounded-50 btn btn-primary btn-sm hvr-grow"
                                                                        onClick={(event) => {
                                                                            setaddlibrarycontent_modal(true);
                                                                        }}
                                                                    >
                                                                        Create new content
                                                                        <span className="svg-icon svg-icon-2">
                                                                            <img src="assets/images/icons/white-add.svg" title="Create new content" alt="Create new content" />
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {library_contents &&
                                                        library_contents.length !== 0 &&
                                                        (casestudey_preview === "grid" ? (
                                                            <LibraryCaseStudyGridView
                                                                library_contents={library_contents}
                                                                LibraryContentRename={(library_content) => LibraryContentRename(library_content)}
                                                                LibraryContentMakeAsCopy={(library_content) => LibraryContentMakeAsCopy(library_content)}
                                                                LibraryContentDelete={(library_content) => LibraryContentDelete(library_content)}
                                                                LibraryContentFilePreview={(library_content) => {
                                                                    setlibraryrightsidebar_filepreview(true);
                                                                    setSelctedlibrarycontents(library_content);
                                                                }}
                                                            />
                                                        ) : (
                                                            <LibraryCaseStudyListView
                                                                library_contents={library_contents}
                                                                LibraryContentRename={(library_content) => LibraryContentRename(library_content)}
                                                                LibraryContentMakeAsCopy={(library_content) => LibraryContentMakeAsCopy(library_content)}
                                                                LibraryContentDelete={(library_content) => LibraryContentDelete(library_content)}
                                                                LibraryContentFilePreview={(library_content) => {
                                                                    setlibraryrightsidebar_filepreview(true);
                                                                    setSelctedlibrarycontents(library_content);
                                                                }}
                                                            />
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right_sidebar_comment library-sidebar">
                                {libraryrightsidebar_filepreview && (
                                    <LibraryRightSideFilePreview
                                        library_content={library_content}
                                        library_rightsidebar_filepreview={libraryrightsidebar_filepreview}
                                        LibraryContentRename={(library_content) => {
                                            setlibraryrightsidebar_filepreview(false);
                                            LibraryContentRename(library_content);
                                        }}
                                        LibraryContentMakeAsCopy={(library_content) => {
                                            setlibraryrightsidebar_filepreview(false);
                                            LibraryContentMakeAsCopy(library_content);
                                        }}
                                        LibraryContentDelete={(library_content) => {
                                            setlibraryrightsidebar_filepreview(false);
                                            LibraryContentDelete(library_content);
                                        }}
                                        library_rightsidebar_filepreview_action={(modal_action) => {
                                            setlibraryrightsidebar_filepreview(modal_action);
                                        }}
                                    />
                                )}
                            </div>
                        </div>

                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetLibraryByID: userActions.GetLibraryByID,
    onGetContentsByLibraryID: userActions.GetContentsByLibraryID,
    onCreateLibraryContent: userActions.CreateLibraryContent,
    onUpdateLibraryContent: userActions.UpdateLibraryContent,
    onDeleteLibraryContent: userActions.DeleteLibraryContent,
};
export default connect(mapStateToProps, mapDispatchToProps)(LibraryCaseStudy);