import React from "react";

import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { Redirect } from "react-router-dom";

import Loader from "../../../components/Utility/Loader";
import Validations from "../../../components/Utility/Validations";
import LoginFooter from "../../../components/SuperAdminFooter";
import LoginHeader from "../../../components/SuperAdminHeader";
import LoginSidebar from "../../../components/SuperAdminSidebar";
import * as SuperAdminActions from "../../../actions/SuperAdmin/index";

class CompanyCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addcompany_form: {
                first_name: {
                    type: "text",
                    label: "First name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter first name",
                    validations: { required: true },
                },
                last_name: {
                    type: "text",
                    label: "Last name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Last name",
                    validations: { required: true },
                },
                email: {
                    label: "Email address",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "e.g example@mail.com",
                    validations: { required: true, email: true },
                },
                phone: {
                    type: "phone",
                    label: "Phone number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter phone number",
                    validations: { required: false },
                },
                password: {
                    label: "Password *",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter password",
                    validations: { required: true, min_length: 8 },
                },
                company_name: {
                    type: "text",
                    label: "Company name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter company name",
                    validations: { required: true },
                },
            },
        };
    }

    inputChangeHandler(event, identifier) {
        const update_addcompany_form = { ...this.state.addcompany_form };
        const form_element = { ...update_addcompany_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_addcompany_form[identifier] = form_element;

        this.setState({
            addcompany_form: update_addcompany_form,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_addcompany_form = this.state.addcompany_form;
        for (let key in update_addcompany_form) {
            let form_element = update_addcompany_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_addcompany_form[key] = form_element;
        }
        this.setState({ addcompany_form: update_addcompany_form });

        if (is_form_valid) {
            const form_data = new FormData();
            for (let key in this.state.addcompany_form) {
                form_data.append(key, this.state.addcompany_form[key].value);
            }

            this.props.onAddCompany(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/superadmin/company-lists",
                        });
                    }, 1000);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    render() {
        const { addcompany_form } = this.state;
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Add company" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="row">
                                            <div className="col-lg-11 col-xl-10">
                                                <div className="card mb-5 mb-xl-6">
                                                    <div className="card-header border-0 pt-5 mb-4">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder fs-2x mb-1">Add Company</span>
                                                        </h3>
                                                    </div>

                                                    <div className="card-body pt-0 pb-0">
                                                        <form className="form w-100" autoComplete="off" onSubmit={this.SubmitHandler}>
                                                            <div className="row mb-4">
                                                                <div className={"col-lg-6 fv-row" + (!addcompany_form.first_name.valid && addcompany_form.first_name.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{addcompany_form.first_name.label}</label>
                                                                    <input
                                                                        autoComplete="new_first_name"
                                                                        type={addcompany_form.first_name.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={addcompany_form.first_name.placeholder}
                                                                        value={addcompany_form.first_name.value ? addcompany_form.first_name.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "first_name")}
                                                                        onBlur={(event) => {
                                                                            addcompany_form.first_name.onBlur_out = true;
                                                                            this.setState({
                                                                                addcompany_form: addcompany_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!addcompany_form.first_name.valid && addcompany_form.first_name.onBlur_out ? <div className="error field-error">{addcompany_form.first_name.error_msg}</div> : ""}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!addcompany_form.last_name.valid && addcompany_form.last_name.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{addcompany_form.last_name.label}</label>
                                                                    <input
                                                                        autoComplete="new_last_name"
                                                                        type={addcompany_form.last_name.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={addcompany_form.last_name.placeholder}
                                                                        value={addcompany_form.last_name.value ? addcompany_form.last_name.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "last_name")}
                                                                        onBlur={(event) => {
                                                                            addcompany_form.last_name.onBlur_out = true;
                                                                            this.setState({
                                                                                addcompany_form: addcompany_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!addcompany_form.last_name.valid && addcompany_form.last_name.onBlur_out ? <div className="error field-error">{addcompany_form.last_name.error_msg}</div> : ""}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!addcompany_form.email.valid && addcompany_form.email.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{addcompany_form.email.label}</label>
                                                                    <input
                                                                        autoComplete="new_email"
                                                                        type={addcompany_form.email.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={addcompany_form.email.placeholder}
                                                                        value={addcompany_form.email.value ? addcompany_form.email.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                        onBlur={(event) => {
                                                                            addcompany_form.email.onBlur_out = true;
                                                                            this.setState({
                                                                                addcompany_form: addcompany_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!addcompany_form.email.valid && addcompany_form.email.onBlur_out ? <div className="error field-error">{addcompany_form.email.error_msg}</div> : ""}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!addcompany_form.phone.valid && addcompany_form.phone.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{addcompany_form.phone.label}</label>
                                                                    <input
                                                                        autoComplete="new_phone"
                                                                        type={addcompany_form.phone.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={addcompany_form.phone.placeholder}
                                                                        value={addcompany_form.phone.value ? addcompany_form.phone.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "phone")}
                                                                        onBlur={(event) => {
                                                                            addcompany_form.phone.onBlur_out = true;
                                                                            this.setState({
                                                                                addcompany_form: addcompany_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!addcompany_form.phone.valid && addcompany_form.phone.onBlur_out ? <div className="error field-error">{addcompany_form.phone.error_msg}</div> : ""}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!addcompany_form.password.valid && addcompany_form.password.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{addcompany_form.password.label}</label>
                                                                    <input
                                                                        autoComplete="new_password"
                                                                        type={addcompany_form.password.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={addcompany_form.password.placeholder}
                                                                        value={addcompany_form.password.value ? addcompany_form.password.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "password")}
                                                                        onBlur={(event) => {
                                                                            addcompany_form.password.onBlur_out = true;
                                                                            this.setState({
                                                                                addcompany_form: addcompany_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!addcompany_form.password.valid && addcompany_form.password.onBlur_out ? <div className="error field-error">{addcompany_form.password.error_msg}</div> : ""}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!addcompany_form.company_name.valid && addcompany_form.company_name.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{addcompany_form.company_name.label}</label>
                                                                    <input
                                                                        autoComplete="new_company_name"
                                                                        type={addcompany_form.company_name.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={addcompany_form.company_name.placeholder}
                                                                        value={addcompany_form.company_name.value ? addcompany_form.company_name.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "company_name")}
                                                                        onBlur={(event) => {
                                                                            addcompany_form.company_name.onBlur_out = true;
                                                                            this.setState({
                                                                                addcompany_form: addcompany_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!addcompany_form.company_name.valid && addcompany_form.company_name.onBlur_out ? <div className="error field-error">{addcompany_form.company_name.error_msg}</div> : ""}
                                                                </div>
                                                                <div className="col-sm-12 my-4 fv-row">
                                                                    <button type="submit" className="save-button hvr-grow btn btn-primary rounded-50 w-200" id="kt_account_profile_details_submit">
                                                                        Save changes
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="hover-new btn text-grey-dark rounded-50 w-150"
                                                                        onClick={(event) => {
                                                                            this.setState({
                                                                                isAuthenticated: true,
                                                                                redirect_url: "/superadmin/company-lists",
                                                                            });
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.superdmin_users.error,
        loader: state.superdmin_users.loader,
        isAuthenticated: state.superdmin_users.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onAddCompany: SuperAdminActions.AddCompany,
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreate);