import React, { useState } from "react";

import moment from "moment";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";

const CompanyUsersListItem = (props) => {
    const [user_name_show, setuser_name_show] = useState(true);
    const [user_uud_show, setuser_uud_show] = useState(true);
    const [company_name_show, setcompany_name_show] = useState(true);
    const [user_role_show, setuser_role_show] = useState(true);
    const [system_logs_show, setsystem_logs_show] = useState(true);
    const [user_status_show, setuser_status_show] = useState(true);

    const [columns, setcolumns] = useState([
        {
            hidden: true,
            text: "User id",
            dataField: "user_id",
            formatter: (cellContent, row, index, extraData) => {
                return row?.user_id;
            },
        },
        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },
            text: "Name",
            dataField: "user_name",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="d-flex align-items-center" key={`user_name_${row?.user_id}`}>
                        <div className="symbol symbol-45px me-5">
                            <img title={row?.first_name + " " + row?.last_name} alt={row?.first_name + " " + row?.last_name} src={row.avatar ? row.avatar : "assets/images/profile_dummy.jpg"} />
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                            <span className="text-dark fw-bolder mb-1 fs-6">{row?.first_name + " " + row?.last_name}</span>
                            <span className="text-muted text-hover-primary d-block fs-7">{row?.email}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            sort: false,
            text: "Phone",
            dataField: "phone",
            formatter: (cellContent, row, index, extraData) => {
                return row?.phone;
            },
        },
        {
            sort: false,
            text: "Company Name",
            dataField: "company_name",
            formatter: (cellContent, row, index, extraData) => {
                return row?.company?.company_name;
            },
        },
        {
            sort: false,
            text: "Role",
            dataField: "user_role",
            formatter: (cellContent, row, index, extraData) => {
                return row?.role?.user_role;
            },
        },
        {
            sort: true,
            onSort: (field, order) => {
                props.propsfiltersort(field);
                props.propsfilterorder(order);
            },
            text: "Last active",
            dataField: "system_logs",
            formatter: (cellContent, row, index, extraData) => {
                return row?.system_logs[0] ? moment(row.system_logs[row.system_logs.length - 1].created_date).fromNow() : "Never logged in";
            },
        },
        {
            sort: false,
            text: "Status",
            dataField: "user_status",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        <span className="svg-icon-5 me-1">{row.user_status ? <span className="user_status_active"></span> : <span className="user_status_inactive"></span>}</span>
                    </>
                );
            },
        },
        {
            sort: false,
            text: "",
            dataField: "action",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="dropdown" key={`company_action_${index}`}>
                        <div className="" aria-expanded="false" id={`company_action_${index}`} data-bs-toggle="dropdown">
                            <img src="assets/images/icons/more.svg" title="more" alt="more" />
                        </div>
                        <div className="dropdown-menu" aria-labelledby={`company_action_${index}`}>
                            <Link className="dropdown-item" to={`/superadmin/update-company-users/${row.user_uud}`}>
                                Edit User
                            </Link>
                            {row.user_status ? (
                                <span className="dropdown-item curser_pointer" onClick={(event) => props.ConfirmChangeUserStatus(row, false)}>
                                    Inactive User
                                </span>
                            ) : (
                                <span className="dropdown-item curser_pointer" onClick={(event) => props.ConfirmChangeUserStatus(row, true)}>
                                    Active User
                                </span>
                            )}
                            <span className="dropdown-item curser_pointer" onClick={(event) => props.ConfirmArchiveUser(row)}>
                                Delete User
                            </span>
                        </div>
                    </div>
                );
            },
        },
    ]);

    const onColumnToggle = (identifier, actions) => {
        let newTableColumns = columns.map((val) => {
            if (val.dataField === identifier) {
                val.hidden = actions;
            }
            return val;
        });
        setcolumns(newTableColumns);
    };

    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };

    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive ">
                        <BootstrapTable
                            remote
                            bootstrap4
                            striped
                            data={data}
                            keyField="user_id"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered table-row-bordered align-middle custom-table-style fs-8"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-end flex-wrap pt-3 pb-7 pagination-main">
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </>
            )}
        </PaginationProvider>
    );

    return (
        <React.Fragment>
            <div className="row align-items-center">
                <div className="col-md-4 company-items-label">
                    <div className="d-flex align-items-center position-relative search-bids">
                        <span className="svg-icon svg-icon-3 position-absolute me-2">
                            <img src="assets/images/icons/search.svg" title="Company user" alt="Company user" />
                        </span>
                        <input
                            type="text"
                            placeholder="Search for user..."
                            className="form-control form-control-sm ps-7"
                            onChange={(event) => {
                                props.propsfilterpage(1);
                                props.propsfiltersearch(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="d-flex align-items-center mt-8 justify-content-end flex-sm-wrap">
                        <button className="btn rounded-50 btn-white btn-sm me-4 text-center px-5 hvr-grow mb-sm-4" onClick={(event) => props.GetCompanyUsersExcel(event)}>
                            <i className="icon-download text-black p-0"></i>
                        </button>
                        <div className="me-4">
                            <div className="dropdown toggle-btn-dropdown column-drop-out mb-sm-4 mt-sm-0">
                                <div aria-expanded="false" id="columns_menu" data-bs-toggle="dropdown" className="btn rounded-50 btn-white btn-sm">
                                    <h6 className="text-dark m-0 fs-7">
                                        Columns <i className="icon-down-arrow text-dark fs-10 ms-1"></i>
                                    </h6>
                                </div>

                                <div className="dropdown-menu" aria-labelledby="columns_menu">
                                    <h6 className="fs-7 mb-3">Customise Fields</h6>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Name</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={user_name_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("user_name", user_name_show);
                                                            setuser_name_show(user_name_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">User ID</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={user_uud_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("user_uud", user_uud_show);
                                                            setuser_uud_show(user_uud_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Company Name</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={company_name_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("company_name", company_name_show);
                                                            setcompany_name_show(company_name_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Role</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={user_role_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("user_role", user_role_show);
                                                            setuser_role_show(user_role_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Last active</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={system_logs_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("system_logs", system_logs_show);
                                                            setsystem_logs_show(system_logs_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Status</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={user_status_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("user_status", user_status_show);
                                                            setuser_status_show(user_status_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className="btn rounded-50 btn-white fw-bold-600 btn-sm me-4 text-center px-5 hvr-grow fs-7 text-dark mb-sm-4" onClick={(event) => props.ChangeUserListType("invite_user")}>
                            View invites
                        </span>
                        <Link to={`/superadmin/add-company-users/${props?.company_id}`} className="rounded-50 btn btn-danger btn-md hvr-grow-arrow companies-right mb-sm-2">
                            Add individual
                            <span className="svg-icon svg-icon-2 ">
                                <img src="assets/images/icons/white-add.svg" title="all_bids" alt="all_bids" />
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="card-body p-0 bg-white radious-style radious-users  mt-5 user-table-new">
                <RemotePagination
                    data={props.companyusers}
                    columns={columns}
                    totalSize={props.companyuserscount}
                    order={props.filterorder}
                    dataField={props.filtersort}
                    page={props.filterpage}
                    sizePerPage={props.filterlimit}
                    onTableChange={handleTableChange}
                />
            </div>
        </React.Fragment>
    );
};

export default CompanyUsersListItem;