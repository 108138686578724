import React, { useState } from "react";

import moment from "moment";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";

const CompanyUsersItems = (props) => {
    const [columns] = useState([
        {
            hidden: true,
            text: "User Id",
            dataField: "user_id",
            formatter: (cellContent, row, index, extraData) => {
                return row?.user_id;
            },
        },
        {
            sort: false,
            text: "Name",
            dataField: "user_name",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="d-flex align-items-center">
                        <div className="symbol symbol-45px me-5">
                            <img title="user" alt="user" src={row.avatar ? row.avatar : "assets/images/profile_dummy.jpg"} onError={() => setimage()} />
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                            <span className="text-dark fw-bolder mb-1 fs-6">{row.first_name + " " + row.last_name}</span>
                            <span className="text-muted text-hover-primary d-block fs-7">{row.email}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            sort: false,
            text: "Phone",
            dataField: "phone",
            formatter: (cellContent, row, index, extraData) => {
                return row?.phone;
            },
        },
        {
            sort: false,
            text: "Role",
            dataField: "user_role",
            formatter: (cellContent, row, index, extraData) => {
                return row?.role?.user_role;
            },
        },
        {
            sort: false,
            text: "Last active",
            dataField: "system_logs",
            formatter: (cellContent, row, index, extraData) => {
                return row?.system_logs[0] ? moment(row.system_logs[row.system_logs.length - 1].created_date).fromNow() : "Never logged in";
            },
        },
        {
            sort: false,
            text: "",
            dataField: "invited_user_action",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="cursor-pointer" key={"invited_user_action_" + row.invite_id}>
                        <span className="btn" onClick={(event) => props.CompanyUserEdit(row)}>
                            <span className="svg-icon svg-icon-3">
                                <img src="assets/images/icons/edit.svg" title="edit" alt="edit" />
                            </span>
                        </span>
                        <span className="btn" onClick={(event) => props.CompanyUserRemove(row)}>
                            <span className="svg-icon svg-icon-3">
                                <img src="assets/images/icons/delete.svg" title="delete" alt="delete" />
                            </span>
                        </span>
                    </div>
                );
            },
        },
    ]);
    const handleTableChange = (type, { page, sizePerPage }) => {
        props.propsfilterpage(page);
    };

    const setimage = () => {
        console.log("image error")
    }

    const RemotePagination = ({ columns, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                page,
                totalSize,
                sizePerPage,
                custom: true,
                hidePageListOnlyOnePage: true,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive user-list-tables">
                        <BootstrapTable
                            remote
                            bootstrap4
                            striped
                            data={data}
                            keyField="user_id"
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder border-bottom-1"
                            classes="table align-middle custom-table-style"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-end flex-wrap pt-3 pb-7 pagination-main">
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </>
            )}
        </PaginationProvider>
    );
    return (
        <React.Fragment>
            <div className="card-body p-0 bg-white radious-style radious-users client-items-list mt-5">
                <RemotePagination data={props.company_users} columns={columns} page={props.filterpage} totalSize={props.company_userscount} sizePerPage={props.filterlimit} onTableChange={handleTableChange} />
            </div>
        </React.Fragment>
    );
};
export default CompanyUsersItems;