import React from "react";
import Notifications from "react-notify-toast";

import MyProfile from "../../components/Settings/MyProfile.js";
import Company from "../../components/Settings/Company.js";
import Billing from "../../components/Settings/Billing.js";
import BillingSubscription from "../../components/Settings/BillingSubscription.js";
import UsersList from "../../components/Settings/Users/UsersList";
import SettingsAccount from "../../components/Settings/SettingsAccount.js";
import AccessDenied from "../../containers/Page/AccessDenied.js";

class Settings extends React.Component {
    render() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        const renderview = () => {
            if (this.props.match.path === "/settings-myprofile") {
                return <MyProfile />;
            }

            if (this.props.match.path === "/settings-company") {
                if (user_info.user.role.user_role === "Account owner") {
                    return <Company />;
                } else {
                    return <AccessDenied />;
                }
            }

            if (this.props.match.path === "/settings-account") {
                return <SettingsAccount />;
            }

            if (this.props.match.path === "/settings-billing") {
                if (user_info.user.role.user_role === "Account owner") {
                    return <Billing />;
                } else {
                    return <AccessDenied />;
                }
            }

            if (this.props.match.path === "/settings-subscription") {
                if (user_info.user.role.user_role === "Account owner") {
                    return <BillingSubscription />;
                } else {
                    return <AccessDenied />;
                }
            }

            if (this.props.match.path === "/settings-users") {
                if (user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") {
                    return <UsersList />;
                } else {
                    return <AccessDenied />;
                }
            }
        };
        return (
            <React.Fragment>
                <Notifications options={{ zIndex: 99999 }} />
                {renderview()}
            </React.Fragment>
        );
    }
}
export default Settings;