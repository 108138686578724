import { React, useState, useEffect } from "react";
import WebViewer from "@pdftron/pdfjs-express-viewer";

const PdfDesignView = (props) => {
    const viewer = document.getElementById("viewer");
    const [viewerUrl, setViewerUrl] = useState("");

    useEffect(() => {
        if (props.documentUrl) {
            setViewerUrl(props.documentUrl);
        }
    }, [props.documentUrl]);

    useEffect(() => {
        if (viewerUrl) {
            WebViewer(
                {
                    initialDoc: viewerUrl,
                    path: process.env.REACT_APP_API_URL + "/pdfviewer",
                    licenseKey: process.env.REACT_APP_PDFTRON_LICENSEKEY,
                },
                viewer
            ).then(async (instance) => {
                const { docViewer } = instance;
                docViewer.getDocument(viewerUrl);

                // set pdf to full width of viewer
                instance.setFitMode(instance.FitMode.FitWidth);
            });
        }
    }, [viewer, viewerUrl]);

    return (
        <div className="webviewer_app">
            <div className="webviewer" id="viewer" style={{ height: "100vh" }}></div>
        </div>
    );
};

export default PdfDesignView;