import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";

import Validations from "../../../components/Utility/Validations";
import LoginHeader from "../../../components/SuperAdminHeader";
import LoginFooter from "../../../components/SuperAdminFooter";
import LoginSidebar from "../../../components/SuperAdminSidebar";

import * as SuperAdminActions from "../../../actions/SuperAdmin/index";
class MyProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            personal_form: {
                first_name: {
                    type: "text",
                    label: "First name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter first name",
                    validations: { required: true },
                },
                last_name: {
                    type: "text",
                    label: "Second name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter second name",
                    validations: { required: true },
                },
                email: {
                    label: "Email address",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "e.g example@mail.com",
                    validations: { required: true, email: true },
                },
            },

            password_form: {
                current_password: {
                    label: "Current password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8 },
                },
                password: {
                    label: "New password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, password_validation: true },
                },
                confirm_password: {
                    label: "Confirm password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, password_validation: true },
                },
            },

            current_password_show: false,
            password_show: false,
            confirm_password_show: false,

            loader: false,
            is_form_valid: false,
        };
    }

    componentDidMount() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        let personal_info = user_info.user;

        const update_personal_form = { ...this.state.personal_form };

        update_personal_form.first_name.value = personal_info.first_name;
        update_personal_form.last_name.value = personal_info.last_name;
        update_personal_form.email.value = personal_info.email;

        this.setState({ personal_form: update_personal_form });
    }

    PersonalChangeHandler(event, identifier) {
        const update_personal_form = { ...this.state.personal_form };
        const form_element = { ...update_personal_form[identifier] };

        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_personal_form[identifier] = form_element;

        this.setState({ personal_form: update_personal_form });
    }

    PersonalSubmitHandler = (event) => {
        event.preventDefault();

        // Check Validation
        let is_form_valid = true;
        const update_personal_form = this.state.personal_form;
        for (let key in update_personal_form) {
            let form_element = update_personal_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_personal_form[key] = form_element;
        }
        this.setState({ personal_form: update_personal_form });

        // Form submit handler
        if (is_form_valid) {
            const form_data = new FormData();
            for (let key in this.state.personal_form) {
                form_data.append(key, this.state.personal_form[key].value);
            }
            this.props.onUpdatePersonal(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");

                    let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                    user_info.user = response.user_info;
                    localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    PasswordChangeHandler(event, identifier) {
        const update_password_form = { ...this.state.password_form };
        const form_element = { ...update_password_form[identifier] };

        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_password_form[identifier] = form_element;

        this.setState({ password_form: update_password_form });
    }

    PasswordSubmitHandler = (event) => {
        event.preventDefault();

        // Check Validation
        let is_form_valid = true;
        const update_password_form = this.state.password_form;
        for (let key in update_password_form) {
            let form_element = update_password_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_password_form[key] = form_element;
        }
        this.setState({ password_form: update_password_form });

        // Form submit handler
        if (is_form_valid) {
            const form_data = new FormData();
            for (let key in this.state.password_form) {
                form_data.append(key, this.state.password_form[key].value);
            }
            this.props.onUpdatePassword(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    window.location.reload();
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    render() {
        const { personal_form, password_form, current_password_show, password_show, confirm_password_show } = this.state;
        return (
            <React.Fragment>
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                            <LoginHeader page_title="Settings" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container " className="container-xxl ">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/superadmin/settings-myprofile" className="nav-link text-active-primary me-6 active">
                                                        Account settings
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/superadmin/settings-users" className="nav-link text-active-primary me-6">
                                                        Users
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/superadmin/settings-pricing" className="nav-link text-active-primary me-6">
                                                        Pricing
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-11 col-xl-10">
                                                <div className="card mb-5 mb-xl-6">
                                                    <div className="card-header border-0 pt-5 mb-3">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder fs-2x mb-1">Personal details</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body pt-0 pb-0">
                                                        <form className="form w-100" onSubmit={this.PersonalSubmitHandler}>
                                                            <div className="row">
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!personal_form.first_name.valid && personal_form.first_name.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{personal_form.first_name.label}</label>
                                                                    <input
                                                                        type={personal_form.first_name.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={personal_form.first_name.placeholder}
                                                                        value={personal_form.first_name.value ? personal_form.first_name.value : ""}
                                                                        onChange={(event) => this.PersonalChangeHandler(event, "first_name")}
                                                                        onBlur={(event) => {
                                                                            personal_form.first_name.onBlur_out = true;
                                                                            this.setState({
                                                                                personal_form: personal_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!personal_form.first_name.valid && personal_form.first_name.onBlur_out && <div className="error field-error">{personal_form.first_name.error_msg}</div>}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!personal_form.last_name.valid && personal_form.last_name.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{personal_form.last_name.label}</label>
                                                                    <input
                                                                        type={personal_form.last_name.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={personal_form.last_name.placeholder}
                                                                        value={personal_form.last_name.value ? personal_form.last_name.value : ""}
                                                                        onChange={(event) => this.PersonalChangeHandler(event, "last_name")}
                                                                        onBlur={(event) => {
                                                                            personal_form.last_name.onBlur_out = true;
                                                                            this.setState({
                                                                                personal_form: personal_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!personal_form.first_name.valid && personal_form.first_name.onBlur_out && <div className="error field-error">{personal_form.first_name.error_msg}</div>}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className={"col-sm-6 col-lg-6 fv-row mb-4" + (!personal_form.email.valid && personal_form.email.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{personal_form.email.label}</label>
                                                                    <input
                                                                        readOnly
                                                                        type={personal_form.email.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={personal_form.email.placeholder}
                                                                        value={personal_form.email.value ? personal_form.email.value : ""}
                                                                        onChange={(event) => this.PersonalChangeHandler(event, "email")}
                                                                        onBlur={(event) => {
                                                                            personal_form.email.onBlur_out = true;
                                                                            this.setState({
                                                                                personal_form: personal_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!personal_form.first_name.valid && personal_form.first_name.onBlur_out && <div className="error field-error">{personal_form.first_name.error_msg}</div>}
                                                                </div>
                                                            </div>
                                                            <div className="card-footer d-flex pt-6">
                                                                <button type="submit" className="save-button hvr-grow btn btn-primary rounded-50 w-200">
                                                                    Save changes
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="card mb-5 mb-xl-6">
                                                    <div className="card-header border-0 pt-5 mb-4">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label text-dark fw-bolder fs-2x mb-1">Password</span>
                                                            <span className="text-gray-new mt-1 fw-normal fs-7">To update your password please enter your current password and then choose and confirm a new one.</span>
                                                        </h3>
                                                    </div>
                                                    <form className="form w-100" onSubmit={this.PasswordSubmitHandler}>
                                                        <div className="card-body pt-0 pb-0">
                                                            <div className="row mb-4">
                                                                <div className={"col-lg-6 fv-row" + (!password_form.current_password.valid && password_form.current_password.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{password_form.current_password.label}</label>
                                                                    <div className="position-relative">
                                                                        <input
                                                                            type={current_password_show ? "text" : "password"}
                                                                            className="form-control form-control-lg form-control-solid"
                                                                            placeholder={password_form.current_password.placeholder}
                                                                            value={password_form.current_password.value ? password_form.current_password.value : ""}
                                                                            onChange={(event) => this.PasswordChangeHandler(event, "current_password")}
                                                                            onBlur={(event) => {
                                                                                password_form.current_password.onBlur_out = true;
                                                                                this.setState({
                                                                                    password_form: password_form,
                                                                                });
                                                                            }}
                                                                        />
                                                                        <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                                            <i
                                                                                className={current_password_show ? "bi bi-eye fs-2" : "bi bi-eye-slash fs-2"}
                                                                                onClick={(event) => {
                                                                                    this.setState({ current_password_show: current_password_show ? false : true });
                                                                                }}
                                                                            ></i>
                                                                        </span>
                                                                    </div>
                                                                    {!password_form.current_password.valid && password_form.current_password.onBlur_out && <div className="error field-error">{password_form.current_password.error_msg}</div>}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!password_form.password.valid && password_form.password.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{password_form.password.label}</label>
                                                                    <div className="position-relative mb-2">
                                                                        <input
                                                                            type={this.state.password_show ? "text" : "password"}
                                                                            className="form-control form-control-lg form-control-solid"
                                                                            placeholder={password_form.password.placeholder}
                                                                            value={password_form.password.value ? password_form.password.value : ""}
                                                                            onChange={(event) => this.PasswordChangeHandler(event, "password")}
                                                                            onBlur={(event) => {
                                                                                password_form.password.onBlur_out = true;
                                                                                this.setState({
                                                                                    password_form: password_form,
                                                                                });
                                                                            }}
                                                                        />
                                                                        <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                                            <i
                                                                                className={password_show ? "bi bi-eye fs-2" : "bi bi-eye-slash fs-2"}
                                                                                onClick={(event) => {
                                                                                    this.setState({ password_show: password_show ? false : true });
                                                                                }}
                                                                            ></i>
                                                                        </span>
                                                                    </div>
                                                                    {!password_form.password.valid && password_form.password.onBlur_out && <div className="error field-error">{password_form.password.error_msg}</div>}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!password_form.confirm_password.valid && password_form.confirm_password.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{password_form.confirm_password.label}</label>
                                                                    <div className="position-relative mb-2">
                                                                        <input
                                                                            type={this.state.confirm_password_show ? "text" : "password"}
                                                                            className="form-control form-control-lg form-control-solid"
                                                                            placeholder={password_form.confirm_password.placeholder}
                                                                            value={password_form.confirm_password.value ? password_form.confirm_password.value : ""}
                                                                            onChange={(event) => this.PasswordChangeHandler(event, "confirm_password")}
                                                                            onBlur={(event) => {
                                                                                password_form.confirm_password.onBlur_out = true;
                                                                                this.setState({
                                                                                    password_form: password_form,
                                                                                });
                                                                            }}
                                                                        />
                                                                        <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                                            <i
                                                                                className={confirm_password_show ? "bi bi-eye fs-2" : "bi bi-eye-slash fs-2"}
                                                                                onClick={(event) => {
                                                                                    this.setState({ confirm_password_show: confirm_password_show ? false : true });
                                                                                }}
                                                                            ></i>
                                                                        </span>
                                                                    </div>
                                                                    {!password_form.confirm_password.valid && password_form.confirm_password.onBlur_out && <div className="error field-error">{password_form.confirm_password.error_msg}</div>}
                                                                </div>
                                                            </div>
                                                            <div className="card-footer d-flex pt-0">
                                                                <button type="submit" className="save-button hvr-grow btn btn-primary rounded-50 w-200">
                                                                    Save changes
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onUpdatePersonal: SuperAdminActions.UpdatePersonal,
    onUpdatePassword: SuperAdminActions.UpdatePassword,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);