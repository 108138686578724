import React from "react";
import { Modal } from "react-bootstrap";
class BillingUpgradeSuccessfulModal extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    className="billingupgradesuccessful_modal bid-custom-modal "
                    show={this.props.billingupgradesuccessful_modal}
                    onHide={() => {
                        this.props.billingupgradesuccessful_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="auth-main text-center m-0">
                            <img src="assets/images/icons/payment-done.svg" alt="payment-done" title="payment-done" />
                            <h2 className="fw-bold-700 text-success mt-7 fs-2x">Upgrade successful!</h2>
                            <p className="mb-5">Thanks for upgrading! We can't wait to help you win more business, more often.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default BillingUpgradeSuccessfulModal;