import React from "react";
import { Link, useHistory } from "react-router-dom";

import * as userActions from "../../actions/index";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

const AccessDenied = (props) => {
    const history = useHistory();
    const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
    return (
        <React.Fragment>
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                        <LoginHeader page_title={userActions.GreetingOfTheData() + ", " + user_info.user.first_name + " " + user_info.user.last_name + "!"} />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div className="container-xxl">
                                    <div className="error-notify d-flex justify-content-center flex-column align-items-center">
                                        <img className="img-fluid mb-6 h-350px" src="assets/images/sorry-image.svg" alt="sorry-access-banner" title="sorry-access-banner" />
                                        <h2 className="text-dark fw-bolder fs-2tx">Oops! We can't let you in here.</h2>
                                        <p className="fs-base mb-12">Please ask your account administrator for access.</p>
                                        <div className="d-flex">
                                            <span
                                                className=" hvr-grow btn rounded-50 btn-lg btn-outline-primary me-4"
                                                onClick={(event) => {
                                                    history.goBack();
                                                }}
                                            >
                                                <span className="indicator-label">Go back </span>
                                            </span>
                                            <Link to="/dashboard" className=" hvr-grow btn rounded-50 btn-lg btn-primary">
                                                <span className="indicator-label">Go to Dashboard </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AccessDenied;