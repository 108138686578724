import React, { useState } from "react";

import WysiwygEditor from "../../../components/Utility/WysiwygEditor";

const BidTypeCreateContentSection = (props) => {
    const [content_section, setcontent_section] = useState(props?.content_section);

    return (
        <React.Fragment>
            <div className="card mb-5 mb-xl-6" key={`content_section_index_${props.content_section_index}`}>
                <div className="card-header border-0 pt-8 mb-4">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <img className="mt-1 align-self-start" src="assets/images/icons/c-info.svg" title="all_bids" alt="all_bids" />
                        <h3 className="card-title align-items-start flex-column ms-4 mt-0">
                            <span className="card-label fw-bolder fs-22px mb-1 color1">Name of content section</span>
                        </h3>
                    </div>
                    <button type="button" className="btn-close" onClick={(event) => props.RemoveBidTypeContentSection(event, props.content_section_index)}></button>
                </div>

                <div className="card-body pt-0">
                    <div className="row mb-4">
                        <div className={"col-lg-12 fv-row mb-6" + (!content_section.title.valid && content_section.title.onBlur_out ? " error-field" : "")}>
                            <label className="form-label fs-6">{content_section.title.label}</label>
                            <input
                                type={content_section.title.type}
                                className="form-control form-control-lg form-control-solid"
                                placeholder={content_section.title.placeholder}
                                value={content_section.title.value ? content_section.title.value : ""}
                                onChange={(event) => props.inputChangeHandlerContentSection(event, props.content_section_index, "title")}
                                onBlur={(event) => {
                                    content_section.title.onBlur_out = true;
                                    setcontent_section(content_section);
                                }}
                            />
                            {!content_section.title.valid && content_section.title.onBlur_out && <div className="error field-error">{content_section.title.error_msg}</div>}
                        </div>

                        <div className="col-lg-12 fv-row mb-6">
                            <label className="form-label fs-6">{content_section.content.label}</label>
                            <WysiwygEditor
                                placeholder={content_section.content.placeholder}
                                value={content_section.content.value ? content_section.content.value : ""}
                                onChange={(event) => props.inputChangeHandlerWysiwygEditorContentSection(event, props.content_section_index, "content")}
                            />
                            {!content_section.content.valid && content_section.content.onBlur_out && <div className="error field-error">{content_section.content.error_msg}</div>}
                        </div>

                        <div className={"col-lg-12 fv-row mb-6" + (!content_section.bidtype_content_status.valid && content_section.bidtype_content_status.onBlur_out ? " error-field" : "")}>
                            <label className="form-label fs-6">{content_section.bidtype_content_status.label}</label>
                            <select
                                className="form-control form-control-lg form-control-solid"
                                value={content_section.bidtype_content_status.value}
                                onChange={(event) => props.inputChangeHandlerContentSection(event, props.content_section_index, "bidtype_content_status")}
                            >
                                {content_section.bidtype_content_status.options.map((option) => (
                                    <option className="one" value={option.value} key={option.opk}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                            {!content_section.bidtype_content_status.valid && content_section.bidtype_content_status.onBlur_out && <div className="error field-error">{content_section.bidtype_content_status.error_msg}</div>}
                        </div>

                        <div className={"col-lg-12 fv-row mb-6" + (!content_section.is_pricing.valid && content_section.is_pricing.onBlur_out ? " error-field" : "")}>
                            <label className="form-label fs-6">{content_section.is_pricing.label}</label>
                            <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`is_pricing_${props.content_section_index}`}
                                    value={content_section.is_pricing.value}
                                    checked={content_section.is_pricing.value}
                                    onChange={(event) => props.inputChangeHandlerContentSection(event, props.content_section_index, "is_pricing")}
                                />
                                <label className="form-check-label" htmlFor={`is_pricing_${props.content_section_index}`}></label>
                            </div>
                            {!content_section.is_pricing.valid && content_section.is_pricing.onBlur_out && <div className="error field-error">{content_section.is_pricing.error_msg}</div>}
                        </div>

                        <div className="col-lg-12 fv-row mb-6">
                            <label className="form-label fs-6">{content_section.demo_video_description.label}</label>
                            <WysiwygEditor
                                placeholder={content_section.demo_video_description.placeholder}
                                value={content_section.demo_video_description.value ? content_section.demo_video_description.value : ""}
                                onChange={(event) => props.inputChangeHandlerWysiwygEditorContentSection(event, props.content_section_index, "demo_video_description")}
                            />
                            {!content_section.demo_video_description.valid && content_section.demo_video_description.onBlur_out && <div className="error field-error">{content_section.demo_video_description.error_msg}</div>}
                        </div>

                        <div className={"col-lg-12 fv-row mb-6" + (!content_section.demo_video.valid && content_section.demo_video.onBlur_out ? " error-field" : "")}>
                            <label className="form-label fs-6">{content_section.demo_video.label}</label>
                            <input
                                type={content_section.demo_video.type}
                                className="form-control form-control-lg form-control-solid"
                                placeholder={content_section.demo_video.placeholder}
                                value={content_section.demo_video.value ? content_section.demo_video.value : ""}
                                onChange={(event) => props.inputChangeHandlerContentSection(event, props.content_section_index, "demo_video")}
                                onBlur={(event) => {
                                    content_section.demo_video.onBlur_out = true;
                                    setcontent_section(content_section);
                                }}
                            />
                            {!content_section.demo_video.valid && content_section.demo_video.onBlur_out && <div className="error field-error">{content_section.demo_video.error_msg}</div>}
                        </div>

                        <div className="col-lg-12 fv-row mb-6">
                            <label className="form-label fs-6">{content_section.demo_content.label}</label>
                            <WysiwygEditor
                                placeholder={content_section.demo_content.placeholder}
                                value={content_section.demo_content.value ? content_section.demo_content.value : ""}
                                onChange={(event) => props.inputChangeHandlerWysiwygEditorContentSection(event, props.content_section_index, "demo_content")}
                            />
                            {!content_section.demo_content.valid && content_section.demo_content.onBlur_out && <div className="error field-error">{content_section.demo_content.error_msg}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default BidTypeCreateContentSection;