import axios from "axios";
import * as actionType from "../../constants/actionTypes";
import * as SuperAdminactionTypes from "../../constants/SuperAdminactionTypes";

let qs = require("qs");

/*** GetSuperadminDashboard Action ***/
export const GetSuperadminDashboardStart = () => {
    return {
        error: false,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetSuperadminDashboard_START,
    };
};
export const GetSuperadminDashboardSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetSuperadminDashboard_SUCCESS,
    };
};
export const GetSuperadminDashboardFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetSuperadminDashboard_FAIL,
    };
};

export const GetSuperadminDashboard = (fromDate, toDate) => {
    return (dispatch) => {
        dispatch(GetSuperadminDashboardStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetSuperadminDashboard`,
            params: {
                fromDate: fromDate,
                toDate: toDate,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetSuperadminDashboardSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetSuperadminDashboardFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

export const GetSuperadminDashboardExcel = (fromDate, toDate) => {
    return (dispatch) => {
        dispatch(GetSuperadminDashboardStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetSuperadminDashboardExcel`,
            params: {
                fromDate: fromDate,
                toDate: toDate,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetSuperadminDashboardSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetSuperadminDashboardFail("Something went wrong, Please try again."));
                return error;
            });
    };
};
