import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

let qs = require("qs");

/*** GetDashboardStart Action ***/
export const GetDashboardStartStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetDashboardStart_START,
    };
};
export const GetDashboardStartSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetDashboardStart_SUCCESS,
    };
};
export const GetDashboardStartFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetDashboardStart_FAIL,
    };
};
export const GetDashboardStart = () => {
    return (dispatch) => {
        dispatch(GetDashboardStartStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetDashboardStart`,
        })
            .then(function (response) {
                dispatch(GetDashboardStartSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetDashboardStartFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DashboardGreatJobs Action ***/
export const DashboardGreatJobsStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.DashboardGreatJobs_START,
    };
};
export const DashboardGreatJobsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DashboardGreatJobs_SUCCESS,
    };
};
export const DashboardGreatJobsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DashboardGreatJobs_FAIL,
    };
};
export const DashboardGreatJobs = () => {
    return (dispatch) => {
        dispatch(DashboardGreatJobsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/DashboardGreatJobs`,
        })
            .then(function (response) {
                dispatch(DashboardGreatJobsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DashboardGreatJobsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DashboardTasks Action ***/
export const DashboardTasksStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.DashboardTasks_START,
    };
};
export const DashboardTasksSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DashboardTasks_SUCCESS,
    };
};
export const DashboardTasksFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DashboardTasks_FAIL,
    };
};
export const DashboardTasks = () => {
    return (dispatch) => {
        dispatch(DashboardTasksStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/DashboardTasks`,
        })
            .then(function (response) {
                dispatch(DashboardTasksSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DashboardTasksFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DashboardRecentActivity Action ***/
export const DashboardRecentActivityStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.DashboardRecentActivity_START,
    };
};
export const DashboardRecentActivitySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DashboardRecentActivity_SUCCESS,
    };
};
export const DashboardRecentActivityFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DashboardRecentActivity_FAIL,
    };
};
export const DashboardRecentActivity = (limit) => {
    return (dispatch) => {
        dispatch(DashboardRecentActivityStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/DashboardRecentActivity`,
            params: { limit: limit },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(DashboardRecentActivitySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DashboardRecentActivityFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidActivity Action ***/
export const GetBidActivityStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetBidActivity_START,
    };
};
export const GetBidActivitySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetBidActivity_SUCCESS,
    };
};
export const GetBidActivityFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetBidActivity_FAIL,
    };
};
export const GetBidActivity = () => {
    return (dispatch) => {
        dispatch(GetBidActivityStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidActivity`,
        })
            .then(function (response) {
                dispatch(GetBidActivitySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidActivityFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidValues Action ***/
export const GetBidValuesStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetBidValues_START,
    };
};
export const GetBidValuesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetBidValues_SUCCESS,
    };
};
export const GetBidValuesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetBidValues_FAIL,
    };
};
export const GetBidValues = () => {
    return (dispatch) => {
        dispatch(GetBidValuesStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidValues`,
        })
            .then(function (response) {
                dispatch(GetBidValuesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidValuesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpcomingBids Action ***/
export const UpcomingBidsStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.UpcomingBids_START,
    };
};
export const UpcomingBidsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpcomingBids_SUCCESS,
    };
};
export const UpcomingBidsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpcomingBids_FAIL,
    };
};
export const UpcomingBids = () => {
    return (dispatch) => {
        dispatch(UpcomingBidsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/UpcomingBids`,
        })
            .then(function (response) {
                dispatch(UpcomingBidsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpcomingBidsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidRate Action ***/
export const GetBidRateStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetBidRate_START,
    };
};
export const GetBidRateSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetBidRate_SUCCESS,
    };
};
export const GetBidRateFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetBidRate_FAIL,
    };
};
export const GetBidRate = () => {
    return (dispatch) => {
        dispatch(GetBidRateStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidRate`,
        })
            .then(function (response) {
                dispatch(GetBidRateSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidRateFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetLatestBidHQ Action ***/
export const GetLatestBidHQStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetLatestBidHQ_START,
    };
};
export const GetLatestBidHQSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetLatestBidHQ_SUCCESS,
    };
};
export const GetLatestBidHQFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetLatestBidHQ_FAIL,
    };
};
export const GetLatestBidHQ = () => {
    return (dispatch) => {
        dispatch(GetLatestBidHQStart());

        return axios({
            method: "get",
            url: "https://bidhq.com.au/api/getlatestblogs.php",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(function (response) {
                dispatch(GetLatestBidHQSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetLatestBidHQFail("Something went wrong, Please try again."));
                return error;
            });
    };
};