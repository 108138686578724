import React from "react";
import { Link } from "react-router-dom";

// import exportAsImage from "../../components/Utility/exportAsImage";
class DashboardStart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboard_getstarts: [],
            dashboard_greatjobs_show: this.props.dashboard_welcomesection_show,
        };
    }

    componentDidMount() {
        this.setState({
            dashboard_getstarts: this.props.dashboard_getstarts,
        });
    }

    DashboardGreatJobsReload() {
        this.setState({
            dashboard_getstarts: this.props.dashboard_getstarts,
        });
    }

    render() {
        const { dashboard_greatjobs_show } = this.state;
        const dashboard_getstartsdata = this.props.dashboard_getstarts;
        return (
            <React.Fragment>
                <div className="col-xl-4 mob-none notify-activity">
                    <div className={"card card-bg-new  border-0 mb-lg-8" + (dashboard_greatjobs_show ? " card-xl-stretch jobs-main-dash " : "")}>
                        <div className="card-header border-0 py-5 justify-content-end job-dropdown">
                            <div className="card-toolbar m-0">
                                <div className="tab-none z-index-9">
                                    <button type="button" className="dot-butns btn btn-sm border-0 me-n3" id="dashboard_welcome" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="icon-dots text-grey-dark"></i>
                                    </button>
                                    <div className="dropdown-menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_welcome">
                                        {/**
                                        <div className="py-4 px-2 cursor-pointer link-hover">
                                            <div className="text-black" onClick={(event) => exportAsImage(this.dashboard_welcome_ref, "Great job")}>
                                                <i className="icon-download me-2 text-black"></i> <span>Download as image</span>
                                            </div>
                                        </div>
										**/}
                                        <div className="py-4 px-2 cursor-pointer link-hover">
                                            <div className="text-black" onClick={(event) => this.props.dashboard_welcomesection_show_action(dashboard_greatjobs_show ? false : true)}>
                                                <i className={"me-2 text-black" + (dashboard_greatjobs_show ? " icon-eye font-size-8" : " bi bi-eye")}></i>
                                                <span>{dashboard_greatjobs_show ? "Hide chart" : "Show chart"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-show z-index-9">
                                    {/**
                                    <div className="px-2 cursor-pointer">
                                        <div className="text-gray" onClick={(event) => exportAsImage(this.dashboard_welcome_ref, "Great job")}>
                                            <i className="icon-download text-gray"></i>
                                        </div>
                                    </div>
									**/}
                                    <div className="px-2 cursor-pointer link-hover">
                                        <div className="text-gray z-index-3" onClick={(event) => this.props.dashboard_welcomesection_show_action(dashboard_greatjobs_show ? false : true)}>
                                            <i className={"text-gray" + (dashboard_greatjobs_show ? " icon-eye font-size-8" : " bi bi-eye fs-4")}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"d-flex card-body  p-0 card-bg bgi-position-y-top flex-column " + (dashboard_greatjobs_show ? "" : " d-none")} ref={(node) => (this.dashboard_welcome_ref = node)}>
                            <div className="flex-center z-index-1 job-left text-center">
                                <img className="image-bg-main img-fluid d-xl-inline-block d-md-none" src="assets/images/main-dash.svg" alt="main-dash" />
                                <img className="image-bg-main img-fluid d-sm-none d-md-block d-xl-none" src="assets/images/dash-md.svg" alt="dash-md" />
                                <div className="z-index-1 box-one-title">
                                    <h3 className="text-center job-title mb-0 fw-bolder">Let's get started</h3>
                                </div>
                            </div>
                            <div className="card-p mt-new position-relative job-info get_start">
                                <div className="g-0">
                                    <ul className="fs-8 mt-18 mt-xl-18 mb-0 p-0 great-job-info-height scroll-y">
                                        {dashboard_getstartsdata.length !== 0 && (
                                            <>
                                                {(this.props.user_info.user.role.user_role === "Account owner" || this.props.user_info.user.role.user_role === "Bid Manager") && <li className="mb-2 no-tasks get_started_li">
                                                    {dashboard_getstartsdata.company_invite ? (
                                                        <div className="d-flex align-items-center">
                                                            <span className="get_started_span_tick">
                                                                <i className="icon-tick1 title_vector_won"></i>
                                                            </span>
                                                            Invite your team
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex">
                                                            <span className="get_started_span_count"> 1</span>
                                                            <Link to="/settings-users/?activesection=inviate-users" className="get_started_href_link">
                                                                Invite your team
                                                            </Link>
                                                        </div>
                                                    )}
                                                </li>}

                                                {(this.props.user_info.user.role.user_role === "Account owner" || this.props.user_info.user.role.user_role === "Bid Manager" || this.props.user_info.user.role.user_role === "Contributor") && <li className="mb-2 no-tasks get_started_li">
                                                    {dashboard_getstartsdata.company_library ? (
                                                        <div className="d-flex align-items-center">
                                                            <span className="get_started_span_tick">
                                                                <i className="icon-tick1 title_vector_won"></i>
                                                            </span>
                                                            Add content to your library
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex">
                                                            <span className="get_started_span_count"> {this.props.user_info.user.role.user_role === "Contributor" ? '1': '2'}</span>
                                                            <Link to="/library" className="get_started_href_link">
                                                                Add content to your library
                                                            </Link>
                                                        </div>
                                                    )}
                                                </li>}

                                                {(this.props.user_info.user.role.user_role === "Account owner" || this.props.user_info.user.role.user_role === "Bid Manager") && <li className="mb-2 no-tasks get_started_li">
                                                    {dashboard_getstartsdata.company_bid ? (
                                                        <div className="d-flex align-items-center">
                                                            <span className="get_started_span_tick">
                                                                <i className="icon-tick1 title_vector_won"></i>
                                                            </span>
                                                            Start writing bids
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex">
                                                            <span className="get_started_span_count"> 3</span>
                                                            <Link to="/bid-create" className="get_started_href_link">
                                                                Start writing bids
                                                            </Link>
                                                        </div>
                                                    )}
                                                </li>}
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default DashboardStart;