import React from "react";
import { Modal } from "react-bootstrap";
class disabletwofactoraccountconfirmationModal extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    className="disabletwofactoraccountconfirmation_modal bid-custom-modal "
                    show={this.props.disabletwofactoraccountconfirmation_modal}
                    onHide={() => {
                        this.props.disabletwofactoraccountconfirmation_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Disable two-factor authentication</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="auth-main text-center">
                            <i className="icon-tick1 text-grey-dark fs-4x"></i>
                            <h5 className="mb-9 text-grey-dark">Two-factor authentification disabled.</h5>
                        </div>
                        <div className="modal-footer twofactor-footer">
                            <button
                                type="submit"
                                className="btn btn-primary remove-user rounded-50 w-140px hvr-grow"
                                onClick={() => {
                                    this.props.disabletwofactoraccountconfirmation_modal_action(false);
                                }}
                            >
                                Done
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default disabletwofactoraccountconfirmationModal;