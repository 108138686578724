import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));

const HtmlExportToPdf = async (element, imageFileName, pdf_details) => {
    const html = document.getElementsByTagName("html")[0];
    const body = document.getElementsByTagName("body")[0];

    let htmlWidth = html.clientWidth;
    let bodyWidth = body.clientWidth;

    const newWidth = element.scrollWidth - element.clientWidth;

    if (newWidth > element.clientWidth) {
        htmlWidth += newWidth;
        bodyWidth += newWidth;
    }

    html.style.width = htmlWidth + "px";
    body.style.width = bodyWidth + "px";

    var targetComponent = element.current || element;
    const canvas = await html2canvas(targetComponent);

    const image = canvas.toDataURL("image/png", 2.0);
    ConvertImageToPdf(image, imageFileName, pdf_details);

    html.style.width = null;
    body.style.width = null;
};

const ConvertImageToPdf = (imgData, fileName, pdf_details) => {
    var doc = new jsPDF();
    doc.setFontSize(11);
    doc.addFont("Lexend.ttf", "Lexend", "bold");
    doc.setTextColor(25, 25, 100);

    doc.text(110, 10, "Reporting", "center");
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(8);
    doc.text(10, 15, "Company: " + user_info?.company?.company_name);
    doc.text(10, 20, "Showing Data for: " + pdf_details.daterangepicker_label);
    doc.addImage(imgData, "PNG", 10, 40, 200, 85);

    doc.save(fileName);
};

export default HtmlExportToPdf;
