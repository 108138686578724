import React from "react";
class BidDesignPreviewFontPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            font_options: [],
        };
    }

    componentDidMount() {
        let designpreviewpdf_form = this.props.designpreviewpdf_form;
        this.setState({
            font_options: designpreviewpdf_form.bidpdf_fonts.options,
        });
    }

    render() {
        const { font_options } = this.state;
        return (
            <React.Fragment>
                <div className="wrap-right-info bid_designpreview_fontpicker_wrapinfo">
				<div className="card-toolbar py-4 design-border align-items-center d-flex justify-content-center">
                        <h4 className="text-dark-blue m-0 fs-8">Design</h4>
                    </div>
					<div className="scroll-y right-scroll-height">
                    <div
                        className="p-4 pb-0  re-upload text-gray-new curser_pointer"
                        onClick={(event) => {
                            this.props.fontpicker_action(false);
                        }}
                    >
                        <i className="icon-left-arrow me-2"></i> Back
                    </div>
                    <div className="card-toolbar p-4 ">
                        <h6 className="text-dark-blue fs-7 mb-3">
                            <i className="icon-Text text-dark-blue fs-4"></i> Fonts
                        </h6>
                        <p className="text-grey-dark fs-8 m-0">Pick a font pairing to achive the typography style for your copy.</p>
                    </div>
                    <div className="font_option_section m-4 mt-0">
                        {font_options.length !== 0 &&
                            font_options.map((font_option, font_key) => (
                                <div
									className={"border border-gray-300 logo-design p-4 mb-3 "+font_option?.font_classname}
									key={font_option.opk}
								>								
                                    <div
                                        className="curser_pointer"
                                        onClick={(event) => {
                                            this.props.BidFontSelect(font_option);
                                        }}
                                    >
                                        <span className="text-gray fw-bold-600 fs-8">Title font:</span>
                                        <h6 className="fs-7">{font_option.name}</h6>
                                        <span className="text-gray fw-bold-600 fs-8">Paragraph font:</span>
                                        <p className="m-0 fs-8">{font_option.sub}</p>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
				</div>
            </React.Fragment>
        );
    }
}
export default BidDesignPreviewFontPicker;