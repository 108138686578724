import React, { useState } from "react";

import moment from "moment";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
const LibraryCaseStudyListView = (props) => {
    const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
    const [columns] = useState([
        {
            text: "Content name",
            dataField: "content_name",
        },
        {
            text: "Last modified",
            dataField: "updated_date",
            formatter: (cellContent, row, index, extraData) => {
                return row.updated_date ? moment(row.updated_date).format("DD MMM YYYY") : "-";
            },
        },
        {
            text: "Modified by",
            dataField: "modified_by",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        <div className="symbol-group symbol-hover" key={"row_" + row.content_id + "modified_by"}>
                            {row.modified_by &&
                                row.modified_by.map((modified_by) => (
                                    <div className="custom-tooltip" key={"modified_by_" + modified_by.user_id}>
                                        <div className="symbol symbol-25px symbol-circle">
                                            {modified_by && modified_by.avatar ? (
                                                <span className="symbol_img symbol-label">
                                                    <img src={modified_by.avatar} alt="bid_owner_avatar" title="bid_owner_avatar" />
                                                    <span className="custom-tooltip-text">{modified_by.first_name + " " + modified_by.last_name}</span>
                                                </span>
                                            ) : (
                                                <span className="symbol-label bg-primary text-inverse-primary">
                                                    {modified_by.first_name?.slice(0, 1) + modified_by.last_name?.slice(0, 1)}
                                                    <span className="custom-tooltip-text">{modified_by.first_name + " " + modified_by.last_name}</span>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </>
                );
            },
        },
        {
            text: "Last used in bid",
            dataField: "lastused_inbid",
            formatter: (cellContent, row, index, extraData) => {
                return row.lastused_inbid ? moment(row.lastused_inbid).format("DD MMM YYYY") : "-";
            },
        },
        {
            text: "Times used",
            dataField: "overall_timeused",
        },
        {
            text: "Date created",
            dataField: "created_date",
            formatter: (cellContent, row, index, extraData) => {
                return row.created_date ? moment(row.created_date).format("DD MMM YYYY") : "-";
            },
        },
        {
            text: "Owner",
            dataField: "created_user",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="custom-tooltip">
                        <div className="symbol symbol-25px symbol-circle">
                            <span className="symbol-label bg-primary text-inverse-primary">
                                {row.created_user ? row.created_user.first_name?.slice(0, 1) + row.created_user.last_name?.slice(0, 1) : ""}
                                <span className="custom-tooltip-text ">{row.created_user ? row.created_user.first_name + " " + row.created_user.last_name : ""}</span>
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            text: "",
            dataField: "library_action",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="dropdown library-list-table" key="1">
                        <div className="cursor-pointer" aria-expanded="false" id="1" data-bs-toggle="dropdown">
                            <i className="icon-vertical-dots color1"></i>
                        </div>
                        <div className="dropdown-menu cursor-pointer" aria-labelledby="1">
                            <Link to={"/librarycontent-update/" + row.content_uuid} className="dropdown-item">
                                <i className="fa fa-eye text-black me-3" aria-hidden="true"></i> View
                            </Link>
                            {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                <span className="dropdown-item" onClick={(event) => props.LibraryContentRename(row)}>
                                    <i className="icon-edit text-black me-3" aria-hidden="true"></i> Rename
                                </span>
                            )}
                            <span className="dropdown-item" onClick={(event) => props.LibraryContentMakeAsCopy(row)}>
                                <i className="icon-copy text-black me-3" aria-hidden="true"></i> Make a copy
                            </span>
                            {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                <span
                                    className="dropdown-item"
                                    onClick={(event) => {
                                        props.LibraryContentDelete(row);
                                    }}
                                >
                                    <i className="icon-delete text-black me-3" aria-hidden="true"></i> Delete
                                </span>
                            )}
                            <span
                                className="dropdown-item"
                                onClick={(event) => {
                                    props.LibraryContentFilePreview(row);
                                }}
                            >
                                <i className="icon-File text-black me-3" aria-hidden="true"></i> File info
                            </span>
                        </div>
                    </div>
                );
            },
        },
    ]);

    return (
        <div className="card-body pt-0">
            <div className="card-body p-0 bg-white radious-style radious-users mt-4 library-grid-list">
                <div className="table-responsive">
                    <BootstrapTable keyField="content_name" data={props.library_contents} columns={columns} />
                </div>
            </div>
        </div>
    );
};
export default LibraryCaseStudyListView;