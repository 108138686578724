import React from "react";
import moment from "moment";

import WysiwygEditorComment from "../../../../components/Utility/WysiwygEditorComment";
class BidRightSideComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parent_id: "",
            bid_comment: "",

            tag_users: [],
            comment_text: "",
        };
    }

    componentDidMount() {
        if (this.props.bid_comment !== undefined) {
            this.setState({
                tag_users: [],
                comment_text: "",

                bid_comment: this.props.bid_comment,
                parent_id: this.props.bid_comment.comment.comment_id,
            });
        } else {
            this.setState({
                parent_id: "",
                bid_comment: "",

                tag_users: [],
                comment_text: "",
            });
        }
    }

    BidRightSideCommentReload() {
        if (this.props.bid_comment !== undefined) {
            this.setState({
                tag_users: [],
                comment_text: "",

                bid_comment: this.props.bid_comment,
                parent_id: this.props.bid_comment.comment.comment_id,
            });
        } else {
            this.setState({
                parent_id: "",
                bid_comment: "",

                tag_users: [],
                comment_text: "",
            });
        }
    }

    inputChangeHandler(event, identifier) {
        this.setState({ comment_text: event });
    }

    SubmitHandler = (event) => {
        event.preventDefault();
        this.props.BidCreateComment(this.state.comment_text, this.state.parent_id, this.state.tag_users);
    };

    render() {
        const { bid_comment } = this.state;
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        return (
            <React.Fragment>
                <div className="wrap-right-info right-comment bid_rightsidebar_comment">
                    <div
                        className="comment-close-button"
                        onClick={() => {
                            this.props.bid_create_rightsidebar_comment_action(false);
                        }}
                    >
                        <i className="icon-cross-2" aria-hidden="true"></i>
                    </div>
                    <div className="card-toolbar m-0">
                        <div className="card-header border-0 pt-5">
                            <h2 className="card-title align-items-start flex-column fw-bolder">{bid_comment ? "Comment" : "Add new comment"}</h2>
                        </div>
                        {bid_comment && (
                            <div className="comment_section ">
                                <div className="original_comment_section p-5">
                                    <h6 className="text-grey-dark fs-8 mb-3">ORIGINAL COMMENT</h6>
                                    <div className="d-flex flex-column flex-row-fluid">
                                        <div className="d-flex align-items-center flex-wrap mb-1">
                                            <span className="text-hover-primary fw-bolder me-2 fs-14">
                                                {bid_comment.comment.user.first_name} {bid_comment.comment.user.last_name}
                                            </span>
                                            <span className="time-date-wrap fs-14">{moment(bid_comment.comment.comment_date).format("DD/MM/YYYY")}</span>
                                        </div>
                                        <span className=" fs-14 fw-normal">{renderHTML(bid_comment.comment.comment_text)}</span>
                                    </div>
                                </div>
                                <div className="reply_comment_section p-5">
                                    <h6 className="text-grey-dark fs-8 mb-3">{bid_comment.comment.reply_count} REPLIES</h6>
                                    {bid_comment.child &&
                                        bid_comment.child.map((comment) => {
                                            return (
                                                <div className="d-flex flex-column flex-row-fluid" key={"reply_comment_" + comment.comment_id}>
                                                    <div className="d-flex align-items-center flex-wrap mb-1">
                                                        <span className=" text-hover-primary fw-bolder me-2 fs-14">
                                                            {comment.user.first_name} {comment.user.last_name}
                                                        </span>
                                                        <span className=" time-date-wrap fs-14">{moment(comment.comment_date).format("DD/MM/YYYY")}</span>
                                                    </div>
                                                    <span className=" fs-14 fw-normal pb-2">{renderHTML(comment.comment_text)}</span>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        )}
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="card-body pt-0">
                                <div className="row mt-4">
                                    <WysiwygEditorComment
                                        tagusers={this.props.bid_users}
                                        value={this.state.comment_text}
                                        BidCommentUpdateTagUsers={(user_detail) => {
                                            let tag_users = this.state.tag_users;
                                            tag_users.push(user_detail.user_id);
                                            this.setState({ tag_users: tag_users });
                                        }}
                                        onChange={(event) => {
                                            this.inputChangeHandler(event, "comment_text");
                                        }}
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary rounded-50 w-200 mt-4 hvr-grow">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default BidRightSideComment;