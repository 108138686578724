import React from "react";
import { Route, Switch } from "react-router-dom";

import Test from "./containers/Page/Test";
import NotFound from "./containers/Page/NotFound";
import Invitation from "./containers/Page/Invitation";
import Authentication from "./containers/Page/Authentication";
import PrivacyPolicy from "./containers/Page/PrivacyPolicy";
import TermsConditions from "./containers/Page/TermsConditions";

/*** Application imports ***/
import signin from "./containers/Auth/signin";
import subscription from "./containers/Auth/subscription";
import payment from "./containers/Auth/payment";
import verification from "./containers/Auth/verification";
import signup from "./containers/Auth/signup";
import signupcompany from "./containers/Auth/signupcompany";
import logout from "./containers/Auth/logout";
import ForgotPassword from "./containers/Auth/ForgotPassword";
import ResetPassword from "./containers/Auth/ResetPassword";
import Dashboard from "./containers/Application/Dashboard";
import Settings from "./containers/Application/Settings";
import Bid from "./containers/Application/Bid";
import MyTasks from "./containers/Application/MyTasks";
import Clients from "./containers/Application/Clients";
import Library from "./containers/Application/Library";
import Reporting from "./containers/Application/Reporting";
import UserNotifications from "./containers/Application/UserNotifications";

/*** SuperAdmin Imports*/
import SuperAdminDashboard from "./containers/SuperAdmin/Dashboard";
import SuperAdminSettings from "./containers/SuperAdmin/Settings";
import SuperAdminSales from "./containers/SuperAdmin/Sales";
import SuperAdminUsers from "./containers/SuperAdmin/Users";
import SuperAdminManageContent from "./containers/SuperAdmin/ManageContent";
import SuperAdminReporting from "./containers/SuperAdmin/Reporting";

import AuthRouter from "./AuthRouter";
import ProtectedRouter from "./ProtectedRouters";
import SuperAdminRouter from "./SuperAdminRouter";

const Router = (props) => (
    <Switch>
        {/***************** SuperAdmin Route *****************/}
        <SuperAdminRouter path="/superadmin" exact component={SuperAdminDashboard} />
        <SuperAdminRouter path="/superadmin/dashboard" exact component={SuperAdminDashboard} />

        <SuperAdminRouter path="/superadmin/settings-myprofile" exact component={SuperAdminSettings} />
        <SuperAdminRouter path="/superadmin/settings-users" exact component={SuperAdminSettings} />
        <SuperAdminRouter path="/superadmin/settings-pricing" exact component={SuperAdminSettings} />

        <SuperAdminRouter path="/superadmin/sales" exact component={SuperAdminSales} />

        {/*** SyperAdmin Company And User Route ***/}
        <SuperAdminRouter path="/superadmin/company-lists" exact component={SuperAdminUsers} />
        <SuperAdminRouter path="/superadmin/add-company" exact component={SuperAdminUsers} />
        <SuperAdminRouter path="/superadmin/update-company/:id" exact component={SuperAdminUsers} />
        <SuperAdminRouter path="/superadmin/company-users/:id" exact component={SuperAdminUsers} />
        <SuperAdminRouter path="/superadmin/add-company-users/:id" exact component={SuperAdminUsers} />
        <SuperAdminRouter path="/superadmin/update-company-users/:id" exact component={SuperAdminUsers} />

        <SuperAdminRouter path="/superadmin/individual-user-lists" exact component={SuperAdminUsers} />

        {/*** SyperAdmin ManageConten Route ***/}
        <SuperAdminRouter path="/superadmin/manage-content" exact component={SuperAdminManageContent} />
        <SuperAdminRouter path="/superadmin/bidtype-create" exact component={SuperAdminManageContent} />
        <SuperAdminRouter path="/superadmin/bidtype-update/:id" exact component={SuperAdminManageContent} />

        <SuperAdminRouter path="/superadmin/add-contentsection/:id" exact component={SuperAdminManageContent} />
        <SuperAdminRouter path="/superadmin/contentsection-update/:id" exact component={SuperAdminManageContent} />

        {/*** SyperAdmin Reporting Route ***/}
        <SuperAdminRouter path="/superadmin/reporting" exact component={SuperAdminReporting} />

        {/***************** Application Router *****************/}
        <Route path="/authentication" exact component={Authentication} />
        <Route path="/privacy" exact component={PrivacyPolicy} />
        <Route path="/terms" exact component={TermsConditions} />
        <Route path="/test" exact component={Test} />

        {/*** Auth Route ***/}
        <Route path="/logout" exact component={logout} />
        <AuthRouter path="/" exact component={signin} />
        <AuthRouter path="/signin" exact component={signin} />
        <AuthRouter path="/subscription" exact component={subscription} />
        <AuthRouter path="/payment" exact component={payment} />
        <AuthRouter path="/verification" exact component={verification} />
        <AuthRouter path="/signup" exact component={signup} />
        <Route path="/signup-company" exact component={signupcompany} />

        <AuthRouter path="/forgot-password" component={ForgotPassword} />
        <AuthRouter path="/reset-password/:useremail" component={ResetPassword} />

        <AuthRouter path="/invitation/:id" exact component={Invitation} />

        {/*** Dashboard Route ***/}
        <ProtectedRouter path="/dashboard" exact component={Dashboard} />

        {/*** Settings Route ***/}
        <ProtectedRouter path="/settings-myprofile" exact component={Settings} />
        <ProtectedRouter path="/settings-account" exact component={Settings} />
        <ProtectedRouter path="/settings-company" exact component={Settings} />
        <ProtectedRouter path="/settings-billing" exact component={Settings} />
        <ProtectedRouter path="/settings-subscription" exact component={Settings} />
        <ProtectedRouter path="/settings-users" exact component={Settings} />

        {/*** Bid Route ***/}
        <ProtectedRouter path="/bid-create" exact component={Bid} />
        <ProtectedRouter path="/bid-overview" exact component={Bid} />
        <ProtectedRouter path="/bid-update/:id" exact component={Bid} />
        <ProtectedRouter path="/bid-response/:id" exact component={Bid} />
        <Route path="/bid-response-share/:id" exact component={Bid} />

        {/*** My tasks Route ***/}
        <ProtectedRouter path="/tasks" exact component={MyTasks} />

        {/*** Clients Route ***/}
        <ProtectedRouter path="/clients" exact component={Clients} />
        <ProtectedRouter path="/client-create" exact component={Clients} />
        <ProtectedRouter path="/client-update/:id" exact component={Clients} />

        {/*** Library Route ***/}
        <ProtectedRouter path="/library" exact component={Library} />
        <ProtectedRouter path="/case-study/:id" exact component={Library} />
        <ProtectedRouter path="/case-study/:id" exact component={Library} />
        <ProtectedRouter path="/librarycontent-update/:id" exact component={Library} />
        <ProtectedRouter path="/returnable-documents" exact component={Library} />

        <ProtectedRouter path="/past-proposal-list/:id" exact component={Library} />

        {/*** Reporting Route ***/}
        <ProtectedRouter path="/reporting" exact component={Reporting} />

        {/*** UserNotifications Route ***/}
        <ProtectedRouter path="/notification-lists" exact component={UserNotifications} />

        <Route component={NotFound} />
    </Switch>
);
export default Router;
