import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import Validations from "../../components/Utility/Validations";

class signupcompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            signupcompany_form: {
                role: {
                    label: "What’s your role?",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Size of company",
                    validations: { required: false },
                    options: [{ opk: "role_opk_0", name: "What’s your role?", value: "" }],
                },
                user_title: {
                    label: "What’s your role?",
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    placeholder: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                abn: {
                    type: "text",
                    label: "ABN *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: {
                        required: true,
						min_length: 11,
                        ValidateABN: true,
                        NumberAndSpace: true,
                    },
                },
                company_name: {
                    type: "text",
                    label: "Company name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                industry: {
                    type: "text",
                    label: "Industry",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                company_size: {
                    label: "Size of company *",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [
                        { opk: "company_size_opk_0", name: "Size of company", value: "" },
                        { opk: "company_size_opk_1", name: "1-5", value: "1-5" },
                        { opk: "company_size_opk_2", name: "6-15", value: "6-15" },
                        { opk: "company_size_opk_3", name: "16-50", value: "16-50" },
                        { opk: "company_size_opk_4", name: "51-100", value: "51-100" },
                        { opk: "company_size_opk_5", name: "100+", value: "100+" },
                    ],
                },
                address: {
                    type: "text",
                    label: "Company address *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                zipcode: {
                    type: "number",
                    label: "Postcode *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, max_length: 10 },
                },
                state: {
                    label: "State *",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [{ opk: "state_opk0", name: "Select state", value: "" }],
                },
            },
            is_form_valid: false,
            redirect_url: "",
            isAuthenticated: false,
        };
    }

    componentDidMount() {
        const update_signupcompany_form = { ...this.state.signupcompany_form };
        userActions.GetCountries().then((response) => {
            if (response.success === true) {
                let country_detail = response.data;
                let country_state = country_detail["AU"][0].state;
                let state_option = [{ opk: "state_opk0", name: "Select a state", value: "" }];
                for (let key in country_state) {
                    state_option.push({
                        opk: country_state[key].state_code,
                        name: country_state[key].state_name,
                        value: country_state[key].state_code,
                    });
                }
                update_signupcompany_form.state.options = state_option;
                this.setState({
                    loader: false,
                    signupcompany_form: update_signupcompany_form,
                });
            } else {
                notify.show(response.message, "error");
            }
        });

        userActions.GetRoles().then((response) => {
            if (response.success === true) {
                let user_roles = response.data;
                let roles_option = [{ opk: "role_opk_0", name: "What’s your role?", value: "" }];
                for (let key in user_roles) {
                    if (user_roles[key].user_role === "Account owner") {
                        update_signupcompany_form.role.value = user_roles[key].role_id;
                    }
                    roles_option.push({
                        opk: user_roles[key]["role_id"],
                        name: user_roles[key]["user_role"],
                        value: user_roles[key]["role_id"],
                    });
                }
                update_signupcompany_form.role.options = roles_option;
                this.setState({ signupcompany_form: update_signupcompany_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    inputChangeHandler(event, identifier) {
        const update_signupcompany_form = { ...this.state.signupcompany_form };
        const form_element = { ...update_signupcompany_form[identifier] };

        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_signupcompany_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_signupcompany_form) {
            is_form_valid = update_signupcompany_form[identifier].valid && is_form_valid;
        }

        this.setState({
            signupcompany_form: update_signupcompany_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_signupcompany_form = this.state.signupcompany_form;
        for (let key in update_signupcompany_form) {
            let form_element = update_signupcompany_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_signupcompany_form[key] = form_element;
        }
        this.setState({ signupcompany_form: update_signupcompany_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.signupcompany_form) {
                form_data[key] = this.state.signupcompany_form[key].value;
            }
            this.props.onSignUpCompany(form_data).then((response) => {
                if (response.success === true) {
                    localStorage.setItem("bidhq_tutorial_token", true);
                    this.props.onGetAuthUser().then((response) => {
                        if (response.success === true) {
                            this.setState({
                                isAuthenticated: true,
                                redirect_url: "/dashboard",
                            });
                        } else {
                            notify.show(response.message, "error");
                        }
                    });
                } else {
                    notify.show(response.message, "error");
                    if (response.status === 401) {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/logout",
                        });
                    }
                }
            });
        }
    };

    render() {
        const { signupcompany_form } = this.state;
        return (
            <React.Fragment>
                <Notifications />
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <div
                            id="kt_aside"
                            className="aside aside-dark aside-hoverable aside-one"
                            data-kt-drawer="true"
                            data-kt-drawer-name="aside"
                            data-kt-drawer-activate="{default: true, lg: false}"
                            data-kt-drawer-overlay="true"
                            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                            data-kt-drawer-direction="start"
                            data-kt-drawer-toggle="#kt_aside_mobile_toggle"
                        >
                            <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                                <Link to="#">
                                    <img src="assets/images/logo.svg" alt="Logo" className="logo" />
                                </Link>
                                <Link to="#" className="short-logo">
                                    <img src="assets/images/short-logo.svg" alt="Logo" className="logo" />
                                </Link>
                                <img src="assets/images/thankpage-img/Emblem.png" alt="#" className="nav-img-wrap" />
                                <img src="assets/images/thankpage-img/thank1.svg" alt="#" className="nav-img-wrap-two" />
                            </div>
                        </div>
                        <div className="wrapper d-flex flex-column flex-row-fluid bg-white pt-0" id="kt_wrapper">
                            <div className="mobile-logo-block flex-center d-flex bg-dark-color p-10 pt-15 pb-15 w-100 mb-5">
                                <Link to="#">
                                    <img src="assets/images/logo.svg" alt="Logo" className="logo" />
                                </Link>
                                <img src="assets/images/thankpage-img/Emblem.png" alt="#" className="nav-img-wrap" />
                                <img src="assets/images/thankpage-img/Emblem-one.png" alt="#" className="nav-img-wrap-two" />
                            </div>
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="d-flex flex-column flex-lg-row-fluid">
                                    <div className="d-flex flex-center flex-column flex-column-fluid">
                                        <div className="w-lg-800px p-10 pt-0 pb-0 mx-auto">
                                            <form className="form w-100" autoComplete="off" onSubmit={this.SubmitHandler}>
                                                <div className="mb-10">
                                                    <h2 className="fw-bolder text-dark mb-3 fs-35">Almost done – let’s finish your account.</h2>
                                                    <div className="text-dark fs-15">Tell us a little about you and your business – this helps us give you the best possible experience.</div>
                                                </div>
                                                <div className={"fv-row mb-4 fv-plugins-icon-container" + (!signupcompany_form.user_title.valid && signupcompany_form.user_title.onBlur_out ? " error-field" : "")}>
                                                    <label className="form-label fs-6">{signupcompany_form.user_title.label}</label>
                                                    <input
                                                        autoComplete="new_user_title"
                                                        type={signupcompany_form.user_title.type}
                                                        className="form-control form-control-lg form-control-solid"
                                                        placeholder={signupcompany_form.user_title.placeholder}
                                                        value={signupcompany_form.user_title.value ? signupcompany_form.user_title.value : ""}
                                                        onChange={(event) => this.inputChangeHandler(event, "user_title")}
                                                        onBlur={(event) => {
                                                            signupcompany_form.user_title.onBlur_out = true;
                                                            this.setState({
                                                                signupcompany_form: signupcompany_form,
                                                            });
                                                        }}
                                                    />
                                                    {!signupcompany_form.user_title.valid && signupcompany_form.user_title.onBlur_out ? <div className="error field-error">{signupcompany_form.user_title.error_msg}</div> : ""}
                                                </div>

                                                <div className="row fv-row">
                                                    <div className={"col-xl-6 mb-4" + (!signupcompany_form.company_name.valid && signupcompany_form.company_name.onBlur_out ? " error-field" : "")}>
                                                        <label className="form-label fs-6">{signupcompany_form.company_name.label}</label>
                                                        <input
                                                            autoComplete="new_company_name"
                                                            type={signupcompany_form.company_name.type}
                                                            className="form-control form-control-lg form-control-solid"
                                                            placeholder={signupcompany_form.company_name.placeholder}
                                                            value={signupcompany_form.company_name.value ? signupcompany_form.company_name.value : ""}
                                                            onChange={(event) => this.inputChangeHandler(event, "company_name")}
                                                            onBlur={(event) => {
                                                                signupcompany_form.company_name.onBlur_out = true;
                                                                this.setState({
                                                                    signupcompany_form: signupcompany_form,
                                                                });
                                                            }}
                                                        />
                                                        {!signupcompany_form.company_name.valid && signupcompany_form.company_name.onBlur_out ? <div className="error field-error">{signupcompany_form.company_name.error_msg}</div> : ""}
                                                    </div>
                                                    <div className={"col-xl-6 mb-4" + (!signupcompany_form.abn.valid && signupcompany_form.abn.onBlur_out ? " error-field" : "")}>
                                                        <label className="form-label fs-6">{signupcompany_form.abn.label}</label>
                                                        <input
                                                            autoComplete="new_abn"
                                                            type={signupcompany_form.abn.type}
                                                            className="form-control form-control-lg form-control-solid"
                                                            placeholder={signupcompany_form.abn.placeholder}
                                                            value={signupcompany_form.abn.value ? signupcompany_form.abn.value : ""}
                                                            onChange={(event) => this.inputChangeHandler(event, "abn")}
                                                            onBlur={(event) => {
                                                                signupcompany_form.abn.onBlur_out = true;
                                                                this.setState({
                                                                    signupcompany_form: signupcompany_form,
                                                                });
                                                            }}
                                                        />
                                                        {!signupcompany_form.abn.valid && signupcompany_form.abn.onBlur_out ? <div className="error field-error">{signupcompany_form.abn.error_msg}</div> : ""}
                                                    </div>
                                                </div>
                                                <div className="row fv-row">
                                                    <div className={"col-xl-6 mb-4" + (!signupcompany_form.industry.valid && signupcompany_form.industry.onBlur_out ? " error-field" : "")}>
                                                        <label className="form-label fs-6">{signupcompany_form.industry.label}</label>
                                                        <input
                                                            autoComplete="new_industry"
                                                            type={signupcompany_form.industry.type}
                                                            className="form-control form-control-lg form-control-solid"
                                                            placeholder={signupcompany_form.industry.placeholder}
                                                            value={signupcompany_form.industry.value ? signupcompany_form.industry.value : ""}
                                                            onChange={(event) => this.inputChangeHandler(event, "industry")}
                                                            onBlur={(event) => {
                                                                signupcompany_form.industry.onBlur_out = true;
                                                                this.setState({
                                                                    signupcompany_form: signupcompany_form,
                                                                });
                                                            }}
                                                        />
                                                        {!signupcompany_form.industry.valid && signupcompany_form.industry.onBlur_out ? <div className="error field-error">{signupcompany_form.industry.error_msg}</div> : ""}
                                                    </div>
                                                    <div className={"col-xl-6 mb-4" + (!signupcompany_form.company_size.valid && signupcompany_form.company_size.onBlur_out ? " error-field" : "")}>
                                                        <label className="form-label fs-6">{signupcompany_form.company_size.label}</label>
                                                        <select className="form-select form-select-solid" value={signupcompany_form.company_size.value} onChange={(event) => this.inputChangeHandler(event, "company_size")}>
                                                            {signupcompany_form.company_size.options.map((option) => (
                                                                <option value={option.value} key={option.opk}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {!signupcompany_form.company_size.valid && signupcompany_form.company_size.onBlur_out ? <div className="error field-error">{signupcompany_form.company_size.error_msg}</div> : ""}
                                                    </div>
                                                </div>
                                                <div className={"fv-row mb-4 fv-plugins-icon-container" + (!signupcompany_form.address.valid && signupcompany_form.address.onBlur_out ? " error-field" : "")}>
                                                    <label className="form-label fs-6">{signupcompany_form.address.label}</label>
                                                    <input
                                                        autoComplete="new_address"
                                                        type={signupcompany_form.address.type}
                                                        className="form-control form-control-lg form-control-solid"
                                                        placeholder={signupcompany_form.address.placeholder}
                                                        value={signupcompany_form.address.value ? signupcompany_form.address.value : ""}
                                                        onChange={(event) => this.inputChangeHandler(event, "address")}
                                                        onBlur={(event) => {
                                                            signupcompany_form.address.onBlur_out = true;
                                                            this.setState({
                                                                signupcompany_form: signupcompany_form,
                                                            });
                                                        }}
                                                    />
                                                    {!signupcompany_form.address.valid && signupcompany_form.address.onBlur_out ? <div className="error field-error">{signupcompany_form.address.error_msg}</div> : ""}
                                                </div>
                                                <div className="row fv-row">
                                                    <div className={"col-xl-6 mb-4" + (!signupcompany_form.zipcode.valid && signupcompany_form.zipcode.onBlur_out ? " error-field" : "")}>
                                                        <label className="form-label fs-6">{signupcompany_form.zipcode.label}</label>
                                                        <input
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            autoComplete="new_zipcode"
                                                            type={signupcompany_form.zipcode.type}
                                                            className="form-control form-control-lg form-control-solid"
                                                            placeholder={signupcompany_form.zipcode.placeholder}
                                                            value={signupcompany_form.zipcode.value ? signupcompany_form.zipcode.value : ""}
                                                            onChange={(event) => this.inputChangeHandler(event, "zipcode")}
                                                            onBlur={(event) => {
                                                                signupcompany_form.zipcode.onBlur_out = true;
                                                                this.setState({
                                                                    signupcompany_form: signupcompany_form,
                                                                });
                                                            }}
                                                        />
                                                        {!signupcompany_form.zipcode.valid && signupcompany_form.zipcode.onBlur_out ? <div className="error field-error">{signupcompany_form.zipcode.error_msg}</div> : ""}
                                                    </div>
                                                    <div className={"col-xl-6 mb-4" + (!signupcompany_form.state.valid && signupcompany_form.state.onBlur_out ? " error-field" : "")}>
                                                        <label className="form-label fs-6">{signupcompany_form.state.label}</label>
                                                        <select className="form-select form-select-solid" value={signupcompany_form.state.value} onChange={(event) => this.inputChangeHandler(event, "state")}>
                                                            {signupcompany_form.state.options.map((option) => (
                                                                <option value={option.value} key={option.opk}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {!signupcompany_form.state.valid && signupcompany_form.state.onBlur_out ? <div className="error field-error">{signupcompany_form.state.error_msg}</div> : ""}
                                                    </div>
                                                </div>
                                                <div className="mt-10">
                                                    <button type="submit" id="kt_sign_up_submit" className="btn rounded-50 btn-lg btn-primary save-btn hvr-grow">
                                                        <span className="indicator-label">Complete signup</span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onGetCountries: userActions.GetCountries,
    onSignUpCompany: userActions.SignUpCompany,
    onGetAuthUser: userActions.GetAuthUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(signupcompany);