import React from "react";
import moment from "moment";
class ReturnableRightSideBarFilePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            returnable_document: "",
        };
    }

    componentDidMount() {
        this.setState({
            returnable_document: this.props.returnable_document,
        });
    }

    render() {
        const { returnable_document } = this.state;
        return (
            <React.Fragment>
                <div className="wrap-right-info right-comment bid_rightsidebar_sectioncomment">
                    <div
                        className="comment-close-button"
                        onClick={() => {
                            this.props.returnablerightsidebar_filepreview_action(false);
                        }}
                    >
                        <i className="icon-cross-2" aria-hidden="true"></i>
                    </div>
                    <div className="card-toolbar m-0">
                        <div className="card-header border-0 p-0">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card scroll-y right-scroll-height border-0">
                                        <div className="title-header-top border-bottom p-5">
                                            <h4 className="text-dark fw-bolder fs-2x m-0">
                                                <span className="icon-File color1 me-1"></span> File info
                                            </h4>
                                        </div>

                                        <div className="p-5 pb-1 l-sidbar-title">
                                            <h6 className="text-gray-400 fs-9 mb-1">FILE NAME</h6>
                                            <span className="fs-base d-block fw-bolder color1">{returnable_document.title}</span>
                                        </div>
                                        <div className="p-5 pb-1 l-sidbar-title">
                                            <h6 className="text-gray-400 fs-9 mb-1">LAST MODIFIED</h6>
                                            <span className="fs-base d-block color1 ">{moment(returnable_document.updated_date).format("DD/MM/YYYY")}</span>
                                        </div>
                                        <div className="p-5 pb-1 l-sidbar-title">
                                            <h6 className="text-gray-400 fs-9 mb-2">LAST USED IN BID</h6>
                                            <span className="fs-base d-block color1 ">{returnable_document.lastused_inbid ? moment(returnable_document.lastused_inbid).format("DD/MM/YYYY") : "-"}</span>
                                        </div>
                                        <div className="p-5 pb-1 l-sidbar-title">
                                            <h6 className="text-gray-400 fs-9 mb-2">OVERALL TIMES USED</h6>
                                            <span className="fs-base d-block color1">{returnable_document.overall_timeused ? returnable_document.overall_timeused : 0}</span>
                                        </div>
                                        <div className="p-5 pb-1 l-sidbar-title">
                                            <h6 className="text-gray-400 fs-9 mb-2">DATE CREATED</h6>
                                            <span className="fs-base d-block color1">{moment(returnable_document.created_date).format("DD/MM/YYYY")}</span>
                                        </div>
                                        <div className="p-5 pb-1 l-sidbar-title">
                                            <h6 className="text-gray-400 fs-9 mb-2">OWNER</h6>
                                            <div className="custom-tooltip">
                                                <div className="symbol symbol-25px symbol-circle">
                                                    <span className="symbol-label bg-primary text-inverse-primary">
                                                        {returnable_document.created_user ? returnable_document.created_user.first_name?.slice(0, 1) + returnable_document.created_user.last_name?.slice(0, 1) : ""}
                                                        <span className="custom-tooltip-text ">{returnable_document.created_user ? returnable_document.created_user.first_name + " " + returnable_document.created_user.last_name : ""}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-sidebar-footer border-top p-5">
                                        <ul className=" nav">
                                            <li className="nav-item curser_pointer">
                                                <span className="nav-link justify-content-center text-gray-new" onClick={() => this.props.DeleteLibraryReturnableDocument(returnable_document)}>
                                                    <i className="icon-delete fs-4 text-gray-new"></i> Delete
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ReturnableRightSideBarFilePreview;