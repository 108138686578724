import * as Master_actionType from "../constants/MasteractionTypes";

const initial_state = {
    error: null,
    loader: false,
    isAuthenticated: true,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case Master_actionType.GetBids_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBids_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBids_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetCountByStatus_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetCountByStatus_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetCountByStatus_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetTags_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetTags_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetTags_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetBidTypes_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidTypes_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidTypes_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.CreateBid_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.CreateBid_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.CreateBid_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.UpdateBid_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateBid_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateBid_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.DeleteBid_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.DeleteBid_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.DeleteBid_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetBidById_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidById_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidById_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.UpdateBidTeam_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateBidTeam_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateBidTeam_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.RemoveBidTeam_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveBidTeam_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveBidTeam_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.UpdateBidOwner_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateBidOwner_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateBidOwner_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.RemoveBidOwner_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveBidOwner_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveBidOwner_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetBidtypeContent_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidtypeContent_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidtypeContent_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetBidSectionByBidId_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidSectionByBidId_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidSectionByBidId_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetBidSectionById_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidSectionById_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidSectionById_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidSectionInsert_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSectionInsert_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSectionInsert_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidInsertNewBlankSection_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidInsertNewBlankSection_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidInsertNewBlankSection_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidSectionRemove_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSectionRemove_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSectionRemove_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidSectionUpdate_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSectionUpdate_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSectionUpdate_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidSectionShortingUpdate_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSectionShortingUpdate_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSectionShortingUpdate_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidSectionUpdateTagUsers_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSectionUpdateTagUsers_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSectionUpdateTagUsers_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidUploadDocument_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidUploadDocument_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidUploadDocument_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidRemoveDocument_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidRemoveDocument_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidRemoveDocument_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidDocumentTitleUpdate_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidDocumentTitleUpdate_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidDocumentTitleUpdate_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidUpdateLibraryReturnableDocument_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidUpdateLibraryReturnableDocument_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidUpdateLibraryReturnableDocument_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidStatusUpdate_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidStatusUpdate_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidStatusUpdate_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.CreateComment_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.CreateComment_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.CreateComment_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetCommentById_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetCommentById_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetCommentById_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidSelectedStatusUpdate_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSelectedStatusUpdate_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSelectedStatusUpdate_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidSectionDueDateUpdate_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSectionDueDateUpdate_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidSectionDueDateUpdate_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.UpdateSectionTeam_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateSectionTeam_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateSectionTeam_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.RemoveSectionTeam_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveSectionTeam_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveSectionTeam_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.UpdateSectionOwner_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateSectionOwner_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateSectionOwner_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.RemoveSectionOwner_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveSectionOwner_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveSectionOwner_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetResponseRecord_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetResponseRecord_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetResponseRecord_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.DesignPreviewGenratepdf_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.DesignPreviewGenratepdf_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.DesignPreviewGenratepdf_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.DesignPreviewUpdatepdf_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.DesignPreviewUpdatepdf_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.DesignPreviewUpdatepdf_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidLockStatusChange_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidLockStatusChange_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidLockStatusChange_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GenrateBidZip_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GenrateBidZip_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GenrateBidZip_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidCompleteZipSend_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidCompleteZipSend_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidCompleteZipSend_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetBidResponseShare_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidResponseShare_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidResponseShare_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.BidResponseShare_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidResponseShare_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.BidResponseShare_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetBidActivityLogs_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidActivityLogs_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidActivityLogs_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetBidResponsePricingBySectionId_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidResponsePricingBySectionId_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidResponsePricingBySectionId_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.CreateBidResponsePricing_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.CreateBidResponsePricing_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.CreateBidResponsePricing_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.UpdateBidResponsePricing_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateBidResponsePricing_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateBidResponsePricing_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.RemoveBidContentPricing_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveBidContentPricing_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.RemoveBidContentPricing_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.UpdateBidResponsePricingGST_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateBidResponsePricingGST_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.UpdateBidResponsePricingGST_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetBidLinkShareExtenal_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidLinkShareExtenal_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetBidLinkShareExtenal_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GenrateBidLinkShareExtenalZip_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GenrateBidLinkShareExtenalZip_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GenrateBidLinkShareExtenalZip_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        default:
            return state;
    }
};

export default reducer;