import React from "react";
import { Chart } from "react-google-charts";

import exportAsImage from "../../components/Utility/exportAsImage";
class BidActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bidactivity_data: [
                ["Tasks", "value"],
                ["Won", 0],
                ["In progress", 0],
                ["Submitted", 0],
                ["Registered", 0],
                ["Lost", 0],
                ["Withdrawn", 0],
            ],
            status_count: 0,
            bid_won: 0,
            bid_progress: 0,
            bid_submitted: 0,
            bid_registered: 0,
            bid_lost: 0,
            bid_withdrawn: 0,
            dashboard_bidactivity_show: this.props.dashboard_bidactivitysection_show,
        };
    }

    componentDidMount() {
        let bid_activity = this.props.bid_activity;

        let bid_won = bid_activity && bid_activity.bid_won ? parseInt(bid_activity.bid_won) : 0;
        let bid_progress = bid_activity && bid_activity.bid_progress ? parseInt(bid_activity.bid_progress) : 0;
        let bid_submitted = bid_activity && bid_activity.bid_submitted ? parseInt(bid_activity.bid_submitted) : 0;
        let bid_registered = bid_activity && bid_activity.bid_registered ? parseInt(bid_activity.bid_registered) : 0;
        let bid_lost = bid_activity && bid_activity.bid_lost ? parseInt(bid_activity.bid_lost) : 0;
        let bid_withdrawn = bid_activity && bid_activity.bid_withdrawn ? parseInt(bid_activity.bid_withdrawn) : 0;

        let status_count = parseInt(bid_won) + parseInt(bid_progress) + parseInt(bid_submitted) + parseInt(bid_registered) + parseInt(bid_lost) + parseInt(bid_withdrawn);

        let bidactivity_data = [
            ["Tasks", "value"],
            ["Won " + bid_won, bid_won],
            ["In progress " + bid_progress, bid_progress],
            ["Submitted " + bid_submitted, bid_submitted],
            ["Registered " + bid_registered, bid_registered],
            ["Lost " + bid_lost, bid_lost],
            ["Withdrawn " + bid_withdrawn, bid_withdrawn],
        ];

        this.setState({
            bid_won: bid_won,
            bid_progress: bid_progress,
            bid_submitted: bid_submitted,
            bid_registered: bid_registered,
            bid_lost: bid_lost,
            status_count: status_count,
            bid_withdrawn: bid_withdrawn,
            bidactivity_data: bidactivity_data,
        });
    }

    BidActivityLoad() {
        let bid_activity = this.props.bid_activity;

        let bid_won = bid_activity && bid_activity.bid_won ? parseInt(bid_activity.bid_won) : 0;
        let bid_progress = bid_activity && bid_activity.bid_progress ? parseInt(bid_activity.bid_progress) : 0;
        let bid_submitted = bid_activity && bid_activity.bid_submitted ? parseInt(bid_activity.bid_submitted) : 0;
        let bid_registered = bid_activity && bid_activity.bid_registered ? parseInt(bid_activity.bid_registered) : 0;
        let bid_lost = bid_activity && bid_activity.bid_lost ? parseInt(bid_activity.bid_lost) : 0;
        let bid_withdrawn = bid_activity && bid_activity.bid_withdrawn ? parseInt(bid_activity.bid_withdrawn) : 0;
        let status_count = parseInt(bid_won) + parseInt(bid_progress) + parseInt(bid_submitted) + parseInt(bid_registered) + parseInt(bid_lost) + parseInt(bid_withdrawn);
        let bidactivity_data = [
            ["Tasks", "value"],
            ["Won " + bid_won, bid_won],
            ["In progress " + bid_progress, bid_progress],
            ["Submitted " + bid_submitted, bid_submitted],
            ["Registered " + bid_registered, bid_registered],
            ["Lost " + bid_lost, bid_lost],
            ["Withdrawn " + bid_withdrawn, bid_withdrawn],
        ];

        this.setState({
            bid_won: bid_won,
            bid_progress: bid_progress,
            bid_submitted: bid_submitted,
            bid_registered: bid_registered,
            bid_lost: bid_lost,
            status_count: status_count,
            bid_withdrawn: bid_withdrawn,
            bidactivity_data: bidactivity_data,
        });
    }

    render() {
        const { bidactivity_data, dashboard_bidactivity_show } = this.state;
        return (
            <React.Fragment>
                <div className="col-xl-4 dashboard_bidactivity bid-notify">
                    <div className={"card mb-lg-8" + (dashboard_bidactivity_show ? " card-xl-stretch " : "")}>
                        <div className="card-header align-items-center border-0 mt-4">
                            <h4 className="card-title align-items-start flex-column m-0">
                                <span className="fw-bolder text-main-color">Bid Activity</span>
                                <span className="text-muted fw-normal fs-6 text-grey-dark">Status (all time).</span>
                            </h4>
                            <div className="card-toolbar">
                                <button type="button" className="btn btn-sm btn-icon" id="dashboard_bidactivity" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="icon-dots text-grey-dark icon-size"></i>
                                </button>
                                <div className="dropdown-menu menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_bidactivity">
                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                        <div className="text-black" onClick={(event) => exportAsImage(this.dashboard_welcome_ref, "Bid Activity")}>
                                            <i className="icon-download me-2 text-black"></i> <span>Download as image</span>
                                        </div>
                                    </div>
                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                        <div className="text-black" onClick={(event) => this.props.dashboard_bidactivitysection_show_action(dashboard_bidactivity_show ? false : true)}>
                                            <i className={"me-2 text-black" + (dashboard_bidactivity_show ? " icon-eye font-size-8" : " bi bi-eye")}></i>
                                            <span>{dashboard_bidactivity_show ? "Hide chart" : "Show chart"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ref={(node) => (this.dashboard_welcome_ref = node)} className={"card-body pt-0 bid-activity-status" + (dashboard_bidactivity_show ? "" : " d-none")}>
                            <div className="d-flex mb-4 flex-stack">
                                <div className="align-items-center total-opp">
                                    <div className="activity-main d-flex mb-3">
                                        <div className="symbol symbol-30px me-2">
                                            <i className="icon-Won text-green mt-1"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="text-green fw-normal fs-9">Won</span>
                                            <span className="color1 fw-bold d-block fs-8">{this.state.bid_won}</span>
                                        </div>
                                    </div>
                                    <div className="activity-main d-flex mb-3">
                                        <div className="symbol symbol-30px me-2">
                                            <i className="icon-progress-1 text-purple mt-1"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="text-purple fw-normal fs-9">In progress</span>
                                            <span className="color1 fw-bold d-block fs-8">{this.state.bid_progress}</span>
                                        </div>
                                    </div>
                                    <div className="activity-main d-flex mb-3">
                                        <div className="symbol symbol-30px me-2">
                                            <i className="icon-submit text-blue mt-1"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="text-blue fw-normal fs-9">Submitted</span>
                                            <span className="color1 fw-bold d-block fs-8">{this.state.bid_submitted}</span>
                                        </div>
                                    </div>
                                    <div className="activity-main d-flex mb-3 d-none">
                                        <div className="symbol symbol-30px me-2">
                                            <i className="icon-media text-main-color mt-1"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="text-main-color fw-normal fs-9">Registered</span>
                                            <span className="color1 fw-bold d-block fs-8">{this.state.bid_registered}</span>
                                        </div>
                                    </div>
                                    <div className="activity-main d-flex mb-3">
                                        <div className="symbol symbol-30px me-2">
                                            <i className="icon-cross-1 text-grey-dark mt-1"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="text-grey-dark fw-normal fs-9">Lost</span>
                                            <span className="color1 fw-bold d-block fs-8">{this.state.bid_lost}</span>
                                        </div>
                                    </div>
                                    <div className="activity-main d-flex mb-3">
                                        <div className="symbol symbol-30px me-2">
                                            <i className="icon-Withdrawn text-gray-new mt-1"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="text-gray-new fw-normal fs-9">Withdrawn</span>
                                            <span className="color1 fw-bold d-block fs-8">{this.state.bid_withdrawn}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="align-items-center flex-wrap dashboard_bidactivity_piechart">
                                    <Chart
                                        chartType="PieChart"
                                        graph_id="dashboard_bidactivity_piechart"
                                        data={bidactivity_data}
                                        options={{
                                            responsive: "true",
                                            width: "250px",
                                            height: "300px",
                                            pieHole: 0.7,
                                            pieSliceText: "none",
                                            pieSliceTextStyle: {
                                                color: "black",
                                            },
                                            legend: {
                                                position: "none",
                                            },
                                            slices: {
                                                0: { color: "rgb(61, 161, 83)" },
                                                1: { color: "#967DFA" },
                                                2: { color: "#6496FA" },
                                                3: { color: "#3DA153" },
                                                4: { color: "#7B7B7B" },
                                                5: { color: "#A0A0A0" },
                                            },
                                            chartArea: {
                                                left: 60,
                                                right: 0,

                                                // width: "100%",
                                                // height: "90%",
                                            },
                                        }}
                                        // width={"100%"}
                                        // height={"300px"}
                                    />
                                    <div id="labelOverlay">
                                        <p className="used-size">Total Opportunities:</p>
                                        <p className="total-size">{this.state.status_count}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default BidActivity;