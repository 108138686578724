import React from "react";

import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";
import Notifications from "react-notify-toast";

import DashboardWelcomeBidHQ from "./DashboardWelcomeBidHQ";
import DashboardStart from "./DashboardStart";
import DashboardGreatJobs from "./DashboardGreatJobs";
import RecentActivity from "./RecentActivity";
import TasksThisWeek from "./TasksThisWeek";
import BidActivity from "./BidActivity";
import BidValues from "./BidValues";
import UpcomingBids from "./UpcomingBids";
import LatestBidHQ from "./LatestBidHQ";

import * as userActions from "../../actions/index";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";
import WatchTutorialVideoPopup from "../WatchTutorialVideo";
// import exportAsImage from "../../components/Utility/exportAsImage";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboard_getstarts: [],
            dashboard_greatjobs: [],
            recentactivites: [],
            taskthis_weeks: [],
            upcoming_bids: [],
            bid_activity: [],
            bid_values: [],
            bid_rates: [],

            dashboard_welcomebidhq_show: true,
            dashboard_welcomesection_show: true,
            dashboard_recentctivitysection_show: true,
            dashboard_thisweeksection_show: true,
            dashboard_bidactivitysection_show: true,
            dashboard_bidvaluesection_show: true,
            dashboard_upcomingbidssection_show: true,
            dashboard_winlossrateratessection_show: true,
            dashboard_usercountssection_show: true,
            dashboard_latestbidhqsection_show: true,

            dashboard_winlossrate_show: true,
            dashboard_usercounts_show: true,
            watch_tutorialvideo_modal: false
        };
    }

    componentDidMount() {
        this.GetDashboardStart();
        this.DashboardGreatJobs();
        this.DashboardRecentActivity();
        this.DashboardTasks();
        this.GetBidActivity();
        this.GetBidValues();
        this.UpcomingBids();
        this.GetBidRate();
        if (localStorage.getItem("bidhq_tutorial_token") === "true") {
            this.setState({
                watch_tutorialvideo_modal: true,
            });
        }
    }

    GetDashboardStart() {
        this.props.onGetDashboardStart().then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        dashboard_getstarts: response.data,
                    },
                    function () {
                        if (this.BidActivityEvent && this.BidActivityEvent !== undefined) {
                            this.BidActivityEvent.BidActivityLoad();
                        }
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    DashboardGreatJobs() {
        this.props.onDashboardGreatJobs().then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        dashboard_greatjobs: response.data,
                    },
                    function () {
                        if (this.DashboardGreatJobsEvent && this.DashboardGreatJobsEvent !== undefined) {
                            this.DashboardGreatJobsEvent.DashboardGreatJobsReload();
                        }
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    DashboardRecentActivity() {
        this.props.onDashboardRecentActivity().then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        recentactivites: response.data,
                    },
                    function () {
                        if (this.RecentActivityEvent && this.RecentActivityEvent !== undefined) {
                            this.RecentActivityEvent.RecentActivityReload();
                        }
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    DashboardTasks() {
        this.props.onDashboardTasks().then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        taskthis_weeks: response.data,
                    },
                    function () {
                        if (this.TasksThisWeekEvent && this.TasksThisWeekEvent !== undefined) {
                            this.TasksThisWeekEvent.TasksThisWeekReload();
                        }
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    GetBidActivity() {
        this.props.onGetBidActivity().then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        bid_activity: response.data,
                    },
                    function () {
                        if (this.BidActivityEvent && this.BidActivityEvent !== undefined) {
                            this.BidActivityEvent.BidActivityLoad();
                        }
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    GetBidValues() {
        this.props.onGetBidValues().then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        bid_values: response.data,
                    },
                    function () {
                        if (this.BidValuesEvent && this.BidValuesEvent !== undefined) {
                            this.BidValuesEvent.BidValuesReload();
                        }
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    UpcomingBids() {
        this.props.onUpcomingBids().then((response) => {
            if (response.success === true) {
                this.setState({ upcoming_bids: response.data });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    GetBidRate() {
        this.props.onGetBidRate().then((response) => {
            if (response.success === true) {
                this.setState({
                    bid_rates: response.data,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    render() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        const {
            dashboard_getstarts,
            dashboard_greatjobs,
            recentactivites,
            taskthis_weeks,
            bid_activity,
            bid_values,
            upcoming_bids,
            bid_rates,

            dashboard_welcomebidhq_show,
            dashboard_welcomesection_show,
            dashboard_recentctivitysection_show,
            dashboard_thisweeksection_show,
            dashboard_bidactivitysection_show,
            dashboard_bidvaluesection_show,
            dashboard_upcomingbidssection_show,
            dashboard_winlossrateratessection_show,
            dashboard_usercountssection_show,
            dashboard_latestbidhqsection_show,

            dashboard_winlossrate_show,
            dashboard_usercounts_show,
        } = this.state;
        return (
            <React.Fragment>
                {this.state.watch_tutorialvideo_modal && (
                    <WatchTutorialVideoPopup
                        watch_tutorialvideo_modal={this.state.watch_tutorialvideo_modal}
                        watch_tutorialvideo_modal_action={(modal_action) => {
                            localStorage.removeItem("bidhq_tutorial_token");
                            this.setState({
                                watch_tutorialvideo_modal: modal_action,
                            });
                        }}
                    />
                )}
                <Notifications />
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title={userActions.GreetingOfTheData() + ", " + user_info.user.first_name + " " + user_info.user.last_name + "!"} />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl dash-outer">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card mb-4 mb-xl-8 bg-transparent">
                                                    <div className="card-header border-0 p-0 align-items-center">
                                                        <h3 className="card-title align-items-start flex-column m-0 mb-md-8">
                                                            <span className="card-label fw-bolder fs-2x mb-1">Welcome back to your dashboard</span>
                                                            <span className="mt-1 fw-bold text-dark-muted fs-15 color1">It is now {moment().format("H:mm, DD MMMM YYYY")}</span>
                                                        </h3>
                                                        <div className="dropdown toggle-btn-dropdown column-drop-out">
                                                            <div className="btn rounded-50 btn-white btn-sm visible-title" id="dashboard_visibility" aria-expanded="false" data-bs-toggle="dropdown">
                                                                <h6 className="text-dark m-0 fs-7">
                                                                    Visibility <i className="icon-down-arrow text-dark fs-10 ms-1"></i>
                                                                </h6>
                                                            </div>
                                                            <div className="dropdown-menu" aria-labelledby="dashboard_visibility">
                                                                <h6 className="fs-7 mb-3">Customise Fields</h6>

                                                                {!user_info?.company?.updated_by ? (
                                                                    <div className="dropdown-item">
                                                                        <div className="d-flex flex-stack">
                                                                            <div className="d-flex">
                                                                                <span className="fs-8 color1">Welcome to BidHQ!</span>
                                                                            </div>
                                                                            <div className="d-flex justify-content-end">
                                                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={dashboard_welcomebidhq_show}
                                                                                        className="form-check-input"
                                                                                        onChange={(event) => {
                                                                                            this.setState({
                                                                                                dashboard_welcomebidhq_show: dashboard_welcomebidhq_show ? false : true,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    (user_info.user.role.user_role === "Account owner" ||
                                                                        user_info.user.role.user_role === "Bid Manager" ||
                                                                        user_info.user.role.user_role === "Contributor" ||
                                                                        user_info.user.role.user_role === "Guest") && (
                                                                        <div className="dropdown-item">
                                                                            <div className="d-flex flex-stack">
                                                                                <div className="d-flex">
                                                                                    <span className="fs-8 color1">Welcome Dashboard</span>
                                                                                </div>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={dashboard_welcomesection_show}
                                                                                            className="form-check-input"
                                                                                            onChange={(event) => {
                                                                                                this.setState({
                                                                                                    dashboard_welcomesection_show: dashboard_welcomesection_show ? false : true,
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                                {(user_info.user.role.user_role === "Account owner" ||
                                                                    user_info.user.role.user_role === "Bid Manager" ||
                                                                    user_info.user.role.user_role === "Contributor" ||
                                                                    user_info.user.role.user_role === "Guest") &&
                                                                    dashboard_recentctivitysection_show && (
                                                                        <div className="dropdown-item">
                                                                            <div className="d-flex flex-stack">
                                                                                <div className="d-flex">
                                                                                    <span className="fs-8 color1">Recent Activity</span>
                                                                                </div>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={dashboard_recentctivitysection_show}
                                                                                            className="form-check-input"
                                                                                            onChange={(event) => {
                                                                                                this.setState({
                                                                                                    dashboard_recentctivitysection_show: dashboard_recentctivitysection_show ? false : true,
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {(user_info.user.role.user_role === "Account owner" ||
                                                                    user_info.user.role.user_role === "Bid Manager" ||
                                                                    user_info.user.role.user_role === "Contributor" ||
                                                                    user_info.user.role.user_role === "Guest") && (
                                                                        <div className="dropdown-item">
                                                                            <div className="d-flex flex-stack">
                                                                                <div className="d-flex">
                                                                                    <span className="fs-8 color1">Tasks this week</span>
                                                                                </div>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={dashboard_thisweeksection_show}
                                                                                            className="form-check-input"
                                                                                            onChange={(event) => {
                                                                                                this.setState({
                                                                                                    dashboard_thisweeksection_show: dashboard_thisweeksection_show ? false : true,
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Reports Overview") && (
                                                                    <div className="dropdown-item">
                                                                        <div className="d-flex flex-stack">
                                                                            <div className="d-flex">
                                                                                <span className="fs-8 color1">Bid Activity</span>
                                                                            </div>
                                                                            <div className="d-flex justify-content-end">
                                                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={dashboard_bidactivitysection_show}
                                                                                        className="form-check-input"
                                                                                        onChange={(event) => {
                                                                                            this.setState({
                                                                                                dashboard_bidactivitysection_show: dashboard_bidactivitysection_show ? false : true,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Reports Overview") && (
                                                                    <div className="dropdown-item">
                                                                        <div className="d-flex flex-stack">
                                                                            <div className="d-flex">
                                                                                <span className="fs-8 color1">Bid Values</span>
                                                                            </div>
                                                                            <div className="d-flex justify-content-end">
                                                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={dashboard_bidvaluesection_show}
                                                                                        className="form-check-input"
                                                                                        onChange={(event) => {
                                                                                            this.setState({
                                                                                                dashboard_bidvaluesection_show: dashboard_bidvaluesection_show ? false : true,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {(user_info.user.role.user_role === "Account owner" ||
                                                                    user_info.user.role.user_role === "Bid Manager" ||
                                                                    user_info.user.role.user_role === "Contributor" ||
                                                                    user_info.user.role.user_role === "Guest" ||
                                                                    user_info.user.role.user_role === "Reports Overview") && (
                                                                        <div className="dropdown-item">
                                                                            <div className="d-flex flex-stack">
                                                                                <div className="d-flex">
                                                                                    <span className="fs-8 color1">Upcoming Bids</span>
                                                                                </div>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={dashboard_upcomingbidssection_show}
                                                                                            className="form-check-input"
                                                                                            onChange={(event) => {
                                                                                                this.setState({
                                                                                                    dashboard_upcomingbidssection_show: dashboard_upcomingbidssection_show ? false : true,
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                                    <div className="dropdown-item">
                                                                        <div className="d-flex flex-stack">
                                                                            <div className="d-flex">
                                                                                <span className="fs-8 color1">Win/Loss Rate</span>
                                                                            </div>
                                                                            <div className="d-flex justify-content-end">
                                                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={dashboard_winlossrateratessection_show}
                                                                                        className="form-check-input"
                                                                                        onChange={(event) => {
                                                                                            this.setState({
                                                                                                dashboard_winlossrateratessection_show: dashboard_winlossrateratessection_show ? false : true,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {user_info.user.role.user_role === "Account owner" && (
                                                                    <div className="dropdown-item">
                                                                        <div className="d-flex flex-stack">
                                                                            <div className="d-flex">
                                                                                <span className="fs-8 color1">User Counts</span>
                                                                            </div>
                                                                            <div className="d-flex justify-content-end">
                                                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={dashboard_usercountssection_show}
                                                                                        className="form-check-input"
                                                                                        onChange={(event) => {
                                                                                            this.setState({
                                                                                                dashboard_usercountssection_show: dashboard_usercountssection_show ? false : true,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {(user_info.user.role.user_role === "Account owner" ||
                                                                    user_info.user.role.user_role === "Bid Manager" ||
                                                                    user_info.user.role.user_role === "Contributor" ||
                                                                    user_info.user.role.user_role === "Guest" ||
                                                                    user_info.user.role.user_role === "Reports Overview") && (
                                                                        <div className="dropdown-item">
                                                                            <div className="d-flex flex-stack">
                                                                                <div className="d-flex">
                                                                                    <span className="fs-8 color1">Latest from BidHQ</span>
                                                                                </div>
                                                                                <div className="d-flex justify-content-end">
                                                                                    <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={dashboard_latestbidhqsection_show}
                                                                                            className="form-check-input"
                                                                                            onChange={(event) => {
                                                                                                this.setState({
                                                                                                    dashboard_latestbidhqsection_show: dashboard_latestbidhqsection_show ? false : true,
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                        <span className="form-check-label fw-bold text-muted"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row gy-5 g-xl-8 dash-mb">
                                            {(!user_info?.company?.updated_by && user_info.user.role.user_role !== "Bid Manager" &&
                                                user_info.user.role.user_role !== "Contributor" &&
                                                user_info.user.role.user_role !== "Guest") || (!user_info.user.user_timezone &&
                                                (user_info.user.role.user_role === "Bid Manager" ||
                                                user_info.user.role.user_role === "Contributor" ||
                                                user_info.user.role.user_role === "Guest"))
                                                ? dashboard_welcomebidhq_show && (
                                                    <DashboardWelcomeBidHQ
                                                        ref={(ref) => (this.DashboardWelcomeBidHQEvent = ref)}
                                                        dashboard_welcomebidhq_show_action={(action) => {
                                                            console.log("action---------", action);
                                                        }}
                                                    />
                                                )
                                                : (user_info.user.role.user_role === "Account owner" ||
                                                    user_info.user.role.user_role === "Bid Manager" ||
                                                    user_info.user.role.user_role === "Contributor" ||
                                                    user_info.user.role.user_role === "Guest") &&
                                                dashboard_welcomesection_show &&
                                                (dashboard_getstarts && dashboard_getstarts.company_invite && dashboard_getstarts && dashboard_getstarts.company_library && dashboard_getstarts && dashboard_getstarts.company_bid ? (
                                                    <DashboardGreatJobs
                                                        dashboard_greatjobs={dashboard_greatjobs}
                                                        ref={(ref) => (this.DashboardGreatJobsEvent = ref)}
                                                        dashboard_welcomesection_show={dashboard_welcomesection_show}
                                                        dashboard_welcomesection_show_action={(action) => {
                                                            this.setState({
                                                                dashboard_welcomesection_show: action,
                                                            });
                                                        }}
                                                    />
                                                ) : (
                                                    <DashboardStart
                                                        dashboard_getstarts={dashboard_getstarts}
                                                        user_info={user_info}
                                                        ref={(ref) => (this.DashboardGreatJobsEvent = ref)}
                                                        dashboard_welcomesection_show={dashboard_welcomesection_show}
                                                        dashboard_welcomesection_show_action={(action) => {
                                                            this.setState({
                                                                dashboard_welcomesection_show: action,
                                                            });
                                                        }}
                                                    />
                                                ))}

                                            {(user_info.user.role.user_role === "Account owner" ||
                                                user_info.user.role.user_role === "Bid Manager" ||
                                                user_info.user.role.user_role === "Contributor" ||
                                                user_info.user.role.user_role === "Guest") &&
                                                dashboard_recentctivitysection_show && (
                                                    <RecentActivity
                                                        recentactivites={recentactivites}
                                                        ref={(ref) => (this.RecentActivityEvent = ref)}
                                                        dashboard_recentctivitysection_show={dashboard_recentctivitysection_show}
                                                        dashboard_recentctivitysection_show_action={(action) => {
                                                            this.setState({
                                                                dashboard_recentctivitysection_show: action,
                                                            });
                                                        }}
                                                    />
                                                )}
                                            {(user_info.user.role.user_role === "Account owner" ||
                                                user_info.user.role.user_role === "Bid Manager" ||
                                                user_info.user.role.user_role === "Contributor" ||
                                                user_info.user.role.user_role === "Guest") &&
                                                dashboard_thisweeksection_show && (
                                                    <TasksThisWeek
                                                        taskthis_weeks={taskthis_weeks}
                                                        ref={(ref) => (this.TasksThisWeekEvent = ref)}
                                                        dashboard_thisweeksection_show={dashboard_thisweeksection_show}
                                                        dashboard_thisweeksection_show_action={(action) => {
                                                            this.setState({
                                                                dashboard_thisweeksection_show: action,
                                                            });
                                                        }}
                                                    />
                                                )}

                                            {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Reports Overview") &&
                                                dashboard_bidactivitysection_show && (
                                                    <BidActivity
                                                        bid_activity={bid_activity}
                                                        ref={(ref) => (this.BidActivityEvent = ref)}
                                                        dashboard_bidactivitysection_show={dashboard_bidactivitysection_show}
                                                        dashboard_bidactivitysection_show_action={(action) => {
                                                            this.setState({
                                                                dashboard_bidactivitysection_show: action,
                                                            });
                                                        }}
                                                    />
                                                )}

                                            {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Reports Overview") && dashboard_bidvaluesection_show && (
                                                <BidValues
                                                    bid_values={bid_values}
                                                    ref={(ref) => (this.BidValuesEvent = ref)}
                                                    dashboard_bidvaluesection_show={dashboard_bidvaluesection_show}
                                                    dashboard_bidvaluesection_show_action={(action) => {
                                                        this.setState({
                                                            dashboard_bidvaluesection_show: action,
                                                        });
                                                    }}
                                                />
                                            )}
                                            {(user_info.user.role.user_role === "Account owner" ||
                                                user_info.user.role.user_role === "Bid Manager" ||
                                                user_info.user.role.user_role === "Contributor" ||
                                                user_info.user.role.user_role === "Guest" ||
                                                user_info.user.role.user_role === "Reports Overview") &&
                                                dashboard_upcomingbidssection_show && (
                                                    <UpcomingBids
                                                        upcoming_bids={upcoming_bids}
                                                        dashboard_upcomingbidssection_show={dashboard_upcomingbidssection_show}
                                                        dashboard_upcomingbidssection_show_action={(action) => {
                                                            this.setState({
                                                                dashboard_upcomingbidssection_show: action,
                                                            });
                                                        }}
                                                    />
                                                )}

                                            <div className="col-xl-4 ">
                                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && dashboard_winlossrateratessection_show && (
                                                    <div className="card mb-sm-5 mb-lg-8 notify-activity">
                                                        <div className="card-header align-items-center border-0 mt-4 ">
                                                            <h4 className="card-title align-items-start flex-column m-0">
                                                                <span className="fw-bolder text-main-color">Win/Loss Rate</span>
                                                                <span className="text-muted fw-normal fs-6 text-grey-dark">In the last 30 Days.</span>
                                                            </h4>
                                                            <div className="card-toolbar">
                                                                <button type="button" className="btn btn-sm btn-icon" id="dashboard_winlossrate" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="icon-dots text-grey-dark icon-size"></i>
                                                                </button>
                                                                <div className="dropdown-menu menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_winlossrate">
                                                                    {/**
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div className="text-black" onClick={(event) => exportAsImage(this.dashboard_winlossrate_ref, "Win/Loss Rate")}>
                                                                            <i className="icon-download me-2 text-black"></i> <span>Download as image</span>
                                                                        </div>
                                                                    </div>
																	**/}
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div className="text-black" onClick={(event) => this.setState({ dashboard_winlossrateratessection_show: dashboard_winlossrateratessection_show ? false : true })}>
                                                                            <i className={"me-2 text-black" + (dashboard_winlossrate_show ? " icon-eye font-size-8" : " bi bi-eye")}></i>
                                                                            <span>{dashboard_winlossrate_show ? "Hide chart" : "Show chart"}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div ref={(node) => (this.dashboard_winlossrate_ref = node)} className={"card-body pt-0" + (dashboard_winlossrate_show ? "" : " d-none")}>
                                                            <div className="Bid-top">
                                                                <div className="d-flex flex-column">
                                                                    <div className="progress">
                                                                        <div
                                                                            className="progress-bar"
                                                                            role="progressbar"
                                                                            style={{ width: bid_rates?.bid_win ? Math.round((bid_rates?.bid_win / bid_rates?.bid_total) * 100) + "%" : 0 + "%" }}
                                                                            aria-valuenow={bid_rates?.bid_win ? Math.round((bid_rates?.bid_win / bid_rates?.bid_total) * 100) : 0}
                                                                            aria-valuemin="0"
                                                                            aria-valuemax="100"
                                                                        >
                                                                            {bid_rates && bid_rates.bid_win ? Math.round((bid_rates.bid_win / bid_rates.bid_total) * 100) : 0}%
                                                                        </div>
                                                                        <div
                                                                            className="progress-bar bid_loss"
                                                                            role="progressbar"
                                                                            style={{ width: bid_rates?.bid_loss ? Math.round((bid_rates?.bid_loss / bid_rates?.bid_total) * 100) + "%" : 0 + "%" }}
                                                                            aria-valuenow={bid_rates?.bid_loss ? Math.round((bid_rates?.bid_loss / bid_rates?.bid_total) * 100) : 0}
                                                                            aria-valuemin="0"
                                                                            aria-valuemax="100"
                                                                        >
                                                                            {bid_rates && bid_rates.bid_loss ? Math.round((bid_rates.bid_loss / bid_rates.bid_total) * 100) : 0}%
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mt-1">
                                                                        <span className="text-purple">${bid_rates && bid_rates.bid_win_amount ? bid_rates.bid_win_amount : 0}</span>
                                                                        <span className="text-grey-dark">${bid_rates && bid_rates.bid_loss_amount ? bid_rates.bid_loss_amount : 0}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {user_info.user.role.user_role === "Account owner" && dashboard_usercountssection_show && (
                                                    <div className="card notify-activity mb-lg-8 ">
                                                        <div className="card-header align-items-center border-0 mt-4 ">
                                                            <h4 className="card-title align-items-start flex-column m-0">
                                                                <span className="fw-bolder text-main-color">User Counts</span>
                                                                <span className="text-muted fw-normal fs-6 text-grey-dark">Available licenses.</span>
                                                            </h4>
                                                            <div className="card-toolbar">
                                                                <button type="button" className="btn btn-sm btn-icon" id="dashboard_usercounts" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="icon-dots text-grey-dark icon-size"></i>
                                                                </button>
                                                                <div className="dropdown-menu menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_usercounts">
                                                                    {/**
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div className="text-black" onClick={(event) => exportAsImage(this.dashboard_usercounts_ref, "User Counts")}>
                                                                            <i className="icon-download me-2 text-black"></i> <span>Download as image</span>
                                                                        </div>
                                                                    </div>
																	**/}
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div className="text-black" onClick={(event) => this.setState({ dashboard_usercountssection_show: dashboard_usercountssection_show ? false : true })}>
                                                                            <i className={"me-2 text-black" + (dashboard_usercounts_show ? " icon-eye font-size-8" : " bi bi-eye")}></i>
                                                                            <span>{dashboard_usercounts_show ? "Hide chart" : "Show chart"}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div ref={(node) => (this.dashboard_usercounts_ref = node)} className={"card-body pt-0 pe-0 pb-5" + (dashboard_usercounts_show ? "" : " d-none")}>
                                                            <div className="Bid-top">
                                                                <div className="d-flex">
                                                                    <div className="text-counts">
                                                                        <h4 className="text-dark m-0 fs-1">
                                                                            <span className="fs-5tx text-blue ">{user_info?.company?.company_users}</span>
                                                                            {user_info?.subscription?.is_freetrail === false && <span>/{user_info?.company?.user_limits}</span>}
                                                                        </h4>
                                                                        <div className="fs-9 text-gray-700">
                                                                            <Link to="/settings-subscription" className="fs-7 text-grey-dark fw-bold-600">
                                                                                Purchase more<span className="icon-right-arrow fs-9 ps-2 more-icon"></span>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                                        <div className="flex-grow-1 mt-n15 text-end">
                                                                            <img className="counts-img" alt="Pic" src="assets/images/counts.svg" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {(user_info.user.role.user_role === "Account owner" ||
                                                user_info.user.role.user_role === "Bid Manager" ||
                                                user_info.user.role.user_role === "Contributor" ||
                                                user_info.user.role.user_role === "Guest" ||
                                                user_info.user.role.user_role === "Reports Overview") &&
                                                dashboard_latestbidhqsection_show && (
                                                    <LatestBidHQ
                                                        dashboard_latestbidhqsection_show={dashboard_latestbidhqsection_show}
                                                        dashboard_latestbidhqsection_show_action={(action) => {
                                                            this.setState({
                                                                dashboard_latestbidhqsection_show: action,
                                                            });
                                                        }}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Dashboard.error,
        loader: state.Dashboard.loader,
        isAuthenticated: state.Dashboard.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetDashboardStart: userActions.GetDashboardStart,
    onDashboardGreatJobs: userActions.DashboardGreatJobs,
    onDashboardRecentActivity: userActions.DashboardRecentActivity,
    onDashboardTasks: userActions.DashboardTasks,
    onGetBidActivity: userActions.GetBidActivity,
    onGetBidValues: userActions.GetBidValues,
    onUpcomingBids: userActions.UpcomingBids,
    onGetBidRate: userActions.GetBidRate,
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);