import React from "react";
import { Modal } from "react-bootstrap";

class OverwriteContentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            overwritecontent_modal: false,
        };
    }

    componentDidMount() {
        this.setState({
            overwritecontent_modal: this.props.overwritecontent_modal,
        });
    }

    OverwriteContentModalAction() {
        this.setState({
            overwritecontent_modal: false,
        });
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="overwritecontent_modal bid-custom-modal"
                    show={this.state.overwritecontent_modal}
                    onHide={() => {
                        this.props.overwritecontent_modal_action(false);
                    }}
                >
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-12 fv-row mb-4 mt-8 text-center">
							<i className="icon-overwrite overwrite-icon-fs"></i>
                                <h2 className="mt-8">Overwrite content?</h2>
                                <p className="mt-6">Looks like there’s already content in the section. <br/>Would you like to continue?</p>
                            </div>
                        </div>
                        <Modal.Footer>
                            <button type="button" className="hover-new btn cancel-butn rounded-50 w-150 text-gray-new" onClick={() => this.props.overwritecontent_modal_action(false)}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary w-140px remove-user rounded-50 w-150 hvr-grow"
                                onClick={() => {
                                    this.props.onConfirm();
                                }}
                            >
                                Yes
                            </button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default OverwriteContentModal;