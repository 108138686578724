import React, { useState, useEffect, useCallback } from "react";

import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";

import NotificationItems from "./NotificationItems";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

const NotificationsDashboard = (props) => {
    const dispatch = useDispatch();

    const [loader, setloader] = useState(false);
    const [desktop_notifications, setdesktop_notifications] = useState([]);
    const [desktop_notificationcount, setdesktop_notificationcount] = useState(0);

    const [filterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);

    const GetDesktopNotificationList = useCallback(() => {
        setloader(true);
        dispatch(userActions.GetDesktopNotificationList(filterlimit, filterpage)).then((response) => {
            setloader(false);
            if (response.success === true) {
                setdesktop_notifications(response.data);
                setdesktop_notificationcount(response.count);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, filterlimit, filterpage]);

    useEffect(() => {
        GetDesktopNotificationList();
    }, [GetDesktopNotificationList, filterlimit, filterpage]);

    const NotificationMarkRead = (event, notification) => {
        dispatch(userActions.NotificationMarkRead(notification.system_user_log_id)).then((response) => {
            if (response.success === true) {
                if (notification.action_type.indexOf("bid_") !== -1) {
                    window.location = "/bid-response/" + notification.bid_detail.bid_uuid;
                } else if (notification.action_type.indexOf("bidsection_") !== -1) {
                    window.location = "/bid-response/" + notification.bid_detail.bid_uuid + "?activesection=" + notification.response_detail.section_uuid;
                }
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    return (
        <React.Fragment>
            {loader ? <Loader /> : null}
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                        <LoginHeader page_title="Notifications" />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div className="container-xxl">
                                    <NotificationItems
                                        desktop_notifications={desktop_notifications}
                                        desktop_notificationcount={desktop_notificationcount}
                                        filterlimit={filterlimit}
                                        filterpage={filterpage}
                                        propsfilterpage={(event) => setfilterpage(event)}
                                        NotificationMarkRead={(event, notification) => NotificationMarkRead(event, notification)}
                                    />
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default NotificationsDashboard;