import React from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";

import * as userActions from "../../actions/index";
// import exportAsImage from "../../components/Utility/exportAsImage";
class LatestBidHQ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            latest_blogs: [],
            dashboard_latestbidhq_show: this.props.dashboard_latestbidhqsection_show,
        };
    }

    componentDidMount() {
        this.props.onGetLatestBidHQ().then((response) => {
            if (response.success === true) {
                this.setState({ latest_blogs: response.data });
            } else {
                console.log("response---------", response);
                notify.show(response.message, "error");
            }
        });
    }

    render() {
        const { latest_blogs, dashboard_latestbidhq_show } = this.state;
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        return (
            <React.Fragment>
                <div className="col-xl-4 notify-activity">
                    <div className={"card" + (dashboard_latestbidhq_show ? " card-xl-stretch " : "")}>
                        <div className="card-header align-items-center border-0 mt-4">
                            <h4 className="card-title align-items-start flex-column m-0">
                                <span className="fw-bolder text-main-color">Latest from BidHQ</span>
                                <span className="text-muted fw-normal fs-6 text-grey-dark">News, articles and resources.</span>
                            </h4>
                            <div className="card-toolbar">
                                <button type="button" className="btn btn-sm btn-icon" id="dashboard_latestbidhq" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="icon-dots text-grey-dark icon-size"></i>
                                </button>
                                <div className="dropdown-menu menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_latestbidhq">
                                    {/**
                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                        <div className="text-black" onClick={(event) => exportAsImage(this.dashboard_latestbidhq_ref, "Upcoming Bids")}>
                                            <i className="icon-download me-2 text-black"></i> <span>Download as image</span>
                                        </div>
                                    </div>
									**/}
                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                        <div className="text-black" onClick={(event) => this.props.dashboard_latestbidhqsection_show_action(dashboard_latestbidhq_show ? false : true)}>
                                            <i className={"me-2 text-black" + (dashboard_latestbidhq_show ? " icon-eye font-size-8" : " bi bi-eye")}></i>
                                            <span>{dashboard_latestbidhq_show ? "Hide chart" : "Show chart"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ref={(node) => (this.dashboard_latestbidhq_ref = node)} className={"card-body pt-0 up-bids" + (dashboard_latestbidhq_show ? "" : " d-none")}>
                            <div className="">
                                {latest_blogs.length > 0 &&
                                    latest_blogs.map((latest_blog) => (
                                        <div className="Bid-outer bid-blog-outer" key={"latest_blog_" + latest_blog.post_ID}>
                                            <div className="d-flex mb-4 p-3">
                                                <div className="symbol symbol-100px me-5">
                                                    <span className="symbol-label avtars-outer">
                                                        {latest_blog.post_thumbnail_url ? (
                                                            <img src={latest_blog.post_thumbnail_url} className="img-fluid" alt="Pic" />
                                                        ) : (
                                                            <img src="assets/images/training.png" className="img-fluid" alt="Pic" />
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                    <div className="flex-grow-1">
                                                        <span className="fw-normal fs-8 color1 d-flex">{renderHTML(latest_blog.post_reading_time)} mins read</span>
                                                        <p className="fs-7 fw-bold-600 mb-1">{latest_blog.post_title}</p>
                                                        <div className="fs-9 text-gray-700">
                                                            <Link target="_blank" to={{ pathname: latest_blog.post_link }} className="fs-7 text-main-color fw-bold-600">
                                                                Read More <span className="icon-right-arrow fs-9 ps-1 more-icon"></span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Dashboard.error,
        loader: state.Dashboard.loader,
        isAuthenticated: state.Dashboard.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetLatestBidHQ: userActions.GetLatestBidHQ,
};
export default connect(mapStateToProps, mapDispatchToProps)(LatestBidHQ);