import React from "react";
import moment from "moment";

// import exportAsImage from "../../components/Utility/exportAsImage";
class RecentActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 20,
            recentactivites: [],
            dashboard_recentctivity_show: this.props.dashboard_recentctivitysection_show,
        };
    }

    componentDidMount() {
        this.setState({
            recentactivites: this.props.recentactivites,
        });
    }

    RecentActivityReload() {
        this.setState({
            recentactivites: this.props.recentactivites,
        });
    }

    render() {
        const { recentactivites, dashboard_recentctivity_show } = this.state;
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        return (
            <React.Fragment>
                <div className="col-xl-4 act-main notify-activity">
                    <div className={"card mb-lg-8" + (dashboard_recentctivity_show ? " card-xl-stretch " : "")}>
                        <div className="card-header align-items-center border-0 mt-4">
                            <h4 className="card-title align-items-start flex-column m-0">
                                <span className="fw-bolder text-main-color">Recent activity</span>
                                <span className="text-muted fw-normal fs-6 text-grey-dark">Notifications directed just to you.</span>
                            </h4>
                            <div className="card-toolbar">
                                <div className="tab-none">
                                    <button type="button" className="btn btn-sm btn-icon" id="dashboard_recentctivity" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="icon-dots text-grey-dark icon-size"></i>
                                    </button>
                                    <div className="dropdown-menu menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_recentctivity">
                                        {/**
                                        <div className="py-4 px-2 cursor-pointer link-hover">
                                            <div className="text-black" onClick={(event) => exportAsImage(this.dashboard_recentctivity_ref, "Your recent activity")}>
                                                <i className="icon-download me-2 text-black"></i> <span>Download as image</span>
                                            </div>
                                        </div>
										**/}
                                        <div className="py-4 px-2 cursor-pointer link-hover">
                                            <div className="text-black" onClick={(event) => this.props.dashboard_recentctivitysection_show_action(dashboard_recentctivity_show ? false : true)}>
                                                <i className={"me-2 text-black" + (dashboard_recentctivity_show ? " icon-eye font-size-8" : " bi bi-eye")}></i>
                                                <span>{dashboard_recentctivity_show ? "Hide chart" : "Show chart"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-show">
                                    {/**
                                    <div className="px-2 cursor-pointer">
                                        <div className="text-gray" onClick={(event) => exportAsImage(this.dashboard_recentctivity_ref, "Your recent activity")}>
                                            <i className="icon-download text-gray"></i>
                                        </div>
                                    </div>
									**/}
                                    <div className="px-2 cursor-pointer eye-outer">
                                        <div className="text-gray" onClick={(event) => this.props.dashboard_recentctivitysection_show_action(dashboard_recentctivity_show ? false : true)}>
                                            <i className={"text-gray" + (dashboard_recentctivity_show ? " icon-eye font-size-8" : " bi bi-eye fs-4")}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ref={(node) => (this.dashboard_recentctivity_ref = node)} className={"card-body recent-activity-out pt-0 " + (dashboard_recentctivity_show ? "" : " d-none")}>
                            <div className="scroll-y r-activity-height">
                                {recentactivites.length > 0 ? (
                                    recentactivites.map((recentactivite, recentactivite_key) => (
                                        <div className="d-flex mb-4" key={"recentactivite_" + recentactivite_key}>
                                            <div className="symbol-group symbol-hover">
                                                <div className="symbol symbol-25px symbol-circle me-3">
                                                    {recentactivite?.action_user && recentactivite?.action_user.avatar ? (
                                                        <span className="symbol_img symbol-labely">
                                                            <img src={recentactivite?.action_user.avatar} alt={recentactivite?.action_user.first_name + " " + recentactivite?.action_user.last_name} />
                                                        </span>
                                                    ) : (
                                                        <span className="symbol-label bg-primary text-inverse-primary">{recentactivite?.action_user?.first_name?.slice(0, 1) + recentactivite?.action_user?.last_name?.slice(0, 1)}</span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                <div className="flex-grow-1">
                                                    <span className="text-muted fw-normal fs-7 text-grey-dark">
                                                        {moment(recentactivite.created_date).calendar(null, {
                                                            sameDay: "[Today] h:mm a",
                                                            nextDay: "[Tomorrow] h:mm a",
                                                            nextWeek: "ddd  DD MMM  YYYY h:mm a",
                                                            lastDay: "[Yesterday] h:mm a",
                                                            lastWeek: "ddd  DD MMM  YYYY h:mm a",
                                                            sameElse: "ddd  DD MMM  YYYY h:mm a",
                                                        })}
                                                    </span>
                                                    <div className="color1 fw-bold d-block fs-7">
                                                        <span className="color1 fs-7 notify-txt me-1">{recentactivite?.action_user?.first_name + " " + recentactivite?.action_user?.last_name}</span>
                                                        <span className="color1 fs-7 notify-txt notify-txt-2 ">{renderHTML(recentactivite?.desktopnotification_html)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-center">No records found</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default RecentActivity;