import React from "react";
import { Modal } from "react-bootstrap";

class CompleteBidZippingEverythingModal extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    className="completebid_zippingeverything_modal bid-custom-modal  "
                    show={this.props.completebid_zippingeverything_modal}
                    onHide={() => {
                        this.props.completebid_zippingeverything_modal_action(false);
                    }}
                >
                    <Modal.Body>
                        <div className="text-center m-0 py-4">
                            <img className="my-7" src="assets/images/icons/zipping.svg" alt="zipping" />
                            <h2 className="text-dark fw-bolder fs-22px">Zipping everything up!</h2>
                            <p className="mb-5 fs-6 mt-3">It might take a few seconds.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default CompleteBidZippingEverythingModal;