import React from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";

class LibraryReturnableDocumentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_returnabledocuments: {},
        };
    }
    render() {
        const { selected_returnabledocuments } = this.state;
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="library_returnabledocuments_modal bid-custom-modal doc-modal"
                    show={this.props.library_returnabledocuments_modal}
                    onHide={() => {
                        this.props.library_returnabledocuments_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Returnable documents</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.library_returnabledocuments && this.props.library_returnabledocuments.length !== 0 && (
                            <div className="library_returnabledocuments">
                                <div className="document-modal-body">
                                    <div className="row mb-4">
                                        {this.props.library_returnabledocuments.map((returnable_document) => {
                                            return (
                                                <div
                                                    className="col-md-4 col-xl-3 fv-row"
                                                    key={"library_returnable_documents_id_" + returnable_document.library_returnable_documents_id}
                                                    onClick={(event) => {
                                                        if (typeof selected_returnabledocuments[returnable_document.library_returnable_documents_id] === "undefined") {
                                                            selected_returnabledocuments[returnable_document.library_returnable_documents_id] = returnable_document;
                                                        } else {
                                                            delete selected_returnabledocuments[returnable_document.library_returnable_documents_id];
                                                        }
                                                        this.setState({ selected_returnabledocuments: selected_returnabledocuments });
                                                    }}
                                                >
                                                    <div className={"row main_returnable_documents_div" + (selected_returnabledocuments[returnable_document.library_returnable_documents_id] ? " section_active" : "")}>
                                                        <div className="col-sm-12 col-lg-12 mt-2">
                                                            {returnable_document?.media_type ? (
                                                                <div>
                                                                    {returnable_document.media_type.indexOf("jpeg") !== -1 && (
                                                                        <div className="doc-icon text-center mb-3">
                                                                            <i className="text-grey-dark icon-JPG fs-5x" aria-hidden="true"></i>
                                                                        </div>
                                                                    )}
                                                                    {returnable_document.media_type.indexOf("png") !== -1 && (
                                                                        <div className="doc-icon text-center mb-3">
                                                                            <i className="text-grey-dark icon-PNG fs-5x" aria-hidden="true"></i>
                                                                        </div>
                                                                    )}
                                                                    {returnable_document.media_type.indexOf("gif") !== -1 && (
                                                                        <div className="doc-icon text-center mb-3">
                                                                            <i className="text-grey-dark icon-GIF fs-5x" aria-hidden="true"></i>
                                                                        </div>
                                                                    )}
                                                                    {returnable_document.media_type.indexOf("svg") !== -1 && (
                                                                        <div className="doc-icon text-center mb-3">
                                                                            <i className="text-grey-dark icon-SVG fs-5x" aria-hidden="true"></i>
                                                                        </div>
                                                                    )}
                                                                    {returnable_document.media_type.indexOf("pdf") !== -1 && (
                                                                        <div className="doc-icon text-center mb-3">
                                                                            <i className="text-grey-dark icon-PDF fs-5x" aria-hidden="true"></i>
                                                                        </div>
                                                                    )}
                                                                    {returnable_document.media_type.indexOf("msword") !== -1 && (
                                                                        <div className="doc-icon text-center mb-3">
                                                                            <i className="text-grey-dark icon-DOC fs-5x" aria-hidden="true"></i>
                                                                        </div>
                                                                    )}
                                                                    {returnable_document.media_type.indexOf("spreadsheetml") !== -1 && (
                                                                        <div className="doc-icon text-center mb-3">
                                                                            <i className="text-grey-dark icon-XLS fs-5x" aria-hidden="true"></i>
                                                                        </div>
                                                                    )}
                                                                    {returnable_document.media_type.indexOf("officedocument") !== -1 && returnable_document.media_type.indexOf("spreadsheetml") === -1 && (
                                                                        <div className="doc-icon text-center mb-3">
                                                                            <i className="text-grey-dark icon-DOC fs-5x" aria-hidden="true"></i>
                                                                        </div>
                                                                    )}
                                                                    {returnable_document.media_type.indexOf("excel") !== -1 && (
                                                                        <div className="doc-icon text-center mb-3">
                                                                            <i className="text-grey-dark icon-XLS fs-5x" aria-hidden="true"></i>
                                                                        </div>
                                                                    )}
                                                                    {returnable_document.media_type.indexOf("csv") !== -1 && (
                                                                        <div className="doc-icon text-center mb-3">
                                                                            <i className="text-grey-dark icon-CSV fs-5x" aria-hidden="true"></i>
                                                                        </div>
                                                                    )}
                                                                    {returnable_document.media_type.indexOf("html") !== -1 && (
                                                                        <div className="doc-icon text-center mb-3">
                                                                            <i className="text-grey-dark icon-HTML fs-5x" aria-hidden="true"></i>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <div className="doc-icon text-center mb-3">
                                                                    <i className="text-grey-dark icon-Pdf fs-5x" aria-hidden="true"></i>
                                                                </div>
                                                            )}
                                                            <div className="folder_name">{returnable_document.title}</div>
                                                            <div className="folder_foles">Last modified {moment(returnable_document.updated_date).format("DD MMM YYYY")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center my-2 justify-content-end">
                                    <button
                                        type="button"
                                        className="btn cancel-butn rounded-50 w-150"
                                        onClick={() => {
                                            this.props.library_returnabledocuments_modal_action(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    {Object.keys(selected_returnabledocuments).length !== 0 && (
                                        <button
                                            type="button"
                                            className="btn btn-primary rounded-50 w-150px hvr-grow"
                                            onClick={(event) => {
                                                this.props.BidUpdateLibraryReturnableDocument(selected_returnabledocuments);
                                            }}
                                        >
                                            Select
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default LibraryReturnableDocumentModal;