import React from "react";
import { Modal } from "react-bootstrap";
class EnableTwoFactorAccountConfirmationModal extends React.Component {
    render() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                <Modal
                    className="enabletwofactoraccountconfirmation_modal bid-custom-modal"
                    show={this.props.enabletwofactoraccountconfirmation_modal}
                    onHide={() => {
                        this.props.enabletwofactoraccountconfirmation_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Enable two-factor authentication</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="auth-main text-center">
                            <i className="icon-tick1 title_vector_won fs-4x"></i>
                            <h5>Successfully enabled</h5>
                            <p className="mb-9">Your email {user_info.user ? user_info.user.email : "-"} is set to receive the authentification codes for logging in.</p>
                        </div>
                        <div className="modal-footer twofactor-footer">
                            <button
                                type="submit"
                                className="btn btn-primary remove-user rounded-50 w-140px hvr-grow"
                                onClick={() => {
                                    this.props.enabletwofactoraccountconfirmation_modal_action(false);
                                }}
                            >
                                Done
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default EnableTwoFactorAccountConfirmationModal;