import React from "react";
import { Link } from "react-router-dom";

import * as userActions from "../../actions/index";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

class NotFound extends React.Component {
    render() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title={userActions.GreetingOfTheData() + ", " + user_info.user.first_name + " " + user_info.user.last_name + "!"} />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div className="container-xxl">
                                        <div className="error-notify d-flex justify-content-center flex-column align-items-center">
                                            <figure>
                                                <img src="assets/images/error/lost.png" alt="#" className="img-fluid" />
                                            </figure>
                                            <h2 className="text-dark fw-bolder fs-2tx">Whoops - looks like you’re lost!</h2>
                                            <h5 className="fw-normal text-dark">Let’s get you back to the right place.</h5>
                                            <div className="d-flex justify-content-center mt-10">
                                                <span
                                                    className=" hvr-grow btn rounded-50 btn-lg btn-outline-primary me-4"
                                                    onClick={(event) => {
                                                        this.props.history.goBack();
                                                    }}
                                                >
                                                    <span className="indicator-label">Go back </span>
                                                </span>
                                                <Link to="/dashboard" className=" hvr-grow btn rounded-50 btn-lg btn-primary">
                                                    <span className="indicator-label">Go to Dashboard </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default NotFound;