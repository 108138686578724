import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const LibraryCaseStudyGridView = (props) => {
    const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
    return (
        <div className="card-body pt-7">
            <div className="row mb-4 return-doc">
                {props.library_contents.map((library_content) => {
                    return (
                        <div className="col-md-3 col-xl-2 fv-row" key={"library_content_" + library_content.content_id}>
                            <div className="row main_returnable_documents_div">
                                <div className="add_dropdowan_returnable_documents">
                                    <div className="dropdown " key="1">
                                        <div className="cursor-pointer" aria-expanded="false" id="1" data-bs-toggle="dropdown">
                                            <i className="icon-vertical-dots text-gray-new"></i>
                                        </div>
                                        <div className="dropdown-menu w-175px cursor-pointer" aria-labelledby="1">
                                            <Link className="dropdown-item" to={"/librarycontent-update/" + library_content.content_uuid}>
                                                <i className="fa fa-eye text-black me-3" aria-hidden="true"></i> View
                                            </Link>
                                            {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                <span className="dropdown-item" onClick={(event) => props.LibraryContentRename(library_content)}>
                                                    <i className="icon-edit text-black me-3" aria-hidden="true"></i> Rename
                                                </span>
                                            )}
                                            <span className="dropdown-item" onClick={(event) => props.LibraryContentMakeAsCopy(library_content)}>
                                                <i className="icon-copy text-black me-3" aria-hidden="true"></i> Make a copy
                                            </span>
                                            {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                <span
                                                    className="dropdown-item"
                                                    onClick={(event) => {
                                                        props.LibraryContentDelete(library_content);
                                                    }}
                                                >
                                                    <i className="icon-delete text-black me-3" aria-hidden="true"></i> Delete
                                                </span>
                                            )}
                                            <span
                                                className="dropdown-item"
                                                onClick={(event) => {
                                                    props.LibraryContentFilePreview(library_content);
                                                }}
                                            >
                                                <i className="icon-File text-black me-3" aria-hidden="true"></i> File info
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-12">
                                    <div className="folder-icon d-flex justify-content-center my-4">
                                        <i className="icon-folder-window " aria-hidden="true"></i>
                                    </div>
                                    <div className="folder_name mb-2">{library_content.content_name}</div>
                                    <div className="folder_foles">Last modified {moment(library_content.updated_date).format("DD/MM/YYYY")}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default LibraryCaseStudyGridView;