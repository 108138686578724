import React from "react";

import PdfDesignView from "../../../components/Utility/PdfDesignView";
class BidDesignPreviewTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bid_detail: "",
            response_pdf: "",
            response_content: [],
        };
    }

    componentDidMount() {
        this.setState({
            bid_detail: this.props.bid_detail,
            response_pdf: this.props.response_pdf,
            response_content: this.props.bid_response_form.response_content,
        });
    }

    BidDesignPreviewReload() {
        this.setState({
            bid_detail: this.props.bid_detail,
            response_pdf: this.props.response_pdf,
            response_content: this.props.bid_response_form.response_content,
        });
    }

    render() {
        const { response_pdf } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className={"col-lg-12"}>
                        <div className="pdf-fixed">{response_pdf && <PdfDesignView documentUrl={response_pdf} />}</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default BidDesignPreviewTab;