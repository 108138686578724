import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

let qs = require("qs");

/*** GetBids Action ***/
export const GetBidsStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetBids_START,
    };
};
export const GetBidsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetBids_SUCCESS,
    };
};
export const GetBidsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetBids_FAIL,
    };
};
export const GetBids = (bid_status, search, page, limit, sort, order) => {
    return (dispatch) => {
        dispatch(GetBidsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBids`,
            params: {
                bid_status: bid_status,
                search: search,
                page: page,
                limit: limit,
                sort: sort,
                order: order,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetCountByStatus Action ***/
export const GetCountByStatusStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetCountByStatus_START,
    };
};
export const GetCountByStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetCountByStatus_SUCCESS,
    };
};
export const GetCountByStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetCountByStatus_FAIL,
    };
};
export const GetCountByStatus = () => {
    return (dispatch) => {
        dispatch(GetCountByStatusStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetCountByStatus`,
        })
            .then(function (response) {
                dispatch(GetCountByStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCountByStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetTags Action ***/
export const GetTagsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetTags_START,
    };
};
export const GetTagsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetTags_SUCCESS,
    };
};
export const GetTagsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetTags_FAIL,
    };
};
export const GetTags = () => {
    return (dispatch) => {
        dispatch(GetTagsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidTags`,
        })
            .then(function (response) {
                dispatch(GetTagsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetTagsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidTypes Action ***/
export const GetBidTypesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetBidTypes_START,
    };
};
export const GetBidTypesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetBidTypes_SUCCESS,
    };
};
export const GetBidTypesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetBidTypes_FAIL,
    };
};
export const GetBidTypes = () => {
    return (dispatch) => {
        dispatch(GetBidTypesStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidTypes`,
        })
            .then(function (response) {
                dispatch(GetBidTypesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidTypesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateBid Action ***/
export const CreateBidStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.CreateBid_START,
    };
};
export const CreateBidSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CreateBid_SUCCESS,
    };
};
export const CreateBidFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CreateBid_FAIL,
    };
};
export const CreateBid = (form_data) => {
    return (dispatch) => {
        dispatch(CreateBidStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CreateBid`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CreateBidSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateBidFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateBid Action ***/
export const UpdateBidStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdateBid_START,
    };
};
export const UpdateBidSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateBid_SUCCESS,
    };
};
export const UpdateBidFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateBid_FAIL,
    };
};
export const UpdateBid = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateBidStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateBid`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateBidSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateBidFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeleteBid Action ***/
export const DeleteBidStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.DeleteBid_START,
    };
};
export const DeleteBidSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DeleteBid_SUCCESS,
    };
};
export const DeleteBidFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DeleteBid_FAIL,
    };
};
export const DeleteBid = (form_data) => {
    return (dispatch) => {
        dispatch(DeleteBidStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            url: `${actionType.environment_url}/api/DeleteBid`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(DeleteBidSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeleteBidFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidById Action ***/
export const GetBidByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetBidById_START,
    };
};
export const GetBidByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetBidById_SUCCESS,
    };
};
export const GetBidByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetBidById_FAIL,
    };
};
export const GetBidById = (bid_id) => {
    return (dispatch) => {
        dispatch(GetBidByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidById`,
            params: {
                bid_id: bid_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateBidTeam Action ***/
export const UpdateBidTeamStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdateBidTeam_START,
    };
};
export const UpdateBidTeamSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateBidTeam_SUCCESS,
    };
};
export const UpdateBidTeamFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateBidTeam_FAIL,
    };
};
export const UpdateBidTeam = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateBidTeamStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateBidTeam`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateBidTeamSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateBidTeamFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** RemoveBidTeam Action ***/
export const RemoveBidTeamStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.RemoveBidTeam_START,
    };
};
export const RemoveBidTeamSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.RemoveBidTeam_SUCCESS,
    };
};
export const RemoveBidTeamFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.RemoveBidTeam_FAIL,
    };
};
export const RemoveBidTeam = (form_data) => {
    return (dispatch) => {
        dispatch(RemoveBidTeamStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            url: `${actionType.environment_url}/api/RemoveBidTeam`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(RemoveBidTeamSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(RemoveBidTeamFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateBidOwner Action ***/
export const UpdateBidOwnerStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdateBidOwner_START,
    };
};
export const UpdateBidOwnerSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateBidOwner_SUCCESS,
    };
};
export const UpdateBidOwnerFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateBidOwner_FAIL,
    };
};
export const UpdateBidOwner = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateBidOwnerStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateBidOwner`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateBidOwnerSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateBidOwnerFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** RemoveBidOwner Action ***/
export const RemoveBidOwnerStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.RemoveBidOwner_START,
    };
};
export const RemoveBidOwnerSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.RemoveBidOwner_SUCCESS,
    };
};
export const RemoveBidOwnerFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.RemoveBidOwner_FAIL,
    };
};
export const RemoveBidOwner = (form_data) => {
    return (dispatch) => {
        dispatch(RemoveBidOwnerStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            url: `${actionType.environment_url}/api/RemoveBidOwner`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(RemoveBidOwnerSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(RemoveBidOwnerFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidtypeContent Action ***/
export const GetBidtypeContentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetBidtypeContent_START,
    };
};
export const GetBidtypeContentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetBidtypeContent_SUCCESS,
    };
};
export const GetBidtypeContentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetBidtypeContent_FAIL,
    };
};
export const GetBidtypeContent = (bidtype_id) => {
    return (dispatch) => {
        dispatch(GetBidtypeContentStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidtypeContent`,
            params: {
                bidtype_id: bidtype_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidtypeContentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidtypeContentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidSectionByBidId Action ***/
export const GetBidSectionByBidIdStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetBidSectionByBidId_START,
    };
};
export const GetBidSectionByBidIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetBidSectionByBidId_SUCCESS,
    };
};
export const GetBidSectionByBidIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetBidSectionByBidId_FAIL,
    };
};
export const GetBidSectionByBidId = (bid_id) => {
    return (dispatch) => {
        dispatch(GetBidSectionByBidIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidSectionByBidId`,
            params: {
                bid_id: bid_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidSectionByBidIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidSectionByBidIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidSectionById Action ***/
export const GetBidSectionByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetBidSectionById_START,
    };
};
export const GetBidSectionByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetBidSectionById_SUCCESS,
    };
};
export const GetBidSectionByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetBidSectionById_FAIL,
    };
};
export const GetBidSectionById = (section_id) => {
    return (dispatch) => {
        dispatch(GetBidSectionByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidSectionById`,
            params: {
                section_id: section_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidSectionByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidSectionByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidSectionInsert Action ***/
export const BidSectionInsertStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.BidSectionInsert_START,
    };
};
export const BidSectionInsertSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidSectionInsert_SUCCESS,
    };
};
export const BidSectionInsertFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidSectionInsert_FAIL,
    };
};
export const BidSectionInsert = (form_data) => {
    return (dispatch) => {
        dispatch(BidSectionInsertStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/BidSectionInsert`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidSectionInsertSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidSectionInsertFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidInsertNewBlankSection Action ***/
export const BidInsertNewBlankSectionStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.BidInsertNewBlankSection_START,
    };
};
export const BidInsertNewBlankSectionSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidInsertNewBlankSection_SUCCESS,
    };
};
export const BidInsertNewBlankSectionFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidInsertNewBlankSection_FAIL,
    };
};
export const BidInsertNewBlankSection = (form_data) => {
    return (dispatch) => {
        dispatch(BidInsertNewBlankSectionStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/BidInsertNewBlankSection`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidInsertNewBlankSectionSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidInsertNewBlankSectionFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidSectionRemove Action ***/
export const BidSectionRemoveStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.BidSectionRemove_START,
    };
};
export const BidSectionRemoveSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidSectionRemove_SUCCESS,
    };
};
export const BidSectionRemoveFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidSectionRemove_FAIL,
    };
};
export const BidSectionRemove = (form_data) => {
    return (dispatch) => {
        dispatch(BidSectionRemoveStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            url: `${actionType.environment_url}/api/BidSectionRemove`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidSectionRemoveSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidSectionRemoveFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidSectionUpdate Action ***/
export const BidSectionUpdateStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.BidSectionUpdate_START,
    };
};
export const BidSectionUpdateSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidSectionUpdate_SUCCESS,
    };
};
export const BidSectionUpdateFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidSectionUpdate_FAIL,
    };
};
export const BidSectionUpdate = (form_data) => {
    return (dispatch) => {
        dispatch(BidSectionUpdateStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/BidSectionUpdate`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidSectionUpdateSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidSectionUpdateFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidSectionShortingUpdate Action ***/
export const BidSectionShortingUpdateStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.BidSectionShortingUpdate_START,
    };
};
export const BidSectionShortingUpdateSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidSectionShortingUpdate_SUCCESS,
    };
};
export const BidSectionShortingUpdateFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidSectionShortingUpdate_FAIL,
    };
};
export const BidSectionShortingUpdate = (form_data) => {
    return (dispatch) => {
        dispatch(BidSectionShortingUpdateStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            url: `${actionType.environment_url}/api/BidSectionShortingUpdate`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidSectionShortingUpdateSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidSectionShortingUpdateFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidSectionUpdateTagUsers Action ***/
export const BidSectionUpdateTagUsersStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.BidSectionUpdateTagUsers_START,
    };
};
export const BidSectionUpdateTagUsersSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidSectionUpdateTagUsers_SUCCESS,
    };
};
export const BidSectionUpdateTagUsersFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidSectionUpdateTagUsers_FAIL,
    };
};
export const BidSectionUpdateTagUsers = (form_data) => {
    return (dispatch) => {
        dispatch(BidSectionUpdateTagUsersStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            url: `${actionType.environment_url}/api/BidSectionUpdateTagUsers`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidSectionUpdateTagUsersSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidSectionUpdateTagUsersFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidUploadDocument Action ***/
export const BidUploadDocumentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.BidUploadDocument_START,
    };
};
export const BidUploadDocumentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidUploadDocument_SUCCESS,
    };
};
export const BidUploadDocumentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidUploadDocument_FAIL,
    };
};
export const BidUploadDocument = (form_data) => {
    return (dispatch) => {
        dispatch(BidUploadDocumentStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/BidUploadDocument`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidUploadDocumentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidUploadDocumentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

export const BidUploadDocuments = (form_data) => {
    return (dispatch) => {
        dispatch(BidUploadDocumentStart());

        let requestArray = []

        for (const item of form_data) {
            let formdata = new FormData();
            for (let key in item) {
                formdata.append(key, item[key]);
            }

            requestArray.push(axios({
                method: "post",
                data: formdata,
                url: `${actionType.environment_url}/api/BidUploadDocument`,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
                },
            }))
        }

        return axios.all(requestArray)
            .then(function (response) {
                dispatch(BidUploadDocumentSuccess(response));
                return response;
            })
            .catch(function (error) {
                dispatch(BidUploadDocumentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidRemoveDocument Action ***/
export const BidRemoveDocumentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.BidRemoveDocument_START,
    };
};
export const BidRemoveDocumentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidRemoveDocument_SUCCESS,
    };
};
export const BidRemoveDocumentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidRemoveDocument_FAIL,
    };
};
export const BidRemoveDocument = (form_data) => {
    return (dispatch) => {
        dispatch(BidRemoveDocumentStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/BidRemoveDocument`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidRemoveDocumentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidRemoveDocumentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidDocumentTitleUpdate Action ***/
export const BidDocumentTitleUpdateStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.BidDocumentTitleUpdate_START,
    };
};
export const BidDocumentTitleUpdateSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidDocumentTitleUpdate_SUCCESS,
    };
};
export const BidDocumentTitleUpdateFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidDocumentTitleUpdate_FAIL,
    };
};
export const BidDocumentTitleUpdate = (form_data) => {
    return (dispatch) => {
        dispatch(BidDocumentTitleUpdateStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/BidDocumentTitleUpdate`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidDocumentTitleUpdateSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidDocumentTitleUpdateFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidUpdateLibraryReturnableDocument Action ***/
export const BidUpdateLibraryReturnableDocumentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.BidUpdateLibraryReturnableDocument_START,
    };
};
export const BidUpdateLibraryReturnableDocumentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidUpdateLibraryReturnableDocument_SUCCESS,
    };
};
export const BidUpdateLibraryReturnableDocumentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidUpdateLibraryReturnableDocument_FAIL,
    };
};
export const BidUpdateLibraryReturnableDocument = (form_data) => {
    return (dispatch) => {
        dispatch(BidUpdateLibraryReturnableDocumentStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/BidUpdateLibraryReturnableDocument`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidUpdateLibraryReturnableDocumentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidUpdateLibraryReturnableDocumentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidStatusUpdate Action ***/
export const BidStatusUpdateStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.BidStatusUpdate_START,
    };
};
export const BidStatusUpdateSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidStatusUpdate_SUCCESS,
    };
};
export const BidStatusUpdateFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidStatusUpdate_FAIL,
    };
};
export const BidStatusUpdate = (form_data) => {
    return (dispatch) => {
        dispatch(BidStatusUpdateStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/BidStatusUpdate`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidStatusUpdateSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidStatusUpdateFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateComment Action ***/
export const CreateCommentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.CreateComment_START,
    };
};
export const CreateCommentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CreateComment_SUCCESS,
    };
};
export const CreateCommentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CreateComment_FAIL,
    };
};
export const CreateComment = (form_data) => {
    return (dispatch) => {
        dispatch(CreateCommentStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CreateComment`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CreateCommentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateCommentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetCommentById Action ***/
export const GetCommentByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetCommentById_START,
    };
};
export const GetCommentByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetCommentById_SUCCESS,
    };
};
export const GetCommentByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetCommentById_FAIL,
    };
};
export const GetCommentById = (comment_id) => {
    return (dispatch) => {
        dispatch(GetCommentByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetCommentById`,
            params: {
                comment_id: comment_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetCommentByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCommentByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidSelectedStatusUpdate Action ***/
export const BidSelectedStatusUpdateStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.BidSelectedStatusUpdate_START,
    };
};
export const BidSelectedStatusUpdateSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidSelectedStatusUpdate_SUCCESS,
    };
};
export const BidSelectedStatusUpdateFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidSelectedStatusUpdate_FAIL,
    };
};
export const BidSelectedStatusUpdate = (form_data) => {
    return (dispatch) => {
        dispatch(BidSelectedStatusUpdateStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/BidSelectedStatusUpdate`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidSelectedStatusUpdateSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidSelectedStatusUpdateFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidSectionDueDateUpdate Action ***/
export const BidSectionDueDateUpdateStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.BidSectionDueDateUpdate_START,
    };
};
export const BidSectionDueDateUpdateSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidSectionDueDateUpdate_SUCCESS,
    };
};
export const BidSectionDueDateUpdateFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidSectionDueDateUpdate_FAIL,
    };
};
export const BidSectionDueDateUpdate = (form_data) => {
    return (dispatch) => {
        dispatch(BidSectionDueDateUpdateStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/BidSectionDueDateUpdate`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidSectionDueDateUpdateSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidSectionDueDateUpdateFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateSectionTeam Action ***/
export const UpdateSectionTeamStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdateSectionTeam_START,
    };
};
export const UpdateSectionTeamSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateSectionTeam_SUCCESS,
    };
};
export const UpdateSectionTeamFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateSectionTeam_FAIL,
    };
};
export const UpdateSectionTeam = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateSectionTeamStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateSectionTeam`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateSectionTeamSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateSectionTeamFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** RemoveSectionTeam Action ***/
export const RemoveSectionTeamStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.RemoveSectionTeam_START,
    };
};
export const RemoveSectionTeamSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.RemoveSectionTeam_SUCCESS,
    };
};
export const RemoveSectionTeamFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.RemoveSectionTeam_FAIL,
    };
};
export const RemoveSectionTeam = (form_data) => {
    return (dispatch) => {
        dispatch(RemoveSectionTeamStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            url: `${actionType.environment_url}/api/RemoveSectionTeam`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(RemoveSectionTeamSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(RemoveSectionTeamFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateSectionOwner Action ***/
export const UpdateSectionOwnerStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdateSectionOwner_START,
    };
};
export const UpdateSectionOwnerSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateSectionOwner_SUCCESS,
    };
};
export const UpdateSectionOwnerFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateSectionOwner_FAIL,
    };
};
export const UpdateSectionOwner = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateSectionOwnerStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateSectionOwner`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateSectionOwnerSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateSectionOwnerFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** RemoveSectionOwner Action ***/
export const RemoveSectionOwnerStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.RemoveSectionOwner_START,
    };
};
export const RemoveSectionOwnerSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.RemoveSectionOwner_SUCCESS,
    };
};
export const RemoveSectionOwnerFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.RemoveSectionOwner_FAIL,
    };
};
export const RemoveSectionOwner = (form_data) => {
    return (dispatch) => {
        dispatch(RemoveSectionOwnerStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            url: `${actionType.environment_url}/api/RemoveSectionOwner`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(RemoveSectionOwnerSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(RemoveSectionOwnerFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetResponseRecord Action ***/
export const GetResponseRecordStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetResponseRecord_START,
    };
};
export const GetResponseRecordSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetResponseRecord_SUCCESS,
    };
};
export const GetResponseRecordFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetResponseRecord_FAIL,
    };
};
export const GetResponseRecord = (bid_id) => {
    return (dispatch) => {
        dispatch(GetResponseRecordStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetResponseRecord`,
            params: {
                bid_id: bid_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetResponseRecordSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetResponseRecordFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DesignPreviewGenratepdf Action ***/
export const DesignPreviewGenratepdfStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.DesignPreviewGenratepdf_START,
    };
};
export const DesignPreviewGenratepdfSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DesignPreviewGenratepdf_SUCCESS,
    };
};
export const DesignPreviewGenratepdfFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DesignPreviewGenratepdf_FAIL,
    };
};
export const DesignPreviewGenratepdf = (bid_id) => {
    return (dispatch) => {
        dispatch(DesignPreviewGenratepdfStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/DesignPreviewGenratepdf`,
            params: {
                bid_id: bid_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(DesignPreviewGenratepdfSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DesignPreviewGenratepdfFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DesignPreviewUpdatepdf Action ***/
export const DesignPreviewUpdatepdfStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.DesignPreviewUpdatepdf_START,
    };
};
export const DesignPreviewUpdatepdfSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DesignPreviewUpdatepdf_SUCCESS,
    };
};
export const DesignPreviewUpdatepdfFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DesignPreviewUpdatepdf_FAIL,
    };
};
export const DesignPreviewUpdatepdf = (form_data) => {
    return (dispatch) => {
        dispatch(DesignPreviewUpdatepdfStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/DesignPreviewUpdatepdf`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(DesignPreviewUpdatepdfSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DesignPreviewUpdatepdfFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidLockStatusChange Action ***/
export const BidLockStatusChangeStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.BidLockStatusChange_START,
    };
};
export const BidLockStatusChangeSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidLockStatusChange_SUCCESS,
    };
};
export const BidLockStatusChangeFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidLockStatusChange_FAIL,
    };
};
export const BidLockStatusChange = (form_data) => {
    return (dispatch) => {
        dispatch(BidLockStatusChangeStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "patch",
            data: formdata,
            url: `${actionType.environment_url}/api/BidLockStatusChange`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidLockStatusChangeSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidLockStatusChangeFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GenrateBidZip Action ***/
export const GenrateBidZipStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GenrateBidZip_START,
    };
};
export const GenrateBidZipSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GenrateBidZip_SUCCESS,
    };
};
export const GenrateBidZipFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GenrateBidZip_FAIL,
    };
};
export const GenrateBidZip = (bid_id) => {
    return (dispatch) => {
        dispatch(GenrateBidZipStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GenrateBidZip`,
            params: {
                bid_id: bid_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GenrateBidZipSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GenrateBidZipFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidCompleteZipSend Action ***/
export const BidCompleteZipSendStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.BidCompleteZipSend_START,
    };
};
export const BidCompleteZipSendSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidCompleteZipSend_SUCCESS,
    };
};
export const BidCompleteZipSendFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidCompleteZipSend_FAIL,
    };
};
export const BidCompleteZipSend = (bid_id) => {
    return (dispatch) => {
        dispatch(BidCompleteZipSendStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/BidCompleteZipSend`,
            params: {
                bid_id: bid_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(BidCompleteZipSendSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidCompleteZipSendFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidResponseShare Action ***/
export const GetBidResponseShareStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetBidResponseShare_START,
    };
};
export const GetBidResponseShareSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetBidResponseShare_SUCCESS,
    };
};
export const GetBidResponseShareFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetBidResponseShare_FAIL,
    };
};
export const GetBidResponseShare = (bid_id) => {
    return (dispatch) => {
        dispatch(GetBidResponseShareStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidResponseShare`,
            params: {
                bid_id: bid_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidResponseShareSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidResponseShareFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BidResponseShare Action ***/
export const BidResponseShareStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.BidResponseShare_START,
    };
};
export const BidResponseShareSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BidResponseShare_SUCCESS,
    };
};
export const BidResponseShareFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BidResponseShare_FAIL,
    };
};
export const BidResponseShare = (form_data) => {
    return (dispatch) => {
        dispatch(BidResponseShareStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/BidResponseShare`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BidResponseShareSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BidResponseShareFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidActivityLogs Action ***/
export const GetBidActivityLogsStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetBidActivityLogs_START,
    };
};
export const GetBidActivityLogsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetBidActivityLogs_SUCCESS,
    };
};
export const GetBidActivityLogsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetBidActivityLogs_FAIL,
    };
};
export const GetBidActivityLogs = (bid_id, limit) => {
    return (dispatch) => {
        dispatch(GetBidActivityLogsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidActivityLogs`,
            params: {
                bid_id: bid_id,
                limit: limit,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidActivityLogsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidActivityLogsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidResponsePricingBySectionId Action ***/
export const GetBidResponsePricingBySectionIdStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetBidResponsePricingBySectionId_START,
    };
};
export const GetBidResponsePricingBySectionIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetBidResponsePricingBySectionId_SUCCESS,
    };
};
export const GetBidResponsePricingBySectionIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetBidResponsePricingBySectionId_FAIL,
    };
};
export const GetBidResponsePricingBySectionId = (section_id) => {
    return (dispatch) => {
        dispatch(GetBidResponsePricingBySectionIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidResponsePricingBySectionId`,
            params: {
                section_id: section_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidResponsePricingBySectionIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidResponsePricingBySectionIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateBidResponsePricing Action ***/
export const CreateBidResponsePricingStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.CreateBidResponsePricing_START,
    };
};
export const CreateBidResponsePricingSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CreateBidResponsePricing_SUCCESS,
    };
};
export const CreateBidResponsePricingFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CreateBidResponsePricing_FAIL,
    };
};
export const CreateBidResponsePricing = (form_data) => {
    return (dispatch) => {
        dispatch(CreateBidResponsePricingStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/CreateBidResponsePricing`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CreateBidResponsePricingSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateBidResponsePricingFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateBidResponsePricing Action ***/
export const UpdateBidResponsePricingStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.UpdateBidResponsePricing_START,
    };
};
export const UpdateBidResponsePricingSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateBidResponsePricing_SUCCESS,
    };
};
export const UpdateBidResponsePricingFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateBidResponsePricing_FAIL,
    };
};
export const UpdateBidResponsePricing = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateBidResponsePricingStart());

        return axios({
            method: "put",
            data: form_data,
            url: `${actionType.environment_url}/api/UpdateBidResponsePricing`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateBidResponsePricingSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateBidResponsePricingFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** RemoveBidContentPricing Action ***/
export const RemoveBidContentPricingStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.RemoveBidContentPricing_START,
    };
};
export const RemoveBidContentPricingSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.RemoveBidContentPricing_SUCCESS,
    };
};
export const RemoveBidContentPricingFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.RemoveBidContentPricing_FAIL,
    };
};
export const RemoveBidContentPricing = (response_pricing_id) => {
    return (dispatch) => {
        dispatch(RemoveBidContentPricingStart());
        return axios({
            method: "delete",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/RemoveBidContentPricing`,
            params: {
                response_pricing_id: response_pricing_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(RemoveBidContentPricingSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(RemoveBidContentPricingFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateBidResponsePricingGST Action ***/
export const UpdateBidResponsePricingGSTStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.UpdateBidResponsePricingGST_START,
    };
};
export const UpdateBidResponsePricingGSTSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateBidResponsePricingGST_SUCCESS,
    };
};
export const UpdateBidResponsePricingGSTFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateBidResponsePricingGST_FAIL,
    };
};
export const UpdateBidResponsePricingGST = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateBidResponsePricingGSTStart());

        return axios({
            method: "put",
            data: form_data,
            url: `${actionType.environment_url}/api/UpdateBidResponsePricingGST`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateBidResponsePricingGSTSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateBidResponsePricingGSTFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidLinkShareExtenal Action ***/
export const GetBidLinkShareExtenalStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetBidLinkShareExtenal_START,
    };
};
export const GetBidLinkShareExtenalSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetBidLinkShareExtenal_SUCCESS,
    };
};
export const GetBidLinkShareExtenalFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetBidLinkShareExtenal_FAIL,
    };
};
export const GetBidLinkShareExtenal = (bid_id) => {
    return (dispatch) => {
        dispatch(GetBidLinkShareExtenalStart());
        return axios({
            method: "get",
            headers: { "Content-Type": "multipart/form-data" },
            url: `${actionType.environment_url}/api/GetBidLinkShareExtenal`,
            params: { bid_id: bid_id },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidLinkShareExtenalSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidLinkShareExtenalFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GenrateBidLinkShareExtenalZip Action ***/
export const GenrateBidLinkShareExtenalZipStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GenrateBidLinkShareExtenalZip_START,
    };
};
export const GenrateBidLinkShareExtenalZipSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GenrateBidLinkShareExtenalZip_SUCCESS,
    };
};
export const GenrateBidLinkShareExtenalZipFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GenrateBidLinkShareExtenalZip_FAIL,
    };
};
export const GenrateBidLinkShareExtenalZip = (bid_id) => {
    return (dispatch) => {
        dispatch(GenrateBidLinkShareExtenalZipStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            url: `${actionType.environment_url}/api/GenrateBidLinkShareExtenalZip`,
            params: { bid_id: bid_id },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GenrateBidLinkShareExtenalZipSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GenrateBidLinkShareExtenalZipFail("Something went wrong, Please try again."));
                return error;
            });
    };
};