import React, { useRef, useState, useEffect } from "react";

import "./Dropzone.css";

const Dropzone = (props) => {
    const fileInputRef = useRef();
    const [validFiles, setValidFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [setErrorMessage] = useState("");
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);

    useEffect(() => {
        let filteredArr = selectedFiles.reduce((acc, current) => {
            const x = acc.find((item) => item.name === current.name);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        setValidFiles([...filteredArr]);
    }, [selectedFiles]);

    useEffect(() => {
        if (unsupportedFiles.length === 0 && validFiles.length) {
            props.FileUploadHandleChange(validFiles);
        }
    }, [props, unsupportedFiles, validFiles]);

    const dragOver = (event) => {
        event.preventDefault();
    };

    const dragEnter = (event) => {
        event.preventDefault();
    };

    const dragLeave = (event) => {
        event.preventDefault();
    };

    const fileDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    };

    const fileInputClicked = () => {
        fileInputRef.current.click();
    };

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
        }
    };

    const handleFiles = (files) => {
        for (let i = 0; i < files.length; i++) {
            if (validateFile(files[i])) {
                setSelectedFiles((prevArray) => [...prevArray, files[i]]);
            } else {
                files[i]["invalid"] = true;
                setSelectedFiles((prevArray) => [...prevArray, files[i]]);
                setErrorMessage("File type not permitted");
                setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
            }
        }
    };

    const validateFile = (file) => {
        // ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon']
        // ["application/pdf"]
        if (props.file_types) {
            if (props.file_types.indexOf(file.type) === -1) {
                return false;
            }
        }
        return true;
    };

    return (
        <>
            <div className="">
                {unsupportedFiles.length ? <p>Please remove all unsupported files.</p> : ""}
                {props.isTender && <div className="drop-container" onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave} onDrop={fileDrop} onClick={fileInputClicked}>
                    <p className="drop-message text-gray text-center">
                        Drop your file here or <span className="fw-bold-600 text-orange-color">browse on your computer</span><br />PNG, JPG and SVG
                    </p>
                    <input type="file" ref={fileInputRef} className="file-input" onChange={filesSelected} accept={props.inputaccept} multiple={props.multiple === false ? false : true} />
                </div>}
                {!props.isTender && <div className="drop-container" onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave} onDrop={fileDrop} onClick={fileInputClicked}>
                    <div className="drop-message">
                        Drop your file here or <span className="fw-bold-600 text-purple-dark">browse on your computer</span>
                    </div>
                    <input type="file" ref={fileInputRef} className="file-input" onChange={filesSelected} accept={props.inputaccept} multiple={props.multiple === false ? false : true} />
                </div>}

            </div>
        </>
    );
};
export default Dropzone;