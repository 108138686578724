import React from "react";
import { Modal } from "react-bootstrap";

import * as actionType from "../../../../constants/actionTypes";
import Validations from "../../../../components/Utility/Validations";

class AddUsersModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userinvite_form: {
                site_url: {
                    label: "Site url",
                    type: "hidden",
                    value: actionType.siteurl,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                email: {
                    label: "Email",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "e.g example@mail.com",
                    validations: { required: true, email: true },
                },
                role: {
                    label: "Role",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Access",
                    validations: { required: true },
                    options: [{ opk: "role_opk_0", name: "Select role", value: "" }],
                },
            },
        };
    }

    componentDidMount() {
        const update_userinvite_form = { ...this.state.userinvite_form };
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        let user_roles = this.props.user_roles;
        if (user_roles) {
            let roles_option = [{ opk: "role_opk_0", name: "Select Role", value: "" }];
            for (let key in user_roles) {
                if (user_info.user.role.user_role === "Bid Manager") {
                    if (user_roles[key].user_role !== "Account owner") {
                        roles_option.push({
                            opk: user_roles[key]["role_id"],
                            name: user_roles[key]["user_role"],
                            value: user_roles[key]["role_id"],
                        });
                    }
                } else {
                    roles_option.push({
                        opk: user_roles[key]["role_id"],
                        name: user_roles[key]["user_role"],
                        value: user_roles[key]["role_id"],
                    });
                }
            }
            update_userinvite_form.role.options = roles_option;
            this.setState({ userinvite_form: update_userinvite_form });
        }
    }

    inputChangeHandler(event, identifier) {
        const update_userinvite_form = { ...this.state.userinvite_form };
        const form_element = { ...update_userinvite_form[identifier] };

        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_userinvite_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_userinvite_form) {
            is_form_valid = update_userinvite_form[identifier].valid && is_form_valid;
        }

        this.setState({
            userinvite_form: update_userinvite_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_userinvite_form = this.state.userinvite_form;
        for (let key in update_userinvite_form) {
            let form_element = update_userinvite_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_userinvite_form[key] = form_element;
        }
        this.setState({ userinvite_form: update_userinvite_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.userinvite_form) {
                form_data[key] = this.state.userinvite_form[key].value;
            }
            this.props.InviteUserSubmit(form_data);
        }
    };

    render() {
        const { userinvite_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="addinviteduser_modal"
                    show={this.props.addinviteduser_modal}
                    onHide={() => {
                        this.props.addinviteduser_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Invite user</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="row mb-8">
                                <div className="col-lg-12 fv-row">
                                    <label className="form-label fs-6">{userinvite_form.email.label}</label>
                                    <input
                                        type={userinvite_form.email.type}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={userinvite_form.email.placeholder}
                                        value={userinvite_form.email.value ? userinvite_form.email.value : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "email")}
                                        onBlur={(event) => {
                                            userinvite_form.email.onBlur_out = true;
                                            this.setState({
                                                userinvite_form: userinvite_form,
                                            });
                                        }}
                                    />
                                    {!userinvite_form.email.valid && userinvite_form.email.onBlur_out ? <div className="error field-error">{userinvite_form.email.error_msg}</div> : ""}
                                </div>
                            </div>
                            <div className="row mb-8">
                                <div className="col-lg-12 fv-row">
                                    <label className="form-label fs-6">{userinvite_form.role.label}</label>
                                    <select className="form-select form-select-solid" value={userinvite_form.role.value} onChange={(event) => this.inputChangeHandler(event, "role")}>
                                        {userinvite_form.role.options.map((option) => (
                                            <option value={option.value} key={option.opk}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                    {!userinvite_form.role.valid && userinvite_form.role.onBlur_out ? <div className="error field-error">{userinvite_form.role.error_msg}</div> : ""}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary rounded-50 mt-2 mb-4 w-140px hvr-grow">
                                        Invite user
                                    </button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddUsersModal;