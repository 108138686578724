import React, { useState } from "react";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";

const ClientItems = (props) => {
    const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));

    const [client_name_show, setclient_name_show] = useState(true);
    const [client_email_show, setclient_email_show] = useState(true);
    const [client_number_show, setclient_number_show] = useState(true);
    const [abn_show, setabn_show] = useState(true);
    const [total_bids_show, settotal_bids_show] = useState(true);
    const [win_loss_rate_show, setwin_loss_rate_show] = useState(true);
    const [lifetime_value_show, setlifetime_value_show] = useState(true);

    const [columns, setcolumns] = useState([
        {
            sort: true,
            text: "Client name",
            dataField: "client_name",
            formatter: (cellContent, row, index, extraData) => {
                return user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Contributor" || user_info.user.role.user_role === "Guest" ? (
                    <Link to={`/client-update/${row.client_uuid}`}>{row.client_name}</Link>
                ) : (
                    row.client_name
                );
            },
        },
        {
            sort: false,
            text: "Email address",
            dataField: "client_email",
        },
        {
            sort: false,
            text: "Phone no.",
            dataField: "client_number",
        },
        {
            sort: false,
            text: "ABN no.",
            dataField: "abn",
        },
        {
            sort: true,
            text: "Total bids",
            dataField: "total_bids",
            formatter: (cellContent, row, index, extraData) => {
                return row.bids ? row.bids.length : 0;
            },
        },
        {
            sort: true,
            text: "Win/loss rate",
            dataField: "win_loss_rate_show",
            formatter: (cellContent, row, index, extraData) => {
                let bid_won = 0;
                let bid_loss = 0;
                let bids = row.bids;
                if (bids) {
                    for (let bid_key in bids) {
                        if (bids[bid_key].bid_status === "Won") {
                            bid_won++;
                        }
                        if (bids[bid_key].bid_status === "Lost") {
                            bid_loss++;
                        }
                    }
                }
                return bid_won + "/" + bid_loss;
            },
        },
        {
            sort: true,
            text: "Lifetime value",
            dataField: "lifetime_value_show",
            formatter: (cellContent, row, index, extraData) => {
                let bids = row.bids;
                let bidvalue_count = 0;
                if (bids) {
                    for (let bid_key in bids) {
                        bidvalue_count = bidvalue_count + parseFloat(bids[bid_key].bid_value);
                    }
                }
                return bidvalue_count;
            },
        },
        {
            text: "",
            dataField: "client_action",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="dropdown client-item-dropdown cursor-pointer" key={"client_action_" + row.client_id}>
                        {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Contributor" || user_info.user.role.user_role === "Guest") && (
                            <>
                                <div className="" aria-expanded="false" id={`client_action_${index}`} data-bs-toggle="dropdown">
                                    <img src="assets/images/icons/more.svg" title="more" alt="more" />
                                </div>
                                <div className="dropdown-menu" aria-labelledby={`client_action_${index}`}>
                                    <Link className="dropdown-item" to={`/client-update/${row.client_uuid}`}>
                                        Edit client information
                                    </Link>
                                    <Link className="dropdown-item" to={`/client-update/${row.client_uuid}?client-activetab=point-to-contact`}>
                                        View points of contact
                                    </Link>
                                    <Link className="dropdown-item" to={`/client-update/${row.client_uuid}?client-activetab=bids-to-client`}>
                                        View total bids to client
                                    </Link>
                                </div>
                            </>
                        )}
                    </div>
                );
            },
        },
    ]);

    const onColumnToggle = (identifier, actions) => {
        let newTableColumns = columns.map((val) => {
            if (val.dataField === identifier) {
                val.hidden = actions;
            }
            return val;
        });
        setcolumns(newTableColumns);
    };

    const handleTableChange = (type, { page, sizePerPage }) => {
        props.propsfilterpage(page);
    };

    const RemotePagination = ({ columns, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                page,
                totalSize,
                sizePerPage,
                custom: true,
                hidePageListOnlyOnePage: true,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive">
                        <BootstrapTable
                            remote
                            bootstrap4
                            striped
                            data={data}
                            keyField="client_name"
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered table-row-bordered align-middle custom-table-style"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-end flex-wrap pt-3 pb-7 pagination-main">
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </>
            )}
        </PaginationProvider>
    );

    return (
        <React.Fragment>
            <div className="row align-items-center">
                <div className="col-md-4">
                    <div className="d-flex align-items-center position-relative search-bids">
                        <span className="svg-icon svg-icon-3 position-absolute me-2">
                            <img src="assets/images/icons/search.svg" title="all_bids" alt="all_bids" />
                        </span>
                        <input
                            type="text"
                            placeholder="Search for client..."
                            className="form-control form-control-sm ps-9"
                            onChange={(event) => {
                                props.propsfiltersearch(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="d-flex align-items-center  justify-content-end">
                        <div className="me-5">
                            <div className="dropdown toggle-btn-dropdown column-drop-out column-menu">
                                <div aria-expanded="false" id="columns_menu" data-bs-toggle="dropdown" className="btn rounded-50 btn-white btn-sm hvr-grow text-dark ">
                                    <h6 className="text-dark m-0 fs-7">
                                        {" "}
                                        Columns<i className="icon-down-arrow text-dark fs-10 ms-2"></i>
                                    </h6>
                                </div>
                                <div className="dropdown-menu" aria-labelledby="columns_menu">
                                    <h6 className="fs-7 mb-3">Customise Fields</h6>

                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Client name</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={client_name_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("client_name", client_name_show);
                                                            setclient_name_show(client_name_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Email address</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={client_email_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("client_email", client_email_show);
                                                            setclient_email_show(client_email_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Phone no.</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={client_number_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("client_number", client_number_show);
                                                            setclient_number_show(client_number_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">ABN no</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={abn_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("abn", abn_show);
                                                            setabn_show(abn_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Total bids</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={total_bids_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("total_bids", total_bids_show);
                                                            settotal_bids_show(total_bids_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Win/loss rate</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={win_loss_rate_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("win_loss_rate_show", win_loss_rate_show);
                                                            setwin_loss_rate_show(win_loss_rate_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Lifetime value</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={lifetime_value_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("lifetime_value_show", lifetime_value_show);
                                                            setlifetime_value_show(lifetime_value_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                            <Link to="/client-create" className="rounded-50 btn btn-danger btn-sm hvr-grow c-create-btn">
                                Create new client
                                <span className="svg-icon svg-icon-2">
                                    <img src="assets/images/icons/white-add.svg" title="all_bids" alt="all_bids" />
                                </span>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <div className="card-body p-0 bg-white radious-style radious-users client-items-list mt-5">
                <RemotePagination data={props.clients} columns={columns} page={props.filterpage} totalSize={props.clientcount} sizePerPage={props.filterlimit} onTableChange={handleTableChange} />
            </div>
        </React.Fragment>
    );
};
export default ClientItems;