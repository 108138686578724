import React from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";

class BidShareHistoryModal extends React.Component {
    render() {
        const { bid_response_shares } = this.props;
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    className="bidsharehistory_modal bid-custom-modal "
                    show={this.props.bidsharehistory_modal}
                    onHide={() => {
                        this.props.bidsharehistory_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Share History</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="scroll-y r-activity-height">
                            {bid_response_shares.length > 0
                                ? bid_response_shares.map((bid_response_share) => (
                                      <div className="d-flex mb-4 pb-4  notify-outer" key={"share_key_" + bid_response_share.response_share_id}>
                                          <div className="symbol-group symbol-hover">
                                              <div className="symbol symbol-25px symbol-circle me-3">
                                                  {bid_response_share?.created_user && bid_response_share?.created_user.avatar ? (
                                                      <span className="symbol_img symbol-labely">
                                                          <img
                                                              src={bid_response_share?.created_user.avatar}
                                                              alt={bid_response_share?.created_user.first_name + " " + bid_response_share?.created_user.last_name}
                                                              title={bid_response_share?.created_user.first_name + " " + bid_response_share?.created_user.last_name}
                                                          />
                                                      </span>
                                                  ) : (
                                                      <span className="symbol-label bg-primary text-inverse-primary">{bid_response_share?.created_user.first_name?.slice(0, 1) + bid_response_share?.created_user.last_name?.slice(0, 1)}</span>
                                                  )}
                                              </div>
                                          </div>
                                          <div className=" justify-content-between">
                                              <span className="text-gray-400 fs-7 notify-txt me-1 fw-bolder">{bid_response_share?.created_user.first_name + " " + bid_response_share?.created_user.last_name}</span>
                                              <span className="text-gray-400 fs-7 notify-txt notify-txt-2">
                                                  <div>shared your bid with {bid_response_share?.invite_users.join(", ")} on</div>
                                              </span>
                                              <span className="text-gray-400 m-0 fs-6 d-flex">{moment(bid_response_share.created_date).format("DD MMMM YYYY")}</span>
                                          </div>
                                      </div>
                                  ))
                                : "No records found"}
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default BidShareHistoryModal;