import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { Redirect } from "react-router-dom";
import { render } from "@testing-library/react";

import BidCreateTab from "./BidCreateTab";
import BidDesignPreviewTab from "./BidDesignPreviewTab";
import BidReviewAndLockTab from "./BidReviewAndLockTab";
import BidExportAndShareTab from "./BidExportAndShareTab";

import BidRightSideBar from "./RightSideBar/BidRightSideBar";
import BidRightSideComment from "./RightSideBar/BidRightSideComment";
import BidRightSideSectionComment from "./RightSideBar/BidRightSideSectionComment";

import BidDesignPreviewRightSideBar from "./RightSideBar/BidDesignPreviewRightSideBar";

import InsertSectionModal from "./Modal/InsertSectionModal";
import AddBidTeamModal from "./Modal/AddBidTeamModal";
import AddBidOwnersModal from "./Modal/AddBidOwnersModal";

import AddLibraryFolder from "./Modal/AddLibraryFolder";
import SavetoLibraryModal from "./Modal/SavetoLibraryModal";

import AddSectionTeamModal from "./Modal/AddSectionTeamModal";
import AddSectionOwnerModal from "./Modal/AddSectionOwnerModal";

import OverwriteContentModal from "./Modal/OverwriteContentModal";

import BidShareHistoryModal from "./Modal/BidShareHistoryModal";
import LockingYourBidModal from "./Modal/LockingYourBidModal";

import CompleteBidZippingEverythingModal from "./Modal/CompleteBidZippingEverythingModal";
import CompleteBidOnTheWayModal from "./Modal/CompleteBidOnTheWayModal";
import CompleteBidCongratsModal from "./Modal/CompleteBidCongratsModal";

import DemoContentVideoModal from "./Modal/DemoContentVideoModal";
import ViewResponseMediaModal from "./Modal/ViewResponseMediaModal";

import ConfirmDialog from "../../../components/Utility/ConfirmDialog";

import * as userActions from "../../../actions/index";
import Loader from "../../../components/Utility/Loader";
import LoginHeader from "../../../components/LoginHeader";
import LoginSidebar from "../../../components/LoginSidebar";
import LoginFooter from "../../../components/LoginFooter";
import AccessDenied from "../../../containers/Page/AccessDenied.js";

class BidResponse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bid_response_form: {
                bid_id: {
                    label: "Bid ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                response_content: [],
            },

            setstate_loader: false,
            bid_access: true,
            is_pricing_content: true,

            bid_id: "",
            bid_detail: [],
            bid_users: [],
            company_users: [],
            manage_content: [],

            confirm_modal: false,

            bid_create_rightsidebar: true,
            bid_response_tabs: "bid_create", //bid_create, bid_designpreview, bid_reviewandlock, bid_exportandshare
            bid_designpreview_rightsidebar: false,

            bid_section_selected_handle: true,
            bid_create_rightsidebar_comment: false,
            bid_create_rightsidebar_sectioncomment: false,

            bid_team_modal: false,
            bid_owner_modal: false,
            bid_insert_section_modal: false,

            savetolibrary_modal: false,
            addlibraryfolder_modal: false,

            section_team_modal: false,
            section_owner_modal: false,
            overwritecontent_modal: false,

            lockingyourbid_modal: false,
            view_responsemedia_modal: false,

            bidsharehistory_modal: false,

            completebid_zippingeverything_modal: false,
            completebid_ontheway_modal: false,
            completebid_congrats_modal: false,

            demo_contentvideo_modal: false,

            response_pdf: "",
            response_pdf_thumbnail: "",
            response_record: "",
        };

        this.ConfirmDialogEvent = React.createRef();
        this.BidSelectedClickOutside = this.BidSelectedClickOutside.bind(this);
        this.setBidCreateWrapperRef = this.setBidCreateWrapperRef.bind(this);
        this.setBidCreateSideBarWrapperRef = this.setBidCreateSideBarWrapperRef.bind(this);
    }

    key_to_array_multidimensional(array_objects, key) {
        let custom_array = {};
        for (let map_key in array_objects) {
            let array_object = array_objects[map_key];
            let new_key = array_object[key];
            if (typeof custom_array[array_object[key]] === "undefined") {
                custom_array[new_key] = array_object;
            }
        }
        return custom_array;
    }

    async componentDidMount() {
        document.addEventListener("click", this.BidSelectedClickOutside);

        await this.props.onGetCompanyUsers().then((response) => {
            if (response.success === true) {
                this.setState({ company_users: response.data });
            } else {
                notify.show(response.message, "error");
            }
        });

        await this.GetBidById();
        await this.GetBidtypeContent();
        await this.CheckBidUserAccess();
        await this.GetLibraryWithoutContents();
    }

    GetBidById = async () => {
        await this.props.onGetBidById(this.props.bid_id).then((response) => {
            if (response.success === true) {
                let bid_detail = response.data;

                let bid_section_comment = [];
                let bid_response_comments = [];
                if (bid_detail.response_comments) {
                    let response_comments = bid_detail.response_comments;
                    for (let key in response_comments) {
                        let comment = response_comments[key];

                        //Comment: Separate section comments and bid comments
                        if (comment.section_id) {
                            if (typeof bid_section_comment[comment.section_id] === "undefined") {
                                bid_section_comment[comment.section_id] = [];
                            }
                            if (comment.parent_id === null) {
                                if (typeof bid_section_comment[comment.section_id][comment.comment_id] === "undefined") {
                                    bid_section_comment[comment.section_id][comment.comment_id] = {};
                                }
                                bid_section_comment[comment.section_id][comment.comment_id]["comment"] = comment;
                            } else {
                                if (typeof bid_section_comment[comment.section_id][comment.parent_id] === "undefined") {
                                    bid_section_comment[comment.section_id][comment.parent_id] = {};
                                }
                                if (typeof bid_section_comment[comment.section_id][comment.parent_id]["child"] === "undefined") {
                                    bid_section_comment[comment.section_id][comment.parent_id]["child"] = [];
                                }
                                bid_section_comment[comment.section_id][comment.parent_id]["child"].push(comment);
                            }
                        } else {
                            if (comment.parent_id === null) {
                                if (typeof bid_response_comments[comment.comment_id] === "undefined") {
                                    bid_response_comments[comment.comment_id] = {};
                                }
                                bid_response_comments[comment.comment_id]["comment"] = comment;
                            } else {
                                if (typeof bid_response_comments[comment.parent_id] === "undefined") {
                                    bid_response_comments[comment.parent_id] = {};
                                }
                                if (typeof bid_response_comments[comment.parent_id]["child"] === "undefined") {
                                    bid_response_comments[comment.parent_id]["child"] = [];
                                }
                                bid_response_comments[comment.parent_id]["child"].push(comment);
                            }
                        }
                    }
                }

                let bid_users = [];
                let bid_use_ids = [];
                if (bid_detail.bid_owners && bid_detail.bid_owners.length !== 0) {
                    let bid_owners = bid_detail.bid_owners;
                    for (let owner_key in bid_owners) {
                        let bid_owner = bid_owners[owner_key];
                        if (bid_use_ids.indexOf(parseInt(bid_owner.user_id)) === -1) {
                            bid_use_ids.push(bid_owner.user_id);
                            bid_users.push({
                                value: bid_owner.user_id,
                                avatar: bid_owner.avatar,
                                user_id: bid_owner.user_id,
                                first_name: bid_owner.first_name,
                                last_name: bid_owner.last_name,
                                key: bid_owner.first_name + " " + bid_owner.last_name,
                                name: bid_owner.first_name + " " + bid_owner.last_name,
                            });
                        }
                    }
                }

                if (bid_detail.bid_teams && bid_detail.bid_teams.length !== 0) {
                    let bid_teams = bid_detail.bid_teams;
                    for (let team_key in bid_teams) {
                        let bid_team = bid_teams[team_key];
                        if (bid_use_ids.indexOf(parseInt(bid_team.user_id)) === -1) {
                            bid_use_ids.push(bid_team.user_id);
                            bid_users.push({
                                value: bid_team.user_id,
                                avatar: bid_team.avatar,
                                user_id: bid_team.user_id,
                                first_name: bid_team.first_name,
                                last_name: bid_team.last_name,
                                key: bid_team.first_name + " " + bid_team.last_name,
                                name: bid_team.first_name + " " + bid_team.last_name,
                            });
                        }
                    }
                }

                bid_detail.bid_section_comment = bid_section_comment;
                bid_detail.bid_response_comments = bid_response_comments;

                this.setState(
                    {
                        bid_detail: bid_detail,
                        bid_users: bid_users,
                        bid_id: bid_detail?.bid_id,
                    },
                    function () {
                        this.GetBidSectionByBidId();
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetBidSectionByBidId = async () => {
        await this.props.onGetBidSectionByBidId(this.state.bid_id).then((response) => {
            if (response.success === true) {
                const update_bid_response_form = { ...this.state.bid_response_form };

                let is_pricing_content = false;
                let bidresponse_contents = response.data;
                for (let content_key in bidresponse_contents) {
                    let bidresponse_content = bidresponse_contents[content_key];
                    if (bidresponse_content.is_pricing) {
                        is_pricing_content = true;
                    }
                }

                let response_content = update_bid_response_form.response_content;
                response_content = response.data;
                update_bid_response_form.response_content = response_content;

                this.setState(
                    {
                        is_pricing_content: is_pricing_content,
                        bid_response_form: update_bid_response_form,
                    },
                    function () {
                        /*** MyTask Tab Active Start ***/
                        const queryParams = new URLSearchParams(window.location.search);
                        if (queryParams.get("activesection")) {
                            if (response_content) {
                                for (let bid_content_key in response_content) {
                                    if (response_content[bid_content_key].section_uuid === queryParams.get("activesection")) {
                                        this.BidSectionSelected(bid_content_key, response_content[bid_content_key]);
                                    }
                                }
                            }
                        }
                        /*** MyTask Tab Active End ***/
                    }
                );

                if (this.BidCreateEvent && this.BidCreateEvent !== undefined) {
                    this.BidCreateEvent.BidCreateTabReload();
                }
                if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
                    this.BidRightSideBarEvent.BidRightSideBarReload();
                }
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetBidSectionByBidIdNoUpdate = async () => {
        await this.props.onGetBidSectionByBidId(this.state.bid_id).then((response) => {
            if (response.success === true) {
                const update_bid_response_form = { ...this.state.bid_response_form };

                let is_pricing_content = false;
                let bidresponse_contents = response.data;
                for (let content_key in bidresponse_contents) {
                    let bidresponse_content = bidresponse_contents[content_key];
                    if (bidresponse_content.is_pricing) {
                        is_pricing_content = true;
                    }
                    for (let key in update_bid_response_form.response_content) {
                        if (update_bid_response_form.response_content[key].section_id === bidresponse_contents[content_key].section_id) {
                            bidresponse_content.sort_order = update_bid_response_form.response_content[key].sort_order
                        }
                    }
                }

                let response_content = update_bid_response_form.response_content;
                response_content = response.data;
                update_bid_response_form.response_content = response_content;

                this.setState(
                    {
                        is_pricing_content: is_pricing_content,
                        bid_response_form: update_bid_response_form
                    },
                    function () {
                        /*** MyTask Tab Active Start ***/
                        const queryParams = new URLSearchParams(window.location.search);
                        if (queryParams.get("activesection")) {
                            if (response_content) {
                                for (let bid_content_key in response_content) {
                                    if (response_content[bid_content_key].section_uuid === queryParams.get("activesection")) {
                                        this.BidSectionSelected(bid_content_key, response_content[bid_content_key]);
                                    }
                                }
                            }
                        }
                        /*** MyTask Tab Active End ***/
                    }
                );

                if (this.BidCreateEvent && this.BidCreateEvent !== undefined) {
                    this.BidCreateEvent.BidCreateTabReload();
                }
                if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
                    this.BidRightSideBarEvent.BidRightSideBarReload();
                }
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    CheckBidUserAccess = async () => {
        let bid_detail = this.state.bid_detail;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        if (user_info.user.role.user_role !== "Account owner" && user_info.user.role.user_role !== "Bid Manager") {
            let bid_users = [];
            if (bid_detail.bid_owners !== null) {
                let bid_owners = bid_detail.bid_owners;
                for (let bid_owner_key in bid_owners) {
                    bid_users.push(bid_owners[bid_owner_key].user_id);
                }
            }
            if (bid_detail.bid_teams !== null) {
                let bid_teams = bid_detail.bid_teams;
                for (let bid_team_key in bid_teams) {
                    bid_users.push(bid_teams[bid_team_key].user_id);
                }
            }
            this.setState({ bid_access: bid_users.includes(user_info.user.user_id) });
        }
    };

    GetBidSectionById = async (section_id) => {
        await this.props.onGetBidSectionById(section_id).then((response) => {
            if (response.success === true) {
                this.setState({ bid_section_selected: response.data }, function () {
                    if (this.BidCreateEvent && this.BidCreateEvent !== undefined) {
                        this.BidCreateEvent.BidCreateTabReload();
                    }
                    if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
                        this.BidRightSideBarEvent.BidRightSideBarReload();
                    }
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetBidtypeContent = async () => {
        let bidtype_id = this.state.bid_detail.bidtype_id;
        this.props.onGetBidtypeContent(bidtype_id).then((response) => {
            if (response.success === true) {
                this.setState({
                    manage_content: this.key_to_array_multidimensional(response.data, "content_id"),
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    load_UpdateBidTeam = async () => {
        await this.GetBidById();
        this.AddBidTeamEvent.AddBidTeamReload();
        if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
            this.BidRightSideBarEvent.BidRightSideBarReload();
        }
    };

    RemoveBidTeam = async (bid_detail, bid_team) => {
        render(
            <ConfirmDialog
                title={"Remove " + bid_team.first_name + " " + bid_team.last_name + " ?"}
                body="Are you sure you want to delete this team member?"
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmRemoveBidTeam(bid_detail, bid_team)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    };

    ConfirmRemoveBidTeam(bid_detail, bid_team) {
        const form_data = {};
        form_data["bid_id"] = bid_detail.bid_id;
        form_data["bid_team"] = bid_team.user_id;
        this.props.onRemoveBidTeam(form_data).then((response) => {
            if (response.success === true) {
                this.load_UpdateBidTeam();
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({ confirm_modal: false });
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    UpdateBidTeam(form_data) {
        this.setState({ bid_team_modal: false });

        if (form_data["bid_teams"].length > 0) {
            this.props.onUpdateBidTeam(form_data).then((response) => {
                if (response.success === true) {
                    this.load_UpdateBidTeam();
                    notify.show(response.message, "success");
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    }

    load_UpdateBidOwner = async () => {
        await this.GetBidById();
        this.AddBidOwnersEvent.AddBidOwnersReload();
        if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
            this.BidRightSideBarEvent.BidRightSideBarReload();
        }
    };

    UpdateBidOwner(form_data) {
        this.setState({ bid_owner_modal: false });

        if (form_data["bid_owners"].length > 0) {
            this.props.onUpdateBidOwner(form_data).then((response) => {
                if (response.success === true) {
                    this.load_UpdateBidOwner();
                    notify.show(response.message, "success");
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    }

    RemoveBidOwner = async (bid_detail, bid_owner) => {
        render(
            <ConfirmDialog
                title={"Remove " + bid_owner.first_name + " " + bid_owner.last_name + " ?"}
                body="Are you sure you want to delete this owner ?"
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmRemoveBidOwner(bid_detail, bid_owner)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    };

    ConfirmRemoveBidOwner(bid_detail, bid_owner) {
        const form_data = {};
        form_data["bid_id"] = bid_detail.bid_id;
        form_data["bid_owner"] = bid_owner.user_id;
        this.props.onRemoveBidOwner(form_data).then((response) => {
            if (response.success === true) {
                this.load_UpdateBidOwner();
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({ confirm_modal: false });
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    InsertSectionSubmit = async (form_data) => {
        const formdata = {};
        let manage_content = this.state.manage_content;

        formdata["response_content"] = JSON.stringify({
            bid_id: this.state.bid_id,
            content_id: manage_content[form_data.bid_content].content_id,
            sort_order: parseInt(this.state.bid_response_form.response_content.length) + 1,
            title: manage_content[form_data.bid_content].title,
            description: manage_content[form_data.bid_content].content,
            is_pricing: manage_content[form_data.bid_content].is_pricing,
        });

        this.props.onBidSectionInsert(formdata).then(async (response) => {
            if (response.success === true) {
                this.setState(
                    {
                        bid_insert_section_modal: false,
                    },
                    function () {
                        this.load_BidSelectedStatusUpdate(response.data.section_id);
                    }
                );

                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    BidInsertNewBlankSection = (event) => {
        const formdata = {};
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        formdata["title"] = "Section title";
        formdata["sort_order"] = parseInt(this.state.bid_response_form.response_content.length) + 1;

        this.props.onBidInsertNewBlankSection(formdata).then(async (response) => {
            if (response.success === true) {
                this.setState(
                    {
                        bid_insert_section_modal: false,
                    },
                    function () {
                        this.load_BidSelectedStatusUpdate(response.data.section_id);
                    }
                );
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    BidtypeDemoContentAvailable = (event, bid_content) => {
        const formdata = {};
        formdata["response_content"] = JSON.stringify({
            bid_id: bid_content.bid_id,
            section_id: bid_content.section_id,
            description: bid_content?.bidtype_content?.demo_content,
        });
        if (bid_content.description) {
            this.setState(
                {
                    overwritecontent_modal: true,
                },
                function () {
                    render(
                        <OverwriteContentModal
                            ref={(ref) => (this.OverwriteContentModalEvent = ref)}
                            overwritecontent_modal={this.state.overwritecontent_modal}
                            overwritecontent_modal_action={(modal_action) => {
                                this.setState(
                                    {
                                        overwritecontent_modal: modal_action,
                                    },
                                    function () {
                                        this.OverwriteContentModalEvent.OverwriteContentModalAction();
                                    }
                                );
                            }}
                            onConfirm={(event) => {
                                this.setState(
                                    {
                                        overwritecontent_modal: false,
                                    },
                                    function () {
                                        this.OverwriteContentModalEvent.OverwriteContentModalAction();
                                        this.BidSectionUpdateWithDemoContent(bid_content, formdata);
                                    }
                                );
                            }}
                        />
                    );
                }
            );
        } else {
            this.BidSectionUpdateWithDemoContent(bid_content, formdata);
        }
    };

    BidSectionUpdateWithDemoContent = (bid_content, formdata) => {
        this.props.onBidSectionUpdate(formdata).then((response) => {
            if (response.success === true) {
                this.load_BidSelectedStatusUpdate(bid_content.section_id);
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    BidtypeDemoVideoAvailable = (event, bid_content) => {
        this.setState({
            demo_contentvideo: bid_content,
            demo_contentvideo_modal: true,
        });
    };

    BidSectionRemove(bid_content) {
        render(
            <ConfirmDialog
                confirmButtonText={"Delete"}
                title={"Remove " + bid_content.title + "?"}
                body="Are you sure you want to delete this section? It’ll be gone permanently."
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmBidSectionRemove(bid_content)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    }

    load_BidSection = async () => {
        await this.GetBidSectionByBidId();
        this.BidCreateEvent.BidCreateTabReload();
        if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
            this.BidRightSideBarEvent.BidRightSideBarReload();
        }
    };

    ConfirmBidSectionRemove(bid_content) {
        this.setState({
            setstate_loader: true,
        });

        const form_data = {};
        form_data["bid_id"] = this.state.bid_detail.bid_id;
        form_data["section_id"] = bid_content.section_id;
        this.props.onBidSectionRemove(form_data).then((response) => {
            if (response.success === true) {
                this.load_BidSection();
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({
                    confirm_modal: false,
                    setstate_loader: false,
                });
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidSectionUpdate(form_data) {
        const formdata = {};
        formdata["response_content"] = JSON.stringify({
            bid_id: form_data.bid_id,
            title: form_data.title,
            description: form_data.description,
            section_id: form_data.section_id,
        });
        this.props.onBidSectionUpdate(formdata).then((response) => {
            if (response.success === true) {
                console.log("BidSectionUpdate response.data------------", response.data);
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidDocumentTitleUpdate(bid_id, response_media_id, avatar_title) {
        const formdata = {};
        formdata["bid_id"] = bid_id;
        formdata["avatar_title"] = avatar_title;
        formdata["response_media_id"] = response_media_id;
        this.props.onBidDocumentTitleUpdate(formdata).then((response) => {
            if (response.success === true) {
                console.log("BidDocumentTitleUpdate response.data------------", response.data);
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidStatusUpdate(event, bid_status) {
        event.preventDefault();
        const formdata = {};
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        formdata["bid_status"] = bid_status;
        this.props.onBidStatusUpdate(formdata).then((response) => {
            if (response.success === true) {
                this.GetBidById();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidCommentReply(event, bid_comment) {
        event.preventDefault();
        this.setState({
            bid_comment: bid_comment,
            bid_create_rightsidebar_comment: true,
        });
    }

    load_BidCommentEvent = async (new_comment) => {
        if (new_comment) {
            await this.GetBidById();
            let bid_detail = this.state.bid_detail;
            let comment_id = new_comment.parent_id === null ? new_comment.comment_id : new_comment.parent_id;
            this.setState({
                bid_create_rightsidebar_comment: true,
                bid_comment: bid_detail.bid_response_comments[comment_id],
            });
        }
        if (this.BidRightSideCommentEvent && this.BidRightSideCommentEvent !== undefined) {
            this.BidRightSideCommentEvent.BidRightSideCommentReload();
        }
    };

    BidCreateComment(comment_text, parent_id, tag_users) {
        const formdata = {};
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        formdata["comment_text"] = comment_text;
        formdata["tag_users"] = tag_users;
        formdata["parent_id"] = parent_id;
        this.props.onCreateComment(formdata).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
                this.load_BidCommentEvent(response.data);
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidUploadDocument(file, is_tender = false) {
        const formdata = {};
        formdata["file"] = file;
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        formdata["is_tender"] = is_tender
        this.props.onBidUploadDocument(formdata).then((response) => {
            if (response.success === true) {
                this.GetBidById();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidUpdateLibraryReturnableDocument = (selected_returnabledocuments) => {
        const formdata = {};
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        formdata["returnabledocuments"] = JSON.stringify(selected_returnabledocuments);
        this.props.onBidUpdateLibraryReturnableDocument(formdata).then((response) => {
            if (response.success === true) {
                this.GetBidById();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    BidRemoveDocument(event, media) {
        if (media.media_meta) {
            render(
                <ConfirmDialog
                    title={"Remove " + media.media_meta.title + "?"}
                    body="Are you sure you want to delete this document? It’ll be gone permanently."
                    confirm_modal={this.state.confirm_modal}
                    ref={(ref) => (this.ConfirmDialogEvent = ref)}
                    confirmButtonText={"Delete"}
                    onConfirm={(event) => this.ConfirmBidRemoveDocument(event, media)}
                    confirm_modal_action={(modal_action) => {
                        this.setState({ confirm_modal: modal_action });
                        this.ConfirmDialogEvent.ModalActionHide();
                    }}
                />
            );
        } else {
            this.ConfirmBidRemoveDocument(event, media);
        }
    }

    ConfirmBidRemoveDocument(event, media) {
        const formdata = {};
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        formdata["response_media_id"] = media.response_media_id;
        this.props.onBidRemoveDocument(formdata).then((response) => {
            if (response.success === true) {
                this.GetBidById();
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({ confirm_modal: false });
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidSectionSelected(bid_content_key, bid_content) {
        let response_content = this.state.bid_response_form.response_content;
        response_content = this.key_to_array_multidimensional(response_content, "section_id");

        this.setState({ bid_section_selected: response_content[bid_content.section_id] }, function () {
            if (this.BidCreateEvent && this.BidCreateEvent !== undefined) {
                this.BidCreateEvent.BidCreateTabReload();
            }
            if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
                this.BidRightSideBarEvent.BidRightSideBarReload();
            }
        });
    }

    BidSectionShortingUpdate = (response_contents) => {
        const formdata = {};
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        let bid_response_form = this.state.bid_response_form
        bid_response_form.response_content = response_contents
        this.setState({
            bid_response_form: bid_response_form
        })
        const response_contents_copy = []
        for (let key in response_contents) {
            let item = JSON.parse(JSON.stringify(response_contents[key]));
            item.sort_order = key
            response_contents_copy.push(item)
        }
        formdata["response_contents"] = JSON.stringify(response_contents_copy);
        this.props.onBidSectionShortingUpdate(formdata).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    BidSectionUpdateTagUsers = (user_detail, bid_content_key, bid_content) => {
        const formdata = {};
        formdata["bid_id"] = bid_content.bid_id;
        formdata["tag_user"] = user_detail.user_id;
        formdata["section_id"] = bid_content.section_id;
        this.props.onBidSectionUpdateTagUsers(formdata).then((response) => {
            if (response.success === true) {
                console.log("BidSectionUpdateTagUsers response-----------", response);
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetLibraryWithoutContents = () => {
        this.props.onGetLibraryWithoutContents().then((response) => {
            if (response.success === true) {
                this.setState({
                    library_lists: response.data,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    ConfirmSavetoLibrary = (event, bid_content) => {
        this.setState({
            savetolibrary_modal: true,
            library_bid_content: bid_content,
        });
    };

    SavetoLibrary = (form_data) => {
        this.props.onCreateLibraryContent(form_data).then((response) => {
            if (response.success === true) {
                notify.show("Content saved to library successfully", "success");
                this.setState({
                    savetolibrary_modal: false,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    BidAddNewLibrary = (event) => {
        this.setState({ addlibraryfolder_modal: true });
    };

    CreateLibrary = async (form_data) => {
        this.props.onCreateLibrary(form_data).then(async (response) => {
            if (response.success === true) {
                await this.GetLibraryWithoutContents();
                this.setState(
                    {
                        addlibraryfolder_modal: false,
                        library_bid_content_library_id: response.data.library_id,
                        library_bid_content_name: response.data.name,
                    },
                    function () {
                        if (this.AddSavetoLibraryModalEvent && this.AddSavetoLibraryModalEvent !== undefined) {
                            this.AddSavetoLibraryModalEvent.AddSavetoLibraryModalTabReload();
                        }
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    load_BidSelectedStatusUpdate = async (section_id) => {
        await this.GetBidSectionByBidIdNoUpdate();
        let response_content = this.state.bid_response_form.response_content;
        response_content = this.key_to_array_multidimensional(response_content, "section_id");
        this.setState({ bid_section_selected: response_content[section_id] }, function () {
            if (this.BidCreateEvent && this.BidCreateEvent !== undefined) {
                this.BidCreateEvent.BidCreateTabReload();
            }
            if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
                this.BidRightSideBarEvent.BidRightSideBarReload();
            }
        });
    };

    BidSelectedStatusUpdate = (event, section_selected, section_status) => {
        event.preventDefault();
        const formdata = {};
        formdata["bid_id"] = section_selected.bid_id;
        formdata["section_id"] = section_selected.section_id;
        formdata["section_status"] = section_status;
        this.props.onBidSelectedStatusUpdate(formdata).then((response) => {
            if (response.success === true) {
                this.load_BidSelectedStatusUpdate(section_selected.section_id);
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    BidSectionDueDateUpdate(section_due_date, section_selected) {
        const formdata = {};
        formdata["bid_id"] = section_selected.bid_id;
        formdata["section_id"] = section_selected.section_id;
        formdata["section_due_date"] = moment(section_due_date).format("YYYY-MM-DD");
        this.props.onBidSectionDueDateUpdate(formdata).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
                this.load_BidSelectedStatusUpdate(section_selected.section_id);
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    load_UpdateSectionTeam = async (section_id) => {
        await this.GetBidSectionByBidId();
        let response_content = this.state.bid_response_form.response_content;
        response_content = this.key_to_array_multidimensional(response_content, "section_id");
        this.setState({ bid_section_selected: response_content[section_id] }, function () {
            if (this.BidCreateEvent && this.BidCreateEvent !== undefined) {
                this.BidCreateEvent.BidCreateTabReload();
            }
            if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
                this.BidRightSideBarEvent.BidRightSideBarReload();
            }
            if (this.AddSectionTeamEvent && this.AddSectionTeamEvent !== undefined) {
                this.AddSectionTeamEvent.AddSectionTeamReload();
            }
        });
    };

    UpdateSectionTeam(form_data) {
        this.setState({ section_team_modal: false });

        if (form_data["section_teams"].length > 0) {
            this.props.onUpdateSectionTeam(form_data).then((response) => {
                if (response.success === true) {
                    this.load_UpdateSectionTeam(form_data["section_id"]);
                    notify.show(response.message, "success");
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    }

    RemoveSectionTeam = async (bid_section_selected, section_team) => {
        render(
            <ConfirmDialog
                title={"Remove " + section_team.first_name + " " + section_team.last_name + " ?"}
                body="Are you sure you want to delete this team member?"
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmRemoveSectionTeam(bid_section_selected, section_team)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    };

    ConfirmRemoveSectionTeam(bid_section_selected, section_team) {
        const form_data = {};
        form_data["bid_id"] = bid_section_selected.bid_id;
        form_data["section_id"] = bid_section_selected.section_id;
        form_data["section_team"] = section_team.user_id;
        this.props.onRemoveSectionTeam(form_data).then((response) => {
            if (response.success === true) {
                this.load_UpdateSectionTeam(form_data["section_id"]);
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({ confirm_modal: false });
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    load_UpdateSectionOwner = async (section_id) => {
        await this.GetBidSectionByBidId();
        let response_content = this.state.bid_response_form.response_content;
        response_content = this.key_to_array_multidimensional(response_content, "section_id");
        this.setState({ bid_section_selected: response_content[section_id] }, function () {
            if (this.BidCreateEvent && this.BidCreateEvent !== undefined) {
                this.BidCreateEvent.BidCreateTabReload();
            }
            if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
                this.BidRightSideBarEvent.BidRightSideBarReload();
            }
            if (this.AddSectionOwnerEvent && this.AddSectionOwnerEvent !== undefined) {
                this.AddSectionOwnerEvent.AddSectionOwnerReload();
            }
        });
    };

    UpdateSectionOwner(form_data) {
        this.setState({ section_owner_modal: false });

        if (form_data["section_owners"].length > 0) {
            this.props.onUpdateSectionOwner(form_data).then((response) => {
                if (response.success === true) {
                    this.load_UpdateSectionOwner(form_data["section_id"]);
                    notify.show(response.message, "success");
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    }

    RemoveSectionOwner = async (bid_section_selected, section_owner) => {
        render(
            <ConfirmDialog
                title={"Remove " + section_owner.first_name + " " + section_owner.last_name + " ?"}
                body="Are you sure you want to delete this owner?"
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmRemoveSectionOwner(bid_section_selected, section_owner)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    };

    ConfirmRemoveSectionOwner(bid_section_selected, section_owner) {
        const form_data = {};
        form_data["bid_id"] = bid_section_selected.bid_id;
        form_data["section_id"] = bid_section_selected.section_id;
        form_data["section_owner"] = section_owner.user_id;
        this.props.onRemoveSectionOwner(form_data).then((response) => {
            if (response.success === true) {
                this.load_UpdateSectionOwner(form_data["section_id"]);
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({ confirm_modal: false });
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    load_BidSectionCommentEvent = async (new_comment) => {
        if (new_comment) {
            await this.GetBidById();
            let bid_detail = this.state.bid_detail;
            let section_id = new_comment.section_id;
            let comment_id = new_comment.parent_id === null ? new_comment.comment_id : new_comment.parent_id;
            this.setState({
                bid_create_rightsidebar_sectioncomment: true,
                section_comment: bid_detail.bid_section_comment[section_id][comment_id],
            });
        }
        if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
            this.BidRightSideBarEvent.BidRightSideBarReload();
        }
        if (this.BidRightSideSectionCommentEvent !== undefined) {
            this.BidRightSideSectionCommentEvent.BidRightSideSectionCommentReload();
        }
    };

    BidSectionCommentReply(event, section_comment) {
        event.preventDefault();

        this.setState({
            section_comment: section_comment,
            bid_create_rightsidebar_sectioncomment: true,
        });
    }

    BidSectionComment(comment_text, parent_id, section_id, tag_users) {
        const formdata = {};
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        formdata["section_id"] = section_id;
        formdata["comment_text"] = comment_text;
        formdata["tag_users"] = tag_users;
        formdata["parent_id"] = parent_id;
        this.props.onCreateComment(formdata).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
                this.load_BidSectionCommentEvent(response.data);
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    UpdateSectionWithLibraryContent(event, content) {
        event.preventDefault();

        const formdata = {};
        let bid_section_selected = this.state.bid_section_selected;
        bid_section_selected.description += content.description;
        formdata["response_content"] = JSON.stringify({
            bid_id: this.state.bid_id,
            title: bid_section_selected.title,
            description: bid_section_selected.description,
            section_id: bid_section_selected.section_id,
        });
        this.props.onBidSectionUpdate(formdata).then((response) => {
            if (response.success === true) {
                this.load_BidSelectedStatusUpdate(response.data[1][0].section_id);
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    AddNewBidproposal(event, proposal) {
        event.preventDefault();
        const formdata = {};
        let bid_section_selected = this.state.bid_section_selected;
        bid_section_selected.description += proposal.description;

        formdata["response_content"] = JSON.stringify({
            bid_id: this.state.bid_id,
            title: bid_section_selected.title,
            description: bid_section_selected.description,
            section_id: bid_section_selected.section_id,
        });
        this.props.onBidSectionUpdate(formdata).then((response) => {
            if (response.success === true) {
                this.load_BidSelectedStatusUpdate(response.data[1][0].section_id);
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    /*** Set the wrapper ref ***/
    setBidCreateWrapperRef(node) {
        this.BidCreateWrapperRef = node;
    }
    setBidCreateSideBarWrapperRef(node) {
        this.BidCreateSideBarWrapperRef = node;
    }
    /*** Alert if clicked on outside of element ***/
    BidSelectedClickOutside(event) {
        if (this.state.bid_section_selected_handle && event.target?.className?.includes("bid_section_selected_handle_false") === false) {
            if (this.BidCreateWrapperRef && !this.BidCreateWrapperRef.contains(event.target) && this.BidCreateSideBarWrapperRef && !this.BidCreateSideBarWrapperRef.contains(event.target)) {
                this.setState({ bid_section_selected: undefined }, function () {
                    if (this.BidCreateEvent && this.BidCreateEvent !== undefined) {
                        this.BidCreateEvent.BidCreateTabReload();
                    }
                    if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
                        this.BidRightSideBarEvent.BidRightSideBarReload();
                    }
                });
            }
        }
    }

    /***
     ** Design Preview Tab functions
     ***/
    GetResponseRecord = async () => {
        await this.props.onGetResponseRecord(this.state.bid_id).then((response) => {
            if (response.success === true) {
                this.setState({ response_record: response.data }, async () => {
                    await this.DesignPreviewGenratepdf();
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    DesignPreviewGenratepdf = async () => {
        this.setState({ setstate_loader: true });
        this.props.onDesignPreviewGenratepdf(this.state.bid_detail.bid_id).then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        setstate_loader: false,
                        bid_create_rightsidebar: false,

                        response_pdf: response.pdf_url,
                        response_pdf_thumbnail: response.pdf_thumbnail_url,

                        bid_designpreview_rightsidebar: true,
                        bid_response_tabs: "bid_designpreview",
                    },
                    function () {
                        if (this.BidDesignPreviewEvent !== undefined) {
                            this.BidDesignPreviewEvent.BidDesignPreviewReload();
                        }
                        if (this.BidDesignPreviewRightSideBarEvent !== undefined) {
                            this.BidDesignPreviewRightSideBarEvent.BidDesignPreviewRightSideBarReload();
                        }
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    DesignPreviewUpdatepdf(form_data) {
        this.props.onDesignPreviewUpdatepdf(form_data).then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        response_pdf: "",
                        response_pdf_thumbnail: "",
                    },
                    async () => {
                        if (this.BidDesignPreviewRightSideBarEvent !== undefined) {
                            this.BidDesignPreviewRightSideBarEvent.BidDesignPreviewRightSideBarReload();
                        }
                        if (this.BidDesignPreviewEvent !== undefined) {
                            this.BidDesignPreviewEvent.BidDesignPreviewReload();
                        }
                        await this.GetResponseRecord();
                        // setTimeout(async () => {
                        // await this.GetResponseRecord();
                        // }, 5000);
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    /**
     *** Review and Lock
     **/
    ViewResponseMedia = (media_detail) => {
        this.setState({
            view_responsemedia_modal: true,
            responsemedia_detail: media_detail,
        });
    };

    load_BidReviewAndLockTab = async () => {
        await this.GetBidById();
        if (this.BidReviewAndLockEvent !== undefined) {
            this.BidReviewAndLockEvent.BidReviewAndLockReload();
        }
    };
    BidLockAlert() {
        render(
            <ConfirmDialog
                confirmButtonText="OK"
                cancelButtonText="Cancel"
                title="Oops! Please lock your bid."
                body="You will need to lock your bid before continuing. This is to ensure that the export reflects the latest approval."
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => {
                    this.ConfirmDialogEvent.ModalActionHide();
                    this.setState({ confirm_modal: false });
                }}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    }

    BidUnlockConfirm(event) {
        render(
            <ConfirmDialog
                cancelButtonText="Cancel"
                confirmButtonText="I understand"
                title="Are you sure?"
                body="Unlocking will allow bid to be editable again and creating another version. We recommend labeling past exports accurately to avoid doubling-up!"
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => {
                    this.BidLockStatusChange(false);
                    this.ConfirmDialogEvent.ModalActionHide();
                    this.setState({ confirm_modal: false });
                }}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    }

    BidLockConfirm = (event) => {
        this.setState(
            {
                lockingyourbid_modal: true,
            },
            function () {
                this.BidLockStatusChange(true);
            }
        );
    };

    BidLockStatusChange(lock_status) {
        const formdata = {};
        formdata["bid_id"] = this.state.bid_id;
        formdata["lock_status"] = lock_status;
        this.props.onBidLockStatusChange(formdata).then((response) => {
            if (response.success === true) {
                this.load_BidReviewAndLockTab();
                setTimeout(() => {
                    this.setState({
                        lockingyourbid_modal: false,
                    });
                }, 2000);
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    /**
     ***  Export and Share
     **/

    GenrateBidZip = () => {
        this.setState({ completebid_zippingeverything_modal: true });
        this.props.onGenrateBidZip(this.state.bid_id).then((response) => {
            if (response.success === true) {
                setTimeout(() => {
                    this.setState(
                        {
                            bidzip_url: response.bidzip_url,
                            completebid_zippingeverything_modal: false,
                        },
                        function () {
                            this.DownloadBidZip(response.bidzip_url);
                        }
                    );
                }, 2000);
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    DownloadBidZip(bidzip_url) {
        const link = document.createElement("a");
        link.href = bidzip_url;
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    BidShareHistory = (event, bid_response_shares) => {
        this.setState({
            bidsharehistory_modal: true,
            bid_response_shares: bid_response_shares,
        });
    };

    load_BidExportAndShareTab = async () => {
        if (this.BidExportAndShareEvent && this.BidExportAndShareEvent !== undefined) {
            this.BidExportAndShareEvent.BidExportAndShareReload();
        }
    };

    BidResponseShare(form_data) {
        const formdata = {};
        formdata["bid_id"] = this.state.bid_id;
        formdata["invite_users"] = form_data["invite_users"];
        formdata["message"] = form_data["message"];
        this.setState({ completebid_ontheway_modal: true });
        this.props.onBidResponseShare(formdata).then((response) => {
            if (response.success === true) {
                setTimeout(() => {
                    this.setState({
                        completebid_ontheway_modal: false,
                    });
                }, 2000);

                this.load_BidExportAndShareTab();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    BidCompleteZipSend = () => {
        this.setState({ completebid_congrats_modal: true });
        // this.props.onBidCompleteZipSend(this.state.bid_id).then((response) => {
        // if (response.success === true) {
        // this.setState({
        // completebid_congrats_modal: true,
        // });
        // } else {
        // notify.show(response.message, "error");
        // }
        // });
    };

    MarkAsSubmitted = (event) => {
        event.preventDefault();
        const formdata = {};
        formdata["bid_id"] = this.state.bid_detail.bid_id;
        formdata["bid_status"] = "Submitted";
        this.props.onBidStatusUpdate(formdata).then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        completebid_congrats_modal: false,
                    },
                    function () {
                        this.GetBidById();
                    }
                );

                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    render() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        const { bid_access, bid_detail, bid_users, bid_response_tabs, bid_create_rightsidebar, bid_designpreview_rightsidebar, bid_create_rightsidebar_comment, bid_create_rightsidebar_sectioncomment } = this.state;

        return (
            <React.Fragment>
                {this.props.loader ? <Loader /> : null}
                {this.state.setstate_loader ? <Loader /> : null}
                {this.props.isAuthenticated === false ? <Redirect to="/logout" /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                {this.state.demo_contentvideo_modal && (
                    <DemoContentVideoModal
                        demo_contentvideo={this.state.demo_contentvideo}
                        demo_contentvideo_modal={this.state.demo_contentvideo_modal}
                        demo_contentvideo_modal_action={(modal_action) => {
                            this.setState({
                                demo_contentvideo_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.lockingyourbid_modal && (
                    <LockingYourBidModal
                        lockingyourbid_modal={this.state.lockingyourbid_modal}
                        lockingyourbid_modal_action={(modal_action) => {
                            this.setState({
                                lockingyourbid_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.view_responsemedia_modal && (
                    <ViewResponseMediaModal
                        responsemedia_detail={this.state.responsemedia_detail}
                        view_responsemedia_modal={this.state.view_responsemedia_modal}
                        view_responsemedia_modal_action={(modal_action) => {
                            this.setState({
                                view_responsemedia_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.bidsharehistory_modal && (
                    <BidShareHistoryModal
                        bid_response_shares={this.state.bid_response_shares}
                        bidsharehistory_modal={this.state.bidsharehistory_modal}
                        bidsharehistory_modal_action={(modal_action) => {
                            this.setState({
                                bidsharehistory_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.completebid_zippingeverything_modal && (
                    <CompleteBidZippingEverythingModal
                        completebid_zippingeverything_modal={this.state.completebid_zippingeverything_modal}
                        completebid_zippingeverything_modal_action={(modal_action) => {
                            this.setState({
                                completebid_zippingeverything_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.completebid_ontheway_modal && (
                    <CompleteBidOnTheWayModal
                        completebid_ontheway_modal={this.state.completebid_ontheway_modal}
                        completebid_ontheway_modal_action={(modal_action) => {
                            this.setState({
                                completebid_ontheway_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.completebid_congrats_modal && (
                    <CompleteBidCongratsModal
                        completebid_congrats_modal={this.state.completebid_congrats_modal}
                        completebid_congrats_modal_action={(modal_action) => {
                            this.setState({
                                completebid_congrats_modal: modal_action,
                            });
                        }}
                        MarkAsSubmitted={(event) => this.MarkAsSubmitted(event)}
                    />
                )}

                {this.state.bid_insert_section_modal && (
                    <InsertSectionModal
                        bid_detail={bid_detail}
                        manage_content={this.state.manage_content}
                        is_pricing_content={this.state.is_pricing_content}
                        bid_insert_section_modal={this.state.bid_insert_section_modal}
                        InsertSectionSubmit={(form_data) => this.InsertSectionSubmit(form_data)}
                        BidInsertNewBlankSection={(event) => this.BidInsertNewBlankSection(event)}
                        bid_insert_section_modal_action={(modal_action) => {
                            this.setState({
                                bid_insert_section_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.bid_team_modal && (
                    <AddBidTeamModal
                        bid_detail={bid_detail}
                        ref={(ref) => (this.AddBidTeamEvent = ref)}
                        company_users={this.state.company_users}
                        bid_team_modal={this.state.bid_team_modal}
                        UpdateBidTeam={(form_data) => this.UpdateBidTeam(form_data)}
                        RemoveBidTeam={(bid_detail, bid_team) => {
                            this.setState({ confirm_modal: true }, function () {
                                this.RemoveBidTeam(bid_detail, bid_team);
                            });
                        }}
                        bid_team_modal_action={(modal_action) => {
                            this.setState({
                                bid_team_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.bid_owner_modal && (
                    <AddBidOwnersModal
                        bid_detail={this.state.bid_detail}
                        ref={(ref) => (this.AddBidOwnersEvent = ref)}
                        company_users={this.state.company_users}
                        bid_owner_modal={this.state.bid_owner_modal}
                        UpdateBidOwner={(form_data) => this.UpdateBidOwner(form_data)}
                        RemoveBidOwner={(bid_detail, bid_owner) => {
                            this.setState({ confirm_modal: true }, function () {
                                this.RemoveBidOwner(bid_detail, bid_owner);
                            });
                        }}
                        bid_owner_modal_action={(modal_action) => {
                            this.setState({
                                bid_owner_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.addlibraryfolder_modal && (
                    <AddLibraryFolder
                        addlibraryfolder_modal={this.state.addlibraryfolder_modal}
                        CreateLibrary={(form_data) => this.CreateLibrary(form_data)}
                        addlibraryfolder_modal_action={(modal_action) => {
                            this.setState({
                                addlibraryfolder_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.savetolibrary_modal && (
                    <SavetoLibraryModal
                        library_lists={this.state.library_lists}
                        library_bid_content={this.state.library_bid_content}
                        library_bid_content_name={this.state.library_bid_content_name}
                        library_bid_content_library_id={this.state.library_bid_content_library_id}
                        savetolibrary_modal={this.state.savetolibrary_modal}
                        ref={(ref) => (this.AddSavetoLibraryModalEvent = ref)}
                        SavetoLibrary={(form_data) => this.SavetoLibrary(form_data)}
                        BidAddNewLibrary={(event) => this.BidAddNewLibrary(event)}
                        savetolibrary_modal_action={(modal_action) => {
                            this.setState({
                                savetolibrary_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.section_team_modal && (
                    <AddSectionTeamModal
                        bid_detail={this.state.bid_detail}
                        company_users={this.state.company_users}
                        ref={(ref) => (this.AddSectionTeamEvent = ref)}
                        section_team_modal={this.state.section_team_modal}
                        bid_section_selected={this.state.bid_section_selected}
                        UpdateSectionTeam={(form_data) => this.UpdateSectionTeam(form_data)}
                        RemoveSectionTeam={(bid_section_selected, section_team) => {
                            this.setState({ confirm_modal: true }, function () {
                                this.RemoveSectionTeam(bid_section_selected, section_team);
                            });
                        }}
                        section_team_modal_action={(modal_action) => {
                            this.setState(
                                {
                                    bid_section_selected_handle: true,
                                    section_team_modal: modal_action,
                                },
                                function () {
                                    this.GetBidById();
                                    document.addEventListener("click", this.BidSelectedClickOutside);
                                }
                            );
                        }}
                    />
                )}

                {this.state.section_owner_modal && (
                    <AddSectionOwnerModal
                        bid_detail={this.state.bid_detail}
                        company_users={this.state.company_users}
                        ref={(ref) => (this.AddSectionOwnerEvent = ref)}
                        section_owner_modal={this.state.section_owner_modal}
                        bid_section_selected={this.state.bid_section_selected}
                        UpdateSectionOwner={(form_data) => this.UpdateSectionOwner(form_data)}
                        RemoveSectionOwner={(bid_section_selected, section_owner) => {
                            this.setState({ confirm_modal: true }, function () {
                                this.RemoveSectionOwner(bid_section_selected, section_owner);
                            });
                        }}
                        section_owner_modal_action={(modal_action) => {
                            this.setState(
                                {
                                    bid_section_selected_handle: true,
                                    section_owner_modal: modal_action,
                                },
                                function () {
                                    document.addEventListener("click", this.BidSelectedClickOutside);
                                }
                            );
                        }}
                    />
                )}

                {bid_access === true ? (
                    <div
                        className={
                            "d-flex flex-column flex-root" +
                            (bid_create_rightsidebar && (user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Contributor")
                                ? " right-aside-wrap bid_create_rightsidebar"
                                : "") +
                            (bid_designpreview_rightsidebar ? " right-aside-wrap bid_designpreview_rightsidebar" : "") +
                            (bid_create_rightsidebar_comment ? " bid_rightsidebar_comment" : "") +
                            (bid_create_rightsidebar_sectioncomment ? " bid_rightsidebar_comment" : "") +
                            (bid_response_tabs === "bid_create" ? " bid-create-tab" : "") +
                            (bid_response_tabs === "bid_designpreview" ? " bid-designpreview-tab" : "") +
                            (bid_response_tabs === "bid_reviewandlock" ? " bid-reviewandlock-tab" : "") +
                            (bid_response_tabs === "bid_exportandshare" ? " bid-exportandshare-tab" : "")
                        }
                    >
                        <div className="page d-flex flex-row flex-column-fluid">
                            <LoginSidebar />
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <LoginHeader page_title="Bid response" />
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div>
                                        <div className="toolbar toolbar-fixed z-index-3 flex-wrap mb-10" id="kt_toolbar">
                                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack flex-wrap">
                                                <div className="page-title align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                                    <h2 className="d-flex align-items-center text-pink fw-bold-700 my-1 fs-2x">{bid_detail.bid_title}</h2>
                                                    <div className="d-flex fw-bold fs-6 my-1">
                                                        <div className="me-4 text-dark">
                                                            <span className="fw-bold-700">Bid ID: </span> {bid_detail.reference_number}
                                                        </div>
                                                        <div className="text-dark">
                                                            <span className="fw-bold-700">Client: </span> {bid_detail.client !== undefined ? bid_detail.client.client_name : "-"}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center py-1">
                                                    <div className="symbol-group symbol-hover me-10">
                                                        {bid_detail.bid_teams &&
                                                            bid_detail.bid_teams.map((bid_team) => {
                                                                return (
                                                                    <div className="custom-tooltip response-tooltip" key={"bid_owner_" + bid_team.user_id}>
                                                                        <div className="symbol symbol-25px symbol-circle">
                                                                            {bid_team && bid_team.avatar ? (
                                                                                <span className="symbol_img symbol-label">
                                                                                    <img src={bid_team.avatar} alt="bid_team_avatar" />
                                                                                    <span className="custom-tooltip-text ">{bid_team.first_name + " " + bid_team.last_name}</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span className="symbol-label bg-primary text-inverse-primary">
                                                                                    {bid_team.first_name?.slice(0, 1) + bid_team.last_name?.slice(0, 1)}
                                                                                    <span className="custom-tooltip-text ">{bid_team.first_name + " " + bid_team.last_name}</span>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                    <div className="bid-status-out">
                                                        {bid_detail.bid_status && (
                                                            <span
                                                                data-kt-menu-trigger="click"
                                                                data-kt-menu-placement="bottom-end"
                                                                className={"btn btn-progress btn-sm rounded-50 fs-7 bid-bg_" + bid_detail.bid_status.replace(" ", "_").toLowerCase()}
                                                            >
                                                                <span className="svg-icon-5 me-1">
                                                                    <i className={"p-0 bit_status_" + bid_detail.bid_status.replace(" ", "_").toLowerCase()}></i>
                                                                </span>
                                                                {bid_detail.bid_status}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="border-top container-fluid mt-2 w-100 d-flex overflow-auto h-40px nav-border-3">
                                                <ul className="nav nav-stretch custom-style">
                                                    <li className={"nav-item" + (this.state.bid_response_tabs === "bid_create" ? " active" : "")} key="bid_create_tab">
                                                        <span className="nav-link me-4">
                                                            <span className="bid-txt1">1</span> Bid Creation
                                                        </span>
                                                    </li>
                                                    {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                        <li className={"nav-item" + (this.state.bid_response_tabs === "bid_designpreview" ? " active" : "")} key="bid_designpreview_tab">
                                                            <span className="nav-link me-4">
                                                                <span className="bid-txt1">2</span> Design Preview
                                                            </span>
                                                        </li>
                                                    )}
                                                    {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                        <li className={"nav-item" + (this.state.bid_response_tabs === "bid_reviewandlock" ? " active" : "")} key="bid_reviewandlock_tab">
                                                            <span className="nav-link me-4">
                                                                <span className="bid-txt1">3</span> Review and Lock
                                                            </span>
                                                        </li>
                                                    )}
                                                    {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                        <li className={"nav-item" + (this.state.bid_response_tabs === "bid_exportandshare" ? " active" : "")} key="bid_exportandshare_tab">
                                                            <span className="nav-link me-4">
                                                                <span className="bid-txt1">4</span> Export and Share
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="post d-flex flex-column-fluid mg-120" id="kt_post">
                                            <div id="kt_content_container" className="container-xxl">
                                                {this.state.bid_response_tabs === "bid_create" && bid_detail.bid_id && (
                                                    <div id="bid_create_tab">
                                                        <BidCreateTab
                                                            bid_detail={bid_detail}
                                                            bid_users={bid_users}
                                                            ref={(ref) => (this.BidCreateEvent = ref)}
                                                            section_select_ref={this.setBidCreateWrapperRef}
                                                            bid_response_form={this.state.bid_response_form}
                                                            bid_section_selected={this.state.bid_section_selected}
                                                            BidUploadDocument={(file) => this.BidUploadDocument(file)}
                                                            BidSectionUpdate={(form_data) => this.BidSectionUpdate(form_data)}
                                                            BidDocumentTitleUpdate={(bid_id, response_media_id, avatar_title) => this.BidDocumentTitleUpdate(bid_id, response_media_id, avatar_title)}
                                                            BidUpdateLibraryReturnableDocument={(file) => this.BidUpdateLibraryReturnableDocument(file)}
                                                            bid_insert_section_action={(modal_action) => this.setState({ bid_insert_section_modal: modal_action })}
                                                            BidtypeDemoContentAvailable={(event, bid_content) => this.BidtypeDemoContentAvailable(event, bid_content)}
                                                            BidSectionRemove={(event, bid_content) => {
                                                                this.setState({ confirm_modal: true }, function () {
                                                                    this.BidSectionRemove(bid_content);
                                                                });
                                                            }}
                                                            BidRemoveDocument={(event, media) => {
                                                                this.setState({ confirm_modal: true }, function () {
                                                                    this.BidRemoveDocument(event, media);
                                                                });
                                                            }}
                                                            BidSectionSelected={(bid_content_key, bid_content) => this.BidSectionSelected(bid_content_key, bid_content)}
                                                            BidSectionUnselected={(event) => {
                                                                this.setState({ bid_section_selected: undefined }, function () {
                                                                    if (this.BidCreateEvent && this.BidCreateEvent !== undefined) {
                                                                        this.BidCreateEvent.BidCreateTabReload();
                                                                    }
                                                                    if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
                                                                        this.BidRightSideBarEvent.BidRightSideBarReload();
                                                                    }
                                                                });
                                                            }}
                                                            BidSectionShortingUpdate={(response_contents) => this.BidSectionShortingUpdate(response_contents)}
                                                            RefreshBidCreateTab={() => this.GetBidSectionByBidId()}
                                                            BidSectionUpdateTagUsers={(user_detail, bid_content_key, bid_content) => {
                                                                this.BidSectionUpdateTagUsers(user_detail, bid_content_key, bid_content);
                                                            }}
                                                            ConfirmSavetoLibrary={(event, bid_content) => this.ConfirmSavetoLibrary(event, bid_content)}
                                                        />
                                                        {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="nav_footer_button text-end mt-5">
                                                                        <button type="button" className="btn btn-danger btn-lg rounded-50 hvr-grow-arrow" onClick={(event) => this.GetResponseRecord(event)}>
                                                                            Continue to design <span className="icon-right-arrow fs-9 ps-1"></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}

                                                {this.state.bid_response_tabs === "bid_designpreview" && (
                                                    <div id="bid_designpreview_tab">
                                                        <BidDesignPreviewTab
                                                            bid_detail={bid_detail}
                                                            response_pdf={this.state.response_pdf}
                                                            response_pdf_thumbnail={this.state.response_pdf_thumbnail}
                                                            ref={(ref) => (this.BidDesignPreviewEvent = ref)}
                                                            bid_response_form={this.state.bid_response_form}
                                                        />
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="nav_footer_button text-end mt-5">
                                                                    <button
                                                                        type="button"
                                                                        className="btn head-btn-bg back-button btn-lg rounded-50 me-2"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                bid_response_tabs: "bid_create",
                                                                                bid_create_rightsidebar: true,
                                                                                bid_designpreview_rightsidebar: false,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <span className="icon-left-arrow fs-9"></span>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger btn-lg rounded-50 me-2 hvr-grow-arrow"
                                                                        onClick={() => {
                                                                            this.setState({ bid_response_tabs: "bid_reviewandlock" });
                                                                        }}
                                                                    >
                                                                        Continue <span className="icon-right-arrow fs-9 ps-1"></span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {this.state.bid_response_tabs === "bid_reviewandlock" && (
                                                    <div id="bid_designpreview_tab">
                                                        <BidReviewAndLockTab
                                                            bid_detail={bid_detail}
                                                            response_pdf={this.state.response_pdf}
                                                            response_pdf_thumbnail={this.state.response_pdf_thumbnail}
                                                            bid_response_form={this.state.bid_response_form}
                                                            ref={(ref) => (this.BidReviewAndLockEvent = ref)}
                                                            ViewResponseMedia={(media_detail) => this.ViewResponseMedia(media_detail)}
                                                            BidUnlockConfirm={(event) => {
                                                                this.setState({ confirm_modal: true }, function () {
                                                                    this.BidUnlockConfirm(event);
                                                                });
                                                            }}
                                                            BidLockConfirm={(event) => this.BidLockConfirm()}
                                                        />
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="nav_footer_button text-end">
                                                                    <button
                                                                        type="button"
                                                                        className="head-btn-bg back-button btn  btn-lg rounded-50 me-2"
                                                                        onClick={() => {
                                                                            this.setState({ bid_response_tabs: "bid_designpreview" });
                                                                        }}
                                                                    >
                                                                        <span className="icon-left-arrow fs-9"></span>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger btn-lg rounded-50 me-2 hvr-grow-arrow"
                                                                        onClick={() => {
                                                                            if (bid_detail.bid_lock === true) {
                                                                                this.setState({
                                                                                    bid_response_tabs: "bid_exportandshare",
                                                                                });
                                                                            } else {
                                                                                this.setState({ confirm_modal: true }, function () {
                                                                                    this.BidLockAlert();
                                                                                });
                                                                            }
                                                                        }}
                                                                    >
                                                                        Continue <span className="icon-right-arrow fs-9 ps-1"></span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {this.state.bid_response_tabs === "bid_exportandshare" && (
                                                    <div id="bid_designpreview_tab">
                                                        <BidExportAndShareTab
                                                            bid_detail={bid_detail}
                                                            bid_id={this.state.bid_id}
                                                            response_pdf={this.state.response_pdf}
                                                            ref={(ref) => (this.BidExportAndShareEvent = ref)}
                                                            bid_response_form={this.state.bid_response_form}
                                                            GenrateBidZip={(event) => this.GenrateBidZip(event)}
                                                            BidResponseShare={(form_data) => this.BidResponseShare(form_data)}
                                                            BidShareHistory={(form_data, bid_response_shares) => this.BidShareHistory(form_data, bid_response_shares)}
                                                        />
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="nav_footer_button text-end">
                                                                    <button
                                                                        type="button"
                                                                        className="head-btn-bg btn back-button btn-lg rounded-50 me-2"
                                                                        onClick={() => {
                                                                            this.setState({ bid_response_tabs: "bid_reviewandlock" });
                                                                        }}
                                                                    >
                                                                        <span className="icon-left-arrow fs-9"></span>
                                                                    </button>

                                                                    <button type="button" className="btn btn-danger btn-lg rounded-50 me-2 hvr-grow-arrow" onClick={(event) => this.BidCompleteZipSend(event)}>
                                                                        Complete bid
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Contributor") &&
                                        this.state.bid_response_tabs === "bid_create" && (
                                            <div className="right-sidebar bid_create_rightsidebar" ref={this.setBidCreateSideBarWrapperRef}>
                                                <button
                                                    className="arrow-btn-wrap"
                                                    onClick={() => {
                                                        this.setState({ bid_create_rightsidebar: bid_create_rightsidebar ? false : true });
                                                    }}
                                                >
                                                    <i className="icon-left-arrow icon-side-arrow"></i>
                                                    <i className="icon-right-arrow icon-side-arrow"></i>
                                                </button>
                                                {bid_create_rightsidebar && bid_detail.bid_id && (
                                                    <BidRightSideBar
                                                        bid_detail={bid_detail}
                                                        ref={(ref) => (this.BidRightSideBarEvent = ref)}
                                                        bid_response_form={this.state.bid_response_form}
                                                        bid_section_selected={this.state.bid_section_selected}
                                                        BidStatusUpdate={(event, bid_status) => this.BidStatusUpdate(event, bid_status)}
                                                        bid_team_modal_action={(modal_action) => this.setState({ bid_team_modal: modal_action })}
                                                        bid_owner_modal_action={(modal_action) => this.setState({ bid_owner_modal: modal_action })}
                                                        bid_insert_section_action={(modal_action) => this.setState({ bid_insert_section_modal: modal_action })}
                                                        BidCommentReply={(event, bid_comment) => this.BidCommentReply(event, bid_comment)}
                                                        comment_sidebar_action={(modal_action) => this.setState({ bid_create_rightsidebar_comment: modal_action })}
                                                        BidtypeDemoContentAvailable={(event, bid_content) => this.BidtypeDemoContentAvailable(event, bid_content)}
                                                        BidtypeDemoVideoAvailable={(event, bid_content) => this.BidtypeDemoVideoAvailable(event, bid_content)}
                                                        BidSectionUnselected={(event) => {
                                                            this.setState({ bid_section_selected: undefined }, function () {
                                                                if (this.BidCreateEvent && this.BidCreateEvent !== undefined) {
                                                                    this.BidCreateEvent.BidCreateTabReload();
                                                                }
                                                                if (this.BidRightSideBarEvent && this.BidRightSideBarEvent !== undefined) {
                                                                    this.BidRightSideBarEvent.BidRightSideBarReload();
                                                                }
                                                            });
                                                        }}
                                                        BidSectionDueDateUpdate={(section_due_date, section_selected) => this.BidSectionDueDateUpdate(section_due_date, section_selected)}
                                                        BidSelectedStatusUpdate={(event, section_selected, section_status) => this.BidSelectedStatusUpdate(event, section_selected, section_status)}
                                                        section_team_modal_action={(modal_action) => {
                                                            this.setState(
                                                                {
                                                                    bid_section_selected_handle: false,
                                                                    section_team_modal: modal_action,
                                                                },
                                                                function () {
                                                                    document.removeEventListener("click", this.BidSelectedClickOutside);
                                                                }
                                                            );
                                                        }}
                                                        section_owner_modal_action={(modal_action) => {
                                                            this.setState(
                                                                {
                                                                    bid_section_selected_handle: false,
                                                                    section_owner_modal: modal_action,
                                                                },
                                                                function () {
                                                                    document.removeEventListener("click", this.BidSelectedClickOutside);
                                                                }
                                                            );
                                                        }}
                                                        sectioncomment_sidebar_action={(modal_action) => {
                                                            this.setState(
                                                                {
                                                                    bid_section_selected_handle: false,
                                                                    bid_create_rightsidebar_sectioncomment: modal_action,
                                                                },
                                                                function () {
                                                                    document.removeEventListener("click", this.BidSelectedClickOutside);
                                                                }
                                                            );
                                                        }}
                                                        BidSectionCommentReply={(event, section_comment) => this.BidSectionCommentReply(event, section_comment)}
                                                        UpdateSectionWithLibraryContent={(event, content) => this.UpdateSectionWithLibraryContent(event, content)}
                                                        AddNewBidproposal={(event, proposal) => this.AddNewBidproposal(event, proposal)}
                                                        BidRemoveDocument={(event, media) => {
                                                            this.setState({ confirm_modal: true }, function () {
                                                                this.BidRemoveDocument(event, media);
                                                            });
                                                        }}
                                                        BidUploadDocument={(file, is_tender) => this.BidUploadDocument(file, is_tender)}
                                                        ViewResponseMedia={(media_detail) => this.ViewResponseMedia(media_detail)}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    {this.state.bid_response_tabs === "bid_designpreview" && (
                                        <div className="right-sidebar bid_designpreview_rightsidebar">
                                            <button
                                                className="arrow-btn-wrap"
                                                onClick={() => {
                                                    this.setState({ bid_designpreview_rightsidebar: bid_designpreview_rightsidebar ? false : true });
                                                }}
                                            >
                                                <i className="icon-left-arrow icon-side-arrow"></i>
                                                <i className="icon-right-arrow icon-side-arrow"></i>
                                            </button>
                                            {bid_designpreview_rightsidebar && (
                                                <BidDesignPreviewRightSideBar
                                                    bid_detail={bid_detail}
                                                    response_record={this.state.response_record}
                                                    ref={(ref) => (this.BidDesignPreviewRightSideBarEvent = ref)}
                                                    DesignPreviewUpdatepdf={(form_data) => this.DesignPreviewUpdatepdf(form_data)}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className="comment-overlay">
                                    <div className="right_sidebar_comment">
                                        {bid_create_rightsidebar_comment && (
                                            <BidRightSideComment
                                                bid_users={bid_users}
                                                bid_comment={this.state.bid_comment}
                                                ref={(ref) => (this.BidRightSideCommentEvent = ref)}
                                                BidCreateComment={(comment_text, parent_id, tag_users) => this.BidCreateComment(comment_text, parent_id, tag_users)}
                                                bid_create_rightsidebar_comment_action={(modal_action) => {
                                                    this.setState({
                                                        bid_comment: undefined,
                                                        bid_create_rightsidebar_comment: modal_action,
                                                    });
                                                }}
                                            />
                                        )}
                                        {bid_create_rightsidebar_sectioncomment && (
                                            <BidRightSideSectionComment
                                                bid_users={bid_users}
                                                section_comment={this.state.section_comment}
                                                bid_section_selected={this.state.bid_section_selected}
                                                ref={(ref) => (this.BidRightSideSectionCommentEvent = ref)}
                                                BidSectionComment={(comment_text, parent_id, section_id, tag_users) => this.BidSectionComment(comment_text, parent_id, section_id, tag_users)}
                                                bid_create_rightsidebar_sectioncomment_action={(modal_action) => {
                                                    this.setState(
                                                        {
                                                            section_comment: undefined,
                                                            bid_section_selected_handle: false,
                                                            bid_create_rightsidebar_sectioncomment: modal_action,
                                                        },
                                                        function () {
                                                            document.removeEventListener("click", this.BidSelectedClickOutside);
                                                        }
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                                <LoginFooter />
                            </div>
                        </div>
                    </div>
                ) : (
                    <AccessDenied />
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetTags: userActions.GetTags,
    onGetBidById: userActions.GetBidById,
    onGetCompanyUsers: userActions.GetCompanyUsers,

    onGetBidtypeContent: userActions.GetBidtypeContent,

    onUpdateBidTeam: userActions.UpdateBidTeam,
    onRemoveBidTeam: userActions.RemoveBidTeam,

    onUpdateBidOwner: userActions.UpdateBidOwner,
    onRemoveBidOwner: userActions.RemoveBidOwner,

    onGetBidSectionByBidId: userActions.GetBidSectionByBidId,
    onGetBidSectionById: userActions.GetBidSectionById,
    onBidSectionInsert: userActions.BidSectionInsert,
    onBidInsertNewBlankSection: userActions.BidInsertNewBlankSection,
    onBidSectionRemove: userActions.BidSectionRemove,

    onBidStatusUpdate: userActions.BidStatusUpdate,
    onBidSectionUpdate: userActions.BidSectionUpdate,
    onBidSectionShortingUpdate: userActions.BidSectionShortingUpdate,
    onBidSectionUpdateTagUsers: userActions.BidSectionUpdateTagUsers,

    onCreateLibrary: userActions.CreateLibrary,
    onCreateLibraryContent: userActions.CreateLibraryContent,
    onGetLibraryWithoutContents: userActions.GetLibraryWithoutContents,

    onBidUploadDocument: userActions.BidUploadDocument,
    onBidRemoveDocument: userActions.BidRemoveDocument,
    onBidDocumentTitleUpdate: userActions.BidDocumentTitleUpdate,
    onBidUpdateLibraryReturnableDocument: userActions.BidUpdateLibraryReturnableDocument,

    onCreateComment: userActions.CreateComment,
    onGetCommentById: userActions.GetCommentById,

    onBidSelectedStatusUpdate: userActions.BidSelectedStatusUpdate,
    onBidSectionDueDateUpdate: userActions.BidSectionDueDateUpdate,

    onUpdateSectionTeam: userActions.UpdateSectionTeam,
    onRemoveSectionTeam: userActions.RemoveSectionTeam,
    onUpdateSectionOwner: userActions.UpdateSectionOwner,
    onRemoveSectionOwner: userActions.RemoveSectionOwner,

    onGetResponseRecord: userActions.GetResponseRecord,
    onDesignPreviewGenratepdf: userActions.DesignPreviewGenratepdf,
    onDesignPreviewUpdatepdf: userActions.DesignPreviewUpdatepdf,

    onBidLockStatusChange: userActions.BidLockStatusChange,

    onGenrateBidZip: userActions.GenrateBidZip,
    onBidResponseShare: userActions.BidResponseShare,
    onBidCompleteZipSend: userActions.BidCompleteZipSend,
};
export default connect(mapStateToProps, mapDispatchToProps)(BidResponse);