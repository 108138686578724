import React from "react";

import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { Redirect } from "react-router-dom";

import Loader from "../../../components/Utility/Loader";
import Validations from "../../../components/Utility/Validations";
import LoginFooter from "../../../components/SuperAdminFooter";
import LoginHeader from "../../../components/SuperAdminHeader";
import LoginSidebar from "../../../components/SuperAdminSidebar";

import * as userActions from "../../../actions/index";
import * as SuperAdminActions from "../../../actions/SuperAdmin/index";

class CompanyUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companydetails_form: {
                company_id: {
                    label: "Company ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                company_name: {
                    type: "text",
                    label: "Company name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Company name",
                    validations: { required: true },
                },
                abn: {
                    type: "text",
                    label: "ABN",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter ABN",
                    validations: {
                        required: true,
                        min_length: 11,
                        ValidateABN: true,
                        NumberAndSpace: true,
                    },
                },
                company_email: {
                    type: "email",
                    label: "Company email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Company email",
                    validations: { required: true },
                },
                industry: {
                    type: "text",
                    label: "Industry",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Industry",
                    validations: { required: true },
                },
                company_size: {
                    label: "Size of company",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter size of company",
                    validations: { required: true },
                    options: [
                        { opk: "company_size_opk_0", name: "Size of company", value: "" },
                        { opk: "company_size_opk_1", name: "1-5", value: "1-5" },
                        { opk: "company_size_opk_2", name: "6-15", value: "6-15" },
                        { opk: "company_size_opk_3", name: "16-50", value: "16-50" },
                        { opk: "company_size_opk_4", name: "51-100", value: "51-100" },
                        { opk: "company_size_opk_5", name: "100+", value: "100+" },
                    ],
                },
                address: {
                    type: "text",
                    label: "Address",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter company address",
                    validations: { required: true },
                },
                zipcode: {
                    type: "text",
                    label: "Postcode",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter postcode",
                    validations: { required: true },
                },
                state: {
                    label: "State",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [{ opk: "state_opk0", name: "Select state", value: "" }],
                },
            },
        };
    }

    async componentDidMount() {
        await this.GetCountries();
        await this.GetCompanyById();
    }

    GetCountries = async () => {
        await userActions.GetCountries().then((response) => {
            if (response.success === true) {
                const update_companydetails_form = { ...this.state.companydetails_form };
                let country_detail = response.data;
                let country_state = country_detail["AU"][0].state;
                let state_option = [{ opk: "state_opk0", name: "Select a state", value: "" }];
                for (let key in country_state) {
                    state_option.push({
                        opk: country_state[key].state_code,
                        name: country_state[key].state_name,
                        value: country_state[key].state_code,
                    });
                }
                update_companydetails_form.state.options = state_option;
                this.setState({
                    loader: false,
                    companydetails_form: update_companydetails_form,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetCompanyById = async () => {
        await this.props.onGetCompanyById(this.props.company_id).then((response) => {
            if (response.success === true) {
                let company_info = response.data;
                const update_companydetails_form = { ...this.state.companydetails_form };

                update_companydetails_form.company_id.value = company_info.company_id;
                update_companydetails_form.company_name.value = company_info.company_name;
                update_companydetails_form.abn.value = company_info.abn;
                update_companydetails_form.company_email.value = company_info.company_email;
                update_companydetails_form.industry.value = company_info.industry;
                update_companydetails_form.company_size.value = company_info.company_size;
                update_companydetails_form.address.value = company_info.address;
                update_companydetails_form.zipcode.value = company_info.zipcode;
                update_companydetails_form.state.value = company_info.state;

                this.setState({ companydetails_form: update_companydetails_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    inputChangeHandler(event, identifier) {
        const update_companydetails_form = { ...this.state.companydetails_form };
        const form_element = { ...update_companydetails_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_companydetails_form[identifier] = form_element;

        this.setState({
            companydetails_form: update_companydetails_form,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_companydetails_form = this.state.companydetails_form;
        for (let key in update_companydetails_form) {
            let form_element = update_companydetails_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_companydetails_form[key] = form_element;
        }
        this.setState({ companydetails_form: update_companydetails_form });

        if (is_form_valid) {
            const form_data = new FormData();
            for (let key in this.state.companydetails_form) {
                form_data.append(key, this.state.companydetails_form[key].value);
            }

            this.props.onUpdateCompany(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/superadmin/company-lists",
                        });
                    }, 1000);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    render() {
        const { companydetails_form } = this.state;
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Update company" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="row">
                                            <div className="col-lg-11 col-xl-10">
                                                <div className="card mb-5 mb-xl-6">
                                                    <div className="card-header border-0 pt-5 mb-4">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder fs-2x mb-1">Company details</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body pt-0 pb-0">
                                                        <form className="form w-100" autoComplete="off" onSubmit={this.SubmitHandler}>
                                                            <div className="row">
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!companydetails_form.company_name.valid && companydetails_form.company_name.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{companydetails_form.company_name.label}</label>
                                                                    <input
                                                                        autoComplete="new_company_name"
                                                                        type={companydetails_form.company_name.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={companydetails_form.company_name.placeholder}
                                                                        value={companydetails_form.company_name.value ? companydetails_form.company_name.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "company_name")}
                                                                        onBlur={(event) => {
                                                                            companydetails_form.company_name.onBlur_out = true;
                                                                            this.setState({
                                                                                companydetails_form: companydetails_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!companydetails_form.company_name.valid && companydetails_form.company_name.onBlur_out ? (
                                                                        <div className="error field-error">{companydetails_form.company_name.error_msg}</div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!companydetails_form.abn.valid && companydetails_form.abn.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{companydetails_form.abn.label}</label>
                                                                    <input
                                                                        autoComplete="new_abn"
                                                                        type={companydetails_form.abn.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={companydetails_form.abn.placeholder}
                                                                        value={companydetails_form.abn.value ? companydetails_form.abn.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "abn")}
                                                                        onBlur={(event) => {
                                                                            companydetails_form.abn.onBlur_out = true;
                                                                            this.setState({
                                                                                companydetails_form: companydetails_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!companydetails_form.abn.valid && companydetails_form.abn.onBlur_out ? <div className="error field-error">{companydetails_form.abn.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className={"fv-row mb-4 fv-plugins-icon-container" + (!companydetails_form.company_email.valid && companydetails_form.company_email.onBlur_out ? " error-field" : "")}>
                                                                <label className="form-label fs-6">{companydetails_form.company_email.label}</label>
                                                                <input
                                                                    readOnly
                                                                    autoComplete="new_company_email"
                                                                    type={companydetails_form.company_email.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={companydetails_form.company_email.placeholder}
                                                                    value={companydetails_form.company_email.value ? companydetails_form.company_email.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "company_email")}
                                                                    onBlur={(event) => {
                                                                        companydetails_form.company_email.onBlur_out = true;
                                                                        this.setState({
                                                                            companydetails_form: companydetails_form,
                                                                        });
                                                                    }}
                                                                />
                                                                {!companydetails_form.company_email.valid && companydetails_form.company_email.onBlur_out ? (
                                                                    <div className="error field-error">{companydetails_form.company_email.error_msg}</div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="row fv-row">
                                                                <div className={"col-xl-6 mb-4" + (!companydetails_form.industry.valid && companydetails_form.industry.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{companydetails_form.industry.label}</label>
                                                                    <input
                                                                        autoComplete="new_industry"
                                                                        type={companydetails_form.industry.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={companydetails_form.industry.placeholder}
                                                                        value={companydetails_form.industry.value ? companydetails_form.industry.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "industry")}
                                                                        onBlur={(event) => {
                                                                            companydetails_form.industry.onBlur_out = true;
                                                                            this.setState({
                                                                                companydetails_form: companydetails_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!companydetails_form.industry.valid && companydetails_form.industry.onBlur_out ? <div className="error field-error">{companydetails_form.industry.error_msg}</div> : ""}
                                                                </div>
                                                                <div className="col-xl-6 mb-4">
                                                                    <label className="form-label fs-6">{companydetails_form.company_size.label}</label>
                                                                    <select
                                                                        autoComplete="new_company_size"
                                                                        className="form-select form-select-solid"
                                                                        data-control="select2"
                                                                        data-placeholder="10"
                                                                        value={companydetails_form.company_size.value}
                                                                        onChange={(event) => this.inputChangeHandler(event, "company_size")}
                                                                    >
                                                                        {companydetails_form.company_size.options.map((option) => (
                                                                            <option value={option.value} key={option.opk}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {!companydetails_form.company_size.valid && companydetails_form.company_size.onBlur_out ? (
                                                                        <div className="error field-error">{companydetails_form.company_size.error_msg}</div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={"fv-row mb-4 fv-plugins-icon-container" + (!companydetails_form.address.valid && companydetails_form.address.onBlur_out ? " error-field" : "")}>
                                                                <label className="form-label fs-6">{companydetails_form.address.label}</label>
                                                                <input
                                                                    autoComplete="new_address"
                                                                    type={companydetails_form.address.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={companydetails_form.address.placeholder}
                                                                    value={companydetails_form.address.value ? companydetails_form.address.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "address")}
                                                                    onBlur={(event) => {
                                                                        companydetails_form.address.onBlur_out = true;
                                                                        this.setState({
                                                                            companydetails_form: companydetails_form,
                                                                        });
                                                                    }}
                                                                />
                                                                {!companydetails_form.address.valid && companydetails_form.address.onBlur_out ? <div className="error field-error">{companydetails_form.address.error_msg}</div> : ""}
                                                            </div>
                                                            <div className="row fv-row">
                                                                <div className={"col-xl-6 mb-mobile" + (!companydetails_form.zipcode.valid && companydetails_form.zipcode.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{companydetails_form.zipcode.label}</label>
                                                                    <input
                                                                        autoComplete="new_zipcode"
                                                                        type={companydetails_form.zipcode.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={companydetails_form.zipcode.placeholder}
                                                                        value={companydetails_form.zipcode.value ? companydetails_form.zipcode.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "zipcode")}
                                                                        onBlur={(event) => {
                                                                            companydetails_form.zipcode.onBlur_out = true;
                                                                            this.setState({
                                                                                companydetails_form: companydetails_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!companydetails_form.zipcode.valid && companydetails_form.zipcode.onBlur_out ? <div className="error field-error">{companydetails_form.zipcode.error_msg}</div> : ""}
                                                                </div>
                                                                <div className="col-xl-6">
                                                                    <label className="form-label fs-6">{companydetails_form.state.label}</label>
                                                                    <select className="form-select form-select-solid" value={companydetails_form.state.value} onChange={(event) => this.inputChangeHandler(event, "state")}>
                                                                        {companydetails_form.state.options.map((option) => (
                                                                            <option value={option.value} key={option.opk}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {!companydetails_form.state.valid && companydetails_form.state.onBlur_out ? <div className="error field-error">{companydetails_form.state.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 my-4 fv-row">
                                                                <button type="submit" className="save-button hvr-grow btn btn-primary rounded-50 w-200" id="kt_account_profile_details_submit">
                                                                    Save changes
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="hover-new btn text-grey-dark rounded-50 w-150"
                                                                    onClick={(event) => {
                                                                        this.setState({
                                                                            isAuthenticated: true,
                                                                            redirect_url: "/superadmin/company-lists",
                                                                        });
                                                                    }}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.superdmin_users.error,
        loader: state.superdmin_users.loader,
        isAuthenticated: state.superdmin_users.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetCompanyById: SuperAdminActions.GetCompanyById,
    onUpdateCompany: SuperAdminActions.UpdateCompany,
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyUpdate);