import React from "react";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";

class subscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect_url: "",
            isAuthenticated: false,

            payment_information: [],
            subscription_monthly: [],
            subscription_annually: [],
            subscription_packages: [],

            extrasubsciption_users: 0,
            subscription_plantab: "month_tab",
            subscription_package_selected: "",

            billingoverview_modal: false,
            billingaddadditionalusers_modal: false,
            billingupgradesuccessful_modal: false,
            company_info: {}
        };
    }

    async componentDidMount() {
        if (!localStorage.getItem("signUpForm")) {
            return this.props.history.push("/signup");
        }
        await this.GetProducts();
    }

    GetProducts = async () => {
        this.props.onGetProducts().then((response) => {
            if (response.success === true) {
                let subscriptions = [];
                let subscription_monthly = [];
                let subscription_annually = [];
                let product_response = response.data;

                if (product_response.length > 0) {
                    for (let key in product_response) {
                        let product_detail = product_response[key];
                        if (typeof subscriptions[product_detail.product_id] === "undefined") {
                            subscriptions[product_detail.product_id] = [];
                        }
                        if (typeof subscriptions[product_detail.product_id]["product_detail"] === "undefined") {
                            subscriptions[product_detail.product_id]["product_detail"] = product_detail;
                        }
                        if (product_detail.subscription_packages) {
                            for (let package_key in product_detail.subscription_packages) {
                                let subscription_packages = product_detail.subscription_packages[package_key];
                                if (typeof subscriptions[product_detail.product_id][subscription_packages.billing_cycle] === "undefined") {
                                    subscriptions[product_detail.product_id][subscription_packages.billing_cycle] = subscription_packages;
                                }
                                if (subscription_packages.billing_cycle === "month") {
                                    subscription_monthly.push(subscription_packages);
                                } else {
                                    subscription_annually.push(subscription_packages);
                                }
                            }
                        }
                    }
                    this.setState({
                        subscription_packages: subscriptions,
                        subscription_monthly: subscription_monthly,
                        subscription_annually: subscription_annually,
                    });
                }
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    inputChangeHandler(event, identifier) {
    }

    submitHandler = (event) => {
        event.preventDefault();
    };

    packageSelected = (is_monthly, selected_package) => {
        localStorage.setItem("subscriptionOpted", JSON.stringify({
            is_monthly: is_monthly,
            selected_package: selected_package
        }));
        setTimeout(() => {
            this.props.history.push("/payment");
        }, 500);
    }

    render() {
        const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        const { subscription_plantab, subscription_monthly, subscription_annually, subscription_packages, subscription_package_selected } = this.state;
        return (
            <React.Fragment>
                <Notifications />
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.redirect_url ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                        <div class="d-flex flex-column  flex-lg-row-auto w-xl-500px positon-xl-relative bg-dark-blue-color">
                            <div class="d-flex flex-column text-center-md position-xl-fixed top-0 bottom-0 w-xl-500px scroll-y">
                                <div class="d-flex flex-row-fluid flex-column pt-lg-10 ps-lg-20">
                                    <a href="#" class="mb-10 ps-lg-10">
                                        <img alt="Logo" src="assets/images/logo.svg" class="h-60px" />
                                    </a>
                                    <h1 class="fw-bold fs-3x pb-md-5 ps-md-10 mb-0 orange-text">Welcome!</h1>
                                    <h2 class="fw-bold fs-2x pb-md-5 ps-md-10 pe-md-15 mb-0 text-white">You’re just a few clicks away from
                                        harnessing the power of BidHQ for success</h2>
                                </div>
                                <div
                                    class="d-flex flex-row-auto bgi-no-repeat subs-sign-in-banner bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px">
                                    <img src="assets/images/subs-side-img.png" alt="" class="w-100" />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-lg-row-fluid py-9">
                            <div className="d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="">
                                            <div className="row">
                                                <div className="col-lg-11 col-xl-11 mx-auto">
                                                    <div className="card mb-5 mb-xl-6 bg-transparent">
                                                        <div className="row border-0 p-0 mb-5 align-items-center pricing-plans">
                                                            <h3 className="col-lg-7 col-xl-7 card-title align-items-start flex-column mb-4 subscription-sub-heading">
                                                                <span className="card-label text-dark fw-bolder subscription-heading mb-3">Tailored plans and transparent pricing</span>
                                                                <br></br>
                                                                <span className="text-dark subscription-sub-heading fw-bold ">Enjoy a full 30 days without any charge. Cancel anytime.</span>
                                                            </h3>
                                                            <div className="col-lg-5 col-xl-5 nav-group choose-plan-block d-flex mb-4">
                                                                <span
                                                                    className={"btn btn-active active-light-btn-info rounded-50 fw-bold-700 me-2 subscription-toogle" + (subscription_plantab === "month_tab" ? " active" : "")}
                                                                    onClick={(event) => {
                                                                        this.setState({
                                                                            subscription_plantab: "month_tab",
                                                                        });
                                                                    }}
                                                                >
                                                                    Billed Monthly
                                                                </span>
                                                                <span
                                                                    className={"btn btn-active active-light-btn-info rounded-50 fw-bold-700 subscription-toogle " + (subscription_plantab === "annually_tab" ? " active" : "")}
                                                                    onClick={(event) => {
                                                                        this.setState({
                                                                            subscription_plantab: "annually_tab",
                                                                        });
                                                                    }}
                                                                >
                                                                    Billed Annually <small className="fw-bold">10% off!</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="card-body p-0 pb-5 plans-card">
                                                            <div className="row">
                                                                {subscription_plantab === "month_tab"
                                                                    ? subscription_monthly.length !== 0 &&
                                                                    subscription_monthly.map((monthly) => (
                                                                        <div className={"col-md-6 month_tab package_name_" + monthly.package_name.toLowerCase()} key={"subscription_" + monthly.subscription_package_id}>
                                                                            <div className="card p-4 plan-one">
                                                                                <div className="card-header justify-content-center text-center p-2 border-bottom-2">
                                                                                    <div className="d-flex mb-4 align-items-center">
                                                                                        <div className="symbol-100px symbol-fixed position-relative me-3 ">
                                                                                            <i className="icon-subscription fs-3"></i>
                                                                                        </div>
                                                                                        <h4 className="m-0 fw-bolder">{monthly.package_name}</h4>
                                                                                    </div>
                                                                                    <p className="mb-8">{renderHTML(monthly?.package_description)}</p>
                                                                                    <div className="mb-8  w-100">
                                                                                        <div className="price-range-new d-flex justify-content-center">
                                                                                            <h2 className="fw-bold-600 fs-4x m-0 subscription-price">
                                                                                                <sup>$</sup>
                                                                                                {(parseInt(monthly.price) / 100) * parseInt(50)}
                                                                                            </h2>
                                                                                        </div>
                                                                                        <h5 className="fw-bold-700 m-0">AUD per month</h5>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-body p-2">
                                                                                    <div class="features-body">
                                                                                        {renderHTML(monthly?.package_features)}
                                                                                    </div>
                                                                                    <span
                                                                                        className="btn btn-outline subscription-plan-btn w-100 mt-10 mb-5 rounded-50 btn-active-info"
                                                                                        onClick={(event) => {
                                                                                            this.packageSelected(true, monthly)
                                                                                        }}
                                                                                    >
                                                                                        {monthly.package_name.toLowerCase() == 'basic' || monthly.package_name.toLowerCase() == 'individual' ?
                                                                                            (
                                                                                                <>Select {monthly.package_name} plan for yourself</>
                                                                                            ) :
                                                                                            (
                                                                                                <>Select {monthly.package_name} plan for your team</>
                                                                                            )
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    : subscription_annually.length !== 0 &&
                                                                    subscription_annually.map((annually) => (
                                                                        <div className={"col-md-6 annually_tab package_name_" + annually.package_name.toLowerCase()} key={"subscription_" + annually.subscription_package_id}>
                                                                            <div className="card p-4 plan-one">
                                                                                <div className="card-header justify-content-center text-center p-2 border-bottom-2">
                                                                                    <div className="d-flex mb-4 align-items-center">
                                                                                        <div className="symbol-100px symbol-fixed position-relative me-3 ">
                                                                                            <i className="icon-subscription fs-3"></i>
                                                                                        </div>
                                                                                        <h4 className="m-0 fw-bolder">{annually.package_name}</h4>
                                                                                    </div>
                                                                                    <p className="mb-8">{renderHTML(annually?.package_description)}</p>
                                                                                    <div className="mb-8  w-100">
                                                                                        <div className="price-range-new d-flex justify-content-center">
                                                                                            <h2 className="fw-bold-600 fs-4x m-0 subscription-price">
                                                                                                <sup>$</sup>
                                                                                                {(parseInt(annually.price) / 100) * parseInt(50)}
                                                                                            </h2>
                                                                                        </div>
                                                                                        <h5 className="fw-bold-700 m-0">AUD per year</h5>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-body p-2">
                                                                                    <div class="features-body">
                                                                                        {renderHTML(annually?.package_features)}
                                                                                    </div>
                                                                                    <span
                                                                                        className="btn btn-outline subscription-plan-btn mt-10 mb-5 w-100 rounded-50 btn-active-info"
                                                                                        onClick={(event) => {
                                                                                            this.packageSelected(false, annually)
                                                                                        }}
                                                                                    >
                                                                                        {annually.package_name.toLowerCase() == 'basic' || annually.package_name.toLowerCase() == 'individual' ?
                                                                                            (
                                                                                                <>Select {annually.package_name} plan for yourself</>
                                                                                            ) :
                                                                                            (
                                                                                                <>Select {annually.package_name} plan for your team</>
                                                                                            )
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                        <div className="border-0 p-0 mt-5 pricing-plans">
                                                            <h3 className="card-title align_font flex-column mb-4">
                                                                <span className="text-dark fs-6 fw-bold ">Feel free to switch plans later. Have any questions?
                                                                    <Link to="#"
                                                                        onClick={(event) => {
                                                                            window.location = "mailto:team@bidhq.com.au";
                                                                            event.preventDefault();
                                                                        }}
                                                                        className="ms-1 link-black">
                                                                        Get in touch
                                                                    </Link>{" "}
                                                                    with us for assistance.</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onGetProducts: userActions.GetProducts
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(subscription));