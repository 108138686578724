import React from "react";

import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";

class WatchTutorialVideo extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    className="demo_contentvideo_modal bid-custom-modal "
                    show={this.props.watch_tutorialvideo_modal}
                    onHide={() => {
                        localStorage.setItem("BidHQ_Tutorial_Token", false);
                        this.props.watch_tutorialvideo_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Get started with BidHQ</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <ReactPlayer loop={true} playing={true} controls={true} autoPlay={true} className="react-player" url="https://vimeo.com/713956431" />
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default WatchTutorialVideo;