import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

let qs = require("qs");

/*** GetUserDesktopNotification Action ***/
export const GetUserDesktopNotificationStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetUserDesktopNotification_START,
    };
};
export const GetUserDesktopNotificationSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetUserDesktopNotification_SUCCESS,
    };
};
export const GetUserDesktopNotificationFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetUserDesktopNotification_FAIL,
    };
};
export const GetUserDesktopNotification = (search) => {
    return (dispatch) => {
        dispatch(GetUserDesktopNotificationStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetUserDesktopNotification`,
        })
            .then(function (response) {
                dispatch(GetUserDesktopNotificationSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetUserDesktopNotificationFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetDesktopNotificationList Action ***/
export const GetDesktopNotificationListStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetDesktopNotificationList_START,
    };
};
export const GetDesktopNotificationListSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetDesktopNotificationList_SUCCESS,
    };
};
export const GetDesktopNotificationListFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetDesktopNotificationList_FAIL,
    };
};
export const GetDesktopNotificationList = (limit, page) => {
    return (dispatch) => {
        dispatch(GetDesktopNotificationListStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetDesktopNotificationList`,
            params: {
                limit: limit,
                page: page,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetDesktopNotificationListSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetDesktopNotificationListFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** MarkAllNotificationRead Action ***/
export const MarkAllNotificationReadStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.MarkAllNotificationRead_START,
    };
};
export const MarkAllNotificationReadSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.MarkAllNotificationRead_SUCCESS,
    };
};
export const MarkAllNotificationReadFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.MarkAllNotificationRead_FAIL,
    };
};
export const MarkAllNotificationRead = (search) => {
    return (dispatch) => {
        dispatch(MarkAllNotificationReadStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/MarkAllNotificationRead`,
        })
            .then(function (response) {
                dispatch(MarkAllNotificationReadSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(MarkAllNotificationReadFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** NotificationMarkRead Action ***/
export const NotificationMarkReadStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.NotificationMarkRead_START,
    };
};
export const NotificationMarkReadSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.NotificationMarkRead_SUCCESS,
    };
};
export const NotificationMarkReadFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.NotificationMarkRead_FAIL,
    };
};
export const NotificationMarkRead = (system_user_log_id) => {
    return (dispatch) => {
        dispatch(NotificationMarkReadStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/NotificationMarkRead`,
			params: { system_user_log_id: system_user_log_id },
			paramsSerializer: (params) => {
				return qs.stringify(params);
			},
        })
            .then(function (response) {
                dispatch(NotificationMarkReadSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(NotificationMarkReadFail("Something went wrong, Please try again."));
                return error;
            });
    };
};