import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

class SubscriptionExpiredModal extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    className="subscriptionsxpired_modal bid-custom-modal "
                    show={this.props.subscriptionsxpired_modal}
                    onHide={() => {
                        this.props.subscriptionsxpired_modal_action(false);
                    }}
                >
                    <Modal.Body>
                        <div className="text-center m-0">
                            <img src="assets/images/icons/sub-expired.svg" alt="sub-expired" />
                            <h2 className="text-dark fw-bolder fs-22px">Uh oh! Looks like your free trial has expired.</h2>
                            <p className="mb-5 fs-6">
                                Not to worry, you can upgrade your account to our Basic or Pro plan and get back to your bidding best in no time. Got a question before you upgrade? <br />
                                Get in touch with our support team and we'll be happy to help!
                            </p>
                        </div>
                        <div className="modal-footer">
                            <Link
                                to="#"
                                onClick={(event) => {
                                    window.location = "mailto:team@bidhq.com.au";
                                    event.preventDefault();
                                }}
                                className="btn copy-link btn-lg rounded-50 w-200px hvr-grow"
                            >
                                Get in touch with us
                            </Link>
                            <Link to="/settings-subscription" className="btn btn-danger remove-user rounded-50 w-175px hvr-grow">
                                Upgrade plan
                            </Link>
                            <div className="bid-logout w-100 d-flex justify-content-center">
                                <Link to="/logout" className="btn cancel-butn remove-user rounded-50 w-150">
                                    Log out of BidHQ
                                </Link>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default SubscriptionExpiredModal;