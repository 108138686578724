import axios from "axios";
import * as actionType from "../../constants/actionTypes";
import * as SuperAdminactionTypes from "../../constants/SuperAdminactionTypes";

let qs = require("qs");

/*** GetBidTypes Action ***/
export const GetBidTypesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetBidTypes_START,
    };
};
export const GetBidTypesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetBidTypes_SUCCESS,
    };
};
export const GetBidTypesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetBidTypes_FAIL,
    };
};

export const GetBidTypes = (search, page, limit, sort, order) => {
    return (dispatch) => {
        dispatch(GetBidTypesStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidTypes`,
            params: {
                search: search,
                page: page,
                limit: limit,
                sort: sort,
                order: order,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidTypesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidTypesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidTypeByid Action ***/
export const GetBidTypeByidStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetBidTypeByid_START,
    };
};
export const GetBidTypeByidSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetBidTypeByid_SUCCESS,
    };
};
export const GetBidTypeByidFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetBidTypeByid_FAIL,
    };
};

export const GetBidTypeByid = (bidtype_uuid) => {
    return (dispatch) => {
        dispatch(GetBidTypeByidStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidTypeByid`,
            params: {
                bidtype_uuid: bidtype_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidTypeByidSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidTypeByidFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateBidType ***/
export const CreateBidTypeStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.CreateBidType_START,
    };
};
export const CreateBidTypeSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.CreateBidType_SUCCESS,
    };
};
export const CreateBidTypeFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.CreateBidType_FAIL,
    };
};

export const CreateBidType = (form_data) => {
    return (dispatch) => {
        dispatch(CreateBidTypeStart());
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/CreateBidType`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CreateBidTypeSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateBidTypeFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateBidType Action ***/
export const UpdateBidTypeStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.UpdateBidType_START,
    };
};
export const UpdateBidTypeSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdateBidType_SUCCESS,
    };
};
export const UpdateBidTypeFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdateBidType_FAIL,
    };
};
export const UpdateBidType = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateBidTypeStart());

        return axios({
            method: "put",
            data: form_data,
            url: `${actionType.environment_url}/api/UpdateBidType`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateBidTypeSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateBidTypeFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeleteBidType Action ***/
export const DeleteBidTypeStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.DeleteBidType_START,
    };
};
export const DeleteBidTypeSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.DeleteBidType_SUCCESS,
    };
};
export const DeleteBidTypeFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.DeleteBidType_FAIL,
    };
};
export const DeleteBidType = (bidtype_uuid) => {
    return (dispatch) => {
        dispatch(DeleteBidTypeStart());

        return axios({
            method: "delete",
            url: `${actionType.environment_url}/api/DeleteBidType`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            params: {
                bidtype_uuid: bidtype_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(DeleteBidTypeSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeleteBidTypeFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeleteBidTypeContent Action ***/
export const DeleteBidTypeContentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.DeleteBidTypeContent_START,
    };
};
export const DeleteBidTypeContentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.DeleteBidTypeContent_SUCCESS,
    };
};
export const DeleteBidTypeContentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.DeleteBidTypeContent_FAIL,
    };
};
export const DeleteBidTypeContent = (content_uuid) => {
    return (dispatch) => {
        dispatch(DeleteBidTypeContentStart());

        return axios({
            method: "delete",
            url: `${actionType.environment_url}/api/DeleteBidTypeContent`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            params: {
                content_uuid: content_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(DeleteBidTypeContentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeleteBidTypeContentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidtypeContentsByBidTypeId Action ***/
export const GetBidtypeContentsByBidTypeIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetBidtypeContentsByBidTypeId_START,
    };
};
export const GetBidtypeContentsByBidTypeIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetBidtypeContentsByBidTypeId_SUCCESS,
    };
};
export const GetBidtypeContentsByBidTypeIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetBidtypeContentsByBidTypeId_FAIL,
    };
};
export const GetBidtypeContentsByBidTypeId = (filter_params) => {
    return (dispatch) => {
        dispatch(GetBidtypeContentsByBidTypeIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidtypeContentsByBidTypeId`,
            params: filter_params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidtypeContentsByBidTypeIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidtypeContentsByBidTypeIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetBidtypeDemoContents Action ***/
export const GetBidtypeDemoContentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetBidtypeDemoContents_START,
    };
};
export const GetBidtypeDemoContentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetBidtypeDemoContents_SUCCESS,
    };
};
export const GetBidtypeDemoContentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetBidtypeDemoContents_FAIL,
    };
};

export const GetBidtypeDemoContents = () => {
    return (dispatch) => {
        dispatch(GetBidtypeDemoContentsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidtypeDemoContent`,
            params: {
                // search: search,
                // page: page,
                // limit: limit,
                // sort: sort,
                // order: order
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidtypeDemoContentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidtypeDemoContentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

export const GetBidTypeContentByidStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetBidTypeContentByid_START,
    };
};
export const GetBidTypeContentByidSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetBidTypeContentByid_SUCCESS,
    };
};
export const GetBidTypeContentByidFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetBidTypeContentByid_FAIL,
    };
};
export const GetBidTypeContentByid = (content_uuid, search, page, limit, sort, order) => {
    return (dispatch) => {
        dispatch(GetBidTypeContentByidStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetBidTypeContentByid`,
            params: {
                content_uuid: content_uuid,
                // search: search,
                // page: page,
                // limit: limit,
                // sort: sort,
                // order: order,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetBidTypeContentByidSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetBidTypeContentByidFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateManageContent ***/
export const CreateManageContentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.CreateManageContent_START,
    };
};
export const CreateManageContentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.CreateManageContent_SUCCESS,
    };
};
export const CreateManageContentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.CreateManageContent_FAIL,
    };
};

export const CreateManageContent = (form_data) => {
    return (dispatch) => {
        dispatch(CreateManageContentStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CreateBidtypeContent`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CreateManageContentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateManageContentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateBidtypeContent ***/
export const UpdateBidtypeContentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.UpdateBidtypeContent_START,
    };
};
export const UpdateBidtypeContentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdateBidtypeContent_SUCCESS,
    };
};
export const UpdateBidtypeContentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdateBidtypeContent_FAIL,
    };
};

export const UpdateBidtypeContent = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateBidtypeContentStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateBidtypeContent`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateBidtypeContentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateBidtypeContentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};