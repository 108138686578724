import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

let qs = require("qs");

/*** UpdatePersonalAvatar Action ***/
export const UpdatePersonalAvatarStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdatePersonalAvatar_START,
    };
};
export const UpdatePersonalAvatarSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdatePersonalAvatar_SUCCESS,
    };
};
export const UpdatePersonalAvatarFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdatePersonalAvatar_FAIL,
    };
};
export const UpdatePersonalAvatar = (avatar) => {
    return (dispatch) => {
        dispatch(UpdatePersonalAvatarStart());

        let formdata = new FormData();
        formdata.append("avatar", avatar);

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdatePersonalAvatar`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdatePersonalAvatarSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdatePersonalAvatarFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdatePersonal Action ***/
export const UpdatePersonalStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdatePersonal_START,
    };
};
export const UpdatePersonalSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdatePersonal_SUCCESS,
    };
};
export const UpdatePersonalFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdatePersonal_FAIL,
    };
};
export const UpdatePersonal = (form_data) => {
    return (dispatch) => {
        dispatch(UpdatePersonalStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdatePersonal`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdatePersonalSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdatePersonalFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** RemoveProfileImage Action ***/
export const RemoveProfileImageStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.RemoveProfileImage_START,
    };
};
export const RemoveProfileImageSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.RemoveProfileImage_SUCCESS,
    };
};
export const RemoveProfileImageFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.RemoveProfileImage_FAIL,
    };
};
export const RemoveProfileImage = () => {
    return (dispatch) => {
        dispatch(RemoveProfileImageStart());

        return axios({
            method: "post",
            url: `${actionType.environment_url}/api/RemoveProfileImage`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(RemoveProfileImageSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(RemoveProfileImageFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdatePassword Action ***/
export const UpdatePasswordStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdatePassword_START,
    };
};
export const UpdatePasswordSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdatePassword_SUCCESS,
    };
};
export const UpdatePasswordFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdatePassword_FAIL,
    };
};
export const UpdatePassword = (form_data) => {
    return (dispatch) => {
        dispatch(UpdatePasswordStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdatePassword`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdatePasswordSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdatePasswordFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** TwoFactorAccountVerify Action ***/
export const TwoFactorAccountVerifyStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.TwoFactorAccountVerify_START,
    };
};
export const TwoFactorAccountVerifySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.TwoFactorAccountVerify_SUCCESS,
    };
};
export const TwoFactorAccountVerifyFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.TwoFactorAccountVerify_FAIL,
    };
};
export const TwoFactorAccountVerify = (form_data) => {
    return (dispatch) => {
        dispatch(TwoFactorAccountVerifyStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/TwoFactorAccountVerify`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(TwoFactorAccountVerifySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(TwoFactorAccountVerifyFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** ResendTwoFactorOTPVerify Action ***/
export const ResendTwoFactorOTPVerifyStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.ResendTwoFactorOTPVerify_START,
    };
};
export const ResendTwoFactorOTPVerifySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.ResendTwoFactorOTPVerify_SUCCESS,
    };
};
export const ResendTwoFactorOTPVerifyFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.ResendTwoFactorOTPVerify_FAIL,
    };
};
export const ResendTwoFactorOTPVerify = () => {
    return (dispatch) => {
        dispatch(ResendTwoFactorOTPVerifyStart());
        return axios({
            method: "post",
            url: `${actionType.environment_url}/api/ResendTwoFactorOTPVerify`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(ResendTwoFactorOTPVerifySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ResendTwoFactorOTPVerifyFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** EnableTwoFactorOTPVerify Action ***/
export const EnableTwoFactorOTPVerifyStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.EnableTwoFactorOTPVerify_START,
    };
};
export const EnableTwoFactorOTPVerifySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.EnableTwoFactorOTPVerify_SUCCESS,
    };
};
export const EnableTwoFactorOTPVerifyFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.EnableTwoFactorOTPVerify_FAIL,
    };
};
export const EnableTwoFactorOTPVerify = (form_data) => {
    return (dispatch) => {
        dispatch(EnableTwoFactorOTPVerifyStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/EnableTwoFactorOTPVerify`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(EnableTwoFactorOTPVerifySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(EnableTwoFactorOTPVerifyFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DisableTwoFactorAccountVerify Action ***/
export const DisableTwoFactorAccountVerifyStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.DisableTwoFactorAccountVerify_START,
    };
};
export const DisableTwoFactorAccountVerifySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DisableTwoFactorAccountVerify_SUCCESS,
    };
};
export const DisableTwoFactorAccountVerifyFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DisableTwoFactorAccountVerify_FAIL,
    };
};
export const DisableTwoFactorAccountVerify = (form_data) => {
    return (dispatch) => {
        dispatch(DisableTwoFactorAccountVerifyStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/DisableTwoFactorAccountVerify`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(DisableTwoFactorAccountVerifySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DisableTwoFactorAccountVerifyFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateUserNotification Action ***/
export const UpdateUserNotificationStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.UpdateUserNotification_START,
    };
};
export const UpdateUserNotificationSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateUserNotification_SUCCESS,
    };
};
export const UpdateUserNotificationFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateUserNotification_FAIL,
    };
};
export const UpdateUserNotification = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateUserNotificationStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateUserNotification`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateUserNotificationSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateUserNotificationFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CompanyUpdateAvatar Action ***/
export const CompanyUpdateAvatarStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.CompanyUpdateAvatar_START,
    };
};
export const CompanyUpdateAvatarSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CompanyUpdateAvatar_SUCCESS,
    };
};
export const CompanyUpdateAvatarFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CompanyUpdateAvatar_FAIL,
    };
};
export const CompanyUpdateAvatar = (avatar) => {
    return (dispatch) => {
        dispatch(CompanyUpdateAvatarStart());

        let formdata = new FormData();
        formdata.append("avatar", avatar);

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CompanyUpdateAvatar`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CompanyUpdateAvatarSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CompanyUpdateAvatarFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CompanyUpdate Action ***/
export const CompanyUpdateStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.CompanyUpdate_START,
    };
};
export const CompanyUpdateSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CompanyUpdate_SUCCESS,
    };
};
export const CompanyUpdateFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CompanyUpdate_FAIL,
    };
};
export const CompanyUpdate = (form_data) => {
    return (dispatch) => {
        dispatch(CompanyUpdateStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CompanyUpdate`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CompanyUpdateSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CompanyUpdateFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** RemoveCompanyLogo Action ***/
export const RemoveCompanyLogoStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.RemoveCompanyLogo_START,
    };
};
export const RemoveCompanyLogoSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.RemoveCompanyLogo_SUCCESS,
    };
};
export const RemoveCompanyLogoFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.RemoveCompanyLogo_FAIL,
    };
};
export const RemoveCompanyLogo = () => {
    return (dispatch) => {
        dispatch(RemoveCompanyLogoStart());

        return axios({
            method: "post",
            url: `${actionType.environment_url}/api/RemoveCompanyLogo`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(RemoveCompanyLogoSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(RemoveCompanyLogoFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetCompanySubscription Action ***/
export const GetCompanySubscriptionStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetCompanySubscription_START,
    };
};
export const GetCompanySubscriptionSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetCompanySubscription_SUCCESS,
    };
};
export const GetCompanySubscriptionFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetCompanySubscription_FAIL,
    };
};
export const GetCompanySubscription = (search) => {
    return (dispatch) => {
        dispatch(GetCompanySubscriptionStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetCompanySubscription`,
        })
            .then(function (response) {
                dispatch(GetCompanySubscriptionSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCompanySubscriptionFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllUsers Action ***/
export const GetAllUsersStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetAllUsers_START,
    };
};
export const GetAllUsersSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetAllUsers_SUCCESS,
    };
};
export const GetAllUsersFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetAllUsers_FAIL,
    };
};
export const GetAllUsers = (limit, page, sort, order) => {
    return (dispatch) => {
        dispatch(GetAllUsersStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetAllUsers`,
            params: {
                limit: limit,
                page: page,
                sort: sort,
                order: order,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllUsersSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllUsersFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateUser Action ***/
export const UpdateUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdateUser_START,
    };
};
export const UpdateUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateUser_SUCCESS,
    };
};
export const UpdateUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateUser_FAIL,
    };
};
export const UpdateUser = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateUserStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** RemoveUser Action ***/
export const RemoveUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.RemoveUser_START,
    };
};
export const RemoveUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.RemoveUser_SUCCESS,
    };
};
export const RemoveUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.RemoveUser_FAIL,
    };
};
export const RemoveUser = (form_data) => {
    return (dispatch) => {
        dispatch(RemoveUserStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            url: `${actionType.environment_url}/api/RemoveUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(RemoveUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(RemoveUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetInviteUsers Action ***/
export const GetInviteUsersStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetInviteUsers_START,
    };
};
export const GetInviteUsersSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetInviteUsers_SUCCESS,
    };
};
export const GetInviteUsersFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetInviteUsers_FAIL,
    };
};
export const GetInviteUsers = (limit, page, sort, order) => {
    return (dispatch) => {
        dispatch(GetInviteUsersStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetInviteUsers`,
            params: {
                limit: limit,
                page: page,
                sort: sort,
                order: order,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetInviteUsersSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetInviteUsersFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** InviteUser Action ***/
export const InviteUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.InviteUser_START,
    };
};
export const InviteUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.InviteUser_SUCCESS,
    };
};
export const InviteUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.InviteUser_FAIL,
    };
};
export const InviteUser = (form_data) => {
    return (dispatch) => {
        dispatch(InviteUserStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/InviteUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(InviteUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(InviteUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateInviteUser Action ***/
export const UpdateInviteUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdateInviteUser_START,
    };
};
export const UpdateInviteUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateInviteUser_SUCCESS,
    };
};
export const UpdateInviteUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateInviteUser_FAIL,
    };
};
export const UpdateInviteUser = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateInviteUserStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateInviteUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateInviteUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateInviteUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** ReInviteUser Action ***/
export const ReInviteUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.ReInviteUser_START,
    };
};
export const ReInviteUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.ReInviteUser_SUCCESS,
    };
};
export const ReInviteUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.ReInviteUser_FAIL,
    };
};
export const ReInviteUser = (form_data) => {
    return (dispatch) => {
        dispatch(ReInviteUserStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/ReInviteUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(ReInviteUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ReInviteUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** RemoveInviteUser Action ***/
export const RemoveInviteUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.RemoveInviteUser_START,
    };
};
export const RemoveInviteUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.RemoveInviteUser_SUCCESS,
    };
};
export const RemoveInviteUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.RemoveInviteUser_FAIL,
    };
};
export const RemoveInviteUser = (invite_id) => {
    return (dispatch) => {
        dispatch(RemoveInviteUserStart());
        return axios({
            method: "delete",
            url: `${actionType.environment_url}/api/RemoveInviteUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            params: { invite_id: invite_id },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(RemoveInviteUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(RemoveInviteUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetCompanyUsers Action ***/
export const GetCompanyUsersStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetCompanyUsers_START,
    };
};
export const GetCompanyUsersSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetCompanyUsers_SUCCESS,
    };
};
export const GetCompanyUsersFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetCompanyUsers_FAIL,
    };
};
export const GetCompanyUsers = (search) => {
    return (dispatch) => {
        dispatch(GetCompanyUsersStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetCompanyUsers`,
            params: { search: search },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetCompanyUsersSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCompanyUsersFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetProducts Action ***/
export const GetProductsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetProducts_START,
    };
};
export const GetProductsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetProducts_SUCCESS,
    };
};
export const GetProductsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetProducts_FAIL,
    };
};
export const GetProducts = (search) => {
    return (dispatch) => {
        dispatch(GetProductsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetProducts`,
            params: { search: search },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetProductsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetProductsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetCurrentSubscription Action ***/
export const GetCurrentSubscriptionStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetCurrentSubscription_START,
    };
};
export const GetCurrentSubscriptionSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetCurrentSubscription_SUCCESS,
    };
};
export const GetCurrentSubscriptionFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetCurrentSubscription_FAIL,
    };
};
export const GetCurrentSubscription = (search) => {
    return (dispatch) => {
        dispatch(GetCurrentSubscriptionStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetCurrentSubscription`,
            params: { search: search },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetCurrentSubscriptionSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCurrentSubscriptionFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetSubscriptionPackages Action ***/
export const GetSubscriptionPackagesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetSubscriptionPackages_START,
    };
};
export const GetSubscriptionPackagesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetSubscriptionPackages_SUCCESS,
    };
};
export const GetSubscriptionPackagesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetSubscriptionPackages_FAIL,
    };
};
export const GetSubscriptionPackages = (search) => {
    return (dispatch) => {
        dispatch(GetSubscriptionPackagesStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetSubscriptionPackages`,
            params: { search: search },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetSubscriptionPackagesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetSubscriptionPackagesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateSubscription Action ***/
export const CreateSubscriptionStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.CreateSubscription_START,
    };
};
export const CreateSubscriptionSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CreateSubscription_SUCCESS,
    };
};
export const CreateSubscriptionFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CreateSubscription_FAIL,
    };
};
export const CreateSubscription = (form_data) => {
    return (dispatch) => {
        dispatch(CreateSubscriptionStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CreateSubscription`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CreateSubscriptionSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateSubscriptionFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** AddCard Action ***/
export const AddCardStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.AddCard_START,
    };
};
export const AddCardSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.AddCard_SUCCESS,
    };
};
export const AddCardFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.AddCard_FAIL,
    };
};
export const AddCard = (form_data) => {
    return (dispatch) => {
        dispatch(AddCardStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/AddCard`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(AddCardSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(AddCardFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeleteCard Action ***/
export const DeleteCardStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.DeleteCard_START,
    };
};
export const DeleteCardSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DeleteCard_SUCCESS,
    };
};
export const DeleteCardFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DeleteCard_FAIL,
    };
};
export const DeleteCard = (form_data) => {
    return (dispatch) => {
        dispatch(DeleteCardStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            url: `${actionType.environment_url}/api/DeleteCard`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(DeleteCardSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeleteCardFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** MakecardPrimary Action ***/
export const MakecardPrimaryStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.MakecardPrimary_START,
    };
};
export const MakecardPrimarySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.MakecardPrimary_SUCCESS,
    };
};
export const MakecardPrimaryFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.MakecardPrimary_FAIL,
    };
};
export const MakecardPrimary = (form_data) => {
    return (dispatch) => {
        dispatch(MakecardPrimaryStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/MakecardPrimary`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(MakecardPrimarySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(MakecardPrimaryFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** PaymentInformation Action ***/
export const PaymentInformationStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.PaymentInformation_START,
    };
};
export const PaymentInformationSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.PaymentInformation_SUCCESS,
    };
};
export const PaymentInformationFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.PaymentInformation_FAIL,
    };
};
export const PaymentInformation = (search) => {
    return (dispatch) => {
        dispatch(PaymentInformationStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/PaymentInformation`,
            params: { search: search },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(PaymentInformationSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(PaymentInformationFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** PaymentHistory Action ***/
export const PaymentHistoryStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.PaymentHistory_START,
    };
};
export const PaymentHistorySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.PaymentHistory_SUCCESS,
    };
};
export const PaymentHistoryFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.PaymentHistory_FAIL,
    };
};
export const PaymentHistory = (search) => {
    return (dispatch) => {
        dispatch(PaymentHistoryStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/PaymentHistory`,
            params: { search: search },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(PaymentHistorySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(PaymentHistoryFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** VerifyDiscount Action ***/
export const VerifyDiscountStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.VerifyDiscount_START,
    };
};
export const VerifyDiscountSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.VerifyDiscount_SUCCESS,
    };
};
export const VerifyDiscountFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.VerifyDiscount_FAIL,
    };
};
export const VerifyDiscount = (code) => {
    return (dispatch) => {
        dispatch(VerifyDiscountStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/VerifyDiscount`,
            params: {
                code: code,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(VerifyDiscountSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(VerifyDiscountFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** BillingContactSupport Action ***/
export const BillingContactSupportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.BillingContactSupport_START,
    };
};
export const BillingContactSupportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.BillingContactSupport_SUCCESS,
    };
};
export const BillingContactSupportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.BillingContactSupport_FAIL,
    };
};
export const BillingContactSupport = (form_data) => {
    return (dispatch) => {
        dispatch(BillingContactSupportStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/BillingContactSupport`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(BillingContactSupportSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(BillingContactSupportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};