import React from "react";
import lottie from "lottie-web";
import loading from '../../lotties/loading.json';

const LoaderPayment = (props) => {

    React.useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector("#loading"),
            animationData: loading,
            renderer: "svg", // "canvas", "html"
            loop: true, // boolean
            autoplay: true, // boolean
        });
    }, []);

    return (
        <div id="kt_body" class="bg-body" style={{
            display: "flex",
            width: "100%",
            position: "fixed",
            zIndex: "999999999",
            height: "100%"
        }}>
            <div class="d-flex flex-column flex-root">
                <div class="d-flex flex-column text-center justify-content-center align-items-center h-100 py-10" style={{ background: "rgba(220, 220, 220, 1)" }}>
                    <h2 class="mb-12" style={{ color: "rgba(10, 25, 50, 1)", "fontSize": "25px", "fontWeight": "600" }}>Thank you for joining us!<br />Let's achieve bidding excellence together.</h2>
                    <div class="mb-12">
                        <div style={{ "height": "179px", "width": "182px" }} className="loader_img"  id="loading" />
                        {/* <img className="loader_img" src="assets/images/loader.gif" title="loader_gif" alt="loader_gif" /> */}
                    </div>
                    <p class="mb-10" style={{ color: "rgba(123, 123, 123, 1)", "fontSize": "16px" }}>Taking you to the platform...</p>
                    <img src="assets/images/Wordmark.svg" alt="" style={{ "maxWidth": "150px", "marginTop": "10vh" }} />
                </div>
            </div>
            <script>var hostUrl = "assets/";</script>
            <script src="assets/plugins/global/plugins.bundle.js"></script>
            <script src="assets/js/scripts.bundle.js"></script>
            <script src="assets/js/custom/widgets.js"></script>
        </div >
    );
};

export default LoaderPayment;