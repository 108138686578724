import * as Master_actionType from "../constants/MasteractionTypes";

const initial_state = {
    error: null,
    loader: false,
    isAuthenticated: true,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case Master_actionType.GetMyTaskStatus_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetMyTaskStatus_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetMyTaskStatus_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case Master_actionType.GetMyTasks_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetMyTasks_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetMyTasks_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        default:
            return state;
    }
};

export default reducer;