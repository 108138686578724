import React from "react";
import Notifications from "react-notify-toast";

import DashboardList from "../../components/Dashboard/Dashboard.js";

class Dashboard extends React.Component {
    render() {
        const renderview = () => {
            if (this.props.match.path === "/dashboard") {
                return <DashboardList />;
            }
        };

        return (
            <React.Fragment>
                <Notifications options={{ zIndex: 99999 }} />
                {renderview()}
            </React.Fragment>
        );
    }
}
export default Dashboard;
