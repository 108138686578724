import axios from "axios";
import * as actionType from "../../constants/actionTypes";
import * as SuperAdminactionTypes from "../../constants/SuperAdminactionTypes";

let qs = require("qs");

/*** GetAllCompanies Action ***/
export const GetAllCompaniesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetAllCompanies_START,
    };
};
export const GetAllCompaniesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetAllCompanies_SUCCESS,
    };
};
export const GetAllCompaniesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetAllCompanies_FAIL,
    };
};
export const GetAllCompanies = (search, page, limit, sort, order) => {
    return (dispatch) => {
        dispatch(GetAllCompaniesStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetAllCompanies`,
            params: {
                search: search,
                page: page,
                limit: limit,
                sort: sort,
                order: order,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllCompaniesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllCompaniesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetCompanyById Action ***/
export const GetCompanyByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetCompanyById_START,
    };
};
export const GetCompanyByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetCompanyById_SUCCESS,
    };
};
export const GetCompanyByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetCompanyById_FAIL,
    };
};
export const GetCompanyById = (company_id) => {
    return (dispatch) => {
        dispatch(GetCompanyByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetCompanyById`,
            params: {
                company_id: company_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetCompanyByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCompanyByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** AddCompany Action ***/
export const AddCompanyStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.AddCompany_START,
    };
};
export const AddCompanySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.AddCompany_SUCCESS,
    };
};
export const AddCompanyFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.AddCompany_FAIL,
    };
};
export const AddCompany = (form_data) => {
    return (dispatch) => {
        dispatch(AddCompanyStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/superadmin/AddCompany`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(AddCompanySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(AddCompanyFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateCompany Action ***/
export const UpdateCompanyStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.UpdateCompany_START,
    };
};
export const UpdateCompanySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdateCompany_SUCCESS,
    };
};
export const UpdateCompanyFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdateCompany_FAIL,
    };
};
export const UpdateCompany = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateCompanyStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/superadmin/UpdateCompany`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateCompanySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateCompanyFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeleteCompany Action ***/
export const DeleteCompanyStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.DeleteCompany_START,
    };
};
export const DeleteCompanySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.DeleteCompany_SUCCESS,
    };
};
export const DeleteCompanyFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.DeleteCompany_FAIL,
    };
};
export const DeleteCompany = (company_id) => {
    return (dispatch) => {
        dispatch(DeleteCompanyStart());

        return axios({
            method: "delete",
            url: `${actionType.environment_url}/api/superadmin/DeleteCompany`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            params: {
                company_id: company_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(DeleteCompanySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeleteCompanyFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** ChangeCompanyStatus Action ***/
export const ChangeCompanyStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.ChangeCompanyStatus_START,
    };
};
export const ChangeCompanyStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.ChangeCompanyStatus_SUCCESS,
    };
};
export const ChangeCompanyStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.ChangeCompanyStatus_FAIL,
    };
};
export const ChangeCompanyStatus = (form_data) => {
    return (dispatch) => {
        dispatch(ChangeCompanyStatusStart());

        return axios({
            method: "patch",
            data: form_data,
            url: `${actionType.environment_url}/api/superadmin/ChangeCompanyStatus`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(ChangeCompanyStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ChangeCompanyStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllCompaniesExcel Action ***/
export const GetAllCompaniesExcelStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetAllCompaniesExcel_START,
    };
};
export const GetAllCompaniesExcelSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetAllCompaniesExcel_SUCCESS,
    };
};
export const GetAllCompaniesExcelFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetAllCompaniesExcel_FAIL,
    };
};
export const GetAllCompaniesExcel = (filter_params) => {
    return (dispatch) => {
        dispatch(GetAllCompaniesExcelStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetAllCompaniesExcel`,
            params: filter_params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllCompaniesExcelSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllCompaniesExcelFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateExtendSubscription Action ***/
export const UpdateExtendSubscriptionStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.UpdateExtendSubscription_START,
    };
};
export const UpdateExtendSubscriptionSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdateExtendSubscription_SUCCESS,
    };
};
export const UpdateExtendSubscriptionFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdateExtendSubscription_FAIL,
    };
};
export const UpdateExtendSubscription = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateExtendSubscriptionStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/superadmin/UpdateExtendSubscription`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateExtendSubscriptionSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateExtendSubscriptionFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetCompanyUsers Action ***/
export const GetCompanyUsersStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetCompanyUsers_START,
    };
};
export const GetCompanyUsersSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetCompanyUsers_SUCCESS,
    };
};
export const GetCompanyUsersFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetCompanyUsers_FAIL,
    };
};
export const GetCompanyUsers = (filter_params) => {
    return (dispatch) => {
        dispatch(GetCompanyUsersStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetCompanyUsers`,
            params: filter_params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetCompanyUsersSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCompanyUsersFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

///////////////////////////////////////////////////////////////////////
/*** GetAllUsers Action ***/
export const GetAllUsersStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetAllUsers_START,
    };
};
export const GetAllUsersSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetAllUsers_SUCCESS,
    };
};
export const GetAllUsersFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetAllUsers_FAIL,
    };
};
export const GetAllUsers = (search, page, limit, sort, order) => {
    return (dispatch) => {
        dispatch(GetAllUsersStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetAllUsers`,
            params: {
                search: search,
                page: page,
                limit: limit,
                sort: sort,
                order: order,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllUsersSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllUsersFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetUserByUUID Action ***/
export const GetUserByUUIDStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetUserByUUID_START,
    };
};
export const GetUserByUUIDSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetUserByUUID_SUCCESS,
    };
};
export const GetUserByUUIDFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetUserByUUID_FAIL,
    };
};
export const GetUserByUUID = (user_uud) => {
    return (dispatch) => {
        dispatch(GetUserByUUIDStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetUserByUUID`,
            params: {
                user_uud: user_uud,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetUserByUUIDSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetUserByUUIDFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** AddCompanyUser Action ***/
export const AddCompanyUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.AddCompanyUser_START,
    };
};
export const AddCompanyUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.AddCompanyUser_SUCCESS,
    };
};
export const AddCompanyUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.AddCompanyUser_FAIL,
    };
};
export const AddCompanyUser = (form_data) => {
    return (dispatch) => {
        dispatch(AddCompanyUserStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/superadmin/AddCompanyUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(AddCompanyUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(AddCompanyUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateUser Action ***/
export const UpdateUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.UpdateUser_START,
    };
};
export const UpdateUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdateUser_SUCCESS,
    };
};
export const UpdateUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdateUser_FAIL,
    };
};
export const UpdateUser = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateUserStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/superadmin/UpdateUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeleteUser Action ***/
export const DeleteUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.DeleteUser_START,
    };
};
export const DeleteUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.DeleteUser_SUCCESS,
    };
};
export const DeleteUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.DeleteUser_FAIL,
    };
};
export const DeleteUser = (user_id) => {
    return (dispatch) => {
        dispatch(DeleteUserStart());

        return axios({
            method: "delete",
            url: `${actionType.environment_url}/api/superadmin/DeleteUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            params: {
                user_id: user_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(DeleteUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeleteUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** ChangeUserStatus Action ***/
export const ChangeUserStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.ChangeUserStatus_START,
    };
};
export const ChangeUserStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.ChangeUserStatus_SUCCESS,
    };
};
export const ChangeUserStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.ChangeUserStatus_FAIL,
    };
};
export const ChangeUserStatus = (form_data) => {
    return (dispatch) => {
        dispatch(ChangeUserStatusStart());

        return axios({
            method: "patch",
            data: form_data,
            url: `${actionType.environment_url}/api/superadmin/ChangeUserStatus`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(ChangeUserStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ChangeUserStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetCompanyUsersExcel Action ***/
export const GetCompanyUsersExcelStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetCompanyUsersExcel_START,
    };
};
export const GetCompanyUsersExcelSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetCompanyUsersExcel_SUCCESS,
    };
};
export const GetCompanyUsersExcelFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetCompanyUsersExcel_FAIL,
    };
};
export const GetCompanyUsersExcel = (company_id) => {
    return (dispatch) => {
        dispatch(GetCompanyUsersExcelStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetCompanyUsersExcel`,
            params: {
                company_id: company_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetCompanyUsersExcelSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCompanyUsersExcelFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetCompanyInvitedUsers Action ***/
export const GetCompanyInvitedUsersStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetCompanyInvitedUsers_START,
    };
};
export const GetCompanyInvitedUsersSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetCompanyInvitedUsers_SUCCESS,
    };
};
export const GetCompanyInvitedUsersFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetCompanyInvitedUsers_FAIL,
    };
};
export const GetCompanyInvitedUsers = (filter_params) => {
    return (dispatch) => {
        dispatch(GetCompanyInvitedUsersStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetCompanyInvitedUsers`,
            params: filter_params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetCompanyInvitedUsersSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCompanyInvitedUsersFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** ReInviteUser Action ***/
export const ReInviteUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.ReInviteUser_START,
    };
};
export const ReInviteUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.ReInviteUser_SUCCESS,
    };
};
export const ReInviteUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.ReInviteUser_FAIL,
    };
};
export const ReInviteUser = (form_data) => {
    return (dispatch) => {
        dispatch(ReInviteUserStart());
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/superadmin/ReInviteUser`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(ReInviteUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ReInviteUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetIndividualUsersExcel Action ***/
export const GetIndividualUsersExcelStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetIndividualUsersExcel_START,
    };
};
export const GetIndividualUsersExcelSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetIndividualUsersExcel_SUCCESS,
    };
};
export const GetIndividualUsersExcelFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetIndividualUsersExcel_FAIL,
    };
};
export const GetIndividualUsersExcel = (filter_params) => {
    return (dispatch) => {
        dispatch(GetIndividualUsersExcelStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetIndividualUsersExcel`,
            params: filter_params,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetIndividualUsersExcelSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetIndividualUsersExcelFail("Something went wrong, Please try again."));
                return error;
            });
    };
};