import React from "react";

import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { Redirect } from "react-router-dom";

import Loader from "../../../components/Utility/Loader";
import Validations from "../../../components/Utility/Validations";
import LoginFooter from "../../../components/SuperAdminFooter";
import LoginHeader from "../../../components/SuperAdminHeader";
import LoginSidebar from "../../../components/SuperAdminSidebar";

import * as userActions from "../../../actions/index";
import * as SuperAdminActions from "../../../actions/SuperAdmin/index";

class CompanyUserUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updatecompanyuser_form: {
                user_id: {
                    label: "Invite Id",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                email: {
                    label: "Email",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "e.g example@mail.com",
                    validations: { required: true, email: true },
                },
                role: {
                    label: "Role",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Access",
                    validations: { required: true },
                    options: [{ opk: "role_opk_0", name: "Select role", value: "" }],
                },
            },
        };
    }

    async componentDidMount() {
        await this.GetRoles();
        await this.GetUserByUUID();
    }

    GetRoles = async () => {
        await userActions.GetRoles().then((response) => {
            if (response.success === true) {
                let user_roles = response.data;
                let roles_option = [{ opk: "role_opk_0", name: "Select Role", value: "" }];
                const update_updatecompanyuser_form = { ...this.state.updatecompanyuser_form };
                for (let key in user_roles) {
                    roles_option.push({
                        opk: user_roles[key]["role_id"],
                        name: user_roles[key]["user_role"],
                        value: user_roles[key]["role_id"],
                    });
                }
                update_updatecompanyuser_form.role.options = roles_option;
                this.setState({ updatecompanyuser_form: update_updatecompanyuser_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetUserByUUID = async () => {
        await this.props.onGetUserByUUID(this.props?.user_uud).then((response) => {
            if (response.success === true) {
                let user_detail = response.data;
                const update_updatecompanyuser_form = { ...this.state.updatecompanyuser_form };
                update_updatecompanyuser_form.user_id.value = user_detail.user_id;
                update_updatecompanyuser_form.email.value = user_detail.email;
                update_updatecompanyuser_form.role.value = user_detail.role_id;
                this.setState({ updatecompanyuser_form: update_updatecompanyuser_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    inputChangeHandler(event, identifier) {
        const update_updatecompanyuser_form = { ...this.state.updatecompanyuser_form };
        const form_element = { ...update_updatecompanyuser_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_updatecompanyuser_form[identifier] = form_element;

        this.setState({ updatecompanyuser_form: update_updatecompanyuser_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_updatecompanyuser_form = this.state.updatecompanyuser_form;
        for (let key in update_updatecompanyuser_form) {
            let form_element = update_updatecompanyuser_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_updatecompanyuser_form[key] = form_element;
        }
        this.setState({ updatecompanyuser_form: update_updatecompanyuser_form });

        if (is_form_valid) {
            const form_data = new FormData();
            for (let key in this.state.updatecompanyuser_form) {
                form_data.append(key, this.state.updatecompanyuser_form[key].value);
            }

            this.props.onUpdateUser(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `${sessionStorage.getItem("company_user_page")}`,
                            // redirect_url: `/superadmin/company-users/${sessionStorage.getItem("selected_company_uuid")}`,
                        });
                    }, 1000);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    render() {
        const { updatecompanyuser_form } = this.state;
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Update Company User" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="row">
                                            <div className="col-lg-11 col-xl-10">
                                                <div className="card mb-5 mb-xl-6">
                                                    <div className="card-header border-0 pt-5 mb-4">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder fs-2x mb-1">Update Company User</span>
                                                        </h3>
                                                    </div>

                                                    <div className="card-body pt-0 pb-0">
                                                        <form className="form w-100" autoComplete="off" onSubmit={this.SubmitHandler}>
                                                            <div className="row mb-4">
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!updatecompanyuser_form.email.valid && updatecompanyuser_form.email.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{updatecompanyuser_form.email.label}</label>
                                                                    <input
                                                                        readOnly
                                                                        autoComplete="new_email"
                                                                        type={updatecompanyuser_form.email.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={updatecompanyuser_form.email.placeholder}
                                                                        value={updatecompanyuser_form.email.value ? updatecompanyuser_form.email.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                        onBlur={(event) => {
                                                                            updatecompanyuser_form.email.onBlur_out = true;
                                                                            this.setState({
                                                                                updatecompanyuser_form: updatecompanyuser_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!updatecompanyuser_form.email.valid && updatecompanyuser_form.email.onBlur_out && <div className="error field-error">{updatecompanyuser_form.email.error_msg}</div>}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!updatecompanyuser_form.role.valid && updatecompanyuser_form.role.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{updatecompanyuser_form.role.label}</label>
                                                                    <select className="form-select form-select-solid" value={updatecompanyuser_form.role.value} onChange={(event) => this.inputChangeHandler(event, "role")}>
                                                                        {updatecompanyuser_form.role.options.map((option) => (
                                                                            <option value={option.value} key={option.opk}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {!updatecompanyuser_form.role.valid && updatecompanyuser_form.role.onBlur_out ? <div className="error field-error">{updatecompanyuser_form.role.error_msg}</div> : ""}
                                                                </div>
                                                                <div className="col-sm-12 my-4 fv-row">
                                                                    <button type="submit" className="save-button hvr-grow btn btn-primary rounded-50 w-200" id="kt_account_profile_details_submit">
                                                                        Save changes
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="hover-new btn text-grey-dark rounded-50 w-150"
                                                                        onClick={(event) => {
                                                                            this.setState({
                                                                                isAuthenticated: true,
                                                                                redirect_url: `${sessionStorage.getItem("company_user_page")}`,
                                                                            });
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.superdmin_users.error,
        loader: state.superdmin_users.loader,
        isAuthenticated: state.superdmin_users.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetUserByUUID: SuperAdminActions.GetUserByUUID,
    onUpdateUser: SuperAdminActions.UpdateUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyUserUpdate);