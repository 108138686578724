import * as Master_actionType from "../constants/MasteractionTypes";

const initial_state = {
    error: false,
    loader: false,
    isAuthenticated: true,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case Master_actionType.GetReporting_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetReporting_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case Master_actionType.GetReporting_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        default:
            return state;
    }
};

export default reducer;