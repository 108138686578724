import React from "react";
import { connect } from "react-redux";
import Notifications from "react-notify-toast";
import { notify } from "react-notify-toast";
import { Redirect } from "react-router-dom";

import BidTypeCreateContentSection from "./BidTypeCreateContentSection";

import Loader from "../../../components/Utility/Loader";
import Validations from "../../../components/Utility/Validations";

import LoginSidebar from "../../../components/SuperAdminSidebar";
import LoginHeader from "../../../components/SuperAdminHeader";
import LoginFooter from "../../../components/SuperAdminFooter";

import * as userActions from "../../../actions/SuperAdmin/index";

class BidTypeCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bidtypecreate_form: {
                title: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                bidtype_status: {
                    type: "select",
                    label: "Status",
                    value: "true",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [
                        { opk: "bidtype_status_0", name: "Select Status", value: "" },
                        { opk: "bidtype_status_1", name: "Active", value: "true" },
                        { opk: "bidtype_status_2", name: "Inactive", value: "false" },
                    ],
                },
                content_sections: {
                    type: "multiline",
                    multiline_options: [],
                    validations: { required: true },
                },
            },
            loader: false,
            bidtype_demo_contents: [],
            selected_demo_content_ids: [],
        };
    }

    componentDidMount() {
        this.GetBidTypes();
    }

    GetBidTypes() {
        this.props.OnGetBidtypeDemoContents().then((response) => {
            if (response.success === true) {
                this.setState({ bidtype_demo_contents: response.data });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    inputChangeHandler(event, identifier) {
        const update_bidtypecreate_form = { ...this.state.bidtypecreate_form };
        const form_element = { ...update_bidtypecreate_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_bidtypecreate_form[identifier] = form_element;

        this.setState({ bidtypecreate_form: update_bidtypecreate_form });
    }

    onColumnToggle = (event, id) => {
        let selected_demo_content_ids = this.state.selected_demo_content_ids;
        if (selected_demo_content_ids.indexOf(id) >= 0) {
            selected_demo_content_ids.splice(selected_demo_content_ids.indexOf(id), 1);
        } else {
            selected_demo_content_ids.push(id);
        }
        this.setState({
            selected_demo_content_ids: selected_demo_content_ids,
        });
    };

    AddMoreContent = (event) => {
        const AddMoreContentNewEntry = {
            title: {
                type: "text",
                label: "Name",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: true },
            },
            content: {
                type: "text",
                label: "Description",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
            },
            bidtype_content_status: {
                type: "select",
                label: "Status",
                value: "true",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: true },
                options: [
                    { opk: "bidtype_content_status_0", name: "Select Status", value: "" },
                    { opk: "bidtype_content_status_1", name: "Active", value: "true" },
                    { opk: "bidtype_content_status_2", name: "Inactive", value: "false" },
                ],
            },
            is_pricing: {
                type: "checkbox",
                label: "Is Pricing",
                value: false,
                valid: true,
                error_msg: "",
                validations: { required: false },
            },
            demo_video_description: {
                type: "textarea",
                label: "Description",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
            },
            demo_video: {
                type: "url",
                label: "Video",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
            },
            demo_content: {
                type: "textarea",
                label: "Description",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
            },
        };

        const update_bidtypecreate_form = { ...this.state.bidtypecreate_form };
        let content_section_options = update_bidtypecreate_form?.content_sections?.multiline_options;

        content_section_options.push(AddMoreContentNewEntry);

        this.setState({ bidtypecreate_form: update_bidtypecreate_form });
    };

    inputChangeHandlerContentSection = (event, content_section_index, identifier) => {
        const update_bidtypecreate_form = { ...this.state.bidtypecreate_form };
        let content_section_options = update_bidtypecreate_form?.content_sections?.multiline_options;

        const form_element = content_section_options[content_section_index][identifier];

        if (form_element.type === "checkbox") {
            form_element.value = event.target.checked;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        content_section_options[content_section_index][identifier] = form_element;

        this.setState({ bidtypecreate_form: update_bidtypecreate_form });
    };

    inputChangeHandlerWysiwygEditorContentSection(event, content_section_index, identifier) {
        const update_bidtypecreate_form = { ...this.state.bidtypecreate_form };
        let content_section_options = update_bidtypecreate_form?.content_sections?.multiline_options;

        const form_element = content_section_options[content_section_index][identifier];
        form_element.value = event;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        content_section_options[content_section_index][identifier] = form_element;

        this.setState({ bidtypecreate_form: update_bidtypecreate_form });
    }

    RemoveBidTypeContentSection = (event, content_section_index) => {
        const update_bidtypecreate_form = { ...this.state.bidtypecreate_form };
        let content_section_options = update_bidtypecreate_form?.content_sections?.multiline_options;

        delete content_section_options[content_section_index];

        this.setState({ bidtypecreate_form: update_bidtypecreate_form });
    };

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_bidtypecreate_form = this.state.bidtypecreate_form;
        for (let key in update_bidtypecreate_form) {
            let form_element = update_bidtypecreate_form[key];

            if (form_element?.type === "multiline") {
                let multiline_options = form_element?.multiline_options;
                if (multiline_options?.length > 0) {
                    for (let multiline_option of multiline_options) {
                        for (let identifier in multiline_option) {
                            let identifier_field = multiline_option[identifier];

                            let validate_element = Validations(identifier_field);

                            is_form_valid = validate_element.valid === false ? false : is_form_valid;
                            identifier_field.onBlur_out = true;
                            identifier_field.valid = validate_element.valid;
                            identifier_field.error_msg = validate_element.error_msg;
                        }
                    }
                }
            } else {
                let validate_element = Validations(form_element);

                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;
            }

            update_bidtypecreate_form[key] = form_element;
        }
        this.setState({ bidtypecreate_form: update_bidtypecreate_form });

        if (is_form_valid) {
            const form_data = new FormData();
            for (let key in update_bidtypecreate_form) {
                let form_element = update_bidtypecreate_form[key];
                if (form_element?.type === "multiline") {
                    let content_sections = [];
                    let multiline_options = form_element?.multiline_options;
                    if (multiline_options?.length > 0) {
                        for (let multiline_option of multiline_options) {
                            let multiline_array = {};
                            for (let identifier in multiline_option) {
                                let identifier_field = multiline_option[identifier];
                                multiline_array[identifier] = identifier_field.value;
                            }
                            content_sections.push(multiline_array);
                        }
                    }
                    form_data.append(key, JSON.stringify(content_sections));
                } else {
                    form_data.append(key, update_bidtypecreate_form[key].value);
                }
            }

            form_data.append("demo_content_ids", JSON.stringify(this.state.selected_demo_content_ids));

            this.props.onCreateBidType(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "manage-content",
                        });
                    }, 1000);
                } else {
                    notify.show(response.message, "error");
                }
            });
        } else {
            window.scrollTo(0, 0);
            notify.show("Please enter all fields", "error");
        }
    };

    render() {
        const { bidtypecreate_form, bidtype_demo_contents } = this.state;
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <Notifications />
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Manage Content" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card mb-4 mb-xl-8 bg-transparent">
                                                    <div className="card-header border-0 p-0 align-items-center">
                                                        <h3 className="card-title align-items-start flex-column m-0">
                                                            <span className="card-label fw-bolder fs-2x mb-2">Add a type of bid</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-10">
                                                <div className="form w-100">
                                                    <form className="form w-100" onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 mb-xl-6">
                                                            <div className="card-header border-0 pt-8 mb-4">
                                                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                    <img className="mt-1 align-self-start" src="assets/images/icons/c-info.svg" title="all_bids" alt="all_bids" />
                                                                    <h3 className="card-title align-items-start flex-column ms-4 mt-0">
                                                                        <span className="card-label fw-bolder fs-22px mb-1 color1">Bid name</span>
                                                                        <span className="fw-normal text-gray-new fs-6">Name the type of bid we’re creating a template for. This will be visible to users in the BidHQ application.</span>
                                                                    </h3>
                                                                </div>
                                                            </div>

                                                            <div className="card-body pt-0">
                                                                <div className="row">
                                                                    <div className={"col-lg-12 fv-row mb-6" + (!bidtypecreate_form.title.valid && bidtypecreate_form.title.onBlur_out ? " error-field" : "")}>
                                                                        <label className="form-label fs-6">{bidtypecreate_form.title.label}</label>
                                                                        <input
                                                                            type={bidtypecreate_form.title.type}
                                                                            className="form-control form-control-lg form-control-solid"
                                                                            placeholder={bidtypecreate_form.title.placeholder}
                                                                            value={bidtypecreate_form.title.value ? bidtypecreate_form.title.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "title")}
                                                                            onBlur={(event) => {
                                                                                bidtypecreate_form.title.onBlur_out = true;
                                                                                this.setState({
                                                                                    bidtypecreate_form: bidtypecreate_form,
                                                                                });
                                                                            }}
                                                                        />
                                                                        {!bidtypecreate_form.title.valid && bidtypecreate_form.title.onBlur_out ? <div className="error field-error">{bidtypecreate_form.title.error_msg}</div> : ""}
                                                                    </div>
                                                                    <div className="col-lg-12 fv-row">
                                                                        <div className={"col-lg-12 fv-row" + (!bidtypecreate_form.bidtype_status.valid && bidtypecreate_form.bidtype_status.onBlur_out ? " error-field" : "")}>
                                                                            <label className="form-label fs-6">{bidtypecreate_form.bidtype_status.label}</label>
                                                                            <select
                                                                                className="form-control form-control-lg form-control-solid"
                                                                                value={bidtypecreate_form.bidtype_status.value}
                                                                                onChange={(event) => this.inputChangeHandler(event, "bidtype_status")}
                                                                            >
                                                                                {bidtypecreate_form.bidtype_status.options.map((option) => (
                                                                                    <option className="one" value={option.value} key={option.opk}>
                                                                                        {option.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                            {!bidtypecreate_form.bidtype_status.valid && bidtypecreate_form.bidtype_status.onBlur_out ? (
                                                                                <div className="error field-error">{bidtypecreate_form.bidtype_status.error_msg}</div>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="card mb-5 mb-xl-6">
                                                            <div className="card-header border-0 pt-8 mb-4">
                                                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                    <img className="mt-1 align-self-start" src="assets/images/icons/edu-img.svg" title="all_bids" alt="all_bids" />
                                                                    <h3 className="card-title align-items-start flex-column ms-4 mt-0">
                                                                        <span className="card-label fw-bolder fs-22px mb-1 color1">Select relevant content</span>
                                                                        <span className="fw-normal text-gray-new fs-6">Choose the types of content that should populate this bid template.</span>
                                                                    </h3>
                                                                </div>
                                                            </div>

                                                            <div className="card-body pt-0">
                                                                <div className="row">
                                                                    <div className="col-lg-12 fv-row">
                                                                        <div className="checkbox-main ms-3 mt-8">
                                                                            {bidtype_demo_contents &&
                                                                                bidtype_demo_contents.map((bidtype_demo_content) => (
                                                                                    <label key={bidtype_demo_content.demo_content_id} className="form-check form-check-solid mb-8 cursor-pointer">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            name="content"
                                                                                            type="checkbox"
                                                                                            onChange={(event) => {
                                                                                                this.onColumnToggle(event, bidtype_demo_content.demo_content_id);
                                                                                            }}
                                                                                        />
                                                                                        <span className="fw-bold ps-8 fs-4">{bidtype_demo_content.title}</span>
                                                                                    </label>
                                                                                ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {bidtypecreate_form?.content_sections?.multiline_options?.length > 0 && (
                                                            <div className="bidtype_create_contentsection">
                                                                {bidtypecreate_form?.content_sections?.multiline_options.map((content_section, content_section_index) => (
                                                                    <BidTypeCreateContentSection
                                                                        key={content_section_index}
                                                                        content_section={content_section}
                                                                        content_section_index={content_section_index}
                                                                        inputChangeHandlerContentSection={(event, content_section_index, identifier) => {
                                                                            this.inputChangeHandlerContentSection(event, content_section_index, identifier);
                                                                        }}
                                                                        inputChangeHandlerWysiwygEditorContentSection={(event, content_section_index, identifier) => {
                                                                            this.inputChangeHandlerWysiwygEditorContentSection(event, content_section_index, identifier);
                                                                        }}
                                                                        RemoveBidTypeContentSection={(event, content_section_index) => this.RemoveBidTypeContentSection(event, content_section_index)}
                                                                    />
                                                                ))}
                                                            </div>
                                                        )}
                                                        <div className="col-lg-12 d-flex justify-content-end">
                                                            <button type="button" className="mt-4 rounded-50 btn btn-danger btn-lg hvr-grow-arrow w-200px" onClick={(event) => this.AddMoreContent(event)}>
                                                                Add More Content
                                                            </button>
                                                        </div>

                                                        <div className="col-lg-12 d-flex justify-content-end">
                                                            <button type="submit" className="mt-4 rounded-50 btn btn-danger btn-lg hvr-grow-arrow w-200px">
                                                                Add bid type
                                                                <span className="svg-icon svg-icon-2">
                                                                    <img src="assets/images/icons/white-add.svg" title="all_bids" alt="all_bids" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.superadmin_managecontent.error,
        loader: state.superadmin_managecontent.loader,
        isAuthenticated: state.superadmin_managecontent.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onCreateBidType: userActions.CreateBidType,
    OnGetBidtypeDemoContents: userActions.GetBidtypeDemoContents,
};
export default connect(mapStateToProps, mapDispatchToProps)(BidTypeCreate);