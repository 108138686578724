import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { notify } from "react-notify-toast";

import EnableTwoFactorOTPVerifyModal from "./Modal/EnableTwoFactorOTPVerifyModal";
import EnableTwoFactorAccountVerifyModal from "./Modal/EnableTwoFactorAccountVerifyModal";
import EnableTwoFactorAccountConfirmationModal from "./Modal/EnableTwoFactorAccountConfirmationModal";

import DisableTwoFactorAccountVerifyModal from "./Modal/DisableTwoFactorAccountVerifyModal";
import DisableTwoFactorAccountConfirmationModal from "./Modal/DisableTwoFactorAccountConfirmationModal";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";
import Validations from "../../components/Utility/Validations";

class SettingsAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password_form: {
                current_password: {
                    label: "Current password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8 },
                },
                password: {
                    label: "New password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8 },
                },
                confirm_password: {
                    label: "Confirm password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8 },
                },
            },

            notifications_form: {
                desktop_notification_daily_summaries: {
                    label: "Daily summaries",
                    type: "CheckboxSwitch",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                desktop_notification_task_updates: {
                    label: "Task updates",
                    type: "CheckboxSwitch",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                desktop_notification_bidstatus_updates: {
                    label: "Bid status updates",
                    type: "CheckboxSwitch",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                email_notification_daily_summaries: {
                    label: "Daily summaries",
                    type: "CheckboxSwitch",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                email_notification_task_updates: {
                    label: "Task updates",
                    type: "CheckboxSwitch",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                email_notification_bidstatus_updates: {
                    label: "Bid status updates",
                    type: "CheckboxSwitch",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
            },

            loader: false,
            is_form_valid: false,
            redirect_url: "",
            isAuthenticated: false,

            password_show: false,
            current_password_show: false,
            confirm_password_show: false,

            two_factor_authentication: true,

            enabletwofactoraccountverify_modal: false,
            enabletwofactorOTPverify_modal: false,
            enabletwofactoraccountconfirmation_modal: false,

            disabletwofactoraccountverify_modal: false,
            disabletwofactoraccountconfirmation_modal: false,
        };
    }

    componentDidMount() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));

        const update_notifications_form = this.state.notifications_form;

        update_notifications_form.desktop_notification_daily_summaries.value = user_info.user.desktop_notification_daily_summaries;
        update_notifications_form.desktop_notification_task_updates.value = user_info.user.desktop_notification_task_updates;
        update_notifications_form.desktop_notification_bidstatus_updates.value = user_info.user.desktop_notification_bidstatus_updates;
        update_notifications_form.email_notification_daily_summaries.value = user_info.user.email_notification_daily_summaries;
        update_notifications_form.email_notification_task_updates.value = user_info.user.email_notification_task_updates;
        update_notifications_form.email_notification_bidstatus_updates.value = user_info.user.email_notification_bidstatus_updates;

        this.setState({
            notifications_form: update_notifications_form,
            two_factor_authentication: user_info.user.two_factor_authentication,
        });
    }

    PasswordChangeHandler(event, identifier) {
        const update_password_form = { ...this.state.password_form };
        const form_element = { ...update_password_form[identifier] };

        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_password_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_password_form) {
            is_form_valid = update_password_form[identifier].valid && is_form_valid;
        }

        this.setState({
            password_form: update_password_form,
            is_form_valid: is_form_valid,
        });
    }

    PasswordSubmitHandler = (event) => {
        event.preventDefault();

        // Check Validation
        let is_form_valid = true;
        if (!this.state.is_form_valid) {
            const update_password_form = this.state.password_form;
            for (let key in update_password_form) {
                let form_element = update_password_form[key];
                let validate_element = Validations(form_element);

                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;

                update_password_form[key] = form_element;
            }
            this.setState({
                password_form: update_password_form,
                is_form_valid: is_form_valid,
            });
        }

        // Form submit handler
        if (this.state.is_form_valid) {
            const form_data = {};
            for (let key in this.state.password_form) {
                form_data[key] = this.state.password_form[key].value;
            }
            this.props.onUpdatePassword(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    // window.location.reload();
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    DisableTwoFactorAccountVerify = (form_data) => {
        this.props.onDisableTwoFactorAccountVerify(form_data).then((response) => {
            if (response.success === true) {
                // notify.show(response.message, "success");
                let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                user_info.user = response.user_info;
                localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));
                this.setState({
                    disabletwofactoraccountverify_modal: false,
                    disabletwofactoraccountconfirmation_modal: true,
                    two_factor_authentication: user_info.user.two_factor_authentication,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    EnableTwoFactorAccountVerify = (form_data) => {
        this.props.onTwoFactorAccountVerify(form_data).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
                this.setState({
                    enabletwofactorOTPverify_modal: true,
                    enabletwofactoraccountverify_modal: false,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    ResendTwoFactorOTPVerify = () => {
        this.props.onResendTwoFactorOTPVerify().then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
                this.setState(
                    {
                        otp_token_validations: true,
                    },
                    function () {
                        if (this.EnableTwoFactorOTPVerifyModalEvent && this.EnableTwoFactorOTPVerifyModalEvent !== undefined) {
                            this.EnableTwoFactorOTPVerifyModalEvent.EnableTwoFactorOTPVerifyModalReload();
                        }
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    EnableTwoFactorOTPVerify = (form_data) => {
        this.props.onEnableTwoFactorOTPVerify(form_data).then((response) => {
            if (response.success === true) {
                // notify.show(response.message, "success");

                let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                user_info.user = response.user_info;
                localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));

                this.setState({
                    enabletwofactorOTPverify_modal: false,
                    enabletwofactoraccountconfirmation_modal: true,
                    two_factor_authentication: user_info.user.two_factor_authentication,
                });
            } else {
                if (response.tokenValidate === false) {
                    this.setState(
                        {
                            otp_token_validations: false,
                        },
                        function () {
                            if (this.EnableTwoFactorOTPVerifyModalEvent && this.EnableTwoFactorOTPVerifyModalEvent !== undefined) {
                                this.EnableTwoFactorOTPVerifyModalEvent.EnableTwoFactorOTPVerifyModalReload();
                            }
                        }
                    );
                } else {
                    notify.show(response.message, "error");
                }
            }
        });
    };

    NotificationsChangeHandler(event, identifier) {
        const update_notifications_form = this.state.notifications_form;
        const form_element = { ...update_notifications_form[identifier] };

        form_element.value = event.target.checked;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_notifications_form[identifier] = form_element;
        this.setState(
            {
                notifications_form: update_notifications_form,
            },
            function () {
                this.timer = setTimeout(() => {
                    this.NotificationsSubmitHandler();
                }, 500);
            }
        );
    }

    NotificationsSubmitHandler = () => {
        const form_data = {};
        for (let key in this.state.notifications_form) {
            form_data[key] = this.state.notifications_form[key].value;
        }
        this.props.onUpdateUserNotification(form_data).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    render() {
        const { password_form, notifications_form } = this.state;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.props.isAuthenticated === false ? <Redirect to="/logout" /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                {this.state.disabletwofactoraccountverify_modal && (
                    <DisableTwoFactorAccountVerifyModal
                        ref={(ref) => (this.DisableTwoFactorAccountVerifyModalEvent = ref)}
                        DisableTwoFactorAccountVerify={(form_data) => this.DisableTwoFactorAccountVerify(form_data)}
                        disabletwofactoraccountverify_modal={this.state.disabletwofactoraccountverify_modal}
                        disabletwofactoraccountverify_modal_action={(modal_action) => {
                            this.setState({
                                disabletwofactoraccountverify_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.disabletwofactoraccountconfirmation_modal && (
                    <DisableTwoFactorAccountConfirmationModal
                        disabletwofactoraccountconfirmation_modal={this.state.disabletwofactoraccountconfirmation_modal}
                        disabletwofactoraccountconfirmation_modal_action={(modal_action) => {
                            this.setState({
                                disabletwofactoraccountconfirmation_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.enabletwofactoraccountverify_modal && (
                    <EnableTwoFactorAccountVerifyModal
                        ref={(ref) => (this.EnableTwoFactorAccountVerifyModalEvent = ref)}
                        enabletwofactoraccountverify_modal={this.state.enabletwofactoraccountverify_modal}
                        EnableTwoFactorAccountVerify={(form_data) => this.EnableTwoFactorAccountVerify(form_data)}
                        enabletwofactoraccountverify_modal_action={(modal_action) => {
                            this.setState({
                                enabletwofactoraccountverify_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.enabletwofactorOTPverify_modal && (
                    <EnableTwoFactorOTPVerifyModal
                        ref={(ref) => (this.EnableTwoFactorOTPVerifyModalEvent = ref)}
                        otp_token_validations={this.state.otp_token_validations}
                        enabletwofactorOTPverify_modal={this.state.enabletwofactorOTPverify_modal}
                        EnableTwoFactorOTPVerify={(form_data) => this.EnableTwoFactorOTPVerify(form_data)}
                        ResendTwoFactorOTPVerify={(event) => this.ResendTwoFactorOTPVerify(event)}
                        enabletwofactoraccountverify_modal_action={(modal_action) => {
                            this.setState({
                                enabletwofactorOTPverify_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.enabletwofactoraccountconfirmation_modal && (
                    <EnableTwoFactorAccountConfirmationModal
                        enabletwofactoraccountconfirmation_modal={this.state.enabletwofactoraccountconfirmation_modal}
                        enabletwofactoraccountconfirmation_modal_action={(modal_action) => {
                            this.setState({
                                enabletwofactoraccountconfirmation_modal: modal_action,
                            });
                        }}
                    />
                )}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                            <LoginHeader page_title="Settings" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container " className="container-xxl ">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/settings-myprofile" className="nav-link text-active-primary me-6">
                                                        My profile
                                                    </Link>
                                                </li>
                                                {user_info.user.role.user_role === "Account owner" && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-company" className="nav-link text-active-primary me-6">
                                                            Company details
                                                        </Link>
                                                    </li>
                                                )}
                                                <li className="nav-item">
                                                    <Link to="/settings-account" className="nav-link text-active-primary me-6 active">
                                                        Account
                                                    </Link>
                                                </li>
                                                {user_info.user.role.user_role === "Account owner" && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-billing" className="nav-link text-active-primary me-6">
                                                            Billing
                                                        </Link>
                                                    </li>
                                                )}
                                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-users" className="nav-link text-active-primary me-6">
                                                            Users
                                                        </Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-11 col-xl-10 ">
                                                <div className="card mb-5 mb-xl-6">
                                                    <div className="card-header border-0 pt-5 mb-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label text-dark fw-bolder fs-2x mb-3">Your Organisations</span>
                                                            <span className="text-gray-new fw-normal fs-7">
                                                                Organisation:<span className="color1 ms-2">{user_info.company.company_name}</span>
                                                            </span>
                                                        </h3>
                                                    </div>
                                                </div>

                                                {user_info.user.oauth_users.length === 0 && (
                                                    <>
                                                        <div className="card mb-5 mb-xl-6">
                                                            <div className="card-header border-0 pt-5 mb-4">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label text-dark fw-bolder fs-2x mb-1">Password</span>{" "}
                                                                    <span className="text-gray-new mt-1 fw-normal fs-7">To update your password please enter your current password and then choose and confirm a new one.</span>
                                                                </h3>
                                                            </div>
                                                            <form className="form w-100" onSubmit={this.PasswordSubmitHandler}>
                                                                <div className="card-body pt-0 pb-0">
                                                                    <div className="row mb-4">
                                                                        <div className={"col-lg-6 fv-row" + (!password_form.current_password.valid && password_form.current_password.onBlur_out ? " error-field" : "")}>
                                                                            <label className="form-label fs-6">{password_form.current_password.label}</label>
                                                                            <div className="position-relative mb-1">
                                                                                <input
                                                                                    type={this.state.current_password_show ? "text" : "password"}
                                                                                    className="form-control form-control-lg form-control-solid"
                                                                                    placeholder={password_form.current_password.placeholder}
                                                                                    value={password_form.current_password.value ? password_form.current_password.value : ""}
                                                                                    onChange={(event) => this.PasswordChangeHandler(event, "current_password")}
                                                                                    onBlur={(event) => {
                                                                                        password_form.current_password.onBlur_out = true;
                                                                                        this.setState({
                                                                                            password_form: password_form,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {!password_form.current_password.valid && password_form.current_password.onBlur_out ? (
                                                                                <div className="error field-error">{password_form.current_password.error_msg}</div>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!password_form.password.valid && password_form.password.onBlur_out ? " error-field" : "")}>
                                                                            <label className="form-label fs-6">{password_form.password.label}</label>
                                                                            <div className="position-relative mb-2">
                                                                                <input
                                                                                    type={this.state.password_show ? "text" : "password"}
                                                                                    className="form-control form-control-lg form-control-solid"
                                                                                    placeholder={password_form.password.placeholder}
                                                                                    value={password_form.password.value ? password_form.password.value : ""}
                                                                                    onChange={(event) => this.PasswordChangeHandler(event, "password")}
                                                                                    onBlur={(event) => {
                                                                                        password_form.password.onBlur_out = true;
                                                                                        this.setState({
                                                                                            password_form: password_form,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {!password_form.password.valid && password_form.password.onBlur_out ? <div className="error field-error">{password_form.password.error_msg}</div> : ""}
                                                                        </div>
                                                                        <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!password_form.confirm_password.valid && password_form.confirm_password.onBlur_out ? " error-field" : "")}>
                                                                            <label className="form-label fs-6">{password_form.confirm_password.label}</label>
                                                                            <div className="position-relative mb-2">
                                                                                <input
                                                                                    type={this.state.confirm_password_show ? "text" : "password"}
                                                                                    className="form-control form-control-lg form-control-solid"
                                                                                    placeholder={password_form.confirm_password.placeholder}
                                                                                    value={password_form.confirm_password.value ? password_form.confirm_password.value : ""}
                                                                                    onChange={(event) => this.PasswordChangeHandler(event, "confirm_password")}
                                                                                    onBlur={(event) => {
                                                                                        password_form.confirm_password.onBlur_out = true;
                                                                                        this.setState({
                                                                                            password_form: password_form,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {!password_form.confirm_password.valid && password_form.confirm_password.onBlur_out ? (
                                                                                <div className="error field-error">{password_form.confirm_password.error_msg}</div>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-footer d-flex pt-0">
                                                                        <button type="submit" className="save-button hvr-grow btn btn-primary rounded-50 w-200">
                                                                            Save changes
                                                                        </button>
                                                                        <button type="submit" className="hover-new text-muted btn btn-link ms-6 fs-7">
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div className="card mb-5 mb-xl-6">
                                                            <div className="card-header border-0 pt-5 mb-0">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label text-dark fw-bolder fs-2x mb-1">Two-Factor Authentication</span>
                                                                </h3>
                                                            </div>
                                                            <div className="card-body pt-0 pb-5">
                                                                <div className="d-flex align-items-center flex-wrap flex-sm-nowrap mb-5">
                                                                    <div className="flex-grow-1">
                                                                        <span className="text-black mt-1 fw-normal fs-5">Require an authentication code when you log in with an email and password</span>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        Enable
                                                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={this.state.two_factor_authentication}
                                                                                className="form-check-input"
                                                                                onChange={(event) => {
                                                                                    if (this.state.two_factor_authentication) {
                                                                                        this.setState({
                                                                                            disabletwofactoraccountverify_modal: true,
                                                                                        });
                                                                                    } else {
                                                                                        this.setState({
                                                                                            enabletwofactoraccountverify_modal: true,
                                                                                        });
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <span className="form-check-label fw-bold text-muted"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="card mb-5 mb-xl-6">
                                                    <div className="card-body pt-5 pb-5 table-responsive">
                                                        <table className="table billing-table">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="min-w-200px  text-gray-new">
                                                                        <h3 className="card-title align-items-start flex-column">
                                                                            <span className="card-label text-dark fw-bolder fs-2x mb-1">Notifications</span>
                                                                        </h3>
                                                                    </td>
                                                                    <td className="w-175px text-gray-new ">Desktop notification</td>
                                                                    <td className="w-175px text-gray-new">Email</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-black mt-1 fw-normal fs-5">Daily summaries</td>
                                                                    <td>
                                                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2 d-inline-flex">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={notifications_form.desktop_notification_daily_summaries.value}
                                                                                onChange={(event) => this.NotificationsChangeHandler(event, "desktop_notification_daily_summaries")}
                                                                            />
                                                                            <span className="form-check-label fw-bold text-muted"></span>
                                                                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2 d-inline-flex">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={notifications_form.email_notification_daily_summaries.value}
                                                                                onChange={(event) => this.NotificationsChangeHandler(event, "email_notification_daily_summaries")}
                                                                            />
                                                                            <span className="form-check-label fw-bold text-muted"></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-black mt-1 fw-normal fs-5">Task updates</td>
                                                                    <td>
                                                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2 d-inline-flex">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={notifications_form.desktop_notification_task_updates.value}
                                                                                onChange={(event) => this.NotificationsChangeHandler(event, "desktop_notification_task_updates")}
                                                                            />
                                                                            <span className="form-check-label fw-bold text-muted"></span>
                                                                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2 d-inline-flex">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={notifications_form.email_notification_task_updates.value}
                                                                                onChange={(event) => this.NotificationsChangeHandler(event, "email_notification_task_updates")}
                                                                            />
                                                                            <span className="form-check-label fw-bold text-muted"></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-black mt-1 fw-normal fs-5">Bid status updates</td>
                                                                    <td>
                                                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2 d-inline-flex">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={notifications_form.desktop_notification_bidstatus_updates.value}
                                                                                onChange={(event) => this.NotificationsChangeHandler(event, "desktop_notification_bidstatus_updates")}
                                                                            />
                                                                            <span className="form-check-label fw-bold text-muted"></span>
                                                                        </label>
                                                                    </td>
                                                                    <td>
                                                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid ms-2 d-inline-flex">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={notifications_form.email_notification_bidstatus_updates.value}
                                                                                onChange={(event) => this.NotificationsChangeHandler(event, "email_notification_bidstatus_updates")}
                                                                            />
                                                                            <span className="form-check-label fw-bold text-muted"></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onUpdatePassword: userActions.UpdatePassword,

    onTwoFactorAccountVerify: userActions.TwoFactorAccountVerify,
    onResendTwoFactorOTPVerify: userActions.ResendTwoFactorOTPVerify,
    onEnableTwoFactorOTPVerify: userActions.EnableTwoFactorOTPVerify,
    onDisableTwoFactorAccountVerify: userActions.DisableTwoFactorAccountVerify,

    onUpdateUserNotification: userActions.UpdateUserNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(SettingsAccount);