import React from "react";
import Notifications from "react-notify-toast";

import BidTypeLists from "../../components/SuperAdmin/ManageContent/BidTypeLists.js";
import BidTypeCreate from "../../components/SuperAdmin/ManageContent/BidTypeCreate.js";
import BidTypeUpdate from "../../components/SuperAdmin/ManageContent/BidTypeUpdate.js";

import AddContentSection from "../../components/SuperAdmin/ManageContent/AddContentSection.js";
import ContentSectionUpdate from "../../components/SuperAdmin/ManageContent/ContentSectionUpdate.js";
class ManageContent extends React.Component {
    render() {
        const renderview = () => {
            if (this.props.match.path === "/superadmin/manage-content") {
                return <BidTypeLists />;
            }
            if (this.props.match.path === "/superadmin/bidtype-create") {
                return <BidTypeCreate />;
            }
            if (this.props.match.path === "/superadmin/bidtype-update/:id") {
                return <BidTypeUpdate bidtype_uuid={this.props.match.params.id} />;
            }

            if (this.props.match.path === "/superadmin/add-contentsection/:id") {
                return <AddContentSection bidtype_uuid={this.props.match.params.id} />;
            }
            if (this.props.match.path === "/superadmin/contentsection-update/:id") {
                return <ContentSectionUpdate content_uuid={this.props.match.params.id} />;
            }
        };

        return (
            <React.Fragment>
                <Notifications options={{ zIndex: 99999 }} />
                {renderview()}
            </React.Fragment>
        );
    }
}
export default ManageContent;