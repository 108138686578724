import React, { useRef, useState, useEffect } from "react";

import "./ButtonDropzone.css";

const ButtonDropzone = (props) => {
    const fileInputRef = useRef();
    const [validFiles, setValidFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [setErrorMessage] = useState("");
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);

    useEffect(() => {
        let filteredArr = selectedFiles.reduce((acc, current) => {
            const x = acc.find((item) => item.name === current.name);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        setValidFiles([...filteredArr]);
    }, [selectedFiles]);

    useEffect(() => {
        if (unsupportedFiles.length === 0 && validFiles.length) {
            props.FileUploadHandleChange(validFiles);
            setValidFiles([])
            setSelectedFiles([])
        }
    }, [props, unsupportedFiles, validFiles]);

    const fileInputClicked = () => {
        fileInputRef.current.click();
    };

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
        }
    };

    const handleFiles = (files) => {
        for (let i = 0; i < files.length; i++) {
            if (validateFile(files[i])) {
                setSelectedFiles((prevArray) => [...prevArray, files[i]]);
            } else {
                files[i]["invalid"] = true;
                setSelectedFiles((prevArray) => [...prevArray, files[i]]);
                setErrorMessage("File type not permitted");
                setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
            }
        }
    };

    const validateFile = (file) => {
        // ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon']
        // ["application/pdf"]
        if (props.file_types) {
            if (props.file_types.indexOf(file.type) === -1) {
                return false;
            }
        }
        return true;
    };

    return (
        <>
            <div className="">
                {unsupportedFiles.length ? <p>Please remove all unsupported files.</p> : ""}
                {props.rightSideButton && <div className="mt-4 mb-4 curser_pointer" onClick={fileInputClicked}>
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <img src="assets/images/icons/plus-red.svg" title="all_bids" alt="all_bids" />
                        <h6 className="card-title align-items-start flex-column ms-4 mt-1 text-gray">
                            Upload more documents
                        </h6>
                    </div>
                    <input type="file" ref={fileInputRef} className="file-input" onChange={filesSelected} accept={props.inputaccept} multiple={props.multiple === false ? false : true} />
                </div>}
                {!props.rightSideButton && <div className="btn btn-secondary btn-lg rounded-50 mt-2 mb-4" onClick={fileInputClicked}>
                    Add a document &nbsp;&nbsp;<img src="assets/images/icons/upload.svg" title="all_bids" alt="all_bids" />
                    <input type="file" ref={fileInputRef} className="file-input" onChange={filesSelected} accept={props.inputaccept} multiple={props.multiple === false ? false : true} />
                </div>}
            </div>
        </>
    );
};
export default ButtonDropzone;