import React from "react";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";

import Tribute from "tributejs";
import FroalaEditor from "react-froala-wysiwyg";
import Froalaeditor from "froala-editor";
import $ from "jquery"
import * as userActions from "../../actions/index";

// Define popup template.
Froalaeditor.POPUP_TEMPLATES["customPlugin.popup"] = '[_BUTTONS_][_CUSTOM_LAYER_]';

// Define popup buttons.
Object.assign(Froalaeditor.DEFAULTS, {
    popupButtons: ['search', '|'],
});

// The custom popup is defined inside a plugin (new or existing).
Froalaeditor.PLUGINS.customPlugin = function (editor) {
    // Create custom popup.
    function initPopup() {
        // Popup buttons.
        var popup_buttons = '';

        // Create the list of buttons.
        if (editor.opts.popupButtons.length > 1) {
            popup_buttons += `
            <div style="width: 100%;" class="fr-buttons">
                <div
                    id="fr-my-layer-first-${editor.id}"
                    class="popup-style"
                    style="
                        left: 55%;
                        box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.3);
                        border-radius: 10px;
                        padding: 20px 15px 20px 15px;
                        max-width: 335px;
                        text-align: center;
                        position: absolute;
                        z-index: 9;
                        background: #fff;
                        top: 35px;
                        width: 335px;
                        display: inherit;
                    ">
                    <div style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; padding: 5px 10px 5px 10px; background: #fff; text-align: center;">
                        <i style="font-size: 40px; line-height: 1; color: #fa6464 !important;" class="icon-new-popular"></i>
                        <p style="margin-top: 13px; line-height: normal; white-space: initial; font-size: 14px; font-weight: 700; font-family: 'Lexend';">Hi, I'm BidAI - your writing assistant</p>
                        <p style="line-height: normal; white-space: initial; font-size: 14px; font-weight: 400; font-family: 'Lexend';">
                            Looking for writing guidance? Click this button for entire section help, or highlight text for specific advice. Remember that my tips are only suggestions - you're the expert on your own writing style and preferences!
                        </p>
                        <button
                            class="got-it-btn"
                            style="font-family: 'Lexend'; background: #5028dc; color: #fff; margin-right: 2px; height: 30px; border: 1px solid #5028dc; padding-left: 12px; padding-right: 12px; border-radius: 25px; font-size: 12px; font-weight: 500;"
                            id="fr-my-layer-gotIt-${editor.id}"
                        >
                            Got it, thanks!
                        </button>
                    </div>
                </div>
                <div id="fr-my-layer-bidAI-${editor.id}" style="cursor: default;">
                    <div style="width: 165%; border-radius: 0.625rem; box-shadow: 0 0 20px 0 rgba(76, 87, 125, 0.02); background: #fff !important; padding-top: 5px; padding-right: 5px; padding-left: 5px;">
                        <div
                            id="fr-my-layer-search-text-${editor.id}"
                            style="
                                height: 0px;
                                visibility: hidden;
                                max-height: 200px;
                                overflow: scroll;
                                line-height: normal;
                                white-space: pre-wrap;
                                font-size: 12px;
                                color: rgb(10, 25, 50);
                                font-family: Lexend;
                                font-weight: 500;
                                display: flex;
                                gap: 5px;
                                margin-bottom: 5px;
                                background-color: #fff;
                                background-clip: border-box;
                                padding: 0px;
                                border: 1px solid #eff2f5;
                                border-radius: 0.625rem;
                                box-shadow: 0 0 20px 0 rgba(76, 87, 125, 0.02);
                            "
                        ></div>
                        <div style="width: 100%; margin-bottom: 10px;">
                            <span style="height: 40px; padding: 7px 5px; display: inline-flex; position: absolute; cursor: pointer;" id="fr-my-layer-add-${editor.id}">
                                <i style="font-size: 24px; line-height: 1; color: #fa6464 !important;" class="icon-new-popular"></i>
                            </span>
                            <input
                                autofocus="true"
                                type="text"
                                placeholder="Ask BidAI what you’d like help with...."
                                style="border-radius: 5px; border: 1px solid #a0a0a0; height: 40px; width: 100%; padding: 10px 40px 10px 35px;"
                                id="fr-my-layer-text-${editor.id}"
                            />
                            <span class="arrow-icon" style="height: 40px; padding: 7px 10px; display: inline-flex; right: -61%; position: absolute; cursor: pointer;" id="fr-my-layer-search-${editor.id}">
                                <span style="font-size: 24px; line-height: 1; color: #fa6464 !important;" class="icon-new-play"><span class="path1"></span><span class="path2"></span></span>
                            </span>
                        </div>
                        <div style="width: 100%; margin-bottom: 10px; text-align: end;">
                            <button
                                style="
                                    cursor: pointer;
                                    font-family: 'Lexend';
                                    background: transparent;
                                    margin-right: 5px;
                                    height: 30px;
                                    border: 1px solid black;
                                    padding-left: 12px;
                                    padding-right: 12px;
                                    border-radius: 25px;
                                    font-size: 12px;
                                    font-weight: 500;
                                "
                                id="fr-my-layer-replace-${editor.id}"
                            >
                                <i style="line-height: 0; color: #222222 !important; font-size: 11px;" class="icon-new-check"></i>&nbsp&nbspReplace Section
                            </button>
                            <button
                                style="
                                    cursor: pointer;
                                    font-family: 'Lexend';
                                    background: transparent;
                                    margin-right: 5px;
                                    height: 30px;
                                    border: 1px solid black;
                                    padding-left: 12px;
                                    padding-right: 12px;
                                    border-radius: 25px;
                                    font-size: 12px;
                                    font-weight: 500;
                                "
                                id="fr-my-layer-insert-below-${editor.id}"
                            >
                                <i style="line-height: 0; color: #222222 !important; font-size: 11px;" class="icon-new-check"></i>&nbsp&nbspInsert Below Section
                            </button>
                            <button
                                style="
                                    cursor: pointer;
                                    font-family: 'Lexend';
                                    background: transparent;
                                    margin-right: 5px;
                                    height: 30px;
                                    border: 1px solid black;
                                    padding-left: 12px;
                                    padding-right: 12px;
                                    border-radius: 25px;
                                    font-size: 12px;
                                    font-weight: 500;
                                "
                                id="fr-my-layer-insert-${editor.id}"
                            >
                                <i style="line-height: 0; color: #222222 !important; font-size: 11px;" class="icon-new-check"></i>&nbsp&nbspInsert generated content
                            </button>
                            <button
                                style="
                                    cursor: pointer;
                                    font-family: 'Lexend';
                                    background: transparent;
                                    margin-right: 5px;
                                    height: 30px;
                                    border: 1px solid black;
                                    padding-left: 12px;
                                    padding-right: 12px;
                                    border-radius: 25px;
                                    font-size: 12px;
                                    font-weight: 500;
                                "
                                id="fr-my-layer-try-again-${editor.id}"
                            >
                                <i style="font-weight: bold; line-height: 0; color: #222222 !important; font-size: 11px;" class="icon-new-reload"></i>&nbsp&nbspTry again
                            </button>
                            <button
                                style="
                                    cursor: pointer;
                                    font-family: 'Lexend';
                                    background: transparent;
                                    margin-right: 2px;
                                    height: 30px;
                                    border: 1px solid black;
                                    padding-left: 12px;
                                    padding-right: 12px;
                                    border-radius: 25px;
                                    font-size: 12px;
                                    font-weight: 500;
                                "
                                id="fr-my-layer-discard-${editor.id}"
                            >
                                <i style="line-height: 0; color: #222222 !important; font-size: 11px;" class="icon-delete"></i>&nbsp&nbspDiscard
                            </button>
                        </div>
                        <div style="display: none; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; padding: 5px 10px 5px 10px; background: #f4f4f4 !important;" id="fr-my-layer-warning-content-${editor.id}">
                            <span style="line-height: normal; white-space: initial; font-size: 10px; color: #a0a0a0; font-family: 'Lexend'; font-weight: 500; display: flex; gap: 10px; padding: 7px 0;">
                                <i style="line-height: normal; font-size: 15px; color: #fa6464 !important;" class="icon-new-informational"></i> BidAI generated content is intended as a suggestion only and should still be reviewed to ensure accuracy and
                                suitability.
                            </span>
                        </div>
                    </div>
                    <div
                        id="fr-my-layer-demo-${editor.id}"
                        style="
                            margin-left: 5px;
                            padding: 5px 10px 5px 10px;
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            min-width: 0;
                            word-wrap: break-word;
                            background-color: #fff;
                            background-clip: border-box;
                            border: 1px solid #eff2f5;
                            border-radius: 0.625rem;
                            box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.3);
                            width: 100%;
                        "
                    >
                        <div id="fr-my-layer-insert-demo-content-${editor.id}">
                            <h4 style="color: #a0a0a0; font-weight: 500; font-size: 10px; font-family: 'Lexend';">Draft with BidHQ</h4>
                            <span
                                class="hover-red"
                                id="fr-my-layer-demo-content-${editor.id}"
                                style="cursor: pointer; line-height: 2; white-space: initial; font-size: 12px; color: #0a1932; font-family: 'Lexend'; font-weight: 500; display: flex; gap: 5px; padding: 0;"
                            >
                                <i style="line-height: 2; color: #fa6464 !important; margin-right: 7px; font-size: 16px;" class="icon-demo-content"></i> Use demo content from BidHQ as a template to get started.
                            </span>
                            <hr style="margin-left: 10px; margin-right: 10px; height: 1px; border-top: 1px solid #dcdcdc; margin-bottom: 15px; margin-top: 4px;" />
                        </div>
                        <div id="fr-my-layer-insert-suggestion-content-${editor.id}">
                            <h4 style="color: #a0a0a0; font-weight: 500; font-size: 10px; font-family: 'Lexend';">Improve selection</h4>
                            <button
                                class="hover-red"
                                id="fr-my-layer-content-improve-${editor.id}"
                                style="cursor: pointer; border: none; background: inherit; line-height: normal; white-space: initial; font-size: 12px; color: #0a1932; font-family: 'Lexend'; font-weight: 500; display: flex; gap: 3px; padding: 7px 0;"
                            >
                                <i style="line-height: 1.5; color: #fa6464 !important; margin-right: 5px;" class="icon-edit"></i>&nbspSuggest grammar improvements
                            </button>
                            <button
                                class="hover-red"
                                id="fr-my-layer-content-tone-${editor.id}"
                                style="cursor: pointer; border: none; background: inherit; line-height: normal; white-space: initial; font-size: 12px; color: #0a1932; font-family: 'Lexend'; font-weight: 500; display: flex; gap: 3px; padding: 7px 0;"
                            >
                                <i style="line-height: 1.5; color: #fa6464 !important; margin-right: 5px;" class="icon-edit"></i>&nbspSuggest tone and voice improvements
                            </button>
                            <button
                                class="hover-red"
                                id="fr-my-layer-content-length-${editor.id}"
                                style="cursor: pointer; border: none; background: inherit; line-height: normal; white-space: initial; font-size: 12px; color: #0a1932; font-family: 'Lexend'; font-weight: 500; display: flex; gap: 3px; padding: 7px 0;"
                            >
                                <i style="line-height: 1.5; color: #fa6464 !important; margin-right: 5px;" class="icon-edit"></i>&nbspOptimise sentence length
                            </button>
                            <hr style="margin-left: 10px; margin-right: 10px; height: 1px; border-top: 1px solid #dcdcdc; margin-bottom: 15px; margin-top: 4px;" />
                        </div>
                        <div id="fr-my-layer-populars-div-${editor.id}"></div>
                    </div>
                </div>
            </div>
            `;
            // popup_buttons += editor.button.buildList(editor.opts.popupButtons);
            // popup_buttons += '</div>';
        }

        // Load popup template.
        var template = {
            buttons: popup_buttons,
            custom_layer: ''
        };

        // Create popup.
        var $popup = editor.popups.create('customPlugin.popup', template);

        return $popup;
    }

    // Show the popup
    function showPopup(first = false, isFocus = false) {

        var $btn = editor.$tb.find('.fr-command[data-cmd="myButton"]');
        $btn.removeAttr("disabled");
        $btn.find('i')
            .removeClass('bidAi-icon')
            .addClass('bidAi-icon')

        editor.toolbar.disable();

        // Get the popup object defined above.
        var $popup = editor.popups.get('customPlugin.popup');

        // If popup doesn't exist then create it.
        // To improve performance it is best to create the popup when it is first needed
        // and not when the editor is initialized.
        if (!$popup) $popup = initPopup();

        // Set the editor toolbar as the popup's container.
        editor.popups.setContainer('customPlugin.popup', editor.$tb);

        // This will trigger the refresh event assigned to the popup.
        // editor.popups.refresh('customPlugin.popup');

        // This custom popup is opened by pressing a button from the editor's toolbar.
        // Get the button's object in order to place the popup relative to it.
        var $btn = editor.$tb.find('.fr-command[data-cmd="myButton"]');

        // Set the popup's position.
        var left = $btn.offset().left + $btn.outerWidth() / 2;
        var top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

        // Show the custom popup.
        // The button's outerHeight is required in case the popup needs to be displayed above it.
        editor.popups.show('customPlugin.popup', left, top, $btn.outerHeight());

        if (first) {
            var $mainDiv = editor.$tb.find('.fr-popup');
            $mainDiv.removeClass('full-width')
                .addClass('full-width')

            let firstPopUp = $("#fr-my-layer-first-" + editor.id)
            firstPopUp.css("display", "inherit");
            let bidAIPopUp = $("#fr-my-layer-bidAI-" + editor.id)
            bidAIPopUp.css("display", "none");

            let gotItButton = $("#fr-my-layer-gotIt-" + editor.id)
            gotItButton.off('click').on("click", function () {
                editor.toolbar.enable();
                editor.popups.hide('customPlugin.popup');
            })

        } else {

            var $mainDiv = editor.$tb.find('.fr-popup');
            $mainDiv.removeClass('full-width')

            let firstPopUp = $("#fr-my-layer-first-" + editor.id)
            firstPopUp.css("display", "none");
            let bidAIPopUp = $("#fr-my-layer-bidAI-" + editor.id)
            bidAIPopUp.css("display", "inherit");

            let input = $("#fr-my-layer-text-" + editor.id)
            let inputBox = document.getElementById("fr-my-layer-text-" + editor.id)

            let selectedText = editor.selection.text()

            if (!selectedText)
                setTimeout(() => {
                    inputBox.focus()
                    var $frPopup = editor.$tb.find('.fr-popup');
                    $frPopup.removeClass('fr-active')
                        .addClass('fr-active')
                    if (!isFocus) {
                        editor.customPlugin.showPopup(false, true)
                    }
                })

            let select = $("#fr-my-layer-select-" + editor.id)
            let searchButton = $("#fr-my-layer-search-" + editor.id)

            let replaceButton = $("#fr-my-layer-replace-" + editor.id)
            replaceButton.css("display", "none");

            let insertBelowButton = $("#fr-my-layer-insert-below-" + editor.id)
            insertBelowButton.css("display", "none");

            let insertButton = $("#fr-my-layer-insert-" + editor.id)
            insertButton.css("display", "none");

            let tryAgainButton = $("#fr-my-layer-try-again-" + editor.id)
            tryAgainButton.css("display", "none");

            let discardButton = $("#fr-my-layer-discard-" + editor.id)
            discardButton.css("display", "none");

            let demoContentButton = $(`#fr-my-layer-demo-content-${editor.id}`)

            let contentBox = $("#fr-my-layer-search-text-" + editor.id)
            contentBox.css("visibility", "hidden");
            contentBox.css("height", "0px");
            contentBox.css("padding", "0px");

            let warningBox = $("#fr-my-layer-warning-content-" + editor.id)
            warningBox.css("display", "none");

            let demoBox = $("#fr-my-layer-demo-" + editor.id)
            if (!editor.opts.saveParams.is_pricing) {
                demoBox.css("visibility", "visible");
                demoBox.css("display", "block");
            } else {
                demoBox.css("visibility", "hidden");
                demoBox.css("display", "none");
            }

            let demoContentBox = $(`#fr-my-layer-insert-demo-content-${editor.id}`)

            if (!editor.opts.saveParams.demo_content) {
                demoContentBox.css("display", "none");
            } else {
                if (editor.opts.saveParams.is_pricing) {
                    demoContentBox.css("display", "unset");
                }
            }

            let suggestionBox = $("#fr-my-layer-insert-suggestion-content-" + editor.id)
            suggestionBox.css("display", "none");

            let grammerButton = $("#fr-my-layer-content-improve-" + editor.id)

            let toneButton = $("#fr-my-layer-content-tone-" + editor.id)

            let lengthButton = $("#fr-my-layer-content-length-" + editor.id)

            let populars = []

            if (editor.opts.saveParams.ai_prompts) {
                populars = [...JSON.parse(editor.opts.saveParams.ai_prompts)]
            }

            document.getElementById("fr-my-layer-populars-div-" + editor.id).innerHTML = ''

            if (populars.length && !editor.opts.saveParams.is_pricing) {
                const element = document.createElement("h4");
                element.innerHTML = `Popular BidAI prompts...`;
                element.style = "color:#A0A0A0; font-weight:500; font-size:10px;font-family: 'Lexend';"
                document.getElementById("fr-my-layer-populars-div-" + editor.id).appendChild(element);
            }

            if (!editor.opts.saveParams.is_pricing) {
                for (const popular of populars) {
                    const element = document.createElement("a");
                    element.innerHTML = `<i style="line-height: 1; color: #FA6464 !important; font-size: 19px; margin-right: 2px;" class="icon-new-popular"></i>${popular}`;
                    element.style = "cursor: pointer; line-height: normal; white-space: initial; font-size:12px;color:#0A1932; font-family: 'Lexend';font-weight:500;display:flex; gap:5px;padding:7px 0"
                    element.classList.add("hover-red")
                    document.getElementById("fr-my-layer-populars-div-" + editor.id).appendChild(element);
                    element.onclick = function () {
                        input.val(popular)
                    };
                }
            }

            if (!populars.length && !editor.opts.saveParams.demo_content) {
                demoBox.css("visibility", "hidden");
                demoBox.css("display", "none");
            }

            select.on("change", function () {
                input.val(this.value)
            });

            grammerButton.off('click').on("click", function () {
                if (input.val().indexOf("Suggest grammar improvements for ") == -1) {
                    input.val(input.val().replaceAll('Suggest tone and voice improvements for ', ''))
                    input.val(input.val().replaceAll('Optimise sentence length for ', ''))
                    input.val("Suggest grammar improvements for " + input.val())

                }
            })

            toneButton.off('click').on("click", function () {
                if (input.val().indexOf("Suggest tone and voice improvements for ") == -1) {
                    input.val(input.val().replaceAll('Suggest grammar improvements for ', ''))
                    input.val(input.val().replaceAll('Optimise sentence length for ', ''))
                    input.val("Suggest tone and voice improvements for " + input.val())
                }
            })

            lengthButton.off('click').on("click", function () {
                if (input.val().indexOf("Optimise sentence length for ") == -1) {
                    input.val(input.val().replaceAll('Suggest tone and voice improvements for ', ''))
                    input.val(input.val().replaceAll('Suggest grammar improvements for ', ''))
                    input.val("Optimise sentence length for " + input.val())
                }
            })

            insertButton.off('click').on("click", function () {
                let contentBox = $("#fr-my-layer-search-text-" + editor.id)

                var html = contentBox.html();

                html = html.replace(/^\s+|\s+$/g, '');

                html = html.trim()

                html = html.replace(/(?:\r\n|\r|\n)/g, '<br>');

                html = html.replaceAll('<br><br>', '<br>')

                let editorHtml = editor.html.get()

                editorHtml = editorHtml + html;

                editor.html.set(editorHtml);
                editor.undo.saveStep();

                contentBox.html('')
                editor.toolbar.enable();
                editor.popups.hide('customPlugin.popup');
            });

            insertBelowButton.off('click').on("click", function () {
                let contentBox = $("#fr-my-layer-search-text-" + editor.id)

                var html = contentBox.html();

                html = html.replace(/^\s+|\s+$/g, '');

                html = html.trim()

                html = html.replace(/(?:\r\n|\r|\n)/g, '<br>');

                html = html.replaceAll('<br><br>', '<br>')

                editor.selection.endElement().innerHTML = editor.selection.endElement().innerHTML + "<br>" + html

                editor.undo.saveStep();

                contentBox.html('')
                editor.toolbar.enable();
                editor.popups.hide('customPlugin.popup');
            });

            replaceButton.off('click').on("click", function () {
                let contentBox = $("#fr-my-layer-search-text-" + editor.id)

                var html = contentBox.html();

                html = html.replace(/^\s+|\s+$/g, '');

                html = html.trim()

                html = html.replace(/(?:\r\n|\r|\n)/g, '<br>');

                html = html.replaceAll('<br><br>', '<br>')

                editor.html.insert(html)
                editor.undo.saveStep();

                contentBox.html('')

                editor.toolbar.enable();
                editor.popups.hide('customPlugin.popup');
            });

            demoContentButton.off('click').on("click", function () {

                editor.html.insert(editor.opts.saveParams.demo_content)
                editor.undo.saveStep();

                editor.toolbar.enable();
                editor.popups.hide('customPlugin.popup');
            });

            inputBox.readOnly = false;

            tryAgainButton.off('click').on("click", function () {

                inputBox.readOnly = true;

                let contentBox = $("#fr-my-layer-search-text-" + editor.id)
                contentBox.css("visibility", "visible");
                contentBox.css("height", "auto");
                contentBox.css("padding", "10px");
                contentBox.html("Please wait. Searching...");

                let warningBox = $("#fr-my-layer-warning-content-" + editor.id)
                warningBox.css("display", "block");

                let demoBox = $("#fr-my-layer-demo-" + editor.id)
                demoBox.css("visibility", "hidden");
                demoBox.css("display", "none");

                let replaceButton = $("#fr-my-layer-replace-" + editor.id)
                replaceButton.css("display", "none");

                let insertBelowButton = $("#fr-my-layer-insert-below-" + editor.id)
                insertBelowButton.css("display", "none");

                let insertButton = $("#fr-my-layer-insert-" + editor.id)
                insertButton.css("display", "none");

                let tryAgainButton = $("#fr-my-layer-try-again-" + editor.id)
                tryAgainButton.css("display", "none");

                let discardButton = $("#fr-my-layer-discard-" + editor.id)
                discardButton.css("display", "none");

                userActions.GetCompletions({
                    "model": "text-davinci-003",
                    "prompt": input.val(),
                    "temperature": 1,
                    "max_tokens": 1000
                }).then((response) => {
                    console.log(response)
                    if (response.choices && response.choices.length) {

                        inputBox.readOnly = false;

                        if (editor.selection.text()) {
                            let replaceButton = $("#fr-my-layer-replace-" + editor.id)
                            replaceButton.css("display", "unset");

                            let insertBelowButton = $("#fr-my-layer-insert-below-" + editor.id)
                            insertBelowButton.css("display", "unset");
                        } else {
                            let insertButton = $("#fr-my-layer-insert-" + editor.id)
                            insertButton.css("display", "unset");
                        }

                        let discardButton = $("#fr-my-layer-discard-" + editor.id)
                        discardButton.css("display", "unset");

                        let tryAgainButton = $("#fr-my-layer-try-again-" + editor.id)
                        tryAgainButton.css("display", "unset");

                        contentBox.html(response.choices[0].text);
                    } else {

                        inputBox.readOnly = false;

                        let tryAgainButton = $("#fr-my-layer-try-again-" + editor.id)
                        tryAgainButton.css("display", "unset");

                        contentBox.html("Unable to search. Please try again!");
                    }
                });
            });

            discardButton.off('click').on("click", function () {
                let contentBox = $("#fr-my-layer-search-text-" + editor.id)
                contentBox.css("visibility", "hidden");
                contentBox.css("height", "0px");
                contentBox.css("padding", "0px");
                contentBox.html("");

                let warningBox = $("#fr-my-layer-warning-content-" + editor.id)
                warningBox.css("display", "none");

                let demoBox = $("#fr-my-layer-demo-" + editor.id)
                if (!editor.opts.saveParams.is_pricing) {
                    demoBox.css("visibility", "visible");
                    demoBox.css("display", "block");
                }

                let replaceButton = $("#fr-my-layer-replace-" + editor.id)
                replaceButton.css("display", "none");

                let insertBelowButton = $("#fr-my-layer-insert-below-" + editor.id)
                insertBelowButton.css("display", "none");

                let insertButton = $("#fr-my-layer-insert-" + editor.id)
                insertButton.css("display", "none");

                let tryAgainButton = $("#fr-my-layer-try-again-" + editor.id)
                tryAgainButton.css("display", "none");

                let discardButton = $("#fr-my-layer-discard-" + editor.id)
                discardButton.css("display", "none");
            });

            searchButton.off('click').on("click", function () {

                if (!input.val()) {
                    return
                }

                inputBox.readOnly = true;

                let contentBox = $("#fr-my-layer-search-text-" + editor.id)
                contentBox.css("visibility", "visible");
                contentBox.css("height", "auto");
                contentBox.css("padding", "10px");
                contentBox.html("Please wait. Searching...");

                let warningBox = $("#fr-my-layer-warning-content-" + editor.id)
                warningBox.css("display", "block");

                let demoBox = $("#fr-my-layer-demo-" + editor.id)
                demoBox.css("visibility", "hidden");
                demoBox.css("display", "none");

                userActions.GetCompletions({
                    "model": "text-davinci-003",
                    "prompt": input.val(),
                    "temperature": 1,
                    "max_tokens": 1000
                }).then((response) => {
                    console.log(response)
                    if (response.choices && response.choices.length) {

                        inputBox.readOnly = false;

                        if (editor.selection.text()) {
                            let replaceButton = $("#fr-my-layer-replace-" + editor.id)
                            replaceButton.css("display", "unset");

                            let insertBelowButton = $("#fr-my-layer-insert-below-" + editor.id)
                            insertBelowButton.css("display", "unset");
                        } else {
                            let insertButton = $("#fr-my-layer-insert-" + editor.id)
                            insertButton.css("display", "unset");
                        }

                        let tryAgainButton = $("#fr-my-layer-try-again-" + editor.id)
                        tryAgainButton.css("display", "unset");

                        let discardButton = $("#fr-my-layer-discard-" + editor.id)
                        discardButton.css("display", "unset");

                        contentBox.html(response.choices[0].text);
                    } else {

                        inputBox.readOnly = false;

                        let tryAgainButton = $("#fr-my-layer-try-again-" + editor.id)
                        tryAgainButton.css("display", "unset");

                        contentBox.html("Unable to search. Please try again!");
                    }
                });
            });

            input.val('')

            if (selectedText) {
                let demoContentBox = $(`#fr-my-layer-insert-demo-content-${editor.id}`)
                demoContentBox.css("display", "none");

                let suggestionBox = $("#fr-my-layer-insert-suggestion-content-" + editor.id)
                suggestionBox.css("display", "unset");

                input.val(selectedText)
            }

            inputBox.addEventListener("keypress", function (event) {
                if (event.keyCode == 13) {
                    event.preventDefault();

                    if (!inputBox.value) {
                        return
                    }

                    inputBox.readOnly = true;

                    let contentBox = $("#fr-my-layer-search-text-" + editor.id)
                    contentBox.css("visibility", "visible");
                    contentBox.css("height", "auto");
                    contentBox.css("padding", "10px");
                    contentBox.html("Please wait. Searching...");

                    let warningBox = $("#fr-my-layer-warning-content-" + editor.id)
                    warningBox.css("display", "block");

                    let demoBox = $("#fr-my-layer-demo-" + editor.id)
                    demoBox.css("visibility", "hidden");
                    demoBox.css("display", "none");

                    userActions.GetCompletions({
                        "model": "text-davinci-003",
                        "prompt": input.val(),
                        "temperature": 1,
                        "max_tokens": 1000
                    }).then((response) => {
                        console.log(response)
                        if (response.choices && response.choices.length) {
                            inputBox.readOnly = false;
                            if (editor.selection.text()) {
                                let replaceButton = $("#fr-my-layer-replace-" + editor.id)
                                replaceButton.css("display", "unset");

                                let insertBelowButton = $("#fr-my-layer-insert-below-" + editor.id)
                                insertBelowButton.css("display", "unset");
                            } else {
                                let insertButton = $("#fr-my-layer-insert-" + editor.id)
                                insertButton.css("display", "unset");
                            }

                            let tryAgainButton = $("#fr-my-layer-try-again-" + editor.id)
                            tryAgainButton.css("display", "unset");

                            let discardButton = $("#fr-my-layer-discard-" + editor.id)
                            discardButton.css("display", "unset");

                            contentBox.html(response.choices[0].text);
                        } else {
                            inputBox.readOnly = false;
                            let tryAgainButton = $("#fr-my-layer-try-again-" + editor.id)
                            tryAgainButton.css("display", "unset");

                            contentBox.html("Unable to search. Please try again!");
                        }
                    });
                }
            });

            select.val('')
        }

    }

    // Hide the custom popup.
    function hidePopup() {
        var $btn = editor.$tb.find('.fr-command[data-cmd="myButton"]');
        $btn.attr("disabled", true);
        $btn.find('i')
            .removeClass('bidAi-icon')
        editor.toolbar.enable();
        editor.popups.hide('customPlugin.popup');
    }

    function isActive() {
        if (editor.popups.isVisible('customPlugin.popup')) {
            return true
        } else {
            return false
        }
    }

    // Methods visible outside the plugin.
    return {
        showPopup: showPopup,
        hidePopup: hidePopup,
        isActive: isActive
    }
}

// Define an icon and command for the button that opens the custom popup.
Froalaeditor.DefineIconTemplate('arrowIconTemplate', `<i style="background: inherit; border-radius: 15px; padding: 2px 6px 2px 6px; width: fit-content; font-size:22px; display: inline-flex; align-items: center;" class="icon-new-popular bidAi-icon-disabled"><span style="font-weight: 600; font-family: 'Lexend' !important; font-size: 15px; line-height: 1.5;">BidAI</span></i>`);
Froalaeditor.DefineIcon('buttonIcon', { NAME: 'smartphone', template: 'arrowIconTemplate' });
Froalaeditor.RegisterCommand('myButton', {
    title: `BidAI`,
    icon: 'buttonIcon',
    undo: false,
    focus: false,
    plugin: 'customPlugin',
    callback: function () {
        if (!this.customPlugin.isActive()) {
            this.customPlugin.showPopup();
        }
    }
});

const WysiwygEditor = (props) => {
    if (props.tagusers) {
        var tribute = new Tribute({
            selectClass: "highlight",
            values: props.tagusers,
            requireLeadingSpace: true,
            menuShowMinLength: 1,
            selectTemplate: function (item) {
                props.propsBidSectionUpdateTagUsers(item.original);
                return `<span class="fr-deletable fr-tribute editor_user_selected">@${item.original.key}</a></span>`;
            },
            menuItemTemplate: function (item) {
                return item.original && item.original?.avatar
                    ? `<img
						class="tribute-user-menuitem-img"
						src=${item.original.avatar}
						alt=${item.original?.first_name + " " + item.original?.last_name}
						title=${item.original?.first_name + " " + item.original?.last_name}
					/>${item.string}`
                    : `<span class="tribute-user-menuitem-name bg-primary">${item.original.first_name?.slice(0, 1) + item.original.last_name?.slice(0, 1)}</span>${item.string}`;
            },
        });
    }

    return (
        <React.Fragment>
            <div className="froala_editor_section">
                <FroalaEditor
                    tag="textarea"
                    config={{
                        saveParams: props.bid_content ? {
                            section_id: props.bid_content.section_id,
                            content_id: props.bid_content.content_id,
                            bid_id: props.bid_content.bid_id,
                            demo_content: props.bid_content.bidtype_content ? props.bid_content.bidtype_content.demo_content : null,
                            title: props.bid_content.title,
                            is_pricing: props.bid_content.is_pricing,
                            ai_prompts: props.bid_content.bidtype_content ? props.bid_content.bidtype_content.ai_prompts : null,
                            isFirstLaunch: props.isFirstLaunch
                        } : {},
                        attribution: false,
                        toolbarSticky: true,
                        quickInsertTags: [],
                        charCounterCount: false,
                        key: "VCC6kE4C3D3C2A7B7B7cbhfkuC8eheE5kC-8pI1A1A1B6A1F6D5A1B3B3==",
                        placeholderText: props.placeholder ? props.placeholder : "Write something...",
                        paragraphFormatSelection: true,
                        paragraphDefaultSelection: "Headings",
                        paragraphFormat: {
                            N: "Normal",
                            H2: "Heading 2",
                            H3: "Heading 3",
                            H4: "Heading 4",
                            H5: "Heading 5",
                            H6: "Heading 6",
                        },
                        toolbarButtons: [
                            ["undo", "redo", "|"],
                            ["fontSize", "|"],
                            ["paragraphFormat", "|"],
                            ["bold", "italic", "underline", "|"],
                            ["alignLeft", "alignCenter", "alignRight", "alignJustify", "|"],
                            ["formatOL", "formatUL", "|"],
                            ["insertLink", "insertTable", "insertHR"],
                            ["myButton"]
                        ],
                        // pluginsEnabled: ['customPlugin'],
                        events: {
                            initialized: async function () {
                                var editor = this;
                                if (props.tagusers) {
                                    tribute.attach(editor.el);
                                    editor.events.on(
                                        "keydown",
                                        function (event) {
                                            if (event.which === Froalaeditor.KEYCODE.ENTER && tribute.isActive) {
                                                return false;
                                            }
                                        },
                                        true
                                    );
                                    var $btn = editor.$tb.find('.fr-command[data-cmd="myButton"]');
                                    $btn.attr("disabled", true);
                                }
                                if (props.showAIPrompt) {
                                    props.onFirstBidAILaunch()
                                    editor.customPlugin.showPopup(true)
                                }
                            },
                            click: function (event, clickEvent) {
                                var editor = this;
                                // if (localStorage.getItem(`new-bid-${props.bid_detail.bid_uuid}`) == "true") {
                                //     localStorage.setItem(`new-bid-${props.bid_detail.bid_uuid}`, "false")
                                //     editor.customPlugin.showPopup(true)
                                // }
                            },
                            'mousedown': function (mousedownEvent) {
                                var editor = this;
                                var $btn = editor.$tb.find('.fr-command[data-cmd="myButton"]');
                                if (editor.selection.text()) {
                                    $btn.find('i')
                                        .removeClass('bidAi-icon')
                                        .addClass('bidAi-icon')
                                } else {
                                    $btn.find('i')
                                        .removeClass('bidAi-icon')
                                }
                            },
                            'mouseup': function (mouseupEvent) {
                                var editor = this;
                                var $btn = editor.$tb.find('.fr-command[data-cmd="myButton"]');
                                if (editor.selection.text()) {
                                    $btn.find('i')
                                        .removeClass('bidAi-icon')
                                        .addClass('bidAi-icon')
                                } else {
                                    $btn.find('i')
                                        .removeClass('bidAi-icon')
                                }
                            },
                            'focus': function () {
                                var editor = this;
                                if (props.onClick !== undefined) {
                                    props.onClick(null);
                                }
                                var $btn = editor.$tb.find('.fr-command[data-cmd="myButton"]');
                                $btn.removeAttr("disabled");
                                $btn.find('i')
                                    .removeClass('bidAi-icon')
                            },
                            'keydown': function (keydown) {
                                if (keydown.key === '/') {
                                    this.customPlugin.showPopup()
                                    keydown.preventDefault()
                                }
                            },
                            'blur': function () {
                                var editor = this;
                                var $btn = editor.$tb.find('.fr-command[data-cmd="myButton"]');
                                $btn.attr("disabled", true);
                                $btn.find('i')
                                    .removeClass('bidAi-icon')
                            }
                        },
                    }}
                    model={props.value}
                    onModelChange={(event) => props.onChange(event)}
                />
            </div>
        </React.Fragment>
    );
};

export default WysiwygEditor;