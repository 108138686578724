import React from "react";
import { Link, NavLink } from "react-router-dom";

import WatchTutorialVideoPopup from "./WatchTutorialVideo";
class LoginSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            watch_tutorialvideo_modal: false,
        };
    }

    componentDidMount() {
    }

    render() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                {this.state.watch_tutorialvideo_modal && (
                    <WatchTutorialVideoPopup
                        watch_tutorialvideo_modal={this.state.watch_tutorialvideo_modal}
                        watch_tutorialvideo_modal_action={(modal_action) => {
                            localStorage.removeItem("bidhq_tutorial_token");
                            this.setState({
                                watch_tutorialvideo_modal: modal_action,
                            });
                        }}
                    />
                )}

                <div
                    id="kt_aside"
                    className="aside aside-dark aside-hoverable-btn "
                    data-kt-drawer="true"
                    data-kt-drawer-name="aside"
                    data-kt-drawer-activate="{default: true, lg: false}"
                    data-kt-drawer-overlay="true"
                    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                    data-kt-drawer-direction="start"
                    data-kt-drawer-toggle="#kt_aside_mobile_toggle"
                >
                    <div className="aside-logo flex-column-auto" id="kt_aside_logo">
                        <Link className="big-logo" to="/dashboard">
                            <img alt="Logo" src="assets/images/logo.svg" className="logo" />
                        </Link>
                        <Link className="short-logo" to="/dashboard">
                            <img alt="Logo" src="assets/images/short-logo.svg" className="logo" />
                        </Link>
                        <div id="kt_aside_toggle" className="arrow-btn aside-toggle">
                            <i className="icon-left-arrow icon-side-arrow"></i>
                            <i className="icon-right-arrow icon-side-arrow"></i>
                        </div>
                    </div>
                    <div className="aside-menu flex-column-fluid">
                        <div
                            className="hover-scroll-overlay-y my-5 my-lg-5"
                            id="kt_aside_menu_wrapper"
                            data-kt-scroll="true"
                            data-kt-scroll-activate="{default: false, lg: true}"
                            data-kt-scroll-height="auto"
                            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                            data-kt-scroll-wrappers="#kt_aside_menu"
                            data-kt-scroll-offset="0"
                        >
                            <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500" id="#kt_aside_menu" data-kt-menu="true">
                                <div className="menu-item">
                                    <NavLink to="/dashboard" className="menu-link">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <img src="assets/images/navigation-icon/dashboard.svg" title="dashboard" alt="dashboard" />
                                            </span>
                                        </span>
                                        <span className="menu-title">Dashboard</span>
                                    </NavLink>
                                </div>
                                <div className="menu-item">
                                    <NavLink to="/bid-overview" className="menu-link">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <img src="assets/images/navigation-icon/sales.svg" alt="sales" />
                                            </span>
                                        </span>
                                        <span className="menu-title">Bid overview</span>
                                    </NavLink>
                                </div>

                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Contributor" || user_info.user.role.user_role === "Guest") && (
                                    <div className="menu-item">
                                        <NavLink to="/tasks" className="menu-link">
                                            <span className="menu-icon">
                                                <span className="svg-icon svg-icon-2">
                                                    <img src="assets/images/navigation-icon/my-tasks.svg" alt="my-tasks" />
                                                </span>
                                            </span>
                                            <span className="menu-title">My tasks</span>
                                        </NavLink>
                                    </div>
                                )}

                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Reports Overview") && (
                                    <div className="menu-item">
                                        <NavLink to="/clients" className="menu-link">
                                            <span className="menu-icon">
                                                <span className="svg-icon svg-icon-2">
                                                    <img src="assets/images/navigation-icon/user.svg" alt="user" />
                                                </span>
                                            </span>
                                            <span className="menu-title">Clients</span>
                                        </NavLink>
                                    </div>
                                )}

                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Contributor") && (
                                    <div className="menu-item">
                                        <NavLink to="/library" className="menu-link">
                                            <span className="menu-icon">
                                                <span className="svg-icon svg-icon-2">
                                                    <img src="assets/images/navigation-icon/library.svg" alt="library" />
                                                </span>
                                            </span>
                                            <span className="menu-title">Library</span>
                                        </NavLink>
                                    </div>
                                )}

                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Reports Overview") && (
                                    <div className="menu-item">
                                        <NavLink to="/reporting" className="menu-link">
                                            <span className="menu-icon">
                                                <span className="svg-icon svg-icon-2">
                                                    <img src="assets/images/navigation-icon/Chart.svg" alt="Chart" />
                                                </span>
                                            </span>
                                            <span className="menu-title">Reporting</span>
                                        </NavLink>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="flex-column-auto pt-5 pb-7 px-5">
                        <span
                            className="btn btn-custom btn-primary w-100"
                            onClick={(event) => {
                                this.setState({
                                    watch_tutorialvideo_modal: true,
                                });
                            }}
                        >
                            <span className="svg-icon-2 me-2">
                                <img src="assets/images/navigation-icon/video-play.svg" alt="Tutorial" />
                            </span>
                            <span className="btn-label">Watch Tutorial</span>
                        </span>
                    </div>

                    <div className="aside-footer flex-column-auto pt-5 pb-7 px-5" id="kt_aside_footer">
                        <Link to="/logout" className="btn btn-custom btn-primary w-100">
                            <span className="svg-icon-2">
                                <img src="assets/images/navigation-icon/logout.svg" alt="logout" />
                            </span>
                            <span className="btn-label">Log out</span>
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default LoginSidebar;