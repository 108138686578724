import React from "react";
import SocialLogin from "react-social-login";

class SocialButton extends React.Component {
    render() {
        const { children, triggerLogin, ...props } = this.props;
        return (
            <button
				className="googleButton hvr-border-fade-red btn btn-outline-red rounded-50 w-100"
				onClick={triggerLogin} {...props}
			>{children}</button>
        );
    }
}

export default SocialLogin(SocialButton);