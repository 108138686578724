import React from "react";
class TermsConditions extends React.Component {
    render() {        
        return (
            <React.Fragment>
                <main id="main">
                    <div className="text-center">
                        <h1 className="display-4">Welcome To Terms Conditions</h1>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}
export default TermsConditions;