import React from "react";

import { Modal } from "react-bootstrap";
import PdfDesignViewThumbnail from "../../../../components/Utility/PdfDesignViewThumbnail";

class ViewResponseMediaModal extends React.Component {
    render() {        
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    className="view_responsemedia_modal bid-custom-modal "
                    show={this.props.view_responsemedia_modal}
                    onHide={() => {
                        this.props.view_responsemedia_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.responsemedia_detail.avatar_title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row respose-img">
                            {(this.props.responsemedia_detail.media_type.indexOf("jpeg") !== -1 || this.props.responsemedia_detail.media_type.indexOf("png") !== -1 || this.props.responsemedia_detail.media_type.indexOf("gif") !== -1) && (
                                <img width="100%" height="100%" src={this.props.responsemedia_detail?.avatar} alt={this.props.responsemedia_detail?.avatar_title} title={this.props.responsemedia_detail?.avatar_title} />
                            )}

                            {this.props.responsemedia_detail.media_type.indexOf("pdf") !== -1 && <PdfDesignViewThumbnail width="100%" height="500px" documentUrl={this.props.responsemedia_detail?.avatar} />}

                            {this.props.responsemedia_detail.media_type.indexOf("csv") !== -1 && (
                                <iframe
                                    width="100%"
                                    height="500px"
                                    frameBorder="0"
                                    title={this.props.responsemedia_detail?.avatar_title}
                                    className={this.props.responsemedia_detail?.media_type}
                                    src={this.props.responsemedia_detail?.avatar}
                                />
                            )}

                            {(this.props.responsemedia_detail.media_type.indexOf("msword") !== -1 || this.props.responsemedia_detail.media_type.indexOf("officedocument") !== -1) && (
                                <iframe
                                    scrolling="no"
                                    width="100%"
                                    height="500px"
                                    frameBorder="0"
                                    title={this.props.responsemedia_detail?.avatar_title}
                                    className={this.props.responsemedia_detail?.media_type}
                                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${this.props.responsemedia_detail?.avatar}`}
                                />
                            )}

                            {this.props.responsemedia_detail.media_type.indexOf("excel") !== -1 && (
                                <iframe
                                    width="100%"
                                    height="500px"
                                    frameBorder="0"
                                    title={this.props.responsemedia_detail?.avatar_title}
                                    className={this.props.responsemedia_detail?.media_type}
                                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${this.props.responsemedia_detail?.avatar}`}
                                />
                            )}
                            {this.props.responsemedia_detail.media_type.indexOf("html") !== -1 && (
                                <iframe
                                    width="100%"
                                    height="500px"
                                    frameBorder="0"
                                    title={this.props.responsemedia_detail?.avatar_title}
                                    className={this.props.responsemedia_detail?.media_type}
                                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${this.props.responsemedia_detail?.avatar}`}
                                />
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default ViewResponseMediaModal;