import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { render } from "@testing-library/react";
import { Link, Redirect } from "react-router-dom";

import AddCardModal from "./Modal/AddCardModal";
import NeedHelpModal from "./Modal/NeedHelpModal";
import DowngradeSubscriptionModal from "./Modal/DowngradeSubscriptionModal";
import ConfirmDialog from "../../components/Utility/ConfirmDialog";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

class Billing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            next_subscription: "",
            current_subscription: "",
            paid_subscription: false,

            payment_information: [],
            payment_histories: [],

            addcard_modal: false,
            need_help_modal: false,
            downgrade_subscription_modal: false,

            redirect_url: "",
            isAuthenticated: false,
            confirm_modal: false,
        };
        this.ConfirmDialogEvent = React.createRef();
    }

    async componentDidMount() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));

        let paid_subscription = false;
        let subscription_details = user_info?.subscription_details;
        if (subscription_details) {
            for (let subscription_key in subscription_details) {
                let subscription_detail = subscription_details[subscription_key];
                if (subscription_detail?.is_freetrail === false) {
                    paid_subscription = true;
                }
            }
        }

        this.setState({
            paid_subscription: paid_subscription,
            current_subscription: user_info?.subscription,
        });

        if (user_info.subscription_details.length > 1) {
            this.setState({
                next_subscription: user_info?.subscription_details[1],
            });
        }
        await this.GetPaymentInformation();
        await this.PaymentHistory();
    }

    GetPaymentInformation = async () => {
        this.props.onPaymentInformation().then((response) => {
            if (response.success === true) {
                this.setState({ payment_information: response.data });
            } else {
                // notify.show(response.message, "error");
            }
        });
    };

    PaymentHistory = async () => {
        this.props.onPaymentHistory().then((response) => {
            if (response.success === true) {
                this.setState({ payment_histories: response.data });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    AddCard = (form_data) => {
        const formdata = {};
        let expiry_date = form_data.expiry_date.split("/");
        formdata["cardnumber"] = form_data.cardnumber;
        formdata["exp_month"] = expiry_date[0];
        formdata["exp_year"] = expiry_date[1];
        formdata["cvc"] = form_data.cvc;

        this.props.onAddCard(formdata).then((response) => {
            if (response.success === true) {
                notify.show("Card added successfully.", "success");
                this.setState(
                    {
                        addcard_modal: false,
                    },
                    function () {
                        this.GetPaymentInformation();
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    MakecardPrimary = (information) => {
        const formdata = {};
        formdata["cardId"] = information.card_id;
        this.props.onMakecardPrimary(formdata).then((response) => {
            if (response.success === true) {
                this.GetPaymentInformation();
                notify.show("Primary card selected.", "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    DeleteCard = (information) => {
        render(
            <ConfirmDialog
                title={"Delete card"}
                body="Are you sure you want to delete this payment method?"
                confirmButtonText={"Delete"}
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmDeleteCard(information)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    };

    ConfirmDeleteCard = (information) => {
        const formdata = {};
        formdata["cardId"] = information.card_id;
        this.props.onDeleteCard(formdata).then((response) => {
            if (response.success === true) {
                this.GetPaymentInformation();
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({ confirm_modal: false });
                notify.show("Card deleted.", "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    DownloadAllInvoices = async (event) => {
        event.preventDefault();

        let payment_histories = this.state.payment_histories;
        console.log("payment_histories-----------", payment_histories);
    };

    NeedHelpSubmit = (form_data) => {
        this.props.onBillingContactSupport(form_data).then((response) => {
            if (response.success === true) {
                this.setState({ need_help_modal: false });
                notify.show("Thanks for contacting us", "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    DowngradeSubscriptionSubmit = (form_data) => {
        this.props.onBillingContactSupport(form_data).then((response) => {
            if (response.success === true) {
                this.setState({ downgrade_subscription_modal: false });
                notify.show("Thanks for contacting us", "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    render() {
        const { current_subscription, next_subscription, payment_information, payment_histories } = this.state;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                {this.state.addcard_modal && (
                    <AddCardModal
                        addcard_modal={this.state.addcard_modal}
                        AddCardProps={(form_data) => this.AddCard(form_data)}
                        addcard_modal_action={(modal_action) => {
                            this.setState({
                                addcard_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.need_help_modal && (
                    <NeedHelpModal
                        need_help_modal={this.state.need_help_modal}
                        NeedHelpSubmit={(form_data) => this.NeedHelpSubmit(form_data)}
                        need_help_modal_action={(modal_action) => {
                            this.setState({
                                need_help_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.downgrade_subscription_modal && (
                    <DowngradeSubscriptionModal
                        downgrade_subscription_modal={this.state.downgrade_subscription_modal}
                        DowngradeSubscriptionSubmit={(form_data) => this.DowngradeSubscriptionSubmit(form_data)}
                        downgrade_subscription_modal_action={(modal_action) => {
                            this.setState({
                                downgrade_subscription_modal: modal_action,
                            });
                        }}
                    />
                )}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                            <LoginHeader page_title="Settings" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/settings-myprofile" className="nav-link text-active-primary me-6">
                                                        My profile
                                                    </Link>
                                                </li>
                                                {user_info.user.role.user_role === "Account owner" && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-company" className="nav-link text-active-primary me-6">
                                                            Company details
                                                        </Link>
                                                    </li>
                                                )}
                                                <li className="nav-item">
                                                    <Link to="/settings-account" className="nav-link text-active-primary me-6">
                                                        Account
                                                    </Link>
                                                </li>
                                                {user_info.user.role.user_role === "Account owner" && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-billing" className="nav-link text-active-primary me-6 active">
                                                            Billing
                                                        </Link>
                                                    </li>
                                                )}
                                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-users" className="nav-link text-active-primary me-6">
                                                            Users
                                                        </Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-11 col-xl-10">
                                                <div className="card mb-5 mb-xl-6">
                                                    <div className="card-header border-0 pt-5 mb-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label text-dark fw-bolder fs-2x mb-1">Subscription</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body pt-0 pb-5">
                                                        <div className="d-flex align-items-center flex-wrap  mb-5 plan-pro">
                                                            <div className="me-5">
                                                                <div className="symbol-100px symbol-fixed position-relative">
                                                                    <i className="text-blue icon-subscription fs-2tx"></i>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <div className="d-flex justify-content-between align-items-start flex-wrap">
                                                                    <div className="d-flex flex-column">
                                                                        <h4 className="text-blue mb-1 fs-7">{current_subscription?.subscription_package?.package_name}</h4>
                                                                        {next_subscription ? (
                                                                            <p className="text-gray-new mb-0 fs-9">
                                                                                {userActions.calculate_days_left(current_subscription?.subscription_billing?.end_date)} days left. Your {next_subscription?.subscription_package?.package_name}{" "}
                                                                                subscription will start on {moment(current_subscription?.subscription_billing?.end_date).add(1, "days").format("DD MMMM YYYY")}.
                                                                            </p>
                                                                        ) : (
                                                                            <p className="text-gray-new mb-0 fs-9">
                                                                                {userActions.calculate_days_left(current_subscription?.subscription_billing?.end_date)} days left. Your{" "}
                                                                                {current_subscription?.subscription_package?.package_name} ends on {moment(current_subscription?.subscription_billing?.end_date).format("DD MMMM YYYY")}.
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="edit-btn">
                                                                <Link to="/settings-subscription" className="btn btn-sm light-btn-info btn-info rounded-50 px-6 hvr-grow">
                                                                    {next_subscription ? "View subscriptions" : "Edit"}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {this.state.paid_subscription && (
                                                    <>
                                                        <div className="card mb-5 mb-xl-6">
                                                            <div className="card-header border-0 pt-5 mb-5">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label text-dark fw-bolder fs-2x mb-1">Payment information</span>
                                                                    <span className="text-gray-new mt-1 fw-normal fs-7">Manage the payment methods that are connected to your BidHQ account.</span>
                                                                </h3>
                                                                <div className="card-toolbar add-code-btn">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-dark p-0 fs-7"
                                                                        onClick={(event) => {
                                                                            this.setState({
                                                                                addcard_modal: true,
                                                                            });
                                                                        }}
                                                                    >
                                                                        Add card
                                                                        <span>
                                                                            <i className="icon-add"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className="card-body pt-0 pb-5">
                                                                {payment_information.length !== 0 &&
                                                                    payment_information.map((information, information_key) => (
                                                                        <div className="d-flex align-items-center flex-wrap flex-sm-nowrap mb-5 plan-pro" key={"payment_information_" + information_key}>
                                                                            <div className="me-5">
                                                                                <div className="symbol-100px symbol-fixed position-relative">
                                                                                    <img src={"assets/images/icons/card-img/card-" + information.brand.replace(" ", "-") + ".svg"} alt="card" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                <div className="d-flex justify-content-between align-items-start flex-wrap">
                                                                                    <div className="d-flex flex-column">
                                                                                        <h4 className="text-gray-new mb-1 fs-8">Credit or debit card</h4>
                                                                                        <p className="text-gray-new mb-0 fs-6">
                                                                                            •••• •••• •••• {information.last4} (Expires {information.exp_month}/{information.exp_year})
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {information.default_card ? (
                                                                                <div className="edit-btn d-flex align-items-center">
                                                                                    <h5 className={"mb-0 fs-7 text-dark-blue-light curser_pointer me-2"}>Primary</h5>
                                                                                    <span
                                                                                        className="btn-icon"
                                                                                        onClick={(event) => {
                                                                                            notify.show("Please choose another default method before deleting your card details.", "error");
                                                                                        }}
                                                                                    >
                                                                                        <i className="icon-delete"></i>
                                                                                    </span>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="edit-btn d-flex align-items-center">
                                                                                    <h5
                                                                                        className={"mb-0 fs-7 curser_pointer text-gray-new me-2"}
                                                                                        onClick={(event) => {
                                                                                            this.MakecardPrimary(information);
                                                                                        }}
                                                                                    >
                                                                                        Make primary
                                                                                    </h5>
                                                                                    <span
                                                                                        className="btn-icon"
                                                                                        onClick={(event) => {
                                                                                            this.setState({ confirm_modal: true }, function () {
                                                                                                this.DeleteCard(information);
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        <i className="icon-delete"></i>
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                        <div className="card mb-5 mb-xl-6">
                                                            <div className="card-header border-0 pt-5 mb-5">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label text-dark fw-bolder fs-2x mb-1">Payment history</span>
                                                                </h3>
                                                                <div className="card-toolbar add-code-btn d-none">
                                                                    <button type="button" className="btn btn-sm btn-dark p-0 fs-7" onClick={(event) => this.DownloadAllInvoices(event)}>
                                                                        Download all invoices
                                                                        <span>
                                                                            <i className="icon-download"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0 pb-5 table-responsive">
                                                                <table className="table billing-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="text-gray-new">Date</th>
                                                                            <th className="text-gray-new">Amount</th>
                                                                            <th className="text-gray-new">Description</th>
                                                                            <th className="text-gray-new">Card</th>
                                                                            <th className="text-gray-new">Status</th>
                                                                            <th className="text-gray-new">Invoice</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {payment_histories.length !== 0 &&
                                                                            payment_histories.map((payment_history) => (
                                                                                <tr key={"subscription_id_" + payment_history.subscription_id}>
                                                                                    <td>{moment(payment_history.subscription_date).format("DD/MM/YYYY")}</td>
                                                                                    <td>${payment_history.price}</td>
                                                                                    <td>{payment_history.billing_cycle === "month" ? "Monthly" : "Annual"} subscription</td>
                                                                                    <td>{payment_history?.subscription_billing?.stripe_carddetail}</td>
                                                                                    {payment_history.subscription_billing.stripe_invoice_number ?
                                                                                        <td className={payment_history.is_active ? "success-txt" : "pending-txt"}>{payment_history.is_active ? "Success" : "Pending"}</td> :
                                                                                        <td></td>}
                                                                                    {payment_history.subscription_billing.stripe_invoice_number ?
                                                                                        <td className="text-dark-blue-light">
                                                                                            {payment_history.subscription_billing && (
                                                                                                <Link to={{ pathname: `${payment_history.subscription_billing.stripe_invoice_pdf}` }} target="_blank">
                                                                                                    {payment_history.subscription_billing.stripe_invoice_number} <i className="color1 icon-download"></i>
                                                                                                </Link>
                                                                                            )}
                                                                                        </td> :
                                                                                        <td></td>}

                                                                                </tr>
                                                                            ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="card-footer d-flex pt-0 help-btns-outer justify-content-end">
                                                            <span
                                                                className="btn  rounded-50  help-border-btn me-4 hvr-grow-arrow"
                                                                onClick={(event) => {
                                                                    this.setState({
                                                                        need_help_modal: true,
                                                                    });
                                                                }}
                                                            >
                                                                I need help
                                                            </span>
                                                            <span
                                                                className="btn  rounded-50 help-border-btn hvr-grow-arrow"
                                                                onClick={(event) => {
                                                                    this.setState({
                                                                        downgrade_subscription_modal: true,
                                                                    });
                                                                }}
                                                            >
                                                                Cancel or downgrade subscription
                                                            </span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onAddCard: userActions.AddCard,
    onDeleteCard: userActions.DeleteCard,

    onMakecardPrimary: userActions.MakecardPrimary,
    onPaymentInformation: userActions.PaymentInformation,
    onPaymentHistory: userActions.PaymentHistory,

    onBillingContactSupport: userActions.BillingContactSupport,
};
export default connect(mapStateToProps, mapDispatchToProps)(Billing);