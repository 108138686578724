import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

let qs = require("qs");

/*** GetAllLibrary Action ***/
export const GetAllLibraryStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetAllLibrary_START,
    };
};
export const GetAllLibrarySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetAllLibrary_SUCCESS,
    };
};
export const GetAllLibraryFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetAllLibrary_FAIL,
    };
};
export const GetAllLibrary = (search) => {
    return (dispatch) => {
        dispatch(GetAllLibraryStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetAllLibrary`,
            params: {
                search: search,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllLibrarySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllLibraryFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateLibrary Action ***/
export const CreateLibraryStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.CreateLibrary_START,
    };
};
export const CreateLibrarySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CreateLibrary_SUCCESS,
    };
};
export const CreateLibraryFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CreateLibrary_FAIL,
    };
};
export const CreateLibrary = (form_data) => {
    return (dispatch) => {
        dispatch(CreateLibraryStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CreateLibrary`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CreateLibrarySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateLibraryFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateLibrary Action ***/
export const UpdateLibraryStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdateLibrary_START,
    };
};
export const UpdateLibrarySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateLibrary_SUCCESS,
    };
};
export const UpdateLibraryFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateLibrary_FAIL,
    };
};
export const UpdateLibrary = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateLibraryStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateLibrary`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateLibrarySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateLibraryFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeleteLibrary Action ***/
export const DeleteLibraryStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.DeleteLibrary_START,
    };
};
export const DeleteLibrarySuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DeleteLibrary_SUCCESS,
    };
};
export const DeleteLibraryFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DeleteLibrary_FAIL,
    };
};
export const DeleteLibrary = (form_data) => {
    return (dispatch) => {
        dispatch(DeleteLibraryStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            url: `${actionType.environment_url}/api/DeleteLibrary`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(DeleteLibrarySuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeleteLibraryFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetLibraryWithoutContents Action ***/
export const GetLibraryWithoutContentsStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetLibraryWithoutContents_START,
    };
};
export const GetLibraryWithoutContentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetLibraryWithoutContents_SUCCESS,
    };
};
export const GetLibraryWithoutContentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetLibraryWithoutContents_FAIL,
    };
};
export const GetLibraryWithoutContents = (search) => {
    return (dispatch) => {
        dispatch(GetLibraryWithoutContentsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetLibraryWithoutContents`,
        })
            .then(function (response) {
                dispatch(GetLibraryWithoutContentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetLibraryWithoutContentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetLibraryWithContents Action ***/
export const GetLibraryWithContentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetLibraryWithContents_START,
    };
};
export const GetLibraryWithContentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetLibraryWithContents_SUCCESS,
    };
};
export const GetLibraryWithContentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetLibraryWithContents_FAIL,
    };
};
export const GetLibraryWithContents = (search) => {
    return (dispatch) => {
        dispatch(GetLibraryWithContentsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetLibraryWithContents`,
            params: {
                search: search,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetLibraryWithContentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetLibraryWithContentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetLibraryByID Action ***/
export const GetLibraryByIDStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetLibraryByID_START,
    };
};
export const GetLibraryByIDSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetLibraryByID_SUCCESS,
    };
};
export const GetLibraryByIDFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetLibraryByID_FAIL,
    };
};
export const GetLibraryByID = (library_id, search) => {
    return (dispatch) => {
        dispatch(GetLibraryByIDStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetLibraryByID`,
            params: { library_id: library_id },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetLibraryByIDSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetLibraryByIDFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetContentsByLibraryID Action ***/
export const GetContentsByLibraryIDStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetContentsByLibraryID_START,
    };
};
export const GetContentsByLibraryIDSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetContentsByLibraryID_SUCCESS,
    };
};
export const GetContentsByLibraryIDFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetContentsByLibraryID_FAIL,
    };
};
export const GetContentsByLibraryID = (library_id, search) => {
    return (dispatch) => {
        dispatch(GetContentsByLibraryIDStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetContentsByLibraryID`,
            params: {
                library_id: library_id,
                search: search,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetContentsByLibraryIDSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetContentsByLibraryIDFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateLibraryContent Action ***/
export const CreateLibraryContentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.CreateLibraryContent_START,
    };
};
export const CreateLibraryContentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CreateLibraryContent_SUCCESS,
    };
};
export const CreateLibraryContentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CreateLibraryContent_FAIL,
    };
};
export const CreateLibraryContent = (form_data) => {
    return (dispatch) => {
        dispatch(CreateLibraryContentStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CreateLibraryContent`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CreateLibraryContentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateLibraryContentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateLibraryContent Action ***/
export const UpdateLibraryContentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdateLibraryContent_START,
    };
};
export const UpdateLibraryContentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateLibraryContent_SUCCESS,
    };
};
export const UpdateLibraryContentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateLibraryContent_FAIL,
    };
};
export const UpdateLibraryContent = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateLibraryContentStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateLibraryContent`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateLibraryContentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateLibraryContentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeleteLibraryContent Action ***/
export const DeleteLibraryContentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.DeleteLibraryContent_START,
    };
};
export const DeleteLibraryContentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DeleteLibraryContent_SUCCESS,
    };
};
export const DeleteLibraryContentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DeleteLibraryContent_FAIL,
    };
};
export const DeleteLibraryContent = (content_id) => {
    return (dispatch) => {
        dispatch(DeleteLibraryContentStart());
        return axios({
            method: "delete",
            url: `${actionType.environment_url}/api/DeleteLibraryContent`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            params: {
                content_id: content_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(DeleteLibraryContentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeleteLibraryContentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetContentByID Action ***/
export const GetContentByIDStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetContentByID_START,
    };
};
export const GetContentByIDSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetContentByID_SUCCESS,
    };
};
export const GetContentByIDFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetContentByID_FAIL,
    };
};
export const GetContentByID = (content_id) => {
    return (dispatch) => {
        dispatch(GetContentByIDStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetContentByID`,
            params: { content_id: content_id },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetContentByIDSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetContentByIDFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** LibraryContentCountUpdate Action ***/
export const LibraryContentCountUpdateStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.LibraryContentCountUpdate_START,
    };
};
export const LibraryContentCountUpdateSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.LibraryContentCountUpdate_SUCCESS,
    };
};
export const LibraryContentCountUpdateFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.LibraryContentCountUpdate_FAIL,
    };
};
export const LibraryContentCountUpdate = (content_id) => {
    return (dispatch) => {
        dispatch(LibraryContentCountUpdateStart());
        return axios({
            method: "patch",
            url: `${actionType.environment_url}/api/LibraryContentCountUpdate`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            params: { content_id: content_id },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(LibraryContentCountUpdateSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(LibraryContentCountUpdateFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetLibraryReturnableDocuments Action ***/
export const GetLibraryReturnableDocumentsStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetLibraryReturnableDocuments_START,
    };
};
export const GetLibraryReturnableDocumentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetLibraryReturnableDocuments_SUCCESS,
    };
};
export const GetLibraryReturnableDocumentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetLibraryReturnableDocuments_FAIL,
    };
};
export const GetLibraryReturnableDocuments = (search) => {
    return (dispatch) => {
        dispatch(GetLibraryReturnableDocumentsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetLibraryReturnableDocuments`,
            params: { search: search },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetLibraryReturnableDocumentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetLibraryReturnableDocumentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateLibraryReturnableDocument Action ***/
export const CreateLibraryReturnableDocumentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.CreateLibraryReturnableDocument_START,
    };
};
export const CreateLibraryReturnableDocumentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CreateLibraryReturnableDocument_SUCCESS,
    };
};
export const CreateLibraryReturnableDocumentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CreateLibraryReturnableDocument_FAIL,
    };
};
export const CreateLibraryReturnableDocument = (form_data) => {
    return (dispatch) => {
        dispatch(CreateLibraryReturnableDocumentStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CreateLibraryReturnableDocument`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CreateLibraryReturnableDocumentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateLibraryReturnableDocumentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeleteLibraryReturnableDocument Action ***/
export const DeleteLibraryReturnableDocumentStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.DeleteLibraryReturnableDocument_START,
    };
};
export const DeleteLibraryReturnableDocumentSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DeleteLibraryReturnableDocument_SUCCESS,
    };
};
export const DeleteLibraryReturnableDocumentFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DeleteLibraryReturnableDocument_FAIL,
    };
};
export const DeleteLibraryReturnableDocument = (library_returnable_documents_id) => {
    return (dispatch) => {
        dispatch(DeleteLibraryReturnableDocumentStart());
        return axios({
            method: "delete",
            url: `${actionType.environment_url}/api/DeleteLibraryReturnableDocument`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            params: {
                library_returnable_documents_id: library_returnable_documents_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(DeleteLibraryReturnableDocumentSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeleteLibraryReturnableDocumentFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetLibraryPastProposals Action ***/
export const GetLibraryPastProposalsStart = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.GetLibraryPastProposals_START,
    };
};
export const GetLibraryPastProposalsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetLibraryPastProposals_SUCCESS,
    };
};
export const GetLibraryPastProposalsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetLibraryPastProposals_FAIL,
    };
};
export const GetLibraryPastProposals = (search) => {
    return (dispatch) => {
        dispatch(GetLibraryPastProposalsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetLibraryPastProposals`,
            params: {
                search: search,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetLibraryPastProposalsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetLibraryPastProposalsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetLibraryPastProposalsByBid Action ***/
export const GetLibraryPastProposalsByBidStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetLibraryPastProposalsByBid_START,
    };
};
export const GetLibraryPastProposalsByBidSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetLibraryPastProposalsByBid_SUCCESS,
    };
};
export const GetLibraryPastProposalsByBidFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetLibraryPastProposalsByBid_FAIL,
    };
};
export const GetLibraryPastProposalsByBid = (bid_id) => {
    return (dispatch) => {
        dispatch(GetLibraryPastProposalsByBidStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetLibraryPastProposalsByBid`,
            params: { bid_id: bid_id },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetLibraryPastProposalsByBidSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetLibraryPastProposalsByBidFail("Something went wrong, Please try again."));
                return error;
            });
    };
};