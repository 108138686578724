import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

// import exportAsImage from "../../components/Utility/exportAsImage";
class UpcomingBids extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboard_upcomingbids_show: this.props.dashboard_upcomingbidssection_show,
        };
    }

    render() {
        const { dashboard_upcomingbids_show } = this.state;
        return (
            <React.Fragment>
                <div className="col-xl-4 notify-activity">
                    <div className={"card mb-lg-8" + (dashboard_upcomingbids_show ? " card-xl-stretch " : "")}>
                        <div className="card-header align-items-center border-0 mt-4">
                            <h4 className="card-title align-items-start flex-column m-0">
                                <span className="fw-bolder text-main-color">Upcoming Bids</span>
                                <span className="text-muted fw-normal fs-6 text-grey-dark bid-subtitle">Sorted according to start dates.</span>
                            </h4>
                            <div className="card-toolbar">
                                <button type="button" className="btn btn-sm btn-icon" id="dashboard_upcomingbids" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="icon-dots text-grey-dark icon-size"></i>
                                </button>
                                <div className="dropdown-menu menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_upcomingbids">
                                    {/**
										<div className="py-4 px-2 cursor-pointer link-hover">
											<div className="text-black" onClick={(event) => exportAsImage(this.dashboard_upcomingbids_ref, "Upcoming Bids")}>
												<i className="icon-download me-2 text-black"></i> <span>Download as image</span>
											</div>
										</div>
									**/}
                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                        <div className="text-black" onClick={(event) => this.props.dashboard_upcomingbidssection_show_action(dashboard_upcomingbids_show ? false : true)}>
                                            <i className={"me-2 text-black" + (dashboard_upcomingbids_show ? " icon-eye font-size-8" : " bi bi-eye")}></i>
                                            <span>{dashboard_upcomingbids_show ? "Hide chart" : "Show chart"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ref={(node) => (this.dashboard_upcomingbids_ref = node)} className={"card-body pt-0 up-bids" + (dashboard_upcomingbids_show ? "" : " d-none")}>
                            <div className="scroll-y r-activity-height">
                                {this.props.upcoming_bids.length !== 0 ? (
                                    this.props.upcoming_bids.map((upcoming_bid) => (
                                        <div className="Bid-outer up-bids" key={"upcoming_bid_" + upcoming_bid.bid_id}>
                                            <div className="d-flex mb-4 p-4">
                                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                    <div className="flex-grow-1 me-2">
                                                        <span className="text-muted fw-normal fs-7 color1">{moment(upcoming_bid.submission_date).format("DD/MM/YYYY")}</span>
                                                        <span className="color1 fw-bold d-block fs-7">
                                                            <Link to={`/bid-response/${upcoming_bid.bid_uuid}`}>
                                                                <strong>{upcoming_bid.bid_title}</strong>
                                                            </Link>
                                                        </span>
                                                        <span className="color1 fw-bold d-block fs-7">
                                                            <Link to={`/client-update/${upcoming_bid?.client?.client_uuid}`}>
                                                                <strong>{upcoming_bid?.client?.client_name}</strong>
                                                            </Link>
                                                        </span>
                                                    </div>
                                                    <div className="text-end">
                                                        <span className="text-muted fw-normal fs-8 color1 d-block">Team</span>
                                                        <div className="symbol-group symbol-hover" key={"bid_team_" + upcoming_bid.bid_id}>
                                                            {upcoming_bid.bid_teams &&
                                                                upcoming_bid.bid_teams.map((bid_team) => (
                                                                    <div className="custom-tooltip up-bid-tooltip" key={"bid_owner_" + bid_team.user_id}>
                                                                        <div className="symbol symbol-25px symbol-circle">
                                                                            {bid_team && bid_team.avatar ? (
                                                                                <span className="symbol_img symbol-label">
                                                                                    <img src={bid_team.avatar} alt="bid_team_avatar" title="bid_team_avatar" />
                                                                                    <span className="custom-tooltip-text ">{bid_team.first_name + " " + bid_team.last_name}</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span className="symbol-label bg-primary text-inverse-primary">
                                                                                    {bid_team.first_name?.slice(0, 1) + bid_team.last_name?.slice(0, 1)}
                                                                                    <span className="custom-tooltip-text ">{bid_team.first_name + " " + bid_team.last_name}</span>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-center">No records found</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default UpcomingBids;