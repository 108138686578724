import React, { useState, useEffect, useCallback, useRef } from "react";

import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";
import { render } from "@testing-library/react";
import { Link, Redirect } from "react-router-dom";

import CompanyUserEditModal from "./Modal/CompanyUserEditModal";
import CompanyUserRemoveModal from "./Modal/CompanyUserRemoveModal";

import AddUsersModal from "./Modal/AddUsersModal";
import InviteUserEditModal from "./Modal/InviteUserEditModal";

import InvitedUsersItems from "./InvitedUsersItems";
import CompanyUsersItems from "./CompanyUsersItems";

import ConfirmDialog from "../../../components/Utility/ConfirmDialog";

import * as userActions from "../../../actions/index";
import Loader from "../../../components/Utility/Loader";
import LoginFooter from "../../../components/LoginFooter";
import LoginHeader from "../../../components/LoginHeader";
import LoginSidebar from "../../../components/LoginSidebar";
import * as actionType from "../../../constants/actionTypes";

const UsersList = (props) => {
    const [active_tab, setactive_tab] = useState("company_users_tab"); // company_users_tab, inviate_users_tab

    const dispatch = useDispatch();
    const ConfirmDialogEvent = useRef(null);
    const [user_roles, setuser_roles] = useState("");

    // Company User
    const [company_users, setcompany_users] = useState([]);
    const [company_userscount, setcompany_userscount] = useState(0);

    const [company_usersfilterlimit] = useState(10);
    const [company_usersfilterpage, setcompany_usersfilterpage] = useState(1);
    const [company_usersfiltersort, setcompany_usersfiltersort] = useState("user_id");
    const [company_usersfilterorder, setcompany_usersfilterorder] = useState("asc");

    const [edit_company_user, setedit_company_user] = useState([]);
    const [edit_company_modal, setedit_company_modal] = useState(false);

    const [remove_company_user, setremove_company_user] = useState([]);
    const [remove_company_user_modal, setremove_company_user_modal] = useState(false);

    // Invite User
    const [invited_users, setinvited_users] = useState([]);
    const [invited_userscount, setinvited_userscount] = useState(0);

    const [invited_usersfilterlimit] = useState(10);
    const [invited_usersfilterpage, setinvited_usersfilterpage] = useState(1);
    const [invited_usersfiltersort, setinvited_usersfiltersort] = useState("invite_id");
    const [invited_usersfilterorder, setinvited_usersfilterorder] = useState("asc");

    const [addinviteduser_modal, setaddinviteduser_modal] = useState(false);

    const [edit_invited_user, setedit_invited_user] = useState([]);
    const [edit_invited_modal, setedit_invited_modal] = useState(false);

    const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);		
        if (queryParams.get("activesection") && user_roles) {
            if (queryParams.get("activesection") === "inviate-users") {
                setaddinviteduser_modal(true);
                setactive_tab("inviate_users_tab");
            }
        }
    }, [user_roles, setactive_tab, setaddinviteduser_modal]);

    useEffect(() => {
        const GetRoles = () => {
            userActions.GetRoles().then((response) => {
                if (response.success === true) {
                    setuser_roles(response.data);
                } else {
                    notify.show(response.message, "error");
                }
            });
        };

        GetRoles();
    }, [dispatch]);

    const GetAllUsers = useCallback(() => {
        dispatch(userActions.GetAllUsers(company_usersfilterlimit, company_usersfilterpage, company_usersfiltersort, company_usersfilterorder)).then((response) => {
            if (response.success === true) {
                setcompany_users(response.data);
                setcompany_userscount(response.count);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, company_usersfilterlimit, company_usersfilterpage, company_usersfiltersort, company_usersfilterorder]);

    useEffect(() => {
        GetAllUsers();
    }, [dispatch, GetAllUsers, company_usersfilterlimit, company_usersfilterpage, company_usersfiltersort, company_usersfilterorder]);

    const GetInviteUsers = useCallback(() => {
        dispatch(userActions.GetInviteUsers(invited_usersfilterlimit, invited_usersfilterpage, invited_usersfiltersort, invited_usersfilterorder)).then((response) => {
            if (response.success === true) {
                setinvited_users(response.data);
                setinvited_userscount(response.count);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, invited_usersfilterlimit, invited_usersfilterpage, invited_usersfiltersort, invited_usersfilterorder]);

    useEffect(() => {
        GetInviteUsers();
    }, [dispatch, GetInviteUsers, invited_usersfilterlimit, invited_usersfilterpage, invited_usersfiltersort, invited_usersfilterorder]);

    const InviteUserSubmit = (form_data) => {
        dispatch(userActions.InviteUser(form_data)).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
                setaddinviteduser_modal(false);
                GetInviteUsers();
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const CompanyUserUpdate = (form_data) => {
        dispatch(userActions.UpdateUser(form_data)).then((response) => {
            if (response.success === true) {
                GetAllUsers();
                setedit_company_user([]);
                setedit_company_modal(false);
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const CompanyUserRemove = (company_user) => {
        setedit_company_user([]);
        setedit_company_modal(false);

        setremove_company_user(company_user);
        setremove_company_user_modal(true);
    };

    const ConfirmCompanyUserRemove = (form_data) => {
        dispatch(userActions.RemoveUser(form_data)).then((response) => {
            if (response.success === true) {
                GetAllUsers();
                setremove_company_user([]);
                setremove_company_user_modal(false);
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const ReInviteUser = (invited_user) => {
        const form_data = {};
        form_data["site_url"] = actionType.siteurl;
        form_data["invite_id"] = invited_user.invite_id;
        dispatch(userActions.ReInviteUser(form_data)).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const RemoveInviteUser = (invited_user) => {
        render(
            <ConfirmDialog
                title={`Delete ${invited_user.invite_email}?`}
                body="Are you sure you want to delete this user? It’ll be gone permanently."
                confirm_modal={true}
                ref={ConfirmDialogEvent}
                confirmButtonText="Delete"
                onConfirm={(event) => ConfirmRemoveInviteUser(invited_user)}
            />
        );
    };

    const ConfirmRemoveInviteUser = (invited_user) => {
        dispatch(userActions.RemoveInviteUser(invited_user.invite_id)).then((response) => {
            if (response.success === true) {
                GetInviteUsers();
                notify.show(response.message, "success");
                ConfirmDialogEvent.current.ModalActionHide();
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const ConfirmEditInviteUser = (form_data) => {
        dispatch(userActions.UpdateInviteUser(form_data)).then((response) => {
            if (response.success === true) {
                setedit_invited_user([]);
                setedit_invited_modal(false);
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    return (
        <React.Fragment>
            {props.loader ? <Loader /> : null}
            {props.isAuthenticated === false ? <Redirect to="/logout" /> : null}

            {edit_company_modal && (
                <CompanyUserEditModal
                    user_roles={user_roles}
                    edit_company_user={edit_company_user}
                    edit_company_modal={edit_company_modal}
                    CompanyUserUpdate={(form_data) => CompanyUserUpdate(form_data)}
                    CompanyUserRemove={(company_user) => CompanyUserRemove(company_user)}
                    edit_company_modal_action={(modal_action) => setedit_company_modal(modal_action)}
                />
            )}

            {remove_company_user_modal && (
                <CompanyUserRemoveModal
                    invited_users={invited_users}
                    remove_company_user={remove_company_user}
                    remove_company_user_modal={remove_company_user_modal}
                    ConfirmCompanyUserRemove={(event) => ConfirmCompanyUserRemove(event)}
                    remove_company_user_modal_action={(modal_action) => setremove_company_user_modal(modal_action)}
                />
            )}

            {addinviteduser_modal && (
                <AddUsersModal
                    user_roles={user_roles}
                    addinviteduser_modal={addinviteduser_modal}
                    InviteUserSubmit={(event) => InviteUserSubmit(event)}
                    addinviteduser_modal_action={(modal_action) => setaddinviteduser_modal(modal_action)}
                />
            )}

            {edit_invited_modal && (
                <InviteUserEditModal
                    user_roles={user_roles}
                    edit_invited_user={edit_invited_user}
                    edit_invited_modal={edit_invited_modal}
                    ReInviteUser={(invited_user) => ReInviteUser(invited_user)}
                    RemoveInviteUser={(invited_user) => RemoveInviteUser(invited_user)}
                    ConfirmEditInviteUser={(form_data) => ConfirmEditInviteUser(form_data)}
                    edit_invited_modal_action={(modal_action) => setedit_invited_modal(modal_action)}
                />
            )}

            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                        <LoginHeader page_title="Settings" />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                            <li className="nav-item">
                                                <Link to="/settings-myprofile" className="nav-link text-active-primary me-6">
                                                    My profile
                                                </Link>
                                            </li>
                                            {user_info.user.role.user_role === "Account owner" && (
                                                <li className="nav-item">
                                                    <Link to="/settings-company" className="nav-link text-active-primary me-6">
                                                        Company details
                                                    </Link>
                                                </li>
                                            )}
                                            <li className="nav-item">
                                                <Link to="/settings-account" className="nav-link text-active-primary me-6">
                                                    Account
                                                </Link>
                                            </li>
                                            {user_info.user.role.user_role === "Account owner" && (
                                                <li className="nav-item">
                                                    <Link to="/settings-billing" className="nav-link text-active-primary me-6">
                                                        Billing
                                                    </Link>
                                                </li>
                                            )}
                                            {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                <li className="nav-item">
                                                    <Link to="/settings-users" className="nav-link text-active-primary me-6 active">
                                                        Users
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card mb-5 mb-xl-8 s-users">
                                                <div className="card-header border-0 pt-5 ">
                                                    <ul className="nav nav-pills ">
                                                        <li className={"nav-item" + (active_tab === "company_users_tab" ? " active" : "")} onClick={(event) => setactive_tab("company_users_tab")}>
                                                            <span className="nav-link me-4 px-0">Company Users</span>
                                                        </li>
                                                        <li className={"nav-item" + (active_tab === "inviate_users_tab" ? " active" : "")} onClick={(event) => setactive_tab("inviate_users_tab")}>
                                                            <span className="nav-link me-4 px-0">Invite Users</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {active_tab === "company_users_tab" && (
                                                    <>
                                                        <div className="card-header border-0">
                                                            <h3 className="card-title align-items-start flex-column">
                                                                <span className="card-label fw-bolder fs-2x mb-1">All users</span>
                                                                <span className="text-gray-new mt-1 fw-normal fs-6">A full list of your teammates.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="company_users_tab">
                                                                <div className="table-responsive user-list-tables">
                                                                    <CompanyUsersItems
                                                                        filterlimit={company_usersfilterlimit}
                                                                        filterpage={company_usersfilterpage}
                                                                        company_users={company_users}
                                                                        company_userscount={company_userscount}
                                                                        propsfilterpage={(event) => setcompany_usersfilterpage(event)}
                                                                        propsfiltersort={(event) => setcompany_usersfiltersort(event)}
                                                                        propsfilterorder={(event) => setcompany_usersfilterorder(event)}
                                                                        CompanyUserEdit={(company_user) => {
                                                                            setedit_company_user(company_user);
                                                                            setedit_company_modal(true);
                                                                        }}
                                                                        CompanyUserRemove={(company_user) => CompanyUserRemove(company_user)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {active_tab === "inviate_users_tab" && (
                                                    <>
                                                        <div className="card-header border-0">
                                                            <h3 className="card-title align-items-start flex-column">
                                                                <span className="card-label fw-bolder fs-2x mb-1">Invite users</span>
                                                                <span className="text-gray-new mt-1 fw-normal fs-6">A full list of your teammates.</span>
                                                            </h3>
                                                            <div className="card-toolbar">
                                                                {user_info?.subscription?.is_freetrail ? (
                                                                    <button type="button" className="btn rounded-50 btn-primary btn-sm hvr-grow" onClick={(event) => setaddinviteduser_modal(true)}>
                                                                        Invite users
                                                                        <span className="svg-icon svg-icon-2">
                                                                            <img src="assets/images/icons/white-add.svg" title="white-add" alt="white-add" />
                                                                        </span>
                                                                    </button>
                                                                ) : user_info?.company?.company_users >= user_info?.company?.user_limits ? (
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary rounded-50 btn-sm hvr-grow"
                                                                        onClick={() => {
                                                                            notify.show("User Invitation Limit Exceeded, Please Upgrade Your Subscription", "error");
                                                                        }}
                                                                    >
                                                                        Invite users
                                                                        <span className="svg-icon svg-icon-2">
                                                                            <img src="assets/images/icons/white-add.svg" title="white-add" alt="white-add" />
                                                                        </span>
                                                                    </button>
                                                                ) : (
                                                                    <button type="button" className="btn rounded-50 btn-primary btn-sm hvr-grow" onClick={(event) => setaddinviteduser_modal(true)}>
                                                                        Invite users
                                                                        <span className="svg-icon svg-icon-2">
                                                                            <img src="assets/images/icons/white-add.svg" title="white-add" alt="white-add" />
                                                                        </span>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="inviate_users_tab">
                                                                <div className="table-responsive user-list-tables">
                                                                    <InvitedUsersItems
                                                                        filterlimit={invited_usersfilterlimit}
                                                                        filterpage={invited_usersfilterpage}
                                                                        invited_users={invited_users}
                                                                        invited_userscount={invited_userscount}
                                                                        propsfilterpage={(event) => setinvited_usersfilterpage(event)}
                                                                        propsfiltersort={(event) => setinvited_usersfiltersort(event)}
                                                                        propsfilterorder={(event) => setinvited_usersfilterorder(event)}
                                                                        EditInviteUser={(event, invited_user) => {
                                                                            setedit_invited_user(invited_user);
                                                                            setedit_invited_modal(true);
                                                                        }}
                                                                        RemoveInviteUser={(event, invited_user) => RemoveInviteUser(invited_user)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetAllUsers: userActions.GetAllUsers,
    onGetInviteUsers: userActions.GetInviteUsers,

    onInviteUser: userActions.InviteUser,
    onReInviteUser: userActions.ReInviteUser,
    onUpdateInviteUser: userActions.UpdateInviteUser,
    onRemoveInviteUser: userActions.RemoveInviteUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersList);