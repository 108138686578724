import React from "react";
import { Modal } from "react-bootstrap";

class LockingYourBidModal extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    className="lockingyourbid_modal bid-custom-modal "
                    show={this.props.lockingyourbid_modal}
                    onHide={() => {
                        this.props.lockingyourbid_modal_action(false);
                    }}
                >
                    <Modal.Body>
                        <div className="text-center m-0">
                            <img className="mb-7" src="assets/images/icons/unlock-bid.svg" alt="unlock-bid" />
                            <h2 className="text-dark fw-bolder fs-22px">Locking your bid.</h2>
                            <p className="mb-5 fs-6 mt-3">It might take a few seconds.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default LockingYourBidModal;