import React from "react";
import { Modal } from "react-bootstrap";
import { Dropdown, DropdownButton } from "react-bootstrap";

import Validations from "../../../../components/Utility/Validations";

class InsertSectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            insert_section_form: {
                bid_id: {
                    label: "Bid ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                bid_content: {
                    type: "select",
                    label: "Select a type of section",
                    value_title: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [],
                    // options: [{ opk: "bid_content_opk_0", name: "Select a type of section", value: "" }],
                },
            },
            bid_detail: [],
        };
    }

    componentDidMount() {
        let bid_detail = this.props.bid_detail;
        let manage_content = this.props.manage_content;
        // let is_pricing_content = this.props.is_pricing_content;
        const update_insert_section_form = { ...this.state.insert_section_form };
        let manage_content_option = [];

        for (let key in manage_content) {
            // if (manage_content[key].is_pricing === true && is_pricing_content === true) {
            //     continue;
            // }

            manage_content_option.push({
                opk: manage_content[key].content_id,
                name: manage_content[key].title,
                value: manage_content[key].content_id,
            });
        }
        update_insert_section_form.bid_id.value = bid_detail.bid_id;
        update_insert_section_form.bid_content.options = manage_content_option;
        this.setState({
            bid_detail: bid_detail,
            bid_team_form: update_insert_section_form,
        });
    }

    inputChangeHandler(event, bid_content, identifier) {
        const update_insert_section_form = { ...this.state.insert_section_form };
        const form_element = { ...update_insert_section_form[identifier] };

        form_element.value = bid_content.value;
        form_element.value_title = bid_content.name;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_insert_section_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_insert_section_form) {
            is_form_valid = update_insert_section_form[identifier].valid && is_form_valid;
        }

        this.setState({ insert_section_form: update_insert_section_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_insert_section_form = this.state.insert_section_form;
        for (let key in update_insert_section_form) {
            let form_element = update_insert_section_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_insert_section_form[key] = form_element;
        }
        this.setState({ insert_section_form: update_insert_section_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.insert_section_form) {
                if (this.state.insert_section_form[key].type === "typeahead") {
                    let form_element = this.state.insert_section_form[key].value;
                    form_data[key] = form_element.map((t) => t.value);
                } else {
                    form_data[key] = this.state.insert_section_form[key].value;
                }
            }
            this.props.InsertSectionSubmit(form_data);
        }
    };

    render() {
        const { insert_section_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    className="bid_insert_section_modal bid-custom-modal"
                    show={this.props.bid_insert_section_modal}
                    onHide={() => {
                        this.props.bid_insert_section_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add section</Modal.Title>
                    </Modal.Header>
                    <form className="form w-100" onSubmit={this.SubmitHandler}>
                        <Modal.Body>
                            <div className="row mb-6">
                                <div className={"col-lg-12 fv-row" + (!insert_section_form.bid_content.valid && insert_section_form.bid_content.onBlur_out ? " error-field" : "")}>
                                    <label className="form-label fs-6 color1">{insert_section_form.bid_content.label}</label>
                                    <DropdownButton className="clientdorpdown add-sect-dropdown" title={insert_section_form.bid_content.value_title}>
                                        {insert_section_form.bid_content.options.map((option) => (
                                            <Dropdown.Item key={option.opk} eventKey={option.value} onClick={(event) => this.inputChangeHandler(event, option, "bid_content")}>
                                                {option.name}
                                            </Dropdown.Item>
                                        ))}
                                        <Dropdown.Item eventKey="0" className="add-client-btn text-gray-new" onClick={(event) => this.props.BidInsertNewBlankSection(event)}>
                                            <i className="fas fa-plus"></i> Add new section
                                        </Dropdown.Item>
                                    </DropdownButton>
                                    {!insert_section_form.bid_content.valid && insert_section_form.bid_content.onBlur_out ? <div className="error field-error">{insert_section_form.bid_content.error_msg}</div> : ""}
                                </div>
                            </div>
                            <Modal.Footer>
                                <button
                                    type="button"
                                    className="btn cancel-butn rounded-50 w-150 text-gray-new"
                                    onClick={() => {
                                        this.props.bid_insert_section_modal_action(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-danger rounded-50 w-150 hvr-grow">
                                    Add
                                </button>
                            </Modal.Footer>
                        </Modal.Body>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}
export default InsertSectionModal;