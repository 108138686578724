import React from "react";
import moment from "moment";
class LibraryRightSideFilePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            library_content: "",
        };
    }

    componentDidMount() {
        this.setState({
            library_content: this.props.library_content,
        });
    }

    render() {
        const { library_content } = this.state;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                <div className="wrap-right-info right-comment bid_rightsidebar_sectioncomment">
                    <div
                        className="comment-close-button"
                        onClick={() => {
                            this.props.library_rightsidebar_filepreview_action(false);
                        }}
                    >
                        <i className="icon-cross-2" aria-hidden="true"></i>
                    </div>
                    <div className="card-toolbar m-0">
                        <div className="card-header border-0 p-0">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card scroll-y right-scroll-height border-0">
                                        <div className="title-header-top border-bottom p-5">
                                            <h4 className="text-dark fw-bolder fs-2x m-0">
                                                <span className="icon-File color1 me-1"></span> File info
                                            </h4>
                                        </div>

                                        <div className="p-5 pb-1 l-sidbar-title">
                                            <h6 className="text-gray-400 fs-9 mb-1">CONTENT NAME</h6>
                                            <span className="fs-base d-block fw-bolder color1">{library_content.content_name}</span>
                                        </div>
                                        <div className="p-5 pb-1 l-sidbar-title">
                                            <h6 className="text-gray-400 fs-9 mb-1">LAST MODIFIED</h6>
                                            <span className="fs-base d-block color1 ">{moment(library_content.updated_date).format("DD/MM/YYYY")}</span>
                                        </div>
                                        <div className="p-5 pb-1 l-sidbar-title">
                                            <h6 className="text-gray fs-9 mb-1">MODIFIED BY</h6>
                                            <div className="symbol-group symbol-hover" key={"library_content_" + library_content.content_id + "modified_by"}>
                                                {library_content.modified_by &&
                                                    library_content.modified_by.map((modified_by) => (
                                                        <div className="custom-tooltip" key={"modified_by_" + modified_by.user_id}>
                                                            <div className="symbol symbol-25px symbol-circle">
                                                                {modified_by && modified_by.avatar ? (
                                                                    <span className="symbol_img symbol-label">
                                                                        <img src={modified_by.avatar} alt="bid_owner_avatar" title="bid_owner_avatar" />
                                                                        <span className="custom-tooltip-text">{modified_by.first_name + " " + modified_by.last_name}</span>
                                                                    </span>
                                                                ) : (
                                                                    <span className="symbol-label bg-primary text-inverse-primary">
                                                                        {modified_by.first_name?.slice(0, 1) + modified_by.last_name?.slice(0, 1)}
                                                                        <span className="custom-tooltip-text">{modified_by.first_name + " " + modified_by.last_name}</span>
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="p-5 pb-1 l-sidbar-title">
                                            <h6 className="text-gray-400 fs-9 mb-2">LAST USED IN BID</h6>
                                            <span className="fs-base d-block color1 ">{library_content.lastused_inbid ? moment(library_content.lastused_inbid).format("DD/MM/YYYY") : "-"}</span>
                                        </div>
                                        <div className="p-5 pb-1 l-sidbar-title">
                                            <h6 className="text-gray-400 fs-9 mb-2">OVERALL TIMES USED</h6>
                                            <span className="fs-base d-block color1">{library_content.overall_timeused ? library_content.overall_timeused : 0}</span>
                                        </div>
                                        <div className="p-5 pb-1 l-sidbar-title">
                                            <h6 className="text-gray-400 fs-9 mb-2">DATE CREATED</h6>
                                            <span className="fs-base d-block color1">{moment(library_content.created_date).format("DD/MM/YYYY")}</span>
                                        </div>
                                        <div className="p-5 pb-1 l-sidbar-title">
                                            <h6 className="text-gray-400 fs-9 mb-2">OWNER</h6>
                                            <div className="custom-tooltip">
                                                <div className="symbol symbol-25px symbol-circle">
                                                    <span className="symbol-label bg-primary text-inverse-primary">
                                                        {library_content.created_user ? library_content.created_user.first_name?.slice(0, 1) + library_content.created_user.last_name?.slice(0, 1) : ""}
                                                        <span className="custom-tooltip-text ">{library_content.created_user ? library_content.created_user.first_name + " " + library_content.created_user.last_name : ""}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-sidebar-footer border-top p-5">
                                        <ul className=" nav">
                                            {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                <li className="nav-item curser_pointer">
                                                    <span className="nav-link justify-content-center text-gray-new " onClick={() => this.props.LibraryContentRename(library_content)}>
                                                        <i className="icon-edit fs-4 text-gray-new"></i>Rename
                                                    </span>
                                                </li>
                                            )}
                                            <li className="nav-item curser_pointer">
                                                <span className="nav-link justify-content-center text-gray-new" onClick={() => this.props.LibraryContentMakeAsCopy(library_content)}>
                                                    <i className="icon-copy fs-4 text-gray-new"></i>Make a copy
                                                </span>
                                            </li>
                                            {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                <li className="nav-item curser_pointer">
                                                    <span className="nav-link justify-content-center text-gray-new" onClick={() => this.props.LibraryContentDelete(library_content)}>
                                                        <i className="icon-delete fs-4 text-gray-new"></i> Delete
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default LibraryRightSideFilePreview;