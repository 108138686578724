import React, { useState, useEffect, useCallback } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";

import AddLibraryFolder from "./modal/AddLibraryFolder";
import PastProposal from "./PastProposal";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

const LibraryList = (props) => {
    const dispatch = useDispatch();
    const [loader] = useState(false);

    const [library_folders, setlibrary_folders] = useState([]);
    const [library_search, setlibrary_search] = useState(null);
    const [addlibraryfolder_modal, setaddlibraryfolder_modal] = useState(false);

    const GetAllLibrary = useCallback(() => {
        dispatch(userActions.GetAllLibrary(library_search)).then((response) => {
            if (response.success === true) {
                setlibrary_folders(response.data);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, library_search]);

    useEffect(() => {
        GetAllLibrary();
    }, [GetAllLibrary, library_search]);

    const CreateLibrary = (form_data) => {
        props.onCreateLibrary(form_data).then((response) => {
            if (response.success === true) {
                GetAllLibrary();
                setaddlibraryfolder_modal(false);
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    return (
        <React.Fragment>
            {loader ? <Loader /> : null}
            {props.loader ? <Loader /> : null}

            {addlibraryfolder_modal && (
                <AddLibraryFolder
                    addlibraryfolder_modal={addlibraryfolder_modal}
                    CreateLibrary={(form_data) => CreateLibrary(form_data)}
                    addlibraryfolder_modal_action={(modal_action) => {
                        setaddlibraryfolder_modal(modal_action);
                    }}
                />
            )}
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                        <LoginHeader page_title="Library" />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                            <li className="nav-item">
                                                <Link to="/content" className="nav-link text-active-primary me-6 active">
                                                    Content
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="returnable-documents" className="nav-link text-active-primary me-6">
                                                    Returnable Documents
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="">
                                        <div className="card mb-5 mb-xl-8">
                                            <div className="card-header border-0 pt-5 mb-5 d-block">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="d-flex flex-wrap flex-sm-nowrap">
                                                            <h3 className="card-title align-items-start flex-column">
                                                                <span className="card-label mt-1 fw-bolder fs-2x color1">Content</span>
                                                                <span className="fw-normal text-gray-new fs-6 mt-2">Pre-created content responses, ready to reuse in your bids.</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-end">
                                                        <div className="d-flex align-items-center my-2 justify-content-end">
                                                            <div className="d-flex align-items-center position-relative me-4 library-search">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Search for content..."
                                                                    className="form-control form-control-sm ps-7 search-expand"
                                                                    onChange={(event) => {
                                                                        setlibrary_search(event.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="rounded-50 btn btn-primary btn-sm hvr-grow"
                                                                onClick={(event) => {
                                                                    setaddlibraryfolder_modal(true);
                                                                }}
                                                            >
                                                                Create new folder
                                                                <span className="svg-icon svg-icon-2">
                                                                    <img src="assets/images/icons/white-add.svg" title="all_bids" alt="all_bids" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {library_folders && library_folders.length !== 0 && (
                                                <div className="card-body pt-0">
                                                    <div className="row library-outer">
                                                        {library_folders.map((library_folder) => {
                                                            return (
                                                                <Link className="col-md-3 col-xl-2 fv-row mb-5" to={`/case-study/${library_folder.library_uuid}`} key={"library_folder_" + library_folder.library_id}>
                                                                    <div className="row main_floder_div" library_id={library_folder.library_id}>
                                                                        <div className="col-2 col-sm-3 col-lg-3 p-0">
                                                                            <i className="fa fa-folder" aria-hidden="true"></i>
                                                                        </div>
                                                                        <div className="col-10 col-sm-9 col-lg-9 p-0">
                                                                            <div className="folder_name">{library_folder.name}</div>
                                                                            <div className="folder_foles">{library_folder.library_contents ? library_folder.library_contents.length : 0} files</div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="card mb-5 mb-xl-8">
                                        <PastProposal />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
function mapStateToProps(state) {
    return {
        error: state.Library.error,
        loader: state.Library.loader,
        isAuthenticated: state.Library.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetAllLibrary: userActions.GetAllLibrary,
    onCreateLibrary: userActions.CreateLibrary,
    onUpdateLibrary: userActions.UpdateLibrary,
    onDeleteLibrary: userActions.DeleteLibrary,
};
export default connect(mapStateToProps, mapDispatchToProps)(LibraryList);