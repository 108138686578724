import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { notify } from "react-notify-toast";

import Loader from "../../components/Utility/Loader";
import * as userActions from "../../actions/index";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";
import Validations from "../../components/Utility/Validations";

class ClientCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientcreate_form: {
                client_name: {
                    type: "text",
                    label: "Client name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                abn: {
                    type: "text",
                    label: "ABN",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false, NumberAndSpace: true },
                },
                client_email: {
                    type: "email",
                    label: "Client email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false, email: true },
                },
                client_number: {
                    type: "text",
                    label: "Phone number",
                    value: "",
                    valid: false,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                address: {
                    type: "text",
                    label: "Address",
                    value: "",
                    valid: false,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                zipcode: {
                    type: "text",
                    label: "Postcode",
                    value: "",
                    valid: false,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                state: {
                    type: "select",
                    label: "State",
                    value: "",
                    valid: false,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                    options: [{ opk: "role_opk_0", name: "Select State", value: "" }],
                },
            },
            loader: false,
        };
    }

    componentDidMount() {
        const update_clientcreate_form = { ...this.state.clientcreate_form };
        userActions.GetCountries().then((response) => {
            if (response.success === true) {
                let country_detail = response.data;
                let country_state = country_detail["AU"][0].state;
                let state_option = [{ opk: "state_opk0", name: "Select a state", value: "" }];
                for (let key in country_state) {
                    state_option.push({
                        opk: country_state[key].state_code,
                        name: country_state[key].state_name,
                        value: country_state[key].state_code,
                    });
                }
                update_clientcreate_form.state.options = state_option;
                this.setState({
                    loader: false,
                    clientcreate_form: update_clientcreate_form,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    inputChangeHandler(event, identifier) {
        const update_clientcreate_form = { ...this.state.clientcreate_form };
        const form_element = { ...update_clientcreate_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_clientcreate_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_clientcreate_form) {
            is_form_valid = update_clientcreate_form[identifier].valid && is_form_valid;
        }

        this.setState({
            clientcreate_form: update_clientcreate_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_clientcreate_form = this.state.clientcreate_form;
        for (let key in update_clientcreate_form) {
            let form_element = update_clientcreate_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_clientcreate_form[key] = form_element;
        }
        this.setState({ clientcreate_form: update_clientcreate_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.clientcreate_form) {
                form_data[key] = this.state.clientcreate_form[key].value;
            }
            this.props.onCreateClient(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/clients",
                        });
                    }, 1000);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    render() {
        const { clientcreate_form } = this.state;
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Client management" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="row">
                                            <form className="form w-100" onSubmit={this.SubmitHandler} autoComplete="off">
                                                <div className="card mb-5 mb-xl-8">
                                                    <div className="card-header border-0 pt-5 mb-4">
                                                        <div className="d-flex flex-wrap flex-sm-nowrap">
                                                            <h3 className="card-title align-items-start flex-column">
                                                                <span className="card-label fw-bolder fs-2x mb-1 color1">Client information</span>
                                                                <span className="mt-1 fw-normal text-dark-muted fs-7 text-gray-new">Enter your client details.</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-0">
                                                        <div className="row mb-4">
                                                            <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!clientcreate_form.client_name.valid && clientcreate_form.client_name.onBlur_out ? " error-field" : "")}>
                                                                <label className="form-label fs-6">{clientcreate_form.client_name.label}</label>
                                                                <input
                                                                    autoComplete="new_client_name"
                                                                    type={clientcreate_form.client_name.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={clientcreate_form.client_name.placeholder}
                                                                    value={clientcreate_form.client_name.value ? clientcreate_form.client_name.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "client_name")}
                                                                    onBlur={(event) => {
                                                                        clientcreate_form.client_name.onBlur_out = true;
                                                                        this.setState({
                                                                            clientcreate_form: clientcreate_form,
                                                                        });
                                                                    }}
                                                                />
                                                                {!clientcreate_form.client_name.valid && clientcreate_form.client_name.onBlur_out ? <div className="error field-error">{clientcreate_form.client_name.error_msg}</div> : ""}
                                                            </div>
                                                            <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!clientcreate_form.abn.valid && clientcreate_form.abn.onBlur_out ? " error-field" : "")}>
                                                                <label className="form-label fs-6">{clientcreate_form.abn.label}</label>
                                                                <input
                                                                    autoComplete="new_client_abn"
                                                                    type={clientcreate_form.abn.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={clientcreate_form.abn.placeholder}
                                                                    value={clientcreate_form.abn.value ? clientcreate_form.abn.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "abn")}
                                                                    onBlur={(event) => {
                                                                        clientcreate_form.abn.onBlur_out = true;
                                                                        this.setState({
                                                                            clientcreate_form: clientcreate_form,
                                                                        });
                                                                    }}
                                                                />
                                                                {!clientcreate_form.abn.valid && clientcreate_form.abn.onBlur_out ? <div className="error field-error">{clientcreate_form.abn.error_msg}</div> : ""}
                                                            </div>

                                                            <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!clientcreate_form.client_email.valid && clientcreate_form.client_email.onBlur_out ? " error-field" : "")}>
                                                                <label className="form-label fs-6">{clientcreate_form.client_email.label}</label>
                                                                <input
                                                                    autoComplete="new_client_email"
                                                                    type={clientcreate_form.client_email.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={clientcreate_form.client_email.placeholder}
                                                                    value={clientcreate_form.client_email.value ? clientcreate_form.client_email.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "client_email")}
                                                                    onBlur={(event) => {
                                                                        clientcreate_form.client_email.onBlur_out = true;
                                                                        this.setState({
                                                                            clientcreate_form: clientcreate_form,
                                                                        });
                                                                    }}
                                                                />
                                                                {!clientcreate_form.client_email.valid && clientcreate_form.client_email.onBlur_out ? <div className="error field-error">{clientcreate_form.client_email.error_msg}</div> : ""}
                                                            </div>
                                                            <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!clientcreate_form.client_number.valid && clientcreate_form.client_number.onBlur_out ? " error-field" : "")}>
                                                                <label className="form-label fs-6">{clientcreate_form.client_number.label}</label>
                                                                <input
                                                                    autoComplete="new_client_number"
                                                                    type={clientcreate_form.client_number.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={clientcreate_form.client_number.placeholder}
                                                                    value={clientcreate_form.client_number.value ? clientcreate_form.client_number.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "client_number")}
                                                                    onBlur={(event) => {
                                                                        clientcreate_form.client_number.onBlur_out = true;
                                                                        this.setState({
                                                                            clientcreate_form: clientcreate_form,
                                                                        });
                                                                    }}
                                                                />
                                                                {!clientcreate_form.client_number.valid && clientcreate_form.client_number.onBlur_out ? (
                                                                    <div className="error field-error">{clientcreate_form.client_number.error_msg}</div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className={"col-lg-12 mb-4 fv-row" + (!clientcreate_form.address.valid && clientcreate_form.address.onBlur_out ? " error-field" : "")}>
                                                                <label className="form-label fs-6">{clientcreate_form.address.label}</label>
                                                                <input
                                                                    autoComplete="new_client_address"
                                                                    type={clientcreate_form.address.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={clientcreate_form.address.placeholder}
                                                                    value={clientcreate_form.address.value ? clientcreate_form.address.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "address")}
                                                                    onBlur={(event) => {
                                                                        clientcreate_form.address.onBlur_out = true;
                                                                        this.setState({
                                                                            clientcreate_form: clientcreate_form,
                                                                        });
                                                                    }}
                                                                />
                                                                {!clientcreate_form.address.valid && clientcreate_form.address.onBlur_out ? <div className="error field-error">{clientcreate_form.address.error_msg}</div> : ""}
                                                            </div>

                                                            <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!clientcreate_form.zipcode.valid && clientcreate_form.zipcode.onBlur_out ? " error-field" : "")}>
                                                                <label className="form-label fs-6">{clientcreate_form.zipcode.label}</label>
                                                                <input
                                                                    autoComplete="new_client_zipcode"
                                                                    type={clientcreate_form.zipcode.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={clientcreate_form.zipcode.placeholder}
                                                                    value={clientcreate_form.zipcode.value ? clientcreate_form.zipcode.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "zipcode")}
                                                                    onBlur={(event) => {
                                                                        clientcreate_form.zipcode.onBlur_out = true;
                                                                        this.setState({
                                                                            clientcreate_form: clientcreate_form,
                                                                        });
                                                                    }}
                                                                />
                                                                {!clientcreate_form.zipcode.valid && clientcreate_form.zipcode.onBlur_out ? <div className="error field-error">{clientcreate_form.zipcode.error_msg}</div> : ""}
                                                            </div>
                                                            <div className="col-sm-6 col-lg-6 mb-4 fv-row">
                                                                <label className="form-label fs-6">{clientcreate_form.state.label}</label>
                                                                <select className="form-select form-select-solid" value={clientcreate_form.state.value} onChange={(event) => this.inputChangeHandler(event, "state")}>
                                                                    {clientcreate_form.state.options.map((option) => (
                                                                        <option value={option.value} key={option.opk}>
                                                                            {option.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {!clientcreate_form.state.valid && clientcreate_form.state.onBlur_out ? <div className="error field-error">{clientcreate_form.state.error_msg}</div> : ""}
                                                            </div>
                                                        </div>

                                                        <button type="submit" className="btn btn-primary rounded-50 w-225px client-btn hvr-grow">
                                                            Save changes
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onCreateClient: userActions.CreateClient,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientCreate);