import React from "react";

import moment from "moment";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { Chart } from "react-google-charts";
import DateRangePicker from "react-bootstrap-daterangepicker";

import ReportingStatus from "./ReportingStatus";
import HtmlExportToPdf from "./HtmlExportToPdf";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

class ReportingDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,

            total_bids: 0,
            total_bidsvalue: 0,
            total_bidsshow: true,

            total_won: 0,
            total_wonvalue: 0,
            total_wonshow: false,

            total_progress: 0,
            total_progressvalue: 0,
            total_progressshow: false,

            total_withdrawn: 0,
            total_withdrawnvalue: 0,
            total_withdrawnshow: false,

            total_lost: 0,
            total_lostvalue: 0,
            total_lostshow: false,

            reporting_reponse: [],
            reporting_chartdata: [["Year", "Sales", "Expenses"]],
            reporting_chartcolor: ["#191964", "#3DA153", "#967dfa", "#6496FA", "#FA6464"],

            report_type: "total",
            // report_type: "value",
            daterangepicker_label: "All Time",
            startDate: moment().subtract(2, "days"),
            endDate: moment(),
        };
    }

    async componentDidMount() {
        await this.GetReporting();
    }

    GetReporting = async () => {
        let report_type = this.state.report_type;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;

        let daterangepicker_label = this.state.daterangepicker_label;
        let start_date = daterangepicker_label !== "All Time" ? startDate.format() : "";
        let end_date = daterangepicker_label !== "All Time" ? endDate.format() : "";

        this.props.onGetReporting(report_type, start_date, end_date).then((response) => {
            if (response.success === true) {
                this.setState({ reporting_reponse: response.data }, function () {
                    this.GenerateReportingChart();
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GenerateReportingChart = () => {
        let report_type = this.state.report_type;
        let reporting_reponse = this.state.reporting_reponse;
        let daterangepicker_label = this.state.daterangepicker_label;

        let total_bids = 0;
        let total_won = 0;
        let total_progress = 0;
        let total_withdrawn = 0;
        let total_lost = 0;

        let total_bidsvalue = 0,
            total_wonvalue = 0,
            total_progressvalue = 0,
            total_withdrawnvalue = 0,
            total_lostvalue = 0;

        let reporting_chartdata = [];
        let reporting_chartcolor = [];

        if (daterangepicker_label === "All Time") {
            reporting_chartdata[0] = ["Year"];

            let reportingchartkey = 1;
            if (this.state.total_bidsshow) {
                reporting_chartcolor.push("#191964");
                reporting_chartdata[0][reportingchartkey] = "All Bids";
                reportingchartkey++;
            }
            if (this.state.total_wonshow) {
                reporting_chartcolor.push("#3DA153");
                reporting_chartdata[0][reportingchartkey] = "Won";
                reportingchartkey++;
            }
            if (this.state.total_progressshow) {
                reporting_chartcolor.push("#967dfa");
                reporting_chartdata[0][reportingchartkey] = "Working";
                reportingchartkey++;
            }
            if (this.state.total_withdrawnshow) {
                reporting_chartcolor.push("#6496FA");
                reporting_chartdata[0][reportingchartkey] = "Withdrawn";
                reportingchartkey++;
            }
            if (this.state.total_lostshow) {
                reporting_chartcolor.push("#FA6464");
                reporting_chartdata[0][reportingchartkey] = "Lost";
                reportingchartkey++;
            }

            let graphdata_key = 1;
            for (let report_key in reporting_reponse) {
                let report = reporting_reponse[report_key];

                total_bids += report.bids;
                total_won += report.won;
                total_progress += report.progress;
                total_withdrawn += report.withdrawn;
                total_lost += report.lost;

                total_bidsvalue = parseInt(total_bidsvalue) + parseInt(report.bidsvalue);
                total_wonvalue = parseInt(total_wonvalue) + parseInt(report.wonvalue);
                total_progressvalue = parseInt(total_progressvalue) + parseInt(report.progressvalue);
                total_withdrawnvalue = parseInt(total_withdrawnvalue) + parseInt(report.withdrawnvalue);
                total_lostvalue = parseInt(total_lostvalue) + parseInt(report.lostvalue);

                let record_year = report.year;
                let chartdata = ["" + record_year + ""];
                if (this.state.total_bidsshow) {
                    if (report_type === "total") {
                        chartdata.push(parseInt(report.bids));
                    } else {
                        chartdata.push(parseInt(report.bidsvalue));
                    }
                }
                if (this.state.total_wonshow) {
                    if (report_type === "total") {
                        chartdata.push(parseInt(report.won));
                    } else {
                        chartdata.push(parseInt(report.wonvalue));
                    }
                }
                if (this.state.total_progressshow) {
                    if (report_type === "total") {
                        chartdata.push(parseInt(report.progress));
                    } else {
                        chartdata.push(parseInt(report.progressvalue));
                    }
                }
                if (this.state.total_withdrawnshow) {
                    if (report_type === "total") {
                        chartdata.push(parseInt(report.withdrawn));
                    } else {
                        chartdata.push(parseInt(report.withdrawnvalue));
                    }
                }
                if (this.state.total_lostshow) {
                    if (report_type === "total") {
                        chartdata.push(parseInt(report.lost));
                    } else {
                        chartdata.push(parseInt(report.lostvalue));
                    }
                }
                if (this.state.total_bidsshow || this.state.total_wonshow || this.state.total_progressshow || this.state.total_withdrawnshow || this.state.total_lostshow) {
                    reporting_chartdata[graphdata_key] = chartdata;
                }

                graphdata_key++;
            }
        } else {
            reporting_chartdata[0] = ["Month"];

            let reportingchartkey = 1;
            if (this.state.total_bidsshow) {
                reporting_chartcolor.push("#191964");
                reporting_chartdata[0][reportingchartkey] = "All Bids";
                reportingchartkey++;
            }
            if (this.state.total_wonshow) {
                reporting_chartcolor.push("#3DA153");
                reporting_chartdata[0][reportingchartkey] = "Won";
                reportingchartkey++;
            }
            if (this.state.total_progressshow) {
                reporting_chartcolor.push("#967dfa");
                reporting_chartdata[0][reportingchartkey] = "Working";
                reportingchartkey++;
            }
            if (this.state.total_withdrawnshow) {
                reporting_chartcolor.push("#6496FA");
                reporting_chartdata[0][reportingchartkey] = "Withdrawn";
                reportingchartkey++;
            }
            if (this.state.total_lostshow) {
                reporting_chartcolor.push("#FA6464");
                reporting_chartdata[0][reportingchartkey] = "Lost";
                reportingchartkey++;
            }

            let reportfilters = [];
            for (let report_key in reporting_reponse) {
                let report = reporting_reponse[report_key];
                let record_month = moment(report.date).format("MMM");
                if (typeof reportfilters[record_month] === "undefined") {
                    reportfilters[record_month] = [];
                }
                reportfilters[record_month].push(report);
            }

            if (reportfilters) {
                let graphdata_key = 1;
                for (let reportfilters_key in reportfilters) {
                    let reportfilter = reportfilters[reportfilters_key];
                    let chartdata = ["" + reportfilters_key + ""];
                    let bidsvalue = 0,
                        wonvalue = 0,
                        progressvalue = 0,
                        withdrawnvalue = 0,
                        lostvalue = 0;

                    for (let reportfilter_key in reportfilter) {
                        let monthly_record = reportfilter[reportfilter_key];
                        total_bids += monthly_record.bids;
                        total_won += monthly_record.won;
                        total_progress += monthly_record.progress;
                        total_withdrawn += monthly_record.withdrawn;
                        total_lost += monthly_record.lost;

                        bidsvalue += monthly_record.bidsvalue;
                        wonvalue += monthly_record.wonvalue;
                        progressvalue += monthly_record.progressvalue;
                        withdrawnvalue += monthly_record.withdrawnvalue;
                        lostvalue += monthly_record.lostvalue;
                    }

                    total_bidsvalue = parseInt(total_bidsvalue) + parseInt(bidsvalue);
                    total_wonvalue = parseInt(total_wonvalue) + parseInt(wonvalue);
                    total_progressvalue = parseInt(total_progressvalue) + parseInt(progressvalue);
                    total_withdrawnvalue = parseInt(total_withdrawnvalue) + parseInt(withdrawnvalue);
                    total_lostvalue = parseInt(total_lostvalue) + parseInt(lostvalue);

                    if (this.state.total_bidsshow) {
                        if (report_type === "total") {
                            chartdata.push(parseInt(total_bids));
                        } else {
                            chartdata.push(parseInt(bidsvalue));
                        }
                    }
                    if (this.state.total_wonshow) {
                        if (report_type === "total") {
                            chartdata.push(parseInt(total_won));
                        } else {
                            chartdata.push(parseInt(wonvalue));
                        }
                    }
                    if (this.state.total_progressshow) {
                        if (report_type === "total") {
                            chartdata.push(parseInt(total_progress));
                        } else {
                            chartdata.push(parseInt(progressvalue));
                        }
                    }
                    if (this.state.total_withdrawnshow) {
                        if (report_type === "total") {
                            chartdata.push(parseInt(total_withdrawn));
                        } else {
                            chartdata.push(parseInt(withdrawnvalue));
                        }
                    }
                    if (this.state.total_lostshow) {
                        if (report_type === "total") {
                            chartdata.push(parseInt(total_lost));
                        } else {
                            chartdata.push(parseInt(lostvalue));
                        }
                    }
                    if (this.state.total_bidsshow || this.state.total_wonshow || this.state.total_progressshow || this.state.total_withdrawnshow || this.state.total_lostshow) {
                        reporting_chartdata[graphdata_key] = chartdata;
                    }
                    graphdata_key++;
                }
            }
        }

        this.setState({
            total_bids: total_bids,
            total_won: total_won,
            total_progress: total_progress,
            total_withdrawn: total_withdrawn,
            total_lost: total_lost,

            total_bidsvalue: total_bidsvalue,
            total_wonvalue: total_wonvalue,
            total_progressvalue: total_progressvalue,
            total_withdrawnvalue: total_withdrawnvalue,
            total_lostvalue: total_lostvalue,

            reporting_chartdata: reporting_chartdata,
            reporting_chartcolor: reporting_chartcolor,
        });
    };

    handleCallback = (start_date, end_date, label) => {
        if (label === "Custom Range") {
            label = start_date.format("Do MMMM YYYY") + " , " + end_date.format("Do MMMM YYYY");
        }

        this.setState(
            {
                daterangepicker_label: label,
                startDate: start_date,
                endDate: end_date,
            },
            function () {
                this.GetReporting();
            }
        );
    };

    render() {
        const { loader, report_type, startDate, endDate, daterangepicker_label, reporting_chartdata, reporting_chartcolor } = this.state;
        return (
            <React.Fragment>
                {loader ? <Loader /> : null}
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <LoginHeader page_title="Reporting" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="row gy-5 g-xl-8">
                                            <div className="col-lg-12 p-0">
                                                <div className="card mb-4 mb-xl-8 bg-transparent">
                                                    <div className="card-header p-0 pb-4 pt-4 border-0">
                                                        <div className="card-title align-items-center m-0 calender-time-out">
                                                            <span className="card-label fw-bold-600 fs-7 color1">Showing data for:</span>
                                                            <DateRangePicker
                                                                initialSettings={{
                                                                    startDate: startDate ? startDate.toDate() : "",
                                                                    endDate: endDate ? endDate.toDate() : "",
                                                                    ranges: {
                                                                        Today: [moment().toDate(), moment().toDate()],
                                                                        "Last 7 days": [moment().subtract(6, "days").toDate(), moment().toDate()],
                                                                        "Last 30 days": [moment().subtract(29, "days").toDate(), moment().toDate()],
                                                                        "Last week": [moment().subtract(1, "weeks").startOf("isoWeek").toDate(), moment().subtract(1, "weeks").endOf("week").toDate()],
                                                                        "Last month": [moment().subtract(1, "month").startOf("month").toDate(), moment().subtract(1, "month").endOf("month").toDate()],
                                                                    },
                                                                }}
                                                                onCallback={this.handleCallback}
                                                            >
                                                                <span className="fw-bold-600 fs-7 data-date d-flex align-items-center text-purple-dark me-5 curser_pointer">
                                                                    <i className="icon-calendar fw-bold-600 fs-7  me-2 text-purple-dark"></i>
                                                                    {daterangepicker_label}
                                                                </span>
                                                            </DateRangePicker>

                                                            <div className="bg-white p-1 rounded-50 choose-value">
                                                                <button
                                                                    className={"rounded-50 btn btn-sm cursor-pointer px-6 me-1" + (report_type === "total" ? " btn-primary" : "")}
                                                                    onClick={(event) => {
                                                                        this.setState(
                                                                            {
                                                                                report_type: "total",
                                                                            },
                                                                            function () {
                                                                                this.GetReporting();
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    Total
                                                                </button>
                                                                <button
                                                                    className={"rounded-50 btn btn-sm cursor-pointer px-6" + (report_type === "value" ? " btn-primary" : "")}
                                                                    onClick={(event) => {
                                                                        this.setState(
                                                                            {
                                                                                report_type: "value",
                                                                            },
                                                                            function () {
                                                                                this.GetReporting();
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    Value
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="card-toolbar m-0">
                                                            <button
                                                                className="rounded-50 btn btn-dark btn-sm hvr-grow cursor-pointer"
                                                                onClick={(event) => HtmlExportToPdf(this.dashboard_welcome_ref, "Reporting-" + moment().format("Do MMMM YYYY"), { startDate, endDate, daterangepicker_label })}
                                                            >
                                                                Export as PDF <i className="icon-download fs-8 "></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row gy-5 g-xl-8" ref={(node) => (this.dashboard_welcome_ref = node)}>
                                            <ReportingStatus
                                                report_type={this.state.report_type}
                                                total_bids={this.state.total_bids}
                                                total_bidsvalue={this.state.total_bidsvalue}
                                                total_bidsshow={this.state.total_bidsshow}
                                                propstotal_bidsshow={(propsaction) => {
                                                    this.setState(
                                                        {
                                                            total_bidsshow: propsaction,
                                                        },
                                                        function () {
                                                            this.GenerateReportingChart();
                                                        }
                                                    );
                                                }}
                                                total_won={this.state.total_won}
                                                total_wonvalue={this.state.total_wonvalue}
                                                total_wonshow={this.state.total_wonshow}
                                                propstotal_wonshow={(propsaction) => {
                                                    this.setState(
                                                        {
                                                            total_wonshow: propsaction,
                                                        },
                                                        function () {
                                                            this.GenerateReportingChart();
                                                        }
                                                    );
                                                }}
                                                total_progress={this.state.total_progress}
                                                total_progressvalue={this.state.total_progressvalue}
                                                total_progressshow={this.state.total_progressshow}
                                                propstotal_progressshow={(propsaction) => {
                                                    this.setState(
                                                        {
                                                            total_progressshow: propsaction,
                                                        },
                                                        function () {
                                                            this.GenerateReportingChart();
                                                        }
                                                    );
                                                }}
                                                total_withdrawn={this.state.total_withdrawn}
                                                total_withdrawnvalue={this.state.total_withdrawnvalue}
                                                total_withdrawnshow={this.state.total_withdrawnshow}
                                                propstotal_withdrawnshow={(propsaction) => {
                                                    this.setState(
                                                        {
                                                            total_withdrawnshow: propsaction,
                                                        },
                                                        function () {
                                                            this.GenerateReportingChart();
                                                        }
                                                    );
                                                }}
                                                total_lost={this.state.total_lost}
                                                total_lostvalue={this.state.total_lostvalue}
                                                total_lostshow={this.state.total_lostshow}
                                                propstotal_lostshow={(propsaction) => {
                                                    this.setState(
                                                        {
                                                            total_lostshow: propsaction,
                                                        },
                                                        function () {
                                                            this.GenerateReportingChart();
                                                        }
                                                    );
                                                }}
                                            />
                                            <div className="card card-xl-stretch">
                                                <div className="card-header align-items-center border-0 mt-4">
                                                    <h4 className="card-title align-items-start flex-column m-0">
                                                        <span className="fw-bold-700 color1 fs-5">Your bid data {report_type === "value" ? "($)" : ""}</span>
                                                        <span className="fw-normal fs-base text-grey-dark">Track your progress in the graph 
														below.</span>
                                                    </h4>
                                                </div>
                                                <div className="card-body pt-0">
                                                    <div className="d-flex mb-4">
                                                        <div className="mt-4 graph-image min-w-100">
                                                            {reporting_chartdata.length > 1 ? (
                                                                <Chart
                                                                    chartType="AreaChart"
                                                                    width={"100%"}
                                                                    height={"300px"}
                                                                    data={reporting_chartdata}
                                                                    options={{
                                                                        pointSize: 5,
                                                                        legend: {
                                                                            position: "none",
                                                                        },
                                                                        colors: reporting_chartcolor,
                                                                        vAxis: {
                                                                            format: "short",
                                                                        },
                                                                    }}
                                                                />
                                                            ) : (
                                                                <p> No records found</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Reporting.error,
        loader: state.Reporting.loader,
        isAuthenticated: state.Reporting.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetReporting: userActions.GetReporting,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportingDashboard);