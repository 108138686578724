import React from "react";
import { Link } from "react-router-dom";

import HeaderNotifications from "./Notification/HeaderNotifications";
import HeaderNotificationsSidebar from "./Notification/HeaderNotificationsSidebar";
class LoginHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            header_notifications_sidebar: false,
        };
    }

    render() {
        const { header_notifications_sidebar } = this.state;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                <div id="kt_header" className="header align-items-center ">
                    <div className="container-fluid d-flex justify-content-between">
                        <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
                            <div className="btn btn-icon w-30px h-30px w-md-40px h-md-40px" id="kt_aside_mobile_toggle">
                                <span id="toggle-btn" className="svg-icon svg-icon-2x mt-1 hamburger-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                                        <path
                                            opacity="0.3"
                                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                            fill="black"
                                        />
                                    </svg>
                                    <i className="icon-cross-2 toggle-cross"></i>
                                </span>
                            </div>
                        </div>
                        <div className="d-flex flex-grow-1 flex-lg-grow-0" id="header_logo">
                            <Link to="/dashboard" className="d-lg-none logo-mob">
                                <img alt="Logo" src="assets/images/logo.svg" className="" />
                            </Link>
                        </div>
                        <div className="d-flex flex-stack flex-lg-grow-1" id="header_nav_bar">
                            <div className="d-flex" id="kt_header_nav">
                                <div
                                    data-kt-swapper="true"
                                    data-kt-swapper-mode="prepend"
                                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_header_nav'}"
                                    className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                                >
                                    <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">{this.props.page_title ? this.props.page_title : "Dashboard"}</h1>
                                </div>
                            </div>
                            <div className="d-flex align-items-stretch flex-shrink-0">
                                <div className="d-flex align-items-stretch flex-shrink-0 header-right">
                                    {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Contributor") && (
                                        <div className="d-flex align-items-center ms-1 me-lg-2" id="kt_header_user_menu_toggle">
                                            <div className="dropdown">
                                                <div className="cursor-pointer drop-icon-change align-items-center" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <div className="d-md-flex flex-column justify-content-center ms-2">
                                                        <span className="head-btn-bg">
                                                            <i className="fas fa-plus"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column  menu-gray-800 menu-state-bg menu-state-primary fw-bold px-2 py-2 fs-6 w-225px" aria-labelledby="dropdownMenuButton1">
                                                    {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                        <div className="menu-item">
                                                            <Link to="/bid-create" className="menu-link py-4">
                                                                <span className="me-3 icon-info1"></span>
                                                                Create new bid
                                                            </Link>
                                                        </div>
                                                    )}
                                                    {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                        <div className="menu-item ">
                                                            <Link to="/client-create" className="menu-link py-4">
                                                                <i className="icon-clients me-3"></i>
                                                                Add new client
                                                            </Link>
                                                        </div>
                                                    )}
                                                    {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Contributor") && (
                                                        <div className="menu-item">
                                                            <Link to="/library" className="menu-link py-4">
                                                                <i className="icon-folder-plus1 me-3"></i>
                                                                Create new content
                                                            </Link>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Contributor" || user_info.user.role.user_role === "Guest") && (
                                        <HeaderNotifications
                                            HeaderNotificationsSidebarAction={(event, action) => {
                                                this.setState({
                                                    header_notifications_sidebar: action,
                                                });
                                            }}
                                        />
                                    )}

                                    {header_notifications_sidebar && (
                                        <HeaderNotificationsSidebar
                                            HeaderNotificationsSidebarAction={(event, action) => {
                                                this.setState({
                                                    header_notifications_sidebar: action,
                                                });
                                            }}
                                        />
                                    )}

                                    <div className="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                                        <div className="dropdown">
                                            <div className="drop-hover cursor-pointer symbol align-items-center symbol-30px symbol-md-40px ps-5 border-start" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img title="user" alt="user" src={user_info.user.avatar ? user_info.user.avatar : "assets/images/profile_dummy.jpg"} />
                                                <div className="d-none d-md-flex flex-column justify-content-center ms-2">
                                                    <span className="text-dark fs-7 fw-bold-700 lh-1 mb-2">{user_info?.company?.company_name}</span>
                                                    <span className="text-dark fs-8 fw-bold lh-1 ">
                                                        {user_info.user.first_name} {user_info.user.last_name}
                                                    </span>
                                                </div>
                                                <span className="menu-arrow ms-4">
                                                    <img className="single-arrow" src="assets/images/icons/down-arrow.svg" title="down-arrow" alt="down-arrow" width="12" height="7" />
                                                    <img className="double-arrow" src="assets/images/icons/arrow-icons.svg" title="down-arrow" alt="down-arrow" width="12" height="12" />
                                                </span>
                                            </div>
                                            <div className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column  menu-gray-800 menu-state-bg menu-state-primary fw-bold px-2 py-2 fs-6 w-225px" aria-labelledby="dropdownMenuButton1">
                                                <div className="menu-item">
                                                    <Link to="/settings-myprofile" className="menu-link py-4">
                                                        <span className="me-3">
                                                            <img src="assets/images/icons/setting.svg" title="setting" alt="setting" />
                                                        </span>
                                                        Settings
                                                    </Link>
                                                </div>
                                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                    <div className="menu-item">
                                                        <Link to="/settings-users" className="menu-link py-4">
                                                            <span className="me-3">
                                                                <img src="assets/images/icons/user.svg" title="user" alt="user" />
                                                            </span>
                                                            Invite users
                                                        </Link>
                                                    </div>
                                                )}
                                                {user_info.user.role.user_role === "Account owner" && (
                                                    <div className="menu-item">
                                                        <Link to="/settings-billing" className="menu-link py-4">
                                                            <span className="me-3">
                                                                <img src="assets/images/icons/price.svg" title="price" alt="price" />
                                                            </span>
                                                            Pricing
                                                        </Link>
                                                    </div>
                                                )}
                                                <div className="menu-item">
                                                    <Link to="/logout" className="menu-link py-4">
                                                        <span className="me-3">
                                                            <img src="assets/images/navigation-icon/logout-dark.svg" title="logout" alt="logout" />
                                                        </span>
                                                        Logout
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default LoginHeader;