import * as actionType from "../constants/actionTypes";

const initial_state = {
    error: null,
    token: null,
    loader: false,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case actionType.SignIn_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.SignIn_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.SignIn_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case actionType.SignInVerify_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.SignInVerify_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.SignInVerify_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case actionType.ResendToken_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.ResendToken_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.ResendToken_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case actionType.getAuthUser_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.getAuthUser_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.getAuthUser_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case actionType.SignUp_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.SignUp_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.SignUp_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case actionType.SignUpCompany_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.SignUpCompany_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.SignUpCompany_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case actionType.SocialSignUp_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.SocialSignUp_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.SocialSignUp_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case actionType.SocialSignIn_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.SocialSignIn_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.SocialSignIn_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case actionType.ForgotPassword_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.ForgotPassword_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.ForgotPassword_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case actionType.ResetPassword_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.ResetPassword_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.ResetPassword_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        case actionType.ResentForgotOTP_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.ResentForgotOTP_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.ResentForgotOTP_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

        default:
            return state;
    }
};

export default reducer;