import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

class CompleteBidCongratsModal extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    className="completebid_congrats_modal bid-custom-modal "
                    show={this.props.completebid_congrats_modal}
                    onHide={() => {
                        this.props.completebid_congrats_modal_action(false);
                    }}
                >
                    <Modal.Body>
                        <div className="text-center m-0 pt-4">
                            <img className="mb-7 mt-4" src="assets/images/icons/congrats.svg" alt="congrats" />
                            <h2 className="text-dark fw-bolder fs-22px">Congrats on completing a bid!</h2>
                            <p className="mb-5 fs-6">Would you like to mark this bid as Submitted before returning to Dashboard?</p>
                        </div>
                        <div className="modal-footer border-0 justify-content-center pt-0">
                            <Link to="/dashboard" className="btn cancel-butn rounded-50 ">
                                No, go to dashboard
                            </Link>
                            <span className="btn btn-danger remove-user rounded-50 w-200px hvr-grow" onClick={(event) => this.props.MarkAsSubmitted(event)}>
                                Yes, mark as submitted
                            </span>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default CompleteBidCongratsModal;