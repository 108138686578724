import React from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";

import Validations from "../../../components/Utility/Validations";
class AddCardModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addcard_form: {
                cardnumber: {
                    value: "",
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "Credit Card Number",
                    placeholder: "0000 0000 0000 0000",
                    validations: { required: true, CreditCardNumber: true },
                },
                expiry_date: {
                    value: "",
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "Expiry Month",
                    placeholder: "MM/YY",
                    validations: { required: true, CreditCardExpiry: true, min_length: 5 },
                },
                cvc: {
                    value: "",
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "CVV",
                    placeholder: "•••",
                    validations: { required: true, CreditCardCVV: true },
                },
                card_holdername: {
                    value: "",
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "Cardholder name",
                    placeholder: "Enter cardholder’s full name as appear on card",
                    validations: { required: true },
                },
            },
        };
    }

    inputChangeHandler(event, identifier) {
        event.preventDefault();

        const update_addcard_form = { ...this.state.addcard_form };
        const form_element = { ...update_addcard_form[identifier] };

        if (form_element.validations.CreditCardExpiry) {
            let prevValue = form_element.value;
            let currentValue = event.target.value;
            let textTemp = currentValue;
            let current_year = moment().format("YY");

            if (textTemp[0] !== "0" && textTemp[0] !== "1" && textTemp[0] !== "2") {
                textTemp = "";
            } else if (prevValue?.length === 3 && currentValue.length === prevValue.length - 1) {
                textTemp = textTemp?.slice(0, -1);
            } else if (textTemp.length === 4 && textTemp[3] < current_year[0]) {
                textTemp = textTemp?.slice(0, -1);
            } else if (textTemp.length === 5 && textTemp[4] < current_year[1]) {
                textTemp = textTemp?.slice(0, -1);
            } else if (textTemp.length === 6) {
                textTemp = textTemp?.slice(0, -1);
            } else if (textTemp.length === 2) {
                if (parseInt(textTemp?.substring(0, 2)) > 12 || parseInt(textTemp?.substring(0, 2)) === 0) {
                    textTemp = textTemp?.slice(0, -1);
                } else if (textTemp?.length === 2) {
                    textTemp += "/";
                } else {
                    textTemp = textTemp[0];
                }
            }
            form_element.value = textTemp;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_addcard_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_addcard_form) {
            is_form_valid = update_addcard_form[identifier].valid && is_form_valid;
        }

        this.setState({
            addcard_form: update_addcard_form,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_addcard_form = this.state.addcard_form;
        for (let key in update_addcard_form) {
            let form_element = update_addcard_form[key];

            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_addcard_form[key] = form_element;
        }
        this.setState({
            addcard_form: update_addcard_form,
        });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.addcard_form) {
                form_data[key] = this.state.addcard_form[key].value;
            }
            this.props.AddCardProps(form_data);
        }
    };

    render() {
        const { addcard_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    className="addcard_modal bid-custom-modal "
                    show={this.props.addcard_modal}
                    onHide={() => {
                        this.props.addcard_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Card</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="d-flex flex-column">
                                <div className="row divide-colum">
                                    <div className="col-lg-12 right-colum pt-5">
                                        <div className={"d-flex flex-column mb-7 fv-row" + (!addcard_form.cardnumber.valid && addcard_form.cardnumber.onBlur_out ? " error-field" : "")}>
                                            <label className="d-flex align-items-center fs-7 fw-bold-500 form-label mb-2">{addcard_form.cardnumber.label}</label>
                                            <div className="position-relative">
                                                <input
                                                    type={addcard_form.cardnumber.type}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={addcard_form.cardnumber.placeholder}
                                                    value={addcard_form.cardnumber.value ? addcard_form.cardnumber.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "cardnumber")}
                                                    onBlur={(event) => {
                                                        addcard_form.cardnumber.onBlur_out = true;
                                                        this.setState({
                                                            addcard_form: addcard_form,
                                                        });
                                                    }}
                                                />
                                                <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                                                    <img src="assets/images/icons/card.svg" alt="" />
                                                </div>
                                            </div>
                                            {!addcard_form.cardnumber.valid && addcard_form.cardnumber.onBlur_out ? <div className="error field-error">{addcard_form.cardnumber.error_msg}</div> : ""}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className={"d-flex flex-column mb-7 fv-row" + (!addcard_form.expiry_date.valid && addcard_form.expiry_date.onBlur_out ? " error-field" : "")}>
                                                    <label className="d-flex align-items-center fs-7 fw-bold-500 form-label mb-2">{addcard_form.expiry_date.label}</label>
                                                    <div className="position-relative">
                                                        <input
                                                            type={addcard_form.expiry_date.type}
                                                            className="form-control form-control-lg form-control-solid"
                                                            placeholder={addcard_form.expiry_date.placeholder}
                                                            value={addcard_form.expiry_date.value ? addcard_form.expiry_date.value : ""}
                                                            onChange={(event) => this.inputChangeHandler(event, "expiry_date")}
                                                            onBlur={(event) => {
                                                                addcard_form.expiry_date.onBlur_out = true;
                                                                this.setState({
                                                                    addcard_form: addcard_form,
                                                                });
                                                            }}
                                                        />
                                                        <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                                                            <img src="assets/images/icons/card-date.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    {!addcard_form.expiry_date.valid && addcard_form.expiry_date.onBlur_out ? <div className="error field-error">{addcard_form.expiry_date.error_msg}</div> : ""}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className={"d-flex flex-column mb-7 fv-row" + (!addcard_form.cvc.valid && addcard_form.cvc.onBlur_out ? " error-field" : "")}>
                                                    <label className="d-flex align-items-center fs-7 fw-bold-500 form-label mb-2">{addcard_form.cvc.label}</label>
                                                    <div className="position-relative">
                                                        <input
                                                            type={addcard_form.cvc.type}
                                                            className="form-control form-control-lg form-control-solid"
                                                            placeholder={addcard_form.cvc.placeholder}
                                                            value={addcard_form.cvc.value ? addcard_form.cvc.value : ""}
                                                            onChange={(event) => this.inputChangeHandler(event, "cvc")}
                                                            onBlur={(event) => {
                                                                addcard_form.cvc.onBlur_out = true;
                                                                this.setState({
                                                                    addcard_form: addcard_form,
                                                                });
                                                            }}
                                                        />
                                                        <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                                                            <img src="assets/images/icons/cvc.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    {!addcard_form.cvc.valid && addcard_form.cvc.onBlur_out ? <div className="error field-error">{addcard_form.cvc.error_msg}</div> : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"d-flex flex-column mb-7 fv-row" + (!addcard_form.cvc.valid && addcard_form.cvc.onBlur_out ? " error-field" : "")}>
                                            <label className="d-flex align-items-center fs-7 fw-bold-500 form-label mb-2">{addcard_form.card_holdername.label}</label>
                                            <div className="position-relative">
                                                <input
                                                    type={addcard_form.card_holdername.type}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={addcard_form.card_holdername.placeholder}
                                                    value={addcard_form.card_holdername.value ? addcard_form.card_holdername.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "card_holdername")}
                                                    onBlur={(event) => {
                                                        addcard_form.card_holdername.onBlur_out = true;
                                                        this.setState({
                                                            addcard_form: addcard_form,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn cancel-butn rounded-50 w-150"
                                                onClick={() => {
                                                    this.props.addcard_modal_action(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn light-btn-info btn-info rounded-50 px-10 hvr-grow">
                                                Add Card
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddCardModal;