import React from "react";
import Notifications from "react-notify-toast";

import MyTasksList from "../../components/MyTasks/MyTasksList.js";
class MyTasks extends React.Component {
    render() {
        const renderview = () => {
            if (this.props.match.path === "/tasks") {
                return <MyTasksList />;
            }
        };
        return (
            <React.Fragment>
                <Notifications options={{ zIndex: 99999 }} />
                {renderview()}
            </React.Fragment>
        );
    }
}
export default MyTasks;