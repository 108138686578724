import html2canvas from "html2canvas";

const exportAsImage = async (element, imageFileName) => {
    const html = document.getElementsByTagName("html")[0];

    const body = document.getElementsByTagName("body")[0];

    let htmlWidth = html.clientWidth;

    let bodyWidth = body.clientWidth;

    const newWidth = element.scrollWidth - element.clientWidth;

    if (newWidth > element.clientWidth) {
        htmlWidth += newWidth;

        bodyWidth += newWidth;
    }

    html.style.width = htmlWidth + "px";

    body.style.width = bodyWidth + "px";

    const canvas = await html2canvas(element);

    const image = canvas.toDataURL("image/png", 1.0);

    downloadImage(image, imageFileName);

    html.style.width = null;

    body.style.width = null;
};

const downloadImage = (blob, fileName) => {
    var anchor = document.createElement("a");
    anchor.href = blob;
    anchor.download = fileName;

    var evt = document.createEvent("MouseEvents");
    evt.initMouseEvent("click", true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);

    anchor.dispatchEvent(evt);
};

export default exportAsImage;