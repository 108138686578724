import React from "react";

import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";

class DemoContentVideoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            demo_contentvideo: this.props.demo_contentvideo,
        };
    }
    render() {
        const { demo_contentvideo } = this.state;

        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    className="demo_contentvideo_modal bid-custom-modal "
                    show={this.props.demo_contentvideo_modal}
                    onHide={() => {
                        this.props.demo_contentvideo_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{demo_contentvideo?.bidtype_content?.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
							{demo_contentvideo?.bidtype_content?.demo_video &&(
								<ReactPlayer
									loop={true}
									playing={true}
									autoPlay={true}
									className="react-player"
									url={demo_contentvideo?.bidtype_content?.demo_video}
								/>
							)}
						</div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default DemoContentVideoModal;