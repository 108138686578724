import React from "react";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { Link } from "react-router-dom";

import DiscountCodesModal from "./Modal/DiscountCodesModal";
import SubscriptionsEditModal from "./Modal/SubscriptionsEditModal";

import LoginHeader from "../../../components/SuperAdminHeader";
import LoginFooter from "../../../components/SuperAdminFooter";
import LoginSidebar from "../../../components/SuperAdminSidebar";
import * as SuperAdminUserActions from "../../../actions/SuperAdmin/index";
class Pricing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect_url: "",
            isAuthenticated: false,

            subscriptions: [],
            discount_coupons: [],
            discountcodes_modal: false,
            subscriptionsedit_modal: false,
        };
    }

    componentDidMount() {
        this.GetSubscriptionPackages();
        this.GetDiscounts();
    }

    GetSubscriptionPackages = () => {
        this.props.onGetSubscriptionPackages().then((response) => {
            if (response.success === true) {
                this.setState({ subscriptions: response.data });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetDiscounts = () => {
        this.props.onGetDiscounts().then((response) => {
            if (response.success === true) {
                this.setState({ discount_coupons: response.data });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    render() {
        const { subscriptions, discount_coupons, subscriptionsedit_modal, discountcodes_modal } = this.state;
        return (
            <React.Fragment>
                {subscriptionsedit_modal && (
                    <SubscriptionsEditModal
                        subscriptionsedit_modal={subscriptionsedit_modal}
                        subscriptionsedit_modal_action={(modal_action) => {
                            this.setState({
                                subscriptionsedit_modal: modal_action,
                            });
                        }}
                    />
                )}

                {discountcodes_modal && (
                    <DiscountCodesModal
                        discountcodes_modal={this.state.discountcodes_modal}
                        discountcodes_modal_action={(modal_action) => {
                            this.setState({
                                discountcodes_modal: modal_action,
                            });
                        }}
                    />
                )}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header">
                            <LoginHeader page_title="Settings" />
                            <div className="content d-flex flex-column flex-column-fluid">
                                <div className="post d-flex flex-column-fluid">
                                    <div className="container-xxl ">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/superadmin/settings-myprofile" className="nav-link text-active-primary me-6">
                                                        Account settings
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/superadmin/settings-users" className="nav-link text-active-primary me-6">
                                                        Users
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/superadmin/settings-pricing" className="nav-link text-active-primary me-6 active">
                                                        Pricing
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-11 col-xl-10">
                                                <div className="card mb-5 mb-xl-6">
                                                    <div className="card-header border-0 pt-5 mb-3">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder fs-2x mb-1">Manage pricing tiers</span>
                                                        </h3>
                                                    </div>

                                                    <div className="card-body pt-0 pb-5 super-pricing">
                                                        {subscriptions.length !== 0 &&
                                                            subscriptions.map((subscription) => (
                                                                <div
                                                                    className={"d-flex align-items-center flex-wrap flex-sm-nowrap mb-3 plan-pro subscription_" + subscription.package_name.toLowerCase()}
                                                                    key={"subscription_" + subscription.subscription_package_id}
                                                                >
                                                                    <div className="me-5">
                                                                        <div className="symbol-100px symbol-fixed position-relative">
                                                                            <i className="icon-subscription fs-2tx"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex justify-content-between align-items-start flex-wrap">
                                                                            <div className="d-flex flex-column">
                                                                                <h4 className="text-main-color mb-1 fs-7 fw-bold-700">{subscription.package_name}</h4>
                                                                                <p className="text-gray-new mb-0 fs-8">14 days left. Your free trial ends on DD Month YYYY. </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="edit-btn">
                                                                        <span
                                                                            className="btn btn-sm btn-secondary rounded-50 hvr-grow"
                                                                            onClick={(event) => {
                                                                                this.setState({
                                                                                    subscriptionsedit_modal: true,
                                                                                });
                                                                            }}
                                                                        >
                                                                            Edit
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                                <div className="card mb-5 mb-xl-10">
                                                    <div className="card-header border-0 pt-5 mb-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder fs-2x mb-1">Discount codes</span>
                                                            <span className="text-gray-new fw-normal fs-7">Add and manage discount codes below</span>
                                                        </h3>
                                                        <div className="card-toolbar add-code-btn">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-dark p-0 fs-7"
                                                                onClick={(event) => {
                                                                    this.setState({
                                                                        discountcodes_modal: true,
                                                                    });
                                                                }}
                                                            >
                                                                Add code
                                                                <span>
                                                                    <img className="ms-1" src="assets/images/icons/add.svg" alt="" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {discount_coupons && (
                                                        <div className="card-body pt-0 pb-5">
                                                            {discount_coupons.map((discount_coupon, discount_coupon_key) => (
                                                                <div className="d-flex align-items-center flex-wrap flex-sm-nowrap mb-3 plan-pro" key={`discount_coupon_${discount_coupon_key}`}>
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex justify-content-between align-items-start flex-wrap">
                                                                            <div className="d-flex flex-column">
                                                                                <h4 className="mb-0 fw-bold color1 fs-7">{discount_coupon.code}%</h4>
                                                                                <p className="text-gray-new fs-8 mb-0 mt-1">Take {discount_coupon.percent}% off</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="edit-btn d-flex align-items-center">
                                                                        <h5 className="text-dark-blue-light mb-0 me-3 fs-7 fw-bold-700">Expires 20th June</h5>
                                                                        <span className="btn btn-sm btn-secondary rounded-50 hvr-grow">Edit</span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetSubscriptionPackages: SuperAdminUserActions.GetSubscriptionPackages,
    onGetDiscounts: SuperAdminUserActions.GetDiscounts,
};
export default connect(mapStateToProps, mapDispatchToProps)(Pricing);