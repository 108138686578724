import React, { useState } from "react";

import moment from "moment";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";

const CompanyUserInvitedItems = (props) => {
    const [columns] = useState([
        {
            hidden: true,
            text: "Invite Id",
            dataField: "invite_id",
            formatter: (cellContent, row, index, extraData) => {
                return row?.invite_id;
            },
        },
        {
            sort: false,
            text: "Email",
            dataField: "invite_email",
        },
        {
            sort: false,
            text: "Role",
            dataField: "user_role",
            formatter: (cellContent, row, index, extraData) => {
                return row?.role?.user_role;
            },
        },
        {
            sort: false,
            text: "Last active",
            dataField: "created_date",
            formatter: (cellContent, row, index, extraData) => {
                return moment(row?.created_date).fromNow();
            },
        },
        {
            sort: false,
            text: "",
            dataField: "invited_user_action",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="cursor-pointer" key={"invited_user_action_" + row.invite_id}>
                        <span className="btn" onClick={(event) => props.ReInviteUser(row)}>
                            <span className="svg-icon svg-icon-3">
                                <img src="assets/images/icons/tips.svg" title="edit" alt="edit" />
                            </span>
                        </span>
                    </div>
                );
            },
        },
    ]);

    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };

    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive ">
                        <BootstrapTable
                            remote
                            bootstrap4
                            striped
                            data={data}
                            keyField="user_id"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered table-row-bordered align-middle custom-table-style fs-8"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-end flex-wrap pt-3 pb-7 pagination-main">
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </>
            )}
        </PaginationProvider>
    );

    return (
        <React.Fragment>
            <div className="row align-items-center">
                <div className="col-md-4 company-items-label">
                    <div className="d-flex align-items-center position-relative search-bids">
                        <span className="svg-icon svg-icon-3 position-absolute me-2">
                            <img src="assets/images/icons/search.svg" title="Company user" alt="Company user" />
                        </span>
                        <input
                            type="text"
                            placeholder="Search for user..."
                            className="form-control form-control-sm ps-7"
                            onChange={(event) => {
                                props.propsfilterpage(1);
                                props.propsfiltersearch(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="d-flex align-items-center mt-8 justify-content-end flex-sm-wrap">
                        <span className="btn rounded-50 btn-white fw-bold-600 btn-sm me-4 text-center px-5 hvr-grow fs-7 text-dark mb-sm-4" onClick={(event) => props.ChangeUserListType("company_user")}>
                            View user
                        </span>
                        <Link to={`/superadmin/add-company-users/${props?.company_id}`} className="rounded-50 btn btn-danger btn-md hvr-grow-arrow companies-right mb-sm-2">
                            Add individual
                            <span className="svg-icon svg-icon-2 ">
                                <img src="assets/images/icons/white-add.svg" title="all_bids" alt="all_bids" />
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="card-body p-0 bg-white radious-style radious-users  mt-5 user-table-new">
                <RemotePagination
                    data={props.invited_users}
                    totalSize={props.invited_userscount}
                    columns={columns}
                    order={props.filterorder}
                    dataField={props.filtersort}
                    page={props.filterpage}
                    sizePerPage={props.filterlimit}
                    onTableChange={handleTableChange}
                />
            </div>
        </React.Fragment>
    );
};

export default CompanyUserInvitedItems;