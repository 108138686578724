import React from "react";
import { Modal } from "react-bootstrap";

class ConfirmDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ConfirmDialog_show: false,
        };
    }

    componentDidMount() {
        this.setState({ ConfirmDialog_show: this.props.confirm_modal });
    }

    ModalActionHide() {
        this.setState({ ConfirmDialog_show: false });
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    className="bid-custom-modal"
                    show={this.state.ConfirmDialog_show}
                    onHide={() => {
                        this.ModalActionHide();
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mb-6">{this.props.body}</p>

                        <Modal.Footer>
                            <button
                                type="button"
                                className="hover-new btn cancel-butn rounded-50 w-150 text-gray-new"
                                onClick={() => {
                                    this.ModalActionHide();
                                }}
                            >
                                {this.props.cancelButtonText ? this.props.cancelButtonText : "Cancel"}
                            </button>
                            <button
                                type="submit"
                                className="btn btn-danger remove-user rounded-50 w-150 hvr-grow"
                                onClick={() => {
                                    this.props.onConfirm();
                                }}
                            >
                                {this.props.confirmButtonText ? this.props.confirmButtonText : "Save"}
                            </button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default ConfirmDialog;