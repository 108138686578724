import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

// import exportAsImage from "../../components/Utility/exportAsImage";
class TasksThisWeek extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            taskthis_weeks: [],
            dashboard_thisweek_show: this.props.dashboard_thisweeksection_show,
        };
    }

    componentDidMount() {
        this.setState({
            taskthis_weeks: this.props.taskthis_weeks,
        });
    }

    TasksThisWeekReload() {
        this.setState({
            taskthis_weeks: this.props.taskthis_weeks,
        });
    }

    checkdatastatus = (section_due_date) => {
        let current_date = new Date();
        let subscriptiontrail_days = Math.ceil((new Date(section_due_date) - current_date) / 8.64e7);
        return parseInt(subscriptiontrail_days) === 0 ? " text-red " : "";
    };

    render() {
        const { taskthis_weeks, dashboard_thisweek_show } = this.state;
        return (
            <React.Fragment>
                <div className="col-xl-4 notify-activity">
                    <div className={"card mb-lg-8" + (dashboard_thisweek_show ? " card-xl-stretch  tasks-week" : "")}>
                        <div className="card-header align-items-center border-0 mt-4 ">
                            <h4 className="card-title align-items-start flex-column m-0">
                                <span className="fw-bolder text-main-color ">Tasks this week</span>
                                <span className="text-muted fw-normal fs-6 text-grey-dark">With upcoming due dates.</span>
                            </h4>
                            <div className="card-toolbar">
                                <div className="tab-none">
                                    <button type="button" className="btn btn-sm btn-icon" id="dashboard_thisweek" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="icon-dots text-grey-dark icon-size"></i>
                                    </button>
                                    <div className="dropdown-menu menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_thisweek">
                                        {/**
                                        <div className="py-4 px-2 cursor-pointer link-hover">
                                            <div className="text-black" onClick={(event) => exportAsImage(this.taskthis_weeks_ref, "Tasks this week")}>
                                                <i className="icon-download me-2 text-black"></i> <span>Download as image</span>
                                            </div>
                                        </div>
									**/}
                                        <div className="py-4 px-2 cursor-pointer link-hover">
                                            <div className="text-black" onClick={(event) => this.props.dashboard_thisweeksection_show_action(dashboard_thisweek_show ? false : true)}>
                                                <i className={"me-2 text-black" + (dashboard_thisweek_show ? " icon-eye font-size-8" : " bi bi-eye")}></i>
                                                <span>{dashboard_thisweek_show ? "Hide chart" : "Show chart"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-show">
                                    {/**
                                    <div className="px-2 cursor-pointer">
                                        <div className="text-gray" onClick={(event) => exportAsImage(this.taskthis_weeks_ref, "Tasks this week")}>
                                            <i className="icon-download text-gray"></i>
                                        </div>
                                    </div>
									**/}
                                    <div className="px-2 cursor-pointer eye-outer">
                                        <div className="text-gray" onClick={(event) => this.props.dashboard_thisweeksection_show_action(dashboard_thisweek_show ? false : true)}>
                                            <i className={"text-gray" + (dashboard_thisweek_show ? " icon-eye font-size-8" : " bi bi-eye fs-4")}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ref={(node) => (this.taskthis_weeks_ref = node)} className={"card-body recent-activity-out pt-0 " + (dashboard_thisweek_show ? "" : " d-none")}>
                            <div className="scroll-y r-activity-height">
                                {taskthis_weeks.length !== 0 ? (
                                    taskthis_weeks.map(
                                        (taskthis_week) =>
                                            taskthis_week.response && (
                                                <div className="d-flex mb-4" key={"taskthis_week_" + taskthis_week.response_user_id}>
                                                    <div className="symbol symbol-30px me-5">
                                                        <span className="avtars-outer mt-1">
                                                            <img src="assets/images/Calendar.svg" alt="Calendar" />
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                        <div className="flex-grow-1">
                                                            {taskthis_week.response.section_due_date && (
                                                                <span className={"text-muted fw-normal fs-7" + this.checkdatastatus(taskthis_week.response.section_due_date)}>
                                                                    Due{" "}
                                                                    {moment(taskthis_week.response.section_due_date).calendar(null, {
                                                                        sameDay: "[Today]",
                                                                        nextDay: "[Tomorrow]",
                                                                        nextWeek: "ddd  DD MMM  YYYY",
                                                                        lastDay: "[Yesterday]",
                                                                        lastWeek: "ddd  DD MMM  YYYY",
                                                                        sameElse: "ddd  DD MMM  YYYY",
                                                                    })}
                                                                </span>
                                                            )}
                                                            <span className="color1 fw-bold d-block fs-7">
                                                                <strong>
                                                                    <Link to={`/bid-response/${taskthis_week?.response?.bid?.bid_uuid}/?activesection=${taskthis_week?.response?.section_uuid}`}>
                                                                        {taskthis_week.response.title} for {taskthis_week.response.bid.bid_title}
                                                                    </Link>
                                                                </strong>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    )
                                ) : (
                                    <p className="text-center">No records found</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default TasksThisWeek;