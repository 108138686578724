import React from "react";
const ClientPointContactsList = (props) => {
    return (
        <tr>
            <td>{props.client_pointcontact.contact_name}</td>
            <td>{props.client_pointcontact.contact_role}</td>
            <td>{props.client_pointcontact.contact_phone_number}</td>
            <td>{props.client_pointcontact.contact_email}</td>
            <td className="text-end">
                <span className="btn p-0" type="button" onClick={(event) => props.update_clientpointcontact(props.client_pointcontact)}>
                    <span className="svg-icon svg-icon-3">
                        <img src="assets/images/icons/edit.svg" alt="" />
                    </span>
                </span>
                <span
                    className="btn p-0"
                    type="button"
                    onClick={(event) => {
                        props.RemovePointOfContact(props.client_pointcontact);
                    }}
                >
                    <span className="svg-icon svg-icon-3">
                        <img src="assets/images/icons/delete.svg" alt="" />
                    </span>
                </span>
            </td>
        </tr>
    );
};
export default ClientPointContactsList;