export const UpdatePersonalAvatar_START = "UpdatePersonalAvatar_START";
export const UpdatePersonalAvatar_SUCCESS = "UpdatePersonalAvatar_SUCCESS";
export const UpdatePersonalAvatar_FAIL = "UpdatePersonalAvatar_FAIL";

export const UpdatePersonal_START = "UpdatePersonal_START";
export const UpdatePersonal_SUCCESS = "UpdatePersonal_SUCCESS";
export const UpdatePersonal_FAIL = "UpdatePersonal_FAIL";

export const RemoveProfileImage_START = "RemoveProfileImage_START";
export const RemoveProfileImage_SUCCESS = "RemoveProfileImage_SUCCESS";
export const RemoveProfileImage_FAIL = "RemoveProfileImage_FAIL";

export const UpdatePassword_START = "UpdatePassword_START";
export const UpdatePassword_SUCCESS = "UpdatePassword_SUCCESS";
export const UpdatePassword_FAIL = "UpdatePassword_FAIL";

export const TwoFactorAccountVerify_START = "TwoFactorAccountVerify_START";
export const TwoFactorAccountVerify_SUCCESS = "TwoFactorAccountVerify_SUCCESS";
export const TwoFactorAccountVerify_FAIL = "TwoFactorAccountVerify_FAIL";

export const ResendTwoFactorOTPVerify_START = "ResendTwoFactorOTPVerify_START";
export const ResendTwoFactorOTPVerify_SUCCESS = "ResendTwoFactorOTPVerify_SUCCESS";
export const ResendTwoFactorOTPVerify_FAIL = "ResendTwoFactorOTPVerify_FAIL";

export const EnableTwoFactorOTPVerify_START = "EnableTwoFactorOTPVerify_START";
export const EnableTwoFactorOTPVerify_SUCCESS = "EnableTwoFactorOTPVerify_SUCCESS";
export const EnableTwoFactorOTPVerify_FAIL = "EnableTwoFactorOTPVerify_FAIL";

export const DisableTwoFactorAccountVerify_START = "DisableTwoFactorAccountVerify_START";
export const DisableTwoFactorAccountVerify_SUCCESS = "DisableTwoFactorAccountVerify_SUCCESS";
export const DisableTwoFactorAccountVerify_FAIL = "DisableTwoFactorAccountVerify_FAIL";

export const UpdateUserNotification_START = "UpdateUserNotification_START";
export const UpdateUserNotification_SUCCESS = "UpdateUserNotification_SUCCESS";
export const UpdateUserNotification_FAIL = "UpdateUserNotification_FAIL";

export const CompanyUpdateAvatar_START = "CompanyUpdateAvatar_START";
export const CompanyUpdateAvatar_SUCCESS = "CompanyUpdateAvatar_SUCCESS";
export const CompanyUpdateAvatar_FAIL = "CompanyUpdateAvatar_FAIL";

export const CompanyUpdate_START = "CompanyUpdate_START";
export const CompanyUpdate_SUCCESS = "CompanyUpdate_SUCCESS";
export const CompanyUpdate_FAIL = "CompanyUpdate_FAIL";

export const RemoveCompanyLogo_START = "RemoveCompanyLogo_START";
export const RemoveCompanyLogo_SUCCESS = "RemoveCompanyLogo_SUCCESS";
export const RemoveCompanyLogo_FAIL = "RemoveCompanyLogo_FAIL";

export const GetCompanySubscription_START = "GetCompanySubscription_START";
export const GetCompanySubscription_SUCCESS = "GetCompanySubscription_SUCCESS";
export const GetCompanySubscription_FAIL = "GetCompanySubscription_FAIL";

export const GetAllUsers_START = "GetAllUsers_START";
export const GetAllUsers_SUCCESS = "GetAllUsers_SUCCESS";
export const GetAllUsers_FAIL = "GetAllUsers_FAIL";

export const UpdateUser_START = "UpdateUser_START";
export const UpdateUser_SUCCESS = "UpdateUser_SUCCESS";
export const UpdateUser_FAIL = "UpdateUser_FAIL";

export const RemoveUser_START = "RemoveUser_START";
export const RemoveUser_SUCCESS = "RemoveUser_SUCCESS";
export const RemoveUser_FAIL = "RemoveUser_FAIL";

export const GetInviteUsers_START = "GetInviteUsers_START";
export const GetInviteUsers_SUCCESS = "GetInviteUsers_SUCCESS";
export const GetInviteUsers_FAIL = "GetInviteUsers_FAIL";

export const InviteUser_START = "InviteUser_START";
export const InviteUser_SUCCESS = "InviteUser_SUCCESS";
export const InviteUser_FAIL = "InviteUser_FAIL";

export const UpdateInviteUser_START = "UpdateInviteUser_START";
export const UpdateInviteUser_SUCCESS = "UpdateInviteUser_SUCCESS";
export const UpdateInviteUser_FAIL = "UpdateInviteUser_FAIL";

export const ReInviteUser_START = "ReInviteUser_START";
export const ReInviteUser_SUCCESS = "ReInviteUser_SUCCESS";
export const ReInviteUser_FAIL = "ReInviteUser_FAIL";

export const RemoveInviteUser_START = "RemoveInviteUser_START";
export const RemoveInviteUser_SUCCESS = "RemoveInviteUser_SUCCESS";
export const RemoveInviteUser_FAIL = "RemoveInviteUser_FAIL";

export const GetCompanyUsers_START = "GetCompanyUsers_START";
export const GetCompanyUsers_SUCCESS = "GetCompanyUsers_SUCCESS";
export const GetCompanyUsers_FAIL = "GetCompanyUsers_FAIL";

export const GetProducts_START = "GetProducts_START";
export const GetProducts_SUCCESS = "GetProducts_SUCCESS";
export const GetProducts_FAIL = "GetProducts_FAIL";

export const GetCurrentSubscription_START = "GetCurrentSubscription_START";
export const GetCurrentSubscription_SUCCESS = "GetCurrentSubscription_SUCCESS";
export const GetCurrentSubscription_FAIL = "GetCurrentSubscription_FAIL";

export const GetSubscriptionPackages_START = "GetSubscriptionPackages_START";
export const GetSubscriptionPackages_SUCCESS = "GetSubscriptionPackages_SUCCESS";
export const GetSubscriptionPackages_FAIL = "GetSubscriptionPackages_FAIL";

export const CreateSubscription_START = "CreateSubscription_START";
export const CreateSubscription_SUCCESS = "CreateSubscription_SUCCESS";
export const CreateSubscription_FAIL = "CreateSubscription_FAIL";

export const AddCard_START = "AddCard_START";
export const AddCard_SUCCESS = "AddCard_SUCCESS";
export const AddCard_FAIL = "AddCard_FAIL";

export const DeleteCard_START = "DeleteCard_START";
export const DeleteCard_SUCCESS = "DeleteCard_SUCCESS";
export const DeleteCard_FAIL = "DeleteCard_FAIL";

export const MakecardPrimary_START = "MakecardPrimary_START";
export const MakecardPrimary_SUCCESS = "MakecardPrimary_SUCCESS";
export const MakecardPrimary_FAIL = "MakecardPrimary_FAIL";

export const PaymentInformation_START = "PaymentInformation_START";
export const PaymentInformation_SUCCESS = "PaymentInformation_SUCCESS";
export const PaymentInformation_FAIL = "PaymentInformation_FAIL";

export const PaymentHistory_START = "PaymentHistory_START";
export const PaymentHistory_SUCCESS = "PaymentHistory_SUCCESS";
export const PaymentHistory_FAIL = "PaymentHistory_FAIL";

export const BillingContactSupport_START = "BillingContactSupport_START";
export const BillingContactSupport_SUCCESS = "BillingContactSupport_SUCCESS";
export const BillingContactSupport_FAIL = "BillingContactSupport_FAIL";

export const VerifyDiscount_START = "VerifyDiscount_START";
export const VerifyDiscount_SUCCESS = "VerifyDiscount_SUCCESS";
export const VerifyDiscount_FAIL = "VerifyDiscount_FAIL";

/*** Bid Action Type ***/
export const GetBids_START = "GetBids_START";
export const GetBids_SUCCESS = "GetBids_SUCCESS";
export const GetBids_FAIL = "GetBids_FAIL";

export const GetCountByStatus_START = "GetCountByStatus_START";
export const GetCountByStatus_SUCCESS = "GetCountByStatus_SUCCESS";
export const GetCountByStatus_FAIL = "GetCountByStatus_FAIL";

export const GetTags_START = "GetTags_START";
export const GetTags_SUCCESS = "GetTags_SUCCESS";
export const GetTags_FAIL = "GetTags_FAIL";

export const GetBidTypes_START = "GetBidTypes_START";
export const GetBidTypes_SUCCESS = "GetBidTypes_SUCCESS";
export const GetBidTypes_FAIL = "GetBidTypes_FAIL";

export const CreateBid_START = "CreateBid_START";
export const CreateBid_SUCCESS = "CreateBid_SUCCESS";
export const CreateBid_FAIL = "CreateBid_FAIL";

export const UpdateBid_START = "UpdateBid_START";
export const UpdateBid_SUCCESS = "UpdateBid_SUCCESS";
export const UpdateBid_FAIL = "UpdateBid_FAIL";

export const DeleteBid_START = "DeleteBid_START";
export const DeleteBid_SUCCESS = "DeleteBid_SUCCESS";
export const DeleteBid_FAIL = "DeleteBid_FAIL";

export const GetBidById_START = "GetBidById_START";
export const GetBidById_SUCCESS = "GetBidById_SUCCESS";
export const GetBidById_FAIL = "GetBidById_FAIL";

export const UpdateBidTeam_START = "UpdateBidTeam_START";
export const UpdateBidTeam_SUCCESS = "UpdateBidTeam_SUCCESS";
export const UpdateBidTeam_FAIL = "UpdateBidTeam_FAIL";

export const RemoveBidTeam_START = "RemoveBidTeam_START";
export const RemoveBidTeam_SUCCESS = "RemoveBidTeam_SUCCESS";
export const RemoveBidTeam_FAIL = "RemoveBidTeam_FAIL";

export const UpdateBidOwner_START = "UpdateBidOwner_START";
export const UpdateBidOwner_SUCCESS = "UpdateBidOwner_SUCCESS";
export const UpdateBidOwner_FAIL = "UpdateBidOwner_FAIL";

export const RemoveBidOwner_START = "RemoveBidOwner_START";
export const RemoveBidOwner_SUCCESS = "RemoveBidOwner_SUCCESS";
export const RemoveBidOwner_FAIL = "RemoveBidOwner_FAIL";

export const GetBidtypeContent_START = "GetBidtypeContent_START";
export const GetBidtypeContent_SUCCESS = "GetBidtypeContent_SUCCESS";
export const GetBidtypeContent_FAIL = "GetBidtypeContent_FAIL";

export const GetBidSectionByBidId_START = "GetBidSectionByBidId_START";
export const GetBidSectionByBidId_SUCCESS = "GetBidSectionByBidId_SUCCESS";
export const GetBidSectionByBidId_FAIL = "GetBidSectionByBidId_FAIL";

export const GetBidSectionById_START = "GetBidSectionById_START";
export const GetBidSectionById_SUCCESS = "GetBidSectionById_SUCCESS";
export const GetBidSectionById_FAIL = "GetBidSectionById_FAIL";

export const BidSectionInsert_START = "BidSectionInsert_START";
export const BidSectionInsert_SUCCESS = "BidSectionInsert_SUCCESS";
export const BidSectionInsert_FAIL = "BidSectionInsert_FAIL";

export const BidInsertNewBlankSection_START = "BidInsertNewBlankSection_START";
export const BidInsertNewBlankSection_SUCCESS = "BidInsertNewBlankSection_SUCCESS";
export const BidInsertNewBlankSection_FAIL = "BidInsertNewBlankSection_FAIL";

export const BidSectionRemove_START = "BidSectionRemove_START";
export const BidSectionRemove_SUCCESS = "BidSectionRemove_SUCCESS";
export const BidSectionRemove_FAIL = "BidSectionRemove_FAIL";

export const BidSectionUpdate_START = "BidSectionUpdate_START";
export const BidSectionUpdate_SUCCESS = "BidSectionUpdate_SUCCESS";
export const BidSectionUpdate_FAIL = "BidSectionUpdate_FAIL";

export const BidSectionShortingUpdate_START = "BidSectionShortingUpdate_START";
export const BidSectionShortingUpdate_SUCCESS = "BidSectionShortingUpdate_SUCCESS";
export const BidSectionShortingUpdate_FAIL = "BidSectionShortingUpdate_FAIL";

export const BidSectionUpdateTagUsers_START = "BidSectionUpdateTagUsers_START";
export const BidSectionUpdateTagUsers_SUCCESS = "BidSectionUpdateTagUsers_SUCCESS";
export const BidSectionUpdateTagUsers_FAIL = "BidSectionUpdateTagUsers_FAIL";

export const BidUploadDocument_START = "BidUploadDocument_START";
export const BidUploadDocument_SUCCESS = "BidUploadDocument_SUCCESS";
export const BidUploadDocument_FAIL = "BidUploadDocument_FAIL";

export const BidRemoveDocument_START = "BidRemoveDocument_START";
export const BidRemoveDocument_SUCCESS = "BidRemoveDocument_SUCCESS";
export const BidRemoveDocument_FAIL = "BidRemoveDocument_FAIL";

export const BidDocumentTitleUpdate_START = "BidDocumentTitleUpdate_START";
export const BidDocumentTitleUpdate_SUCCESS = "BidDocumentTitleUpdate_SUCCESS";
export const BidDocumentTitleUpdate_FAIL = "BidDocumentTitleUpdate_FAIL";

export const BidUpdateLibraryReturnableDocument_START = "BidUpdateLibraryReturnableDocument_START";
export const BidUpdateLibraryReturnableDocument_SUCCESS = "BidUpdateLibraryReturnableDocument_SUCCESS";
export const BidUpdateLibraryReturnableDocument_FAIL = "BidUpdateLibraryReturnableDocument_FAIL";

export const BidStatusUpdate_START = "BidStatusUpdate_START";
export const BidStatusUpdate_SUCCESS = "BidStatusUpdate_SUCCESS";
export const BidStatusUpdate_FAIL = "BidStatusUpdate_FAIL";

export const CreateComment_START = "CreateComment_START";
export const CreateComment_SUCCESS = "CreateComment_SUCCESS";
export const CreateComment_FAIL = "CreateComment_FAIL";

export const GetCommentById_START = "GetCommentById_START";
export const GetCommentById_SUCCESS = "GetCommentById_SUCCESS";
export const GetCommentById_FAIL = "GetCommentById_FAIL";

export const BidSelectedStatusUpdate_START = "BidSelectedStatusUpdate_START";
export const BidSelectedStatusUpdate_SUCCESS = "BidSelectedStatusUpdate_SUCCESS";
export const BidSelectedStatusUpdate_FAIL = "BidSelectedStatusUpdate_FAIL";

export const BidSectionDueDateUpdate_START = "BidSectionDueDateUpdate_START";
export const BidSectionDueDateUpdate_SUCCESS = "BidSectionDueDateUpdate_SUCCESS";
export const BidSectionDueDateUpdate_FAIL = "BidSectionDueDateUpdate_FAIL";

export const UpdateSectionTeam_START = "UpdateSectionTeam_START";
export const UpdateSectionTeam_SUCCESS = "UpdateSectionTeam_SUCCESS";
export const UpdateSectionTeam_FAIL = "UpdateSectionTeam_FAIL";

export const RemoveSectionTeam_START = "RemoveSectionTeam_START";
export const RemoveSectionTeam_SUCCESS = "RemoveSectionTeam_SUCCESS";
export const RemoveSectionTeam_FAIL = "RemoveSectionTeam_FAIL";

export const UpdateSectionOwner_START = "UpdateSectionOwner_START";
export const UpdateSectionOwner_SUCCESS = "UpdateSectionOwner_SUCCESS";
export const UpdateSectionOwner_FAIL = "UpdateSectionOwner_FAIL";

export const RemoveSectionOwner_START = "RemoveSectionOwner_START";
export const RemoveSectionOwner_SUCCESS = "RemoveSectionOwner_SUCCESS";
export const RemoveSectionOwner_FAIL = "RemoveSectionOwner_FAIL";

export const GetResponseRecord_START = "GetResponseRecord_START";
export const GetResponseRecord_SUCCESS = "GetResponseRecord_SUCCESS";
export const GetResponseRecord_FAIL = "GetResponseRecord_FAIL";

export const DesignPreviewGenratepdf_START = "DesignPreviewGenratepdf_START";
export const DesignPreviewGenratepdf_SUCCESS = "DesignPreviewGenratepdf_SUCCESS";
export const DesignPreviewGenratepdf_FAIL = "DesignPreviewGenratepdf_FAIL";

export const DesignPreviewUpdatepdf_START = "DesignPreviewUpdatepdf_START";
export const DesignPreviewUpdatepdf_SUCCESS = "DesignPreviewUpdatepdf_SUCCESS";
export const DesignPreviewUpdatepdf_FAIL = "DesignPreviewUpdatepdf_FAIL";

export const BidLockStatusChange_START = "BidLockStatusChange_START";
export const BidLockStatusChange_SUCCESS = "BidLockStatusChange_SUCCESS";
export const BidLockStatusChange_FAIL = "BidLockStatusChange_FAIL";

export const GenrateBidZip_START = "GenrateBidZip_START";
export const GenrateBidZip_SUCCESS = "GenrateBidZip_SUCCESS";
export const GenrateBidZip_FAIL = "GenrateBidZip_FAIL";

export const BidCompleteZipSend_START = "BidCompleteZipSend_START";
export const BidCompleteZipSend_SUCCESS = "BidCompleteZipSend_SUCCESS";
export const BidCompleteZipSend_FAIL = "BidCompleteZipSend_FAIL";

export const GetBidResponseShare_START = "GetBidResponseShare_START";
export const GetBidResponseShare_SUCCESS = "GetBidResponseShare_SUCCESS";
export const GetBidResponseShare_FAIL = "GetBidResponseShare_FAIL";

export const BidResponseShare_START = "BidResponseShare_START";
export const BidResponseShare_SUCCESS = "BidResponseShare_SUCCESS";
export const BidResponseShare_FAIL = "BidResponseShare_FAIL";

export const GetBidActivityLogs_START = "GetBidActivityLogs_START";
export const GetBidActivityLogs_SUCCESS = "GetBidActivityLogs_SUCCESS";
export const GetBidActivityLogs_FAIL = "GetBidActivityLogs_FAIL";

export const GetBidResponsePricingBySectionId_START = "GetBidResponsePricingBySectionId_START";
export const GetBidResponsePricingBySectionId_SUCCESS = "GetBidResponsePricingBySectionId_SUCCESS";
export const GetBidResponsePricingBySectionId_FAIL = "GetBidResponsePricingBySectionId_FAIL";

export const CreateBidResponsePricing_START = "CreateBidResponsePricing_START";
export const CreateBidResponsePricing_SUCCESS = "CreateBidResponsePricing_SUCCESS";
export const CreateBidResponsePricing_FAIL = "CreateBidResponsePricing_FAIL";

export const UpdateBidResponsePricing_START = "UpdateBidResponsePricing_START";
export const UpdateBidResponsePricing_SUCCESS = "UpdateBidResponsePricing_SUCCESS";
export const UpdateBidResponsePricing_FAIL = "UpdateBidResponsePricing_FAIL";

export const RemoveBidContentPricing_START = "RemoveBidContentPricing_START";
export const RemoveBidContentPricing_SUCCESS = "RemoveBidContentPricing_SUCCESS";
export const RemoveBidContentPricing_FAIL = "RemoveBidContentPricing_FAIL";

export const UpdateBidResponsePricingGST_START = "UpdateBidResponsePricingGST_START";
export const UpdateBidResponsePricingGST_SUCCESS = "UpdateBidResponsePricingGST_SUCCESS";
export const UpdateBidResponsePricingGST_FAIL = "UpdateBidResponsePricingGST_FAIL";

export const GetBidLinkShareExtenal_START = "GetBidLinkShareExtenal_START";
export const GetBidLinkShareExtenal_SUCCESS = "GetBidLinkShareExtenal_SUCCESS";
export const GetBidLinkShareExtenal_FAIL = "GetBidLinkShareExtenal_FAIL";

export const GenrateBidLinkShareExtenalZip_START = "GenrateBidLinkShareExtenalZip_START";
export const GenrateBidLinkShareExtenalZip_SUCCESS = "GenrateBidLinkShareExtenalZip_SUCCESS";
export const GenrateBidLinkShareExtenalZip_FAIL = "GenrateBidLinkShareExtenalZip_FAIL";

/*** MyTasks Action Type ***/
export const GetMyTaskStatus_START = "GetMyTaskStatus_START";
export const GetMyTaskStatus_SUCCESS = "GetMyTaskStatus_SUCCESS";
export const GetMyTaskStatus_FAIL = "GetMyTaskStatus_FAIL";

export const GetMyTasks_START = "GetMyTasks_START";
export const GetMyTasks_SUCCESS = "GetMyTasks_SUCCESS";
export const GetMyTasks_FAIL = "GetMyTasks_FAIL";

/*** Client Action Type ***/
export const GetClients_START = "GetClients_START";
export const GetClients_SUCCESS = "GetClients_SUCCESS";
export const GetClients_FAIL = "GetClients_FAIL";

export const GetClientsStatus_START = "GetClientsStatus_START";
export const GetClientsStatus_SUCCESS = "GetClientsStatus_SUCCESS";
export const GetClientsStatus_FAIL = "GetClientsStatus_FAIL";

export const CreateClient_START = "CreateClient_START";
export const CreateClient_SUCCESS = "CreateClient_SUCCESS";
export const CreateClient_FAIL = "CreateClient_FAIL";

export const GetClientById_START = "GetClientById_START";
export const GetClientById_SUCCESS = "GetClientById_SUCCESS";
export const GetClientById_FAIL = "GetClientById_FAIL";

export const UpdateClient_START = "UpdateClient_START";
export const UpdateClient_SUCCESS = "UpdateClient_SUCCESS";
export const UpdateClient_FAIL = "UpdateClient_FAIL";

export const DeleteClient_START = "DeleteClient_START";
export const DeleteClient_SUCCESS = "DeleteClient_SUCCESS";
export const DeleteClient_FAIL = "DeleteClient_FAIL";

export const GetClientContactByClientId_START = "GetClientContactByClientId_START";
export const GetClientContactByClientId_SUCCESS = "GetClientContactByClientId_SUCCESS";
export const GetClientContactByClientId_FAIL = "GetClientContactByClientId_FAIL";

export const CreateClientPointContacts_START = "CreateClientPointContacts_START";
export const CreateClientPointContacts_SUCCESS = "CreateClientPointContacts_SUCCESS";
export const CreateClientPointContacts_FAIL = "CreateClientPointContacts_FAIL";

export const UpdateClientPointContact_START = "UpdateClientPointContact_START";
export const UpdateClientPointContact_SUCCESS = "UpdateClientPointContact_SUCCESS";
export const UpdateClientPointContact_FAIL = "UpdateClientPointContact_FAIL";

export const DeleteClienPointContact_START = "DeleteClienPointContact_START";
export const DeleteClienPointContact_SUCCESS = "DeleteClienPointContact_SUCCESS";
export const DeleteClienPointContact_FAIL = "DeleteClienPointContact_FAIL";

export const GetClientBidsByClientId_START = "GetClientBidsByClientId_START";
export const GetClientBidsByClientId_SUCCESS = "GetClientBidsByClientId_SUCCESS";
export const GetClientBidsByClientId_FAIL = "GetClientBidsByClientId_FAIL";

export const GetClientBidsStatusByClientId_START = "GetClientBidsStatusByClientId_START";
export const GetClientBidsStatusByClientId_SUCCESS = "GetClientBidsStatusByClientId_SUCCESS";
export const GetClientBidsStatusByClientId_FAIL = "GetClientBidsStatusByClientId_FAIL";

/*** Library Action Type ***/
export const GetAllLibrary_START = "GetAllLibrary_START";
export const GetAllLibrary_SUCCESS = "GetAllLibrary_SUCCESS";
export const GetAllLibrary_FAIL = "GetAllLibrary_FAIL";

export const CreateLibrary_START = "CreateLibrary_START";
export const CreateLibrary_SUCCESS = "CreateLibrary_SUCCESS";
export const CreateLibrary_FAIL = "CreateLibrary_FAIL";

export const UpdateLibrary_START = "UpdateLibrary_START";
export const UpdateLibrary_SUCCESS = "UpdateLibrary_SUCCESS";
export const UpdateLibrary_FAIL = "UpdateLibrary_FAIL";

export const DeleteLibrary_START = "DeleteLibrary_START";
export const DeleteLibrary_SUCCESS = "DeleteLibrary_SUCCESS";
export const DeleteLibrary_FAIL = "DeleteLibrary_FAIL";

export const GetLibraryWithoutContents_START = "GetLibraryWithoutContents_START";
export const GetLibraryWithoutContents_SUCCESS = "GetLibraryWithoutContents_SUCCESS";
export const GetLibraryWithoutContents_FAIL = "GetLibraryWithoutContents_FAIL";

export const GetLibraryWithContents_START = "GetLibraryWithContents_START";
export const GetLibraryWithContents_SUCCESS = "GetLibraryWithContents_SUCCESS";
export const GetLibraryWithContents_FAIL = "GetLibraryWithContents_FAIL";

export const GetLibraryByID_START = "GetLibraryByID_START";
export const GetLibraryByID_SUCCESS = "GetLibraryByID_SUCCESS";
export const GetLibraryByID_FAIL = "GetLibraryByID_FAIL";

export const GetContentsByLibraryID_START = "GetContentsByLibraryID_START";
export const GetContentsByLibraryID_SUCCESS = "GetContentsByLibraryID_SUCCESS";
export const GetContentsByLibraryID_FAIL = "GetContentsByLibraryID_FAIL";

export const CreateLibraryContent_START = "CreateLibraryContent_START";
export const CreateLibraryContent_SUCCESS = "CreateLibraryContent_SUCCESS";
export const CreateLibraryContent_FAIL = "CreateLibraryContent_FAIL";

export const UpdateLibraryContent_START = "UpdateLibraryContent_START";
export const UpdateLibraryContent_SUCCESS = "UpdateLibraryContent_SUCCESS";
export const UpdateLibraryContent_FAIL = "UpdateLibraryContent_FAIL";

export const DeleteLibraryContent_START = "DeleteLibraryContent_START";
export const DeleteLibraryContent_SUCCESS = "DeleteLibraryContent_SUCCESS";
export const DeleteLibraryContent_FAIL = "DeleteLibraryContent_FAIL";

export const LibraryContentCountUpdate_START = "LibraryContentCountUpdate_START";
export const LibraryContentCountUpdate_SUCCESS = "LibraryContentCountUpdate_SUCCESS";
export const LibraryContentCountUpdate_FAIL = "LibraryContentCountUpdate_FAIL";

export const GetContentByID_START = "GetContentByID_START";
export const GetContentByID_SUCCESS = "GetContentByID_SUCCESS";
export const GetContentByID_FAIL = "GetContentByID_FAIL";

export const GetLibraryReturnableDocuments_START = "GetLibraryReturnableDocuments_START";
export const GetLibraryReturnableDocuments_SUCCESS = "GetLibraryReturnableDocuments_SUCCESS";
export const GetLibraryReturnableDocuments_FAIL = "GetLibraryReturnableDocuments_FAIL";

export const CreateLibraryReturnableDocument_START = "CreateLibraryReturnableDocument_START";
export const CreateLibraryReturnableDocument_SUCCESS = "CreateLibraryReturnableDocument_SUCCESS";
export const CreateLibraryReturnableDocument_FAIL = "CreateLibraryReturnableDocument_FAIL";

export const DeleteLibraryReturnableDocument_START = "DeleteLibraryReturnableDocument_START";
export const DeleteLibraryReturnableDocument_SUCCESS = "DeleteLibraryReturnableDocument_SUCCESS";
export const DeleteLibraryReturnableDocument_FAIL = "DeleteLibraryReturnableDocument_FAIL";

export const GetLibraryPastProposals_START = "GetLibraryPastProposals_START";
export const GetLibraryPastProposals_SUCCESS = "GetLibraryPastProposals_SUCCESS";
export const GetLibraryPastProposals_FAIL = "GetLibraryPastProposals_FAIL";

export const GetLibraryPastProposalsByBid_START = "GetLibraryPastProposalsByBid_START";
export const GetLibraryPastProposalsByBid_SUCCESS = "GetLibraryPastProposalsByBid_SUCCESS";
export const GetLibraryPastProposalsByBid_FAIL = "GetLibraryPastProposalsByBid_FAIL";

/*** Dashboard Type ***/
export const GetDashboardStart_START = "GetDashboardStart_START";
export const GetDashboardStart_SUCCESS = "GetDashboardStart_SUCCESS";
export const GetDashboardStart_FAIL = "GetDashboardStart_FAIL";

export const DashboardGreatJobs_START = "DashboardGreatJobs_START";
export const DashboardGreatJobs_SUCCESS = "DashboardGreatJobs_SUCCESS";
export const DashboardGreatJobs_FAIL = "DashboardGreatJobs_FAIL";

export const DashboardTasks_START = "DashboardTasks_START";
export const DashboardTasks_SUCCESS = "DashboardTasks_SUCCESS";
export const DashboardTasks_FAIL = "DashboardTasks_FAIL";

export const DashboardRecentActivity_START = "DashboardRecentActivity_START";
export const DashboardRecentActivity_SUCCESS = "DashboardRecentActivity_SUCCESS";
export const DashboardRecentActivity_FAIL = "DashboardRecentActivity_FAIL";

export const GetBidActivity_START = "GetBidActivity_START";
export const GetBidActivity_SUCCESS = "GetBidActivity_SUCCESS";
export const GetBidActivity_FAIL = "GetBidActivity_FAIL";

export const GetBidValues_START = "GetBidValues_START";
export const GetBidValues_SUCCESS = "GetBidValues_SUCCESS";
export const GetBidValues_FAIL = "GetBidValues_FAIL";

export const GetBidRate_START = "GetBidRate_START";
export const GetBidRate_SUCCESS = "GetBidRate_SUCCESS";
export const GetBidRate_FAIL = "GetBidRate_FAIL";

export const UpcomingBids_START = "UpcomingBids_START";
export const UpcomingBids_SUCCESS = "UpcomingBids_SUCCESS";
export const UpcomingBids_FAIL = "UpcomingBids_FAIL";

export const GetLatestBidHQ_START = "GetLatestBidHQ_START";
export const GetLatestBidHQ_SUCCESS = "GetLatestBidHQ_SUCCESS";
export const GetLatestBidHQ_FAIL = "GetLatestBidHQ_FAIL";

/*** Reporting Type ***/
export const GetReporting_START = "GetReporting_START";
export const GetReporting_SUCCESS = "GetReporting_SUCCESS";
export const GetReporting_FAIL = "GetReporting_FAIL";

/*** Notifications Type ***/
export const GetUserDesktopNotification_START = "GetUserDesktopNotification_START";
export const GetUserDesktopNotification_SUCCESS = "GetUserDesktopNotification_SUCCESS";
export const GetUserDesktopNotification_FAIL = "GetUserDesktopNotification_FAIL";

export const GetDesktopNotificationList_START = "GetDesktopNotificationList_START";
export const GetDesktopNotificationList_SUCCESS = "GetDesktopNotificationList_SUCCESS";
export const GetDesktopNotificationList_FAIL = "GetDesktopNotificationList_FAIL";

export const MarkAllNotificationRead_START = "MarkAllNotificationRead_START";
export const MarkAllNotificationRead_SUCCESS = "MarkAllNotificationRead_SUCCESS";
export const MarkAllNotificationRead_FAIL = "MarkAllNotificationRead_FAIL";

export const NotificationMarkRead_START = "NotificationMarkRead_START";
export const NotificationMarkRead_SUCCESS = "NotificationMarkRead_SUCCESS";
export const NotificationMarkRead_FAIL = "NotificationMarkRead_FAIL";



