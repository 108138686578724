/*** Dashboard Action Type ***/
export const GetSuperadminDashboard_START = "GetSuperadminDashboard_START";
export const GetSuperadminDashboard_SUCCESS = "GetSuperadminDashboard_SUCCESS";
export const GetSuperadminDashboard_FAIL = "GetSuperadminDashboard_FAIL";

export const GetSuperadminDashboardExcel_START = "GetSuperadminDashboardExcel_START";
export const GetSuperadminDashboardExcel_SUCCESS = "GetSuperadminDashboardExcel_SUCCESS";
export const GetSuperadminDashboardExcel_FAIL = "GetSuperadminDashboardExcel_FAIL";

/*** Settings Action Type ***/
export const UpdatePersonal_START = "UpdatePersonal_START";
export const UpdatePersonal_SUCCESS = "UpdatePersonal_SUCCESS";
export const UpdatePersonal_FAIL = "UpdatePersonal_FAIL";

export const UpdatePassword_START = "UpdatePassword_START";
export const UpdatePassword_SUCCESS = "UpdatePassword_SUCCESS";
export const UpdatePassword_FAIL = "UpdatePassword_FAIL";

export const GetSubscriptionPackages_START = "GetSubscriptionPackages_START";
export const GetSubscriptionPackages_SUCCESS = "GetSubscriptionPackages_SUCCESS";
export const GetSubscriptionPackages_FAIL = "GetSubscriptionPackages_FAIL";

export const CreateSubscriptionPackage_START = "CreateSubscriptionPackage_START";
export const CreateSubscriptionPackage_SUCCESS = "CreateSubscriptionPackage_SUCCESS";
export const CreateSubscriptionPackage_FAIL = "CreateSubscriptionPackage_FAIL";

export const UpdateSubscriptionPackage_START = "UpdateSubscriptionPackage_START";
export const UpdateSubscriptionPackage_SUCCESS = "UpdateSubscriptionPackage_SUCCESS";
export const UpdateSubscriptionPackage_FAIL = "UpdateSubscriptionPackage_FAIL";

export const GetDiscounts_START = "GetDiscounts_START";
export const GetDiscounts_SUCCESS = "GetDiscounts_SUCCESS";
export const GetDiscounts_FAIL = "GetDiscounts_FAIL";

/*** Companies Action Type ***/
export const GetAllCompanies_START = "GetAllCompanies_START";
export const GetAllCompanies_SUCCESS = "GetAllCompanies_SUCCESS";
export const GetAllCompanies_FAIL = "GetAllCompanies_FAIL";

export const GetCompanyById_START = "GetCompanyById_START";
export const GetCompanyById_SUCCESS = "GetCompanyById_SUCCESS";
export const GetCompanyById_FAIL = "GetCompanyById_FAIL";

export const AddCompany_START = "AddCompany_START";
export const AddCompany_SUCCESS = "AddCompany_SUCCESS";
export const AddCompany_FAIL = "AddCompany_FAIL";

export const UpdateCompany_START = "UpdateCompany_START";
export const UpdateCompany_SUCCESS = "UpdateCompany_SUCCESS";
export const UpdateCompany_FAIL = "UpdateCompany_FAIL";

export const DeleteCompany_START = "DeleteCompany_START";
export const DeleteCompany_SUCCESS = "DeleteCompany_SUCCESS";
export const DeleteCompany_FAIL = "DeleteCompany_FAIL";

export const ChangeCompanyStatus_START = "ChangeCompanyStatus_START";
export const ChangeCompanyStatus_SUCCESS = "ChangeCompanyStatus_SUCCESS";
export const ChangeCompanyStatus_FAIL = "ChangeCompanyStatus_FAIL";

export const GetAllCompaniesExcel_START = "GetAllCompaniesExcel_START";
export const GetAllCompaniesExcel_SUCCESS = "GetAllCompaniesExcel_SUCCESS";
export const GetAllCompaniesExcel_FAIL = "GetAllCompaniesExcel_FAIL";

export const UpdateExtendSubscription_START = "UpdateExtendSubscription_START";
export const UpdateExtendSubscription_SUCCESS = "UpdateExtendSubscription_SUCCESS";
export const UpdateExtendSubscription_FAIL = "UpdateExtendSubscription_FAIL";

export const GetCompanyUsers_START = "GetCompanyUsers_START";
export const GetCompanyUsers_SUCCESS = "GetCompanyUsers_SUCCESS";
export const GetCompanyUsers_FAIL = "GetCompanyUsers_FAIL";

///////////////////////////////////////////////////////////////////////
export const GetAllUsers_START = "GetAllUsers_START";
export const GetAllUsers_SUCCESS = "GetAllUsers_SUCCESS";
export const GetAllUsers_FAIL = "GetAllUsers_FAIL";

export const GetUserByUUID_START = "GetUserByUUID_START";
export const GetUserByUUID_SUCCESS = "GetUserByUUID_SUCCESS";
export const GetUserByUUID_FAIL = "GetUserByUUID_FAIL";

export const AddCompanyUser_START = "AddCompanyUser_START";
export const AddCompanyUser_SUCCESS = "AddCompanyUser_SUCCESS";
export const AddCompanyUser_FAIL = "AddCompanyUser_FAIL";

export const UpdateUser_START = "UpdateUser_START";
export const UpdateUser_SUCCESS = "UpdateUser_SUCCESS";
export const UpdateUser_FAIL = "UpdateUser_FAIL";

export const DeleteUser_START = "DeleteUser_START";
export const DeleteUser_SUCCESS = "DeleteUser_SUCCESS";
export const DeleteUser_FAIL = "DeleteUser_FAIL";

export const ChangeUserStatus_START = "ChangeUserStatus_START";
export const ChangeUserStatus_SUCCESS = "ChangeUserStatus_SUCCESS";
export const ChangeUserStatus_FAIL = "ChangeUserStatus_FAIL";

export const GetCompanyUsersExcel_START = "GetCompanyUsersExcel_START";
export const GetCompanyUsersExcel_SUCCESS = "GetCompanyUsersExcel_SUCCESS";
export const GetCompanyUsersExcel_FAIL = "GetCompanyUsersExcel_FAIL";

export const GetCompanyInvitedUsers_START = "GetCompanyInvitedUsers_START";
export const GetCompanyInvitedUsers_SUCCESS = "GetCompanyInvitedUsers_SUCCESS";
export const GetCompanyInvitedUsers_FAIL = "GetCompanyInvitedUsers_FAIL";

export const ReInviteUser_START = "ReInviteUser_START";
export const ReInviteUser_SUCCESS = "ReInviteUser_SUCCESS";
export const ReInviteUser_FAIL = "ReInviteUser_FAIL";

export const GetIndividualUsersExcel_START = "GetIndividualUsersExcel_START";
export const GetIndividualUsersExcel_SUCCESS = "GetIndividualUsersExcel_SUCCESS";
export const GetIndividualUsersExcel_FAIL = "GetIndividualUsersExcel_FAIL";

/*** ManageContent Action Type ***/
export const GetBidTypes_START = "GetBidTypes_START";
export const GetBidTypes_SUCCESS = "GetBidTypes_SUCCESS";
export const GetBidTypes_FAIL = "GetBidTypes_FAIL";

export const GetBidTypeByid_START = "GetBidTypeByid_START";
export const GetBidTypeByid_SUCCESS = "GetBidTypeByid_SUCCESS";
export const GetBidTypeByid_FAIL = "GetBidTypeByid_FAIL";

export const CreateBidType_START = "CreateBidType_START";
export const CreateBidType_SUCCESS = "CreateBidType_SUCCESS";
export const CreateBidType_FAIL = "CreateBidType_FAIL";

export const UpdateBidType_START = "UpdateBidType_START";
export const UpdateBidType_SUCCESS = "UpdateBidType_SUCCESS";
export const UpdateBidType_FAIL = "UpdateBidType_FAIL";

export const DeleteBidType_START = "DeleteBidType_START";
export const DeleteBidType_SUCCESS = "DeleteBidType_SUCCESS";
export const DeleteBidType_FAIL = "DeleteBidType_FAIL";

export const DeleteBidTypeContent_START = "DeleteBidTypeContent_START";
export const DeleteBidTypeContent_SUCCESS = "DeleteBidTypeContent_SUCCESS";
export const DeleteBidTypeContent_FAIL = "DeleteBidTypeContent_FAIL";

export const GetBidtypeContentsByBidTypeId_START = "GetBidtypeContentsByBidTypeId_START";
export const GetBidtypeContentsByBidTypeId_SUCCESS = "GetBidtypeContentsByBidTypeId_SUCCESS";
export const GetBidtypeContentsByBidTypeId_FAIL = "GetBidtypeContentsByBidTypeId_FAIL";

export const GetBidtypeDemoContents_START = "GetBidtypeDemoContents_START";
export const GetBidtypeDemoContents_SUCCESS = "GetBidtypeDemoContents_SUCCESS";
export const GetBidtypeDemoContents_FAIL = "GetBidtypeDemoContents_FAIL";

export const GetBidTypeContentByid_START = "GetBidTypeContentByid_START";
export const GetBidTypeContentByid_SUCCESS = "GetBidTypeContentByid_SUCCESS";
export const GetBidTypeContentByid_FAIL = "GetBidTypeContentByid_FAIL";

export const CreateManageContent_START = "CreateManageContent_START";
export const CreateManageContent_SUCCESS = "CreateManageContent_SUCCESS";
export const CreateManageContent_FAIL = "CreateManageContent_FAIL";

export const UpdateBidtypeContent_START = "UpdateBidtypeContent_START";
export const UpdateBidtypeContent_SUCCESS = "UpdateBidtypeContent_SUCCESS";
export const UpdateBidtypeContent_FAIL = "UpdateBidtypeContent_FAIL";