import React from "react";

const BidStatus = (props) => {
    return (
        <React.Fragment>
            <div className="card-body mt-4 p-0 users-images reporting-dash-new ">
                <div className="row">
                    <div className="col-sm-6 col-md-4 col-xl-2 mb-4">
                        <div className={"card curser_pointer" + (props.total_bidsshow ? " card_active" : "")} onClick={(event) => props.propstotal_bidsshow(props.total_bidsshow ? false : true)}>
                            <div className="card-header flex-nowrap border-0 list-block-link p-3">
                                <div className="card-title m-0">
                                    <div className="symbol me-3">
                                        <i className="icon-info text-dark fs-3x"></i>
                                    </div>
                                    <div>
                                        <span className="text-dark m-0">All bids</span> <span className="text-dark fw-bolder">{props.report_type === "total" ? props.total_bids : "$" + props.total_bidsvalue}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-xl-2 mb-4">
                        <div className={"card curser_pointer" + (props.total_wonshow ? " card_active" : "")} onClick={(event) => props.propstotal_wonshow(props.total_wonshow ? false : true)}>
                            <div className="card-header flex-nowrap border-0 list-block-link p-3">
                                <div className="card-title m-0">
                                    <div className="symbol me-3">
                                        <i className="icon-tick1 title_vector_won fs-3x"></i>
                                    </div>
                                    <div>
                                        <span className="text-dark m-0">Won</span> <span className="text-dark fw-bolder">{props.report_type === "total" ? props.total_won : "$" + props.total_wonvalue}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-xl-2 mb-4">
                        <div className={"card curser_pointer" + (props.total_progressshow ? " card_active" : "")} onClick={(event) => props.propstotal_progressshow(props.total_progressshow ? false : true)}>
                            <div className="card-header flex-nowrap border-0 list-block-link p-3">
                                <div className="card-title m-0">
                                    <div className="symbol me-3">
                                        <i className="icon-progress-1 text-dark-blue-light fs-3x"></i>
                                    </div>
                                    <div>
                                        <span className="text-dark m-0">Working</span> <span className="text-dark fw-bolder">{props.report_type === "total" ? props.total_progress : "$" + props.total_progressvalue}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-xl-2 mb-4">
                        <div className={"card curser_pointer" + (props.total_withdrawnshow ? " card_active" : "")} onClick={(event) => props.propstotal_withdrawnshow(props.total_withdrawnshow ? false : true)}>
                            <div className="card-header flex-nowrap border-0 list-block-link p-3">
                                <div className="card-title m-0">
                                    <div className="symbol me-3">
                                        <i className="icon-Withdrawn text-gray-new fs-3x"></i>
                                    </div>
                                    <div>
                                        <span className="text-dark m-0">Withdrawn</span> <span className="text-dark fw-bolder">{props.report_type === "total" ? props.total_withdrawn : "$" + props.total_withdrawnvalue}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-xl-2 mb-4">
                        <div className={"card curser_pointer" + (props.total_lostshow ? " card_active" : "")} onClick={(event) => props.propstotal_lostshow(props.total_lostshow ? false : true)}>
                            <div className="card-header flex-nowrap border-0 list-block-link p-3">
                                <div className="card-title m-0">
                                    <div className="symbol me-3">
                                        <i className="icon-cross-1 text-grey-dark fs-3x"></i>
                                    </div>
                                    <div>
                                        <span className="text-dark m-0">Lost</span> <span className="text-dark fw-bolder">{props.report_type === "total" ? props.total_lost : "$" + props.total_lostvalue}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default BidStatus;