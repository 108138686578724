import * as SuperAdminactionTypes from "../../constants/SuperAdminactionTypes";

const initial_state = {
    error: null,
    token: null,
    loader: false,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case SuperAdminactionTypes.GetBidTypes_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.GetBidTypes_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.GetBidTypes_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case SuperAdminactionTypes.GetBidTypeByid_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.GetBidTypeByid_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.GetBidTypeByid_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case SuperAdminactionTypes.CreateBidType_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.CreateBidType_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.CreateBidType_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case SuperAdminactionTypes.UpdateBidType_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.UpdateBidType_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.UpdateBidType_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case SuperAdminactionTypes.DeleteBidType_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.DeleteBidType_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.DeleteBidType_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case SuperAdminactionTypes.GetBidtypeContentsByBidTypeId_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.GetBidtypeContentsByBidTypeId_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.GetBidtypeContentsByBidTypeId_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.CreateManageContent_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.CreateManageContent_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.CreateManageContent_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.UpdateBidtypeContent_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.UpdateBidtypeContent_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.UpdateBidtypeContent_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        default:
            return state;
    }
};

export default reducer;