import React from "react";
import { Modal } from "react-bootstrap";
import Validations from "../../../components/Utility/Validations";

class AddPointOfContactModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addpointsofcontact_form: {
                client_id: {
                    label: "client ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                contact_name: {
                    label: "Contact name",
                    type: "text",
                    value: "",
                    valid: false,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },
                contact_role: {
                    label: "Role",
                    type: "text",
                    value: "",
                    valid: false,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                contact_phone_number: {
                    label: "Phone number",
                    type: "text",
                    value: "",
                    valid: false,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                contact_email: {
                    label: "Email",
                    type: "email",
                    value: "",
                    valid: false,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
            },
        };
    }

    async componentDidMount() {
        const update_addpointsofcontact_form = { ...this.state.addpointsofcontact_form };
        update_addpointsofcontact_form.client_id.value = this.props.client_id;
        this.setState({
            addpointsofcontact_form: update_addpointsofcontact_form,
        });
    }

    inputChangeHandler(event, identifier) {
        const update_addpointsofcontact_form = { ...this.state.addpointsofcontact_form };
        const form_element = { ...update_addpointsofcontact_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_addpointsofcontact_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_addpointsofcontact_form) {
            is_form_valid = update_addpointsofcontact_form[identifier].valid && is_form_valid;
        }

        this.setState({ addpointsofcontact_form: update_addpointsofcontact_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_addpointsofcontact_form = this.state.addpointsofcontact_form;
        for (let key in update_addpointsofcontact_form) {
            let form_element = update_addpointsofcontact_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_addpointsofcontact_form[key] = form_element;
        }
        this.setState({ addpointsofcontact_form: update_addpointsofcontact_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.addpointsofcontact_form) {
                form_data[key] = this.state.addpointsofcontact_form[key].value;
            }
            this.props.CreateClientPointContacts(form_data);
        }
    };

    render() {
        const { addpointsofcontact_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="addpointsofcontact_modal bid-custom-modal"
                    show={this.props.addpointsofcontact_modal}
                    onHide={() => {
                        this.props.addpointsofcontact_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add contact</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="row">
                                <div className={"col-sm-12 mb-4 fv-row" + (!addpointsofcontact_form.contact_name.valid && addpointsofcontact_form.contact_name.onBlur_out ? " error-field" : "")}>
                                    <label className="form-label fs-6">{addpointsofcontact_form.contact_name.label}</label>
                                    <input
                                        type={addpointsofcontact_form.contact_name.type}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={addpointsofcontact_form.contact_name.placeholder}
                                        value={addpointsofcontact_form.contact_name.value ? addpointsofcontact_form.contact_name.value : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "contact_name")}
                                        onBlur={(event) => {
                                            addpointsofcontact_form.contact_name.onBlur_out = true;
                                            this.setState({
                                                addpointsofcontact_form: addpointsofcontact_form,
                                            });
                                        }}
                                    />
                                    {!addpointsofcontact_form.contact_name.valid && addpointsofcontact_form.contact_name.onBlur_out ? <div className="error field-error">{addpointsofcontact_form.contact_name.error_msg}</div> : ""}
                                </div>
                                <div className={"col-sm-12 mb-4 fv-row" + (!addpointsofcontact_form.contact_role.valid && addpointsofcontact_form.contact_role.onBlur_out ? " error-field" : "")}>
                                    <label className="form-label fs-6">{addpointsofcontact_form.contact_role.label}</label>
                                    <input
                                        type={addpointsofcontact_form.contact_role.type}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={addpointsofcontact_form.contact_role.placeholder}
                                        value={addpointsofcontact_form.contact_role.value ? addpointsofcontact_form.contact_role.value : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "contact_role")}
                                        onBlur={(event) => {
                                            addpointsofcontact_form.contact_role.onBlur_out = true;
                                            this.setState({
                                                addpointsofcontact_form: addpointsofcontact_form,
                                            });
                                        }}
                                    />
                                    {!addpointsofcontact_form.contact_role.valid && addpointsofcontact_form.contact_role.onBlur_out ? <div className="error field-error">{addpointsofcontact_form.contact_role.error_msg}</div> : ""}
                                </div>
                                <div className={"col-sm-12 mb-4 fv-row" + (!addpointsofcontact_form.contact_phone_number.valid && addpointsofcontact_form.contact_phone_number.onBlur_out ? " error-field" : "")}>
                                    <label className="form-label fs-6">{addpointsofcontact_form.contact_phone_number.label}</label>
                                    <input
                                        type={addpointsofcontact_form.contact_phone_number.type}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={addpointsofcontact_form.contact_phone_number.placeholder}
                                        value={addpointsofcontact_form.contact_phone_number.value ? addpointsofcontact_form.contact_phone_number.value : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "contact_phone_number")}
                                        onBlur={(event) => {
                                            addpointsofcontact_form.contact_phone_number.onBlur_out = true;
                                            this.setState({
                                                addpointsofcontact_form: addpointsofcontact_form,
                                            });
                                        }}
                                    />
                                    {!addpointsofcontact_form.contact_phone_number.valid && addpointsofcontact_form.contact_phone_number.onBlur_out ? (
                                        <div className="error field-error">{addpointsofcontact_form.contact_phone_number.error_msg}</div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className={"col-sm-12 mb-4 fv-row" + (!addpointsofcontact_form.contact_email.valid && addpointsofcontact_form.contact_email.onBlur_out ? " error-field" : "")}>
                                    <label className="form-label fs-6">{addpointsofcontact_form.contact_email.label}</label>
                                    <input
                                        type={addpointsofcontact_form.contact_email.type}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={addpointsofcontact_form.contact_email.placeholder}
                                        value={addpointsofcontact_form.contact_email.value ? addpointsofcontact_form.contact_email.value : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "contact_email")}
                                        onBlur={(event) => {
                                            addpointsofcontact_form.contact_email.onBlur_out = true;
                                            this.setState({
                                                addpointsofcontact_form: addpointsofcontact_form,
                                            });
                                        }}
                                    />
                                    {!addpointsofcontact_form.contact_email.valid && addpointsofcontact_form.contact_email.onBlur_out ? <div className="error field-error">{addpointsofcontact_form.contact_email.error_msg}</div> : ""}
                                </div>
                            </div>
                            <div className="d-flex align-items-center my-2 justify-content-end">
                                <button
                                    type="button"
                                    className="btn cancel-butn rounded-50 w-150px"
                                    onClick={() => {
                                        this.props.addpointsofcontact_modal_action(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary rounded-50 w-140px hvr-grow">
                                    Add
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddPointOfContactModal;