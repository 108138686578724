import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

let qs = require("qs");

/*** GetMyTaskStatus Action ***/
export const GetMyTaskStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetMyTaskStatus_START,
    };
};
export const GetMyTaskStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetMyTaskStatus_SUCCESS,
    };
};
export const GetMyTaskStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetMyTaskStatus_FAIL,
    };
};
export const GetMyTaskStatus = () => {
    return (dispatch) => {
        dispatch(GetMyTaskStatusStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetMyTaskStatus`,
        })
            .then(function (response) {
                dispatch(GetMyTaskStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetMyTaskStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetMyTasks Action ***/
export const GetMyTasksStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetMyTasks_START,
    };
};
export const GetMyTasksSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetMyTasks_SUCCESS,
    };
};
export const GetMyTasksFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetMyTasks_FAIL,
    };
};
export const GetMyTasks = (search, page, limit, sort, order, section_status) => {
    return (dispatch) => {
        dispatch(GetMyTasksStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetMyTasks`,
            params: {
                search: search,
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                section_status: section_status,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetMyTasksSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetMyTasksFail("Something went wrong, Please try again."));
                return error;
            });
    };
};