import React from "react";
import { Modal } from "react-bootstrap";

import Dropzone from "../../../../components/Utility/dropzone/Dropzone";
class UploadReturnableDocumentModal extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="upload_returnabledocument_modal bid-custom-modal"
                    show={this.props.upload_returnabledocument_modal}
                    onHide={() => {
                        this.props.upload_returnabledocument_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Upload</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-12 fv-row mb-4 mt-2 text-center">
                                <Dropzone
                                    multiple={false}
                                    isTender={false}
                                    // file_types={["application/pdf"]}
                                    // inputaccept={"application/pdf"}
                                    FileUploadHandleChange={(files) => {
                                        this.props.ReturnableDocumentHandle(files[0]);
                                    }}
                                />
                                <div className="d-flex align-items-center my-7 doc-border">
                                    <div className="border-bottom border-bottom-2 mw-50 w-100"></div>
                                    <span className="fw-bold text-gray-400 fs-6 mx-2">or</span>
                                    <div className="border-bottom border-bottom-2 mw-50 w-100"></div>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-lg rounded-50 me-2 hvr-grow"
                                    onClick={(event) => {
                                        this.props.SelectLibraryReturnableDocument(event);
                                    }}
                                >
                                    Select previous uploaded files
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default UploadReturnableDocumentModal;