import React, { useState, useEffect, useCallback } from "react";

import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";

import BidItems from "./BidItems";
import BidStatus from "./BidStatus";

import * as userActions from "../../../actions/index";

const BidOverview = (props) => {
    const dispatch = useDispatch();

    const [client_id] = useState(props.client_id);
    const [client_detail] = useState(props.client_detail);
    const [bids, setbids] = useState([]);
    const [bidcount, setbidcount] = useState(0);
    const [bidstatus_count, setbidstatus_count] = useState([]);

    const [bidstatus_all, setbidstatus_all] = useState(0);
    const [bidstatus_lost, setbidstatus_lost] = useState(0);
    const [bidstatus_won, setbidstatus_won] = useState(0);
    const [bidstatus_submitted, setbidstatus_submitted] = useState(0);
    const [bidstatus_inprogress, setbidstatus_inprogress] = useState(0);
    const [bidstatus_withdrawn, setbidstatus_withdrawn] = useState(0);

    const [filterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filtersort, setfiltersort] = useState("bid_id");
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersearch, setfiltersearch] = useState(null);
    const [filterbid_status, setfilterbid_status] = useState("all");

    const GetClientBidsStatusByClientId = useCallback(() => {
        dispatch(userActions.GetClientBidsStatusByClientId(client_id)).then((response) => {
            if (response.success === true) {
                let bid_count = 0;
                setbidstatus_count(response.data);
                for (let key in response.data) {
                    bid_count += response.data[key].count;
                    if (response.data[key].bid_status === "In progress") {
                        setbidstatus_inprogress(response.data[key].count);
                    }
                    if (response.data[key].bid_status === "Won") {
                        setbidstatus_won(response.data[key].count);
                    }
                    if (response.data[key].bid_status === "Submitted") {
                        setbidstatus_submitted(response.data[key].count);
                    }
                    if (response.data[key].bid_status === "Withdrawn") {
                        setbidstatus_withdrawn(response.data[key].count);
                    }
                    if (response.data[key].bid_status === "Lost") {
                        setbidstatus_lost(response.data[key].count);
                    }
                }
                setbidstatus_all(bid_count);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, client_id]);

    const GetClientBidsByClientId = useCallback(() => {
        dispatch(userActions.GetClientBidsByClientId(filtersearch, filterpage, filterlimit, filtersort, filterorder, filterbid_status, client_id)).then((response) => {
            if (response.success === true) {
                GetClientBidsStatusByClientId();
                setbids(response.data);
                setbidcount(response.count);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, GetClientBidsStatusByClientId, filtersearch, filterpage, filterlimit, filtersort, filterorder, filterbid_status, client_id]);

    useEffect(() => {
        GetClientBidsByClientId();
    }, [GetClientBidsByClientId, filterbid_status, filtersearch, filterpage, filterlimit, filtersort, filterorder]);

    const DesignPreviewGenratepdf = (bid_id) => {
        dispatch(userActions.DesignPreviewGenratepdf(bid_id)).then((response) => {
            if (response.success === true) {
                let pdf_url = response.pdf_url;
                const link = document.createElement("a");
                link.href = pdf_url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    return (
        <React.Fragment>
            <div className="client-bid-overview">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="border-0 p-0 mb-10">
                            <h3 className="d-flex card-title align-items-start flex-column">
                                <span className="card-label fw-bolder fs-2x mb-1 text-dark">Bids to {client_detail.client_name}</span>
                                
                            </h3>
                        </div>

                        <div className="card mb-3 mb-xl-3 bg-transparent all-users">
                            <BidStatus
                                bidcount={bidcount}
                                bidstatus_all={bidstatus_all}
                                bidstatus_count={bidstatus_count}
                                bidstatus_lost={bidstatus_lost}
                                bidstatus_won={bidstatus_won}
                                bidstatus_submitted={bidstatus_submitted}
                                bidstatus_inprogress={bidstatus_inprogress}
                                bidstatus_withdrawn={bidstatus_withdrawn}
                                propsbid_status={(bid_status) => {
                                    setfilterbid_status(bid_status);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <BidItems
                    bids={bids}
                    filterlimit={filterlimit}
                    bidcount={bidcount}
                    filterpage={filterpage}
                    propsfiltersort={(event) => setfiltersort(event)}
                    propsfilterpage={(event) => setfilterpage(event)}
                    propsfilterorder={(event) => setfilterorder(event)}
                    propsfiltersearch={(event) => setfiltersearch(event)}
                    DesignPreviewGenratepdf={(bid_id) => DesignPreviewGenratepdf(bid_id)}
                />
            </div>
        </React.Fragment>
    );
};
export default BidOverview;