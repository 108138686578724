import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

let qs = require("qs");

/*** GetReporting Action ***/
export const GetReportingStart = () => {
    return {
        error: false,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetReporting_START,
    };
};

export const GetReportingSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetReporting_SUCCESS,
    };
};

export const GetReportingFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetReporting_FAIL,
    };
};

export const GetReporting = (type, startDate, endDate) => {
    return (dispatch) => {
        dispatch(GetReportingStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetReporting`,
            params: {
                type: type,
                startDate: startDate,
                endDate: endDate,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetReportingSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetReportingFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

export const GetSuperadminDashboard = (fromDate, toDate) => {
    return (dispatch) => {
        dispatch(GetReportingStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetSuperadminDashboard`,
            params: {
                fromDate: fromDate,
                toDate: toDate,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetReportingSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetReportingFail("Something went wrong, Please try again."));
                return error;
            });
    };
};