import React from "react";
import { Modal } from "react-bootstrap";

import Dropzone from "../../../components/Utility/dropzone/Dropzone";
class UploadReturnableDocumentModal extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="upload_returnabledocument_modal bid-custom-modal"
                    show={this.props.upload_returnabledocument_modal}
                    onHide={() => {
                        this.props.upload_returnabledocument_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Upload</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className={"col-lg-12 fv-row mb-4"}>
                                <Dropzone
                                    multiple={false}
                                    isTender={false}
                                    // file_types={["application/pdf"]}
                                    // inputaccept={"application/pdf"}
                                    FileUploadHandleChange={(files) => {
                                        this.props.CreateLibraryReturnableDocument(files[0]);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="d-flex align-items-center my-2 justify-content-end">
                            <button
                                type="button"
                                className="btn cancel-butn rounded-50 w-150"
                                onClick={() => {
                                    this.props.upload_returnabledocument_modal_action(false);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default UploadReturnableDocumentModal;