import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { render } from "@testing-library/react";
import NumberFormat from "react-number-format";

import Collapse from "react-bootstrap/Collapse";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import BidContentPricingItems from "./BidContentPricingItems";
import UploadReturnableDocumentModal from "./Modal/UploadReturnableDocumentModal";
import LibraryReturnableDocumentModal from "./Modal/LibraryReturnableDocumentModal";

import * as userActions from "../../../actions/index";
import ConfirmDialog from "../../../components/Utility/ConfirmDialog";
import WysiwygEditor from "../../../components/Utility/WysiwygEditor";
class BidCreateTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            response_media: [],
            tender_media: [],
            response_content: [],
            WysiwygEditor_actiive: false,

            pricing_total: [],
            pricing_total_gst_amount: [],
            pricing_total_withoutGST: [],
            pricing_gst_percentagerate: 10,

            upload_returnabledocument_modal: false,

            library_returnabledocuments: [],
            library_returnabledocuments_modal: false,

            confirm_modal: false,
        };
        this.ConfirmDialogEvent = React.createRef();
    }

    componentDidMount() {
        let bid_response_media = this.props.bid_detail;
        let bid_response_form = this.props.bid_response_form;
        for (let bid_content_key in bid_response_form.response_content) {
            let response_content = bid_response_form.response_content[bid_content_key];

            let collapse_show = true;
            let local_collapse_show = localStorage.getItem(`bid_id-${response_content.bid_id}/section_id-${response_content.section_id}`);
            if (local_collapse_show) {
                collapse_show = local_collapse_show === "true" ? true : false;
            }

            bid_response_form.response_content[bid_content_key].title_editable = false;
            bid_response_form.response_content[bid_content_key].collapse_show = collapse_show;
        }

        let tender_media = [];
        let response_media = [];
        if (bid_response_media.response_media && bid_response_media.response_media.length) {
            for (const item of bid_response_media.response_media) {
                if (item.is_tender) {
                    tender_media.push(item);
                } else {
                    response_media.push(item);
                }
            }
        }
        let isFirstLaunch = false
        if (this.props.bid_detail && localStorage.getItem(`new-bid-${this.props.bid_detail.bid_uuid}`) == "true") {
            localStorage.setItem(`new-bid-${this.props.bid_detail.bid_uuid}`, "false")
            isFirstLaunch = true
        }

        this.setState(
            {
                isFirstLaunch: isFirstLaunch,
                bid_detail: this.props.bid_detail,
                response_media: response_media,
                tender_media: tender_media,
                response_content: bid_response_form.response_content,
                bid_section_selected: this.props.bid_section_selected,
            },
            function () {
                for (let bid_content_key in bid_response_form.response_content) {
                    if (bid_response_form.response_content[bid_content_key].is_pricing) {
                        this.CalculatePricing(bid_content_key);
                    }
                }
            }
        );
    }

    BidCreateTabReload() {
        let bid_response_media = this.props.bid_detail;
        let bid_response_form = this.props.bid_response_form;
        for (let bid_content_key in bid_response_form.response_content) {
            let response_content = bid_response_form.response_content[bid_content_key];

            let collapse_show = true;
            let local_collapse_show = localStorage.getItem(`bid_id-${response_content.bid_id}/section_id-${response_content.section_id}`);
            if (local_collapse_show) {
                collapse_show = local_collapse_show === "true" ? true : false;
            }

            bid_response_form.response_content[bid_content_key].title_editable = false;
            bid_response_form.response_content[bid_content_key].collapse_show = collapse_show;
        }

        let tender_media = [];
        let response_media = [];
        if (bid_response_media.response_media && bid_response_media.response_media.length) {
            for (const item of bid_response_media.response_media) {
                if (item.is_tender) {
                    tender_media.push(item);
                } else {
                    response_media.push(item);
                }
            }
        }

        this.setState(
            {
                confirm_modal: false,
                demo_content_available_show: false,
                bid_detail: this.props.bid_detail,
                response_media: response_media,
                tender_media: tender_media,
                response_content: bid_response_form.response_content,
                bid_section_selected: this.props.bid_section_selected,
            },
            function () {
                let bid_content = this.state.bid_section_selected;

                /*** MyTask Tab Active Start ***/
                const queryParams = new URLSearchParams(window.location.search);
                if (queryParams.get("activesection")) {
                    if (bid_content && bid_content !== undefined) {
                        let section_id = "bid_content_bid_id" + bid_content.bid_id + "_section_id" + bid_content.section_id;
                        setTimeout(() => {
                            const SectionElement = document.getElementById(section_id);
                            SectionElement.scrollIntoView({
                                block: "center",
                                behavior: "smooth",
                            });
                        }, 0);
                        let current_url = window.location.href;
                        window.history.pushState({}, "", current_url.split("?")[0]);
                    }
                }
                /*** MyTask Tab Active End ***/

                for (let bid_content_key in bid_response_form.response_content) {
                    if (bid_response_form.response_content[bid_content_key].is_pricing) {
                        this.CalculatePricing(bid_content_key);
                    }
                }
            }
        );
    }

    inputChangeHandlerWysiwygEditor(event, identifier) {
        // data should be saved iff section is selected or not
        // if (this.state.WysiwygEditor_actiive) {
        let response_content = this.state.response_content;

        response_content[identifier].description = event;

        this.setState({ response_content: response_content });
        this.props.BidSectionUpdate(response_content[identifier], identifier);
        // }
    }

    inputChangeHandler(event, identifier) {
        let response_content = this.state.response_content;

        response_content[identifier].description = event.target.value;

        this.setState({ response_content: response_content });
        this.props.BidSectionUpdate(response_content[identifier], identifier);
    }

    onFileChange(event, identifier) {
        event.preventDefault();
        this.props.BidUploadDocument(event.target.files[0]);
    }

    handleOnDragEnd = (result) => {
        if (result.destination) {
            const response_contents = Array.from(this.state.response_content);
            const [reorderedItem] = response_contents.splice(result.source.index, 1);
            response_contents.splice(result.destination.index, 0, reorderedItem);
            this.props.BidSectionShortingUpdate(response_contents);
            this.setState({ response_content: response_contents });
        }
    };

    CheckBidSectionUserAccess = (bid_content) => {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        if (user_info.user.role.user_role !== "Account owner" && user_info.user.role.user_role !== "Bid Manager") {
            let section_users = [];
            if (bid_content.response_user) {
                if (bid_content.response_user.section_teams !== null) {
                    let section_teams = bid_content.response_user.section_teams;
                    for (let section_team_key in section_teams) {
                        section_users.push(section_teams[section_team_key].user_id);
                    }
                }

                if (bid_content.response_user.section_owners !== null) {
                    let section_owners = bid_content.response_user.section_owners;
                    for (let section_owner_key in section_owners) {
                        section_users.push(section_owners[section_owner_key].user_id);
                    }
                }
            }
            if (user_info.user.role.user_role === "Guest") {
                return section_users.includes(user_info.user.user_id) ? "disable_overlay" : "displaynone ";
            } else {
                return section_users.includes(user_info.user.user_id) ? "" : "disable_overlay ";
            }
        } else {
            return user_info.user.role.user_role.replace(" ", "_").toLowerCase();
        }
    };

    GetLibraryReturnableDocuments() {
        this.props.onGetLibraryReturnableDocuments().then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        library_returnabledocuments: response.data,
                    },
                    function () {
                        this.setState({
                            library_returnabledocuments_modal: true,
                        });
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    AddBidContentPricingItem = async (event, bid_content) => {
        event.preventDefault();

        const pricing_form = {
            bid_id: bid_content.bid_id,
            company_id: bid_content.company_id,
            section_id: bid_content.section_id,
            pricing_item: "",
            pricing_unit: "",
            pricing_qty: "",
            pricing_amount: "",
        };

        this.props.onCreateBidResponsePricing(pricing_form).then((response) => {
            if (response.success === true) {
                this.props.RefreshBidCreateTab();
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    RemoveBidContentPricing = async (event, bid_content_key, pricing_detail, pricing_index) => {
        render(
            <ConfirmDialog
                title={"Remove " + pricing_detail.pricing_item + " ?"}
                body="Are you sure you want to remove this item?"
                confirmButtonText="Remove"
                confirm_modal={this.state.confirm_modal}
                ref={(ref) => (this.ConfirmDialogEvent = ref)}
                onConfirm={(event) => this.ConfirmRemoveBidContentPricing(event, bid_content_key, pricing_detail, pricing_index)}
                confirm_modal_action={(modal_action) => {
                    this.setState({ confirm_modal: modal_action });
                    this.ConfirmDialogEvent.ModalActionHide();
                }}
            />
        );
    };

    ConfirmRemoveBidContentPricing = (event, bid_content_key, pricing_detail, pricing_index) => {
        this.props.onRemoveBidContentPricing(pricing_detail.response_pricing_id).then((response) => {
            if (response.success === true) {
                this.props.RefreshBidCreateTab();
                this.ConfirmDialogEvent.ModalActionHide();
                this.setState({ confirm_modal: false });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    inputChangeHandlerPricingItem = (event, field_type, bid_content_key, pricing_detail, pricing_index) => {
        const response_content = [...this.state.response_content];

        response_content[bid_content_key]["response_pricings"][pricing_index][field_type] = event.target.value;

        this.setState({ response_content: response_content }, function () {
            this.CalculatePricing(bid_content_key, pricing_detail, pricing_index);
        });
    };

    CalculatePricing = (bid_content_key, pricing_detail, pricing_index) => {
        let pricing_total = 0;
        let pricing_total_gst_amount = 0;
        let pricing_total_withoutGST = 0;
        const response_content = [...this.state.response_content];

        if (response_content.length > 0) {
            let pricing_response = response_content[bid_content_key];
            let response_pricings = response_content[bid_content_key]["response_pricings"];

            for (let pricing_key in response_pricings) {
                let response_pricing = response_pricings[pricing_key];
                if (response_pricing.pricing_unit && response_pricing.pricing_qty) {
                    let pricing_amount = parseFloat(response_pricing.pricing_unit) * response_pricing.pricing_qty;
                    pricing_total += parseFloat(pricing_amount);
                    response_pricings[pricing_key].pricing_amount = pricing_amount.toFixed(2);
                } else {
                    response_pricings[pricing_key].pricing_amount = "";
                }
            }
            pricing_total_withoutGST = pricing_total;
            response_content[bid_content_key]["response_pricings"] = response_pricings;

            // CalculatePricing Gst Start
            let pricing_gst = pricing_response.pricing_gst;
            if (pricing_gst === "true") {
                let pricing_gst_percentagerate = this.state.pricing_gst_percentagerate;
                let pricinggst_amount = (parseFloat(pricing_total) * parseFloat(pricing_gst_percentagerate)) / 100;
                pricing_total_gst_amount = pricinggst_amount;
                pricing_total = parseFloat(pricing_total) + parseFloat(pricinggst_amount);
            }
            // CalculatePricing Gst End

            let all_pricing_total = this.state.pricing_total || [];
            all_pricing_total[bid_content_key] = pricing_total.toFixed(2);

            let all_pricing_total_withoutGST = this.state.pricing_total_withoutGST || [];
            all_pricing_total_withoutGST[bid_content_key] = pricing_total_withoutGST.toFixed(2);

            let all_pricing_total_gst_amount = this.state.pricing_total_gst_amount || [];
            all_pricing_total_gst_amount[bid_content_key] = pricing_total.toFixed(2);

            this.setState(
                {
                    pricing_total: all_pricing_total,
                    response_content: response_content,
                    pricing_total_gst_amount: all_pricing_total_gst_amount,
                    pricing_total_withoutGST: all_pricing_total_withoutGST,
                },
                function () {
                    this.UpdateBidResponsePricing(bid_content_key, pricing_detail, pricing_index);
                }
            );
        }
    };

    UpdateBidResponsePricing = async (bid_content_key, pricing_detail, pricing_index) => {
        const response_content = [...this.state.response_content];

        if (response_content[bid_content_key]["response_pricings"] && response_content[bid_content_key]["response_pricings"][pricing_index]) {
            let pricing_items = response_content[bid_content_key]["response_pricings"][pricing_index];
            this.props.onUpdateBidResponsePricing(pricing_items).then((response) => {
                if (response.success === true) {
                    // notify.show(response.message, "success");
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    UpdateBidResponsePricingGST = (event, bid_content_key, bid_content) => {
        let pricing_gst = event.target.checked ? true : false;
        let response_content = {
            company_id: bid_content.company_id,
            bid_id: bid_content.bid_id,
            section_id: bid_content.section_id,
            pricing_gst: pricing_gst,
        };
        this.props.onUpdateBidResponsePricingGST(response_content).then((response) => {
            if (response.success === true) {
                this.props.RefreshBidCreateTab();
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    render() {
        const { isFirstLaunch, bid_detail, response_content, response_media } = this.state;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                {this.state.upload_returnabledocument_modal && (
                    <UploadReturnableDocumentModal
                        upload_returnabledocument_modal={this.state.upload_returnabledocument_modal}
                        ReturnableDocumentHandle={(files) => {
                            this.setState(
                                {
                                    upload_returnabledocument_modal: false,
                                },
                                function () {
                                    this.props.BidUploadDocument(files);
                                }
                            );
                        }}
                        SelectLibraryReturnableDocument={(event) => {
                            this.setState(
                                {
                                    upload_returnabledocument_modal: false,
                                },
                                function () {
                                    this.GetLibraryReturnableDocuments();
                                }
                            );
                        }}
                        upload_returnabledocument_modal_action={(modal_action) => {
                            this.setState({
                                upload_returnabledocument_modal: modal_action,
                            });
                        }}
                    />
                )}

                {this.state.library_returnabledocuments_modal && (
                    <LibraryReturnableDocumentModal
                        library_returnabledocuments={this.state.library_returnabledocuments}
                        library_returnabledocuments_modal={this.state.library_returnabledocuments_modal}
                        BidUpdateLibraryReturnableDocument={(selected_returnabledocuments) => {
                            this.setState(
                                {
                                    library_returnabledocuments_modal: false,
                                },
                                function () {
                                    this.props.BidUpdateLibraryReturnableDocument(selected_returnabledocuments);
                                }
                            );
                        }}
                        library_returnabledocuments_modal_action={(modal_action) => {
                            this.setState({
                                library_returnabledocuments_modal: modal_action,
                            });
                        }}
                    />
                )}

                <div className="row">
                    <div className="col-lg-12">
                        <div className="response_content_section" ref={this.props.section_select_ref}>
                            <DragDropContext onDragEnd={(event) => this.handleOnDragEnd(event)}>
                                <Droppable droppableId="response_content">
                                    {(provided) => (
                                        <div
                                            className={"response_content" + (bid_detail?.bid_lock ? " bid_locked_overlay" : "") + (bid_detail?.bid_status && bid_detail?.bid_status === "Won" ? " bid_state_won_overlay" : "")}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {response_content &&
                                                response_content.map((bid_content, bid_content_key) => {
                                                    return (
                                                        <Draggable
                                                            key={"result_" + bid_content.section_id}
                                                            draggableId={"result_" + bid_content.sort_order}
                                                            index={bid_content_key}
                                                            isDragDisabled={user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" ? false : true}
                                                        >
                                                            {(provided) => (
                                                                <div
                                                                    className={
                                                                        "card mb-3 " +
                                                                        (this.state.bid_section_selected && this.state.bid_section_selected.section_id === bid_content.section_id ? "card_active " : "") +
                                                                        this.CheckBidSectionUserAccess(bid_content)
                                                                    }
                                                                    id={"bid_content_bid_id" + bid_content.bid_id + "_section_id" + bid_content.section_id}
                                                                    key={"bid_content_bid_id" + bid_content.bid_id + "_section_id" + bid_content.section_id}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <div className="card-header card-header-edit border-0 pt-5 mb-5">
                                                                        <div className="card-header-left">
                                                                            <span
                                                                                className={`bid_section_selected_handle_false bid_section_card_header_left ${bid_content?.collapse_show ? "collapse_show" : "collapse_hide"}`}
                                                                                onClick={(event) => {
                                                                                    for (let key in response_content) {
                                                                                        if (response_content[key].section_id === bid_content.section_id) {
                                                                                            response_content[key].collapse_show = bid_content?.collapse_show === true ? false : true;
                                                                                        }
                                                                                    }
                                                                                    this.setState({ response_content: response_content }, function () {
                                                                                        localStorage.setItem(`bid_id-${bid_content.bid_id}/section_id-${bid_content.section_id}`, bid_content?.collapse_show);
                                                                                    });
                                                                                }}
                                                                            >
                                                                                {bid_content.title}
                                                                            </span>
                                                                            <h3 className="card-title align-items-start flex-column">
                                                                                {bid_content.title_editable === false ? (
                                                                                    <span className="card-label fw-bold-700 fs-2mx mb-1 text-dark">
                                                                                        {bid_content.title}
                                                                                        <span
                                                                                            className="btn-icon ms-1"
                                                                                            onClick={(event) => {
                                                                                                for (let key in response_content) {
                                                                                                    if (response_content[key].section_id === bid_content.section_id) {
                                                                                                        response_content[key].title_editable = true;
                                                                                                    }
                                                                                                }
                                                                                                this.setState({ response_content: response_content });
                                                                                            }}
                                                                                        >
                                                                                            <i className="icon-edit"></i>
                                                                                        </span>
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className={"card-label fw-bolder fs-4 mb-1 text-pink" + (!bid_content.valid && bid_content.onBlur_out ? " error-field" : "")}>
                                                                                        <input
                                                                                            type="text"
                                                                                            value={bid_content.title}
                                                                                            placeholder="Search for bid..."
                                                                                            className="form-control form-control-sm edit-input"
                                                                                            onChange={(event) => {
                                                                                                for (let key in response_content) {
                                                                                                    if (response_content[key].section_id === bid_content.section_id) {
                                                                                                        response_content[key].title = event.target.value;
                                                                                                    }
                                                                                                }
                                                                                                this.setState({ response_content: response_content });
                                                                                            }}
                                                                                        />

                                                                                        <span
                                                                                            className="btn-icon ms-1"
                                                                                            onClick={(event) => {
                                                                                                for (let key in response_content) {
                                                                                                    if (response_content[key].section_id === bid_content.section_id) {
                                                                                                        response_content[key].title_editable = false;
                                                                                                    }
                                                                                                }
                                                                                                this.setState({ response_content: response_content });
                                                                                            }}
                                                                                        >
                                                                                            <i className="icon-cross-2"></i>
                                                                                        </span>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-btn rounded-50 btn-outline-primary btn-sm hvr-grow"
                                                                                            onClick={(event) => {
                                                                                                for (let key in response_content) {
                                                                                                    if (response_content[key].section_id === bid_content.section_id) {
                                                                                                        response_content[key].title_editable = false;
                                                                                                    }
                                                                                                }
                                                                                                this.setState({ response_content: response_content }, function () {
                                                                                                    this.props.BidSectionUpdate(response_content[bid_content_key]);
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            Save
                                                                                        </button>
                                                                                    </span>
                                                                                )}
                                                                            </h3>
                                                                        </div>
                                                                        <div className="card-header-right">
                                                                            <div className="card-toolbar">
                                                                                {bid_content.bidtype_content && bid_content.bidtype_content.demo_content && (
                                                                                    <div className="custom-tooltip tooltip-icons">
                                                                                        <div className="dropdown">
                                                                                            <div className={"curser_pointer" + (bid_content.demo_content_available_show ? " show" : "")}>
                                                                                                <span
                                                                                                    className="btn-icon tooltip-btn"
                                                                                                    onClick={(event) => {
                                                                                                        for (let key in response_content) {
                                                                                                            if (response_content[key].section_id === bid_content.section_id) {
                                                                                                                response_content[key].demo_content_available_show = true;
                                                                                                            }
                                                                                                        }
                                                                                                        this.setState({
                                                                                                            response_content: response_content,
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="icon-demo-content"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className={"dropdown-menu demo-out" + (bid_content.demo_content_available_show ? " show" : "")}>
                                                                                                <div className="dropdown-item w-275px demo-content-dropdown">
                                                                                                    <i className="icon-demo-content"></i>
                                                                                                    <h3 className="color1 fw-bold-700 fs-14">Demo content available</h3>
                                                                                                    <p className="fs-14">
                                                                                                        Use demo content from BidHQ
                                                                                                        <br /> as a template to get started.
                                                                                                    </p>
                                                                                                    <div className="d-flex">
                                                                                                        <span
                                                                                                            className="btn rounded-50 hvr-grow content-btn me-2"
                                                                                                            onClick={(event) => {
                                                                                                                for (let key in response_content) {
                                                                                                                    if (response_content[key].section_id === bid_content.section_id) {
                                                                                                                        response_content[key].demo_content_available_show = false;
                                                                                                                    }
                                                                                                                }
                                                                                                                this.setState({
                                                                                                                    response_content: response_content,
                                                                                                                });
                                                                                                            }}
                                                                                                        >
                                                                                                            No, thanks
                                                                                                        </span>
                                                                                                        <span
                                                                                                            className="btn rounded-50 btn-primary hvr-grow add-content-btn"
                                                                                                            onClick={(event) => {
                                                                                                                for (let key in response_content) {
                                                                                                                    if (response_content[key].section_id === bid_content.section_id) {
                                                                                                                        response_content[key].demo_content_available_show = false;
                                                                                                                    }
                                                                                                                }
                                                                                                                this.setState({ response_content: response_content }, function () {
                                                                                                                    this.props.BidtypeDemoContentAvailable(event, bid_content, bid_content_key);
                                                                                                                });
                                                                                                            }}
                                                                                                        >
                                                                                                            Add content
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                <div className="custom-tooltip tooltip-icons">
                                                                                    <span
                                                                                        className="btn-icon tooltip-btn"
                                                                                        onClick={(event) => {
                                                                                            this.props.ConfirmSavetoLibrary(event, bid_content);
                                                                                        }}
                                                                                    >
                                                                                        <i className="icon-book"></i>
                                                                                        <span className="custom-tooltip-text ">Save to library</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="custom-tooltip tooltip-icons">
                                                                                    <span className="btn-icon tooltip-btn">
                                                                                        <i className="icon-move"></i>
                                                                                        <span className="custom-tooltip-text ">Move section</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="custom-tooltip tooltip-icons">
                                                                                    <span className="btn-icon tooltip-btn" onClick={(event) => this.props.BidSectionRemove(event, bid_content)}>
                                                                                        <i className="icon-delete" aria-hidden="true"></i>
                                                                                        <span className="custom-tooltip-text ">Delete section</span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <Collapse in={bid_content?.collapse_show}>
                                                                        <div className="content_section">
                                                                            {bid_content.is_pricing ? (
                                                                                <div className="card-body pt-0 pricing_section">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12">
                                                                                            <h4 className="fs-14">Description</h4>
                                                                                            <WysiwygEditor
                                                                                                bid_detail={this.props.bid_detail}
                                                                                                bid_content={bid_content}
                                                                                                tagusers={this.props.bid_users}
                                                                                                value={bid_content.description}
                                                                                                showAIPrompt={isFirstLaunch && bid_content_key == 0 ? true : false}
                                                                                                placeholder="Write something or press ‘/’ for BidAI assistance... "
                                                                                                onFirstBidAILaunch={() => {
                                                                                                    this.setState({ isFirstLaunch: false });
                                                                                                }}
                                                                                                propsBidSectionUpdateTagUsers={(user_detail) => {
                                                                                                    this.props.BidSectionUpdateTagUsers(user_detail, bid_content_key, bid_content);
                                                                                                }}
                                                                                                onClick={(event) => {
                                                                                                    this.setState({ WysiwygEditor_actiive: true });
                                                                                                    this.props.BidSectionSelected(bid_content_key, bid_content);
                                                                                                }}
                                                                                                onChange={(event) => this.inputChangeHandlerWysiwygEditor(event, bid_content_key)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mt-4">
                                                                                        <div className="col-sm-12">
                                                                                            <div className="table-responsive">
                                                                                                <table className="table table-row-gray-300 align-middle custom-table-style">
                                                                                                    <thead>
                                                                                                        <tr className="price-row-bg color1">
                                                                                                            <th scope="col">No.</th>
                                                                                                            <th scope="col">Item</th>
                                                                                                            <th scope="col">Rate/Unit ($)</th>
                                                                                                            <th scope="col">Qty</th>
                                                                                                            <th scope="col">Amount</th>
                                                                                                            <th scope="col"></th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    {bid_content.response_pricings && bid_content.response_pricings.length > 0 && (
                                                                                                        <tbody>
                                                                                                            {bid_content.response_pricings.map((pricing_detail, pricing_index) => (
                                                                                                                <BidContentPricingItems
                                                                                                                    key={pricing_index}
                                                                                                                    index={pricing_index}
                                                                                                                    pricing_detail={pricing_detail}
                                                                                                                    inputChangeHandler={(event, field_type) =>
                                                                                                                        this.inputChangeHandlerPricingItem(event, field_type, bid_content_key, pricing_detail, pricing_index)
                                                                                                                    }
                                                                                                                    propsRemoveBidContentPricing={(event) => {
                                                                                                                        this.setState({ confirm_modal: true }, function () {
                                                                                                                            this.RemoveBidContentPricing(event, bid_content_key, pricing_detail, pricing_index);
                                                                                                                        });
                                                                                                                    }}
                                                                                                                />
                                                                                                            ))}
                                                                                                        </tbody>
                                                                                                    )}
                                                                                                </table>
                                                                                            </div>
                                                                                            <div
                                                                                                className="text-gray-400 fs-6 text-main-color curser_pointer mb-3 d-inline-flex"
                                                                                                onClick={(event) => this.AddBidContentPricingItem(event, bid_content)}
                                                                                            >
                                                                                                <span className="b-icon-weight add-txt-new">
                                                                                                    <i className="bi bi-plus text-main-color"></i>
                                                                                                    <p className="m-0 ms-1 fw-bold-600">Add item</p>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mt-4">
                                                                                        <div className="col-sm-12">
                                                                                            <div className="align-items-center gst-new-txt">
                                                                                                <div className="justify-content-end d-flex align-items-center mt-3">
                                                                                                    <h4 className="fs-14 mb-0 me-8">GST ({this.state.pricing_gst_percentagerate}%)</h4>
                                                                                                    <div className="form-check form-switch total-price-gst">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input"
                                                                                                            checked={bid_content.pricing_gst === "true" ? true : false}
                                                                                                            onChange={(event) => this.UpdateBidResponsePricingGST(event, bid_content_key, bid_content)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>

                                                                                                {bid_content.pricing_gst === "true" && (
                                                                                                    <div className="total-price-gst">
                                                                                                        <div className="justify-content-end d-flex align-items-center mt-3">
                                                                                                            <h4 className="fs-14 mb-0 ">Sub Total </h4>
                                                                                                            <span className="color1 total-price-gst">
                                                                                                                <NumberFormat
                                                                                                                    prefix="$"
                                                                                                                    displayType="text"
                                                                                                                    thousandSeparator={true}
                                                                                                                    value={this.state.pricing_total_withoutGST[bid_content_key]}
                                                                                                                />
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="justify-content-end d-flex align-items-center mt-3">
                                                                                                            <h4 className="fs-14 mb-0 ">GST </h4>
                                                                                                            <span className="color1 total-price-gst">
                                                                                                                <NumberFormat
                                                                                                                    prefix="$"
                                                                                                                    displayType="text"
                                                                                                                    thousandSeparator={true}
                                                                                                                    value={(
                                                                                                                        this.state.pricing_total_gst_amount[bid_content_key] - this.state.pricing_total_withoutGST[bid_content_key]
                                                                                                                    ).toFixed(2)}
                                                                                                                />
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}

                                                                                                <div className="justify-content-end d-flex align-items-center mt-3 total-price-gst">
                                                                                                    <h4 className="fs-14 mb-0 fw-bold-700">Total </h4>
                                                                                                    <span className="color1 fw-bold-700 total-price-gst grand-total-price">
                                                                                                        <NumberFormat prefix="$" displayType="text" thousandSeparator={true} value={this.state.pricing_total[bid_content_key]} />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mt-4">
                                                                                        <div className="col-md-8">
                                                                                            <span className="text-gray-400 fs-8 fw-normal">
                                                                                                Last edited at {moment(bid_content.updated_date).format("h:mm a, DD/MM/YYYY")} by{" "}
                                                                                                {bid_content.updated_user ? bid_content.updated_user.first_name + " " + bid_content.updated_user.last_name : ""}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-4 text-right">
                                                                                            <span className="text-gray-400 fs-8 fw-normal">
                                                                                                Autosaved{" "}
                                                                                                <span className="timeline-badge">
                                                                                                    <i className="fa fa-circle text-success ms-2" aria-hidden="true"></i>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="card-body pt-0">
                                                                                    <div className="row mt-4">
                                                                                        <WysiwygEditor
                                                                                            bid_detail={this.props.bid_detail}
                                                                                            tagusers={this.props.bid_users}
                                                                                            bid_content={bid_content}
                                                                                            value={bid_content.description}
                                                                                            showAIPrompt={isFirstLaunch && bid_content_key == 0 ? true : false}
                                                                                            placeholder="Write something or press ‘/’ for BidAI assistance... "
                                                                                            propsBidSectionUpdateTagUsers={(user_detail) => {
                                                                                                this.props.BidSectionUpdateTagUsers(user_detail, bid_content_key, bid_content);
                                                                                            }}
                                                                                            onClick={(event) => {
                                                                                                this.setState({ WysiwygEditor_actiive: true });
                                                                                                this.props.BidSectionSelected(bid_content_key, bid_content);
                                                                                            }}
                                                                                            onFirstBidAILaunch={() => {
                                                                                                this.setState({ isFirstLaunch: false });
                                                                                            }}
                                                                                            onChange={(event) => this.inputChangeHandlerWysiwygEditor(event, bid_content_key)}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="row mt-4">
                                                                                        <div className="col-md-8">
                                                                                            <span className="text-gray-400 fs-8 fw-normal">
                                                                                                Last edited at {moment(bid_content.updated_date).format("h:mm a, DD/MM/YYYY")} by{" "}
                                                                                                {bid_content.updated_user ? bid_content.updated_user.first_name + " " + bid_content.updated_user.last_name : ""}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-4 text-right">
                                                                                            <span className="text-gray-400 fs-8 fw-normal">
                                                                                                Autosaved{" "}
                                                                                                <span className="timeline-badge">
                                                                                                    <i className="fa fa-circle text-success ms-2" aria-hidden="true"></i>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </Collapse>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && bid_detail?.bid_lock === false && (
                            <div
                                className="text-gray-400 fs-6 text-pink curser_pointer mb-3"
                                onClick={() => {
                                    this.props.bid_insert_section_action(true);
                                }}
                            >
                                <span className="b-icon-weight insert-txt">
                                    <i className="bi bi-plus text-pink"></i> <p className="m-0 ms-2 fw-bold-600">Insert a new section</p>
                                </span>
                            </div>
                        )}

                        <div
                            className={
                                "card mb-3 bid_document_section" +
                                (user_info.user.role.user_role === "Guest" ? " displaynone" : "") +
                                (bid_detail?.bid_lock ? " bid_locked_overlay" : "") +
                                (bid_detail?.bid_status && bid_detail?.bid_status === "Won" ? " bid_state_won_overlay" : "")
                            }
                        >
                            <div className="card-header border-bottom-2 pt-5 mb-6">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fs-6 mb-1 text-orange">Returnable documents and attachments</span>
                                    <button
                                        className="btn btn-secondary btn-sm rounded-50 mt-2 mb-4 hvr-grow"
                                        onClick={(event) => {
                                            this.setState({
                                                upload_returnabledocument_modal: true,
                                            });
                                        }}
                                    >
                                        {response_media.length !== 0 ? "Add another document" : "Add document"}
                                    </button>
                                </h3>
                                <div className="card-toolbar">
                                    <div className="custom-tooltip tooltip-icons">
                                        <span className="btn-icon tooltip-btn">
                                            <i className="icon-book"></i>
                                            <span className="custom-tooltip-text ">Save to library</span>
                                        </span>
                                    </div>
                                    <div className="custom-tooltip tooltip-icons">
                                        <span className="btn-icon tooltip-btn">
                                            <i className="icon-move"></i>
                                            <span className="custom-tooltip-text ">Move section</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="upload-document-outer">
                                    {response_media.length !== 0 &&
                                        response_media.map((media, media_key) => {
                                            return (
                                                <div className="bid_response_document" key={"bid_document_key_" + media.response_media_id}>
                                                    <div className="row">
                                                        <div className="col-sm-10">
                                                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                <img src="assets/images/icons/successfully.svg" title="all_bids" alt="all_bids" />
                                                                <div className="card-title align-items-start flex-column ms-4 d-flex flex-grow-1">
                                                                    {media.media_title_editable ? (
                                                                        <span className="btn-icon flex-grow-1 w-100 d-flex">
                                                                            <input
                                                                                type="text"
                                                                                value={media?.avatar_title}
                                                                                className="form-control form-control-sm edit-input"
                                                                                onChange={(event) => {
                                                                                    for (let key in response_media) {
                                                                                        if (response_media[key].response_media_id === media.response_media_id) {
                                                                                            response_media[key].avatar_title = event.target.value;
                                                                                        }
                                                                                    }
                                                                                    this.setState({ response_media: response_media });
                                                                                }}
                                                                            />
                                                                            <span
                                                                                className="btn-icon ms-1"
                                                                                onClick={(event) => {
                                                                                    for (let key in response_media) {
                                                                                        if (response_media[key].response_media_id === media.response_media_id) {
                                                                                            response_media[key].media_title_editable = false;
                                                                                        }
                                                                                    }
                                                                                    this.setState({ response_media: response_media });
                                                                                }}
                                                                            >
                                                                                <i className="icon-cross-2"></i>
                                                                            </span>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-btn rounded-50 btn-outline-primary btn-sm hvr-grow"
                                                                                onClick={(event) => {
                                                                                    for (let key in response_media) {
                                                                                        if (response_media[key].response_media_id === media.response_media_id) {
                                                                                            response_media[key].media_title_editable = false;
                                                                                        }
                                                                                    }
                                                                                    this.setState(
                                                                                        {
                                                                                            response_media: response_media,
                                                                                        },
                                                                                        function () {
                                                                                            this.props.BidDocumentTitleUpdate(
                                                                                                response_media[media_key].bid_id,
                                                                                                response_media[media_key].response_media_id,
                                                                                                response_media[media_key].avatar_title
                                                                                            );
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Save
                                                                            </button>
                                                                        </span>
                                                                    ) : (
                                                                        <span className="success-title card-label fw-bold-700 fs-6 ">
                                                                            {media?.avatar_title}
                                                                            <span
                                                                                className="btn-icon ms-1"
                                                                                onClick={(event) => {
                                                                                    for (let key in response_media) {
                                                                                        if (response_media[key].response_media_id === media.response_media_id) {
                                                                                            response_media[key].media_title_editable = true;
                                                                                        }
                                                                                    }
                                                                                    this.setState({ response_media: response_media });
                                                                                }}
                                                                            >
                                                                                <i className="icon-edit"></i>
                                                                            </span>
                                                                        </span>
                                                                    )}
                                                                    <span className="mt-1 fw-normal text-gray fs-7">
                                                                        {userActions.bytesToSize(media?.media_meta?.media_size)} | Successfully uploaded
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2 text-end">
                                                            <span
                                                                className="del-btn-icon"
                                                                onClick={(event) => {
                                                                    this.props.BidRemoveDocument(event, media);
                                                                }}
                                                            >
                                                                <i className="icon-delete"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onCreateBidResponsePricing: userActions.CreateBidResponsePricing,
    onUpdateBidResponsePricing: userActions.UpdateBidResponsePricing,
    onRemoveBidContentPricing: userActions.RemoveBidContentPricing,
    onUpdateBidResponsePricingGST: userActions.UpdateBidResponsePricingGST,
    onGetLibraryReturnableDocuments: userActions.GetLibraryReturnableDocuments,
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BidCreateTab);