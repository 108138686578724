import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

import Validations from "../../../components/Utility/Validations";
class DowngradeSubscriptionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            downgrade_subscription_form: {
                email_subject: {
                    type: "text",
                    label: "Subject",
                    value: "Cancel or downgrade subscription",
                    valid: true,
                    error_msg: "",
                    placeholder: "",
                    validations: { required: true },
                },
                email_message: {
                    value: "",
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "Message",
                    placeholder: "",
                    validations: { required: true },
                },
            },
        };
    }

    inputChangeHandler(event, identifier) {
        const update_downgrade_subscription_form = { ...this.state.downgrade_subscription_form };
        const form_element = { ...update_downgrade_subscription_form[identifier] };

        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_downgrade_subscription_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_downgrade_subscription_form) {
            is_form_valid = update_downgrade_subscription_form[identifier].valid && is_form_valid;
        }

        this.setState({
            downgrade_subscription_form: update_downgrade_subscription_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_downgrade_subscription_form = this.state.downgrade_subscription_form;
        for (let key in update_downgrade_subscription_form) {
            let form_element = update_downgrade_subscription_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_downgrade_subscription_form[key] = form_element;
        }
        this.setState({ downgrade_subscription_form: update_downgrade_subscription_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.downgrade_subscription_form) {
                form_data[key] = this.state.downgrade_subscription_form[key].value;
            }
            this.props.DowngradeSubscriptionSubmit(form_data);
        }
    };

    render() {
        const { downgrade_subscription_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    className="need_help_modal bid-custom-modal  text-center"
                    show={this.props.downgrade_subscription_modal}
                    onHide={() => {
                        this.props.downgrade_subscription_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <p className="mb-7 help-txt1">No worries, we're here to help.</p>
                        <p className="mb-4 need-txt">Please let us know why you'd like to cancel your subscription, and one of our team will be in contact to help you.</p>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="d-flex flex-column">
                                <div className="row divide-colum">
                                    <div className="col-lg-12 right-colum pt-5">
                                        <div className={"d-flex flex-column mb-7 fv-row" + (!downgrade_subscription_form.email_message.valid && downgrade_subscription_form.email_message.onBlur_out ? " error-field" : "")}>
                                            <div className="position-relative">
                                                <input
                                                    type={downgrade_subscription_form.email_message.type}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={downgrade_subscription_form.email_message.placeholder}
                                                    value={downgrade_subscription_form.email_message.value ? downgrade_subscription_form.email_message.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "email_message")}
                                                    onBlur={(event) => {
                                                        downgrade_subscription_form.email_message.onBlur_out = true;
                                                        this.setState({
                                                            downgrade_subscription_form: downgrade_subscription_form,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            {!downgrade_subscription_form.email_message.valid && downgrade_subscription_form.email_message.onBlur_out ? (
                                                <div className="error field-error">{downgrade_subscription_form.email_message.error_msg}</div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="submit" className="btn light-btn-info btn-info rounded-50 px-10 hvr-grow contct-button">
                                                Contact support
                                            </button>
                                            <Link to="/dashboard" className="return-home my-4">
                                                Nevermind! Return to dashboard.
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default DowngradeSubscriptionModal;