import React from "react";
import Notifications from "react-notify-toast";

import CompanyLists from "../../components/SuperAdmin/Users/CompanyLists.js";
import CompanyCreate from "../../components/SuperAdmin/Users/CompanyCreate.js";
import CompanyUpdate from "../../components/SuperAdmin/Users/CompanyUpdate.js";

import CompanyUsersList from "../../components/SuperAdmin/Users/CompanyUsersList.js";
import CompanyUserAdd from "../../components/SuperAdmin/Users/CompanyUserAdd.js";
import CompanyUserUpdate from "../../components/SuperAdmin/Users/CompanyUserUpdate.js";

import IndividualUsersList from "../../components/SuperAdmin/Users/IndividualUsersList.js";

class Users extends React.Component {
    render() {
        const renderview = () => {
            if (this.props.match.path === "/superadmin/company-lists") {
                return <CompanyLists />;
            }
            if (this.props.match.path === "/superadmin/add-company") {
                return <CompanyCreate />;
            }
            if (this.props.match.path === "/superadmin/update-company/:id") {
                return <CompanyUpdate company_id={this.props.match.params.id} />;
            }

            if (this.props.match.path === "/superadmin/company-users/:id") {
                return <CompanyUsersList company_id={this.props.match.params.id} />;
            }
            if (this.props.match.path === "/superadmin/add-company-users/:id") {
                return <CompanyUserAdd company_uud={this.props.match.params.id} />;
            }
            if (this.props.match.path === "/superadmin/update-company-users/:id") {
                return <CompanyUserUpdate user_uud={this.props.match.params.id} />;
            }

            if (this.props.match.path === "/superadmin/individual-user-lists") {
                return <IndividualUsersList />;
            }
        };

        return (
            <React.Fragment>
                <Notifications options={{ zIndex: 99999 }} />
                {renderview()}
            </React.Fragment>
        );
    }
}

export default Users;