import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as actions from "./actions";

const SuperAdminRouter = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (actions.isAuthorize()) {
                    const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                    if (user_info?.user?.is_admin) {
                        return <Component {...props} />;
                    } else {
                        if (user_info.company === null) {
                            return <Redirect to="/signup-company" />;
                        } else {
                            return <Redirect to="/dashboard" />;
                        }
                    }
                } else {
                    return <Redirect to="/signin" />;
                }
            }}
        />
    );
};
export default SuperAdminRouter;