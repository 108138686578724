import React, { useState } from "react";

import moment from "moment";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";

const MyTasksItems = (props) => {
    const [bid_title_show, setbid_title_show] = useState(true);
    const [assigned_section_show, setassigned_section_show] = useState(true);
    const [section_due_date_show, setsection_due_date_show] = useState(true);
    const [submission_date_show, setsubmission_date_show] = useState(true);
    const [manager_show, setmanager_show] = useState(true);
    const [tags_show, settags_show] = useState(true);
    const [section_status_show, setsection_status_show] = useState(true);

    const [columns, setcolumns] = useState([
        {
            hidden: true,
            text: "Section ID",
            dataField: "section_id",
            formatter: (cellContent, row, index, extraData) => {
                return `section_id_${row.section_id}`;
            },
        },
        {
            sort: true,
            text: "Name of bid",
            dataField: "bid_title",
            formatter: (cellContent, row, index, extraData) => {
                return row?.response.bid?.bid_title;
            },
        },
        {
            sort: true,
            text: "Assigned section",
            dataField: "assigned_section",
            formatter: (cellContent, row, index, extraData) => {
                return row?.response?.title;
            },
        },
        {
            sort: true,
            text: "Section Due Date",
            dataField: "section_due_date",
            formatter: (cellContent, row, index, extraData) => {
                return row?.response?.section_due_date ? moment(row?.response?.section_due_date).format("DD MMM YYYY") : "-";
            },
        },
        {
            sort: true,
            text: "Bid Due Date",
            dataField: "submission_date",
            formatter: (cellContent, row, index, extraData) => {
                return moment(row?.response.bid?.submission_date).format("DD MMM YYYY");
            },
        },
        {
            text: "Manager",
            dataField: "manager",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        <div className="symbol-group symbol-hover" key={"mytask_manager_" + row.response_user_id}>
                            {row.section_owners &&
                                row.section_owners.map((section_owner) => (
                                    <div className="custom-tooltip" key={"bid_owner_" + section_owner.user_id}>
                                        <div className="symbol symbol-25px symbol-circle">
                                            {section_owner && section_owner.avatar ? (
                                                <span className="symbol_img symbol-label">
                                                    <img src={section_owner.avatar} alt="bid_owner_avatar" title="bid_owner_avatar" />
                                                    <span className="custom-tooltip-text">{section_owner.first_name + " " + section_owner.last_name}</span>
                                                </span>
                                            ) : (
                                                <span className="symbol-label bg-primary text-inverse-primary">
                                                    {section_owner.first_name?.slice(0, 1) + section_owner.last_name?.slice(0, 1)}
                                                    <span className="custom-tooltip-text">{section_owner.first_name + " " + section_owner.last_name}</span>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </>
                );
            },
        },
        {
            text: "Teams",
            dataField: "team",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        <div className="symbol-group symbol-hover" key={"mytask_manager_" + row.response_user_id}>
                            {row.section_teams &&
                                row.section_teams.map((section_team) => (
                                    <div className="custom-tooltip" key={"bid_owner_" + section_team.user_id}>
                                        <div className="symbol symbol-25px symbol-circle">
                                            {section_team && section_team.avatar ? (
                                                <span className="symbol_img symbol-label">
                                                    <img src={section_team.avatar} alt="bid_owner_avatar" title="bid_owner_avatar" />
                                                    <span className="custom-tooltip-text">{section_team.first_name + " " + section_team.last_name}</span>
                                                </span>
                                            ) : (
                                                <span className="symbol-label bg-primary text-inverse-primary">
                                                    {section_team.first_name?.slice(0, 1) + section_team.last_name?.slice(0, 1)}
                                                    <span className="custom-tooltip-text">{section_team.first_name + " " + section_team.last_name}</span>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </>
                );
            },
        },
        {
            text: "Tags",
            dataField: "tags",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        <div className="d-flex tags-scroll" key={"bid_tags_" + row.response_user_id}>
                            {row.response.bid &&
                                row.response.bid.bidtag_ids &&
                                row.response.bid.bidtag_ids.map((bidtag_id) => (
                                    <span className="badge badge-primary rounded-50" key={"tag_key_" + bidtag_id}>
                                        {props.bidtags[bidtag_id] ? props.bidtags[bidtag_id].tag_name : "-"}
                                    </span>
                                ))}
                        </div>
                    </>
                );
            },
        },
        {
            text: "Status",
            dataField: "section_status",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        <span className={"svg-icon-5 me-1 section_status_" + row.response.section_status.replace(" ", "_").toLowerCase()} key={"section_status_" + row.response_user_id}>
                            {row.response.section_status}
                        </span>
                    </>
                );
            },
        },
        {
            text: "",
            dataField: "library_action",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="dropdown " key={"mytask_dropdown_" + row.response_user_id}>
                        <div className="cursor-pointer" aria-expanded="false" id={"mytask_dropdown_" + row.response_user_id} data-bs-toggle="dropdown">
                            <i className="icon-vertical-dots color1"></i>
                        </div>
                        <div className="dropdown-menu" aria-labelledby={"mytask_dropdown_" + row.response_user_id}>
                            <Link to={"/bid-response/" + row?.response?.bid?.bid_uuid + "?activesection=" + row?.response?.section_uuid} className="dropdown-item">
                                <i className="icon-info1 text-black me-2" aria-hidden="true"></i>Go to task section
                            </Link>
                        </div>
                    </div>
                );
            },
        },
    ]);

    const onColumnToggle = (identifier, actions) => {
        let newTableColumns = columns.map((val) => {
            if (val.dataField === identifier) {
                val.hidden = actions;
            }
            return val;
        });
        setcolumns(newTableColumns);
    };

    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };

    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                page,
                totalSize,
                sizePerPage,
                custom: true,
                hidePageListOnlyOnePage: true,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive tasks-list-items">
                        <BootstrapTable
                            remote
                            bootstrap4
                            striped
                            data={data}
                            keyField="section_id"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered table-row-bordered align-middle custom-table-style"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-end flex-wrap pt-3 pb-7 pagination-main">
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </>
            )}
        </PaginationProvider>
    );

    return (
        <React.Fragment>
            <div className="row align-items-center mytasks-list-main">
                <div className="col-md-4">
                    <div className="d-flex align-items-center position-relative search-bids">
                        <span className="svg-icon svg-icon-3 position-absolute me-2">
                            <img src="assets/images/icons/search.svg" title="all_bids" alt="all_bids" />
                        </span>
                        <input
                            type="text"
                            placeholder="Search for task..."
                            className="form-control form-control-sm ps-9"
                            onChange={(event) => {
                                props.propsfiltersearch(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="d-flex align-items-center  justify-content-end">
                        <div className="me-5">
                            <div className="dropdown toggle-btn-dropdown column-drop-out column-menu">
                                <div aria-expanded="false" id="mytask_columnsmenu" data-bs-toggle="dropdown" className="btn rounded-50 btn-white btn-sm hvr-grow text-dark">
                                    <h6 className="text-dark m-0 fs-7">
                                        {" "}
                                        Columns<i className="icon-down-arrow text-dark fs-10 ms-2"></i>
                                    </h6>
                                </div>
                                <div className="dropdown-menu" aria-labelledby="mytask_columnsmenu">
                                    <h6 className="fs-7 mb-3">Customise Fields</h6>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Name of bid</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={bid_title_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("bid_title", bid_title_show);
                                                            setbid_title_show(bid_title_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Assigned section</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={assigned_section_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("assigned_section", assigned_section_show);
                                                            setassigned_section_show(assigned_section_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Section Due Date</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={section_due_date_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("section_due_date", section_due_date_show);
                                                            setsection_due_date_show(section_due_date_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Bid Due Date</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={submission_date_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("submission_date", submission_date_show);
                                                            setsubmission_date_show(submission_date_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Manager</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={manager_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("manager", manager_show);
                                                            setmanager_show(manager_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Tags</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={tags_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("tags", tags_show);
                                                            settags_show(tags_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div className="d-flex flex-stack">
                                            <div className="d-flex">
                                                <span className="fs-8 color1">Status</span>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input
                                                        type="checkbox"
                                                        checked={section_status_show}
                                                        className="form-check-input"
                                                        onChange={(event) => {
                                                            onColumnToggle("section_status", section_status_show);
                                                            setsection_status_show(section_status_show ? false : true);
                                                        }}
                                                    />
                                                    <span className="form-check-label fw-bold text-muted"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body p-0 bg-white radious-style radious-users mt-5">
                <RemotePagination
                    data={props.mytasks}
                    columns={columns}
                    key="mytask_bootstarptable"
                    totalSize={props.mytasks_count}
                    order={props.filterorder}
                    dataField={props.filtersort}
                    page={props.filterpage}
                    sizePerPage={props.filterlimit}
                    onTableChange={handleTableChange}
                />
            </div>
        </React.Fragment>
    );
};
export default MyTasksItems;
