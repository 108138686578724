import React, { useState } from "react";

import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";

const BidItems = (props) => {
    const [columns] = useState([
        {
            hidden: true,
            text: "Bidtype id",
            dataField: "bidtype_id",
            formatter: (cellContent, row, index, extraData) => {
                return row?.bidtype_id;
            },
        },
        {
            sort: true,
            text: "Bid type",
            dataField: "title",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <span
                        className="manage_content_title"
                        onClick={(event) => {
                            props.ShowBidtypeContentsByBidTypeId(row.bidtype_id, row.bidtype_uuid);
                        }}
                    >
                        {row.title}
                    </span>
                );
            },
        },
        {
            text: "Created by",
            dataField: "created_by",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        <div className="symbol-group symbol-hover" key={"bid_manager_" + row.bid_id}>
                            {row.created_user ? (
                                <>
                                    <div className="custom-tooltip" key={"row.created_user_" + row.created_user.user_id}>
                                        <div className="symbol symbol-25px symbol-circle">
                                            {row.created_user && row.created_user.avatar ? (
                                                <span className="symbol_img symbol-label">
                                                    <img src={row.created_user.avatar} alt="row.created_user_avatar" title="row.created_user_avatar" />
                                                    <span className="custom-tooltip-text">{row.created_user.first_name + " " + row.created_user.last_name}</span>
                                                </span>
                                            ) : (
                                                <span className="symbol-label bg-primary text-inverse-primary">
                                                    {row.created_user.first_name?.slice(0, 1) + row.created_user.last_name?.slice(0, 1)}
                                                    <span className="custom-tooltip-text">{row.created_user.first_name + " " + row.created_user.last_name}</span>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            sort: true,
            text: "Status",
            dataField: "bidtype_status",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        {(row.bidtype_status || row.bidtype_status === false) && (
                            <span className="svg-icon-5 me-1 manage_content_status">{row.bidtype_status === true ? <i className="icon-tick1"></i> : <i className="icon-Withdrawn"></i>}</span>
                        )}
                    </>
                );
            },
        },
        {
            text: "Edit",
            dataField: "Edit",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        <div className="d-flex manage_content_edit" key={"bidtype_action_" + row.bidtype_id}>
                            <Link className="dropdown-item" to={`/superadmin/bidtype-update/${row.bidtype_uuid}`}>
                                <i className="icon-edit"></i>
                            </Link>
                            <span className="dropdown-item curser_pointer" onClick={(event) => props.ConfirmArchiveBidType(row)}>
                                <i className="icon-delete"></i>
                            </span>
                        </div>
                    </>
                );
            },
        },
    ]);

    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };

    const rowStyle = (row, rowIndex) => {
        if (props.selected_bidtype_id) {
            let selected_bidtype_id = parseInt(props.selected_bidtype_id);
            if (row.bidtype_id === selected_bidtype_id) {
                return { backgroundColor: "rgba(250, 100, 100, 0.5)" };
            }
        }
    };

    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                page,
                totalSize,
                sizePerPage,
                custom: true,
                hidePageListOnlyOnePage: true,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive">
                        <BootstrapTable
                            remote
                            bootstrap4
                            striped
                            data={data}
                            keyField={"title"}
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered table-row-bordered align-middle custom-table-style fs-8"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                            rowStyle={rowStyle}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-end flex-wrap pt-3 pb-7 pagination-main">
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </>
            )}
        </PaginationProvider>
    );

    return (
        <React.Fragment>
            <div className="card-body p-0 bg-white radious-style radious-users  mt-5">
                <RemotePagination
                    data={props.bidtype_details}
                    columns={columns}
                    totalSize={props.bidtype_count}
                    order={props.filterorder}
                    dataField={props.filtersort}
                    page={props.filterpage}
                    sizePerPage={props.filterlimit}
                    onTableChange={handleTableChange}
                />
            </div>
        </React.Fragment>
    );
};

export default BidItems;