import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";

import Validations from "../../components/Utility/Validations";
import WysiwygEditor from "../../components/Utility/WysiwygEditor";

import * as userActions from "../../actions/index";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";
class LibraryContentUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            librarycontent_form: {
                content_id: {
                    label: "Content ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                content_name: {
                    label: "Content name",
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "Content name",
                    validations: { required: true },
                },
                description: {
                    label: "Content description",
                    type: "WysiwygEditor",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: true },
                },
            },
            library_id: "",
            library_content: [],
            library_name: "Folder name",
            content_name_editable: false,
        };
    }

    componentDidMount() {
        this.props.onGetContentByID(this.props.content_id).then((response) => {
            if (response.success === true) {
                let library_content = response.data;

                const update_librarycontent_form = { ...this.state.librarycontent_form };
                update_librarycontent_form.content_id.value = library_content.content_id;
                update_librarycontent_form.content_name.value = library_content.content_name;
                update_librarycontent_form.description.value = library_content.description;

                this.setState({
                    library_content: library_content,
                    library_id: library_content?.library_id,
                    librarycontent_form: update_librarycontent_form,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    inputChangeHandler(event, identifier) {
        const update_librarycontent_form = { ...this.state.librarycontent_form };
        const form_element = { ...update_librarycontent_form[identifier] };

        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_librarycontent_form[identifier] = form_element;
        this.setState(
            {
                librarycontent_form: update_librarycontent_form,
            },
            function () {
                this.SubmitHandler();
            }
        );
    }

    SubmitHandler = () => {
        const form_data = {};
        for (let key in this.state.librarycontent_form) {
            form_data[key] = this.state.librarycontent_form[key].value;
        }
        this.props.onUpdateLibraryContent(form_data).then((response) => {
            if (response.success === true) {
                console.log("response--------", response);
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    inputChangeHandlerWysiwygEditor(event, identifier) {
        const update_librarycontent_form = { ...this.state.librarycontent_form };
        const form_element = { ...update_librarycontent_form[identifier] };

        form_element.value = event;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_librarycontent_form[identifier] = form_element;
        this.setState({ librarycontent_form: update_librarycontent_form });
    }

    LibraryContentSubmitHandler = (event) => {
        event.preventDefault();

        const form_data = {};
        for (let key in this.state.librarycontent_form) {
            form_data[key] = this.state.librarycontent_form[key].value;
        }
        this.props.onUpdateLibraryContent(form_data).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    render() {
        const { librarycontent_form, library_content } = this.state;
        return (
            <React.Fragment>
                <div className={"d-flex flex-column flex-root"}>
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Content creation" />

                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="toolbar position-fixed z-index-3 w-100 mb-10">
                                    <div className="post" id="kt_post">
                                        <div id="kt_content_container" className="container-xxl">
                                            <div className="container-fluid p-0">
                                                <h3 className="card-title align-items-start flex-column mb-5 mobile-title">
                                                    {this.state.content_name_editable === false ? (
                                                        <span className="card-label fw-bold-700 fs-2 mb-1 text-main-color ">
                                                            {librarycontent_form.content_name.value}
                                                            <span
                                                                className="btn-icon ms-1"
                                                                onClick={(event) => {
                                                                    this.setState({
                                                                        content_name_editable: true,
                                                                    });
                                                                }}
                                                            >
                                                                <i className="icon-edit"></i>
                                                            </span>
                                                        </span>
                                                    ) : (
                                                        <span className="card-label fw-bolder text-pink library-edit ">
                                                            <input
                                                                type={librarycontent_form.content_name.type}
                                                                className="form-control form-control-lg form-control-solid fs-8"
                                                                placeholder={librarycontent_form.content_name.placeholder}
                                                                value={librarycontent_form.content_name.value ? librarycontent_form.content_name.value : ""}
                                                                onChange={(event) => this.inputChangeHandler(event, "content_name")}
                                                            />
                                                            <span
                                                                className="btn-icon ms-1"
                                                                onClick={(event) => {
                                                                    this.setState({
                                                                        content_name_editable: false,
                                                                    });
                                                                }}
                                                            >
                                                                <i className="icon-cross-2"></i>
                                                            </span>
                                                        </span>
                                                    )}
                                                </h3>
                                            </div>
                                            <div className="d-flex overflow-auto h-40px">
                                                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap library-menu">
                                                    <li className="nav-item">
                                                        <Link to="/library" className="nav-link text-active-primary me-6">
                                                            Content
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to={`/case-study/${library_content?.library_detail?.library_uuid}`} className="nav-link text-active-primary me-6">
                                                            {library_content?.library_detail?.name}
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <span className="nav-link text-active-primary me-6 active">{librarycontent_form.content_name.value}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="post d-flex flex-column-fluid mg-120">
                                    <div className="container-xxl">
                                        <div className="card">
                                            <form className="form w-100" onSubmit={this.LibraryContentSubmitHandler}>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <WysiwygEditor
                                                            value={librarycontent_form.description.value ? librarycontent_form.description.value : ""}
                                                            onClick={(event) => {
                                                                console.log("event------", event);
                                                            }}
                                                            onChange={(event) => this.inputChangeHandlerWysiwygEditor(event, "description")}
                                                        />
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-md-8">
                                                            <span className="text-gray-400 fs-8 fw-normal">
                                                                Last edited at {moment(library_content.updated_date).format("h:mm a, DD/MM/YYYY")} by{" "}
                                                                {library_content.updated_user ? library_content.updated_user.first_name + " " + library_content.updated_user.last_name : ""}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center my-2 justify-content-end">
                                                    <button type="submit" className="btn btn-danger rounded-50 w-150px hvr-grow">
                                                        Save
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetLibraryByID: userActions.GetLibraryByID,
    onGetContentByID: userActions.GetContentByID,
    onUpdateLibraryContent: userActions.UpdateLibraryContent,
};
export default connect(mapStateToProps, mapDispatchToProps)(LibraryContentUpdate);