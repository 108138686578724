import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { notify } from "react-notify-toast";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";
import Validations from "../../components/Utility/Validations";

class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            conpanydetails_form: {
                avatar: {
                    label: "File",
                    type: "file",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "assets/images/company_dummy.jpg",
                },
                company_name: {
                    type: "text",
                    label: "Company name*",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Company name",
                    validations: { required: true },
                },
                abn: {
                    type: "text",
                    label: "ABN*",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter ABN",
                    validations: {
                        required: true,
                        min_length: 11,
                        ValidateABN: true,
                        NumberAndSpace: true,
                    },
                },
                company_email: {
                    type: "email",
                    label: "Company email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Company email",
                    validations: { required: true },
                },
                industry: {
                    type: "text",
                    label: "Industry",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter Industry",
                    validations: { required: false },
                },
                company_size: {
                    label: "Size of company*",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter size of company",
                    validations: { required: true },
                    options: [
                        { opk: "company_size_opk_0", name: "Size of company", value: "" },
                        { opk: "company_size_opk_1", name: "1-5", value: "1-5" },
                        { opk: "company_size_opk_2", name: "6-15", value: "6-15" },
                        { opk: "company_size_opk_3", name: "16-50", value: "16-50" },
                        { opk: "company_size_opk_4", name: "51-100", value: "51-100" },
                        { opk: "company_size_opk_5", name: "100+", value: "100+" },
                    ],
                },
                address: {
                    type: "text",
                    label: "Address*",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter company address",
                    validations: { required: true },
                },
                country: {
                    label: "Country*",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [{ opk: "country_opk0", name: "Select Country", value: "" }],
                },
                zipcode: {
                    type: "text",
                    label: "Postcode*",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter postcode",
                    validations: { required: true },
                },
                state: {
                    label: "State*",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [{ opk: "state_opk0", name: "Select State", value: "" }],
                },
            },
            redirect_url: "",
            isAuthenticated: false,
            firstSetup: false
        };
    }

    componentDidMount = async () => {
        await this.GetCountriesLists();

        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        const company_info = user_info.company;
        const update_conpanydetails_form = { ...this.state.conpanydetails_form };

        update_conpanydetails_form.company_name.value = company_info?.company_name ? company_info.company_name : "";
        update_conpanydetails_form.abn.value = company_info?.abn ? company_info.abn : "";
        update_conpanydetails_form.company_email.value = company_info?.company_email ? company_info.company_email : "";
        update_conpanydetails_form.industry.value = company_info?.industry ? company_info.industry : "";
        update_conpanydetails_form.company_size.value = company_info?.company_size ? company_info?.company_size : "";
        update_conpanydetails_form.address.value = company_info?.address ? company_info.address : "";

        if(!company_info || !company_info.company_name) {
            this.setState({ firstSetup: true });
        }

        if (company_info?.country) {
            this.GetStatesByCountryCode(company_info.country);
            update_conpanydetails_form.abn.label = company_info.country === "NZ" ? "NZ GST*" : "ABN*";
            update_conpanydetails_form.abn.validations.min_length = company_info.country === "NZ" ? 9 : 11;
            update_conpanydetails_form.abn.placeholder = company_info.country === "NZ" ? "Enter NZ GST" : "Enter ABN";
        }

        update_conpanydetails_form.country.value = company_info?.country ? company_info.country : "";
        update_conpanydetails_form.zipcode.value = company_info?.zipcode ? company_info.zipcode : "";
        update_conpanydetails_form.state.value = company_info?.state ? company_info?.state : "";

        if (company_info.avatar) {
            update_conpanydetails_form.avatar.thumbnail_image = company_info.avatar;
        }

        this.setState({ conpanydetails_form: update_conpanydetails_form });
    };

    GetCountriesLists = async () => {
        await userActions.GetCountriesLists().then((response) => {
            if (response.success === true) {
                let country_details = response.data;
                const update_conpanydetails_form = { ...this.state.conpanydetails_form };

                let country_option = [{ opk: "country_opk0", name: "Select Country", value: "" }];
                for (let country_detail of country_details) {
                    country_option.push({
                        opk: country_detail.country_code,
                        name: country_detail.country_name,
                        value: country_detail.country_code,
                    });
                }

                update_conpanydetails_form.country.options = country_option;
                this.setState({ conpanydetails_form: update_conpanydetails_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetStatesByCountryCode = (country_code) => {
        userActions.GetStatesByCountryCode(country_code).then((response) => {
            if (response.success === true) {
                let state_details = response.data;
                const update_conpanydetails_form = { ...this.state.conpanydetails_form };

                let state_option = [{ opk: "state_opk0", name: "Select State", value: "" }];
                for (let state_detail of state_details) {
                    state_option.push({
                        opk: state_detail.state_code,
                        name: state_detail.state_name,
                        value: state_detail.state_code,
                    });
                }

                update_conpanydetails_form.state.options = state_option;
                this.setState({ conpanydetails_form: update_conpanydetails_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    remove_company_logo = (event) => {
        event.preventDefault();

        this.props.onRemoveCompanyLogo().then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");

                let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                user_info.company = response.company_info;
                localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));

                window.location.reload();
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    onFileChange(event, identifier) {
        event.preventDefault();
        this.props.onCompanyUpdateAvatar(event.target.files[0]).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");

                let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                user_info.company = response.company_info;
                localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));

                window.location.reload();
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    inputChangeHandler(event, identifier) {
        const update_conpanydetails_form = { ...this.state.conpanydetails_form };
        const form_element = { ...update_conpanydetails_form[identifier] };

        if (form_element.type === "file") {
            form_element.value = event.target.files[0];
        } else {
            form_element.value = event.target.value;
        }

        // Get States By CountryCode
        if (identifier === "country") {
            update_conpanydetails_form.abn.value = "";
            update_conpanydetails_form.abn.label = event.target.value === "NZ" ? "NZ GST*" : "ABN*";
            update_conpanydetails_form.abn.validations.min_length = event.target.value === "NZ" ? 9 : 11;
            update_conpanydetails_form.abn.placeholder = event.target.value === "NZ" ? "Enter NZ GST" : "Enter ABN";
            this.GetStatesByCountryCode(event.target.value);
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_conpanydetails_form[identifier] = form_element;

        this.setState({ conpanydetails_form: update_conpanydetails_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        // Check Validation
        let is_form_valid = true;
        const update_conpanydetails_form = this.state.conpanydetails_form;
        for (let key in update_conpanydetails_form) {
            let form_element = update_conpanydetails_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_conpanydetails_form[key] = form_element;
        }
        this.setState({ conpanydetails_form: update_conpanydetails_form });

        // Form submit handler
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.conpanydetails_form) {
                form_data[key] = this.state.conpanydetails_form[key].value;
            }

            this.props.onCompanyUpdate(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                    user_info.company = response.company_info;
                    localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));

                    // window.location.reload();
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/dashboard",
                        });
                    }, 500);
                } else {
                    notify.show(response.message, "error");
                }
            });
        } else {
            window.scrollTo(0, 0);
            notify.show("Please complete required fields", "error");
        }
    };

    render() {
        const { conpanydetails_form } = this.state;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.props.isAuthenticated === false ? <Redirect to="/logout" /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                            <LoginHeader page_title="Settings" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div className="container-xxl">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/settings-myprofile" className="nav-link text-active-primary me-6">
                                                        My profile
                                                    </Link>
                                                </li>
                                                {user_info.user.role.user_role === "Account owner" && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-company" className="nav-link text-active-primary me-6 active">
                                                            Company details
                                                        </Link>
                                                    </li>
                                                )}
                                                <li className="nav-item">
                                                    <Link to="/settings-account" className="nav-link text-active-primary me-6">
                                                        Account
                                                    </Link>
                                                </li>
                                                {user_info.user.role.user_role === "Account owner" && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-billing" className="nav-link text-active-primary me-6">
                                                            Billing
                                                        </Link>
                                                    </li>
                                                )}
                                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-users" className="nav-link text-active-primary me-6">
                                                            Users
                                                        </Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-11 col-xl-10">
                                                <div className="card mb-5 mb-xl-6">
                                                    <div className="card-header border-0 pt-5 mb-3">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder fs-2x mb-1">Company details</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body pt-0 pb-0">
                                                        <form className="form w-100" autoComplete="off" role="presentation" onSubmit={this.SubmitHandler}>
                                                            <div className="d-flex align-items-center flex-wrap flex-sm-nowrap mb-3">
                                                                <div className="me-7 mb-4 p-pics">
                                                                    <div className="symbol symbol-100px symbol-fixed position-relative">
                                                                        <img title="company_logo" alt="company_logo" src={conpanydetails_form.avatar.thumbnail_image} />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                                        <div className="d-flex flex-column">
                                                                            <div className="mb-2">
                                                                                <p className="text-gray-400 ">Your organisation’s logo</p>
                                                                                <div className={"d-flex align-items-center" + (!conpanydetails_form.avatar.valid && conpanydetails_form.avatar.onBlur_out ? " error-field" : "")}>
                                                                                    <label className="hvr-border-fade btn btn-sm btn-outline-primary rounded-50">
                                                                                        Change photo
                                                                                        <input hidden accept="image/*" type={conpanydetails_form.avatar.type} onChange={(event) => this.onFileChange(event, "avatar")} />
                                                                                    </label>
                                                                                    {!conpanydetails_form.avatar.valid && conpanydetails_form.avatar.onBlur_out ? (
                                                                                        <div className="error field-error">{conpanydetails_form.avatar.error_msg}</div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                    {user_info.company.avatar && (
                                                                                        <>
                                                                                            <span className="h-25px border-gray-700 border-start mx-4"></span>
                                                                                            <span
                                                                                                className="btn btn-sm btn-link"
                                                                                                onClick={(event) => {
                                                                                                    this.remove_company_logo(event);
                                                                                                }}
                                                                                            >
                                                                                                Remove photo
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row fv-row">
                                                                <div className={"col-sm-4 col-lg-4 mb-4 fv-row" + (!conpanydetails_form.country.valid && conpanydetails_form.country.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{conpanydetails_form.country.label}</label>
                                                                    <select className="form-select form-select-solid" value={conpanydetails_form.country.value} onChange={(event) => this.inputChangeHandler(event, "country")}>
                                                                        {conpanydetails_form.country.options.map((option) => (
                                                                            <option value={option.value} key={option.opk}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {!conpanydetails_form.country.valid && conpanydetails_form.country.onBlur_out && <div className="error field-error">{conpanydetails_form.country.error_msg}</div>}
                                                                </div>
                                                                <div className={"col-sm-4 col-lg-4 mb-4 fv-row" + (!conpanydetails_form.zipcode.valid && conpanydetails_form.zipcode.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{conpanydetails_form.zipcode.label}</label>
                                                                    <input
                                                                        autoComplete="new_zipcode"
                                                                        type={conpanydetails_form.zipcode.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={conpanydetails_form.zipcode.placeholder}
                                                                        value={conpanydetails_form.zipcode.value ? conpanydetails_form.zipcode.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "zipcode")}
                                                                        onBlur={(event) => {
                                                                            conpanydetails_form.zipcode.onBlur_out = true;
                                                                            this.setState({
                                                                                conpanydetails_form: conpanydetails_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!conpanydetails_form.zipcode.valid && conpanydetails_form.zipcode.onBlur_out && <div className="error field-error">{conpanydetails_form.zipcode.error_msg}</div>}
                                                                </div>
                                                                <div className={"col-sm-4 col-lg-4 mb-4 fv-row" + (!conpanydetails_form.state.valid && conpanydetails_form.state.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{conpanydetails_form.state.label}</label>
                                                                    <select className="form-select form-select-solid" value={conpanydetails_form.state.value} onChange={(event) => this.inputChangeHandler(event, "state")}>
                                                                        {conpanydetails_form.state.options.map((option) => (
                                                                            <option value={option.value} key={option.opk}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {!conpanydetails_form.state.valid && conpanydetails_form.state.onBlur_out && <div className="error field-error">{conpanydetails_form.state.error_msg}</div>}
                                                                </div>
                                                            </div>

                                                            <div className="row fv-row">
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!conpanydetails_form.company_name.valid && conpanydetails_form.company_name.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{conpanydetails_form.company_name.label}</label>
                                                                    <input
                                                                        autoComplete="new_company_name"
                                                                        type={conpanydetails_form.company_name.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={conpanydetails_form.company_name.placeholder}
                                                                        value={conpanydetails_form.company_name.value ? conpanydetails_form.company_name.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "company_name")}
                                                                        onBlur={(event) => {
                                                                            conpanydetails_form.company_name.onBlur_out = true;
                                                                            this.setState({
                                                                                conpanydetails_form: conpanydetails_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!conpanydetails_form.company_name.valid && conpanydetails_form.company_name.onBlur_out && (
                                                                        <div className="error field-error">{conpanydetails_form.company_name.error_msg}</div>
                                                                    )}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!conpanydetails_form.abn.valid && conpanydetails_form.abn.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{conpanydetails_form.abn.label}</label>
                                                                    <input
                                                                        autoComplete="new_abn"
                                                                        type={conpanydetails_form.abn.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={conpanydetails_form.abn.placeholder}
                                                                        value={conpanydetails_form.abn.value ? conpanydetails_form.abn.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "abn")}
                                                                        onBlur={(event) => {
                                                                            conpanydetails_form.abn.onBlur_out = true;
                                                                            this.setState({
                                                                                conpanydetails_form: conpanydetails_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!conpanydetails_form.abn.valid && conpanydetails_form.abn.onBlur_out && <div className="error field-error">{conpanydetails_form.abn.error_msg}</div>}
                                                                </div>
                                                            </div>

                                                            <div className={"fv-row mb-4 fv-plugins-icon-container" + (!conpanydetails_form.company_email.valid && conpanydetails_form.company_email.onBlur_out ? " error-field" : "")}>
                                                                <label className="form-label fs-6">{conpanydetails_form.company_email.label}</label>
                                                                <input
                                                                    autoComplete="new_company_email"
                                                                    type={conpanydetails_form.company_email.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={conpanydetails_form.company_email.placeholder}
                                                                    value={conpanydetails_form.company_email.value ? conpanydetails_form.company_email.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "company_email")}
                                                                    onBlur={(event) => {
                                                                        conpanydetails_form.company_email.onBlur_out = true;
                                                                        this.setState({
                                                                            conpanydetails_form: conpanydetails_form,
                                                                        });
                                                                    }}
                                                                />
                                                                {!conpanydetails_form.company_email.valid && conpanydetails_form.company_email.onBlur_out && (
                                                                    <div className="error field-error">{conpanydetails_form.company_email.error_msg}</div>
                                                                )}
                                                            </div>

                                                            <div className="row fv-row">
                                                                <div className={"col-xl-6 mb-4" + (!conpanydetails_form.industry.valid && conpanydetails_form.industry.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{conpanydetails_form.industry.label}</label>
                                                                    <input
                                                                        autoComplete="new_industry"
                                                                        type={conpanydetails_form.industry.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={conpanydetails_form.industry.placeholder}
                                                                        value={conpanydetails_form.industry.value ? conpanydetails_form.industry.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "industry")}
                                                                        onBlur={(event) => {
                                                                            conpanydetails_form.industry.onBlur_out = true;
                                                                            this.setState({
                                                                                conpanydetails_form: conpanydetails_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!conpanydetails_form.industry.valid && conpanydetails_form.industry.onBlur_out && <div className="error field-error">{conpanydetails_form.industry.error_msg}</div>}
                                                                </div>
                                                                <div className={"col-xl-6 mb-4" + (!conpanydetails_form.company_size.valid && conpanydetails_form.company_size.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{conpanydetails_form.company_size.label}</label>
                                                                    <select className="form-select form-select-solid" value={conpanydetails_form.company_size.value} onChange={(event) => this.inputChangeHandler(event, "company_size")}>
                                                                        {conpanydetails_form.company_size.options.map((option) => (
                                                                            <option value={option.value} key={option.opk}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {!conpanydetails_form.company_size.valid && conpanydetails_form.company_size.onBlur_out && (
                                                                        <div className="error field-error">{conpanydetails_form.company_size.error_msg}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className={"fv-row mb-4 fv-plugins-icon-container" + (!conpanydetails_form.address.valid && conpanydetails_form.address.onBlur_out ? " error-field" : "")}>
                                                                <label className="form-label fs-6">{conpanydetails_form.address.label}</label>
                                                                <input
                                                                    autoComplete="new_address"
                                                                    type={conpanydetails_form.address.type}
                                                                    className="form-control form-control-lg form-control-solid"
                                                                    placeholder={conpanydetails_form.address.placeholder}
                                                                    value={conpanydetails_form.address.value ? conpanydetails_form.address.value : ""}
                                                                    onChange={(event) => this.inputChangeHandler(event, "address")}
                                                                    onBlur={(event) => {
                                                                        conpanydetails_form.address.onBlur_out = true;
                                                                        this.setState({
                                                                            conpanydetails_form: conpanydetails_form,
                                                                        });
                                                                    }}
                                                                />
                                                                {!conpanydetails_form.address.valid && conpanydetails_form.address.onBlur_out && <div className="error field-error">{conpanydetails_form.address.error_msg}</div>}
                                                            </div>
                                                            <div className="card-footer d-flex pt-6">
                                                                <button type="submit" className="save-button hvr-grow btn rounded-50 btn-lg btn-primary">
                                                                    <span className="indicator-label">Save and continue</span>
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onCompanyUpdateAvatar: userActions.CompanyUpdateAvatar,
    onCompanyUpdate: userActions.CompanyUpdate,
    onRemoveCompanyLogo: userActions.RemoveCompanyLogo,
};
export default connect(mapStateToProps, mapDispatchToProps)(Company);