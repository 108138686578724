import React, { useState } from "react";

import moment from "moment";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";

const NotificationItems = (props) => {
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    const [columns] = useState([
        {
            sort: false,
            text: "Message",
            dataField: "desktopnotification_html",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="d-flex align-items-center " key={"notification_" + row.system_user_log_id}>
                        <div className="symbol-group symbol-hover">
                            <div className="symbol symbol-25px symbol-circle me-3">
                                {row?.action_user && row?.action_user.avatar ? (
                                    <span className="symbol_img symbol-labely">
                                        <img src={row?.action_user.avatar} alt={row?.action_user.first_name + " " + row?.action_user.last_name} title={row?.action_user.first_name + " " + row?.action_user.last_name} />
                                    </span>
                                ) : (
                                    <span className="symbol-label bg-primary text-inverse-primary">{row?.action_user.first_name?.slice(0, 1) + row?.action_user.last_name?.slice(0, 1)}</span>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-row-fluid">
                            <div className="d-flex align-items-center flex-wrap">
                                <span className="color1 fs-14 notify-txt me-1">{row?.action_user.first_name + " " + row?.action_user.last_name}</span>
                                <span className="color1 fs-14 notify-txt notify-txt-2 ">{renderHTML(row?.desktopnotification_html)}</span>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            sort: false,
            text: "Created Date",
            dataField: "created_date",
            formatter: (cellContent, row, index, extraData) => {
                return moment(row.created_date).format("DD/MM/YYYY");
            },
        },
        {
            text: "",
            dataField: "bid_action",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="dropdown" key={"system_user_log_id_" + row.system_user_log_id}>
                        <div className="" aria-expanded="false" id={`bid_action_${index}`} data-bs-toggle="dropdown">
                            <img src="assets/images/icons/more.svg" title="more" alt="more" />
                        </div>
                        <div className="dropdown-menu" aria-labelledby={`bid_action_${index}`}>
                            <span className="dropdown-item" onClick={(event) => props.NotificationMarkRead(event, row)}>
                                view bid
                            </span>
                        </div>
                    </div>
                );
            },
        },
    ]);

    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };

    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive">
                        <BootstrapTable
                            remote
                            bootstrap4
                            striped
                            data={data}
                            keyField="desktopnotification_html"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered table-row-bordered align-middle custom-table-style fs-8"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-end flex-wrap pt-3 pb-7 pagination-main">
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </>
            )}
        </PaginationProvider>
    );

    return (
        <React.Fragment>
            <div className="card-body p-0 bg-white radious-style radious-users  mt-5">
                <RemotePagination
                    columns={columns}
                    data={props.desktop_notifications}
                    totalSize={props.desktop_notificationcount}
                    order={props.filterorder}
                    dataField={props.filtersort}
                    page={props.filterpage}
                    sizePerPage={props.filterlimit}
                    onTableChange={handleTableChange}
                />
            </div>
        </React.Fragment>
    );
};

export default NotificationItems;