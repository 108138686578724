import React from "react";

const Loader = (props) => {
    return (
        <div className="loader">
            <img className="loader_img" src="assets/images/loader.gif" title="loader_gif" alt="loader_gif" />
        </div>
    );
};

export default Loader;