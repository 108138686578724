import React, { useState, useEffect, useCallback } from "react";

import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";

import ClientItems from "./ClientItems";

import * as userActions from "../../actions/index";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";

const ClientList = (props) => {
    const dispatch = useDispatch();

    const [clients, setclients] = useState([]);
    const [clientcount, setclientcount] = useState(0);

    const [totalClient, settotalClient] = useState(0);
    const [totalbidvalue, settotalbidvalue] = useState(0);
    const [totalbidwon, settotalbidwon] = useState(0);
    const [totalbidloss, settotalbidloss] = useState(0);

    const [filterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filtersort, setfiltersort] = useState("client_id");
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersearch, setfiltersearch] = useState(null);

    useEffect(() => {
        const GetClientsStatus = () => {
            dispatch(userActions.GetClientsStatus()).then((response) => {
                if (response.success === true) {
                    settotalbidwon(response.data.bid_status.Won ? response.data.bid_status.Won : 0);
                    settotalbidloss(response.data.bid_status.Lost ? response.data.bid_status.Lost : 0);
                    settotalClient(response.data.totalClient);
                    settotalbidvalue(response.data.totalbidvalue);
                } else {
                    notify.show(response.message, "error");
                }
            });
        };
        GetClientsStatus();
    }, [dispatch]);

    const GetClients = useCallback(() => {
        dispatch(userActions.GetClients(filtersearch, filterpage, filterlimit, filtersort, filterorder)).then((response) => {
            if (response.success === true) {
                setclients(response.data);
                setclientcount(response.count);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, filtersearch, filterpage, filterlimit, filtersort, filterorder]);

    useEffect(() => {
        GetClients();
    }, [dispatch, GetClients, filtersearch, filterpage, filterlimit, filtersort, filterorder]);

    return (
        <React.Fragment>
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <LoginHeader page_title="Client management" />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card mb-4 bg-transparent">
                                                <div className="card-body p-0">
                                                    <div className="row mb-9">
                                                        <div className="col-sm-3">
                                                            <div className="client-main cursor-pointer">
                                                                <span className="text-dark txt-top">Total clients</span> <span className="text-dark fw-bolder txt-bottom">{totalClient}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="client-main cursor-pointer">
                                                                <span className="text-dark txt-top">Lifetime win/loss</span>{" "}
                                                                <span className="text-dark fw-bolder txt-bottom">
                                                                    {totalbidwon}/{totalbidloss}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="client-main cursor-pointer">
                                                                <span className="text-dark txt-top">Lifetime value</span> <span className="text-dark fw-bolder txt-bottom">${totalbidvalue}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ClientItems
                                        clients={clients}
                                        filterlimit={filterlimit}
                                        filterpage={filterpage}
                                        clientcount={clientcount}
                                        propsfiltersort={(event) => setfiltersort(event)}
                                        propsfilterpage={(event) => setfilterpage(event)}
                                        propsfilterorder={(event) => setfilterorder(event)}
                                        propsfiltersearch={(event) => setfiltersearch(event)}
                                    />
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetClientsStatus: userActions.GetClientsStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientList);