import React from "react";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { Redirect } from "react-router-dom";
import NumberFormat from "react-number-format";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { AsyncTypeahead, Token } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AddClient from "./AddClient";

import * as userActions from "../../../actions/index";
import Loader from "../../../components/Utility/Loader";
import LoginFooter from "../../../components/LoginFooter";
import LoginHeader from "../../../components/LoginHeader";
import LoginSidebar from "../../../components/LoginSidebar";
import Validations from "../../../components/Utility/Validations";
import Dropzone from "../../../components/Utility/dropzone/Dropzone";
import ButtonDropzone from "../../../components/Utility/ButtonDropzone/ButtonDropzone";

class BidCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bidcreate_form: {
                bid_title: {
                    type: "text",
                    label: "Bid name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                client_id: {
                    type: "select",
                    label: "Client name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [],
                },
                reference_number: {
                    type: "text",
                    label: "Bid reference ID",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                submission_date: {
                    type: "datepicker",
                    label: "Submission due date *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                submission_timezone: {
                    type: "select",
                    label: "Submission timezone *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [
                        { opk: "submission_timezone_0", name: "Select Submission timezone", value: null },
                        { opk: "submission_timezone_1", name: "Perth (UTC+08:00)", value: "Australia/Perth" },
                        { opk: "submission_timezone_2", name: "Adelaide (UTC+09:30)", value: "Australia/Adelaide" },
                        { opk: "submission_timezone_3", name: "Darwin (UTC+09:30)", value: "Australia/Darwin" },
                        { opk: "submission_timezone_4", name: "Brisbane (UTC+10:00)", value: "Australia/Brisbane" },
                        { opk: "submission_timezone_5", name: "Canberra, Melbourne, Sydney (UTC+10:00)", value: "Australia/Sydney" },
                        { opk: "submission_timezone_6", name: "Hobart (UTC+10:00)", value: "Australia/Hobart" },
                        { opk: "submission_timezone_7", name: "Auckland, Wellington (UTC+12:00)", value: "Pacific/Auckland" }
                    ],
                },
                bid_value: {
                    type: "numberformat",
                    label: "Estimated value ($) *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "$",
                    validations: { required: true },
                },
                tags: {
                    type: "typeahead",
                    label: "Tags",
                    value: [],
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    options: [],
                    validations: { required: false, typeahead: false },
                },
                bidtype_id: {
                    type: "select",
                    label: "Type of bid *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [{ opk: "role_opk_0", name: "Select from dropdown", value: "" }],
                },
                bid_owners: {
                    type: "typeahead",
                    label: "Owners *",
                    value: [],
                    options: [],
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, typeahead: true },
                },
                bid_teams: {
                    type: "typeahead",
                    label: "Teammates",
                    value: [],
                    options: [],
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false, typeahead: false },
                },
            },
            loader: false,
            addclient_modal: false,
            clent_name: "",
            formdata: []
        };
    }

    async componentDidMount() {
        this.GetTags();
        this.GetClients();
        this.GetBidTypes();
        this.GetCompanyUsers();
    }

    GetTags() {
        this.props.onGetTags().then((response) => {
            if (response.success === true) {
                let all_tags = response.data;
                const update_bidcreate_form = { ...this.state.bidcreate_form };
                let tag_option = [];
                for (let key in all_tags) {
                    if (all_tags[key].tag_name) {
                        tag_option.push({
                            opk: all_tags[key].bidtag_id,
                            name: all_tags[key].tag_name,
                            value: all_tags[key].bidtag_id,
                        });
                    }
                }
                update_bidcreate_form.tags.options = tag_option;
                this.setState({ bidcreate_form: update_bidcreate_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    GetClients() {
        this.props.onGetClients().then((response) => {
            if (response.success === true) {
                let all_clients = response.data;
                const update_bidcreate_form = { ...this.state.bidcreate_form };
                let client_option = [];
                for (let key in all_clients) {
                    client_option.push({
                        opk: all_clients[key].client_id,
                        name: all_clients[key].client_name,
                        value: all_clients[key].client_id,
                    });
                }
                update_bidcreate_form.client_id.options = client_option;
                this.setState({ bidcreate_form: update_bidcreate_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    GetBidTypes() {
        this.props.onGetBidTypes().then((response) => {
            if (response.success === true) {
                let all_bidtype = response.data;
                const update_bidcreate_form = { ...this.state.bidcreate_form };
                let bidtype_option = [{ opk: "role_opk_0", name: "Select from dropdown", value: "" }];
                for (let key in all_bidtype) {
                    if (all_bidtype[key].title) {
                        bidtype_option.push({
                            opk: all_bidtype[key].bidtype_id,
                            name: all_bidtype[key].title,
                            value: all_bidtype[key].bidtype_id,
                        });
                    }
                }
                update_bidcreate_form.bidtype_id.options = bidtype_option;
                this.setState({ bidcreate_form: update_bidcreate_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    GetCompanyUsers() {
        this.props.onGetCompanyUsers().then((response) => {
            if (response.success === true) {
                let bid_teams_option = [];
                let bid_owners_option = [];
                let company_users = response.data;
                const update_bidcreate_form = { ...this.state.bidcreate_form };
                for (let key in company_users) {
                    if (company_users[key].role.user_role === "Account owner" || company_users[key].role.user_role === "Bid Manager") {
                        bid_owners_option.push({
                            opk: company_users[key].user_id,
                            first_name: company_users[key].first_name,
                            last_name: company_users[key].last_name,
                            name: company_users[key].first_name + " " + company_users[key].last_name,
                            value: company_users[key].user_id,
                        });
                    }

                    bid_teams_option.push({
                        opk: company_users[key].user_id,
                        first_name: company_users[key].first_name,
                        last_name: company_users[key].last_name,
                        name: company_users[key].first_name + " " + company_users[key].last_name,
                        value: company_users[key].user_id,
                    });
                }
                update_bidcreate_form.bid_teams.options = bid_teams_option;
                update_bidcreate_form.bid_owners.options = bid_owners_option;
                this.setState({ bidcreate_form: update_bidcreate_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    AddNewClient = (event) => {
        event.preventDefault();
        this.setState({ addclient_modal: true });
    };

    CreateClientSubmit = async (form_data) => {
        this.props.onCreateClient(form_data).then(async (response) => {
            if (response.success === true) {
                await this.GetClients();
                this.inputChangeHandlerClient(response.data.client_id, response.data.client_name, "client_id");

                this.AddUsersSuccessEvent.AddNewClientReload();
                this.setState({ addclient_modal: false });

                notify.show("Client created successfully.", "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    inputChangeHandlerClient(id, name, identifier) {
        let update_bidcreate_form = { ...this.state.bidcreate_form };
        update_bidcreate_form.client_id.value = id;
        this.setState({
            bidcreate_form: update_bidcreate_form,
            clent_name: name,
        });
    }

    inputChangeHandler(event, identifier) {
        const update_bidcreate_form = { ...this.state.bidcreate_form };
        const form_element = { ...update_bidcreate_form[identifier] };

        if (form_element.type === "numberformat") {
            form_element.value = event;
        } else if (form_element.type === "datepicker") {
            form_element.value = event;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_bidcreate_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_bidcreate_form) {
            is_form_valid = update_bidcreate_form[identifier].valid && is_form_valid;
        }

        this.setState({
            bidcreate_form: update_bidcreate_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_bidcreate_form = this.state.bidcreate_form;
        for (let key in update_bidcreate_form) {
            let form_element = update_bidcreate_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_bidcreate_form[key] = form_element;
        }
        this.setState({ bidcreate_form: update_bidcreate_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.bidcreate_form) {
                if (this.state.bidcreate_form[key].type === "typeahead") {
                    if (key === "bid_owners" || key === "bid_teams") {
                        let form_element = this.state.bidcreate_form[key].value;
                        form_data[key] = form_element.map((t) => t.value);
                    } else {
                        let typeahead_value = this.state.bidcreate_form[key].value;
                        form_data[key] = typeahead_value.map((t) => t.name);
                    }
                } else {
                    form_data[key] = this.state.bidcreate_form[key].value;
                }
            }
            this.props.onCreateBid(form_data).then((response) => {
                if (response.success === true) {
                    localStorage.setItem(`new-bid-${response.data.bid_uuid}`, "true");
                    let newNotify = notify
                    if (this.state.formdata && this.state.formdata.length) {
                        this.BidUploadDocuments(response.data.bid_id).then(() => {
                            newNotify.show(response.message, "success");
                            setTimeout(() => {
                                this.setState({
                                    isAuthenticated: true,
                                    redirect_url: "/bid-response/" + response.data.bid_uuid,
                                });
                            }, 1000);
                        });
                    } else {
                        newNotify.show(response.message, "success");
                        setTimeout(() => {
                            this.setState({
                                isAuthenticated: true,
                                redirect_url: "/bid-response/" + response.data.bid_uuid,
                            });
                        }, 1000);
                    }
                } else {
                    notify.show(response.message, "error");
                }
            });
        } else {
            window.scrollTo(0, 0);
            notify.show("Please enter all fields", "error");
        }
    };

    BidAddDocument = (file) => {
        let formdataItems = this.state.formdata
        formdataItems.push({
            file: file
        })
        this.setState({
            formdata: formdataItems,
        })
    }

    BidRemoveDocument = (index) => {
        let formdataItems = this.state.formdata
        if (formdataItems.length === 1) {
            this.setState({
                formdata: []
            })
        } else {
            formdataItems.splice(index, 1)
            this.setState({
                formdata: formdataItems,
            })
        }
    }

    BidUploadDocuments = (bid_id) => {
        let form_data = []
        for (const item of this.state.formdata) {
            form_data.push({
                file: item.file,
                bid_id: bid_id,
                is_tender: true
            })
        }
        return this.props.onBidUploadDocuments(form_data)
    }

    render() {
        const { bidcreate_form } = this.state;
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                {this.state.addclient_modal && (
                    <AddClient
                        addclient_modal={this.state.addclient_modal}
                        ref={(ref) => (this.AddUsersSuccessEvent = ref)}
                        CreateClientSubmit={(event) => this.CreateClientSubmit(event)}
                        addclient_modal_action={(modal_action) => {
                            this.setState({
                                addclient_modal: modal_action,
                            });
                        }}
                    />
                )}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Bid creation" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <form className="form w-100" onSubmit={this.SubmitHandler}>
                                                    <div className="card mb-5 mb-xl-6">
                                                        <div className="card-header border-0 pt-5 mb-4">
                                                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                <img src="assets/images/icons/bid-info.svg" title="all_bids" alt="all_bids" />
                                                                <h3 className="card-title align-items-start flex-column ms-4">
                                                                    <span className="card-label fw-bold-700 fs-2x mb-1 color1">Bid Information</span>
                                                                    <span className="fw-normal text-dark-muted fs-7">Fill in the table below with all of the important information about your bid.</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="row mb-4">
                                                                <div className={"col-lg-12 fv-row" + (!bidcreate_form.bid_title.valid && bidcreate_form.bid_title.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{bidcreate_form.bid_title.label}</label>
                                                                    <input
                                                                        type={bidcreate_form.bid_title.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={bidcreate_form.bid_title.placeholder}
                                                                        value={bidcreate_form.bid_title.value ? bidcreate_form.bid_title.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "bid_title")}
                                                                        onBlur={(event) => {
                                                                            bidcreate_form.bid_title.onBlur_out = true;
                                                                            this.setState({
                                                                                bidcreate_form: bidcreate_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!bidcreate_form.bid_title.valid && bidcreate_form.bid_title.onBlur_out ? <div className="error field-error">{bidcreate_form.bid_title.error_msg}</div> : ""}
                                                                </div>
                                                            </div>

                                                            <div className="row mb-4">
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!bidcreate_form.client_id.valid && bidcreate_form.client_id.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{bidcreate_form.client_id.label}</label>
                                                                    <DropdownButton className="clientdorpdown" title={this.state.clent_name ? this.state.clent_name : ""}>
                                                                        {bidcreate_form.client_id.options.map((option) => (
                                                                            <Dropdown.Item onClick={(event) => this.inputChangeHandlerClient(option.value, option.name, "client_id")} key={option.opk} eventKey={option.value}>
                                                                                {option.name}
                                                                            </Dropdown.Item>
                                                                        ))}

                                                                        <Dropdown.Item eventKey="0" className="add-client-btn text-gray-new" onClick={(event) => this.AddNewClient(event)}>
                                                                            <i className="fas fa-plus"></i> Add new client
                                                                        </Dropdown.Item>
                                                                    </DropdownButton>
                                                                    {!bidcreate_form.client_id.valid && bidcreate_form.client_id.onBlur_out ? <div className="error field-error">{bidcreate_form.client_id.error_msg}</div> : ""}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!bidcreate_form.reference_number.valid && bidcreate_form.reference_number.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{bidcreate_form.reference_number.label}</label>
                                                                    <input
                                                                        type={bidcreate_form.reference_number.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={bidcreate_form.reference_number.placeholder}
                                                                        value={bidcreate_form.reference_number.value ? bidcreate_form.reference_number.value : ""}
                                                                        onChange={(event) => this.inputChangeHandler(event, "reference_number")}
                                                                        onBlur={(event) => {
                                                                            bidcreate_form.reference_number.onBlur_out = true;
                                                                            this.setState({
                                                                                bidcreate_form: bidcreate_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!bidcreate_form.reference_number.valid && bidcreate_form.reference_number.onBlur_out ? (
                                                                        <div className="error field-error">{bidcreate_form.reference_number.error_msg}</div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div
                                                                    className={
                                                                        " col-md-12 col-xl-6 mb-4 fv-row position-relative datepicker-calender" +
                                                                        (!bidcreate_form.submission_date.valid && bidcreate_form.submission_date.onBlur_out ? " error-field" : "")
                                                                    }
                                                                >
                                                                    <label className="form-label fs-6">{bidcreate_form.submission_date.label}</label>
                                                                    <DatePicker
                                                                        peekNextMonth
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        minDate={new Date()}
                                                                        dropdownMode="select"
                                                                        className="form-control date-picker-outer"
                                                                        // dateFormat="dd/MM/yyyy"
                                                                        showTimeSelect
                                                                        timeFormat="HH:mm"
                                                                        dateFormat="dd/MM/yyyy h:mm aa"
                                                                        selected={bidcreate_form.submission_date.value}
                                                                        placeholder={bidcreate_form.submission_date.placeholder}
                                                                        onChange={(event) => this.inputChangeHandler(event, "submission_date")}
                                                                        onBlur={(event) => {
                                                                            bidcreate_form.submission_date.onBlur_out = true;
                                                                            this.setState({
                                                                                bidcreate_form: bidcreate_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!bidcreate_form.submission_date.valid && bidcreate_form.submission_date.onBlur_out ? (
                                                                        <div className="error field-error">{bidcreate_form.submission_date.error_msg}</div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                                <div className={"col-md-12 col-xl-6 mb-4 fv-row" + (!bidcreate_form.submission_timezone.valid && bidcreate_form.submission_timezone.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{bidcreate_form.submission_timezone.label}</label>
                                                                    <select
                                                                        className="form-select form-select-solid"
                                                                        value={bidcreate_form.submission_timezone.value}
                                                                        onChange={(event) => this.inputChangeHandler(event, "submission_timezone")}
                                                                    >
                                                                        {bidcreate_form.submission_timezone.options.map((option) => (
                                                                            <option className="one" value={option.value} key={option.opk}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {!bidcreate_form.submission_timezone.valid && bidcreate_form.submission_timezone.onBlur_out ? (
                                                                        <div className="error field-error">{bidcreate_form.submission_timezone.error_msg}</div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className={"col-lg-12 fv-row" + (!bidcreate_form.bid_value.valid && bidcreate_form.bid_value.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{bidcreate_form.bid_value.label}</label>
                                                                    <NumberFormat
                                                                        prefix="$"
                                                                        inputMode="numeric"
                                                                        isNumericString={true}
                                                                        thousandSeparator={true}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        value={bidcreate_form.bid_value.value ? bidcreate_form.bid_value.value : ""}
                                                                        onValueChange={(values) => {
                                                                            const { value } = values;
                                                                            this.inputChangeHandler(value, "bid_value");
                                                                        }}
                                                                    />
                                                                    {!bidcreate_form.bid_value.valid && bidcreate_form.bid_value.onBlur_out ? <div className="error field-error">{bidcreate_form.bid_value.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className={"col-lg-12 fv-row" + (!bidcreate_form.tags.valid && bidcreate_form.tags.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{bidcreate_form.tags.label}</label>

                                                                    <AsyncTypeahead
                                                                        labelKey="name"
                                                                        id="bidtags_typeahead"
                                                                        multiple={true}
                                                                        allowNew={true}
                                                                        isLoading={false}
                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                        options={bidcreate_form.tags.options}
                                                                        selected={bidcreate_form.tags.value}
                                                                        onSearch={(query) => { }}
                                                                        onChange={(selected) => {
                                                                            const update_bidcreate_form = { ...this.state.bidcreate_form };
                                                                            update_bidcreate_form.tags.value = selected;
                                                                            this.setState({ bidcreate_form: update_bidcreate_form });
                                                                        }}
                                                                        ref={(ref) => (this.AsyncTypeaheadBidTagsEvent = ref)}
                                                                        onKeyDown={(event) => {
                                                                            if (event.keyCode === 13 && event.target.value) {
                                                                                this.AsyncTypeaheadBidTagsEvent.clear();
                                                                                this.AsyncTypeaheadBidTagsEvent.blur();
                                                                                this.AsyncTypeaheadBidTagsEvent.hideMenu();

                                                                                const update_bidcreate_form = { ...this.state.bidcreate_form };
                                                                                update_bidcreate_form.tags.value.push({ customOption: true, name: event.target.value });
                                                                                this.setState(
                                                                                    {
                                                                                        bidcreate_form: update_bidcreate_form,
                                                                                    },
                                                                                    function () {
                                                                                        this.AsyncTypeaheadBidTagsEvent.focus();
                                                                                        console.log("this.AsyncTypeaheadBidTagsEvent------------", this.AsyncTypeaheadBidTagsEvent);
                                                                                    }
                                                                                );
                                                                            }
                                                                        }}
                                                                    />

                                                                    {!bidcreate_form.tags.valid && bidcreate_form.tags.onBlur_out ? <div className="error field-error">{bidcreate_form.tags.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card mb-5 mb-xl-6">
                                                        <div className="card-header border-0 pt-5 mb-4">
                                                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                <img src="assets/images/icons/bid-type.svg" title="all_bids" alt="all_bids" />
                                                                <h3 className="card-title align-items-start flex-column ms-4">
                                                                    <span className="card-label fw-bolder fs-2x mb-1 color1">Type of Bid</span>
                                                                    <span className="fw-normal text-dark-muted fs-7">Select your bid type from the dropdown.</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="row mb-4">
                                                                <div className={"col-lg-12 fv-row" + (!bidcreate_form.bidtype_id.valid && bidcreate_form.bidtype_id.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6 ">
                                                                        {bidcreate_form.bidtype_id.label}{" "}
                                                                        <div className="custom-tooltip tooltip-icons tooltip-bid-type">
                                                                            <i className="icon-exclamation ms-1 "></i> <span className="custom-tooltip-text ">Lorem ipsum dolar</span>
                                                                        </div>
                                                                    </label>

                                                                    <select className="form-select form-select-solid " value={bidcreate_form.bidtype_id.value} onChange={(event) => this.inputChangeHandler(event, "bidtype_id")}>
                                                                        {bidcreate_form.bidtype_id.options.map((option) => (
                                                                            <option value={option.value} key={option.opk}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    {!bidcreate_form.bidtype_id.valid && bidcreate_form.bidtype_id.onBlur_out ? <div className="error field-error">{bidcreate_form.bidtype_id.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card mb-5 mb-xl-6 bid-team-outer">
                                                        <div className="card-header border-0 pt-5 mb-4">
                                                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                <img src="assets/images/icons/bid-team.svg" title="all_bids" alt="all_bids" />
                                                                <h3 className="card-title align-items-start flex-column ms-4">
                                                                    <span className="card-label fw-bolder fs-2x mb-1 color1">Bid Team</span>
                                                                    <span className="fw-normal text-dark-muted fs-7">Choose everyone who is part of your bid team here.</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="row">
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!bidcreate_form.bid_owners.valid && bidcreate_form.bid_owners.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{bidcreate_form.bid_owners.label}</label>
                                                                    <AsyncTypeahead
                                                                        multiple
                                                                        id="bidbid_owners"
                                                                        labelKey="name"
                                                                        isLoading={false}
                                                                        className="react-bootstrap-typeahead"
                                                                        onSearch={(query) => { }}
                                                                        options={bidcreate_form.bid_owners.options}
                                                                        selected={bidcreate_form.bid_owners.value}
                                                                        onChange={(selected) => {
                                                                            const update_bidcreate_form = { ...this.state.bidcreate_form };
                                                                            const form_element = { ...update_bidcreate_form["bid_owners"] };
                                                                            form_element.value = selected;
                                                                            update_bidcreate_form["bid_owners"] = form_element;
                                                                            this.setState({
                                                                                bidcreate_form: update_bidcreate_form,
                                                                            });
                                                                        }}
                                                                        renderToken={(option, props, index) => (
                                                                            <Token key={index} onRemove={props.onRemove} option={option}>
                                                                                {option.first_name?.slice(0, 1) + option.last_name?.slice(0, 1)}
                                                                            </Token>
                                                                        )}
                                                                    />
                                                                    {!bidcreate_form.bid_owners.valid && bidcreate_form.bid_owners.onBlur_out ? <div className="error field-error">{bidcreate_form.bid_owners.error_msg}</div> : ""}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!bidcreate_form.bid_teams.valid && bidcreate_form.bid_teams.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{bidcreate_form.bid_teams.label}</label>
                                                                    <AsyncTypeahead
                                                                        multiple
                                                                        id="bidbid_teams"
                                                                        labelKey="name"
                                                                        isLoading={false}
                                                                        className="react-bootstrap-typeahead"
                                                                        options={bidcreate_form.bid_teams.options}
                                                                        selected={bidcreate_form.bid_teams.value}
                                                                        onSearch={(query) => { }}
                                                                        onChange={(selected) => {
                                                                            const update_bidcreate_form = { ...this.state.bidcreate_form };
                                                                            const form_element = { ...update_bidcreate_form["bid_teams"] };
                                                                            form_element.value = selected;
                                                                            update_bidcreate_form["bid_teams"] = form_element;
                                                                            this.setState({
                                                                                bidcreate_form: update_bidcreate_form,
                                                                            });
                                                                        }}
                                                                        renderToken={(option, props, index) => (
                                                                            <Token key={index} onRemove={props.onRemove} option={option}>
                                                                                {option.first_name?.slice(0, 1) + option.last_name?.slice(0, 1)}
                                                                            </Token>
                                                                        )}
                                                                    />
                                                                    {!bidcreate_form.bid_teams.valid && bidcreate_form.bid_teams.onBlur_out ? <div className="error field-error">{bidcreate_form.bid_teams.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card mb-5 mb-xl-6 bid-team-outer">
                                                        <div className="card-header border-0 pt-5 mb-4 row">
                                                            {this.state.formdata.length === 0 && <div className="col-sm-12">
                                                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                    <img src="assets/images/icons/docs.svg" title="all_bids" alt="all_bids" />
                                                                    <h3 className="card-title align-items-start flex-column ms-4">
                                                                        <span className="card-label fw-bolder fs-2x mb-1 color1">Bid Documents</span>
                                                                        <span className="fw-normal text-dark-muted fs-6 text-gray ">Upload documents that provide context to the bid response.</span>
                                                                        <span className="fw-normal text-dark-muted fs-6 text-gray ">These documents can include the Request for Tender (RFT) or Request for Proposal (RFP) documents.</span>
                                                                    </h3>
                                                                </div>
                                                            </div>}
                                                            {this.state.formdata.length !== 0 && <div className="col-sm-9">
                                                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                    <img src="assets/images/icons/docs.svg" title="all_bids" alt="all_bids" />
                                                                    <h3 className="card-title align-items-start flex-column ms-4">
                                                                        <span className="card-label fw-bolder fs-2x mb-1 color1">Bid Documents</span>
                                                                        <span className="fw-normal text-dark-muted fs-6 text-gray ">Upload documents that provide context to the bid response.</span>
                                                                    </h3>
                                                                </div>
                                                            </div>}
                                                            {
                                                                this.state.formdata.length !== 0 && <div className="col-sm-3">
                                                                    <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                        <ButtonDropzone
                                                                            multiple={false}
                                                                            rightSideButton={false}
                                                                            // file_types={["application/pdf"]}
                                                                            // inputaccept={"application/pdf"}
                                                                            FileUploadHandleChange={(files) => {
                                                                                this.BidAddDocument(files[0]);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            {this.state.formdata.length === 0 && <Dropzone
                                                                multiple={false}
                                                                isTender={true}
                                                                // file_types={["application/pdf"]}
                                                                // inputaccept={"application/pdf"}
                                                                FileUploadHandleChange={(files) => {
                                                                    this.BidAddDocument(files[0]);
                                                                }}
                                                            />
                                                            }
                                                            <div className="upload-document-outer">
                                                                {this.state.formdata.length !== 0 &&
                                                                    this.state.formdata.map((media, media_key) => {
                                                                        return (
                                                                            <div className="bid_response_document" key={"bid_document_key_" + media.file.name + media_key}>
                                                                                <div className="row">
                                                                                    <div className="col-sm-10">
                                                                                        <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                                            <img src="assets/images/icons/successfully.svg" title="all_bids" alt="all_bids" />
                                                                                            <div className="card-title align-items-start flex-column ms-4 d-flex flex-grow-1">
                                                                                                <span className="success-title card-label fw-bold-700 fs-6 ">
                                                                                                    {media.file?.name}
                                                                                                </span>
                                                                                                <span className="mt-1 fw-normal text-gray fs-7">
                                                                                                    {userActions.bytesToSize(media.file?.size)} {media.file?.type.split("/").pop().toUpperCase()} | Successfully uploaded
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-2 text-end">
                                                                                        <span
                                                                                            className="del-btn-icon"
                                                                                            onClick={(event) => {
                                                                                                this.BidRemoveDocument(media_key);
                                                                                            }}
                                                                                        >
                                                                                            <i className="icon-delete"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-5 text-end">
                                                        <button type="submit" className="btn rounded-50 btn-lg btn-danger hvr-grow-arrow">
                                                            <span className="indicator-label">
                                                                Continue<i className="icon-right-arrow fs-9 ms-2"></i>{" "}
                                                            </span>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetTags: userActions.GetTags,
    onGetClients: userActions.GetClients,
    onGetBidTypes: userActions.GetBidTypes,
    onGetCompanyUsers: userActions.GetCompanyUsers,
    onBidUploadDocuments: userActions.BidUploadDocuments,
    onCreateBid: userActions.CreateBid,
    onCreateClient: userActions.CreateClient,
};
export default connect(mapStateToProps, mapDispatchToProps)(BidCreate);