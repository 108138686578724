import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

class FreeTrailSubscriptionExpiredModal extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    className="freetrailsubscriptionsxpired_modal bid-custom-modal "
                    show={this.props.freetrailsubscriptionsxpired_modal}
                    onHide={() => {
                        this.props.freetrailsubscriptionsxpired_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="text-center m-0">
                            <img className="mb-6" src="assets/images/icons/subscriptionsxpired.svg" alt="subscriptionsxpired" />
                            <h2 className="text-dark fw-bolder fs-22px">{this.props.subscriptiontrail_days} more days until your free trial expires!</h2>
                            <p className="mb-5 fs-6">Upgrade to keep all access to BidHQ. Got a question before you upgrade? Not sure which plan is right for you? Get in touch with our support team and we'll be happy to help!</p>
                        </div>
                        <div className="modal-footer">
                            <Link
                                to="#"
                                onClick={(event) => {
                                    window.location = "mailto:team@bidhq.com.au";
                                    event.preventDefault();
                                }}
                                className="btn copy-link btn-lg rounded-50 w-200px hvr-grow"
                            >
                                Get in touch with us
                            </Link>
                            <Link to="/settings-subscription" className="btn btn-danger remove-user rounded-50 w-175px hvr-grow">
                                Upgrade plan
                            </Link>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default FreeTrailSubscriptionExpiredModal;