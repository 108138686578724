import React from "react";
import { Chart } from "react-google-charts";

import exportAsImage from "../../components/Utility/exportAsImage";
class BidValues extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bidvalue_data: [["Year", "2021", "2022"]],
            dashboard_bidvalue_show: this.props.dashboard_bidvaluesection_show,
        };
    }

    componentDidMount() {
        let bid_values = this.props.bid_values?.bid_data;
        if (bid_values && bid_values[0] && bid_values[1]) {
            let bidvalue_data = [["Year", "2021", "2022"]];
            let pastYear = JSON.parse(bid_values[0]).pastYear;
            let currentYear = JSON.parse(bid_values[1]).currentYear;
            for (let key in pastYear) {
                bidvalue_data.push([pastYear[key].month, pastYear[key].bid_value, currentYear[key].bid_value]);
            }
            this.setState({ bidvalue_data: bidvalue_data });
        }
    }

    BidValuesReload() {
        let bid_values = this.props.bid_values?.bid_data;
        if (bid_values && bid_values[0] && bid_values[1]) {
            let bidvalue_data = [["Year", "2021", "2022"]];
            let pastYear = JSON.parse(bid_values[0]).pastYear;
            let currentYear = JSON.parse(bid_values[1]).currentYear;
            for (let key in pastYear) {
                bidvalue_data.push([pastYear[key].month, pastYear[key].bid_value, currentYear[key].bid_value]);
            }
            this.setState({ bidvalue_data: bidvalue_data });
        }
    }

    render() {
        const { bidvalue_data, dashboard_bidvalue_show } = this.state;
        return (
            <React.Fragment>
                <div className="col-xl-8 notify-activity">
                    <div className={"card mb-lg-8" + (dashboard_bidvalue_show ? " card-xl-stretch " : "")}>
                        <div className="card-header align-items-center border-0 mt-4">
                            <h4 className="card-title align-items-start flex-column m-0">
                                <span className="fw-bolder text-main-color">Bid Values ($) </span>
                                <span className="text-muted fw-normal fs-6 text-grey-dark">Value (all time).</span>
                            </h4>
                            <div className="card-toolbar">
                                <div className="px-1 cursor-pointer">
                                    <div className="" onClick={(event) => exportAsImage(this.dashboard_bidvalue_ref, "Bid Values")}>
                                        <i className="icon-download me-2 text-gray fs-4"></i> <span></span>
                                    </div>
                                </div>
                                <div className="cursor-pointer eye-outer">
                                    <div className="" onClick={(event) => this.props.dashboard_bidvaluesection_show_action(dashboard_bidvalue_show ? false : true)}>
                                        <i className={"text-gray hide-fs" + (dashboard_bidvalue_show ? " icon-eye font-size-8" : " bi bi-eye fs-4")}></i> <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ref={(node) => (this.dashboard_bidvalue_ref = node)} className={"card-body pt-0" + (dashboard_bidvalue_show ? "" : " d-none")}>
                            <div className="d-flex flex-column mob-hide">
                                {bidvalue_data.length > 1 ? (
                                    <>
                                        <Chart
                                            chartType="ColumnChart"
                                            width={"100%"}
                                            height={"300px"}
                                            data={bidvalue_data}
                                            options={{
                                                legend: {
                                                    position: "none",
                                                },
                                                bar: {
                                                    groupWidth: "100%",
                                                },
                                                colors: ["#5028DC", "#6496FA"],
                                                chartArea: {
                                                    left: 30,
                                                    width: "100%",
                                                },
                                                vAxis: {
                                                    format: "short",
                                                },
                                            }}
                                        />
                                        <div className="d-flex justify-content-evenly">
                                            <div className="activity-main d-flex">
                                                <div className="symbol symbol-30px me-1">
                                                    <i className="bi bi-circle-fill mt-1 text-main-color"></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <span className="fw-normal fs-7">2021</span>
                                                </div>
                                            </div>
                                            <div className="activity-main d-flex">
                                                <div className="symbol symbol-30px me-1">
                                                    <i className="bi bi-circle-fill mt-1 text-blue"></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <span className="fw-normal fs-7">2022</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <p> No records found</p>
                                )}
                            </div>

                            <div className="d-flex flex-column desktop-none mob-show">
                                {bidvalue_data.length > 1 ? (
                                    <>
                                        <Chart
                                            chartType="ColumnChart"
                                            width={"100%"}
                                            height={"300px"}
                                            data={bidvalue_data}
                                            options={{
                                                tooltips: {
                                                    enabled: "false",
                                                },
                                                isStacked: "true",
                                                legend: {
                                                    position: "none",
                                                },
                                                bar: {
                                                    groupWidth: "80%",
                                                },
                                                colors: ["#5028DC", "#6496FA"],
                                                chartArea: {
                                                    left: 30,
                                                    width: "100%",
                                                },

                                                vAxis: {
                                                    format: "short",
                                                    minorGridlines: { count: 0 },

                                                    gridlines: {
                                                        color: "#dddddd",
                                                        borderDash: [4, 4],
                                                        minSpacing: 50,
                                                    },

                                                    // baselineColor: 'rgb(221, 221, 221)',
                                                },
                                                hAxis: {
                                                    textPosition: "none",
                                                },
                                                // scales: {

                                                // vAxis: [{ticks: {beginAtZero: "true"}}]
                                                // }
                                            }}
                                        />
                                        <div className="d-flex justify-content-evenly">
                                            <div className="activity-main d-flex">
                                                <div className="symbol symbol-30px me-1">
                                                    <i className="bi bi-circle-fill mt-1 text-main-color"></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <span className="fw-normal fs-7">2021</span>
                                                </div>
                                            </div>
                                            <div className="activity-main d-flex">
                                                <div className="symbol symbol-30px me-1">
                                                    <i className="bi bi-circle-fill mt-1 text-blue"></i>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <span className="fw-normal fs-7">2022</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <p> No records found</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default BidValues;