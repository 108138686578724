import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";

import * as userActions from "../../../actions/index";
import Loader from "../../../components/Utility/Loader";

import PdfDesignViewThumbnail from "../../../components/Utility/PdfDesignViewThumbnail";

class BidResponseShare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bid_id: "",
            bid_detail: [],

            response_pdf: "",
            response_pdf_thumbnail: "",

            response_content: [],
            response_media_selected: "",
        };
    }

    async componentDidMount() {
        this.setState({ bid_id: this.props.bid_id }, function () {
            this.GetBidLinkShareExtenal();
        });
    }

    GetBidLinkShareExtenal = () => {
        this.props.onGetBidLinkShareExtenal(this.state.bid_id).then((response) => {
            if (response.success === true) {
                this.setState({
                    bid_detail: response.data,

                    response_pdf: response?.response_pdf,
                    response_pdf_thumbnail: response?.response_pdf_thumbnail,

                    response_content: response.data?.response_media,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    BidResponseMediaSelected = (media) => {
        if (media === "response_pdf") {
            media = {
                bid_id: this.state.bid_id,
                media_type: "application/pdf",
                avatar: this.state.response_pdf,
                avatar_title: this.state.bid_detail?.bid_title,
            };
            this.setState({
                response_media_selected: media,
            });
        } else if (media.media_type.indexOf("jpeg") !== -1 || media.media_type.indexOf("png") !== -1 || media.media_type.indexOf("gif") !== -1 || media.media_type.indexOf("pdf") !== -1) {
            this.setState({
                response_media_selected: media,
            });
        } else {
            const link = document.createElement("a");
            link.href = media.avatar;
            // link.setAttribute("download");
            document.body.appendChild(link);
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
        }
    };

    GenrateBidLinkShareExtenalZip = () => {
        this.props.onGenrateBidLinkShareExtenalZip(this.state.bid_detail.bid_id).then((response) => {
            if (response.success === true) {
                this.setState(
                    {
                        bidzip_url: response.bidzip_url,
                    },
                    function () {
                        this.DownloadBidZip(response.bidzip_url);
                    }
                );
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    DownloadBidZip(bidzip_url) {
        const link = document.createElement("a");
        link.href = bidzip_url;
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    render() {
        const { bid_detail, response_pdf_thumbnail, response_media_selected } = this.state;
        return (
            <React.Fragment>
                {this.props.loader ? <Loader /> : null}
                <div className="d-flex flex-column flex-root share-body">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div className="container-xxl">
                                        <div className="row h-100">
                                            <div className="col-lg-12">
                                                {response_media_selected === "" ? (
                                                    <div className="response_media_archive">
                                                        {bid_detail?.company && (
                                                            <div className="mb-8 p-pics d-flex justify-content-center">
                                                                <div className="symbol symbol-100px symbol-fixed position-relative">
                                                                    <img title="company_logo" alt="company_logo" src={bid_detail?.company?.avatar ? bid_detail?.company?.avatar : "assets/images/company_dummy.jpg"} />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {bid_detail?.bid_id && (
                                                            <div className="share-inner-body">
                                                                <div className="row">
                                                                    <div className="col-md-3" onClick={(event) => this.BidResponseMediaSelected("response_pdf")} key={"reviewlocktabdocument_key_" + bid_detail.bid_id}>
                                                                        <div className="review1">
                                                                            <div className="review-top pt-3 thumbnail_view">
                                                                                <div className="align_font mb-1 thumbnail_view_item">
                                                                                    {response_pdf_thumbnail ? (
                                                                                        <img className="img-fluid" src={response_pdf_thumbnail} alt="" />
                                                                                    ) : (
                                                                                        <img className="img-fluid" src="assets/images/pdf-thumb.png" alt="" />
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="review-bottom py-4 px-3">
                                                                                <span className="text-dark fw-bold-700">{bid_detail?.bid_title}</span>
                                                                                <p className="text-gray-400 m-0">Bid response</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {bid_detail.response_media &&
                                                                        bid_detail.response_media.length !== 0 &&
                                                                        bid_detail.response_media.map((media_detail, media_key) => (
                                                                            <div className="col-md-3" onClick={(event) => this.BidResponseMediaSelected(media_detail)} key={"reviewlocktabdocument_key_" + media_detail.response_media_id}>
                                                                                <div className="review1">
                                                                                    <div className="review-top pt-3 thumbnail_view">
                                                                                        {(media_detail.media_type.indexOf("jpeg") !== -1 || media_detail.media_type.indexOf("png") !== -1 || media_detail.media_type.indexOf("gif") !== -1) && (
                                                                                            <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                                <img width="100%" height="150px" src={media_detail?.avatar} alt={media_detail?.avatar_title} title={media_detail?.avatar_title} />
                                                                                            </div>
                                                                                        )}
                                                                                        {media_detail.media_type.indexOf("pdf") !== -1 && (
                                                                                            <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                                <i className="text-grey-dark icon-PDF fs-5x" aria-hidden="true"></i>
                                                                                            </div>
                                                                                        )}
                                                                                        {media_detail.media_type.indexOf("msword") !== -1 && (
                                                                                            <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                                <i className="text-grey-dark icon-DOC fs-5x" aria-hidden="true"></i>
                                                                                            </div>
                                                                                        )}
                                                                                        {media_detail.media_type.indexOf("spreadsheetml") !== -1 && (
                                                                                            <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                                <i className="text-grey-dark icon-XLS fs-5x" aria-hidden="true"></i>
                                                                                            </div>
                                                                                        )}
                                                                                        {media_detail.media_type.indexOf("officedocument") !== -1 && media_detail.media_type.indexOf("spreadsheetml") === -1 && (
                                                                                            <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                                <i className="text-grey-dark icon-DOC fs-5x" aria-hidden="true"></i>
                                                                                            </div>
                                                                                        )}
                                                                                        {media_detail.media_type.indexOf("excel") !== -1 && (
                                                                                            <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                                <i className="text-grey-dark icon-xls-new fs-5x" aria-hidden="true"></i>
                                                                                            </div>
                                                                                        )}
                                                                                        {media_detail.media_type.indexOf("csv") !== -1 && (
                                                                                            <div className="align_font mb-1 thumbnail_view_item">
                                                                                                <i className="text-grey-dark icon-csv-new fs-5x" aria-hidden="true"></i>
                                                                                            </div>
                                                                                        )}
                                                                                        {media_detail.media_type.indexOf("html") !== -1 && (
                                                                                            <div className="align_font mb-1 thumbnail_view_item curser_pointer">
                                                                                                <i className="text-grey-dark icon-HTML fs-5x" aria-hidden="true"></i>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="review-bottom py-4 px-3">
                                                                                        <span className="text-dark fw-bold-700">{media_detail?.avatar_title}</span>
                                                                                        <p className="text-gray-400 m-0">Bid response</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    <div className="nav_footer_button text-center">
                                                                        <button type="button" className="btn btn-danger btn-md rounded-50 hvr-grow-arrow" onClick={(event) => this.GenrateBidLinkShareExtenalZip(event)}>
                                                                            <span className="icon-download fs-8 me-1"></span> Download all files
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="share-footer mt-12 mb-10 text-center">
                                                            <span className="created-by-txt fw-bold me-2">Created with</span>
                                                            <Link className="big-logo" to="/dashboard" target="_blank">
                                                                <img alt="Logo" src="assets/images/logo.svg" className="logo" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="response_media_detail h-100">
                                                        <div className="row h-100">
                                                            <div className="col-md-3 r-media-left">
                                                                <div className="d-flex align-items-stretch flex-column h-100 justify-content-between">
                                                                    <div className="bid-info-top">
                                                                        {bid_detail?.company && (
                                                                            <div className="me-7 mb-8 p-pics">
                                                                                <div className="symbol symbol-100px symbol-fixed position-relative">
                                                                                    <img title="company_logo" alt="company_logo" src={bid_detail?.company?.avatar ? bid_detail?.company?.avatar : "assets/images/company_dummy.jpg"} />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <h2 className="response-media-title">{response_media_selected?.avatar_title}</h2>
                                                                        <span
                                                                            className="go-back-btn cursor-pointer"
                                                                            onClick={(event) => {
                                                                                this.setState({
                                                                                    response_media_selected: "",
                                                                                });
                                                                            }}
                                                                        >
                                                                            <i className="icon-left-arrow fs-9 me-2"></i> Go back to all files
                                                                        </span>
                                                                    </div>
                                                                    <div className="nav_footer_button ">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger btn-md rounded-50 hvr-grow-arrow"
                                                                            onClick={(event) => {
                                                                                this.DownloadBidZip(response_media_selected?.avatar);
                                                                            }}
                                                                        >
                                                                            <i className="icon-download fs-8 me-1"></i> Download file
                                                                        </button>
                                                                        <div className="share-footer mt-7 p-0">
                                                                            <span className="created-by-txt fw-bold me-2">Created with</span>
                                                                            <Link className="big-logo" to="/dashboard" target="_balank">
                                                                                <img alt="Logo" src="assets/images/logo.svg" className="logo" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 iframe-out">
                                                                {(response_media_selected.media_type.indexOf("jpeg") !== -1 ||
                                                                    response_media_selected.media_type.indexOf("png") !== -1 ||
                                                                    response_media_selected.media_type.indexOf("gif") !== -1) && (
                                                                        <img src={response_media_selected?.avatar} alt={response_media_selected?.avatar_title} title={response_media_selected?.avatar_title} />
                                                                    )}

                                                                {response_media_selected.media_type.indexOf("pdf") !== -1 && <PdfDesignViewThumbnail documentUrl={response_media_selected?.avatar} />}

                                                                {(response_media_selected.media_type.indexOf("msword") !== -1 || response_media_selected.media_type.indexOf("officedocument") !== -1) && (
                                                                    <iframe
                                                                        width="100%"
                                                                        height="600px"
                                                                        frameBorder="0"
                                                                        title={response_media_selected?.avatar_title}
                                                                        className={response_media_selected?.media_type}
                                                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${response_media_selected?.avatar}`}
                                                                    />
                                                                )}

                                                                {response_media_selected.media_type.indexOf("excel") !== -1 && (
                                                                    <iframe
                                                                        width="100%"
                                                                        height="600px"
                                                                        frameBorder="0"
                                                                        title={response_media_selected?.avatar_title}
                                                                        className={response_media_selected?.media_type}
                                                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${response_media_selected?.avatar}`}
                                                                    />
                                                                )}
                                                                {response_media_selected.media_type.indexOf("html") !== -1 && (
                                                                    <iframe
                                                                        width="100%"
                                                                        height="600px"
                                                                        frameBorder="0"
                                                                        title={response_media_selected?.avatar_title}
                                                                        className={response_media_selected?.media_type}
                                                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${response_media_selected?.avatar}`}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetBidLinkShareExtenal: userActions.GetBidLinkShareExtenal,
    onGenrateBidLinkShareExtenalZip: userActions.GenrateBidLinkShareExtenalZip,
};
export default connect(mapStateToProps, mapDispatchToProps)(BidResponseShare);