import React, { useState, useEffect, useCallback, useRef } from "react";
import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";

import HeaderNotificationsSidebarItem from "./HeaderNotificationsSidebarItem";

import * as userActions from "../../actions/index";

const HeaderNotificationsSidebar = (props) => {
    const dispatch = useDispatch();
    const HeaderNotificationsSidebarItemEvent = useRef(null);

    const [filterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);

    const [desktop_notifications, setdesktop_notifications] = useState([]);
    const [desktop_notificationcount, setdesktop_notificationcount] = useState(0);

    const GetDesktopNotificationList = useCallback(async () => {
        await dispatch(userActions.GetDesktopNotificationList(filterlimit, filterpage)).then((response) => {
            if (response.success === true) {
                if (response.data.length > 0) {
                    if (response?.data) {
                        let new_desktop_notifications = desktop_notifications;
                        let response_desktop_notifications = response?.data;
                        for (let response_desktop_notification of response_desktop_notifications) {
                            new_desktop_notifications.push(response_desktop_notification);
                        }
                        setdesktop_notifications(new_desktop_notifications);
                    }
                    setdesktop_notificationcount(response.count);
                    HeaderNotificationsSidebarItemEvent.current?.HeaderNotificationsSidebarReload();
                }
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, desktop_notifications, filterlimit, filterpage]);

    useEffect(() => {
        GetDesktopNotificationList();
    }, [GetDesktopNotificationList]);

    /*** On Div Scroll Event Start ***/
    const prevScrollY = useRef(0);
    const onScrollNotificationLoad = async (event) => {
        if (desktop_notifications?.length !== 0 && desktop_notifications.length !== desktop_notificationcount) {
            const currentScrollY = event.target.scrollTop;
            if (prevScrollY.current < currentScrollY) {
                let new_filterpage = Number(filterpage) + 1;
                setfilterpage(new_filterpage);
                prevScrollY.current = currentScrollY;
            }
        }
    };
    /*** On Div Scroll Event End ***/

    const NotificationMarkRead = async (event, desktop_notification) => {
        await dispatch(userActions.NotificationMarkRead(desktop_notification.system_user_log_id)).then((response) => {
            if (response.success === true) {
                if (desktop_notification?.response_detail?.section_uuid && desktop_notification?.action_type.indexOf("bidsection_") !== -1) {
                    window.location = `/bid-response/${desktop_notification?.bid_detail?.bid_uuid}?activesection=${desktop_notification?.response_detail?.section_uuid}`;
                } else {
                    window.location = `/bid-response/${desktop_notification?.bid_detail?.bid_uuid}`;
                }
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    return (
        <React.Fragment>
            <div className="d-flex align-items-center ms-1 me-lg-2 notify-mob-outer">
                <div className="notification-comment-overlay">
                    <div className="right-sidebar bid_create_rightsidebar super-admin-notification">
                        <div className="wrap-right-info wrap-right-sidebar" id="header_notifications_sidebar">
                            <div className="comment-close-button" onClick={(event) => props.HeaderNotificationsSidebarAction(event, false)}>
                                <i className="icon-cross-2" aria-hidden="true"></i>
                            </div>
                            <div className="card-toolbar m-0">
                                <div className="card-header border-0 pt-5">
                                    <h2 className="card-title align-items-start flex-column fw-bolder text-dark">Notifications</h2>
                                </div>
                                <div className="p-5 scroll-y notify-scroll-height" onScroll={(event) => onScrollNotificationLoad(event)}>
                                    <HeaderNotificationsSidebarItem
                                        ref={HeaderNotificationsSidebarItemEvent}
                                        desktop_notifications={desktop_notifications}
                                        propsNotificationMarkRead={(event, desktop_notification) => NotificationMarkRead(event, desktop_notification)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default HeaderNotificationsSidebar;