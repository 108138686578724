const Validation = (form_element) => {
    let value = form_element.value;
    let rule = form_element.validations;

    value = typeof value === "object" ? JSON.stringify(value) : value;
    value = typeof value === "boolean" ? JSON.stringify(value) : value;

    if (rule.required) {
        if (value == null || (typeof value === "string" && value.trim() === "") || (typeof value === "number" && value === "")) {
            return {
                valid: false,
                error_msg: "This field is required!",
            };
        }
    }

    // If filed is email
    if (rule.email) {
        let email_regexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        // let email_regexp = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
        if (email_regexp.test(value) === false) {
            return {
                valid: false,
                error_msg: "Email format is incorrect.",
            };
        }
    }

    // Password Validation
    if (rule.password_validation) {
        let password_regexp = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,40}$/;
        if (!value.match(password_regexp)) {
            return {
                valid: false,
                error_msg: "Password should be at least 8 characters and include a number and special character",
            };
        }
    }

    // Validate min length
    if (rule.min_length) {
        if (value.length < rule.min_length) {
            return {
                valid: false,
                error_msg: "Please enter the correct format",
            };
        }
    }

    // Validate Max length
    if (rule.max_length) {
        if (value.length > rule.max_length) {
            return {
                valid: false,
                error_msg: "Please enter the correct format",
            };
        }
    }

    // Validate Phone Number length
    if (rule.phone_length) {
        if (value.length < rule.phone_length) {
            return {
                valid: false,
                error_msg: "Invalid phone format. ex: " + form_element.placeholder,
            };
        }
    }

    // Field typeahead not empty
    if (rule.typeahead) {
        if (value === "[]") {
            return {
                valid: false,
                error_msg: "This field is required!",
            };
        }
    }

    // Field accept only number and space
    if (rule.NumberAndSpace) {
        if (value) {
            const reg = /^[0-9 ]+$/;
            if (!reg.test(value)) {
                return {
                    valid: false,
                    error_msg: "Please enter the correct format",
                };
            }
        }
    }

    // Check a CreditCardNumber Validate
    if (rule.CreditCardNumber) {
        let regexp = /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;
        if (regexp.test(value) === false) {
            return {
                valid: false,
                error_msg: "Invalid credit card number number",
            };
        }
    }

    // Check a CreditCardExpiry Validate
    // if (rule.CreditCardExpiry) {
    // console.log("value----------",value)
    // }

    // Check a CreditCardCVV Validate
    if (rule.CreditCardCVV) {
        var cvvRegex = /^[0-9]{3,4}$/;
        if (cvvRegex.test(value) === false) {
            return {
                valid: false,
                error_msg: "Invalid cvv number",
            };
        }
    }

    if (!rule.required) {
        return {
            valid: true,
            error_msg: "",
        };
    }

    return {
        valid: true,
        error_msg: "",
    };
};

export default Validation;