import React from "react";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";
import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import Validations from "../../components/Utility/Validations";
import SocialButton from "../../components/Utility/SocialButton";
import { google_client_id } from "../../constants/actionTypes";

class signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            register_form: {
                first_name: {
                    type: "text",
                    label: "First name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                last_name: {
                    type: "text",
                    label: "Last name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                email: {
                    label: "Email address *",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },
                phone: {
                    type: "phone",
                    label: "Phone number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                password: {
                    label: "Password *",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, password_validation: true },
                },
                confirm_password: {
                    label: "Confirm password *",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, password_validation: true },
                },
            },
            passwordshow: false,
            confirm_passwordshow: false,
            redirect_url: "",
            isAuthenticated: false,
        };
    }

    inputChangeHandler(event, identifier) {
        const update_register_form = { ...this.state.register_form };
        const form_element = { ...update_register_form[identifier] };

        if (identifier === "remember") {
            form_element.isChecked = event.target.checked;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_register_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_register_form) {
            is_form_valid = update_register_form[identifier].valid && is_form_valid;
        }

        if (update_register_form.password.value && update_register_form.confirm_password.value) {
            if (update_register_form.password.value !== update_register_form.confirm_password.value) {
                update_register_form.confirm_password.valid = false;
                update_register_form.confirm_password.error_msg = "Passwords do not match";
            }
        }
        this.setState({ register_form: update_register_form });
    }

    SignUpSubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_register_form = { ...this.state.register_form };
        for (let key in update_register_form) {
            let form_element = update_register_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_register_form[key] = form_element;
        }

        if (update_register_form.password.value && update_register_form.confirm_password.value) {
            if (update_register_form.password.value !== update_register_form.confirm_password.value) {
                update_register_form.confirm_password.valid = false;
                update_register_form.confirm_password.error_msg = "confirm password not match";
                is_form_valid = false
            }
        }

        this.setState({ register_form: update_register_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.register_form) {
                form_data[key] = this.state.register_form[key].value;
            }
            this.props.onSignUpValidate(form_data).then((response) => {
                if (response.success === true) {
                    localStorage.setItem("signUpForm", JSON.stringify(form_data));
                    this.props.history.push("/subscription");
                } else {
                    notify.show(response.message, "error");
                }
            })
        }
    };

    handleSocialSignUp = (user) => {
        this.props.onSocialSignUpValidate(user).then((response) => {
            if (response.success === true) {
                let socualUser = {
                    email: user._profile.email,
                    profilePicURL: user._profile.profilePicURL,
                    first_name: user._profile.firstName,
                    last_name: user._profile.lastName,
                    profileId: user._profile.id
                }
                localStorage.setItem("signUpForm", JSON.stringify(socualUser));
                this.props.history.push("/subscription");
            } else {
                notify.show(response.message, "error");
            }
        })
    };

    handleSocialSignUpFailure = (error) => {
        console.log("error -------------", error);
    };

    render() {
        const { register_form } = this.state;
        return (
            <React.Fragment>
                <Notifications />
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.redirect_url ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                        <div class="d-flex flex-column flex-lg-row-auto w-xl-500px positon-xl-relative bg-dark-blue-color">
                            <div class="d-flex flex-column text-center-md position-xl-fixed top-0 bottom-0 w-xl-500px scroll-y">
                                <div class="d-flex flex-row-fluid flex-column pt-lg-10 ps-lg-20">
                                    <a href="#" class="mb-10 ps-lg-10">
                                        <img alt="Logo" src="assets/images/logo.svg" class="h-60px" />
                                    </a>
                                    <h1 class="fw-bold fs-3 pb-md-5 ps-md-10 mb-0 orange-text pe-md-15">Take control of your bidding
                                        success by trying out BidHQ for 30 days</h1>
                                    <h3 class="fw-normal  pb-md-5 ps-md-10 pe-md-15 mb-0 text-white">Start your 30-day trial of BidHQ and
                                        discover how it empowers you to:</h3>

                                    <ul class=" m-0 pe-md-15 text-white check-list-latest">
                                        <li>Effortlessly manage bids: Track, collaborate, and streamline your bidding process with ease.
                                        </li>
                                        <li>Make data-driven decisions: Gain valuable insights from analytics and reports to enhance your
                                            bidding strategy.</li>
                                        <li>Maximise your winning potential: Leverage BidHQ's powerful tools and features to gain a
                                            competitive edge and increase your chances of winning lucrative contracts.</li>
                                    </ul>
                                </div>
                                <div
                                    class="d-flex flex-row-auto bgi-no-repeat subs-sign-in-banner bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px">
                                    <img src="assets/images/subs-side-img.png" alt="" class="w-100" />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-lg-row-fluid py-9">
                            <div className="d-flex flex-center flex-column flex-column-fluid">
                                <div className="w-lg-600px p-10 pb-0 pt-0 mx-auto w-100">
                                    <form className="form w-100" autoComplete="off" onSubmit={this.SignUpSubmitHandler}>
                                        <div className="mb-9">
                                            <h1 className="fw-bolder text-dark mb-2 fs-3qx">Sign up</h1>
                                            <div className="fw-bolder text-dark fs-2x fs-4">Let’s create your account.</div>
                                        </div>
                                        <div className="row fv-row">
                                            <div className={"col-xl-6 mb-6" + (!register_form.first_name.valid && register_form.first_name.onBlur_out ? " error-field" : "")}>
                                                <label className="form-label fs-6">{register_form.first_name.label}</label>
                                                <input
                                                    autoComplete="new_first_name"
                                                    type={register_form.first_name.type}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={register_form.first_name.placeholder}
                                                    value={register_form.first_name.value ? register_form.first_name.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "first_name")}
                                                    onBlur={(event) => {
                                                        register_form.first_name.onBlur_out = true;
                                                        this.setState({
                                                            register_form: register_form,
                                                        });
                                                    }}
                                                />
                                                {!register_form.first_name.valid && register_form.first_name.onBlur_out ? <div className="error field-error">{register_form.first_name.error_msg}</div> : ""}
                                            </div>
                                            <div className={"col-xl-6 mb-6" + (!register_form.last_name.valid && register_form.last_name.onBlur_out ? " error-field" : "")}>
                                                <label className="form-label fs-6">{register_form.last_name.label}</label>
                                                <input
                                                    autoComplete="new_last_name"
                                                    type={register_form.last_name.type}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={register_form.last_name.placeholder}
                                                    value={register_form.last_name.value ? register_form.last_name.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "last_name")}
                                                    onBlur={(event) => {
                                                        register_form.last_name.onBlur_out = true;
                                                        this.setState({
                                                            register_form: register_form,
                                                        });
                                                    }}
                                                />
                                                {!register_form.last_name.valid && register_form.last_name.onBlur_out ? <div className="error field-error">{register_form.last_name.error_msg}</div> : ""}
                                            </div>
                                        </div>
                                        <div className="row fv-row">
                                            <div className={"col-xl-6 mb-6" + (!register_form.email.valid && register_form.email.onBlur_out ? " error-field" : "")}>
                                                <label className="form-label fs-6">{register_form.email.label}</label>
                                                <input
                                                    autoComplete="new_email"
                                                    name="email"
                                                    type={register_form.email.type}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={register_form.email.placeholder}
                                                    value={register_form.email.value ? register_form.email.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "email")}
                                                    onBlur={(event) => {
                                                        register_form.email.onBlur_out = true;
                                                        this.setState({
                                                            register_form: register_form,
                                                        });
                                                    }}
                                                />
                                                {!register_form.email.valid && register_form.email.onBlur_out ? <div className="error field-error">{register_form.email.error_msg}</div> : ""}
                                            </div>
                                            <div className={"col-xl-6 mb-6" + (!register_form.phone.valid && register_form.phone.onBlur_out ? " error-field" : "")}>
                                                <label className="form-label fs-6">{register_form.phone.label}</label>
                                                <input
                                                    autoComplete="new_phone"
                                                    name="phone"
                                                    type={register_form.phone.type}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={register_form.phone.placeholder}
                                                    value={register_form.phone.value ? register_form.phone.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "phone")}
                                                    onBlur={(event) => {
                                                        register_form.phone.onBlur_out = true;
                                                        this.setState({
                                                            register_form: register_form,
                                                        });
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                                {!register_form.phone.valid && register_form.phone.onBlur_out ? <div className="error field-error">{register_form.phone.error_msg}</div> : ""}
                                            </div>
                                        </div>
                                        <div className="row fv-row">
                                            <div className={"col-xl-6 mb-6" + (!register_form.password.valid && register_form.password.onBlur_out ? " error-field" : "")}>
                                                <label className="form-label fs-6">{register_form.password.label}</label>
                                                <div className="position-relative">
                                                    <input
                                                        autoComplete="new_password"
                                                        type={this.state.passwordshow ? "text" : "password"}
                                                        className="form-control form-control-lg form-control-solid"
                                                        placeholder={register_form.password.placeholder}
                                                        value={register_form.password.value ? register_form.password.value : ""}
                                                        onChange={(event) => this.inputChangeHandler(event, "password")}
                                                        onBlur={(event) => {
                                                            register_form.password.onBlur_out = true;
                                                            this.setState({
                                                                register_form: register_form,
                                                            });
                                                        }}
                                                    />
                                                    <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                        <i
                                                            className={this.state.passwordshow ? "bi bi-eye fs-2" : "bi bi-eye-slash fs-2"}
                                                            onClick={(event) => {
                                                                this.setState({ passwordshow: this.state.passwordshow ? false : true });
                                                            }}
                                                        ></i>
                                                    </span>
                                                </div>
                                                {!register_form.password.valid && register_form.password.onBlur_out ? <div className="error field-error">{register_form.password.error_msg}</div> : ""}
                                            </div>
                                            <div className={"col-xl-6 mb-6" + (!register_form.confirm_password.valid && register_form.confirm_password.onBlur_out ? " error-field" : "")}>
                                                <label className="form-label fs-6">{register_form.confirm_password.label}</label>
                                                <div className="position-relative ">
                                                    <input
                                                        autoComplete="new_confirm_password"
                                                        type={this.state.confirm_passwordshow ? "text" : "password"}
                                                        className="form-control form-control-lg form-control-solid"
                                                        placeholder={register_form.confirm_password.placeholder}
                                                        value={register_form.confirm_password.value ? register_form.confirm_password.value : ""}
                                                        onChange={(event) => this.inputChangeHandler(event, "confirm_password")}
                                                        onBlur={(event) => {
                                                            register_form.confirm_password.onBlur_out = true;
                                                            this.setState({
                                                                register_form: register_form,
                                                            });
                                                        }}
                                                    />
                                                    <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                        <i
                                                            className={this.state.confirm_passwordshow ? "bi bi-eye fs-2" : "bi bi-eye-slash fs-2"}
                                                            onClick={(event) => {
                                                                this.setState({ confirm_passwordshow: this.state.confirm_passwordshow ? false : true });
                                                            }}
                                                        ></i>
                                                    </span>
                                                </div>
                                                {!register_form.confirm_password.valid && register_form.confirm_password.onBlur_out ? <div className="error field-error">{register_form.confirm_password.error_msg}</div> : ""}
                                            </div>
                                        </div>
                                        <div className="fv-row mb-6">
                                            <div className="fs-8">
                                                By clicking sign up, you are creating an email account, and you agree to BidHQ’s
                                                <Link to={{ pathname: "https://bidhq.com.au/end-user-agreement" }} target="_blank" className="ms-1 link-black">
                                                    terms of service
                                                </Link>{" "}
                                                and
                                                <Link to={{ pathname: "https://bidhq.com.au/privacy" }} target="_blank" className="ms-1 link-black">
                                                    privacy policy
                                                </Link>
                                                .
                                            </div>
                                        </div>
                                        <div className="text-center mb-6">
                                            <button type="submit" className="hvr-grow btn rounded-50 btn-lg btn-red w-100">
                                                <span className="indicator-label">Start my 30-day trial</span>{" "}
                                                <span className="indicator-progress">
                                                    Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>
                                        <div className="d-flex align-items-center mb-6">
                                            <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                                            <span className="fw-bold text-gray-400 fs-7 mx-2">or</span>
                                            <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                                        </div>
                                    </form>
                                    <SocialButton provider="google" appId={google_client_id} onLoginSuccess={this.handleSocialSignUp} onLoginFailure={this.handleSocialSignUpFailure}>
                                        <img src="assets/images/icons/google-g.svg" title="google-logo" alt="google-logo" className="h-20px me-3" />
                                        Sign up with Google
                                    </SocialButton>
                                </div>
                            </div>
                            <div className="d-flex flex-center flex-wrap fs-6 p-10 pb-0">
                                <div className="d-flex flex-center fw-bold already-signin-signup">
                                    Already have an account?
                                    <Link to="/signin" className="sign-btn ms-1 link-primary">
                                        Log in
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onSocialSignUp: userActions.SocialSignUp,
    onSignUpValidate: userActions.SignUpValidate,
    onSocialSignUpValidate: userActions.SocialSignUpValidate,
    onGetAuthUser: userActions.GetAuthUser,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(signup));