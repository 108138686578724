import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import * as userActions from "../../../../actions/index";
import Validations from "../../../../components/Utility/Validations";
class CompanyUserRemoveModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            removeuser_form: {
                user_id: {
                    label: "Invite Id",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                reassign_projects: {
                    label: "Reassign projects to:",
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: true },
                },
                include_completed_tasks: {
                    valid: true,
                    error_msg: "",
                    value: "",
                    type: "checkbox",
                    label: " Include completed tasks",
                    validations: { required: false },
                },
            },
        };
    }

    componentDidMount() {
        const update_removeuser_form = { ...this.state.removeuser_form };
        update_removeuser_form.user_id.value = this.props.remove_company_user.user_id;

        this.setState({
            removeuser_form: update_removeuser_form,
        });
    }

    inputChangeHandler(event, identifier) {
        const update_removeuser_form = { ...this.state.removeuser_form };
        const form_element = { ...update_removeuser_form[identifier] };
        if (identifier === "include_completed_tasks") {
            form_element.isChecked = event.target.checked;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_removeuser_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_removeuser_form) {
            is_form_valid = update_removeuser_form[identifier].valid && is_form_valid;
        }

        this.setState({
            removeuser_form: update_removeuser_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_removeuser_form = this.state.removeuser_form;
        for (let key in update_removeuser_form) {
            let form_element = update_removeuser_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_removeuser_form[key] = form_element;
        }
        this.setState({ removeuser_form: update_removeuser_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.removeuser_form) {
                form_data[key] = this.state.removeuser_form[key].value;
            }
            this.props.ConfirmCompanyUserRemove(form_data);
        }
    };

    render() {
        const { removeuser_form } = this.state;
        let remove_company_user = this.props.remove_company_user;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                <Modal
                    className="removeuser_modal"
                    show={this.props.remove_company_user_modal}
                    onHide={() => {
                        this.props.remove_company_user_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Remove {remove_company_user.first_name + " " + remove_company_user.last_name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="alert_warning">
                            {remove_company_user.first_name + " " + remove_company_user.last_name} will no longer be able to access {user_info.company.company_name}.
                        </p>
                        <p className="mg-bottom">
                            All of {remove_company_user.first_name + " " + remove_company_user.last_name} tasks will still be saved in BidHQ. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tellus non mus amet bibendum
                            posuere cras. Leo ultricies lorem gravida nisl ut sagittis, netus.
                        </p>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="row mb-4">
                                <div className="col-lg-12 fv-row">
                                    <label className="form-label fs-6">{removeuser_form.reassign_projects.label}</label>
                                    <AsyncTypeahead
                                        multiple
                                        isLoading={false}
                                        labelKey="first_name"
                                        id="reassign_projects"
                                        placeholder="Reassign projects to ...."
                                        onChange={(selected) => {
                                            if (selected.length !== 0) {
                                                const update_removeuser_form = { ...this.state.removeuser_form };
                                                const form_element = { ...update_removeuser_form["reassign_projects"] };
                                                form_element.value = selected.length !== 0 ? selected[0].user_id : "";
                                                update_removeuser_form["reassign_projects"] = form_element;
                                                this.setState({ removeuser_form: update_removeuser_form });
                                            }
                                        }}
                                        onSearch={(query) => {
                                            this.props.onGetCompanyUsers(query).then((response) => {
                                                this.setState({
                                                    isLoading: false,
                                                    all_invited_users: response.data,
                                                });
                                            });
                                        }}
                                        renderMenuItemChildren={(option, props) => (
                                            <React.Fragment>
                                                <img
                                                    className="rounded-circle"
                                                    title={option.first_name + " " + option.last_name}
                                                    alt={option.first_name + " " + option.last_name}
                                                    style={{ height: "40px", marginRight: "10px", width: "40px" }}
                                                    src={option.avatar ? option.avatar : "assets/images/profile_dummy.jpg"}
                                                />
                                                <span>{option.first_name + " " + option.last_name}</span>
                                            </React.Fragment>
                                        )}
                                        options={this.state.all_invited_users}
                                    />
                                    {!removeuser_form.reassign_projects.valid && removeuser_form.reassign_projects.onBlur_out ? <div className="error field-error">{removeuser_form.reassign_projects.error_msg}</div> : ""}
                                </div>
                            </div>
                            <div className="d-flex flex-stack mb-5">
                                <label className="form-check form-check-custom form-check-solid form-check-inline" htmlFor="signin_include_completed_tasks">
                                    <input
                                        id="signin_include_completed_tasks"
                                        className="form-check-input"
                                        type={removeuser_form.include_completed_tasks.type}
                                        value={removeuser_form.include_completed_tasks.value}
                                        checked={removeuser_form.include_completed_tasks.isChecked}
                                        onChange={(event) => this.inputChangeHandler(event, "include_completed_tasks")}
                                    />
                                    <span className="form-check-label">{removeuser_form.include_completed_tasks.label}</span>
                                </label>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="hover-new btn cancel-butn rounded-50 w-150" onClick={(event) => this.props.remove_company_user_modal_action(false)}>
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary remove-user rounded-50 w-150 hvr-grow">
                                    Remove user
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetInviteUsers: userActions.GetInviteUsers,
    onGetCompanyUsers: userActions.GetCompanyUsers,
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyUserRemoveModal);