import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import Validations from "../../components/Utility/Validations";

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resetpassword_form: {
                email: {
                    label: "Email Address",
                    type: "email",
                    value: localStorage.getItem("bidhq_forgotemail"),
                    valid: true,
                    error_msg: "",
                    validations: { required: true, email: true },
                },
                password: {
                    label: "Password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8 },
                },
                confirm_password: {
                    label: "Confirm Password",
                    type: "password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8 },
                },
            },
            loader: false,
            is_form_valid: false,
            passwordshow: false,
            confirm_passwordshow: false,
            redirect_url: "",
            isAuthenticated: false,
        };
    }

    async componentDidMount() {
        const update_resetpassword_form = { ...this.state.resetpassword_form };
        update_resetpassword_form.email.value = this.props.match.params.useremail;
        this.setState({ resetpassword_form: update_resetpassword_form });
    }

    inputChangeHandler(event, identifier) {
        event.preventDefault();

        const update_resetpassword_form = { ...this.state.resetpassword_form };
        const form_element = { ...update_resetpassword_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_resetpassword_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_resetpassword_form) {
            is_form_valid = update_resetpassword_form[identifier].valid && is_form_valid;
        }

        this.setState({
            resetpassword_form: update_resetpassword_form,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_resetpassword_form = this.state.resetpassword_form;
        for (let key in update_resetpassword_form) {
            let form_element = update_resetpassword_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_resetpassword_form[key] = form_element;
        }
        this.setState({
            resetpassword_form: update_resetpassword_form,
        });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.resetpassword_form) {
                form_data[key] = this.state.resetpassword_form[key].value;
            }
            this.props.onResetPassword(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/signin",
                        });
                    }, 1000);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    render() {
        const { resetpassword_form } = this.state;
        return (
            <React.Fragment>
                <Notifications />

                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                        <div className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative bg-dark-color">
                            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 text-center-md w-xl-600px scroll-y">
                                <div className="d-flex flex-column pt-lg-10 ps-lg-20">
                                    <Link to="signup" className="mb-10 ps-md-10">
                                        <img alt="Logo" src="assets/images/logo.svg" className="h-60px" />
                                    </Link>
                                    <h1 className="fw-bold-600 fs-50 pb-md-5 ps-md-10 mb-0 text-white">
                                        No more
                                        <br />
                                        confusion. <br />
                                        No more <br />
                                        missing out.
                                    </h1>
                                </div>
                                <div className="d-flex bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px sign-in-banner mt-12"></div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-lg-row-fluid py-9">
                            <div className="d-flex flex-center flex-column-fluid">
                                <div className="w-lg-600px p-10 pb-0 pt-0 mx-auto w-100 forgot-div">
                                    <form onSubmit={this.SubmitHandler} autoComplete="off">
                                        <div className="mb-5">
                                            <h1 className="fw-bolder text-dark mb-2 fs-3qx">Reset Password</h1>
                                        </div>
                                        <div className={"fv-row mb-5" + (!resetpassword_form.password.valid && resetpassword_form.password.onBlur_out ? " error-field" : "")}>
                                            <label className="form-label fs-6">{resetpassword_form.password.label}</label>
                                            <div className="position-relative">
                                                <input
                                                    type={this.state.passwordshow ? "text" : "password"}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={resetpassword_form.password.placeholder}
                                                    value={resetpassword_form.password.value ? resetpassword_form.password.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "password")}
                                                    onBlur={(event) => {
                                                        resetpassword_form.password.onBlur_out = true;
                                                        this.setState({
                                                            resetpassword_form: resetpassword_form,
                                                        });
                                                    }}
                                                />
                                                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                    <i
                                                        className={this.state.passwordshow ? "bi bi-eye fs-2" : "bi bi-eye-slash fs-2"}
                                                        onClick={(event) => {
                                                            this.setState({ passwordshow: this.state.passwordshow ? false : true });
                                                        }}
                                                    ></i>
                                                </span>
                                            </div>
                                            {!resetpassword_form.password.valid && resetpassword_form.password.onBlur_out ? <div className="error field-error">{resetpassword_form.password.error_msg}</div> : ""}
                                        </div>
                                        <div className={"fv-row mb-5" + (!resetpassword_form.confirm_password.valid && resetpassword_form.confirm_password.onBlur_out ? " error-field" : "")}>
                                            <label className="form-label fs-6">{resetpassword_form.confirm_password.label}</label>
                                            <div className="position-relative">
                                                <input
                                                    type={this.state.confirm_passwordshow ? "text" : "password"}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={resetpassword_form.confirm_password.placeholder}
                                                    value={resetpassword_form.confirm_password.value ? resetpassword_form.confirm_password.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "confirm_password")}
                                                    onBlur={(event) => {
                                                        resetpassword_form.confirm_password.onBlur_out = true;
                                                        this.setState({
                                                            resetpassword_form: resetpassword_form,
                                                        });
                                                    }}
                                                />
                                                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                    <i
                                                        className={this.state.confirm_passwordshow ? "bi bi-eye fs-2" : "bi bi-eye-slash fs-2"}
                                                        onClick={(event) => {
                                                            this.setState({ confirm_passwordshow: this.state.confirm_passwordshow ? false : true });
                                                        }}
                                                    ></i>
                                                </span>
                                            </div>
                                            {!resetpassword_form.confirm_password.valid && resetpassword_form.confirm_password.onBlur_out ? <div className="error field-error">{resetpassword_form.confirm_password.error_msg}</div> : ""}
                                        </div>
                                        <div className="d-flex flex-stack mb-5">
                                            <div className="d-flex">
                                                Already have an account?{" "}
                                                <Link to="/signin" className="sign-btn ms-1 link-primary">
                                                    Log in
                                                </Link>
                                            </div>
                                        </div>
                                        <button type="submit" className="hvr-grow btn btn-lg btn-primary rounded-50 w-100 mb-8 sign-inbtn">
                                            Reset Password
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onResetPassword: userActions.ResetPassword,
    onResentForgotOTP: userActions.ResentForgotOTP,
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);