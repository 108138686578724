import React, { useState, useEffect, useCallback, useRef } from "react";

import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";
import { render } from "@testing-library/react";

import CompanyUsersListItem from "./CompanyUsersListItem";
import CompanyUserInvitedItems from "./CompanyUserInvitedItems";

import ConfirmDialog from "../../../components/Utility/ConfirmDialog";

import Loader from "../../../components/Utility/Loader";
import LoginSidebar from "../../../components/SuperAdminSidebar";
import LoginHeader from "../../../components/SuperAdminHeader";
import LoginFooter from "../../../components/SuperAdminFooter";

import * as actionType from "../../../constants/actionTypes";
import * as SuperAdminUserActions from "../../../actions/SuperAdmin/index";

const CompanyUsersList = (props) => {
    const dispatch = useDispatch();
    const ConfirmDialogEvent = useRef(null);

    const [loader, setloader] = useState(false);

    const [userlist_type, setuserlist_type] = useState("company_user");

    const [company_id] = useState(props.company_id);
    const [companydetail, setcompanydetail] = useState("");
    const [companyusers, setcompanyusers] = useState([]);
    const [companyuserscount, setcompanyuserscount] = useState(0);

    const [filterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filtersort, setfiltersort] = useState("user_id");
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersearch, setfiltersearch] = useState(null);

    const GetCompanyById = useCallback(async () => {
        sessionStorage.setItem("selected_company_uuid", company_id);
        sessionStorage.setItem("company_user_page", `/superadmin/company-users/${sessionStorage.getItem("selected_company_uuid")}`);

        await dispatch(SuperAdminUserActions.GetCompanyById(company_id)).then((response) => {
            if (response.success === true) {
                setcompanydetail(response.data);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, company_id]);

    useEffect(() => {
        GetCompanyById();
    }, [GetCompanyById]);

    const GetCompanyUsers = useCallback(async () => {
        if (companydetail?.company_id) {
            await dispatch(
                SuperAdminUserActions.GetCompanyUsers({
                    company_id: companydetail.company_id,
                    search: filtersearch,
                    page: filterpage,
                    limit: filterlimit,
                    sort: filtersort,
                    order: filterorder,
                })
            ).then((response) => {
                if (response.success === true) {
                    setcompanyusers(response.data);
                    setcompanyuserscount(response.count);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    }, [dispatch, companydetail, filtersearch, filterpage, filterlimit, filtersort, filterorder]);

    useEffect(() => {
        GetCompanyUsers();
    }, [GetCompanyUsers]);

    const ConfirmChangeUserStatus = (user_detail, user_status) => {
        render(
            <ConfirmDialog
                title="User Status Change ?"
                cancelButtonText="Cancel"
                confirmButtonText="Confirm"
                body="Are you sure you want to change user status?"
                confirm_modal={true}
                ref={ConfirmDialogEvent}
                onConfirm={(event) => ChangeUserStatus(user_detail, user_status)}
            />
        );
    };

    const ChangeUserStatus = (user_detail, user_status) => {
        const form_data = new FormData();
        form_data.append("user_uud", user_detail.user_uud);
        form_data.append("user_status", user_status);

        setloader(true);
        dispatch(SuperAdminUserActions.ChangeUserStatus(form_data)).then((response) => {
            setloader(false);
            if (response.success === true) {
                GetCompanyById();
                ConfirmDialogEvent.current.ModalActionHide();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const ConfirmArchiveUser = (user_detail) => {
        render(
            <ConfirmDialog
                title="Archive User?"
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                body="Are you sure you want to archive this user?"
                confirm_modal={true}
                ref={ConfirmDialogEvent}
                onConfirm={(event) => DeleteUser(user_detail)}
            />
        );
    };

    const DeleteUser = (user_detail) => {
        setloader(true);
        dispatch(SuperAdminUserActions.DeleteUser(user_detail.user_id)).then((response) => {
            setloader(false);
            if (response.success === true) {
                GetCompanyById();
                ConfirmDialogEvent.current.ModalActionHide();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const GetCompanyUsersExcel = () => {
        setloader(true);
        dispatch(SuperAdminUserActions.GetCompanyUsersExcel(companydetail?.company_id)).then((response) => {
            setloader(false);
            if (response.success === true) {
                const link = document.createElement("a");
                link.href = response.data;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    // Invite User
    const [invited_users, setinvited_users] = useState([]);
    const [invited_userscount, setinvited_userscount] = useState(0);

    const [invited_usersfilterlimit] = useState(10);
    const [invited_usersfilterpage, setinvited_usersfilterpage] = useState(1);
    const [invited_usersfilterorder, setinvited_usersfilterorder] = useState("asc");
    const [invited_usersfiltersort, setinvited_usersfiltersort] = useState("invite_id");
    const [invited_usersfiltersearch] = useState(null);

    const GetCompanyInvitedUsers = useCallback(async () => {
        if (companydetail?.company_id) {
            await dispatch(
                SuperAdminUserActions.GetCompanyInvitedUsers({
                    company_id: companydetail.company_id,
                    search: invited_usersfiltersearch,
                    page: invited_usersfilterpage,
                    limit: invited_usersfilterlimit,
                    sort: invited_usersfiltersort,
                    order: invited_usersfilterorder,
                })
            ).then((response) => {
                if (response.success === true) {
                    setinvited_users(response.data);
                    setinvited_userscount(response.count);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    }, [dispatch, companydetail, invited_usersfiltersearch, invited_usersfilterlimit, invited_usersfilterpage, invited_usersfiltersort, invited_usersfilterorder]);

    useEffect(() => {
        GetCompanyInvitedUsers();
    }, [GetCompanyInvitedUsers]);

    const ReInviteUser = (invited_user) => {
        const form_data = {};
        form_data["site_url"] = actionType.siteurl;
        form_data["invite_id"] = invited_user.invite_id;
        setloader(true);
        dispatch(SuperAdminUserActions.ReInviteUser(form_data)).then((response) => {
            setloader(false);
            if (response.success === true) {
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    return (
        <React.Fragment>
            {loader ? <Loader /> : null}
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <LoginHeader page_title="Users" />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card mb-4 mb-xl-8 bg-transparent">
                                                <div className="card-header border-0 p-0 align-items-center">
                                                    <h3 className="card-title align-items-start flex-column m-0">
                                                        <span className="card-label fw-bolder fs-22px mb-2">{companydetail?.company_name} Users</span>
                                                    </h3>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>

                                    {userlist_type === "company_user" ? (
                                        <CompanyUsersListItem
                                            company_id={company_id}
                                            companyusers={companyusers}
                                            companyuserscount={companyuserscount}
                                            filterlimit={filterlimit}
                                            filterpage={filterpage}
                                            filtersort={filtersort}
                                            filterorder={filterorder}
                                            propsfiltersort={(field) => setfiltersort(field)}
                                            propsfilterorder={(order) => setfilterorder(order)}
                                            propsfilterpage={(event) => setfilterpage(event)}
                                            propsfiltersearch={(event) => setfiltersearch(event)}
                                            GetCompanyUsersExcel={() => GetCompanyUsersExcel()}
                                            ConfirmArchiveUser={(company_detail) => ConfirmArchiveUser(company_detail)}
                                            ConfirmChangeUserStatus={(event, user_detail, user_status) => ConfirmChangeUserStatus(event, user_detail, user_status)}
                                            ChangeUserListType={(userlist_type) => setuserlist_type(userlist_type)}
                                        />
                                    ) : (
                                        <CompanyUserInvitedItems
                                            company_id={company_id}
                                            invited_users={invited_users}
                                            invited_userscount={invited_userscount}
                                            filterlimit={invited_usersfilterlimit}
                                            filterpage={invited_usersfilterpage}
                                            propsfilterpage={(event) => setinvited_usersfilterpage(event)}
                                            propsfiltersort={(event) => setinvited_usersfiltersort(event)}
                                            propsfilterorder={(event) => setinvited_usersfilterorder(event)}
                                            ChangeUserListType={(userlist_type) => setuserlist_type(userlist_type)}
                                            ReInviteUser={(invited_user) => ReInviteUser(invited_user)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default CompanyUsersList;