import React from "react";
import { Redirect } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";

import Loader from "../../components/Utility/Loader";
import Validations from "../../components/Utility/Validations";
class Authentication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authentication_form: {
                Password: {
                    type: "password",
                    label: "Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
            },
            is_form_valid: false,
            passwordshow: false,
            redirect_url: "",
            isAuthenticated: false,
        };
    }

    inputChangeHandler(event, identifier) {
        const update_authentication_form = { ...this.state.authentication_form };
        const form_element = { ...update_authentication_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_authentication_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_authentication_form) {
            is_form_valid = update_authentication_form[identifier].valid && is_form_valid;
        }

        this.setState({
            authentication_form: update_authentication_form,
            is_form_valid: is_form_valid,
        });
    }

    loginSubmitHandler = (event) => {
        event.preventDefault();

        // Check Validation
        let is_form_valid = true;
        if (!this.state.is_form_valid) {
            const update_authentication_form = this.state.authentication_form;
            for (let key in update_authentication_form) {
                let form_element = update_authentication_form[key];
                let validate_element = Validations(form_element);

                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;

                update_authentication_form[key] = form_element;
            }
            this.setState({
                authentication_form: update_authentication_form,
                is_form_valid: is_form_valid,
            });
        }

        // Form submit handler
        if (this.state.is_form_valid) {
            const update_authentication_form = this.state.authentication_form;
            if (update_authentication_form.Password.value === "=9DvL773y_Y5Z$%X") {
                localStorage.setItem("BidHQ_http_Authenticated", "true");
                this.setState({
                    isAuthenticated: true,
                    redirect_url: "/signin",
                });
            } else {
                notify.show("Invalid password", "error");
            }
        }
    };

    render() {
        const { authentication_form } = this.state;
        return (
            <React.Fragment>
                <Notifications />
                {this.state.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                        <div className="d-flex flex-column flex-lg-row-fluid py-9">
                            <div className="d-flex flex-center flex-column-fluid">
                                <div className="w-lg-600px p-10 pb-0 pt-0 mx-auto w-100">
                                    <form className="form w-100" autoComplete="off" onSubmit={this.loginSubmitHandler}>
                                        <div className="mb-5">
                                            <h1 className="text-dark mb-2 fs-3qx">Authentication</h1>
                                        </div>
                                        <div className={"fv-row mb-5"+(!authentication_form.Password.valid && authentication_form.Password.onBlur_out ? " error-field": "")}>
                                            <label className="form-label fs-6">{authentication_form.Password.label}</label>
                                            <div className="position-relative mb-3">
                                                <input
                                                    type={this.state.passwordshow ? "text" : "Password"}
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder={authentication_form.Password.placeholder}
                                                    value={authentication_form.Password.value ? authentication_form.Password.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "Password")}
                                                    onBlur={(event) => {
                                                        authentication_form.Password.onBlur_out = true;
                                                        this.setState({
                                                            authentication_form: authentication_form,
                                                        });
                                                    }}
                                                />
                                                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                                    <i
                                                        className={this.state.passwordshow ? "bi bi-eye fs-2" : "bi bi-eye-slash fs-2"}
                                                        onClick={(event) => {
                                                            this.setState({ passwordshow: this.state.passwordshow ? false : true });
                                                        }}
                                                    ></i>
                                                </span>
                                            </div>
                                            {!authentication_form.Password.valid && authentication_form.Password.onBlur_out ? <div className="error field-error">{authentication_form.Password.error_msg}</div> : ""}
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-lg btn-primary rounded-50 w-100 mb-8">
                                                <span className="indicator-label">Log in</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Authentication;