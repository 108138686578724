import React from "react";

import moment from "moment-timezone";
import { connect } from "react-redux";

import Routers from "./Routers";
import * as userActions from "./actions/index";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            permission_checked: false,
        };
    }

    componentDidMount() {		
		
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));

        /*** Set Default Timezone Start ***/
        let default_timezone = moment.tz.guess();
        if (user_info?.user?.user_timezone && user_info?.user?.user_timezone !== true) {
            default_timezone = user_info?.user?.user_timezone;
        }
        moment.tz.setDefault(default_timezone);
        /*** Set Default Timezone End ***/

        if (user_info && user_info.user && user_info.company) {
            this.GetUserAccess(user_info.user.user_id);

            if (process.env.REACT_APP_ENV !== "developer") {
                const interval = setInterval(() => {
                    if (user_info && user_info.user && user_info.company) {
                        this.GetUserAccess(user_info.user.user_id);
                    }
                }, 60000);
                return () => clearInterval(interval);
            }
        } else {
            this.setState({ permission_checked: true });
        }
    }

    GetUserAccess = async (user_id) => {
        this.props.onGetAuthUser().then((response) => {
            if (response.success === true) {
                this.setState({ permission_checked: true });
            } else {
                this.setState({ permission_checked: true });
            }
        });
    };

    render() {
        if (this.state.permission_checked === false) {
            return null;
        }
        return <Routers />;
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onGetAuthUser: userActions.GetAuthUser,
    onGetUserDesktopNotification: userActions.GetUserDesktopNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);