import React from "react";
import { Modal } from "react-bootstrap";
class SubscriptionsEditModal extends React.Component {
    SubmitHandler = (event) => {
        event.preventDefault();
        console.log("SubmitHandler---------");
    };
    render() {
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="subscriptionsedit_modal bid-custom-modal "
                    show={this.props.subscriptionsedit_modal}
                    onHide={() => {
                        this.props.subscriptionsedit_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Pricing</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="d-flex flex-column">
                                <div className="row divide-colum">
                                    <div className="col-lg-12 right-colum pt-0">
									<div className="d-flex justify-content-end">
                                        
										<div className="nav-group pricing-block d-flex mb-4">
										<span className="btn btn-active active-light-btn-info rounded-50 fw-bold-600 me-2 py-2 px-4 active">Monthly</span>
										<span className="btn btn-active active-light-btn-info rounded-50 fw-bold-600 py-2 px-4">Annually</span></div>
										
										</div>
										
									
									<div className="row">
									<div className="col-sm-12 col-lg-12 mb-4 fv-row"><label className="form-label fs-6">Plan name</label>
									<input type="text" className="form-control form-control-lg form-control-solid" placeholder="Enter plan name" /></div>
									<div className="col-sm-12 col-lg-12 mb-4 fv-row">
									<label className="form-label fs-6">Price</label>
									<input type="text" className="form-control form-control-lg form-control-solid" placeholder="Enter price"/>
									</div>
									<div className="col-sm-12 col-lg-12 mg-bottom fv-row">
									<label className="form-label fs-6">Users</label>
									<input type="text" className="form-control form-control-lg form-control-solid" placeholder="5 users"/>
									</div>
									
									</div>



                                   									
										
										
										
										
										
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn cancel-butn rounded-50 w-150"
                                                onClick={() => {
                                                    this.props.subscriptionsedit_modal_action(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn light-btn-info btn-info rounded-50 px-10 hvr-grow">
                                                Update Plan
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default SubscriptionsEditModal;