import React from "react";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";

import Tribute from "tributejs";
import FroalaEditor from "react-froala-wysiwyg";
import Froalaeditor from "froala-editor";

const WysiwygEditorComment = (props) => {
    var tribute = new Tribute({
        selectClass: "highlight",
        values: props.tagusers,
        requireLeadingSpace: true,
        selectTemplate: function (item) {
            console.log("selectTemplate item --------------", item);
            return '<span class="fr-deletable fr-tribute editor_user_selected">@' + item.original.key + "</a></span>";
        },
    });

    return (
        <React.Fragment>
            <div className="froala_editor_section">
                <FroalaEditor
                    tag="textarea"
                    config={{
                        attribution: false,
                        toolbarSticky: true,
                        quickInsertTags: [],
                        charCounterCount: false,
                        key: "VCC6kE4C3D3C2A7B7B7cbhfkuC8eheE5kC-8pI1A1A1B6A1F6D5A1B3B3==",
                        placeholderText: props.placeholder ? props.placeholder : "Something write",

                        paragraphFormatSelection: true,
                        paragraphDefaultSelection: "Headings",
                        toolbarButtons: [
                            ["undo", "redo", "|"],
                            ["fontSize", "|"],
                            ["paragraphFormat", "|"],
                            ["bold", "italic", "underline", "|"],
                            ["alignLeft", "alignCenter", "alignRight", "alignJustify", "|"],
                            ["formatOL", "formatUL", "|"],
                            ["insertLink", "insertTable", "insertHR"],
                        ],

                        events: {
                            initialized: async function () {
                                var editor = this;
                                tribute.attach(editor.el);
                                editor.events.on(
                                    "keydown",
                                    function (event) {
                                        console.log("initialized event-----------", event);
                                        if (event.which === Froalaeditor.KEYCODE.ENTER && tribute.isActive) {
                                            return false;
                                        }
                                    },
                                    true
                                );
                            },
                            click: function (event, clickEvent) {
                                if (props.onClick !== undefined) {
                                    props.onClick(event);
                                }
                            },
                        },
                    }}
                    model={props.value}
                    onModelChange={(event) => props.onChange(event)}
                />
            </div>
        </React.Fragment>
    );
};

export default WysiwygEditorComment;