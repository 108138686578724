import React from "react";
import Notifications from "react-notify-toast";

import ReportingDashboard from "../../components/Reporting/ReportingDashboard";
import AccessDenied from "../../containers/Page/AccessDenied.js";

class Reporting extends React.Component {
    render() {
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        const renderview = () => {
            if (user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Reports Overview") {
                if (this.props.match.path === "/reporting") {
                    return <ReportingDashboard />;
                }
            } else {
                return <AccessDenied />;
            }
        };
        return (
            <React.Fragment>
                <Notifications options={{ zIndex: 99999 }} />
                {renderview()}
            </React.Fragment>
        );
    }
}
export default Reporting;