import React, { useState, useEffect, useCallback } from "react";

import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";

import * as userActions from "../../actions/index";
const PastProposal = (props) => {
    const dispatch = useDispatch();

    const [pastproposals, setpastproposals] = useState([]);
    const [pastproposal_search, setpastproposal_search] = useState(null);

    const GetLibraryPastProposals = useCallback(() => {
        dispatch(userActions.GetLibraryPastProposals(pastproposal_search)).then((response) => {
            if (response.success === true) {
                setpastproposals(response.data);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, pastproposal_search]);

    useEffect(() => {
        GetLibraryPastProposals();
    }, [GetLibraryPastProposals, pastproposal_search]);

    return (
        <React.Fragment>
            <div className="card-header border-0 pt-5 mb-5 d-block">
                <div className="row">
                    <div className="col-md-6">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bolder fs-2x color1">Past proposals</span>
                                <span className="fw-normal text-gray-new mt-1 fs-7">Your previous bids stored here to refer back to.</span>
                            </h3>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        <div className="d-flex align-items-center my-2 justify-content-end">
                            <div className="d-flex align-items-center position-relative me-4 library-search">
                                <input
                                    type="text"
                                    placeholder="Search for pastproposal..."
                                    className="form-control form-control-sm ps-7 search-expand"
                                    onChange={(event) => {
                                        setpastproposal_search(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body pt-0 proposal-outer">
                <div className="row">
                    {pastproposals.length !== 0 &&
                        pastproposals.map((pastproposal) => (
                            <Link to={"/past-proposal-list/" + pastproposal.bid_uuid} className="col-md-3 col-xl-2 fv-row mb-5" key={"pastproposals_" + pastproposal.bid_id}>
                                <div className="row main_floder_div">
                                    <div className="col-sm-3 col-lg-3 p-0">
                                        <i className="icon-info1" aria-hidden="true"></i>
                                    </div>
                                    <div className="col-sm-9 col-lg-9 p-0">
                                        <div className="folder_name">{pastproposal.bid_title}</div>
                                        <div className="folder_foles">
                                            {pastproposal?.responses?.length} {pastproposal?.responses?.length > 1 ? "sections" : "section"}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                </div>
            </div>
        </React.Fragment>
    );
};
export default PastProposal;