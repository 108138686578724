import React from "react";
import Notifications from "react-notify-toast";

import MyProfile from "../../components/SuperAdmin/Settings/MyProfile.js";
import UsersList from "../../components/SuperAdmin/Settings/Users/UsersList.js";
import Pricing from "../../components/SuperAdmin/Settings/Pricing.js";
class Settings extends React.Component {
    render() {
        const renderview = () => {
            if (this.props.match.path === "/superadmin/settings-myprofile") {
                return <MyProfile />;
            }
            if (this.props.match.path === "/superadmin/settings-users") {
                return <UsersList />;
            }
            if (this.props.match.path === "/superadmin/settings-pricing") {
                return <Pricing />;
            }
        };

        return (
            <React.Fragment>
                <Notifications options={{ zIndex: 99999 }} />
                {renderview()}
            </React.Fragment>
        );
    }
}
export default Settings;