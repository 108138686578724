import axios from "axios";
import * as actionType from "../../constants/actionTypes";
import * as SuperAdminactionTypes from "../../constants/SuperAdminactionTypes";

/*** UpdatePersonal Action ***/
export const UpdatePersonalStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.UpdatePersonal_START,
    };
};
export const UpdatePersonalSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdatePersonal_SUCCESS,
    };
};
export const UpdatePersonalFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdatePersonal_FAIL,
    };
};
export const UpdatePersonal = (form_data) => {
    return (dispatch) => {
        dispatch(UpdatePersonalStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/superadmin/UpdatePersonal`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdatePersonalSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdatePersonalFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdatePassword Action ***/
export const UpdatePasswordStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.UpdatePassword_START,
    };
};
export const UpdatePasswordSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdatePassword_SUCCESS,
    };
};
export const UpdatePasswordFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdatePassword_FAIL,
    };
};
export const UpdatePassword = (form_data) => {
    return (dispatch) => {
        dispatch(UpdatePasswordStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/api/superadmin/UpdatePassword`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdatePasswordSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdatePasswordFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetSubscriptionPackages Action ***/
export const GetSubscriptionPackagesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetSubscriptionPackages_START,
    };
};
export const GetSubscriptionPackagesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetSubscriptionPackages_SUCCESS,
    };
};
export const GetSubscriptionPackagesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetSubscriptionPackages_FAIL,
    };
};
export const GetSubscriptionPackages = () => {
    return (dispatch) => {
        dispatch(GetSubscriptionPackagesStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetSubscriptionPackages`,
        })
            .then(function (response) {
                dispatch(GetSubscriptionPackagesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetSubscriptionPackagesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateSubscriptionPackage Action ***/
export const CreateSubscriptionPackageStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.CreateSubscriptionPackage_START,
    };
};
export const CreateSubscriptionPackageSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.CreateSubscriptionPackage_SUCCESS,
    };
};
export const CreateSubscriptionPackageFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.CreateSubscriptionPackage_FAIL,
    };
};
export const CreateSubscriptionPackage = (form_data) => {
    return (dispatch) => {
        dispatch(CreateSubscriptionPackageStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/superadmin/CreateSubscriptionPackage`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CreateSubscriptionPackageSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateSubscriptionPackageFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateSubscriptionPackage Action ***/
export const UpdateSubscriptionPackageStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.UpdateSubscriptionPackage_START,
    };
};
export const UpdateSubscriptionPackageSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdateSubscriptionPackage_SUCCESS,
    };
};
export const UpdateSubscriptionPackageFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.UpdateSubscriptionPackage_FAIL,
    };
};
export const UpdateSubscriptionPackage = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateSubscriptionPackageStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/superadmin/UpdateSubscriptionPackage`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateSubscriptionPackageSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateSubscriptionPackageFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetDiscounts Action ***/
export const GetDiscountsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: SuperAdminactionTypes.GetDiscounts_START,
    };
};
export const GetDiscountsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetDiscounts_SUCCESS,
    };
};
export const GetDiscountsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: SuperAdminactionTypes.GetDiscounts_FAIL,
    };
};
export const GetDiscounts = () => {
    return (dispatch) => {
        dispatch(GetDiscountsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetDiscounts`,
        })
            .then(function (response) {
                dispatch(GetDiscountsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetDiscountsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};