import React, { useState } from "react";

import moment from "moment";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";

const InvitedUsersItems = (props) => {
    const [columns] = useState([
        {
            hidden: true,
            text: "Invite Id",
            dataField: "invite_id",
            formatter: (cellContent, row, index, extraData) => {
                return row?.invite_id;
            },
        },
        {
            sort: false,
            text: "Email",
            dataField: "invite_email",
        },
        {
            sort: false,
            text: "Role",
            dataField: "user_role",
            formatter: (cellContent, row, index, extraData) => {
                return row?.role?.user_role;
            },
        },
        {
            sort: false,
            text: "Last active",
            dataField: "created_date",
            formatter: (cellContent, row, index, extraData) => {
                return moment(row?.created_date).fromNow();
            },
        },
        {
            sort: false,
            text: "",
            dataField: "invited_user_action",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <div className="cursor-pointer" key={"invited_user_action_" + row.invite_id}>
                        <span className="btn" onClick={(event) => props.EditInviteUser(event, row)}>
                            <span className="svg-icon svg-icon-3">
                                <img src="assets/images/icons/edit.svg" title="edit" alt="edit" />
                            </span>
                        </span>
                        <span className="btn" onClick={(event) => props.RemoveInviteUser(event, row)}>
                            <span className="svg-icon svg-icon-3">
                                <img src="assets/images/icons/delete.svg" title="delete" alt="delete" />
                            </span>
                        </span>
                    </div>
                );
            },
        },
    ]);

    const handleTableChange = (type, { page, sizePerPage }) => {
        props.propsfilterpage(page);
    };

    const RemotePagination = ({ columns, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                page,
                totalSize,
                sizePerPage,
                custom: true,
                hidePageListOnlyOnePage: true,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive user-list-tables">
                        <BootstrapTable
                            remote
                            bootstrap4
                            striped
                            data={data}
                            keyField="invite_id"
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            headerClasses="fw-bolder  border-bottom-1"
                            classes="table align-middle custom-table-style"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-end flex-wrap pt-3 pb-7 pagination-main">
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </>
            )}
        </PaginationProvider>
    );
    return (
        <React.Fragment>
            <div className="card-body p-0 bg-white radious-style radious-users client-items-list mt-5">
                <RemotePagination data={props.invited_users} columns={columns} page={props.filterpage} totalSize={props.invited_userscount} sizePerPage={props.filterlimit} onTableChange={handleTableChange} />
            </div>
        </React.Fragment>
    );
};
export default InvitedUsersItems;