import React from "react";
import { Link } from "react-router-dom";

class DashboardWelcomeBidHQ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboard_welcomebidhq_show: true,
        };
    }

    render() {
        const { dashboard_welcomebidhq_show } = this.state;
        return (
            <React.Fragment>
                <div className="col-xl-4 mob-none notify-activity dashboard_welcomebidhq">
                    <div className={"card card-bg-new  border-0 mb-lg-8" + (dashboard_welcomebidhq_show ? " card-xl-stretch jobs-main-dash " : "")}>
                        <div className="card-header border-0 py-5 justify-content-end job-dropdown">
                            <div className="card-toolbar m-0">
                                <div className="tab-none z-index-9">
                                    <button type="button" className="dot-butns btn btn-sm border-0 me-n3" id="dashboard_welcome" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="icon-dots text-grey-dark"></i>
                                    </button>
                                    <div className="dropdown-menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_welcome">
                                        <div className="py-4 px-2 cursor-pointer link-hover">
                                            <div className="text-black" onClick={(event) => this.props.dashboard_welcomebidhq_show_action(dashboard_welcomebidhq_show ? false : true)}>
                                                <i className={"me-2 text-black" + (dashboard_welcomebidhq_show ? " icon-eye font-size-8" : " bi bi-eye")}></i>
                                                <span>{dashboard_welcomebidhq_show ? "Hide chart" : "Show chart"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-show z-index-9">
                                    <div className="px-2 cursor-pointer link-hover">
                                        <div className="text-gray z-index-3" onClick={(event) => this.props.dashboard_welcomebidhq_show_action(dashboard_welcomebidhq_show ? false : true)}>
                                            <i className={"text-gray" + (dashboard_welcomebidhq_show ? " icon-eye font-size-8" : " bi bi-eye fs-4")}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"d-flex card-body  p-0 card-bg bgi-position-y-top flex-column " + (dashboard_welcomebidhq_show ? "" : " d-none")} ref={(node) => (this.dashboard_welcome_ref = node)}>
                            <div className="flex-center z-index-1 job-left text-center">
                                <img className="img-fluid d-xl-inline-block d-md-none" src="assets/images/welcome-new.svg" alt="main-dash" />
                                <img className="image-bg-main img-fluid d-sm-none d-md-block d-xl-none" src="assets/images/dash-md.svg" alt="dash-md" />
                            </div>
                            <div className="card-p mt-new position-relative job-info job-info1-new">
                                <div className="g-0 welcome-new-info">
                                    <div className="z-index-1 box-one-title">
                                        <h3 className="text-center job-title mb-0 fw-bolder">Welcome to BidHQ!</h3>
                                        <p className="text-center job-title mb-2 fw-bold-600">
                                            Before you start creating better bids, fill out your profile to collaborate better with your team mates and give you the best possible experience.
                                        </p>
                                    </div>
                                    <Link to="/settings-myprofile" className="save-button1 btn rounded-50 btn-lg btn-primary">
                                        Complete profile
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default DashboardWelcomeBidHQ;