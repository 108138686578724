import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import SubscriptionExpiredModal from "./Modal/SubscriptionExpiredModal";
import FreeTrailSubscriptionExpiredModal from "./Modal/FreeTrailSubscriptionExpiredModal";
class CheckSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptiontrail_days: 0,
            subscitptionexpired_notify: true,
            subscitptionexpired_footerbar: false,

            subscriptionsxpired_modal: false,
            freetrailsubscriptionsxpired_modal: false,
        };
    }

    async componentDidMount() {
        if (window.location.pathname === "/settings-subscription" || window.location.pathname === "/settings-account") {
            return false;
        }

        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));

        let subscription_details = user_info?.subscription_details;
        if (subscription_details) {
            let subscription_active = [];
            let subscription_inactive = [];
            for (let key in subscription_details) {
                let subscription_detail = subscription_details[key];
                if (subscription_detail.is_active === true) {
                    subscription_active.push(subscription_detail);
                } else {
                    subscription_inactive.push(subscription_detail);
                }
            }
            this.setState({
                subscitptionexpired_notify: subscription_active.length > 1 ? false : true,
            });
        }

        let current_date = new Date();

        if (user_info.subscription) {
            let subscription_enddate = new Date(user_info.subscription?.subscription_billing?.end_date);
            if (current_date.getTime() >= subscription_enddate.getTime()) {
                this.setState({ subscriptionsxpired_modal: true });
            } else {
                let subscriptiontrail_days = Math.ceil((subscription_enddate - current_date) / 8.64e7);
                this.setState({ subscriptiontrail_days: subscriptiontrail_days });

                let subscitptionexpired_footerbar = localStorage.getItem("subscitptionexpired_footerbar");
                if (subscriptiontrail_days > 3) {
                    if (subscitptionexpired_footerbar) {
                        subscitptionexpired_footerbar = new Date(subscitptionexpired_footerbar);
                        subscitptionexpired_footerbar.setTime(subscitptionexpired_footerbar.getTime() + 24 * 60 * 60 * 1000);
                        if (current_date.getTime() > subscitptionexpired_footerbar.getTime()) {
                            this.setState({
                                subscitptionexpired_footerbar: true,
                            });
                        }
                    } else {
                        this.setState({
                            subscitptionexpired_footerbar: true,
                        });
                    }
                } else {
                    if (subscitptionexpired_footerbar) {
                        subscitptionexpired_footerbar = new Date(subscitptionexpired_footerbar);
                        subscitptionexpired_footerbar.setTime(subscitptionexpired_footerbar.getTime() + 24 * 60 * 60 * 1000);
                        if (current_date.getTime() > subscitptionexpired_footerbar.getTime()) {
                            this.setState({
                                freetrailsubscriptionsxpired_modal: true,
                            });
                        }
                    } else {
                        this.setState({
                            freetrailsubscriptionsxpired_modal: true,
                        });
                    }
                }
            }
        } else {
            this.setState({
                subscriptionsxpired_modal: true,
            });
        }
    }

    render() {
        const { subscitptionexpired_notify, subscitptionexpired_footerbar, subscriptiontrail_days } = this.state;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                {subscitptionexpired_notify && this.state.subscriptionsxpired_modal && (
                    <SubscriptionExpiredModal
                        subscriptionsxpired_modal={this.state.subscriptionsxpired_modal}
                        subscriptionsxpired_modal_action={(modal_action) => {
                            this.setState({
                                subscriptionsxpired_modal: modal_action,
                            });
                        }}
                    />
                )}

                {subscitptionexpired_notify && this.state.freetrailsubscriptionsxpired_modal && (
                    <FreeTrailSubscriptionExpiredModal
                        subscriptiontrail_days={this.state.subscriptiontrail_days}
                        freetrailsubscriptionsxpired_modal={this.state.freetrailsubscriptionsxpired_modal}
                        freetrailsubscriptionsxpired_modal_action={(modal_action) => {
                            this.setState(
                                {
                                    freetrailsubscriptionsxpired_modal: modal_action,
                                },
                                function () {
                                    localStorage.setItem("subscitptionexpired_footerbar", new Date());
                                }
                            );
                        }}
                    />
                )}

                {subscitptionexpired_notify && subscitptionexpired_footerbar && user_info && user_info.subscription && user_info.subscription.is_freetrail === true && (
                    <div className="check-subscitption-footerbar z-index-1 d-flex justify-content-center">
                        <p>
                            <strong>BidHQ Trial: {subscriptiontrail_days} days left.</strong> Glad to have you here! Your free trial ends on {moment(user_info.subscription.subscription_billing.end_date).format("DD MMMM YYYY")}.
                        </p>
                        <Link to="/settings-subscription"> View subcriptions </Link>
                        <span
                            className="check_subscitption_dismiss cursor-pointer"
                            onClick={(event) => {
                                this.setState({ subscitptionexpired_footerbar: false }, function () {
                                    localStorage.setItem("subscitptionexpired_footerbar", new Date());
                                });
                            }}
                        >
                            Dismiss
                        </span>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
export default CheckSubscription;