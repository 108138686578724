import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";
import moment from "moment";
import * as userActions from "../../actions/index";
import LoaderPayment from "../../components/Utility/Loader-Payment";
import NumberFormat from "react-number-format";
import Validations from "../../components/Utility/Validations";
import BillingAddAdditionalUsersModal from "../../components/Settings/Modal/BillingAddAdditionalUsersModal";

class payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            billingoverview_form: {
                company_name: {
                    type: "text",
                    label: "Company name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                abn: {
                    type: "text",
                    label: "ABN",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: {
                        required: true,
                        min_length: 11,
                        ValidateABN: true,
                        NumberAndSpace: true,
                    },
                },
                country: {
                    label: "Country",
                    type: "select",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                    options: [{ opk: "country_opk0", name: "Select Country", value: "" }],
                },
                subscription_package_id: {
                    label: "subscription_package_id",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                user_limits: {
                    value: 0,
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "Users",
                    placeholder: "",
                    validations: { required: false },
                },
                cardnumber: {
                    value: "",
                    valid: true,
                    type: "numberformat",
                    error_msg: "",
                    label: "Credit Card Number",
                    placeholder: "0000 0000 0000 0000",
                    validations: { required: true, CreditCardNumber: true },
                },
                expiry_date: {
                    value: "",
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "Expiry Month",
                    placeholder: "MM/YY",
                    validations: { required: true, CreditCardExpiry: true, min_length: 5 },
                },
                cvc: {
                    value: "",
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "CVV",
                    placeholder: "•••",
                    validations: { required: true, CreditCardCVV: true },
                },
                card_holdername: {
                    value: "",
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "Cardholder name",
                    placeholder: "Enter cardholder’s full name as appear on card",
                    validations: { required: true },
                },
                subscription_price: {
                    value: 0,
                    valid: true,
                    type: "text",
                    error_msg: "",
                    label: "",
                    placeholder: "",
                    validations: { required: true },
                },
                package_feature_id: {
                    label: "package_feature_id",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                extrasubsciption_users: {
                    label: "extrasubsciption_users",
                    type: "hidden",
                    value: 0,
                    valid: true,
                    error_msg: "",
                    validations: {
                        required: false,
                    },
                },
                subscription_discount_id: {
                    label: "subscription_discount_id",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: {
                        required: false,
                    },
                },
            },
            payment_bycard: true,
            is_monthly: false,
            next_billing_date: "",
            payment_information: [],
            subscription_packages: [],
            subscription_package_selected: "",

            extrasubsciption_users: 0,
            subscription_package_plans: "",

            discount_code: false,
            subscription_discount: [],
            firstSetup: false,
            billingaddadditionalusers_modal: false,
        };
    }

    async componentDidMount() {
        if (!localStorage.getItem("signUpForm")) {
            return this.props.history.push("/signup");
        }
        if (!localStorage.getItem("subscriptionOpted")) {
            return this.props.history.push("/subscription");
        }
        await this.GetCountriesLists();
        let subscription_package_selected
        if (localStorage.getItem("subscriptionOpted") && JSON.parse(localStorage.getItem("subscriptionOpted"))) {
            this.setState({
                is_monthly: JSON.parse(localStorage.getItem("subscriptionOpted")).is_monthly,
                subscription_package_selected: JSON.parse(localStorage.getItem("subscriptionOpted")).selected_package
            })
            subscription_package_selected = JSON.parse(localStorage.getItem("subscriptionOpted")).selected_package
        }

        const update_billingoverview_form = { ...this.state.billingoverview_form };
        update_billingoverview_form.subscription_package_id.value = subscription_package_selected.subscription_package_id;
        update_billingoverview_form.user_limits.value = subscription_package_selected.user_limits;
        update_billingoverview_form.subscription_price.value = subscription_package_selected.price;

        let next_billing_date = "";
        if (subscription_package_selected.billing_cycle === "month") {
            next_billing_date = moment().add(1, "months");
        } else {
            next_billing_date = moment().add(1, "years");
        }

        this.setState({
            next_billing_date: next_billing_date,
            billingoverview_form: update_billingoverview_form,
            payment_bycard: true
        });
        await this.BetaDiscountCouponVerify();
        await this.CalculateSubscriptionBilling();
    }

    BetaDiscountCouponVerify = async () => {
        await this.props.onVerifyDiscount("BETA50").then((response) => {
            if (response.success === true) {
                let subscription_discount = response.data;
                const update_billingoverview_form = { ...this.state.billingoverview_form };
                update_billingoverview_form.subscription_discount_id.value = subscription_discount.subscription_discount_id;
                this.setState({
                    discount_code: true,
                    subscription_discount: response.data,
                    billingoverview_form: update_billingoverview_form,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    BillingOverviewReload() {
        let subscription_package_selected = this.state.subscription_package_selected;

        const update_billingoverview_form = { ...this.state.billingoverview_form };
        update_billingoverview_form.subscription_package_id.value = subscription_package_selected.subscription_package_id;
        update_billingoverview_form.user_limits.value = subscription_package_selected.user_limits;
        update_billingoverview_form.subscription_price.value = subscription_package_selected.price;
        update_billingoverview_form.package_feature_id.value = "";
        update_billingoverview_form.extrasubsciption_users.value = 0;

        let extrasubsciption_users = this.state.extrasubsciption_users;
        let subscription_package_feature = subscription_package_selected.subscription_package_feature;
        if (subscription_package_feature && subscription_package_feature.title === "additional_users" && extrasubsciption_users !== 0) {
            update_billingoverview_form.package_feature_id.value = subscription_package_feature.package_feat;
            update_billingoverview_form.extrasubsciption_users.value = extrasubsciption_users;
            update_billingoverview_form.subscription_price.value = parseInt(subscription_package_selected.price) + parseInt(subscription_package_feature.unit_amount * extrasubsciption_users);
        }

        this.setState(
            {
                extrasubsciption_users: extrasubsciption_users,
                billingoverview_form: update_billingoverview_form,
                subscription_package_selected: subscription_package_selected,
            },
            async () => {
                await this.CalculateSubscriptionBilling();
            }
        );
    }

    CalculateSubscriptionBilling = () => {
        let subscription_discount = this.state.subscription_discount;
        let extrasubsciption_users = this.state.extrasubsciption_users;
        let subscription_package_selected = this.state.subscription_package_selected;
        let subscription_package_feature = subscription_package_selected.subscription_package_feature;

        const update_billingoverview_form = { ...this.state.billingoverview_form };
        let subscription_price = parseInt(subscription_package_selected.price) + parseInt(subscription_package_feature.unit_amount * extrasubsciption_users);

        if (subscription_discount?.percent) {
            subscription_price = (parseInt(subscription_price) / 100) * parseInt(subscription_discount.percent);
        }

        let next_billing_date = "";
        if (subscription_package_selected.billing_cycle === "month") {
            next_billing_date = moment().add(1, "months");
        } else {
            next_billing_date = moment().add(1, "years");
        }

        update_billingoverview_form.subscription_price.value = (Math.round(subscription_price * 10) / 10);
        this.setState({
            next_billing_date: next_billing_date,
            billingoverview_form: update_billingoverview_form,
        });
    };

    GetCountriesLists = async () => {
        await userActions.GetCountriesLists().then((response) => {
            if (response.success === true) {
                let country_details = response.data;
                const update_billingoverview_form = { ...this.state.billingoverview_form };

                let country_option = [{ opk: "country_opk0", name: "Select Country", value: "" }];
                for (let country_detail of country_details) {
                    country_option.push({
                        opk: country_detail.country_code,
                        name: country_detail.country_name,
                        value: country_detail.country_code,
                    });
                }

                update_billingoverview_form.country.options = country_option;
                this.setState({ billingoverview_form: update_billingoverview_form });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    inputChangeHandler(event, identifier) {
        const update_billingoverview_form = { ...this.state.billingoverview_form };
        const form_element = { ...update_billingoverview_form[identifier] };

        if (form_element.type === "numberformat") {
            form_element.value = event;
        } else if (form_element.validations.CreditCardExpiry) {
            let prevValue = form_element.value;
            let currentValue = event.target.value;
            let textTemp = currentValue;
            let current_year = moment().format("YY");

            if (textTemp[0] !== "0" && textTemp[0] !== "1" && textTemp[0] !== "2") {
                textTemp = "";
            } else if (prevValue?.length === 3 && currentValue.length === prevValue.length - 1) {
                textTemp = textTemp?.slice(0, -1);
            } else if (textTemp.length === 4 && textTemp[3] < current_year[0]) {
                textTemp = textTemp?.slice(0, -1);
            } else if (textTemp.length === 5 && textTemp[4] < current_year[1]) {
                textTemp = textTemp?.slice(0, -1);
            } else if (textTemp.length === 6) {
                textTemp = textTemp?.slice(0, -1);
            } else if (textTemp.length === 2) {
                if (parseInt(textTemp?.substring(0, 2)) > 12 || parseInt(textTemp?.substring(0, 2)) === 0) {
                    textTemp = textTemp?.slice(0, -1);
                } else if (textTemp?.length === 2) {
                    textTemp += "/";
                } else {
                    textTemp = textTemp[0];
                }
            }
            form_element.value = textTemp;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_billingoverview_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_billingoverview_form) {
            is_form_valid = update_billingoverview_form[identifier].valid && is_form_valid;
        }

        if (identifier === "country") {
            update_billingoverview_form.abn.value = "";
            update_billingoverview_form.abn.label = event.target.value === "NZ" ? "NZ GST*" : "ABN*";
            update_billingoverview_form.abn.placeholder = event.target.value === "NZ" ? "Enter NZ GST" : "Enter ABN";
        }

        this.setState({
            billingoverview_form: update_billingoverview_form,
            is_form_valid: is_form_valid,
        });
    }

    submitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_billingoverview_form = this.state.billingoverview_form;
        for (let key in update_billingoverview_form) {
            let form_element = update_billingoverview_form[key];

            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_billingoverview_form[key] = form_element;
        }
        this.setState({
            billingoverview_form: update_billingoverview_form,
        });

        if (is_form_valid) {
            let form_data = {};
            for (let key in this.state.billingoverview_form) {
                form_data[key] = this.state.billingoverview_form[key].value;
            }

            let expiry_date = form_data.expiry_date.split("/");

            form_data["exp_year"] = expiry_date[1];
            form_data["exp_month"] = expiry_date[0];

            form_data = { ...JSON.parse(localStorage.getItem("signUpForm")), ...form_data }

            this.props.onSignUpWithPayment(form_data).then((response) => {
                if (response.success === true) {
                    this.props.onGetAuthUser().then((response) => {
                        if (response.success === true) {
                            localStorage.setItem("bidhq_tutorial_token", true);
                            localStorage.removeItem("signUpForm");
                            localStorage.removeItem("subscriptionOpted");
                            notify.show("Logged in successfully", "success");
                            setTimeout(() => {
                                this.setState({
                                    isAuthenticated: true,
                                    redirect_url: "/dashboard",
                                });
                            }, 500);
                        } else {
                            notify.show(response.message, "error");
                        }
                    });
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    render() {
        const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        const { billingoverview_form, is_monthly, subscription_package_selected } = this.state;
        return (
            <React.Fragment>
                <Notifications />
                {this.state.loader ? <LoaderPayment /> : null}
                {this.props.loader ? <LoaderPayment /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                {this.state.billingaddadditionalusers_modal && (
                    <BillingAddAdditionalUsersModal
                        subscription_package_selected={subscription_package_selected}
                        billingaddadditionalusers_modal={this.state.billingaddadditionalusers_modal}
                        extrasubsciption_users={this.state.extrasubsciption_users}
                        BillingAddAdditionalUsersSubmit={(extrasubsciption_users) => {
                            this.setState(
                                {
                                    billingaddadditionalusers_modal: false,
                                    extrasubsciption_users: extrasubsciption_users,
                                },
                                function () {
                                    this.BillingOverviewReload();
                                }
                            );
                        }}
                        billingaddadditionalusers_modal_action={(modal_action) => {
                            this.setState({
                                billingaddadditionalusers_modal: modal_action,
                            });
                        }}
                    />
                )}

                {!this.state.loader && !this.props.loader && <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                        <div class="d-flex flex-column  flex-lg-row-auto w-xl-500px positon-xl-relative bg-dark-blue-color">
                            <div class="d-flex flex-column text-center-md position-xl-fixed top-0 bottom-0 w-xl-500px scroll-y">
                                <div class="d-flex flex-row-fluid flex-column pt-lg-10">
                                    <a href="#" class="mb-10 ps-lg-10">
                                        <img alt="Logo" src="assets/images/logo.svg" class="h-60px" />
                                    </a>
                                    <h1 class="fw-bold fs-3x pb-md-5 ps-md-10 mb-0 orange-text">Your selected plan</h1>
                                    <div className="ps-md-10 pb-md-10">
                                        {subscription_package_selected && <div class="ps-md-10 card rounded border-danger border border-dashed p-6 bg-transparent width-80-p">
                                            <div class="d-flex align-items-center">
                                                <div class="symbol-100px symbol-fixed position-relative me-3">
                                                    {(subscription_package_selected.package_name.toLowerCase() == 'basic' || subscription_package_selected.package_name.toLowerCase() == 'individual') ?
                                                        (<i className="text-dark-blue icon-subscription fs-3"></i>) :
                                                        (<i className="text-orange-color icon-subscription fs-3"></i>)
                                                    }
                                                </div>
                                                <h4 class="text-white me-3 rounded-50"> {subscription_package_selected.package_name}</h4>
                                                <div class="badge-bill">{is_monthly ? 'Billed Monthly' : 'Billed Anually'}</div>
                                            </div>
                                            <p class="text-white mt-2 fw-normal fs-7">{renderHTML(subscription_package_selected.package_description)}</p>
                                            <div class="price-block text-white py-6 mb-2">
                                                <div class="d-flex gap-1"><small class="fs-3x fw-bolder">$</small>
                                                    <h2 class="text-white fs-5x">{(parseInt(subscription_package_selected.price) / 100) * parseInt(50)}</h2>
                                                </div>

                                                <span class="fw-bolder">{is_monthly ? 'AUD per month' : 'AUD per year'}</span>
                                            </div>
                                            <hr class="m-0 border-t-2" />
                                            <div className="text-white mt-7 mb-0">
                                                {renderHTML(subscription_package_selected.package_features)}
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-lg-row-fluid py-9">
                            <div className="ps-md-10 pt-md-10 pb-md-10">
                                <div className="border-0 p-0 mb-5 align-items-center pricing-plans">
                                    <h3 className="card-title align-items-start flex-column mb-4 subscription-sub-heading">
                                        <span className="card-label text-dark fw-bolder subscription-heading mb-3">Payment details</span>
                                        <br></br>
                                        <span className="text-dark fs-6 fw-bold ">30 days free. Cancel, change plans or add more users anytime.</span>
                                    </h3>
                                </div>
                                <div className="payment-card">
                                    <div className={"col-sm-12 col-lg-12 mb-2 fv-row" + (!billingoverview_form.company_name.valid && billingoverview_form.company_name.onBlur_out ? " error-field" : "")}>
                                        <label className="form-label fs-7">{billingoverview_form.company_name.label}</label>
                                        <input
                                            autoComplete="new_company_name"
                                            type={billingoverview_form.company_name.type}
                                            className="form-control form-control-lg form-control-solid color1-imp"
                                            placeholder={billingoverview_form.company_name.placeholder}
                                            value={billingoverview_form.company_name.value ? billingoverview_form.company_name.value : ""}
                                            onChange={(event) => this.inputChangeHandler(event, "company_name")}
                                            onBlur={(event) => {
                                                billingoverview_form.company_name.onBlur_out = true;
                                                this.setState({
                                                    billingoverview_form: billingoverview_form,
                                                });
                                            }}
                                        />
                                        {!billingoverview_form.company_name.valid && billingoverview_form.company_name.onBlur_out && (
                                            <div className="error field-error">{billingoverview_form.company_name.error_msg}</div>
                                        )}
                                    </div>
                                    <div className="row mb-4">
                                        <div className={"col-lg-6" + (!billingoverview_form.country.valid && billingoverview_form.country.onBlur_out ? " error-field" : "")}>
                                            <label className="form-label fs-7">{billingoverview_form.country.label}</label>
                                            <select className="color1-imp form-control form-control-lg form-control-solid form-select form-select-solid" value={billingoverview_form.country.value} onChange={(event) => this.inputChangeHandler(event, "country")}>
                                                {billingoverview_form.country.options.map((option) => (
                                                    <option value={option.value} key={option.opk}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {!billingoverview_form.country.valid && billingoverview_form.country.onBlur_out && <div className="error field-error">{billingoverview_form.country.error_msg}</div>}
                                        </div>
                                        <div className={"col-lg-6" + (!billingoverview_form.abn.valid && billingoverview_form.abn.onBlur_out ? " error-field" : "")}>
                                            <label className="form-label fs-7">{billingoverview_form.abn.label}</label>
                                            <input
                                                autoComplete="new_abn"
                                                type={billingoverview_form.abn.type}
                                                className="form-control form-control-lg form-control-solid color1-imp"
                                                placeholder={billingoverview_form.abn.placeholder}
                                                value={billingoverview_form.abn.value ? billingoverview_form.abn.value : ""}
                                                onChange={(event) => this.inputChangeHandler(event, "abn")}
                                                onBlur={(event) => {
                                                    billingoverview_form.abn.onBlur_out = true;
                                                    this.setState({
                                                        billingoverview_form: billingoverview_form,
                                                    });
                                                }}
                                            />
                                            {!billingoverview_form.abn.valid && billingoverview_form.abn.onBlur_out && <div className="error field-error">{billingoverview_form.abn.error_msg}</div>}
                                        </div>
                                    </div>

                                    <h6 className="fw-bold ">Users</h6>
                                    <div className=" border rounded d-flex flex-stack text-start p-3 mb-2 h-45px">
                                        <div className="d-flex align-items-center me-2">
                                            <div className="flex-grow-1">
                                                <div className="fw-bold">
                                                    {billingoverview_form.user_limits.value} {billingoverview_form.user_limits.value > 1 ? " Users" : " User"}{" "}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ms-5">
                                            <span className="mb-2 fs-7 fw-bold text-black">Included</span>
                                        </div>
                                    </div>
                                    {billingoverview_form.extrasubsciption_users.value !== 0 && (
                                        <div className=" border rounded d-flex flex-stack text-start p-3 mb-2 h-45px">
                                            <div className="d-flex align-items-center me-2">
                                                <div className="flex-grow-1">
                                                    <div className="fw-bold">
                                                        {billingoverview_form.extrasubsciption_users.value} additional {billingoverview_form.extrasubsciption_users.value > 1 ? "users" : "user"}{" "}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ms-5">
                                                <span className="mb-2 fs-7 fw-bold-500">
                                                    AU${subscription_package_selected.subscription_package_feature ? subscription_package_selected.subscription_package_feature.unit_amount : 0} /user
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {subscription_package_selected && subscription_package_selected.subscription_package_feature && subscription_package_selected.subscription_package_feature.title === "additional_users" && (
                                        <button
                                            type="button"
                                            className="btn btn-white fw-normal p-0 fs-6 text-gray-new"
                                            onClick={(event) => {
                                                this.setState({
                                                    billingaddadditionalusers_modal: true,
                                                });
                                            }}
                                        >
                                            <i className="fas fa-plus fs-8"></i> Add users
                                        </button>
                                    )}

                                    <div className={"d-flex flex-column mt-7 mb-7 fv-row" + (!billingoverview_form.cvc.valid && billingoverview_form.cvc.onBlur_out ? " error-field" : "")}>
                                        <label className="d-flex align-items-center fs-7 fw-bold-500 form-label mb-2">{billingoverview_form.card_holdername.label}</label>
                                        <div className="position-relative">
                                            <input
                                                type={billingoverview_form.card_holdername.type}
                                                className="form-control form-control-lg form-control-solid"
                                                placeholder={billingoverview_form.card_holdername.placeholder}
                                                value={billingoverview_form.card_holdername.value ? billingoverview_form.card_holdername.value : ""}
                                                onChange={(event) => this.inputChangeHandler(event, "card_holdername")}
                                                onBlur={(event) => {
                                                    billingoverview_form.card_holdername.onBlur_out = true;
                                                    this.setState({
                                                        billingoverview_form: billingoverview_form,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className={"d-flex flex-column mb-7 fv-row" + (!billingoverview_form.cardnumber.valid && billingoverview_form.cardnumber.onBlur_out ? " error-field" : "")}>
                                        <label className="d-flex align-items-center fs-7 fw-bold-500 form-label mb-2">{billingoverview_form.cardnumber.label}</label>
                                        <div className="position-relative">
                                            <NumberFormat
                                                inputMode="numeric"
                                                format="#### #### #### ####"
                                                placeholder={billingoverview_form.cardnumber.placeholder}
                                                className="form-control form-control-lg form-control-solid"
                                                value={billingoverview_form.cardnumber.value ? billingoverview_form.cardnumber.value : ""}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    this.inputChangeHandler(value, "cardnumber");
                                                }}
                                                onBlur={(event) => {
                                                    billingoverview_form.cardnumber.onBlur_out = true;
                                                    this.setState({
                                                        billingoverview_form: billingoverview_form,
                                                    });
                                                }}
                                            />
                                            <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                                                <img src="assets/images/icons/card.svg" alt="" />
                                            </div>
                                        </div>
                                        {!billingoverview_form.cardnumber.valid && billingoverview_form.cardnumber.onBlur_out ? <div className="error field-error">{billingoverview_form.cardnumber.error_msg}</div> : ""}
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className={"d-flex flex-column mb-7 fv-row" + (!billingoverview_form.expiry_date.valid && billingoverview_form.expiry_date.onBlur_out ? " error-field" : "")}>
                                                <label className="d-flex align-items-center fs-7 fw-bold-500 form-label mb-2">{billingoverview_form.expiry_date.label}</label>
                                                <div className="position-relative">
                                                    <input
                                                        type={billingoverview_form.expiry_date.type}
                                                        className="form-control form-control-lg form-control-solid"
                                                        placeholder={billingoverview_form.expiry_date.placeholder}
                                                        value={billingoverview_form.expiry_date.value ? billingoverview_form.expiry_date.value : ""}
                                                        onChange={(event) => this.inputChangeHandler(event, "expiry_date")}
                                                        onBlur={(event) => {
                                                            billingoverview_form.expiry_date.onBlur_out = true;
                                                            this.setState({
                                                                billingoverview_form: billingoverview_form,
                                                            });
                                                        }}
                                                    />
                                                    <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                                                        <img src="assets/images/icons/card-date.svg" alt="" />
                                                    </div>
                                                </div>
                                                {!billingoverview_form.expiry_date.valid && billingoverview_form.expiry_date.onBlur_out ? (
                                                    <div className="error field-error">{billingoverview_form.expiry_date.error_msg}</div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className={"d-flex flex-column mb-7 fv-row" + (!billingoverview_form.cvc.valid && billingoverview_form.cvc.onBlur_out ? " error-field" : "")}>
                                                <label className="d-flex align-items-center fs-7 fw-bold-500 form-label mb-2">{billingoverview_form.cvc.label}</label>
                                                <div className="position-relative">
                                                    <input
                                                        type={billingoverview_form.cvc.type}
                                                        className="form-control form-control-lg form-control-solid"
                                                        placeholder={billingoverview_form.cvc.placeholder}
                                                        value={billingoverview_form.cvc.value ? billingoverview_form.cvc.value : ""}
                                                        onChange={(event) => this.inputChangeHandler(event, "cvc")}
                                                        onBlur={(event) => {
                                                            billingoverview_form.cvc.onBlur_out = true;
                                                            this.setState({
                                                                billingoverview_form: billingoverview_form,
                                                            });
                                                        }}
                                                    />
                                                    <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                                                        <img src="assets/images/icons/cvc.svg" alt="" />
                                                    </div>
                                                </div>
                                                {!billingoverview_form.cvc.valid && billingoverview_form.cvc.onBlur_out ? <div className="error field-error">{billingoverview_form.cvc.error_msg}</div> : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" border-top d-flex flex-stack text-start py-4 mt-3">
                                        <div className="d-flex align-items-center me-2">
                                            <div className="flex-grow-1">
                                                <div className="fw-bolder color1 d-block ">Total </div>
                                            </div>
                                        </div>
                                        <div className="ms-5 text-right">
                                            <span className="fw-bolder color1 d-block ">AU$ {billingoverview_form.subscription_price.value}</span>
                                            <span className="text-gray-new fw-normal">
                                                Billed {subscription_package_selected.billing_cycle === "month" ? "monthly" : "yearly"} on the {moment(this.state.next_billing_date).format("Do")} until cancellation
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row-fluid justify-content-end mt-10">
                                        <button className="btn btn-red rounded-50 px-10 hvr-grow"
                                            onClick={(event) => {
                                                this.submitHandler(event)
                                            }}>
                                            Confirm payment details and try BidHQ
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onSignUpWithPayment: userActions.SignUpWithPayment,
    onGetProducts: userActions.GetProducts,
    onGetAuthUser: userActions.GetAuthUser,
    onVerifyDiscount: userActions.VerifyDiscount,
};
export default connect(mapStateToProps, mapDispatchToProps)(payment);