import React from "react";
import Notifications from "react-notify-toast";

import LibraryList from "../../components/Library/LibraryList";
import LibraryCaseStudy from "../../components/Library/LibraryCaseStudy";
import LibraryContentUpdate from "../../components/Library/LibraryContentUpdate";
import LibraryReturnableDocument from "../../components/Library/LibraryReturnableDocument";

import PastProposalList from "../../components/Library/PastProposalList";

import AccessDenied from "../../containers/Page/AccessDenied.js";

class Client extends React.Component {
    render() {
        const renderview = () => {
            const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
            if ((user_info.user.role.user_role === "Account owner") | (user_info.user.role.user_role === "Bid Manager" || user_info.user.role.user_role === "Contributor")) {
                if (this.props.match.path === "/library") {
                    return <LibraryList />;
                }
                if (this.props.match.path === "/case-study/:id") {
                    return <LibraryCaseStudy library_id={this.props.match.params.id} />;
                }
                if (this.props.match.path === "/librarycontent-update/:id") {
                    return <LibraryContentUpdate content_id={this.props.match.params.id} />;
                }
                if (this.props.match.path === "/returnable-documents") {
                    return <LibraryReturnableDocument />;
                }
                if (this.props.match.path === "/past-proposal-list/:id") {
                    return <PastProposalList bid_id={this.props.match.params.id} />;
                }
            } else {
                return <AccessDenied />;
            }
        };

        return (
            <React.Fragment>
                <Notifications options={{ zIndex: 99999 }} />
                {renderview()}
            </React.Fragment>
        );
    }
}
export default Client;