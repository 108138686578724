import React from "react";
import { Modal } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Validations from "../../../../components/Utility/Validations";
class AddBidOwnersModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bid_owner_form: {
                bid_id: {
                    label: "Bid ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                bid_owners: {
                    type: "typeahead",
                    label: "Owners",
                    value: [],
                    options: [],
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: {
                        required: false,
                        typeahead: false,
                    },
                },
            },
            bid_detail: [],
        };
    }

    key_to_array_multidimensional(array_objects, key) {
        let custom_array = {};
        for (let map_key in array_objects) {
            let array_object = array_objects[map_key];
            let new_key = array_object[key];
            if (typeof custom_array[array_object[key]] === "undefined") {
                custom_array[new_key] = array_object;
            }
        }
        return custom_array;
    }

    componentDidMount() {
        let bid_owners_option = [];
        let bid_detail = this.props.bid_detail;
        let company_users = this.props.company_users;
        const update_bid_owner_form = { ...this.state.bid_owner_form };
        let bid_owners = this.key_to_array_multidimensional(bid_detail.bid_owners, "user_id");
        for (let key in company_users) {
            if (bid_owners[company_users[key].user_id] === undefined) {
                if (company_users[key].role_id === 1 || company_users[key].role_id === 2) {
                    bid_owners_option.push({
                        opk: company_users[key].user_id,
                        name: company_users[key].first_name + " " + company_users[key].last_name,
                        value: company_users[key].user_id,
                    });
                }
            }
        }
        update_bid_owner_form.bid_id.value = bid_detail.bid_id;
        update_bid_owner_form.bid_owners.options = bid_owners_option;
        this.setState({
            bid_detail: bid_detail,
            bid_owner_form: update_bid_owner_form,
        });
    }

    AddBidOwnersReload() {
        let bid_owners_option = [];
        let bid_detail = this.props.bid_detail;
        let company_users = this.props.company_users;
        const update_bid_owner_form = { ...this.state.bid_owner_form };
        let bid_owners = this.key_to_array_multidimensional(bid_detail.bid_owners, "user_id");
        for (let key in company_users) {
            if (bid_owners[company_users[key].user_id] === undefined) {
                if (company_users[key].role_id === 1 || company_users[key].role_id === 2) {
                    bid_owners_option.push({
                        opk: company_users[key].user_id,
                        name: company_users[key].first_name + " " + company_users[key].last_name,
                        value: company_users[key].user_id,
                    });
                }
            }
        }
        update_bid_owner_form.bid_owners.value = [];
        update_bid_owner_form.bid_id.value = bid_detail.bid_id;
        update_bid_owner_form.bid_owners.options = bid_owners_option;
        this.setState({
            bid_detail: bid_detail,
            bid_owner_form: update_bid_owner_form,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_bid_owner_form = this.state.bid_owner_form;
        for (let key in update_bid_owner_form) {
            let form_element = update_bid_owner_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_bid_owner_form[key] = form_element;
        }
        this.setState({ bid_owner_form: update_bid_owner_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.bid_owner_form) {
                if (this.state.bid_owner_form[key].type === "typeahead") {
                    let form_element = this.state.bid_owner_form[key].value;
                    form_data[key] = form_element.map((t) => t.value);
                } else {
                    form_data[key] = this.state.bid_owner_form[key].value;
                }
            }
            this.props.UpdateBidOwner(form_data);
        }
    };

    render() {
        const { bid_detail, bid_owner_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    className="bid_owner_modal"
                    show={this.props.bid_owner_modal}
                    onHide={() => {
                        this.props.bid_owner_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Assign team members</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mb-5">Choose members of your organisation to join your bid team.</p>
                        <p className="mb-3 fw-bold-700">Managers ({bid_detail.bid_owners ? bid_detail.bid_owners.length : 0})</p>
                        <div className="button-group tags-btn d-flex flex-wrap">
                            {bid_detail.bid_owners &&
                                bid_detail.bid_owners.map((bid_owner) => {
                                    return (
                                        <div className="badge bid-badge-main badge-grey  rounded-50" key={"bid_owner_" + bid_owner.user_id}>
                                            <div className="symbol-group symbol-hover me-2">
                                                <span className="symbol symbol-25px symbol-circle">
                                                    {bid_owner && bid_owner.avatar ? (
                                                        <span className="symbol_img symbol-label">
                                                            <img src={bid_owner.avatar} alt="bid_owner_avatar" title="" />
                                                        </span>
                                                    ) : (
                                                        <span className="symbol-label bg-primary text-inverse-primary">{bid_owner.first_name?.slice(0, 1) + bid_owner.last_name?.slice(0, 1)}</span>
                                                    )}
                                                </span>
                                            </div>
                                            {bid_owner.first_name + " " + bid_owner.last_name}
                                            <span
                                                className="cross-button"
                                                onClick={() => {
                                                    this.props.RemoveBidOwner(bid_detail, bid_owner);
                                                }}
                                            >
                                                <i className="icon-cross-2" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    );
                                })}
                        </div>
                        <form className="form w-100 assign-team-search" onSubmit={this.SubmitHandler}>
                            <div className="row mb-6">
                                <div className="col-lg-12 fv-row">
                                    <AsyncTypeahead
                                        multiple
                                        id="bidbid_owners"
                                        labelKey="name"
                                        isLoading={false}
                                        className="react-bootstrap-typeahead"
                                        onSearch={(query) => {}}
                                        options={bid_owner_form.bid_owners.options}
                                        selected={bid_owner_form.bid_owners.value}
                                        onChange={(selected) => {
                                            const update_bid_owner_form = { ...this.state.bid_owner_form };
                                            const form_element = { ...update_bid_owner_form["bid_owners"] };
                                            form_element.value = selected;
                                            update_bid_owner_form["bid_owners"] = form_element;
                                            this.setState({
                                                bid_owner_form: update_bid_owner_form,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn cancel-butn rounded-50 w-150"
                                    onClick={() => {
                                        this.props.bid_owner_modal_action(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary remove-user rounded-50 w-150 hvr-grow">
                                    Save
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddBidOwnersModal;