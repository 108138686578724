import React from "react";
import { Modal } from "react-bootstrap";
import Validations from "../../../components/Utility/Validations";

class AddLibraryFolder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addlibraryfolder_form: {
                name: {
                    label: "Name this folder",
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: true },
                },
            },
            addlibraryfolder_modal: false,
        };
    }

    inputChangeHandler(event, identifier) {
        const update_addlibraryfolder_form = { ...this.state.addlibraryfolder_form };
        const form_element = { ...update_addlibraryfolder_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_addlibraryfolder_form[identifier] = form_element;
        this.setState({ addlibraryfolder_form: update_addlibraryfolder_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_addlibraryfolder_form = this.state.addlibraryfolder_form;
        for (let key in update_addlibraryfolder_form) {
            let form_element = update_addlibraryfolder_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_addlibraryfolder_form[key] = form_element;
        }
        this.setState({ addlibraryfolder_form: update_addlibraryfolder_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.addlibraryfolder_form) {
                form_data[key] = this.state.addlibraryfolder_form[key].value;
            }
            this.props.CreateLibrary(form_data);
        }
    };

    render() {
        const { addlibraryfolder_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="addlibraryfolder_modal bid-custom-modal"
                    show={this.props.addlibraryfolder_modal}
                    onHide={() => {
                        this.props.addlibraryfolder_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>New folder</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="row">
                                <div className={"col-lg-12 fv-row mb-4" + (!addlibraryfolder_form.name.valid && addlibraryfolder_form.name.onBlur_out ? " error-field" : "")}>
                                    <label className="form-label fs-6">{addlibraryfolder_form.name.label}</label>
                                    <input
                                        type={addlibraryfolder_form.name.type}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={addlibraryfolder_form.name.placeholder}
                                        value={addlibraryfolder_form.name.value ? addlibraryfolder_form.name.value : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "name")}
                                        onBlur={(event) => {
                                            addlibraryfolder_form.name.onBlur_out = true;
                                            this.setState({
                                                addlibraryfolder_form: addlibraryfolder_form,
                                            });
                                        }}
                                    />
                                    {!addlibraryfolder_form.name.valid && addlibraryfolder_form.name.onBlur_out ? <div className="error field-error">{addlibraryfolder_form.name.error_msg}</div> : ""}
                                </div>
                            </div>
                            <div className="d-flex align-items-center my-2 justify-content-end">
                                <button
                                    type="button"
                                    className="btn cancel-butn rounded-50 w-150"
                                    onClick={() => {
                                        this.props.addlibraryfolder_modal_action(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-danger rounded-50 w-150px hvr-grow">
                                    Create
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default AddLibraryFolder;