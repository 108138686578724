import React, { useState, useEffect, useCallback, useRef } from "react";

import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";
import { render } from "@testing-library/react";
import { useDispatch } from "react-redux";

import ConfirmDialog from "../../components/Utility/ConfirmDialog";

import UploadReturnableDocumentModal from "./modal/UploadReturnableDocumentModal";
import ReturnableRightSideBarFilePreview from "./RightSideBar/ReturnableRightSideBarFilePreview";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";
const LibraryReturnableDocument = (props) => {
    const dispatch = useDispatch();
    const ConfirmDialogEvent = useRef(null);

    const [loader] = useState(false);
    const [search, setsearch] = useState(null);
    const [returnable_documents, setreturnable_documents] = useState([]);

    const [SelctedReturnableDocument, setSelctedReturnableDocument] = useState(false);
    const [returnablerightsidebar_filepreview, setreturnablerightsidebar_filepreview] = useState(false);
    const [upload_returnabledocument_modal, setupload_returnabledocument_modal] = useState(false);

    const GetLibraryReturnableDocuments = useCallback(() => {
        dispatch(userActions.GetLibraryReturnableDocuments(search)).then((response) => {
            if (response.success === true) {
                console.log("GetLibraryReturnableDocuments response.data--------", response.data);
                setreturnable_documents(response.data);
            } else {
                notify.show(response.message, "error");
            }
        });
    }, [dispatch, search]);

    useEffect(() => {
        GetLibraryReturnableDocuments();
    }, [GetLibraryReturnableDocuments, search]);

    const CreateLibraryReturnableDocument = (file) => {
        const formdata = {};
        formdata["file"] = file;
        setupload_returnabledocument_modal(false);
        props.onCreateLibraryReturnableDocument(formdata).then((response) => {
            if (response.success === true) {
                GetLibraryReturnableDocuments();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    const DeleteLibraryReturnableDocument = (returnable_document) => {
        render(
            <ConfirmDialog
                title="Delete Returnable Documents ?"
                body="Are you sure you want to delete this document? It’ll be gone permanently."
                confirm_modal={true}
                ref={ConfirmDialogEvent}
                confirmButtonText="Delete"
                onConfirm={(event) => ConfirmDeleteLibraryReturnableDocument(returnable_document)}
            />
        );
    };

    const ConfirmDeleteLibraryReturnableDocument = (returnable_document) => {
        props.onDeleteLibraryReturnableDocument(returnable_document.library_returnable_documents_id).then((response) => {
            if (response.success === true) {
                GetLibraryReturnableDocuments();
                ConfirmDialogEvent.current.ModalActionHide();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    return (
        <React.Fragment>
            {loader ? <Loader /> : null}
            {props.loader ? <Loader /> : null}

            {upload_returnabledocument_modal && (
                <UploadReturnableDocumentModal
                    upload_returnabledocument_modal={upload_returnabledocument_modal}
                    CreateLibraryReturnableDocument={(file) => CreateLibraryReturnableDocument(file)}
                    upload_returnabledocument_modal_action={(modal_action) => {
                        setupload_returnabledocument_modal(modal_action);
                    }}
                />
            )}

            <div className={"d-flex flex-column flex-root" + (returnablerightsidebar_filepreview ? " bid_rightsidebar_comment" : "")}>
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                        <LoginHeader page_title="Library" />

                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="l-content">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/library" className="nav-link text-active-primary me-6">
                                                        Content
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/returnable-documents" className="nav-link text-active-primary me-6 active">
                                                        Returnable Documents
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="card mb-5 mb-xl-8">
                                                <div className="card-header border-0 pt-5 mb-5 d-block">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label mt-1 fw-bolder fs-2x ">Returnable Documents and Attachments</span>
                                                                    <span className="fw-normal text-gray-new fs-6 mt-2">Manage your returnable documents and schedules.</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 d-flex align-items-center my-2 justify-content-end">
                                                            <div className="d-flex align-items-center my-2 justify-content-end">
                                                                <div className="d-flex align-items-center position-relative me-4 library-search return-search">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Search for returnable documents..."
                                                                        className="form-control form-control-sm ps-7 search-expand"
                                                                        onChange={(event) => {
                                                                            setsearch(event.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="rounded-50 btn btn-primary btn-sm hvr-grow"
                                                                    onClick={(event) => {
                                                                        setupload_returnabledocument_modal(true);
                                                                    }}
                                                                >
                                                                    Upload a file
                                                                    <span className="svg-icon svg-icon-2">
                                                                        <img src="assets/images/icons/white-add.svg" title="all_bids" alt="all_bids" />
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {returnable_documents && returnable_documents.length !== 0 && (
                                                    <div className="card-body pt-0">
                                                        <div className="row mb-4 return-doc">
                                                            {returnable_documents.map((returnable_document) => {
                                                                return (
                                                                    <div className="col-md-3 col-xl-2 fv-row" key={"library_returnable_documents_id_" + returnable_document.library_returnable_documents_id}>
                                                                        <div className="row main_returnable_documents_div">
                                                                            {returnable_document?.media_type ? (
                                                                                <div className="col-sm-12 col-lg-12 align_font mb-1">
                                                                                    {returnable_document.media_type.indexOf("jpeg") !== -1 && (
                                                                                        <div className="col-sm-12 col-lg-12 align_font mb-1">
                                                                                            <i className="text-grey-dark icon-JPG fs-5x" aria-hidden="true"></i>
                                                                                        </div>
                                                                                    )}
                                                                                    {returnable_document.media_type.indexOf("png") !== -1 && (
                                                                                        <div className="col-sm-12 col-lg-12 align_font mb-1">
                                                                                            <i className="text-grey-dark icon-PNG fs-5x" aria-hidden="true"></i>
                                                                                        </div>
                                                                                    )}
                                                                                    {returnable_document.media_type.indexOf("gif") !== -1 && (
                                                                                        <div className="col-sm-12 col-lg-12 align_font mb-1">
                                                                                            <i className="text-grey-dark icon-GIF fs-5x" aria-hidden="true"></i>
                                                                                        </div>
                                                                                    )}
                                                                                    {returnable_document.media_type.indexOf("svg") !== -1 && (
                                                                                        <div className="col-sm-12 col-lg-12 align_font mb-1">
                                                                                            <i className="text-grey-dark icon-SVG fs-5x" aria-hidden="true"></i>
                                                                                        </div>
                                                                                    )}
                                                                                    {returnable_document.media_type.indexOf("pdf") !== -1 && (
                                                                                        <div className="col-sm-12 col-lg-12 align_font mb-1">
                                                                                            <i className="text-grey-dark icon-PDF fs-5x" aria-hidden="true"></i>
                                                                                        </div>
                                                                                    )}
                                                                                    {returnable_document.media_type.indexOf("msword") !== -1 && (
                                                                                        <div className="col-sm-12 col-lg-12 align_font mb-1">
                                                                                            <i className="text-grey-dark icon-DOC fs-5x" aria-hidden="true"></i>
                                                                                        </div>
                                                                                    )}
                                                                                    {returnable_document.media_type.indexOf("spreadsheetml") !== -1 && (
                                                                                        <div className="col-sm-12 col-lg-12 align_font mb-1">
                                                                                            <i className="text-grey-dark icon-XLS fs-5x" aria-hidden="true"></i>
                                                                                        </div>
                                                                                    )}
                                                                                    {returnable_document.media_type.indexOf("officedocument") !== -1 && returnable_document.media_type.indexOf("spreadsheetml") === -1 && (
                                                                                        <div className="col-sm-12 col-lg-12 align_font mb-1">
                                                                                            <i className="text-grey-dark icon-DOC fs-5x" aria-hidden="true"></i>
                                                                                        </div>
                                                                                    )}
                                                                                    {returnable_document.media_type.indexOf("excel") !== -1 && (
                                                                                        <div className="col-sm-12 col-lg-12 align_font mb-1">
                                                                                            <i className="text-grey-dark icon-XLS fs-5x" aria-hidden="true"></i>
                                                                                        </div>
                                                                                    )}
                                                                                    {returnable_document.media_type.indexOf("csv") !== -1 && (
                                                                                        <div className="col-sm-12 col-lg-12 align_font mb-1">
                                                                                            <i className="text-grey-dark icon-CSV fs-5x" aria-hidden="true"></i>
                                                                                        </div>
                                                                                    )}
                                                                                    {returnable_document.media_type.indexOf("html") !== -1 && (
                                                                                        <div className="col-sm-12 col-lg-12 align_font mb-1">
                                                                                            <i className="text-grey-dark icon-HTML fs-5x" aria-hidden="true"></i>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <div className="col-sm-12 col-lg-12 align_font mb-1">
                                                                                    <i className="text-grey-dark icon-Pdf fs-5x" aria-hidden="true"></i>
                                                                                </div>
                                                                            )}

                                                                            <div className="add_dropdowan_returnable_documents">
                                                                                <div className="dropdown" key="1">
                                                                                    <div className="cursor-pointer" aria-expanded="false" id="1" data-bs-toggle="dropdown">
                                                                                        <i className="icon-vertical-dots text-gray-new"></i>
                                                                                    </div>
                                                                                    <div className="dropdown-menu w-175px cursor-pointer" aria-labelledby="1">
                                                                                        <Link to={{ pathname: returnable_document.media_url }} className="dropdown-item" target="_blank">
                                                                                            <i className="fa fa-eye text-black me-3" aria-hidden="true"></i> View
                                                                                        </Link>
                                                                                        <span
                                                                                            className="dropdown-item text-black cursor-pointer"
                                                                                            onClick={(event) => {
                                                                                                DeleteLibraryReturnableDocument(returnable_document);
                                                                                            }}
                                                                                        >
                                                                                            <i className="icon-delete text-black me-3" aria-hidden="true"></i> Delete
                                                                                        </span>
                                                                                        <span
                                                                                            className="dropdown-item"
                                                                                            onClick={(event) => {
                                                                                                setreturnablerightsidebar_filepreview(true);
                                                                                                setSelctedReturnableDocument(returnable_document);
                                                                                            }}
                                                                                        >
                                                                                            <i className="icon-File text-black me-3" aria-hidden="true"></i> File info
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12 col-lg-12 mt-2">
                                                                                <div className="folder_name">{returnable_document.title}</div>
                                                                                <div className="folder_foles">Last modified {moment(returnable_document.updated_date).format("DD MMM YYYY")}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right_sidebar_comment library-sidebar">
                                {returnablerightsidebar_filepreview && (
                                    <ReturnableRightSideBarFilePreview
                                        returnable_document={SelctedReturnableDocument}
                                        returnablerightsidebar_filepreview={returnablerightsidebar_filepreview}
                                        DeleteLibraryReturnableDocument={(returnable_document) => {
                                            setreturnablerightsidebar_filepreview(false);
                                            DeleteLibraryReturnableDocument(returnable_document);
                                        }}
                                        returnablerightsidebar_filepreview_action={(modal_action) => {
                                            setreturnablerightsidebar_filepreview(modal_action);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

function mapStateToProps(state) {
    return {
        error: state.Library.error,
        loader: state.Library.loader,
        isAuthenticated: state.Library.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetLibraryReturnableDocuments: userActions.GetLibraryReturnableDocuments,
    onCreateLibraryReturnableDocument: userActions.CreateLibraryReturnableDocument,
    onDeleteLibraryReturnableDocument: userActions.DeleteLibraryReturnableDocument,
};
export default connect(mapStateToProps, mapDispatchToProps)(LibraryReturnableDocument);