import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import CheckSubscription from "./Utility/CheckSubscription/CheckSubscription.js";
class LoginFooter extends React.Component {
    render() {
        return (
            <React.Fragment>
                <CheckSubscription />
                <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                    <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                        <div className="text-dark order-2 order-md-1">
                            <span className="text-muted fw-bold me-1">{moment().format("YYYY")}©</span>
                            <Link to="/dashboard" target="_blank" className="text-gray-800 text-hover-primary">
                                BidHQ
                            </Link>
                        </div>

                        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                            <li className="menu-item">
                                <Link to={{ pathname: "https://bidhq.com.au/about/" }} target="_blank" className="menu-link px-2">
                                    About
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to={{ pathname: "https://support.bidhq.com.au/" }} target="_blank" className="menu-link px-2">
                                    Support
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                    <span className="svg-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
                            <path
                                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                fill="black"
                            />
                        </svg>
                    </span>
                </div>
            </React.Fragment>
        );
    }
}
export default LoginFooter;