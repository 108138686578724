import React from "react";
import Notifications from "react-notify-toast";

import BidCreate from "../../components/Bid/Create/BidCreate.js";
import BidUpdate from "../../components/Bid/Update/BidUpdate.js";
import BidOverview from "../../components/Bid/BidOverview.js";
import BidResponse from "../../components/Bid/View/BidResponse.js";
import BidResponseShare from "../../components/Bid/Share/BidResponseShare.js";

class Bid extends React.Component {
    render() {
        const renderview = () => {
            if (this.props.match.path === "/bid-create") {
                return <BidCreate />;
            }

            if (this.props.match.path === "/bid-update/:id") {
                return <BidUpdate bid_id={this.props.match.params.id} />;
            }

            if (this.props.match.path === "/bid-overview") {
                return <BidOverview />;
            }

            if (this.props.match.path === "/bid-response/:id") {
                return <BidResponse bid_id={this.props.match.params.id} />;
            }

            if (this.props.match.path === "/bid-response-share/:id") {
                return <BidResponseShare bid_id={this.props.match.params.id} />;
            }
        };
        return (
            <React.Fragment>
                <Notifications options={{ zIndex: 99999 }} />
                {renderview()}
            </React.Fragment>
        );
    }
}
export default Bid;