import React from "react";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";

import WysiwygEditorWithUserTag from "../../components/Utility/WysiwygEditorWithUserTag";

import * as userActions from "../../actions/index";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";
import LoginFooter from "../../components/LoginFooter";

class Test extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            company_users: [],
        };
    }

    async componentDidMount() {
        await this.props.onGetCompanyUsers().then((response) => {
            if (response.success === true) {
                let company_users = [];
                let response_details = response.data;

                if (response_details) {
                    for (let response_key in response_details) {
                        let response_detail = response_details[response_key];
                        company_users.push({
                            value: response_detail.user_id,
                            avatar: response_detail.avatar,
                            first_name: response_detail.first_name,
                            last_name: response_detail.last_name,
                            key: response_detail.first_name + " " + response_detail.last_name,
                            name: response_detail.first_name + " " + response_detail.last_name,
                        });
                    }
                }
                this.setState({ company_users: company_users });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    render() {
        const { company_users } = this.state;
        return (
            <React.Fragment>
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Test Page" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="row">
                                        <div className="col-lg-12 w-100 h-100">
                                            {company_users.length !== 0 && (
                                                <WysiwygEditorWithUserTag
                                                    // value=""
                                                    // value='<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>'
                                                    value='Lorem Ipsum is simply dummy text of the printing and typesetting industry. <span class="fr-deletable fr-tribute editor_user_selected">@Contributor 2</span>'
                                                    tagusers={company_users}
                                                    onClick={(event) => console.log("onClick event-------------", event)}
                                                    onChange={(event) => console.log("event-----------", event)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetCompanyUsers: userActions.GetCompanyUsers,
};
export default connect(mapStateToProps, mapDispatchToProps)(Test);