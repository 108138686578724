import React from "react";
import { Modal } from "react-bootstrap";
import Validations from "../../../components/Utility/Validations";

class LibraryContentRenameModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            librarycontentrename_form: {
                content_id: {
                    label: "Content ID",
                    type: "hidden",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                content_name: {
                    label: "Rename this content",
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: true },
                },
            },
        };
    }

    componentDidMount() {
        let library_content = this.props.library_content;		
        const update_librarycontentrename_form = { ...this.state.librarycontentrename_form };

        update_librarycontentrename_form.content_id.value = library_content.content_id;
        update_librarycontentrename_form.content_name.value = library_content.content_name;

        this.setState({ librarycontentrename_form: update_librarycontentrename_form });
    }

    inputChangeHandler(event, identifier) {
        const update_librarycontentrename_form = { ...this.state.librarycontentrename_form };
        const form_element = { ...update_librarycontentrename_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_librarycontentrename_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_librarycontentrename_form) {
            is_form_valid = update_librarycontentrename_form[identifier].valid && is_form_valid;
        }

        this.setState({
            librarycontentrename_form: update_librarycontentrename_form,
            is_form_valid: is_form_valid,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_librarycontentrename_form = this.state.librarycontentrename_form;
        for (let key in update_librarycontentrename_form) {
            let form_element = update_librarycontentrename_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_librarycontentrename_form[key] = form_element;
        }
        this.setState({ librarycontentrename_form: update_librarycontentrename_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.librarycontentrename_form) {
                form_data[key] = this.state.librarycontentrename_form[key].value;
            }
            this.props.UpdateLibraryContent(form_data);
        }
    };

    render() {
        const { librarycontentrename_form } = this.state;
        return (
            <React.Fragment>
                <Modal
                    backdrop="static"
                    keyboard={false}
                    className="librarycontentrename_modal bid-custom-modal"
                    show={this.props.librarycontentrename_modal}
                    onHide={() => {
                        this.props.librarycontentrename_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Rename</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="row">
                                <div className={"col-lg-12 fv-row mb-4" + (!librarycontentrename_form.content_name.valid && librarycontentrename_form.content_name.onBlur_out ? " error-field" : "")}>
                                    <label className="form-label fs-6">{librarycontentrename_form.content_name.label}</label>
                                    <input
                                        type={librarycontentrename_form.content_name.type}
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={librarycontentrename_form.content_name.placeholder}
                                        value={librarycontentrename_form.content_name.value ? librarycontentrename_form.content_name.value : ""}
                                        onChange={(event) => this.inputChangeHandler(event, "content_name")}
                                        onBlur={(event) => {
                                            librarycontentrename_form.content_name.onBlur_out = true;
                                            this.setState({
                                                librarycontentrename_form: librarycontentrename_form,
                                            });
                                        }}
                                    />
                                    {!librarycontentrename_form.content_name.valid && librarycontentrename_form.content_name.onBlur_out ? <div className="error field-error">{librarycontentrename_form.content_name.error_msg}</div> : ""}
                                </div>
                            </div>
                            <div className="d-flex align-items-center my-2 justify-content-end">
                                <button
                                    type="button"
                                    className="btn cancel-butn rounded-50 w-150"
                                    onClick={() => {
                                        this.props.librarycontentrename_modal_action(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-danger rounded-50 w-150px hvr-grow">
                                    Ok
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default LibraryContentRenameModal;