import React from "react";
import { connect } from "react-redux";
import Notifications from "react-notify-toast";
import { notify } from "react-notify-toast";
import { Redirect } from "react-router-dom";

import * as userActions from "../../../actions/SuperAdmin/index";
import WysiwygEditor from "../../../components/Utility/WysiwygEditor";

import Loader from "../../../components/Utility/Loader";
import Validations from "../../../components/Utility/Validations";
import LoginSidebar from "../../../components/SuperAdminSidebar";
import LoginHeader from "../../../components/SuperAdminHeader";
import LoginFooter from "../../../components/SuperAdminFooter";

class AddContentSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentsection_form: {
                bidtype_uuid: {
                    type: "text",
                    label: "bidtype Id",
                    value: this.props.bidtype_uuid,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                title: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                content: {
                    type: "text",
                    label: "Description",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                bidtype_content_status: {
                    type: "select",
                    label: "Status",
                    value: "true",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [
                        { opk: "bidtype_content_status_0", name: "Select Status", value: "" },
                        { opk: "bidtype_content_status_1", name: "Active", value: "true" },
                        { opk: "bidtype_content_status_2", name: "Inactive", value: "false" },
                    ],
                },
                is_pricing: {
                    type: "checkbox",
                    label: "Is Pricing",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },
                demo_video_description: {
                    type: "textarea",
                    label: "Description",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                demo_video: {
                    type: "url",
                    label: "Video",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                demo_content: {
                    type: "textarea",
                    label: "Description",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
            },
            loader: false,
        };
    }

    inputChangeHandlerWysiwygEditor(event, identifier) {
        let update_contentsection_form = this.state.contentsection_form;

        update_contentsection_form[identifier].value = event;

        this.setState({ contentsection_form: update_contentsection_form });
    }

    inputChangeHandler(event, identifier) {
        const update_contentsection_form = { ...this.state.contentsection_form };
        const form_element = { ...update_contentsection_form[identifier] };

        if (form_element.type === "checkbox") {
            form_element.value = event.target.checked;
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_contentsection_form[identifier] = form_element;

        this.setState({ contentsection_form: update_contentsection_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_contentsection_form = this.state.contentsection_form;
        for (let key in update_contentsection_form) {
            let form_element = update_contentsection_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_contentsection_form[key] = form_element;
        }
        this.setState({ contentsection_form: update_contentsection_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.contentsection_form) {
                form_data[key] = this.state.contentsection_form[key].value;
            }
            this.props.onCreateManageContent(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/superadmin/manage-content/?activebidtype=${sessionStorage.getItem("selected_bidtype_uuid")}`,
                        });
                    }, 1000);
                } else {
                    notify.show(response.message, "error");
                }
            });
        } else {
            window.scrollTo(0, 0);
            notify.show("Please enter all fields", "error");
        }
    };

    render() {
        const { contentsection_form } = this.state;
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}
                <Notifications />
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Manage Content" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card mb-4 mb-xl-8 bg-transparent">
                                                    <div className="card-header border-0 p-0 align-items-center">
                                                        <h3 className="card-title align-items-start flex-column m-0">
                                                            <span className="card-label fw-bolder fs-22px mb-2">Add a content section</span>                                                            
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-10">
                                                <div className="form w-100">
                                                    <form className="form w-100" onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 mb-xl-6">
                                                            <div className="card-header border-0 pt-8 mb-4">
                                                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                    <img className="mt-1 align-self-start" src="assets/images/icons/c-info.svg" title="all_bids" alt="all_bids" />
                                                                    <h3 className="card-title align-items-start flex-column ms-4 mt-0">
                                                                        <span className="card-label fw-bolder fs-22px mb-1 color1">Name of content section</span>
                                                                        <span className="fw-normal text-gray-new fs-6">
                                                                            Name the type of content that will go into a bid. You can assign this content to a particular bid (or bids) by navigating to Manage Content > Types of bids > Edit
                                                                            the relevant bid.
                                                                        </span>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="row mb-4">
                                                                    <div className={"col-lg-12 fv-row mb-6" + (!contentsection_form.title.valid && contentsection_form.title.onBlur_out ? " error-field" : "")}>
                                                                        <label className="form-label fs-6">{contentsection_form.title.label}</label>
                                                                        <input
                                                                            type={contentsection_form.title.type}
                                                                            className="form-control form-control-lg form-control-solid"
                                                                            placeholder={contentsection_form.title.placeholder}
                                                                            value={contentsection_form.title.value ? contentsection_form.title.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "title")}
                                                                            onBlur={(event) => {
                                                                                contentsection_form.title.onBlur_out = true;
                                                                                this.setState({
                                                                                    contentsection_form: contentsection_form,
                                                                                });
                                                                            }}
                                                                        />
                                                                        {!contentsection_form.title.valid && contentsection_form.title.onBlur_out ? <div className="error field-error">{contentsection_form.title.error_msg}</div> : ""}
                                                                    </div>
                                                                    <div className="col-lg-12 fv-row mb-6">
                                                                        <label className="form-label fs-6">{contentsection_form.content.label}</label>
                                                                        <WysiwygEditor
                                                                            placeholder={contentsection_form.content.placeholder}
                                                                            value={contentsection_form.content.value ? contentsection_form.content.value : ""}
                                                                            onChange={(event) => this.inputChangeHandlerWysiwygEditor(event, "content")}
                                                                        />
                                                                        {!contentsection_form.content.valid && contentsection_form.content.onBlur_out && <div className="error field-error">{contentsection_form.content.error_msg}</div>}
                                                                    </div>
                                                                    <div
                                                                        className={"col-lg-12 fv-row mb-6" + (!contentsection_form.bidtype_content_status.valid && contentsection_form.bidtype_content_status.onBlur_out ? " error-field" : "")}
                                                                    >
                                                                        <label className="form-label fs-6">{contentsection_form.bidtype_content_status.label}</label>
                                                                        <select
                                                                            className="form-control form-control-lg form-control-solid"
                                                                            value={contentsection_form.bidtype_content_status.value}
                                                                            onChange={(event) => this.inputChangeHandler(event, "bidtype_content_status")}
                                                                        >
                                                                            {contentsection_form.bidtype_content_status.options.map((option) => (
                                                                                <option className="one" value={option.value} key={option.opk}>
                                                                                    {option.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {!contentsection_form.bidtype_content_status.valid && contentsection_form.bidtype_content_status.onBlur_out && (
                                                                            <div className="error field-error">{contentsection_form.bidtype_content_status.error_msg}</div>
                                                                        )}
                                                                    </div>

                                                                    <div className={"col-lg-12 fv-row" + (!contentsection_form.is_pricing.valid && contentsection_form.is_pricing.onBlur_out ? " error-field" : "")}>
                                                                        <label className="form-label fs-6">{contentsection_form.is_pricing.label}</label>
                                                                        <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="is_pricing"
                                                                                className="form-check-input"
                                                                                value={contentsection_form.is_pricing.value}
                                                                                checked={contentsection_form.is_pricing.value}
                                                                                onChange={(event) => this.inputChangeHandler(event, "is_pricing")}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="is_pricing"></label>
                                                                        </div>
                                                                        {!contentsection_form.is_pricing.valid && contentsection_form.is_pricing.onBlur_out && (
                                                                            <div className="error field-error">{contentsection_form.is_pricing.error_msg}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-5 mb-xl-6">
                                                            <div className="card-header border-0 pt-8 mb-4">
                                                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                    <img className="mt-1 align-self-start" src="assets/images/icons/edu-img.svg" title="all_bids" alt="all_bids" />
                                                                    <h3 className="card-title align-items-start flex-column ms-4 mt-0">
                                                                        <span className="card-label fw-bolder fs-22px mb-1 color1">Educational content</span>
                                                                        <span className="fw-normal text-gray-new fs-6">This content will display in the “Education” sidebar content for this content type.</span>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="row">
                                                                    <div className="col-lg-12 fv-row mb-6">
                                                                        <label className="form-label fs-6">{contentsection_form.demo_video_description.label}</label>
                                                                        <WysiwygEditor
                                                                            placeholder={contentsection_form.demo_video_description.placeholder}
                                                                            value={contentsection_form.demo_video_description.value ? contentsection_form.demo_video_description.value : ""}
                                                                            onChange={(event) => this.inputChangeHandlerWysiwygEditor(event, "demo_video_description")}
                                                                        />
                                                                        {!contentsection_form.demo_video_description.valid && contentsection_form.demo_video_description.onBlur_out && (
                                                                            <div className="error field-error">{contentsection_form.demo_video_description.error_msg}</div>
                                                                        )}
                                                                    </div>
                                                                    <div className={"col-lg-12 fv-row" + (!contentsection_form.demo_video.valid && contentsection_form.demo_video.onBlur_out ? " error-field" : "")}>
                                                                        <label className="form-label fs-6">{contentsection_form.demo_video.label}</label>
                                                                        <input
                                                                            type={contentsection_form.demo_video.type}
                                                                            className="form-control form-control-lg form-control-solid"
                                                                            placeholder={contentsection_form.demo_video.placeholder}
                                                                            value={contentsection_form.demo_video.value ? contentsection_form.demo_video.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "demo_video")}
                                                                            onBlur={(event) => {
                                                                                contentsection_form.demo_video.onBlur_out = true;
                                                                                this.setState({
                                                                                    contentsection_form: contentsection_form,
                                                                                });
                                                                            }}
                                                                        />
                                                                        {!contentsection_form.demo_video.valid && contentsection_form.demo_video.onBlur_out && (
                                                                            <div className="error field-error">{contentsection_form.demo_video.error_msg}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-5 mb-xl-6">
                                                            <div className="card-header border-0 pt-8 mb-4">
                                                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                                                    <img className="mt-1 align-self-start" src="assets/images/icons/edu-img.svg" title="all_bids" alt="all_bids" />
                                                                    <h3 className="card-title align-items-start flex-column ms-4 mt-0">
                                                                        <span className="card-label fw-bolder fs-22px mb-1 color1">Demo content</span>
                                                                        <span className="fw-normal text-gray-new fs-6">This will populate the optional demo information for this content type.</span>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="row mb-4">
                                                                    <div className="col-lg-12 fv-row mb-6">
                                                                        <label className="form-label fs-6">{contentsection_form.demo_content.label}</label>
                                                                        <WysiwygEditor
                                                                            placeholder={contentsection_form.demo_content.placeholder}
                                                                            value={contentsection_form.demo_content.value ? contentsection_form.demo_content.value : ""}
                                                                            onChange={(event) => this.inputChangeHandlerWysiwygEditor(event, "demo_content")}
                                                                        />
                                                                        {!contentsection_form.demo_content.valid && contentsection_form.demo_content.onBlur_out && (
                                                                            <div className="error field-error">{contentsection_form.demo_content.error_msg}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 d-flex justify-content-end">
                                                            <button type="submit" className="mt-4 rounded-50 btn btn-danger btn-lg hvr-grow-arrow w-200px">
                                                                Add content
                                                                <span className="svg-icon svg-icon-2">
                                                                    <img src="assets/images/icons/white-add.svg" title="all_bids" alt="all_bids" />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onCreateManageContent: userActions.CreateManageContent,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddContentSection);