import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import LoginHeader from "../../../../components/SuperAdminHeader";
import LoginFooter from "../../../../components/SuperAdminFooter";
import LoginSidebar from "../../../../components/SuperAdminSidebar";

import * as SuperAdminUserActions from "../../../../actions/SuperAdmin/index";

class UsersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                            <LoginHeader page_title="Settings" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/superadmin/settings-myprofile" className="nav-link text-active-primary me-6">
                                                        Account settings
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/superadmin/settings-users" className="nav-link text-active-primary me-6 active">
                                                        Users
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/superadmin/settings-pricing" className="nav-link text-active-primary me-6">
                                                        Pricing
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">UsersList</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetInviteUsers: SuperAdminUserActions.UpdatePassword,
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersList);