import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import Validations from "../../components/Utility/Validations";

class verification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            verification_form: {
                Email: {
                    label: "Email address",
                    type: "email",
                    value: localStorage.getItem("verfication_user_email"),
                    valid: true,
                    error_msg: "",
                    validations: { required: true, email: true },
                },
                Verification_Code: {
                    label: "Two-factor authentication",
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: true },
                },
            },
            loader: false,
            is_form_valid: false,
            redirect_url: "",
            isAuthenticated: false,
        };
    }

    reset_verification_code = (event) => {
        event.preventDefault();

        const form_data = {};
        for (let key in this.state.verification_form) {
            form_data[key] = this.state.verification_form[key].value;
        }
        this.props.onResendToken(form_data).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    inputChangeHandler = (event, identifier) => {
        event.preventDefault();
        const update_verification_form = { ...this.state.verification_form };
        const form_element = { ...update_verification_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_verification_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_verification_form) {
            is_form_valid = update_verification_form[identifier].valid && is_form_valid;
        }

        this.setState({
            verification_form: update_verification_form,
            is_form_valid: is_form_valid,
        });
    };

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_verification_form = this.state.verification_form;
        for (let key in update_verification_form) {
            let form_element = update_verification_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_verification_form[key] = form_element;
        }
        this.setState({ verification_form: update_verification_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.verification_form) {
                form_data[key] = this.state.verification_form[key].value;
            }
            this.props.onSignInVerify(form_data).then((response) => {
                if (response.success === true) {
                    this.props.onGetAuthUser().then((response) => {
                        if (response.success === true) {
                            setTimeout(() => {
                                this.setState({
                                    isAuthenticated: true,
                                    redirect_url: "/dashboard",
                                });
                            }, 500);
                        } else {
                            notify.show(response.message, "error");
                        }
                    });
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    };

    render() {
        const { verification_form } = this.state;
        return (
            <React.Fragment>
                <Notifications />
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                        <div className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative bg-dark-color">
                            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 text-center-md w-xl-600px scroll-y">
                                <div className="d-flex flex-column pt-lg-10 ps-lg-20">
                                    <Link to="signup" className="mb-10 ps-md-10">
                                        <img alt="Logo" src="assets/images/logo.svg" className="h-60px" />
                                    </Link>
                                    <h1 className="fw-bold-600 fs-50 pb-md-5 ps-md-10 mb-0 text-white">
                                        No more
                                        <br />
                                        confusion. <br />
                                        No more <br />
                                        missing out.
                                    </h1>
                                </div>
                                <div className="d-flex bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px sign-in-banner mt-12"></div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-lg-row-fluid py-9">
                            <div className="d-flex flex-center flex-column-fluid">
                                <div className="w-lg-600px p-10 pb-0 pt-0 mx-auto w-100 forgot-div">
                                    <form onSubmit={this.SubmitHandler} autoComplete="off">
                                        <div className="mb-5">
                                            <h1 className="fw-bolder text-dark mb-2 fs-3qx">Almost there</h1>
                                            <div className="text-dark fs-2x fs-4">For your security please enter your 2FA code.</div>
                                        </div>
                                        <div className={"fv-row mb-5" + (!verification_form.Verification_Code.valid && verification_form.Verification_Code.onBlur_out ? " error-field" : "")}>
                                            <label className="form-label fs-6">{verification_form.Verification_Code.label}</label>
                                            <input
                                                type="text"
                                                id="Verification_Code"
                                                className="form-control form-control-lg form-control-solid"
                                                placeholder="Enter unique verification code"
                                                value={verification_form.Verification_Code.value ? verification_form.Verification_Code.value : ""}
                                                onChange={(event) => this.inputChangeHandler(event, "Verification_Code")}
                                            />
                                            {!verification_form.Verification_Code.valid ? <div className="error field-error">{verification_form.Verification_Code.error_msg}</div> : ""}
                                        </div>
                                        <div className="d-flex mb-5 cursor-pointer">
                                            Didn't receive it?
                                            <span
                                                className="sign-btn ms-1 link-primary"
                                                onClick={(event) => {
                                                    this.reset_verification_code(event);
                                                }}
                                            >
                                                Send again.
                                            </span>
                                        </div>
                                        <button type="submit" className="hvr-grow btn btn-lg btn-primary rounded-50 w-100 mb-8 ">
                                            Sign in
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onSignInVerify: userActions.SignInVerify,
    onResendToken: userActions.ResendToken,
    onGetAuthUser: userActions.GetAuthUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(verification);