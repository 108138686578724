import axios from "axios";
import moment from "moment";
import * as actionType from "../constants/actionTypes";

const qs = require("qs");

export const GetCountries = () => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetCountries`,
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GetCompletions = (form_data) => {
    return new Promise((resolve) => {
        axios({
            method: "post",
            data: form_data,
            url: `${actionType.gpt_url}/completions`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${actionType.gpt_key}`,
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GetChatCompletions = (form_data) => {
    let formData = new FormData();
    for (let key in form_data) {
        formData.append(key, form_data[key]);
    }
    return new Promise((resolve) => {
        axios({
            method: "post",
            data: formData,
            url: `${actionType.gpt_url}/chat/completions`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${actionType.gpt_key}`,
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GetCountriesLists = () => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetCountriesLists`,
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GetStatesByCountryCode = (country_code) => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetStatesByCountryCode`,
            params: {
                country_code: country_code,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GetRoles = () => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetRoles`,
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GetInvitationById = (invite_uuid) => {
    return new Promise((resolve) => {
        axios({
            method: "get",
            url: `${actionType.environment_url}/api/GetInvitationById`,
            params: {
                invite_uuid: invite_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const SubmitInvitation = (form_data) => {
    return new Promise((resolve) => {
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/SubmitInvitation`,
        })
            .then(function (response) {
                if (response.data && response.data.token) {
                    localStorage.setItem("bidhq_authtoken", response.data.token);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
};

export const GreetingOfTheData = (date) => {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
        return "Good morning";
    } else if (curHr < 16) {
        return "Good afternoon";
    } else {
        return "Good evening";
    }
};

export const TimeAgo = (date) => {
    let minute = 60;
    let hour = minute * 60;
    let day = hour * 24;
    let month = day * 30;
    let year = day * 365;
    let suffix = " ago";
    let elapsed = Math.floor((Date.now() - date) / 1000);

    if (elapsed < minute) {
        return "just now";
    }

    let time_ago = [];
    if (elapsed < hour) {
        time_ago = [Math.floor(elapsed / minute), "minute"];
    } else if (elapsed < day) {
        time_ago = [Math.floor(elapsed / hour), "hour"];
    } else if (elapsed < month) {
        time_ago = [Math.floor(elapsed / day), "day"];
    } else if (elapsed < year) {
        time_ago = [Math.floor(elapsed / month), "month"];
    } else {
        time_ago = [Math.floor(elapsed / year), "year"];
    }

    return time_ago[0] + " " + time_ago[1] + (time_ago[0] === 1 ? "" : "s") + suffix;
};

export const MomentTimeAgo = (date) => {
    let date_timestamp = moment(date);
    console.log("TimeAgo date_timestamp-----------", date_timestamp);

    let current_timestamp = moment();
    console.log("TimeAgo current_timestamp------------", current_timestamp);
};

export const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const key_to_array_multidimensional = (array_objects, object_key) => {
    let custom_array = {};
    for (let key in array_objects) {
        let array_object = array_objects[key];
        if (Array.isArray(array_object)) {
            let new_key = array_object[object_key];
            if (typeof custom_array[array_object[object_key]] === "undefined") {
                custom_array[new_key] = [];
            }
            custom_array[new_key].push(array_object);
        } else {
            custom_array[array_object[object_key]] = array_object;
        }
    }
    return custom_array;
};

export const calculate_days_left = (end_date) => {
    let current_date = new Date();
    end_date = new Date(end_date);
    let subscriptiontrail_days = Math.ceil((end_date - current_date) / 8.64e7);
    return subscriptiontrail_days;
};