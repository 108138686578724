import * as SuperAdminactionTypes from "../../constants/SuperAdminactionTypes";

const initial_state = {
    error: null,
    token: null,
    loader: false,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case SuperAdminactionTypes.GetSuperadminDashboard_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.GetSuperadminDashboard_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.GetSuperadminDashboard_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        case SuperAdminactionTypes.GetSuperadminDashboardExcel_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.GetSuperadminDashboardExcel_SUCCESS:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };
        case SuperAdminactionTypes.GetSuperadminDashboardExcel_FAIL:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
                isAuthenticated: action.isAuthenticated,
            };

        default:
            return state;
    }
};

export default reducer;