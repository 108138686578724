import React, { useState, useEffect, useCallback, useRef } from "react";

import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";
import { useDispatch } from "react-redux";
import { render } from "@testing-library/react";

import BidTypesItems from "./BidTypesItem";
import ContentSectionsItem from "./ContentSectionsItem";

import ConfirmDialog from "../../../components/Utility/ConfirmDialog";

import Loader from "../../../components/Utility/Loader";
import * as userActions from "../../../actions/SuperAdmin/index";
import LoginSidebar from "../../../components/SuperAdminSidebar";
import LoginHeader from "../../../components/SuperAdminHeader";
import LoginFooter from "../../../components/SuperAdminFooter";

const BidTypeLists = (props) => {
    const dispatch = useDispatch();
    const ConfirmDialogEvent = useRef(null);
    const [loader, setloader] = useState(false);

    /*** BidType Constant ***/
    const [bidtype_details, setbidtype_details] = useState([]);
    const [bidtype_count, setbidtype_count] = useState(0);

    const [filterlimit] = useState(5);
    const [filterpage, setfilterpage] = useState(1);
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersort, setfiltersort] = useState("bidtype_id");
    const [filtersearch, setfiltersearch] = useState(null);

    /*** BidType Content Constant ***/
    const [contentsection_count, setcontentsection_count] = useState(0);
    const [contentsection_details, setcontentsection_details] = useState([]);

    const [filtercontentsectionlimit] = useState(5);
    const [filtercontentsectionpage, setfiltercontentsectionpage] = useState(1);
    const [filtercontentsectionorder, setfiltercontentsectionorder] = useState("asc");
    const [filtercontentsectionsort, setfiltercontentsectionsort] = useState("content_id");
    const [filtercontentsectionsearch, setfiltercontentsectionsearch] = useState(null);

    const [selected_bidtype_id, setselected_bidtype_id] = useState();
    const [selected_bidtype_uuid, setselected_bidtype_uuid] = useState();
    const [bidtype_content_show, setbidtype_content_show] = useState(false);

    /*** BidType Function ***/
    const GetBidTypes = useCallback(() => {
        setloader(true);
        dispatch(userActions.GetBidTypes(filtersearch, filterpage, filterlimit, filtersort, filterorder)).then((response) => {
            setloader(false);
            if (response.success === true) {
                setbidtype_details(response.data);
                setbidtype_count(response.count);

                setbidtype_content_show(false);
                sessionStorage.removeItem("selected_bidtype_id");
                sessionStorage.removeItem("selected_bidtype_uuid");
            } else {
                notify.show(response.message, "error");
            }
        });

        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get("activebidtype")) {
            if (sessionStorage.getItem("selected_bidtype_id") && sessionStorage.getItem("selected_bidtype_uuid")) {
                // ShowBidtypeContentsByBidTypeId(sessionStorage.getItem("selected_bidtype_id"), sessionStorage.getItem("selected_bidtype_uuid"));

                setbidtype_content_show(false);
                setselected_bidtype_id(sessionStorage.getItem("selected_bidtype_id"));
                setselected_bidtype_uuid(sessionStorage.getItem("selected_bidtype_uuid"));
                setfiltercontentsectionpage(1);

                let current_url = window.location.href;
                window.history.pushState({}, "", current_url.split("?")[0]);
            }
        }
    }, [dispatch, filtersearch, filterpage, filterlimit, filtersort, filterorder]);

    useEffect(() => {
        GetBidTypes();
    }, [dispatch, GetBidTypes, filtersearch, filterpage, filterlimit, filtersort, filterorder]);

    const ConfirmArchiveBidType = (bidtype_detail) => {
        render(
            <ConfirmDialog
                title="Archive BidType?"
                body="Are you sure you want to archive this bidtype?"
                confirm_modal={true}
                ref={ConfirmDialogEvent}
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                onConfirm={(event) => DeleteBidType(bidtype_detail)}
            />
        );
    };

    const DeleteBidType = (bidtype_detail) => {
        setloader(true);
        dispatch(userActions.DeleteBidType(bidtype_detail?.bidtype_uuid)).then((response) => {
            setloader(false);
            if (response.success === true) {
                setfilterpage(1);
                GetBidTypes();

                setselected_bidtype_id();
                setselected_bidtype_uuid();
                setbidtype_content_show(false);

                ConfirmDialogEvent.current.ModalActionHide();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    /*** BidType Content  Function ***/
    const ShowBidtypeContentsByBidTypeId = (bidtype_id, bidtype_uuid) => {
        setbidtype_content_show(false);
        setselected_bidtype_id(bidtype_id);
        setselected_bidtype_uuid(bidtype_uuid);

        setfiltercontentsectionpage(1);
        sessionStorage.setItem("selected_bidtype_id", bidtype_id);
        sessionStorage.setItem("selected_bidtype_uuid", bidtype_uuid);
    };

    const GetBidtypeContentsByBidTypeId = useCallback(() => {
        if (selected_bidtype_id) {
            setloader(true);
            dispatch(
                userActions.GetBidtypeContentsByBidTypeId({
                    bidtype_id: selected_bidtype_id,
                    limit: filtercontentsectionlimit,
                    page: filtercontentsectionpage,
                    order: filtercontentsectionorder,
                    sort: filtercontentsectionsort,
                    search: filtercontentsectionsearch,
                })
            ).then((response) => {
                setloader(false);
                if (response.success === true) {
                    setbidtype_content_show(true);
                    setcontentsection_details(response.data);
                    setcontentsection_count(response.count);
                } else {
                    notify.show(response.message, "error");
                }
            });
        }
    }, [dispatch, selected_bidtype_id, filtercontentsectionlimit, filtercontentsectionpage, filtercontentsectionorder, filtercontentsectionsort, filtercontentsectionsearch]);

    useEffect(() => {
        GetBidtypeContentsByBidTypeId();
    }, [dispatch, GetBidtypeContentsByBidTypeId, filtercontentsectionlimit, filtercontentsectionpage, filtercontentsectionorder, filtercontentsectionsort, filtercontentsectionsearch]);

    const ConfirmArchiveContentSections = (contentsection_details) => {
        render(
            <ConfirmDialog
                title="Archive Content Section?"
                body="Are you sure you want to archive this Content Section?"
                confirm_modal={true}
                ref={ConfirmDialogEvent}
                cancelButtonText="Cancel"
                confirmButtonText="Confirm"
                onConfirm={(event) => DeleteContentSections(contentsection_details)}
            />
        );
    };

    const DeleteContentSections = (contentsection_details) => {
        setloader(true);
        dispatch(userActions.DeleteBidTypeContent(contentsection_details?.content_uuid)).then((response) => {
            setloader(false);
            if (response.success === true) {
                setfiltercontentsectionpage(1);
                GetBidtypeContentsByBidTypeId();
                ConfirmDialogEvent.current.ModalActionHide();
                notify.show(response.message, "success");
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    return (
        <React.Fragment>
            {loader ? <Loader /> : null}
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <LoginSidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <LoginHeader page_title="Manage Content" />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div id="kt_content_container" className="container-xxl">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <h3 className="card-title align-items-start flex-column d-flex text-dark">
                                                <span className="card-label fw-bolder fs-2x mb-1">Types of bids (bid templates)</span>
                                                <span className="mt-2 fw-bold fs-6 color1 ">
                                                    Types of bids create the templates a user can choose to build a bid from. You can determine the
                                                    <br /> content that shows in each type of bid editing a bid type, or adding a new type of bid.
                                                </span>
                                            </h3>
                                        </div>
                                        <div className="col-md-4 justify-content-end text-end d-flex align-items-center">
                                            <Link to="/superadmin/bidtype-create" className="rounded-50 btn btn-danger btn-md hvr-grow-arrow bidtype-btn">
                                                Add a bid type
                                                <span className="svg-icon svg-icon-2">
                                                    <img src="assets/images/icons/white-add.svg" title="all_bids" alt="all_bids" />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="card-body px-0">
                                        <BidTypesItems
                                            selected_bidtype_id={selected_bidtype_id}
                                            selected_bidtype_uuid={selected_bidtype_uuid}
                                            bidtype_details={bidtype_details}
                                            bidtype_count={bidtype_count}
                                            filtersort={filtersort}
                                            filterorder={filterorder}
                                            filterlimit={filterlimit}
                                            filterpage={filterpage}
                                            propsfiltersort={(field) => setfiltersort(field)}
                                            propsfilterorder={(order) => setfilterorder(order)}
                                            propsfilterpage={(event) => setfilterpage(event)}
                                            propsfiltersearch={(event) => setfiltersearch(event)}
                                            ConfirmArchiveBidType={(bidtype_detail) => ConfirmArchiveBidType(bidtype_detail)}
                                            ShowBidtypeContentsByBidTypeId={(bidtype_id, bidtype_uuid) => ShowBidtypeContentsByBidTypeId(bidtype_id, bidtype_uuid)}
                                        />
                                    </div>

                                    <hr />
                                    {bidtype_content_show && (
                                        <>
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h3 className="card-title align-items-start flex-column d-flex text-dark">
                                                        <span className="card-label fw-bolder fs-2x mb-1">Content sections</span>
                                                    </h3>
                                                </div>
                                                <div className="col-md-4 justify-content-end text-end d-flex align-items-center">
                                                    <Link to={`/superadmin/add-contentsection/${selected_bidtype_uuid}`} className="rounded-50 btn btn-danger btn-md hvr-grow-arrow bidtype-btn">
                                                        Add section
                                                        <span className="svg-icon svg-icon-2">
                                                            <img src="assets/images/icons/white-add.svg" title="all_bids" alt="all_bids" />
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="card-body px-0">
                                                <ContentSectionsItem
                                                    selected_bidtype_id={selected_bidtype_id}
                                                    selected_bidtype_uuid={selected_bidtype_uuid}
                                                    contentsection_details={contentsection_details}
                                                    contentsection_count={contentsection_count}
                                                    filtercontentsectionsort={filtercontentsectionsort}
                                                    filtercontentsectionorder={filtercontentsectionorder}
                                                    filtercontentsectionlimit={filtercontentsectionlimit}
                                                    filtercontentsectionpage={filtercontentsectionpage}
                                                    propsfiltercontentsectionsort={(field) => setfiltercontentsectionsort(field)}
                                                    propsfiltercontentsectionorder={(order) => setfiltercontentsectionorder(order)}
                                                    propsfiltercontentsectionpage={(event) => setfiltercontentsectionpage(event)}
                                                    propsfiltercontentsectionsearch={(event) => setfiltercontentsectionsearch(event)}
                                                    ConfirmArchiveContentSections={(contentsection_details) => ConfirmArchiveContentSections(contentsection_details)}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default BidTypeLists;