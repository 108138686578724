import React from "react";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { Link, Redirect } from "react-router-dom";

import * as userActions from "../../actions/index";
import Loader from "../../components/Utility/Loader";
import LoginFooter from "../../components/LoginFooter";
import LoginHeader from "../../components/LoginHeader";
import LoginSidebar from "../../components/LoginSidebar";
import Validations from "../../components/Utility/Validations";

class MyProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            personal_form: {
                avatar: {
                    label: "File",
                    type: "file",
                    value: "",
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "assets/images/profile_dummy.jpg",
                },
                first_name: {
                    type: "text",
                    label: "First name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter first name",
                    validations: { required: true },
                },
                last_name: {
                    type: "text",
                    label: "Second name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter second name",
                    validations: { required: true },
                },
                user_title: {
                    label: "What’s your role?",
                    type: "text",
                    value: "",
                    valid: true,
                    error_msg: "",
                    placeholder: "",
                    onBlur_out: false,
                    validations: { required: false },
                },
                email: {
                    label: "Email address",
                    type: "email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "e.g example@mail.com",
                    validations: { required: true, email: true },
                },
                phone: {
                    type: "phone",
                    label: "Phone number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter phone number",
                    validations: { required: false },
                },
                user_timezone: {
                    type: "select",
                    label: "Select your timezone *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [
                        { opk: "submission_timezone_0", name: "Select Submission timezone", value: null },
                        { opk: "submission_timezone_1", name: "Perth (UTC+08:00)", value: "Australia/Perth" },
                        { opk: "submission_timezone_2", name: "Adelaide (UTC+09:30)", value: "Australia/Adelaide" },
                        { opk: "submission_timezone_3", name: "Darwin (UTC+09:30)", value: "Australia/Darwin" },
                        { opk: "submission_timezone_4", name: "Brisbane (UTC+10:00)", value: "Australia/Brisbane" },
                        { opk: "submission_timezone_5", name: "Canberra, Melbourne, Sydney (UTC+10:00)", value: "Australia/Sydney" },
                        { opk: "submission_timezone_6", name: "Hobart (UTC+10:00)", value: "Australia/Hobart" },
                        { opk: "submission_timezone_7", name: "Auckland, Wellington (UTC+12:00)", value: "Pacific/Auckland" }
                    ],
                },
            },

            loader: false,
            is_form_valid: false,
            redirect_url: "",
            isAuthenticated: false,
            password_show: false,
            current_password_show: false,
            confirm_password_show: false,
        };
    }

    async componentDidMount() {
        const update_personal_form = { ...this.state.personal_form };
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        let personal_info = user_info.user;

        update_personal_form.first_name.value = personal_info.first_name;
        update_personal_form.last_name.value = personal_info.last_name;
        update_personal_form.user_title.value = personal_info.user_title;
        update_personal_form.email.value = personal_info.email;
        update_personal_form.phone.value = personal_info.phone;
        update_personal_form.user_timezone.value = personal_info.user_timezone ? personal_info.user_timezone : "";

        if (personal_info.avatar) {
            update_personal_form.avatar.thumbnail_image = personal_info.avatar;
        }

        this.setState({
            personal_form: update_personal_form,
        });
    }

    remove_profile_image = (event) => {
        event.preventDefault();

        this.props.onRemoveProfileImage().then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");
                let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                user_info.user = response.user_info;
                localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));

                window.location.reload();
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    onFileChange(event, identifier) {
        event.preventDefault();
        this.props.onUpdatePersonalAvatar(event.target.files[0]).then((response) => {
            if (response.success === true) {
                notify.show(response.message, "success");

                let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                user_info.user = response.user_info;
                localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));

                window.location.reload();
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    PersonalChangeHandler(event, identifier) {
        const update_personal_form = { ...this.state.personal_form };
        const form_element = { ...update_personal_form[identifier] };

        if (form_element.type === "file") {
            form_element.value = event.target.files[0];
        } else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_personal_form[identifier] = form_element;

        this.setState({ personal_form: update_personal_form });
    }

    PersonalSubmitHandler = (event) => {
        event.preventDefault();

        // Check Validation
        let is_form_valid = true;
        const update_personal_form = this.state.personal_form;
        for (let key in update_personal_form) {
            let form_element = update_personal_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_personal_form[key] = form_element;
        }
        this.setState({ personal_form: update_personal_form });

        // Form submit handler
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.personal_form) {
                form_data[key] = this.state.personal_form[key].value || '';
            }
            this.props.onUpdatePersonal(form_data).then((response) => {
                if (response.success === true) {
                    notify.show(response.message, "success");

                    let user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
                    user_info.user = response.user_info;
                    localStorage.setItem("bidhq_authuser", JSON.stringify(user_info));

                    if (user_info.user.role.user_role === "Account owner") {
                        setTimeout(() => {
                            this.setState({
                                isAuthenticated: true,
                                redirect_url: "/settings-company",
                            });
                        }, 500);
                    } else {
                        setTimeout(() => {
                            this.setState({
                                isAuthenticated: true,
                                redirect_url: "/dashboard",
                            });
                        }, 500);
                    }
                } else {
                    notify.show(response.message, "error");
                }
            });
        } else {
            window.scrollTo(0, 0);
            notify.show("Please enter all fields", "error");
        }
    };

    render() {
        const { personal_form } = this.state;
        const user_info = JSON.parse(localStorage.getItem("bidhq_authuser"));
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                {this.props.isAuthenticated === false ? <Redirect to="/logout" /> : null}
                {this.state.isAuthenticated ? <Redirect to={this.state.redirect_url} /> : null}

                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid setting-header" id="kt_wrapper">
                            <LoginHeader page_title="Settings" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container " className="container-xxl ">
                                        <div className="d-flex overflow-auto h-40px top-fixed-nav">
                                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 flex-nowrap">
                                                <li className="nav-item">
                                                    <Link to="/settings-myprofile" className="nav-link text-active-primary me-6 active">
                                                        My profile
                                                    </Link>
                                                </li>
                                                {user_info.user.role.user_role === "Account owner" && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-company" className="nav-link text-active-primary me-6">
                                                            Company details
                                                        </Link>
                                                    </li>
                                                )}
                                                <li className="nav-item">
                                                    <Link to="/settings-account" className="nav-link text-active-primary me-6">
                                                        Account
                                                    </Link>
                                                </li>
                                                {user_info.user.role.user_role === "Account owner" && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-billing" className="nav-link text-active-primary me-6">
                                                            Billing
                                                        </Link>
                                                    </li>
                                                )}
                                                {(user_info.user.role.user_role === "Account owner" || user_info.user.role.user_role === "Bid Manager") && (
                                                    <li className="nav-item">
                                                        <Link to="/settings-users" className="nav-link text-active-primary me-6">
                                                            Users
                                                        </Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-11 col-xl-10">
                                                <div className="card mb-5 mb-xl-6">
                                                    <div className="card-header border-0 pt-5 mb-3">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bolder fs-2x mb-1">Personal details</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body pt-0 pb-0 personal-details-outer">
                                                        <form className="form w-100" onSubmit={this.PersonalSubmitHandler}>
                                                            <div className="d-flex align-items-center flex-wrap flex-sm-nowrap mb-3 profile-symbol">
                                                                <div className="me-7 mb-4 ">
                                                                    <div className="symbol symbol-100px symbol-fixed position-relative">
                                                                        <img title="profile_logo" alt="profile_logo" src={personal_form.avatar.thumbnail_image} />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                                        <div className="d-flex flex-column">
                                                                            <div className="mb-2">
                                                                                <p className="text-gray-400">Your picture helps your teammates recognise you in BidHQ</p>
                                                                                <div className="d-flex align-items-center">
                                                                                    <label className="hvr-border-fade btn btn-sm btn-outline-primary rounded-50">
                                                                                        Change photo
                                                                                        <input hidden accept="image/*" type={personal_form.avatar.type} onChange={(event) => this.onFileChange(event, "avatar")} />
                                                                                    </label>
                                                                                    {!personal_form.avatar.valid && personal_form.avatar.onBlur_out ? <div className="error field-error">{personal_form.avatar.error_msg}</div> : ""}

                                                                                    {user_info.user.avatar && (
                                                                                        <>
                                                                                            <span className="h-25px border-gray-700 border-start mx-4"></span>
                                                                                            <span
                                                                                                className="btn btn-sm btn-link"
                                                                                                onClick={(event) => {
                                                                                                    this.remove_profile_image(event);
                                                                                                }}
                                                                                            >
                                                                                                Remove photo
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!personal_form.first_name.valid && personal_form.first_name.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{personal_form.first_name.label}</label>
                                                                    <input
                                                                        type={personal_form.first_name.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={personal_form.first_name.placeholder}
                                                                        value={personal_form.first_name.value ? personal_form.first_name.value : ""}
                                                                        onChange={(event) => this.PersonalChangeHandler(event, "first_name")}
                                                                        onBlur={(event) => {
                                                                            personal_form.first_name.onBlur_out = true;
                                                                            this.setState({
                                                                                personal_form: personal_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!personal_form.first_name.valid && personal_form.first_name.onBlur_out ? <div className="error field-error">{personal_form.first_name.error_msg}</div> : ""}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 mb-4 fv-row" + (!personal_form.last_name.valid && personal_form.last_name.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{personal_form.last_name.label}</label>
                                                                    <input
                                                                        type={personal_form.last_name.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={personal_form.last_name.placeholder}
                                                                        value={personal_form.last_name.value ? personal_form.last_name.value : ""}
                                                                        onChange={(event) => this.PersonalChangeHandler(event, "last_name")}
                                                                        onBlur={(event) => {
                                                                            personal_form.last_name.onBlur_out = true;
                                                                            this.setState({
                                                                                personal_form: personal_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!personal_form.last_name.valid && personal_form.last_name.onBlur_out ? <div className="error field-error">{personal_form.last_name.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className="col-lg-12 fv-row">
                                                                    <label className="form-label fs-6">{personal_form.user_title.label}</label>
                                                                    <input
                                                                        type={personal_form.user_title.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={personal_form.user_title.placeholder}
                                                                        value={personal_form.user_title.value ? personal_form.user_title.value : ""}
                                                                        onChange={(event) => this.PersonalChangeHandler(event, "user_title")}
                                                                        onBlur={(event) => {
                                                                            personal_form.user_title.onBlur_out = true;
                                                                            this.setState({
                                                                                personal_form: personal_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!personal_form.user_title.valid && personal_form.user_title.onBlur_out ? <div className="error field-error">{personal_form.user_title.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className={"col-sm-6 col-lg-6 mb-mobile fv-row" + (!personal_form.email.valid && personal_form.email.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{personal_form.email.label}</label>
                                                                    <input
                                                                        readOnly
                                                                        type={personal_form.email.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={personal_form.email.placeholder}
                                                                        value={personal_form.email.value ? personal_form.email.value : ""}
                                                                        onChange={(event) => this.PersonalChangeHandler(event, "email")}
                                                                        onBlur={(event) => {
                                                                            personal_form.email.onBlur_out = true;
                                                                            this.setState({
                                                                                personal_form: personal_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!personal_form.email.valid && personal_form.email.onBlur_out ? <div className="error field-error">{personal_form.email.error_msg}</div> : ""}
                                                                </div>
                                                                <div className={"col-sm-6 col-lg-6 fv-row" + (!personal_form.phone.valid && personal_form.phone.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{personal_form.phone.label}</label>
                                                                    <input
                                                                        type={personal_form.phone.type}
                                                                        className="form-control form-control-lg form-control-solid"
                                                                        placeholder={personal_form.phone.placeholder}
                                                                        value={personal_form.phone.value ? personal_form.phone.value : ""}
                                                                        onChange={(event) => this.PersonalChangeHandler(event, "phone")}
                                                                        onBlur={(event) => {
                                                                            personal_form.phone.onBlur_out = true;
                                                                            this.setState({
                                                                                personal_form: personal_form,
                                                                            });
                                                                        }}
                                                                    />
                                                                    {!personal_form.phone.valid && personal_form.phone.onBlur_out ? <div className="error field-error">{personal_form.phone.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className="row mb-4">
                                                                <div className={"col-sm-6 col-lg-6 fv-row" + (!personal_form.user_timezone.valid && personal_form.user_timezone.onBlur_out ? " error-field" : "")}>
                                                                    <label className="form-label fs-6">{personal_form.user_timezone.label}</label>
                                                                    <select className="form-select form-select-solid" value={personal_form.user_timezone.value} onChange={(event) => this.PersonalChangeHandler(event, "user_timezone")}>
                                                                        {personal_form.user_timezone.options.map((option) => (
                                                                            <option className="one" value={option.value} key={option.opk}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {!personal_form.user_timezone.valid && personal_form.user_timezone.onBlur_out ? <div className="error field-error">{personal_form.user_timezone.error_msg}</div> : ""}
                                                                </div>
                                                            </div>
                                                            <div className="card-footer d-flex pt-6">
                                                                <button type="submit" className="save-button hvr-grow btn btn-primary rounded-50 w-200">
                                                                    Save changes
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.Settings.error,
        loader: state.Settings.loader,
        isAuthenticated: state.Settings.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onUpdatePersonalAvatar: userActions.UpdatePersonalAvatar,
    onUpdatePersonal: userActions.UpdatePersonal,
    onRemoveProfileImage: userActions.RemoveProfileImage,
    onUpdatePassword: userActions.UpdatePassword,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);