import React from "react";

import moment from "moment";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import Notifications from "react-notify-toast";
import DateRangePicker from "react-bootstrap-daterangepicker";

import Loader from "../../components/Utility/Loader";

import LoginSidebar from "../../components/SuperAdminSidebar";
import LoginHeader from "../../components/SuperAdminHeader";
import LoginFooter from "../../components/SuperAdminFooter";

import exportAsImage from "../../components/Utility/exportAsImage";
import * as SuperAdminActions from "../../actions/SuperAdmin/index";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,

            trial_signups: 0,
            paid_conversions: 0,
            plan_conversions: "",
            plan_conversions_pro: 0,
            plan_conversions_basic: 0,
            expired_signups: 0,
            total_value: 0,

            daterangepicker_label: "All Time",
            startDate: moment().subtract(2, "days"),
            endDate: moment(),

            dashboard_trialsignups_show: true,
            dashboard_paidconversions_show: true,
            dashboard_planconversions_show: true,
            dashboard_expiredsignups_show: true,
            dashboard_totalvalue_show: true,
        };
    }

    async componentDidMount() {
        await this.GetSuperadminDashboard();
    }

    GetSuperadminDashboard = async () => {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;

        let daterangepicker_label = this.state.daterangepicker_label;
        let start_date = daterangepicker_label !== "All Time" ? startDate.format() : "";
        let end_date = daterangepicker_label !== "All Time" ? endDate.format() : "";

        this.props.onGetSuperadminDashboard(start_date, end_date).then((response) => {
            if (response.success === true) {
                let plan_conversions_total = parseInt(response.data?.plan_conversions?.pro_plans) + parseInt(response.data?.plan_conversions?.basic_plans);

                let plan_conversions_pro = parseFloat((response.data?.plan_conversions?.pro_plans / plan_conversions_total) * 100).toFixed(2);
                plan_conversions_pro = isNaN(plan_conversions_pro) ? 0 : plan_conversions_pro;

                let plan_conversions_basic = parseFloat((response.data?.plan_conversions?.basic_plans / plan_conversions_total) * 100).toFixed(2);
                plan_conversions_basic = isNaN(plan_conversions_basic) ? 0 : plan_conversions_basic;

                this.setState({
                    trial_signups: response?.data?.trial_signups ? response?.data?.trial_signups : 0,
                    paid_conversions: response?.data?.paid_conversions ? response?.data?.paid_conversions : 0,

                    plan_conversions_pro: plan_conversions_pro,
                    plan_conversions_basic: plan_conversions_basic,
                    plan_conversions: response?.data?.plan_conversions,

                    expired_signups: response?.data?.expired_signups ? response?.data?.expired_signups : 0,
                    total_value: response?.data?.total_value ? response?.data?.total_value : 0,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    };

    GetSuperadminDashboardExcel = async () => {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;

        let daterangepicker_label = this.state.daterangepicker_label;
        let start_date = daterangepicker_label !== "All Time" ? startDate.format() : "";
        let end_date = daterangepicker_label !== "All Time" ? endDate.format() : "";

        this.props.onGetSuperadminDashboardExcel(start_date, end_date).then((response) => {
            if (response.success === true) {
                const link = document.createElement("a");
                link.href = response.data.url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        });
    };

    handleCallback = (start_date, end_date, label) => {
        if (label === "Custom Range") {
            label = start_date.format("Do MMMM YYYY") + " , " + end_date.format("Do MMMM YYYY");
        }

        this.setState(
            {
                daterangepicker_label: label,
                startDate: start_date,
                endDate: end_date,
            },
            function () {
                this.GetSuperadminDashboard();
            }
        );
    };

    render() {
        const {
            daterangepicker_label,
            startDate,
            endDate,

            trial_signups,
            paid_conversions,
            plan_conversions_pro,
            plan_conversions_basic,
            expired_signups,
            total_value,

            dashboard_trialsignups_show,
            dashboard_paidconversions_show,
            dashboard_planconversions_show,
            dashboard_expiredsignups_show,
            dashboard_totalvalue_show,
        } = this.state;
        return (
            <React.Fragment>
                {this.state.loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                <Notifications />
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Dashboard" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card mb-4 bg-transparent">
                                                    <div className="card-header p-0 pb-4 pt-4 border-0">
                                                        <div className="card-title align-items-center m-0">
                                                            <span className="card-label fw-bold-600 fs-7 color1">Showing data for:</span>
                                                            <DateRangePicker
                                                                initialSettings={{
                                                                    startDate: startDate ? startDate.toDate() : "",
                                                                    endDate: endDate ? endDate.toDate() : "",
                                                                    ranges: {
                                                                        Today: [moment().toDate(), moment().toDate()],
                                                                        "Last 7 days": [moment().subtract(6, "days").toDate(), moment().toDate()],
                                                                        "Last 30 days": [moment().subtract(29, "days").toDate(), moment().toDate()],
                                                                        "Last week": [moment().subtract(1, "weeks").startOf("isoWeek").toDate(), moment().subtract(1, "weeks").endOf("week").toDate()],
                                                                        "Last month": [moment().subtract(1, "month").startOf("month").toDate(), moment().subtract(1, "month").endOf("month").toDate()],
                                                                    },
                                                                }}
                                                                onCallback={this.handleCallback}
                                                            >
                                                                <span className="fw-bold-600 fs-7 data-date d-flex align-items-center text-purple-dark me-5 curser_pointer">
                                                                    <i className="icon-calendar fw-bold-600 fs-7  me-2 text-purple-dark"></i>
                                                                    {daterangepicker_label}
                                                                </span>
                                                            </DateRangePicker>
                                                        </div>
                                                        <div className="card-toolbar m-0">
                                                            <button onClick={this.GetSuperadminDashboardExcel} className="rounded-50 btn export-btn-bg btn-md py-2 hvr-grow cursor-pointer">
                                                                Export as CSV <i className="icon-download fs-8 "></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {dashboard_trialsignups_show && (
                                                <div className="col-xl-4 mb-6">
                                                    <div className="card notify-activity">
                                                        <div className="card-header align-items-center border-0 mt-1 ">
                                                            <h4 className="card-title align-items-start flex-column m-0">
                                                                <span className="fw-bold-700 text-main-color mb-1">Trial signups</span>
                                                                <span className="text-muted fw-normal fs-6 text-grey-dark">{daterangepicker_label}</span>
                                                            </h4>
                                                            <div className="card-toolbar">
                                                                <button type="button" className="btn btn-sm btn-icon" id="dashboard_usercounts" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="icon-dots text-grey-dark icon-size"></i>
                                                                </button>
                                                                <div className="dropdown-menu menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_usercounts">
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div className="text-black" onClick={(event) => exportAsImage(this.dashboard_trialsignups_ref, "Trial signups")}>
                                                                            <i className="icon-download me-2 text-black"></i> <span>Download as image</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div
                                                                            className="text-black"
                                                                            onClick={(event) => {
                                                                                this.setState({
                                                                                    dashboard_trialsignups_show: dashboard_trialsignups_show ? false : true,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <i className="icon-eye me-2 text-black font-size-8"></i> <span>Hide chart</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-5 pb-5" ref={(node) => (this.dashboard_trialsignups_ref = node)}>
                                                            <div className="Bid-top">
                                                                <div className="d-flex">
                                                                    <div className="text-counts count-one">
                                                                        <h4 className="text-dark m-0 ms-4 fs-1">
                                                                            <span className="fs-2tx text-blue ">{trial_signups}</span>
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {dashboard_paidconversions_show && (
                                                <div className="col-xl-4 mb-6">
                                                    <div className="card notify-activity">
                                                        <div className="card-header align-items-center border-0 mt-1 ">
                                                            <h4 className="card-title align-items-start flex-column m-0">
                                                                <span className="fw-bold-700 text-main-color mb-1">Paid Conversions</span>
                                                                <span className="text-muted fw-normal fs-6 text-grey-dark">{daterangepicker_label}</span>
                                                            </h4>
                                                            <div className="card-toolbar">
                                                                <button type="button" className="btn btn-sm btn-icon" id="dashboard_usercounts" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="icon-dots text-grey-dark icon-size"></i>
                                                                </button>
                                                                <div className="dropdown-menu menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_usercounts">
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div className="text-black" onClick={(event) => exportAsImage(this.dashboard_paidconversions_ref, "Paid Conversions")}>
                                                                            <i className="icon-download me-2 text-black"></i> <span>Download as image</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div
                                                                            className="text-black"
                                                                            onClick={(event) => {
                                                                                this.setState({
                                                                                    dashboard_paidconversions_show: dashboard_paidconversions_show ? false : true,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <i className="icon-eye me-2 text-black font-size-8"></i> <span>Hide chart</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-5 pb-5" ref={(node) => (this.dashboard_paidconversions_ref = node)}>
                                                            <div className="Bid-top">
                                                                <div className="d-flex">
                                                                    <div className="text-counts count-one">
                                                                        <h4 className="text-dark m-0 ms-4 fs-1">
                                                                            <span className="fs-2tx text-blue ">{paid_conversions}</span>
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {dashboard_planconversions_show && (
                                                <div className="col-xl-4 mb-6">
                                                    <div className="card notify-activity">
                                                        <div className="card-header align-items-center border-0 mt-1 ">
                                                            <h4 className="card-title align-items-start flex-column m-0">
                                                                <span className="fw-bold-700 text-main-color mb-1">Plan conversions</span>
                                                                <span className="text-muted fw-normal fs-6 text-grey-dark">{daterangepicker_label}</span>
                                                            </h4>
                                                            <div className="card-toolbar">
                                                                <button type="button" className="btn btn-sm btn-icon" id="dashboard_usercounts" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="icon-dots text-grey-dark icon-size"></i>
                                                                </button>
                                                                <div className="dropdown-menu menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_usercounts">
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div className="text-black" onClick={(event) => exportAsImage(this.dashboard_planconversions_ref, "Plan Conversions")}>
                                                                            <i className="icon-download me-2 text-black"></i> <span>Download as image</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div
                                                                            className="text-black"
                                                                            onClick={(event) => {
                                                                                this.setState({
                                                                                    dashboard_planconversions_show: dashboard_planconversions_show ? false : true,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <i className="icon-eye me-2 text-black font-size-8"></i> <span>Hide chart</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-5 pb-5" ref={(node) => (this.dashboard_planconversions_ref = node)}>
                                                            <div className="Bid-top">
                                                                <div className="d-flex flex-column">
                                                                    <div className="progress">
                                                                        <div
                                                                            className="progress-bar plan_conversions_pro"
                                                                            role="progressbar"
                                                                            style={{ width: `${plan_conversions_pro}%` }}
                                                                            aria-valuenow={plan_conversions_pro}
                                                                            aria-valuemin="0"
                                                                            aria-valuemax="100"
                                                                        >{`${plan_conversions_pro}%`}</div>
                                                                        <div
                                                                            className="progress-bar plan_conversions_basic"
                                                                            role="progressbar"
                                                                            style={{ width: `${plan_conversions_basic}%` }}
                                                                            aria-valuenow={plan_conversions_basic}
                                                                            aria-valuemin="0"
                                                                            aria-valuemax="100"
                                                                        >{`${plan_conversions_basic}%`}</div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mt-1 mb-lg-8 mb-xl-2">
                                                                        <span className="text-purple">Pro</span>
                                                                        <span className="text-grey-dark">Basic</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {dashboard_expiredsignups_show && (
                                                <div className="col-xl-4 mb-6">
                                                    <div className="card notify-activity">
                                                        <div className="card-header align-items-center border-0 mt-1 ">
                                                            <h4 className="card-title align-items-start flex-column m-0">
                                                                <span className="fw-bold-700 text-main-color mb-1">Expired subscriptions</span>
                                                                <span className="text-muted fw-normal fs-6 text-grey-dark">{daterangepicker_label}</span>
                                                            </h4>
                                                            <div className="card-toolbar">
                                                                <button type="button" className="btn btn-sm btn-icon" id="dashboard_usercounts" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="icon-dots text-grey-dark icon-size"></i>
                                                                </button>
                                                                <div className="dropdown-menu menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_usercounts">
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div className="text-black" onClick={(event) => exportAsImage(this.dashboard_expiredsignups_ref, "Expired subscriptions")}>
                                                                            <i className="icon-download me-2 text-black"></i> <span>Download as image</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div
                                                                            className="text-black"
                                                                            onClick={(event) => {
                                                                                this.setState({
                                                                                    dashboard_expiredsignups_show: dashboard_expiredsignups_show ? false : true,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <i className="icon-eye me-2 text-black font-size-8"></i> <span>Hide chart</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-5 pb-5" ref={(node) => (this.dashboard_expiredsignups_ref = node)}>
                                                            <div className="Bid-top">
                                                                <div className="d-flex">
                                                                    <div className="text-counts count-one">
                                                                        <h4 className="text-dark m-0 ms-4 fs-1">
                                                                            <span className="fs-2tx text-blue ">{expired_signups}</span>
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {dashboard_totalvalue_show && (
                                                <div className="col-xl-4 mb-6">
                                                    <div className="card notify-activity">
                                                        <div className="card-header align-items-center border-0 mt-1 ">
                                                            <h4 className="card-title align-items-start flex-column m-0">
                                                                <span className="fw-bold-700 text-main-color mb-1">Total value</span>
                                                                <span className="text-muted fw-normal fs-6 text-grey-dark">{daterangepicker_label}</span>
                                                            </h4>
                                                            <div className="card-toolbar">
                                                                <button type="button" className="btn btn-sm btn-icon" id="dashboard_usercounts" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="icon-dots text-grey-dark icon-size"></i>
                                                                </button>
                                                                <div className="dropdown-menu menu menu-sub menu-sub-dropdown w-200px graph-dropdown p-2" aria-labelledby="dashboard_usercounts">
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div className="text-black" onClick={(event) => exportAsImage(this.dashboard_totalvalue_ref, "Total value")}>
                                                                            <i className="icon-download me-2 text-black"></i> <span>Download as image</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="py-4 px-2 cursor-pointer link-hover">
                                                                        <div
                                                                            className="text-black"
                                                                            onClick={(event) => {
                                                                                this.setState({
                                                                                    dashboard_totalvalue_show: dashboard_totalvalue_show ? false : true,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <i className="icon-eye me-2 text-black font-size-8"></i> <span>Hide chart</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-5 pb-5" ref={(node) => (this.dashboard_totalvalue_ref = node)}>
                                                            <div className="Bid-top">
                                                                <div className="d-flex">
                                                                    <div className="text-counts count-one">
                                                                        <h4 className="text-dark m-0 ms-4 fs-1">
                                                                            <span className="fs-2tx text-blue ">{total_value}</span>
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        error: state.superdmin_dashboard.error,
        loader: state.superdmin_dashboard.loader,
        isAuthenticated: state.superdmin_dashboard.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetSuperadminDashboard: SuperAdminActions.GetSuperadminDashboard,
    onGetSuperadminDashboardExcel: SuperAdminActions.GetSuperadminDashboardExcel,
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
