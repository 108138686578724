import React from "react";
import Notifications from "react-notify-toast";

import LoginSidebar from "../../components/SuperAdminSidebar";
import LoginHeader from "../../components/SuperAdminHeader";
import LoginFooter from "../../components/SuperAdminFooter";
class Sales extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Notifications />
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <LoginSidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <LoginHeader page_title="Sales" />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-xxl">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card mb-4 mb-xl-8 bg-transparent">
                                                    <div className="card-header border-0 p-0 mb-8">
                                                        <h3 className="card-title align-items-start flex-column m-0">
                                                            <span className="card-label fw-bold-700 fs-2x mb-1">Welcome back to your Sales</span>
                                                            <span className="mt-1 fw-normal text-dark-muted fs-base text-dark">It is now 10:00AM AEDT, 07 September 2022</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <LoginFooter />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Sales;