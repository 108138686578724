import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as actions from "./actions";

const AuthRouter = ({ component: Component, ...rest }) => {
    const http_Authenticated = localStorage.getItem("BidHQ_http_Authenticated");
    return (
        <Route
            {...rest}
            render={(props) => {
                if (process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "staging") {
                    if (http_Authenticated) {
                        if (actions.isAuthorize()) {
                            return <Redirect to="/dashboard" />;
                        } else {
                            return <Component {...props} />;
                        }
                    } else {
                        return <Redirect to="/authentication" />;
                    }
                } else {
                    if (actions.isAuthorize()) {
                        return <Redirect to="/dashboard" />;
                    } else {
                        return <Component {...props} />;
                    }
                }
            }}
        />
    );
};
export default AuthRouter;