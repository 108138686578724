import React from "react";
import { Modal } from "react-bootstrap";
class BillingAddAdditionalUsersModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            extrasubsciption_users: 1,
            subscription_package_selected: "",
        };
    }

    async componentDidMount() {
        this.setState({
            subscription_package_selected: this.props.subscription_package_selected,
            extrasubsciption_users: this.props.extrasubsciption_users === undefined ? this.state.extrasubsciption_users : this.props.extrasubsciption_users,
        });
    }

    SubmitHandler = (event) => {
        event.preventDefault();
        this.props.BillingAddAdditionalUsersSubmit(this.state.extrasubsciption_users);
    };

    render() {
        const { extrasubsciption_users, subscription_package_selected } = this.state;
        return (
            <React.Fragment>
                <Modal
                    className="billingaddadditionalusers_modal bid-custom-modal "
                    show={this.props.billingaddadditionalusers_modal}
                    onHide={() => {
                        this.props.billingaddadditionalusers_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add additional users</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form w-100" onSubmit={this.SubmitHandler}>
                            <div className="d-flex text-start mb-7 mt-2">
                                <div className="btn-group add-user-wrap border rounded">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-active-light-primary"
                                        onClick={(event) => {
                                            if (extrasubsciption_users) {
                                                this.setState({
                                                    extrasubsciption_users: extrasubsciption_users - 1,
                                                });
                                            }
                                        }}
                                    >
                                        <i className="bi bi-dash"></i>
                                    </button>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={extrasubsciption_users}
                                        onChange={(event) => {
                                            this.setState({
                                                extrasubsciption_users: event.target.value,
                                            });
                                        }}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-active-light-primary"
                                        onClick={(event) => {
                                            this.setState({
                                                extrasubsciption_users: parseInt(extrasubsciption_users) + 1,
                                            });
                                        }}
                                    >
                                        <i className="bi bi-plus"></i>
                                    </button>
                                </div>
                                <div className="ms-5">
                                    <span className="fw-bold-700 text-dark d-block ">AU${subscription_package_selected.subscription_package_feature ? subscription_package_selected.subscription_package_feature.unit_amount : 0}</span>
                                    <span className="text-muted fs-7 ">per user per {subscription_package_selected ? subscription_package_selected.billing_cycle : "-"}</span>
                                </div>
                            </div>
                            <div className="modal-footer twofactor-footer">
                                <button
                                    type="button"
                                    className="btn cancel-butn rounded-50 w-150"
                                    onClick={() => {
                                        this.props.billingaddadditionalusers_modal_action(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary remove-user rounded-50 w-140px hvr-grow">
                                    Add users
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
export default BillingAddAdditionalUsersModal;