import React from "react";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import * as userActions from "../../../actions/index";
import Validations from "../../../components/Utility/Validations";
import WysiwygEditorComment from "../../../components/Utility/WysiwygEditorComment";
class BidExportAndShareTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            share_form: {
                invite_users: {
                    type: "multiple_email",
                    label: "Invite ",
                    value: "",
                    valid: true,
                    error_msg: "",
                    placeholder: "Add multiple email addresses separated by commas.",
                    validations: { required: true },
                },
                message: {
                    type: "textarea",
                    label: "Message",
                    value: "",
                    valid: true,
                    error_msg: "",
                    placeholder: "Type a message...",
                    validations: { required: false },
                },
            },
            bid_detail: [],
            response_pdf: "",
            response_content: [],
            bid_response_shares: [],
            bid_response_share_users: [],
        };
    }

    componentDidMount() {
        let update_share_form = { ...this.state.share_form };
        update_share_form.invite_users.value = "";
        update_share_form.message.value = "";
        this.setState(
            {
                bid_detail: this.props.bid_detail,
                share_form: update_share_form,
                response_pdf: this.props.response_pdf,
                response_content: this.props.bid_response_form.response_content,
            },
            function () {
                this.GetBidResponseShare();
            }
        );
    }

    BidExportAndShareReload() {
        let update_share_form = { ...this.state.share_form };
        update_share_form.invite_users.value = "";
        update_share_form.message.value = "";
        this.setState(
            {
                bid_detail: this.props.bid_detail,
                share_form: update_share_form,
                response_pdf: this.props.response_pdf,
                response_content: this.props.bid_response_form.response_content,
            },
            function () {
                this.GetBidResponseShare();
            }
        );
    }

    GetBidResponseShare() {
        this.props.onGetBidResponseShare(this.props.bid_id).then((response) => {
            if (response.success === true) {
                let invite_users = [];
                for (let key in response.data) {
                    let invite_details = response.data[key];
                    for (let key in invite_details.invite_users) {
                        invite_users.push(invite_details.invite_users[key]);
                    }
                }
                this.setState({
                    bid_response_shares: response.data,
                    bid_response_share_users: invite_users,
                });
            } else {
                notify.show(response.message, "error");
            }
        });
    }

    ShareForm_Reset() {
        this.setState({
            share_form: {
                invite_users: {
                    type: "multiple_email",
                    label: "Invite ",
                    value: "",
                    valid: true,
                    error_msg: "",
                    placeholder: "Add multiple email addresses separated by commas.",
                    validations: { required: true },
                },
                message: {
                    type: "textarea",
                    label: "Message",
                    value: "",
                    valid: true,
                    error_msg: "",
                    placeholder: "Type a message...",
                    validations: { required: false },
                },
            },
        });
    }

    inputChangeHandlerWysiwygEditor(event, identifier) {
        const update_share_form = { ...this.state.share_form };
        const form_element = { ...update_share_form[identifier] };

        form_element.value = event;
        update_share_form[identifier] = form_element;

        this.setState({ share_form: update_share_form });
    }

    inputChangeHandler(event, identifier) {
        const update_share_form = { ...this.state.share_form };
        const form_element = { ...update_share_form[identifier] };

        form_element.value = event.target.value;

        if (form_element.type === "multiple_email") {
            let inviate_emails = form_element.value.split(",");
            for (let key in inviate_emails) {
                let inviate_email = inviate_emails[key];
                inviate_email = inviate_email.replace(/ /g, "");

                let is_email = inviate_email.match(
                    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                );

                if (!is_email) {
                    form_element.valid = false;
                    form_element.error_msg = "Email format is incorrect.";
                } else {
                    form_element.valid = true;
                    form_element.error_msg = "";
                }
            }
        } else {
            // Check Validation
            let validate_element = Validations(form_element);
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
        }

        update_share_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_share_form) {
            is_form_valid = update_share_form[identifier].valid && is_form_valid;
        }

        this.setState({ share_form: update_share_form });
    }

    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_share_form = this.state.share_form;
        for (let key in update_share_form) {
            let form_element = update_share_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_share_form[key] = form_element;
        }
        this.setState({ share_form: update_share_form });

        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.share_form) {
                form_data[key] = this.state.share_form[key].value;
            }
            this.props.BidResponseShare(form_data);
        }
    };

    render() {
        const { share_form, bid_response_shares, bid_response_share_users } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-6">
                            <div className="p-8">
                                <h2 className="card-title text-dark fw-bolder fs-2x">Export</h2>
                                <p className="m-0 color1">All your files will be zipped up and downloaded to your computer.</p>
                                <button type="button" className="btn btn-danger btn-lg rounded-50 mt-6 fs-7 hvr-grow-arrow" onClick={(event) => this.props.GenrateBidZip(event)}>
                                    Download
                                </button>
                            </div>
                        </div>

                        <div className="card mb-6">
                            <div className="share-header p-8 pb-0">
                                <h2 className="card-title text-dark fw-bolder m-0 fs-2x">Share</h2>
                                <div className="card-toolbar">
                                    <span className="btn-icon" onClick={(event) => this.props.BidShareHistory(event, bid_response_shares)}>
                                        <i className="icon-share fs-2"></i>
                                    </span>
                                </div>
                            </div>

                            <div className="p-8 share-bottom">
                                <div className="row mb-8">
                                    <div className={"col-lg-10 "}>
                                        <label className="form-label fs-7 fw-bold-700 color1 mb-3">Link to share</label>
                                        <input
                                            readOnly
                                            type="text"
                                            className="form-control form-control-lg form-control-solid title_vector_submitted fs-7"
                                            value={process.env.REACT_APP_API_URL + "/bid-response-share/" + this.state?.bid_detail?.bid_uuid}
                                        />
                                    </div>
                                    <div className="col-lg-2 mt-8">
                                        <button
                                            type="button"
                                            className="btn copy-link btn-lg rounded-50 hvr-grow-arrow"
                                            onClick={(event) => {
                                                navigator.clipboard.writeText(process.env.REACT_APP_API_URL + "/bid-response-share/" + this.state?.bid_detail?.bid_uuid);
                                                notify.show("Link copied successfully.", "success");
                                            }}
                                        >
                                            Copy link
                                        </button>
                                    </div>
                                </div>

                                <form className="form w-100" onSubmit={this.SubmitHandler}>
                                    <div className="row mb-4">
                                        <div className={"col-lg-12 fv-row mb-8" + (!share_form.invite_users.valid && share_form.invite_users.onBlur_out ? " error-field" : "")}>
                                            <label className="form-label fs-7 fw-bold-700 color1 mb-3">{share_form.invite_users.label}</label>
                                            <span className="d-inline-flex w-100 mb-2">You’ll send an email with the link from above. Anyone with this link can view.</span>
                                            <input
                                                type={share_form.invite_users.type}
                                                placeholder={share_form.invite_users.placeholder}
                                                className="form-control form-control-lg form-control-solid fs-7 color1 fw-normal"
                                                value={share_form.invite_users.value ? share_form.invite_users.value : ""}
                                                onChange={(event) => this.inputChangeHandler(event, "invite_users")}
                                                onBlur={(event) => {
                                                    share_form.invite_users.onBlur_out = true;
                                                    this.setState({ share_form: share_form });
                                                }}
                                                onKeyDown={(event) => {
                                                    if (event.key.toLowerCase() === "enter") {
                                                        const froalaeditor_event = document.getElementsByClassName("fr-element")[0];
                                                        froalaeditor_event.focus();
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            {!share_form.invite_users.valid && share_form.invite_users.onBlur_out ? <div className="error field-error">{share_form.invite_users.error_msg}</div> : ""}
                                        </div>
                                        <div className="col-lg-12 fv-row">
                                            <label className="form-label fs-6 fw-bold-700">{share_form.message.label}</label>
                                            {/**
												<textarea
													placeholder={share_form.message.placeholder}
													value={share_form.message.value ? share_form.message.value : ""}
													className="form-control form-control-lg form-control-solid fs-7 color1 fw-normal"
													onChange={(event) => this.inputChangeHandler(event, "message")}
												/>
											**/}
                                            <WysiwygEditorComment
                                                placeholder={share_form.message.placeholder}
                                                value={share_form.message.value ? share_form.message.value : ""}
                                                className="form-control form-control-lg form-control-solid fs-7 color1 fw-normal"
                                                onChange={(event) => this.inputChangeHandlerWysiwygEditor(event, "message")}
                                            />
                                        </div>
                                    </div>
                                    <div className="row pb-4">
                                        <div className="col-lg-12">
                                            <div className="border-bottom pb-6 text-end">
                                                <button
                                                    type="reset"
                                                    className="hover-new btn btn-lg rounded-50 w-auto"
                                                    onClick={(event) => {
                                                        this.ShareForm_Reset();
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                                <button type="submit" className="btn copy-link btn-lg rounded-50 w-150px hvr-grow-arrow">
                                                    Send
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <div className="row mb-4">
                                    <div className="col-lg-12">
                                        <div className="pt-4">
                                            <label className="form-label fs-6 fw-bolder">Shared with</label>
                                            <p>These are the people you’ve already shared the link with.</p>
                                            <Typeahead
                                                multiple
                                                disabled
                                                id="bid_response_share_users"
                                                inputProps={{ readOnly: true }}
                                                options={bid_response_share_users}
                                                selected={bid_response_share_users}
                                                onChange={(event) => {
                                                    console.log("event");
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.Bid.error,
        loader: state.Bid.loader,
        isAuthenticated: state.Bid.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetBidResponseShare: userActions.GetBidResponseShare,
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BidExportAndShareTab);