import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

let qs = require("qs");

/*** GetClients Action ***/
export const GetClientsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetClients_START,
    };
};
export const GetClientsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetClients_SUCCESS,
    };
};
export const GetClientsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetClients_FAIL,
    };
};
export const GetClients = (search, page, limit, sort, order) => {
    return (dispatch) => {
        dispatch(GetClientsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetClients`,
            params: {
                search: search,
                page: page,
                limit: limit,
                sort: sort,
                order: order,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetClientsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetClientsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetClientsStatus Action ***/
export const GetClientsStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetClientsStatus_START,
    };
};
export const GetClientsStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetClientsStatus_SUCCESS,
    };
};
export const GetClientsStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetClientsStatus_FAIL,
    };
};
export const GetClientsStatus = (search) => {
    return (dispatch) => {
        dispatch(GetClientsStatusStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetClientsStatus`,
        })
            .then(function (response) {
                dispatch(GetClientsStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetClientsStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateClient Action ***/
export const CreateClientStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.CreateClient_START,
    };
};
export const CreateClientSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CreateClient_SUCCESS,
    };
};
export const CreateClientFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CreateClient_FAIL,
    };
};
export const CreateClient = (form_data) => {
    return (dispatch) => {
        dispatch(CreateClientStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CreateClient`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CreateClientSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateClientFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetClientById Action ***/
export const GetClientByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetClientById_START,
    };
};
export const GetClientByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetClientById_SUCCESS,
    };
};
export const GetClientByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetClientById_FAIL,
    };
};
export const GetClientById = (client_id) => {
    return (dispatch) => {
        dispatch(GetClientByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetClientById`,
            params: {
                client_id: client_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetClientByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetClientByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateClient Action ***/
export const UpdateClientStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdateClient_START,
    };
};
export const UpdateClientSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateClient_SUCCESS,
    };
};
export const UpdateClientFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateClient_FAIL,
    };
};
export const UpdateClient = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateClientStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateClient`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateClientSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateClientFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeleteClient Action ***/
export const DeleteClientStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.DeleteClient_START,
    };
};
export const DeleteClientSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DeleteClient_SUCCESS,
    };
};
export const DeleteClientFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DeleteClient_FAIL,
    };
};
export const DeleteClient = (form_data) => {
    return (dispatch) => {
        dispatch(DeleteClientStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            url: `${actionType.environment_url}/api/DeleteClient`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(DeleteClientSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeleteClientFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetClientContactByClientId Action ***/
export const GetClientContactByClientIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetClientContactByClientId_START,
    };
};
export const GetClientContactByClientIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetClientContactByClientId_SUCCESS,
    };
};
export const GetClientContactByClientIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetClientContactByClientId_FAIL,
    };
};
export const GetClientContactByClientId = (client_id) => {
    return (dispatch) => {
        dispatch(GetClientContactByClientIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetClientContactByClientId`,
            params: { client_id: client_id },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetClientContactByClientIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetClientContactByClientIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** CreateClientPointContacts Action ***/
export const CreateClientPointContactsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.CreateClientPointContacts_START,
    };
};
export const CreateClientPointContactsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.CreateClientPointContacts_SUCCESS,
    };
};
export const CreateClientPointContactsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.CreateClientPointContacts_FAIL,
    };
};
export const CreateClientPointContacts = (form_data) => {
    return (dispatch) => {
        dispatch(CreateClientPointContactsStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            url: `${actionType.environment_url}/api/CreateClientPointContacts`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(CreateClientPointContactsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateClientPointContactsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** UpdateClientPointContact Action ***/
export const UpdateClientPointContactStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.UpdateClientPointContact_START,
    };
};
export const UpdateClientPointContactSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.UpdateClientPointContact_SUCCESS,
    };
};
export const UpdateClientPointContactFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.UpdateClientPointContact_FAIL,
    };
};
export const UpdateClientPointContact = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateClientPointContactStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            url: `${actionType.environment_url}/api/UpdateClientPointContact`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(UpdateClientPointContactSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateClientPointContactFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** DeleteClienPointContact Action ***/
export const DeleteClienPointContactStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.DeleteClienPointContact_START,
    };
};
export const DeleteClienPointContactSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.DeleteClienPointContact_SUCCESS,
    };
};
export const DeleteClienPointContactFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.DeleteClienPointContact_FAIL,
    };
};
export const DeleteClienPointContact = (client_contact_id) => {
    return (dispatch) => {
        dispatch(DeleteClienPointContactStart());
        return axios({
            method: "delete",
            url: `${actionType.environment_url}/api/DeleteClienPointContact`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            params: { client_contact_id: client_contact_id },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(DeleteClienPointContactSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeleteClienPointContactFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetClientBidsStatusByClientId Action ***/
export const GetClientBidsStatusByClientIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetClientBidsStatusByClientId_START,
    };
};
export const GetClientBidsStatusByClientIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetClientBidsStatusByClientId_SUCCESS,
    };
};
export const GetClientBidsStatusByClientIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetClientBidsStatusByClientId_FAIL,
    };
};
export const GetClientBidsStatusByClientId = (client_id) => {
    return (dispatch) => {
        dispatch(GetClientBidsStatusByClientIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetClientBidsStatusByClientId`,
            params: {
                client_id: client_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetClientBidsStatusByClientIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetClientBidsStatusByClientIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetClientBidsByClientId Action ***/
export const GetClientBidsByClientIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: Master_actionType.GetClientBidsByClientId_START,
    };
};
export const GetClientBidsByClientIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: Master_actionType.GetClientBidsByClientId_SUCCESS,
    };
};
export const GetClientBidsByClientIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.GetClientBidsByClientId_FAIL,
    };
};
export const GetClientBidsByClientId = (search, page, limit, sort, order, bid_status, client_id) => {
    return (dispatch) => {
        dispatch(GetClientBidsByClientIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bidhq_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/GetClientBidsByClientId`,
            params: {
                search: search,
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                bid_status: bid_status,
                client_id: client_id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetClientBidsByClientIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetClientBidsByClientIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};